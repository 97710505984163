import * as React from 'react'
import { _ } from '~/assets/localization/util'
import { setCurrentVisibleRanges } from '~/API/syncers/TimelineChunkSyncer'
import type { BasicViewFile } from '~/state/files/selectors'
import type { ImageGroup, TimelineSection } from '~/state/timeline/selectors'
import { containsUnloadedSections } from '~/state/timeline/selectors'
import {
    inArray,
    sliceByElement,
    withoutTheUndefined,
} from '~/utilities/arrayUtils'
import {
    areSameMonths,
    compareMonth,
    getFileMonth,
} from '~/utilities/dateOperations'
import { RippleLoaderModal } from '../Common/RippleLoaderModal'
import type { FileSelectionStatus } from './ImageGroupList'

export type FileTimeDescription = Pick<
    BasicViewFile,
    'fileID' | 'ctime' | 'mtime'
>
type Props = {
    selectFile: (fileID: FileTimeDescription) => void
    selectGroup: (fileIDs: FileID[]) => void
    deselectFile: (fileID: FileTimeDescription) => void
    deselectGroup: (fileIDs: FileID[]) => void
    imagesGrouped: ImageGroup[]
    isInSelectMode: boolean
    timelineSections: TimelineSection[]
    getFileSelectionStatus: (file: FileID) => FileSelectionStatus
    selectedFiles: FileID[]
}
type PropsToWrappedComponent = {
    onHover?: (file: BasicViewFile) => void
    isToBeSelected?: (file: FileID) => boolean
}
type State = {
    toBeSelected: FileID[]
    deselectionMode: boolean
    selectAfterLoaded?: {
        fileA: FileTimeDescription
        fileB: FileTimeDescription
    }
}

/* the resultIndexLookup gives us O(1) lookup to get index of value*/
type FlatFiles = {
    input: ImageGroup[]
    result: FileID[]
}
type SectionSpanCalculation = {
    sections: TimelineSection[]
    start: MonthRef
    end: MonthRef
    result: TimelineSection[]
}

// TODO: convert from HOC to HLC-dance to bake this into the selectGroup-methods instead
type ShiftClickSelectionHandler = () => void
let shiftClickSubscribers: ShiftClickSelectionHandler[] = []
export const subscribeToShiftClick = (handler: ShiftClickSelectionHandler) =>
    shiftClickSubscribers.push(handler)
export const unsubscribeFromShiftClick = (
    handler: ShiftClickSelectionHandler,
) => {
    shiftClickSubscribers = shiftClickSubscribers.filter((h) => h !== handler)
}

export const withSelectionKeyControls = <T extends Props>(
    WrappedComponent: React.ComponentType<T & PropsToWrappedComponent>,
) => {
    class SelectionKeyControls extends React.Component<T, State> {
        public state: Readonly<State> = {
            toBeSelected: [],
            deselectionMode: false,
        }

        isShiftDown = false
        lastClickedFile: FileTimeDescription | undefined = undefined
        lastHoveredFile: FileTimeDescription | undefined = undefined

        public componentDidMount() {
            document.addEventListener('keyup', this.keyUp, false)
            document.addEventListener('keydown', this.keyDown, false)
        }
        public componentWillUnmount() {
            document.removeEventListener('keydown', this.keyUp, false)
            document.removeEventListener('keydown', this.keyDown, false)
        }

        public componentDidUpdate(): void {
            // Do contain file-range pending + do have all files fetched in between - do select the range
            if (this.state.selectAfterLoaded !== undefined) {
                const { fileA, fileB } = this.state.selectAfterLoaded
                if (
                    !containsUnloadedSections(this.getSectionSpan(fileA, fileB))
                ) {
                    const files = this.getFilesBetweenFiles(
                        fileA.fileID,
                        fileB.fileID,
                    )
                    this.props.selectGroup(files)
                    this.setState({ selectAfterLoaded: undefined })
                }
            }
        }

        keyUp = (event: KeyboardEvent) => {
            if (event.shiftKey || event.keyCode === 16) {
                this.isShiftDown = false
                this.reset()
            }
        }
        keyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape' || event.keyCode === 27) {
                this.quitSelection()
            }

            if (event.shiftKey || event.keyCode === 16) {
                this.isShiftDown = true
                if (this.lastHoveredFile) {
                    this.onHover(this.lastHoveredFile)
                }
            }
        }
        reset = () => {
            this.setState({ toBeSelected: [], deselectionMode: false })
        }
        quitSelection = () => {
            this.props.deselectGroup(this.props.selectedFiles)
        }
        isInDeselectionMode = (
            toBeSelected: FileID[],
            excludeFile: FileID,
        ): boolean => {
            const status = this.props.getFileSelectionStatus
            return toBeSelected.every(
                (file) =>
                    file === excludeFile || status(file) !== 'NotSelected',
            )
        }

        // Memoize calculated result to avoid recalculating when hovering image in same month
        prevSectionSpan: SectionSpanCalculation | undefined
        getSectionSpan = (
            fileA: FileTimeDescription,
            fileB: FileTimeDescription,
        ): TimelineSection[] => {
            const [start, end] = [fileA, fileB]
                .map(getFileMonth)
                .sort(compareMonth)

            if (
                this.prevSectionSpan !== undefined &&
                this.props.timelineSections === this.prevSectionSpan.sections &&
                areSameMonths(start, this.prevSectionSpan.start) &&
                areSameMonths(end, this.prevSectionSpan.end)
            ) {
                return this.prevSectionSpan.result
            }

            const result = this.props.timelineSections.filter(
                (section) =>
                    compareMonth(section, start) >= 0 &&
                    compareMonth(section, end) <= 0,
            )
            this.prevSectionSpan = {
                sections: this.props.timelineSections,
                start,
                end,
                result,
            }
            return result
        }
        onHover = ({ fileID, ctime, mtime }: FileTimeDescription) => {
            if (!this.props.isInSelectMode) {
                this.lastClickedFile = undefined
            }
            if (
                this.lastHoveredFile &&
                this.lastHoveredFile.fileID === fileID &&
                this.state.toBeSelected.length > 0
            ) {
                return // spam handling
            }

            this.lastHoveredFile = { fileID, ctime, mtime }
            if (!this.isShiftDown) {
                return
            }
            if (!this.lastClickedFile) {
                return
            }

            const sections = this.getSectionSpan(
                this.lastClickedFile,
                this.lastHoveredFile,
            )
            if (containsUnloadedSections(sections)) {
                setCurrentVisibleRanges(sections)
            }

            const toBeSelected = this.getFilesBetweenFiles(
                this.lastClickedFile.fileID,
                this.lastHoveredFile.fileID,
            )
            if (toBeSelected.length <= 1) {
                return false
            }

            const deselectionMode = this.isInDeselectionMode(
                toBeSelected,
                this.lastClickedFile.fileID,
            )

            this.setState({ toBeSelected, deselectionMode })
            return
        }

        getFileSelectionStatus = (file: FileID): FileSelectionStatus => {
            const isFileSelected = this.props.getFileSelectionStatus(file)
            if (
                !this.isShiftDown ||
                (isFileSelected === 'Selected' && !this.state.deselectionMode)
            ) {
                return isFileSelected
            }

            const files = this.state.toBeSelected
            const isToBeSelected = inArray(files, file)
            if (this.state.deselectionMode) {
                return isToBeSelected ? 'ToBeDeselected' : isFileSelected
            }

            return isToBeSelected ? 'ToBeSelected' : isFileSelected
        }

        selectFile = (file: BasicViewFile) => {
            if (!this.onItemClicked(file)) {
                this.props.selectFile(file)
            }
        }
        deselectFile = (file: BasicViewFile) => {
            if (!this.onItemClicked(file)) {
                this.props.deselectFile(file)
            }
        }
        /* Selects all files between lastClickedFile and currentClickedFile
         * returns true if it overrides select/deselect file*/
        onItemClicked = (file: FileTimeDescription): boolean => {
            const previousClickedFile = this.lastClickedFile
            this.lastClickedFile = file

            if (!previousClickedFile || !this.isShiftDown) {
                return false
            }

            const files = this.getFilesBetweenFiles(
                previousClickedFile.fileID,
                file.fileID,
            )

            if (files.length <= 1) {
                return true
            }

            const sections = this.getSectionSpan(previousClickedFile, file)
            if (containsUnloadedSections(sections)) {
                // Section-loading triggered in `onHover` not yet resolved, set state to select all of them after load
                this.setState({
                    selectAfterLoaded: {
                        fileA: previousClickedFile,
                        fileB: file,
                    },
                })
            }

            if (this.isInDeselectionMode(files, previousClickedFile.fileID)) {
                this.props.deselectGroup(files)
            } else {
                this.props.selectGroup(files)
            }
            shiftClickSubscribers.forEach((c) => c())
            this.reset()
            return true
        }

        flatFiles: FlatFiles | undefined
        getFlatFiles = (): FileID[] => {
            if (
                this.flatFiles &&
                this.flatFiles.input === this.props.imagesGrouped
            ) {
                return this.flatFiles.result
            }
            const result = this.props.imagesGrouped.flatMap((g) =>
                withoutTheUndefined(g.images).map((f) => f.fileID),
            )

            this.flatFiles = { input: this.props.imagesGrouped, result }
            return result
        }

        getFilesBetweenFiles = (
            selectedFileID: FileID,
            lastSelectedFileID: FileID,
        ): FileID[] => {
            return sliceByElement(
                this.getFlatFiles(),
                selectedFileID,
                lastSelectedFileID,
            )
        }
        public render() {
            const showLoader = this.state.selectAfterLoaded !== undefined
            return (
                <>
                    {showLoader && (
                        <RippleLoaderModal
                            loadingText={_('fetching_file_selection_info')}
                        />
                    )}
                    <WrappedComponent
                        onHover={this.onHover}
                        {...this.props}
                        selectFile={this.selectFile}
                        deselectFile={this.deselectFile}
                        getFileSelectionStatus={this.getFileSelectionStatus}
                    />
                </>
            )
        }
    }
    return SelectionKeyControls
}
