import styled, { keyframes } from 'styled-components'
import { colors } from '~/assets/styleConstants'
import { Container as LoadingWrapper } from './LoadingPage'

const rippleEffect = keyframes`
    0% {
        transform: scale(0);
        opacity: 0;
    }
    33% {
        transform: scale(0.44);
        opacity: 1;
    }
    100% {
        transform: scale(0.88);
        opacity: 0;
    }
`

const CircleWrapper = styled.div`
    width: ${(props: { size?: number }) => props.size || 50}px;
    height: ${(props) => props.size || 50}px;
    position: relative;
`

const Circle = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 50%;
    border: 6px solid
        ${(props: { color?: string }) => props.color || colors.turquoise};
    animation: ${rippleEffect} 1.3s ease-out infinite;
    box-sizing: border-box;
`

const Circle2 = styled(Circle)`
    animation-delay: 0.65s;
    border: 6px solid ${(props) => props.color || colors.lightTurquoise};
`

export const RippleLoader: React.FunctionComponent<{
    color?: string
    size?: number
}> = (props) => {
    return (
        <CircleWrapper size={props.size}>
            <Circle color={props.color} />
            <Circle2 color={props.color} />
        </CircleWrapper>
    )
}

export const RippleLoaderPage: React.FunctionComponent<{
    colorTheme?: string
}> = (props) => (
    <LoadingWrapper>
        <RippleLoader size={80} color={props.colorTheme} />
    </LoadingWrapper>
)
