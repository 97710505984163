import * as React from 'react'
import { trackEventInternal, trackEvent } from '~/analytics/eventTracking'
import type { Direction } from '~/utilities/keyboardNavigation'
import { directionFromKeyEvent } from '~/utilities/keyboardNavigation'

type KeyMethods = {
    Direction?: (dir: Direction, keyHeldDown?: boolean) => void
    Enter?: () => void
    Escape?: () => void
    Back?: () => void
    PlayPause?: () => void
    MediaStop?: () => void
}

type ProviderProps = {
    methods: KeyMethods
    children?: React.ReactNode
}

export class KeyEventProvider extends React.Component<ProviderProps> {
    private heldKeys: DictionaryOf<boolean> = {} // not all browsers have event.repeat
    private handleKeydown = (event: KeyboardEvent) => {
        if (
            (event.key === 'Enter' || event.keyCode === 13) &&
            this.props.methods.Enter
        ) {
            event.preventDefault()
            event.stopPropagation()
            this.props.methods.Enter()
            return false
        }
        if (
            (event.key === 'Escape' || event.keyCode === 27) &&
            this.props.methods.Escape
        ) {
            event.preventDefault()
            event.stopPropagation()
            this.props.methods.Escape()
            return false
        }
        const dir = directionFromKeyEvent(event)
        if (dir !== undefined && this.props.methods.Direction) {
            event.preventDefault()
            event.stopPropagation()
            this.props.methods.Direction(
                dir,
                event.repeat !== undefined
                    ? event.repeat
                    : this.heldKeys[event.keyCode] === true,
            )
            this.heldKeys[event.keyCode] = true
            return false
        }
        if (
            (event.key === 'BrowserBack' || event.key === 'b') &&
            this.props.methods.Back
        ) {
            // mapping b key for testing purposes
            event.preventDefault()
            event.stopPropagation()
            trackEvent('RemoteButton', 'BrowserBack_btn_click')
            trackEventInternal('tv_remote_browser_back_btn_click')
            this.props.methods.Back()
            return false
        }
        if (
            (event.key === 'MediaPlayPause' || event.key === 'p') &&
            this.props.methods.PlayPause
        ) {
            event.preventDefault()
            event.stopPropagation()
            trackEvent('RemoteButton', 'MediaPlayPause_btn_click')
            trackEventInternal('tv_remote_media_playpause_btn_click')
            this.props.methods.PlayPause()
            return false
        }
        if (
            (event.key === 'MediaStop' || event.key === 's') &&
            this.props.methods.MediaStop
        ) {
            event.preventDefault()
            event.stopPropagation()
            trackEvent('RemoteButton', 'MediaStop_btn_click')
            trackEventInternal('tv_remote_media_stop_btn_click')
            this.props.methods.MediaStop()
            return false
        }
    }

    private handleKeyup = (event: KeyboardEvent) => {
        delete this.heldKeys[event.keyCode]
    }
    public componentDidMount() {
        document.addEventListener('keydown', this.handleKeydown)
        document.addEventListener('keyup', this.handleKeyup)
    }
    public componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeydown)
        document.removeEventListener('keyup', this.handleKeyup)
    }
    public render() {
        return this.props.children
    }
}
