import { useState } from 'react'
import { type TestFlag, TestFlagService } from '~/API/services/TestFlagService'

export const TestFlagAdmin = () => {
    const [testFlags, setTestFlags] = useState(TestFlagService.getDefined)

    const setTestFlag = (key: TestFlag, isSet: boolean) => {
        TestFlagService.set(key, isSet)
        setTestFlags((prev) => ({ ...prev, [key]: isSet }))
    }

    return (
        <div style={{ marginLeft: 20 }}>
            <h2>TestFlags:</h2>
            {Object.entries(testFlags).map(([name, isSet]) => {
                const inputName = `${name}-checkbox`
                return (
                    <div key={name}>
                        <input
                            type={'checkbox'}
                            checked={isSet}
                            onChange={() =>
                                setTestFlag(name as TestFlag, !isSet)
                            }
                            id={inputName}
                        />
                        {' - '}
                        <label htmlFor={inputName}>{name}</label>
                    </div>
                )
            })}
        </div>
    )
}
