import type { Action } from '../common/actions'
import { isType } from '../common/actions'
import { PopupClosed, TriggeredDisplayOfPopup } from './actions'

export type StoragePopupType =
    | 'AddedMoreFilesThanAvailableStorage'
    | 'RestoredMoreFilesThanAvailableStorage'
    | 'UsedMoreThan80PercentOfStorage'
    | 'UsedMoreThan95PercentOfStorage'

export type PopupState = {
    queue: StoragePopupType[]
    silenced: DictionaryOf<number> // PopupType to timestamp-map
}

const initialState: PopupState = {
    queue: [],
    silenced: {},
}

export function popupReducer(
    state: PopupState = initialState,
    action: Action,
): PopupState {
    if (isType(action, TriggeredDisplayOfPopup)) {
        if (
            state.silenced[action.payload.type] &&
            state.silenced[action.payload.type] > action.payload.triggeredAt
        ) {
            return state
        }
        return { ...state, queue: [...state.queue, action.payload.type] }
    }
    if (isType(action, PopupClosed)) {
        const newQueue = state.queue.filter(
            (type: StoragePopupType) => type !== action.payload.type,
        )
        let silenced = state.silenced
        if (action.payload.silencedUntil) {
            silenced = {
                ...silenced,
                [action.payload.type]: action.payload.silencedUntil,
            }
        }
        return { ...state, queue: newQueue, silenced }
    }
    return state
}

export const popupReducerMapObj = {
    popup: popupReducer,
}

export type StateWithPopup = StateOfReducerMapObj<typeof popupReducerMapObj>
