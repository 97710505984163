import type { Action } from '../common/actions'
import { isType } from '../common/actions'
import { UserInfoWasFetched } from './actions'

export type User = {
    userID: UserID
    name: string
    email?: string
    profilePicture?: string
}

export type UsersState = Record<UserID, User>

export const usersReducer = (
    state: UsersState = {},
    action: Action,
): UsersState => {
    if (isType(action, UserInfoWasFetched)) {
        return {
            ...state,
            ...action.payload.reduce<Record<UserID, User>>((o, user) => {
                o[user.userID] = user
                return o
            }, {}),
        }
    }

    return state
}

export const usersReducerMapObj = {
    users: usersReducer,
}

export type StateWithUsers = StateOfReducerMapObj<typeof usersReducerMapObj>
