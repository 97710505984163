import * as React from 'react'

type Props = {
    fileHandler: (files: File[]) => void
    acceptTypes?: string
    acceptMultiple?: boolean
}

type ComponentState = {
    fileChangeCounter: number // To force a new input-element to be used in dom after changes (resetting is hard)
}

export class InvisibleFileInput extends React.Component<Props, ComponentState> {
    private input: HTMLInputElement | null = null
    public state: ComponentState = { fileChangeCounter: 0 }

    public triggerFileDialog = () => {
        if (this.input) {
            this.input.click()
        }
    }

    private handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const testFiles = e.nativeEvent && (e.nativeEvent as any).testFiles // allow cypress e2e test
        const files = testFiles || e.target.files
        if (files) {
            const fileArr: File[] = []
            for (let i = 0, l = files.length; i < l; ++i) {
                fileArr.push(files[i])
            }
            this.props.fileHandler(fileArr)
            this.setState((s) => ({
                fileChangeCounter: s.fileChangeCounter + 1,
            }))
        }
    }

    private bindInput = (me: HTMLInputElement | null) => {
        this.input = me
    }

    public render() {
        return (
            <input
                key={this.state.fileChangeCounter}
                data-cy="invisibleFileInput"
                type="file"
                accept={
                    this.props.acceptTypes ||
                    'image/*, video/mp4, video/x-m4v, video/*, .heic'
                }
                ref={this.bindInput}
                style={{ display: 'none' }}
                onChange={this.handleFileChange}
                multiple={this.props.acceptMultiple !== false}
            />
        )
    }
}
