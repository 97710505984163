import { createActionCreator, createVoidActionCreator } from '../common/actions'

export enum UserActionTypes {
    DELETE_ALBUM = 'DELETE_ALBUM',
    UNSUBSCRIBE_ALBUM = 'UNSUBSCRIBE_ALBUM',
    DELETE_FILE = 'DELETE_FILE',
    DELETE_DEVICE = 'DELETE_DEVICE',
    FILE_COUNT_EXCEEDS_DOWNLOAD_LIMIT = 'FILE_COUNT_EXCEEDS_DOWNLOAD_LIMIT',
    SIZE_EXCEEDS_DOWNLOAD_LIMIT = 'SIZE_EXCEEDS_DOWNLOAD_LIMIT',
    ALBUM_FILE_COUNT_EXCEEDS_DOWNLOAD_LIMIT = 'ALBUM_FILE_COUNT_EXCEEDS_DOWNLOAD_LIMIT',
    ALBUM_SIZE_EXCEEDS_DOWNLOAD_LIMIT = 'ALBUM_SIZE_EXCEEDS_DOWNLOAD_LIMIT',
    CANCEL_STORAGE_PLAN = 'CANCEL_STORAGE_PLAN',
    CHANGE_STORAGE_PLAN = 'CHANGE_STORAGE_PLAN',
    REACTIVATE_STORAGE_PLAN = 'REACTIVATE_STORAGE_PLAN',
    PLAN_DOWNGRADE_CLICKED = 'PLAN_DOWNGRADE_CLICKED',
    INSUFFICIENT_PLAN_CLICKED = 'INSUFFICIENT_PLAN_CLICKED',
}

export type PendingUserAction = {
    type: UserActionTypes
    target: string
}

export const UserActionInitiated = createActionCreator<PendingUserAction>(
    'USER_ACTION_INITIATED',
)
export const UserAlertInitiated = createActionCreator<PendingUserAction>(
    'USER_ALERT_INITIATED',
)
export const UserActionConfirmHandled = createVoidActionCreator(
    'USER_ACTION_CONFIRM_HANDLED',
)
export const UserActionAlertHandled = createVoidActionCreator(
    'USER_ACTION_ALERT_HANDLED',
)

export const AlbumFileCountExceedsDownloadLimit = createActionCreator<JobID>(
    'ALBUM_FILE_COUNT_EXCEEDS_DOWNLOAD_LIMIT',
)

export const AlbumSizeExceedsDownloadLimit = createActionCreator<JobID>(
    'ALBUM_SIZE_EXCEEDS_DOWNLOAD_LIMIT',
)

export const FileCountExceedsDownloadLimit = createActionCreator<JobID>(
    'FILE_COUNT_EXCEEDS_DOWNLOAD_LIMIT',
)

export const SizeExceedsDownloadLimit = createActionCreator<JobID>(
    'SIZE_EXCEEDS_DOWNLOAD_LIMIT',
)

export const FileDeletionInitiated = (target: FileID) =>
    UserActionInitiated({ target, type: UserActionTypes.DELETE_FILE })

export const PlanChangeInitiated = (planID: string) =>
    UserActionInitiated({
        target: planID,
        type: UserActionTypes.CHANGE_STORAGE_PLAN,
    })

export const PlanCancelInitiated = (url: string) =>
    UserActionInitiated({
        target: url,
        type: UserActionTypes.CANCEL_STORAGE_PLAN,
    })

export const PlanReactivationInitiated = (url: string) =>
    UserActionInitiated({
        target: url,
        type: UserActionTypes.REACTIVATE_STORAGE_PLAN,
    })

export const InsufficientPlanClicked = (
    currMaxStorage: number,
    newPlanSize: number,
    currPlanSize: number,
) => {
    const newStorage = currMaxStorage + (newPlanSize - currPlanSize)
    return UserAlertInitiated({
        type: UserActionTypes.INSUFFICIENT_PLAN_CLICKED,
        target: newStorage.toString(),
    })
}

export const AlbumDeletionInitiated = (target: JobID) =>
    UserActionInitiated({ target, type: UserActionTypes.DELETE_ALBUM })
export const AlbumUnsubscriptionInitiated = (target: JobID) =>
    UserActionInitiated({ target, type: UserActionTypes.UNSUBSCRIBE_ALBUM })

export const DeleteConnectedDeviceInitiated = (target: string) =>
    UserActionInitiated({ target, type: UserActionTypes.DELETE_DEVICE })
