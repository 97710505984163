type MobileDeviceTarget = 'Android' | 'iOS' | 'Windows' | 'otherDevice'
const mobileDeviceRegExp: Record<MobileDeviceTarget, RegExp> = {
    Android: /Android/i,
    iOS: /iPhone|iPad|iPod/i,
    Windows: /IEMobile|Windows Phone|Windows NT.*;.*Touch/i,
    otherDevice: /webOS|BlackBerry|BB|PlayBook|Kindle|Silk|Opera Mini/i,
}

export type MobileDeviceChecker = Record<
    MobileDeviceTarget | 'any',
    () => boolean
>
export const isMobileDevice: MobileDeviceChecker = {
    Android: () => mobileDeviceRegExp['Android'].test(navigator.userAgent),
    iOS: () => mobileDeviceRegExp['iOS'].test(navigator.userAgent),
    Windows: () => mobileDeviceRegExp['Windows'].test(navigator.userAgent),
    otherDevice: () =>
        mobileDeviceRegExp['otherDevice'].test(navigator.userAgent),
    any: () =>
        isMobileDevice.Android() ||
        isMobileDevice.iOS() ||
        isMobileDevice.Windows() ||
        isMobileDevice.otherDevice(),
}

export const isModernBrowser = (): boolean => {
    // IE 10+
    const isIE = navigator.userAgent.match(/MSIE (\d+)/)
    if (isIE && isIE.length === 2) {
        return parseInt(isIE[1], 10) > 9
    }

    return true
}

export const isAnyIE = () =>
    /(?:\b(MS)?IE\s+|\bTrident\/7\.0;.*\s+rv:)(\d+)/.test(navigator.userAgent)

export const isSafariBrowser = (): boolean => {
    return (
        /Safari/.test(navigator.userAgent) &&
        /Apple Computer/.test(navigator.vendor)
    )
}

const isBrowser = (browser: string) =>
    new RegExp(browser).test(navigator.userAgent)
const browserVersion = (browser: string) => {
    const match = navigator.userAgent.match(
        new RegExp(browser + '(\\s|\\/)((\\d|\\.)*)'),
    )
    if (match) {
        return match[2]
    }
    return 'Other'
}
export const getBrowserInfo = (): { browser: string; version: string } => {
    let browser
    let version

    if (isBrowser('Edge')) {
        browser = 'Edge'
        version = browserVersion('Edge')
    } else if (isBrowser('Firefox')) {
        browser = 'Firefox'
        version = browserVersion('Firefox')
    } else if (isBrowser('FBAV') || isBrowser('FBIOS')) {
        browser = 'Facebook App Browser'
        version = browserVersion('FBAV')
    } else if (isBrowser('OPR')) {
        browser = 'Opera'
        version = browserVersion('OPR')
    } else if (isBrowser('Opera') && browserVersion('Version')) {
        browser = 'Opera'
        version = browserVersion('Version')
    } else if (isBrowser('Chrome')) {
        browser = 'Chrome'
        version = browserVersion('Chrome')
    } else if (
        /(iPhone|iPad|iPod|Mac OS X)/.test(navigator.userAgent) &&
        browserVersion('Version')
    ) {
        browser = 'Safari'
        version = browserVersion('Version')
    } else if (
        /Android/.test(navigator.userAgent) &&
        browserVersion('Version')
    ) {
        browser = 'Android Browser'
        version = browserVersion('Version')
    } else if (isBrowser('MSIE')) {
        browser = 'Internet Explorer'
        version = browserVersion('MSIE')
    } else if (navigator.userAgent.match(/Trident\/7\.0;.*\s+rv:((\d|\.)*)/)) {
        browser = 'Internet Explorer'
        version = navigator.userAgent.match(
            /Trident\/7\.0;.*\s+rv:((\d|\.)*)/,
        )![1]
    } else {
        browser = 'Other'
        version = 'Other'
    }
    return { browser, version }
}

export const isBrowserClipboardCompatible = (): boolean =>
    typeof fetch !== 'undefined' &&
    typeof ClipboardItem !== 'undefined' &&
    !!navigator?.clipboard?.write

const checkOS = (name: RegExp) => name.test(navigator.userAgent)
export const getOSInfo = (): { os: string; version: string } => {
    let os
    let osVersion
    if (checkOS(/(iPhone|iPad|iPod) OS/)) {
        const match = navigator.userAgent.match(
            /(iPhone|iPad|iPod) OS ([\d_.]*)/i,
        )
        osVersion = match ? match[2] : 'Other'
        os = 'iOS'
    } else if (checkOS(/Mac OS X/)) {
        const match = navigator.userAgent.match(/Mac OS X[\s/]([\d_.]*)/i)
        osVersion = match ? match[1] : 'Other'
        os = 'Mac OS X'
    } else if (checkOS(/Android \d/)) {
        const match = navigator.userAgent.match(/Android ([\d.]*)/i)
        osVersion = match ? match[1] : 'Other'
        os = 'Android'
    } else if (checkOS(/IEMobile|Windows Phone OS/)) {
        const match = navigator.userAgent.match(
            /IEMobile|Windows Phone OS ([\d.]*)/i,
        )
        osVersion = match ? match[1] : 'Other'
        os = 'Windows Mobile'
    } else if (checkOS(/Windows NT .*Touch/)) {
        const match = navigator.userAgent.match(/Windows NT ([\d.]*).*Touch/i)
        osVersion = match ? match[1] : 'Other'
        os = 'Windows Mobile'
    } else if (checkOS(/Windows NT \d*/)) {
        const match = navigator.userAgent.match(/Windows NT ([\d.]*)/i)
        osVersion = match ? match[1] : 'Other'
        os = 'Windows'
    } else if (checkOS(/Linux [xi]\d*/)) {
        const match = navigator.userAgent.match(/Linux ([xi][\d_.]*)/i)
        osVersion = match ? match[1] : 'Other'
        os = 'Linux'
    } else {
        osVersion = 'Other'
        os = 'Other'
    }
    return { os, version: osVersion }
}
