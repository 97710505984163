import type { ChangesAndAsyncUploadStatus } from '~/@types/backend-types'
import { getServiceProvider } from '../HostProvider'
import { getStoredServiceDict } from '../externals'
import type { AppService } from '../services/AppService'

class JobPollService {
    hostName: string
    jobID: JobID
    onChangeCallback: (
        jobID: JobID,
        fileID: FileID,
        processResponse: ChangesAndAsyncUploadStatus,
    ) => void
    errorHandler: (error: Error) => void

    private newestSerialSeen = 0
    private shouldPoll = false
    private timeoutRef?: NodeJS.Timeout
    private pollInterval = 2500

    constructor(
        hostName: string,
        jobID: JobID,
        onChange: (
            jobID: JobID,
            fileID: FileID,
            processResponse: ChangesAndAsyncUploadStatus,
        ) => void,
        errorHandler: (error: Error) => void,
    ) {
        this.hostName = hostName
        this.jobID = jobID
        this.onChangeCallback = onChange
        this.errorHandler = errorHandler
    }

    public start() {
        if (!this.shouldPoll) {
            this.shouldPoll = true
            this.poll()
        }
    }

    public stop() {
        this.shouldPoll = false
        clearTimeout(this.timeoutRef)
    }

    public poll() {
        if (!this.shouldPoll) {
            return
        }

        getServiceProvider()
            .getAppServiceForJob(this.jobID)
            .then((ah: AppService) =>
                ah
                    .getChangesAndAsyncUploadStatus(
                        this.jobID,
                        this.newestSerialSeen,
                    )
                    .then((response: ChangesAndAsyncUploadStatus) => {
                        const changesStatus = response.changes
                        if (changesStatus.length) {
                            this.newestSerialSeen = changesStatus.reduce(
                                (a, c) => Math.max(a, c.serial),
                                this.newestSerialSeen,
                            )
                            changesStatus
                                .filter((c) => c.type === 0)
                                .forEach((change) => {
                                    // We are currently only tracking new files
                                    this.onChangeCallback(
                                        this.jobID,
                                        change.id,
                                        response,
                                    )
                                })
                        }
                    })
                    .then(() => {
                        this.timeoutRef = setTimeout(
                            () => this.poll(),
                            this.pollInterval,
                        )
                    }),
            ),
            this.errorHandler
    }
}

let instance: UploadSyncer | null = null

class UploadSyncer {
    hostName: string
    jobsWatched: Map<
        JobID,
        {
            watcher: JobPollService
            files: Map<
                FileID,
                (processResponse: ChangesAndAsyncUploadStatus) => void
            >
        }
    >

    constructor(hostName: string) {
        if (instance !== null) {
            throw new Error('There can only be one upload manager')
        }
        // eslint-disable-next-line @typescript-eslint/no-this-alias -- we do this to ensure a singleton
        instance = this
        this.hostName = hostName
        this.jobsWatched = new Map()
    }

    private onChangeReceived(
        jobID: JobID,
        fileID: FileID,
        processResponse: ChangesAndAsyncUploadStatus,
    ) {
        // Check if we have a jobWatcher already
        const watcherForJob = this.jobsWatched.get(jobID)
        if (typeof watcherForJob === 'undefined') {
            return
        }

        if (watcherForJob.files.has(fileID)) {
            // Check if the job contains the file
            const callback = watcherForJob.files.get(fileID)
            if (typeof callback === 'undefined') {
                throw new Error('you forgot to add a callback to a file watch')
            }
            callback(processResponse)
            // Resolve file
            watcherForJob.files.delete(fileID)
        }
        // Delete the poller if we don't have any file left in the job
        if (watcherForJob.files.size === 0) {
            const jobMap = this.jobsWatched.get(jobID)
            if (jobMap !== undefined) {
                jobMap.watcher.stop()
                this.jobsWatched.delete(jobID)
            }
        }
    }

    private createJobWatcher(jobID: JobID, errorHandler: () => void) {
        this.jobsWatched.set(jobID, {
            watcher: new JobPollService(
                this.hostName,
                jobID,
                this.onChangeReceived.bind(this),
                errorHandler,
            ),
            files: new Map(),
        })
    }

    public watchFile(
        jobID: JobID,
        fileID: FileID,
        callback: (processResponse: ChangesAndAsyncUploadStatus) => void,
        errorHandler: () => void,
    ) {
        if (!this.jobsWatched.has(jobID)) {
            this.createJobWatcher(jobID, errorHandler)
        }
        const watcherForJob = this.jobsWatched.get(jobID)
        // @ts-expect-error -- we know it is not undefined since we just created this
        watcherForJob.files.set(fileID, callback)
        watcherForJob?.watcher.start()
    }
}

const pollHost = getStoredServiceDict()?.pollHost ?? 'ps-client.univex.no'
export const uploadSyncer = new UploadSyncer(pollHost)
