import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { TERMS_OF_SERVICE_LINK } from '~/config/constants'
import { colors, fontSize, mediaQueries } from '~/assets/styleConstants'
import { _ } from '~/assets/localization/util'
import { acceptTOSVersion, logoutAndRedirectToOpenWeb } from '~/API/login'
import { getTOSVerificationInfo } from '~/state/currentUser/selectors'
import { CaptureIcon } from '../Icons/CaptureLogo'
import { FullscreenOverlay } from './FullscreenOverlay'
import { FilledTextButton, TextButton } from './IconTextButton'
import { CenteredElementWrapper } from './LayoutComponents'

const ComponentWrapper = styled(CenteredElementWrapper)`
    width: 488px;
    height: 418px;

    border-radius: 3px;
    background-color: white;
    padding: 48px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    gap: 16px;

    align-items: center;
    text-align: center;

    ${mediaQueries.mobile} {
        padding: 48px 16px;
        width: 96%;
        max-width: 400px;
        max-height: 96%;
    }
`

const Title = styled.div`
    font-size: ${fontSize.large_24};
    font-weight: 700;
    color: ${colors.captureGrey800};
`

const TOSText = styled.span`
    color: ${colors.captureBlue};
`

const BtnWrapper = styled.div`
    width: 220px;
`

export const AcceptTOSModal = () => {
    const dispatch = useDispatch()
    const tosVersionToAccept = useSelector(getTOSVerificationInfo)

    const handleRefuseTOS = () => {
        logoutAndRedirectToOpenWeb(dispatch)
    }

    const handleAcceptTOS = () => {
        // TODO: add loading state
        if (tosVersionToAccept) {
            acceptTOSVersion(dispatch, tosVersionToAccept.version)
        }
    }

    const tos_link_text = _('terms_of_service')
    const [accept_tos_before, accept_tos_after] = _(
        'accept_tos__please_read_and_accept',
    ).split('%terms_of_service%')

    if (tosVersionToAccept === null) {
        return null
    }

    return (
        <FullscreenOverlay>
            <ComponentWrapper>
                <CaptureIcon size={78} />
                <Title>{_('accept_tos__header')}</Title>
                <p>
                    {accept_tos_before}
                    <a
                        href={TERMS_OF_SERVICE_LINK}
                        target="_blank"
                        rel="noreferrer">
                        <TOSText>{tos_link_text}</TOSText>
                    </a>
                    {accept_tos_after}
                </p>
                <BtnWrapper data-cy={'overlay_accept'}>
                    <FilledTextButton
                        fillColor={colors.captureAccentBlue}
                        text={_('accept')}
                        onClick={handleAcceptTOS}
                    />
                </BtnWrapper>
                <BtnWrapper data-cy={'overlay_deny'}>
                    <TextButton
                        color={colors.captureAccentBlue}
                        text={_('decline_tos')}
                        onClick={handleRefuseTOS}
                    />
                </BtnWrapper>
            </ComponentWrapper>
        </FullscreenOverlay>
    )
}
