import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import SvgClose from '@capture/capture-components/src/icons/Close'
import { AlbumChangedToPrivate } from '~/state/albumOptions/actions'
import { _ } from '~/assets/localization/util'
import {
    deleteAlbum,
    setAlbumSharingProperty,
    setAlbumTitle,
} from '~/API/album'
import { copyAlbumFilesToTimeline } from '~/API/job'
import { trackEvent, trackEventInternal } from '~/analytics/eventTracking'
import { colors, fontSize } from '~/assets/styleConstants'
import { Pages } from '~/routing'
import {
    AlbumCreationConfirmed,
    AlbumCreationEnded,
    ClearAlbumCreatedFromUploadFiles,
    ShowShareAlbumPrompt,
} from '~/state/album/actions'
import type { PendingAlbum } from '~/state/album/selectors'
import {
    getAlbumImageGroupStyle,
    getAlbumUploadingStatus,
    getPendingAlbum,
    getUploadFlag,
} from '~/state/album/selectors'
import {
    MissingNameModalContext,
    ShowMissingNameModal,
} from '~/state/currentUser/actions'
import { getNameOfLoggedInUser } from '~/state/currentUser/selectors'
import { LongRunningTaskFinished } from '~/state/statusNotifications/actions'
import { b64ToUuid } from '~/utilities/uuid'
import { MissingNameModal } from '../Album/MissingNameModal'
import { FilledTextButton, IconTextButton } from '../Common/IconTextButton'
import { LoadingPage } from '../Common/LoadingPage'
import { PageWrapper } from '../Common/PageWrapper'
import { TwoAreasTopNavBar } from '../Navigation/TwoAreasTopNavBar'
import { AlsoUploadAlbumFilesToTimelinePrompt } from '../Uploader/AlsoUploadToTimelinePrompt'
import { EditAlbumFileSection } from './EditAlbumFileSection'
import { EditAlbumTitle } from './EditAlbumTitle'

const ContentWrapper = styled.div`
    width: ${(props: { width: number }) => props.width}px;
    padding: 0 20px;
    padding-bottom: 32px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const ContentHeader = styled.h3`
    margin-bottom: 16px;
`

const TextButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 25px;
`

const EmptyAlbumCreator = styled.button`
    border: none;
    background-color: inherit;
    width: 100%;
    text-align: right;
    color: ${(props: { disabled: boolean }) =>
        !props.disabled ? colors.captureBlue : colors.captureBlue100};
    font-size: ${fontSize.medium_16};
    text-decoration: underline;
    margin-top: 5px;
    :hover {
        cursor: ${(props: { disabled: boolean }) =>
            !props.disabled ? 'pointer' : 'default'};
    }
`

const emitFromEditAlbumPage = {
    createAlbum: (isAlbumShared: boolean) => {
        trackEvent(
            'CreateAlbum',
            'ConfirmCreateAlbumClicked',
            isAlbumShared ? 'SharedAlbum' : 'PrivateAlbum',
        )
        trackEventInternal('albumedit_confirm_create_album_click', {
            type: isAlbumShared ? 'SharedAlbum' : 'PrivateAlbum',
        })
    },
    copyToTimeline: () => {
        trackEvent('EditAlbumPage', 'CopyFile(s)ToTimeline')
    },
}

// Hook to determine if the album can be created: If the album is shared the user must have a name and the album must have a title
const useCanUserCreateAlbum = (
    isAlbumShared: boolean,
    title: string | undefined,
) => {
    const userName = useSelector(getNameOfLoggedInUser)
    const isUploadingDone = useSelector(getAlbumUploadingStatus)

    const checkCanCreateAlbum = () => {
        if (!isAlbumShared && isUploadingDone) {
            return true
        }
        return !!title && !!userName && isUploadingDone
    }

    return checkCanCreateAlbum()
}

// Hook that adds the option to copy to timeline
const useCopyToTimelineOption = (
    album: PendingAlbum | undefined,
    albumID: string,
    createAlbum: () => Promise<void>,
    canCreateAlbum: boolean,
) => {
    const dispatch = useDispatch()
    const [showTimelineModal, setShowTimelineModal] = useState(false)
    const uploadFlag = useSelector(getUploadFlag)

    const uploadAlbumFilesToTimeline = () => {
        const fileIDs = album?.uploadedFiles.map(({ fileID }) => fileID) ?? []
        if (fileIDs?.length > 0) {
            emitFromEditAlbumPage.copyToTimeline()
            copyAlbumFilesToTimeline(dispatch, albumID, fileIDs)
        }
    }

    // Handler passed to decide on CopyToTimeline modal
    const doDecideOnTimeline = async (val: boolean) => {
        setShowTimelineModal(false)
        await createAlbum()
        val && uploadAlbumFilesToTimeline()
    }

    // Confirm album creation button
    const createAlbumButtonHandler = () => {
        if (!canCreateAlbum) {
            return
        }
        uploadFlag ? setShowTimelineModal(true) : createAlbum()
    }

    return { doDecideOnTimeline, showTimelineModal, createAlbumButtonHandler }
}

// Hook that adds the logic for set name modal
const useCheckForName = (isAlbumShared: boolean) => {
    const dispatch = useDispatch()
    const userName = useSelector(getNameOfLoggedInUser)

    // Showing the set name modal if the user doesn't have a name
    useEffect(() => {
        !userName &&
            isAlbumShared &&
            dispatch(
                ShowMissingNameModal(MissingNameModalContext.AlbumCreation),
            )
    }, [dispatch, isAlbumShared, userName])
    return userName
}

const getPendingAlbumByID =
    (albumID: string) => (state: StateOfSelector<typeof getPendingAlbum>) =>
        getPendingAlbum(state, albumID)

export const EditAlbumPage = () => {
    const styling = useSelector(getAlbumImageGroupStyle)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // Get the album info
    const { albumIDB64, goToAfter } = useParams<{
        albumIDB64: string
        goToAfter: string
    }>()

    const albumID = albumIDB64 ? b64ToUuid(albumIDB64) : ''
    const album = useSelector(getPendingAlbumByID(albumID))

    // Set album title
    const [title, setTitle] = useState<string>(album?.title ?? '')

    const hasAlbumAnyFiles =
        album?.uploadedFiles.length === 0 && album?.pendingFiles.length === 0

    // Check if the album can be created
    const canCreateAlbum = useCanUserCreateAlbum(!!album?.isShared, title)

    // Add name check functionality
    const userName = useCheckForName(!!album?.isShared)

    const makeAlbumPrivate = async () => {
        if (!userName) {
            await setAlbumSharingProperty(dispatch, albumID, false)
            dispatch(AlbumChangedToPrivate())
        }
    }

    // Get the main callbacks
    const createAlbum = async () => {
        if (!canCreateAlbum) {
            return
        }
        // Unset the upload flag
        dispatch(ClearAlbumCreatedFromUploadFiles())

        setAlbumTitle(dispatch, albumID, title)
            .then(() =>
                dispatch(
                    AlbumCreationConfirmed({
                        jobID: albumID,
                        shared: !!album?.isShared,
                    }),
                ),
            )
            .then(() => navigate(Pages.Album(albumID).url, { replace: true }))

        emitFromEditAlbumPage.createAlbum(!!album?.isShared)

        !!album?.isShared && dispatch(ShowShareAlbumPrompt(albumID))
        dispatch(AlbumCreationEnded())
    }

    // Cancel album creation and navigate to goToAfter
    const cancelEditAlbum = useCallback(async () => {
        await deleteAlbum(dispatch, albumID, navigate)
        dispatch(LongRunningTaskFinished('filesAreBeingCopied'))

        navigate(Pages.AfterAbortedAlbumEdit(goToAfter ?? '').url, {
            replace: true,
        })
        dispatch(AlbumCreationEnded())
    }, [albumID, dispatch, goToAfter, navigate])

    // Add copy to timeline functionality
    const { doDecideOnTimeline, showTimelineModal, createAlbumButtonHandler } =
        useCopyToTimelineOption(album, albumID, createAlbum, canCreateAlbum)

    if (!albumIDB64 && !goToAfter) {
        return null
    }
    if (album === undefined) {
        return <LoadingPage />
    }
    return (
        <PageWrapper
            navBar={
                <TwoAreasTopNavBar
                    left={
                        <IconTextButton
                            onClick={cancelEditAlbum}
                            icon={SvgClose}
                            text={_('cancel')}
                        />
                    }
                    hideUserAvi={true}
                />
            }
            hideFooter={true}>
            <ContentWrapper width={styling.width}>
                {!hasAlbumAnyFiles && (
                    <TextButtonWrapper>
                        <FilledTextButton
                            onClick={createAlbumButtonHandler}
                            text={_('save_create_album')}
                            color={'white'}
                            isDisabled={!canCreateAlbum}
                            data-cy="album__confirmAlbumBtn"
                        />
                    </TextButtonWrapper>
                )}

                <ContentHeader>1. {_('name_your_album')}</ContentHeader>
                <EditAlbumTitle
                    album={album}
                    albumID={albumID}
                    setTitle={setTitle}
                />

                <ContentHeader>2. {_('add_photos_to_album')}</ContentHeader>
                <EditAlbumFileSection album={album} albumID={albumID} />
                {hasAlbumAnyFiles && (
                    <EmptyAlbumCreator
                        onClick={createAlbum}
                        disabled={!canCreateAlbum}
                        data-cy={'editAlbum__createEmpty'}>
                        {_('create_empty_album')} &gt;
                    </EmptyAlbumCreator>
                )}

                <MissingNameModal
                    text={_('set_name_for_shared_album')}
                    onClose={makeAlbumPrivate}
                />
                {showTimelineModal && (
                    <AlsoUploadAlbumFilesToTimelinePrompt
                        albumName={title}
                        onDecide={doDecideOnTimeline}
                    />
                )}
            </ContentWrapper>
        </PageWrapper>
    )
}
