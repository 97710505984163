import type { FunctionComponent, PropsWithChildren } from 'react'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { FastScrollerTooltip } from '~/components/Timeline/FastScrollerTooltip'
import { localStorageGet, localStorageSet } from '~/utilities/webStorage'

type WrapperProps = {
    translateX: number
    isMobil: boolean
}
const ContentWrapper = styled.div`
    position: fixed;
    top: ${(props: WrapperProps) => (props.isMobil ? '20px' : '56px')};
    right: 0;
    bottom: 0;
    transform: translateX(${(props: WrapperProps) => props.translateX}px);
    transition: transform 0.3s ease-out;
`
type ToggleBtnProps = { isExpanded: boolean; isMobile: boolean }
const ToggleBtnWrapper = styled.div`
    position: absolute;
    top: ${(props: ToggleBtnProps) => (props.isMobile ? '136px' : '50%')};
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-100%);
`

type Props = PropsWithChildren<{
    toggleButton: FunctionComponent<{ isExpanded: boolean }>
    canToggle: boolean
    translateAmount: number
    isMobile: boolean
    isFastScrolling?: boolean
    isInSelectMode: boolean
    showFastScrollHint?: boolean // trigger fastscroller expansion
}>

export const SlideInHoverWrapper = ({
    toggleButton: ToggleButton,
    canToggle,
    translateAmount,
    isMobile,
    isFastScrolling,
    isInSelectMode,
    showFastScrollHint,
    children,
}: Props) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const [showedTooltipBefore, setShowedTooltipBefore] = useState(
        localStorageGet('fastScrollerTooltip'),
    ) // 'select' | 'regular' | 'both'
    const timerRef = useRef<number>()

    const disableFastScrollerTooltip = () => {
        if (isInSelectMode) {
            showedTooltipBefore === 'regular'
                ? setShowedTooltipBefore('both')
                : setShowedTooltipBefore('select')
            localStorageSet(
                'fastScrollerTooltip',
                showedTooltipBefore === 'regular' ? 'both' : 'select',
            )
        } else {
            showedTooltipBefore === 'select'
                ? setShowedTooltipBefore('both')
                : setShowedTooltipBefore('regular')
            localStorageSet(
                'fastScrollerTooltip',
                showedTooltipBefore === 'select' ? 'both' : 'regular',
            )
        }
        timerRef.current = window.setTimeout(() => setIsExpanded(false), 3000)
    }

    useEffect(() => {
        if (isFastScrolling) {
            handleMouseEnter() // started fast scrolling
        } else {
            handleMouseLeave() // stopped fast scrolling
        }
        if (showFastScrollHint) {
            handleMouseEnter()
        } else {
            handleMouseLeave()
        }
    }, [isFastScrolling, showFastScrollHint])

    const handleToggleClicked = () => {
        setIsExpanded(!isExpanded)
        if (!showedTooltipBefore) disableFastScrollerTooltip()
    }

    const handleMouseEnter = () => {
        clearTimeout(timerRef.current)
        if (!isExpanded) {
            setIsExpanded(true)
        }
    }
    const handleMouseLeave = () => {
        if (isExpanded) {
            if (showedTooltipBefore)
                timerRef.current = window.setTimeout(
                    () => setIsExpanded(false),
                    3000,
                )
        }
    }

    const fastScrollerTooltip = isExpanded &&
        !isMobile &&
        (isInSelectMode
            ? showedTooltipBefore !== 'select' && showedTooltipBefore !== 'both'
            : showedTooltipBefore !== 'regular' &&
              showedTooltipBefore !== 'both') && (
            <FastScrollerTooltip
                onGotIt={disableFastScrollerTooltip}
                isInSelectMode={isInSelectMode}
            />
        )
    return (
        <ContentWrapper
            isMobil={isMobile}
            translateX={!canToggle || isExpanded ? 0 : translateAmount}>
            <div
                style={{ height: '100%' }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                {children}
            </div>
            <ToggleBtnWrapper
                onClick={handleToggleClicked}
                onKeyUp={handleToggleClicked}
                role="button"
                tabIndex={0}
                isExpanded={isExpanded}
                isMobile={isMobile}>
                {canToggle && <ToggleButton isExpanded={isExpanded} />}
            </ToggleBtnWrapper>
            {fastScrollerTooltip}
        </ContentWrapper>
    )
}
