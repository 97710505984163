import type { Action } from '../common/actions'
import { isType } from '../common/actions'
import type { FilesDownloadType } from '../job/actions'
import {
    ClearDownloaderState,
    MaximizeDownloaderModal,
    MinimizeDownloaderModal,
    SetClickedLinkStatus,
    StartPaginatedDownloadFlow,
} from './actions'

export type ModalStatus = 'off' | 'min' | 'max'
export type DownloaderPage = {
    fileIDs: FileID[]
    totalSize: number
    clicked: boolean
    name: string
    hasHEIC: boolean
}

export type DownloaderModalInfo = {
    downloadType: FilesDownloadType
    jobID: JobID
    count: number
    size: number
    pages: DownloaderPage[]
}

type DownloaderModal<T extends ModalStatus, PInfo = object> = {
    modalStatus: T
} & { modalInfo: PInfo }

export type DownloaderState =
    | DownloaderModal<'off', undefined>
    | DownloaderModal<'min', DownloaderModalInfo>
    | DownloaderModal<'max', DownloaderModalInfo>

const initialState: DownloaderState = {
    modalStatus: 'off',
    modalInfo: undefined,
}

export function downloaderReducer(
    state: DownloaderState = initialState,
    action: Action,
): DownloaderState {
    if (isType(action, StartPaginatedDownloadFlow)) {
        return { ...state, modalStatus: 'max', modalInfo: action.payload }
    }
    if (
        isType(action, MinimizeDownloaderModal) &&
        state.modalStatus === 'max'
    ) {
        return { ...state, modalStatus: 'min' }
    }
    if (
        isType(action, MaximizeDownloaderModal) &&
        state.modalStatus === 'min'
    ) {
        return { ...state, modalStatus: 'max' }
    }
    if (isType(action, SetClickedLinkStatus) && state.modalStatus !== 'off') {
        return {
            ...state,
            modalInfo: state.modalInfo && {
                ...state.modalInfo,
                pages: state.modalInfo.pages.map((page, id) => {
                    if (id === action.payload) {
                        return {
                            ...page,
                            clicked: true,
                        }
                    }

                    return page
                }),
            },
        }
    }
    if (isType(action, ClearDownloaderState)) {
        return initialState
    }

    return state
}

export const downloaderReducerMapObj = {
    downloader: downloaderReducer,
}

export type StateWithDownloader = StateOfReducerMapObj<
    typeof downloaderReducerMapObj
>
