import { HostUrl } from '../toolbox'

export type PollResponseStatus =
    | { status: 'ok'; seq: number }
    | { status: 'aborted' | 'error' }

export class PollService {
    private hostUrl: HostUrl

    constructor(hostname: string) {
        this.hostUrl = new HostUrl(hostname)
    }

    public pollForChanges(
        jobID: JobID,
        lastKnownSeq: number,
    ): AbortablePromise<PollResponseStatus> {
        const request = new XMLHttpRequest()
        let canCancel = true
        const promise = new Promise<PollResponseStatus>((ok) => {
            const fail = () => {
                canCancel = false
                ok({ status: 'error' })
            }
            const abort = () => {
                canCancel = false
                ok({ status: 'aborted' })
            }
            const succeed = (seq: number) => {
                canCancel = false
                ok({ status: 'ok', seq })
            }

            request.addEventListener('load', () => {
                if (request.status === 200) {
                    succeed(
                        parseInt(request.responseText.split('\n')[0], 10) || 0,
                    )
                } else {
                    fail()
                }
            })
            request.addEventListener('error', fail)
            request.addEventListener('abort', abort)

            request.open(
                'get',
                this.hostUrl.getPath('/', {
                    seq: lastKnownSeq,
                    event: 'job_changed:' + jobID,
                }),
            )
            request.send()
        })

        // tslint:disable-next-line: prefer-object-spread
        return Object.assign(promise, {
            abort: () => canCancel && request.abort.call(request),
        })
    }
}
