import styled from 'styled-components'
import { RippleLoaderOverlay } from '../Common/RippleLoaderOverlay'
import { _ } from '../../assets/localization/util'

const Wrapper = styled.div`
    width: ${(props: { isMobile: boolean }) =>
        props.isMobile ? 'auto' : '300px'};
    min-width: ${(props: { isMobile: boolean }) =>
        props.isMobile ? '70vw' : 'auto'};
    padding: 0
        ${(props: { isMobile: boolean }) => (props.isMobile ? '10%' : '12%')};
    box-sizing: border-box;
`

const ShareIconsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 8px 0;

    & > div:last-child {
        justify-content: center;
    }
`
const IconWrapper = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    cursor: pointer;
    width: 64px;
    text-align: center;
    margin: 0 10px;
    margin-top: 16px;
`

const BottomElementWrapper = styled.div`
    height: 32px;
    max-width: 240px;
    margin: 0 auto 8px auto;
`

type Props = {
    shareOptions: React.ReactElement[]
    isMobile: boolean
    bottomElement?: React.ReactNode
    isPending?: boolean
}

export const ShareToComponent = (props: Props) => (
    <Wrapper isMobile={props.isMobile}>
        {props.isPending && <RippleLoaderOverlay />}
        <p>{_('share_disclaimer')}</p>
        <ShareIconsWrapper>
            {props.shareOptions.map((option) => (
                <IconWrapper key={`${option.key}_wrapper`}>
                    {option}
                </IconWrapper>
            ))}
        </ShareIconsWrapper>
        <BottomElementWrapper>{props.bottomElement}</BottomElementWrapper>
    </Wrapper>
)
