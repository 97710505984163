import styled from 'styled-components'
import { zIndex } from '~/assets/styleConstants'
import { ItemOverlay } from './Overlay'
import { RippleLoader } from './RippleLoader'

const Wrapper = styled(ItemOverlay)`
    z-index: ${zIndex.modal};

    display: flex;
    justify-content: center;
    align-items: center;
`

export const RippleLoaderOverlay: React.FunctionComponent<{
    loaderSize?: number
    backgroundColor?: string
}> = (props) => {
    return (
        <Wrapper
            backgroundRGBA={
                props.backgroundColor || 'rgba(255, 255, 255, 0.8)'
            }>
            <RippleLoader size={props.loaderSize} />
        </Wrapper>
    )
}
