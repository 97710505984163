import type { SVGProps } from 'react'
const SvgAddToPhotos = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill={props.color ?? 'currentColor'}
            d="M14 14c.283 0 .52-.096.713-.287A.968.968 0 0 0 15 13v-2h2c.283 0 .52-.096.712-.287A.968.968 0 0 0 18 10a.967.967 0 0 0-.288-.713A.968.968 0 0 0 17 9h-2V7a.967.967 0 0 0-.287-.713A.968.968 0 0 0 14 6a.968.968 0 0 0-.713.287A.967.967 0 0 0 13 7v2h-2a.968.968 0 0 0-.713.287A.967.967 0 0 0 10 10c0 .283.096.52.287.713.192.191.43.287.713.287h2v2c0 .283.096.52.287.713.192.191.43.287.713.287m-6 4c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 6 16V4c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 8 2h12c.55 0 1.02.196 1.413.587C21.804 2.98 22 3.45 22 4v12c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 20 18zm0-2h12V4H8zm-4 6c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 2 20V7c0-.283.096-.52.288-.713A.968.968 0 0 1 3 6c.283 0 .52.096.712.287.192.192.288.43.288.713v13h13c.283 0 .52.096.712.288.192.191.288.429.288.712s-.096.52-.288.712A.968.968 0 0 1 17 22z"
        />
    </svg>
)
export default SvgAddToPhotos
