import { useState } from 'react'
import styled from 'styled-components'
import { _ } from '~/assets/localization/util'
import { colors, fontSize, mediaQueries } from '~/assets/styleConstants'
import type { ConfigurableAlbumDetails } from '~/state/albumList/reducer'
import { EditableInput } from '../Common/EditableInput'
import { FilledTextButton } from '../Common/IconTextButton'

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

const SettingHeader = styled.label`
    font-size: ${fontSize.medium_16};
`

const InteractionSettingsWrapper = styled.div`
    margin-top: 24px;
    & > div {
        margin-bottom: 12px;
    }
`

const InputWrapper = styled.div`
    width: 400px;
    padding: 2px 4px;
    box-sizing: border-box;
    border: 1px solid ${colors.captureGrey500};
    margin-top: 4px;

    ${mediaQueries.mobile} {
        width: 250px;
    }
`

type Props = {
    onSettingsSaved: (settings: Partial<ConfigurableAlbumDetails>) => void
    defaultSettings: Partial<ConfigurableAlbumDetails>
}

export const SharedAlbumSettings: React.FC<Props> = (props) => {
    const [title, setTitle] = useState(
        props.defaultSettings.title || _('my_new_shared_album'),
    )
    const [allow_comments, setAllowComments] = useState(
        props.defaultSettings.allow_comments !== false,
    )
    const [allow_uploads, setAllowUploads] = useState(
        props.defaultSettings.allow_uploads !== false,
    )

    return (
        <FormWrapper>
            <div>
                <SettingHeader>{_('album_name')}:</SettingHeader>
                <InputWrapper>
                    <EditableInput
                        initialValue={props.defaultSettings.title}
                        onBlur={setTitle}
                    />
                </InputWrapper>
            </div>
            <InteractionSettingsWrapper>
                <div>
                    <input
                        type="checkbox"
                        checked={allow_comments}
                        onClick={() => setAllowComments(!allow_comments)}
                        style={{ marginRight: 8 }}
                    />
                    {_('allow_others_comment')}
                </div>
                <div>
                    <input
                        type="checkbox"
                        checked={allow_uploads}
                        onClick={() => setAllowUploads(!allow_uploads)}
                        style={{ marginRight: 8 }}
                    />
                    {_('allow_others_upload')}
                </div>
            </InteractionSettingsWrapper>
            <div style={{ width: '100%', marginTop: 'auto' }}>
                <FilledTextButton
                    text={_('create_album')}
                    onClick={() =>
                        props.onSettingsSaved({
                            title,
                            allow_uploads,
                            allow_comments,
                            isShared: true,
                        })
                    }
                />
            </div>
        </FormWrapper>
    )
}
