export type ElementSize = {
    width: number
    height: number
}

export const elementSizeCalculator = (
    containerSize: ElementSize,
    minWidthGapRate = 0,
) => {
    return (originSize: ElementSize): ElementSize => {
        const ratio = originSize.width / originSize.height

        const minWidth = Math.min(originSize.width, containerSize.width)
        const minHeight = Math.min(originSize.height, containerSize.height)

        const scaleToWidth = {
            width: minWidth,
            height: minWidth / ratio,
        }
        const scaleToWidthOK = scaleToWidth.height <= containerSize.height
        const widthScaleRate = scaleToWidth.width / originSize.width

        const scaleToHeight = {
            width: minHeight * ratio,
            height: minHeight,
        }
        const scaleToHeightOK = scaleToHeight.width <= containerSize.width
        const heightScaleRate = scaleToHeight.height / originSize.height

        let betterScaleSize: ElementSize
        if (widthScaleRate < heightScaleRate) {
            betterScaleSize = scaleToHeightOK ? scaleToHeight : scaleToWidth
        } else {
            betterScaleSize = scaleToWidthOK ? scaleToWidth : scaleToHeight
        }

        if (betterScaleSize === scaleToHeight) {
            const widthGap = containerSize.width - scaleToHeight.width
            const widthGapRate = widthGap / containerSize.width
            const widthGapShouldIgnore = widthGapRate < minWidthGapRate

            return widthGapShouldIgnore ? scaleToWidth : scaleToHeight
        }

        return betterScaleSize
    }
}
