/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    CAPBAKHTTPValidationError,
    CAPBAKTrashCanAlbumsGetParams,
    CAPBAKTrashcanAlbumItem,
} from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class TrashCanAlbums<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * @description this call will list all albums in the trash can
     *
     * @tags trash_can
     * @name TrashCanAlbumsGet
     * @summary Get Trash Can Albums
     * @request GET:/trash_can_albums
     */
    trashCanAlbumsGet = (
        query: CAPBAKTrashCanAlbumsGetParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKTrashcanAlbumItem[], CAPBAKHTTPValidationError>({
            path: `/trash_can_albums`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
}
