import type { SVGProps } from 'react'
const SvgGetLink = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill={props.color ?? 'currentColor'}
            d="M7 17c-1.383 0-2.562-.488-3.537-1.463C2.487 14.563 2 13.384 2 12s.487-2.562 1.463-3.537C4.437 7.488 5.617 7 7 7h3c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.713A.968.968 0 0 1 10 9H7c-.833 0-1.542.292-2.125.875A2.893 2.893 0 0 0 4 12c0 .833.292 1.542.875 2.125A2.893 2.893 0 0 0 7 15h3c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.712A.968.968 0 0 1 10 17zm2-4a.967.967 0 0 1-.713-.287A.968.968 0 0 1 8 12c0-.283.096-.52.287-.713A.967.967 0 0 1 9 11h6c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.713A.968.968 0 0 1 15 13zm5 4a.968.968 0 0 1-.713-.288A.968.968 0 0 1 13 16c0-.283.096-.52.287-.713A.968.968 0 0 1 14 15h3c.833 0 1.542-.292 2.125-.875A2.893 2.893 0 0 0 20 12c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0 0 17 9h-3a.968.968 0 0 1-.713-.287A.967.967 0 0 1 13 8c0-.283.096-.52.287-.713A.968.968 0 0 1 14 7h3c1.383 0 2.563.487 3.538 1.463C21.512 9.438 22 10.617 22 12s-.488 2.563-1.462 3.537C19.562 16.513 18.383 17 17 17z"
        />
    </svg>
)
export default SvgGetLink
