import type { SVGProps } from 'react'
const SvgArrowBack = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill={props.color ?? 'currentColor'}
            d="m7.825 13 4.9 4.9c.2.2.296.433.287.7-.008.267-.112.5-.312.7-.2.183-.433.28-.7.287a.916.916 0 0 1-.7-.287l-6.6-6.6a.877.877 0 0 1-.212-.325A1.106 1.106 0 0 1 4.425 12c0-.133.02-.258.063-.375A.878.878 0 0 1 4.7 11.3l6.6-6.6a.933.933 0 0 1 .688-.275c.274 0 .512.092.712.275.2.2.3.438.3.713 0 .275-.1.512-.3.712L7.825 11H19c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.967.967 0 0 1 19 13H7.825Z"
        />
    </svg>
)
export default SvgArrowBack
