import { _ } from '@capture/client/assets/localization/util'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { PRODUCT_NAME } from '@capture/client/config/constants'
import { RequireLoginActionPage } from '@capture/client/components/Info/RequireLoginActionPage'
import { Share } from '@capture/client/routing/pages'
import { localStorageSet } from '@capture/client/utilities/webStorage'

export const RequireLoginCopyShare: React.FunctionComponent = () => {
    const { shareID } = useParams<{ shareID: string }>()
    if (!shareID) {
        throw Error('Must have params for shareID')
    }
    const copyToTimeline = () => {
        // Store addShareFilesToCapture in localStorage (to allow copying
        // files to timeline job after redirects)
        localStorageSet('addShareFilesToCapture', shareID)
    }
    return (
        <RequireLoginActionPage
            text={_('needs_login_to_copy_files_to_timeline').replace(
                '%product_name%',
                PRODUCT_NAME,
            )}
            targetAfterLogin={Share(shareID)}
            afterLoginAction={copyToTimeline}
            loginUtmSource={'share_receiver'}
        />
    )
}
