import { useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { HideMissingNameModal } from '~/state/currentUser/actions'
import { _ } from '~/assets/localization/util'
import { setProfileUserName } from '~/API/currentUser'
import { colors } from '~/assets/styleConstants'
import { EditableInput } from '../Common/EditableInput'
import { FilledTextButton } from '../Common/IconTextButton'

export const IsActiveWrapper = styled.div`
    opacity: ${(props: { isActive: boolean; opacity?: number }) =>
        props.opacity || 1};
    ${({ isActive }) =>
        !isActive &&
        `
        cursor: default;
        user-select: none;
        pointer-events: none;
    `}
`

const NameInputWrapper = styled.div`
    border-bottom: 1px solid ${colors.captureGrey400};
    margin: 8px 0 24px;
    width: 40%;
`

const FinishBtn = styled(FilledTextButton)`
    width: 162px;
    margin: 0 auto;
`

type Props = {
    onDone?: () => void
}

export const ProvideNamePrompt = (props: Props) => {
    const [enteredText, setEnteredText] = useState<string>('')
    const dispatch = useDispatch()

    const handleNameChange = (name: string) => setEnteredText(name)
    const handleDone = () => {
        enteredText &&
            setProfileUserName(dispatch, enteredText).then(() => {
                props.onDone?.()
                dispatch(HideMissingNameModal())
            })
    }

    const hasName = enteredText.length > 0
    return (
        <>
            <NameInputWrapper data-cy={'provideName__input'}>
                <EditableInput
                    initialValue={enteredText}
                    iconSize={24}
                    iconColor={colors.captureGrey400}
                    placeholder={_('tap_here_to_enter_name')}
                    onChange={handleNameChange}
                    maxLength={255}
                    focusOnMount={true}
                />
            </NameInputWrapper>
            <IsActiveWrapper isActive={hasName}>
                <FinishBtn
                    data-cy={'provideName__submit'}
                    onClick={handleDone}
                    text={_('done')}
                    hoverColor={colors.captureBlue}
                    hoverFillColor={colors.captureBlue100}
                    fillColor={
                        hasName ? colors.captureBlue : colors.captureBlue100
                    }
                />
            </IsActiveWrapper>
        </>
    )
}
