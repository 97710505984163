import { connect } from 'react-redux'
import styled from 'styled-components'
import { _ } from '~/assets/localization/util'
import { HOME_SITE_ADDRESS } from '~/config/constants'
import { fontSize } from '~/assets/styleConstants'
import { isMobileMode } from '~/state/viewMode/selectors'
import { PageWrapper } from '../Common/PageWrapper'
import { CaptureIconInactive } from '../Icons/CaptureLogo'
import type { InfoProps } from './InfoContainer'
import { InfoContainer } from './InfoContainer'

const Description = styled.div`
    font-size: ${fontSize.small_12};
    margin-top: 46px;
    max-width: ${(props: { mobile: boolean }) =>
        props.mobile ? '100%' : '480px'};
    padding: 0 16px;
    line-height: 21px;
    letter-spacing: -0.24px;
`

type StateProps = {
    isMobileMode: boolean
}
type OwnProps = {
    title: string
    description: string
}
type Props = StateProps & OwnProps

const _NotFoundPage: React.FunctionComponent<Props> = (props) => {
    const infoProps: InfoProps = {
        infoIcon: <CaptureIconInactive size={78} />,
        infoTitle: props.title,
        extLink: {
            link: HOME_SITE_ADDRESS,
            text: _('capture_more_link'),
        },
    }

    return (
        <PageWrapper>
            <InfoContainer {...infoProps}>
                <Description mobile={props.isMobileMode}>
                    {props.description}
                    <br /> <br />
                    {_('capture_create_user_description')}
                </Description>
            </InfoContainer>
        </PageWrapper>
    )
}

const mapStateToProps = (
    state: StateOfSelector<typeof isMobileMode>,
): StateProps => ({
    isMobileMode: isMobileMode(state),
})

const NotFoundPage = connect(mapStateToProps)(_NotFoundPage)

export const AlbumNotFoundPage = () => (
    <NotFoundPage
        title={_('album_not_found')}
        description={_('album_not_found_description')}
    />
)
export const ShareNotFoundPage = () => (
    <NotFoundPage
        title={_('share_not_found')}
        description={_('share_not_found_description')}
    />
)
