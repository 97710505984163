import { RECEIVER_APPLICATION_ID } from '~/config/constants'

export const loadChromecast = () =>
    new Promise<void>((resolve) => {
        if (window.cast) {
            return resolve()
        }

        window.__onGCastApiAvailable = (isAvailable: boolean) => {
            if (isAvailable && chrome.cast) {
                cast.framework.CastContext.getInstance().setOptions({
                    receiverApplicationId: RECEIVER_APPLICATION_ID,
                    autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
                })
                resolve()
            }
        }
        const s = document.createElement('script')
        s.src =
            'https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1'
        document.head.appendChild(s)
    }).then(() => {
        const remotePlayer = new cast.framework.RemotePlayer()
        return {
            remotePlayer,
            remotePlayerController: new cast.framework.RemotePlayerController(
                remotePlayer,
            ),
        }
    })
