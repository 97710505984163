import type { GridElementProps, GridStyle } from './gridElementSizeCalculator'
import {
    computeGridContainerWidth,
    computeGridHeight,
    computeMaxGridWidth,
} from './gridElementSizeCalculator'

type ImageGroupComponents = GridElementProps & {
    headerHeight: number
    headerBottomGap: number
    groupBottomGap: number
    verticalOffset?: number
}
export type ImageGroupStyle = GridStyle & ImageGroupComponents

const StandardImageGroupStyleDesktop: ImageGroupComponents = {
    elementWidth: 180,
    elementHeight: 180,
    elementSpaceAround: 2,
    headerHeight: 30,
    headerBottomGap: 12,
    groupBottomGap: 44,
}
const StandardImageGroupStyleMobile: ImageGroupComponents = {
    elementWidth: 176,
    elementHeight: 176,
    elementSpaceAround: 2,
    headerHeight: 30,
    headerBottomGap: 8,
    groupBottomGap: 24,
}

type GroupStyles = {
    desktop: ImageGroupComponents
    mobile: ImageGroupComponents
}
export const makeImageGroupStyle = (
    screenWidth: number,
    isMobile: boolean,
    groupStyles: GroupStyles = {
        desktop: StandardImageGroupStyleDesktop,
        mobile: StandardImageGroupStyleMobile,
    },
    maxContainerWidth?: number,
): ImageGroupStyle => {
    const groupStyle = isMobile ? groupStyles.mobile : groupStyles.desktop

    return {
        ...groupStyle,
        width: computeGridContainerWidth(
            screenWidth,
            maxContainerWidth || computeMaxGridWidth(groupStyle, 8),
            groupStyle.elementWidth,
            groupStyle.elementSpaceAround,
        ),
    }
}
export const computeImageGroupHeight = (
    groupImageCount: number,
    style: ImageGroupStyle,
): number =>
    computeGridHeight(style, groupImageCount) +
    style.headerHeight +
    style.headerBottomGap +
    style.groupBottomGap
