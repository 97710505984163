import { createActionCreator } from '../common/actions'
import type { PendingStatusType } from './reducer'

export const StatusNotificationDismissed = createActionCreator<number>(
    'STATUS_NOTIFICATION_DISMISSED',
)

export const LongRunningTaskStarted = createActionCreator<PendingStatusType>(
    'LONG_RUNNING_TASK_STARTED',
)
export const LongRunningTaskFinished = createActionCreator<PendingStatusType>(
    'LONG_RUNNING_TASK_FINISHED',
)
