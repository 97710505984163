import type { Selector } from 'reselect'
import { createSelector } from 'reselect'
import type { Reaction } from '~/@types/backend-types'
import { getCurrentUserUUID } from '../currentUser/selectors'
import type { User } from '../users/reducer'
import { getUsersInfo } from '../users/selectors'
import { Reactions } from './actions'
import type { FileReaction, StateWithReaction } from './reducer'

const getReactionList = (
    state: StateWithReaction,
): Record<FileID, FileReaction> => state.reaction

export type ReactionItem = {
    userID: UserID
    fileID: FileID
    reaction: Reaction
}
const getReactionItems = createSelector(
    getReactionList,
    (reactionList: Record<FileID, FileReaction>): ReactionItem[] => {
        const list: ReactionItem[] = []
        Object.keys(reactionList).forEach((fileID: FileID) => {
            const fileReaction = reactionList[fileID]
            Object.keys(fileReaction).forEach((userID: UserID) => {
                list.push({
                    fileID,
                    userID,
                    reaction: fileReaction[userID],
                })
            })
        })
        return list
    },
)

const getReactionItemsByType = (
    reactionType: Reaction,
): Selector<StateWithReaction, ReactionItem[]> =>
    createSelector(
        getReactionItems,
        (reactions: ReactionItem[]): ReactionItem[] => {
            return reactions.filter(({ reaction }) => reaction === reactionType)
        },
    )

const reactionTotalCountPerFile = (
    reactionType: Reaction,
): Selector<StateWithReaction, Record<FileID, number>> =>
    createSelector(
        getReactionItemsByType(reactionType),
        (reactions: ReactionItem[]): Record<FileID, number> => {
            return reactions.reduce<Record<FileID, number>>((o, { fileID }) => {
                o[fileID] = (o[fileID] || 0) + 1
                return o
            }, {})
        },
    )

export const getTotalLoveCountPerFile = reactionTotalCountPerFile(
    Reactions.Love,
)

const currentUserReactedFiles = (reactionType: Reaction) =>
    createSelector(
        getCurrentUserUUID,
        getReactionItemsByType(reactionType),
        (
            currentUserUUID: UserID | undefined,
            reactions: ReactionItem[],
        ): Record<FileID, boolean> => {
            if (currentUserUUID !== undefined) {
                return reactions.reduce<Record<FileID, boolean>>(
                    (o, { fileID, userID }) => {
                        o[fileID] = userID === currentUserUUID
                        return o
                    },
                    {},
                )
            }
            return {}
        },
    )

export const getCurrentUserLovedFiles = currentUserReactedFiles(Reactions.Love)

export const isCurrentUserLovedFile = (
    state: StateOfSelector<typeof getCurrentUserLovedFiles>,
    fileID: FileID,
) => getCurrentUserLovedFiles(state)[fileID]

const usersWhoReactedToFile = (reactionType: Reaction) =>
    createSelector(
        getReactionItemsByType(reactionType),
        getUsersInfo,
        (
            reactions: ReactionItem[],
            users: Record<UserID, User>,
        ): Record<FileID, User[]> => {
            return reactions.reduce<Record<FileID, User[]>>(
                (o, { userID, fileID }) => {
                    o[fileID] = (o[fileID] || []).concat(users[userID])
                    return o
                },
                {},
            )
        },
    )

export const getUsersLovedFilesList = usersWhoReactedToFile(Reactions.Love)

export const getUsersLovedListByFile = (
    state: StateOfSelector<typeof getUsersLovedFilesList>,
    fileID: FileID,
) => getUsersLovedFilesList(state)[fileID]
