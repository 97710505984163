import { setCursorType } from '~/state/cursor/cursorSlice'
import type { Dispatch } from '~/state/common/actions'
import {
    imageCopiedToClipboard,
    imageCopyToClipboardFailed,
} from '~/state/job/actions'
import { getServiceProvider } from '~/API/HostProvider'
import { BrowserFetchObject } from '~/API/toolbox'
import type { BasicViewFile } from '~/state/files/selectors'
import { isSafariBrowser } from './device'

// Fetching the image from the endpoint as a blob (regardless of the MIME)
const getTheBlob = async (jobID: string, fileId: string) => {
    const service = await getServiceProvider().getThumbServiceForJob(jobID)
    const url = service.getThumbUrl(jobID, fileId, 1280)
    const img = await BrowserFetchObject.get(url).rawResponse()
    const imgBlob = await img.blob()
    return imgBlob
}

// Creating an image element using the image blob (Required to draw the image on the canvas)
const createImageElement = async (
    imageSource: string,
): Promise<HTMLImageElement> => {
    return new Promise(function (resolve, reject) {
        const imageElement = document.createElement('img')
        imageElement.crossOrigin = 'anonymous'
        imageElement.src = imageSource

        imageElement.onload = function (event) {
            const target = event.target as HTMLImageElement
            resolve(target)
        }

        imageElement.onabort = reject
        imageElement.onerror = reject
    })
}

// Using canvas to draw a image and return a blob png
const convertToPng = async (imageElement: HTMLImageElement): Promise<Blob> => {
    return new Promise(function (resolve, reject) {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')

        if (context) {
            const { width, height } = imageElement
            canvas.width = width
            canvas.height = height
            context.drawImage(imageElement, 0, 0, width, height)

            canvas.toBlob(
                (blob) => {
                    if (blob) resolve(blob)
                    else reject('Cannot get blob from image element')
                },
                'image/png',
                1,
            )
        }
    })
}

// The other browsers outside Safari use this
const copyFromOtherBrowsers = async (
    dispatch: Dispatch,
    jobID: string,
    fileId: string,
) => {
    dispatch(setCursorType('wait'))
    try {
        const blob = await getTheBlob(jobID, fileId)
        if (blob.type.includes('png')) {
            await navigator.clipboard.write([
                new ClipboardItem({ 'image/png': blob }),
            ])
        } else {
            // If not a png use the convert via canvas
            const imageSource = URL.createObjectURL(blob)
            const imageElement = await createImageElement(imageSource)
            const pngBlob = await convertToPng(imageElement)
            await navigator.clipboard.write([
                new ClipboardItem({ 'image/png': pngBlob }),
            ])
        }
        dispatch(imageCopiedToClipboard())
        dispatch(setCursorType('auto'))
    } catch (error) {
        console.error(error)
        dispatch(imageCopyToClipboardFailed())
        dispatch(setCursorType('auto'))
    }
}

// Safari needs the fetch promise inside the ClipboardItem promise
const copyFileFromSafari = async (
    dispatch: Dispatch,
    jobID: string,
    fileId: string,
) => {
    dispatch(setCursorType('wait'))
    try {
        await navigator.clipboard.write([
            new ClipboardItem({
                'image/png': getTheBlob(jobID, fileId).then(
                    (imgBlob) => new Blob([imgBlob], { type: 'image/png' }),
                ),
            }),
        ])
        dispatch(imageCopiedToClipboard())
        dispatch(setCursorType('auto'))
    } catch (error) {
        console.error(error)
        dispatch(imageCopyToClipboardFailed())
        dispatch(setCursorType('auto'))
    }
}

export const copyImageToClipboard = async (
    dispatch: Dispatch,
    file: BasicViewFile,
) => {
    if (!file.duration) {
        isSafariBrowser()
            ? copyFileFromSafari(dispatch, file.jobID, file.fileID)
            : copyFromOtherBrowsers(dispatch, file.jobID, file.fileID)
    }
}
