import { CardElement } from '@stripe/react-stripe-js'
import type {
    CreateTokenCardData,
    Stripe,
    StripeCardElementChangeEvent,
    StripeElements,
    StripeElementsOptions,
    StripeError,
    Token,
} from '@stripe/stripe-js'
import * as React from 'react'
import styled from 'styled-components'
import type { HandleCardPaymentResponse } from '~/API/3rdParty/stripe'
import { colors, mediaQueries } from '~/assets/styleConstants'
import { withStripe } from './withStripe'
const InputFieldWrapper = styled.div`
    padding: 8px 0 0;

    & .StripeElement {
        width: 100%;
        border: 1px solid ${colors.captureGrey400};
        border-radius: 0px;
        padding: 8px;
        box-sizing: border-box;
        background: white;

        ${mediaQueries.mobile} {
            max-width: 100%;
        }
    }

    & .StripeElement--focus {
        box-shadow:
            rgba(0, 0, 0, 0.1) 0px 4px 6px,
            rgba(0, 0, 0, 0.1) 0px 1px 3px;
        transition: all 150ms ease;
    }

    & .StripeElement--invalid {
        border: 1px solid ${colors.captureMagenta};
    }
`

export interface CreditCardFieldMethods {
    getToken: (
        email: string,
    ) => Promise<{ token?: Token | undefined; error?: StripeError | undefined }>
    confirmCardPayment: (
        clientSecret: string,
    ) => Promise<HandleCardPaymentResponse>
}

type CredProps = {
    onChange?: (event: StripeCardElementChangeEvent) => void
    stripe: Stripe
    elements: StripeElements
}
class _StripeCreditCardField
    extends React.Component<CredProps>
    implements CreditCardFieldMethods
{
    private getCardElement = () => {
        const element = this.props.elements.getElement(CardElement)
        if (element === null) {
            throw new Error('unable to find stripe card element')
        }
        return element
    }
    public getToken = (email?: string) =>
        this.props.stripe.createToken(this.getCardElement(), {
            email,
        } as CreateTokenCardData)
    public confirmCardPayment = (clientSecret: string) =>
        this.props.stripe.confirmCardPayment(clientSecret, {
            payment_method: { card: this.getCardElement() },
        })

    public render() {
        return (
            <InputFieldWrapper>
                <CardElement
                    options={{
                        hidePostalCode: true,
                        style: { base: { fontSize: '16px' } },
                    }}
                    onChange={this.props.onChange}
                />
            </InputFieldWrapper>
        )
    }
}
export const makeStripeCreditCardField = (
    options: Partial<StripeElementsOptions> = { locale: 'no' },
) => withStripe(options)(_StripeCreditCardField)
