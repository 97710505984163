import type { Selector } from 'reselect'
import { createSelector } from 'reselect'
import { RoutePath } from '~/routing/routePath'
import { getCurrentLocation } from '~/utilities/navigation'
import { getNextUnfetchedAlbumID } from '../albumList/selectors'
import { JobInfoStatus } from '../jobInfo/reducer'
import {
    getJobUUIDFromCaptureWebURL,
    getStatusOfJob,
} from '../jobInfo/selectors'
import type { StateWithTimeline } from '../timeline/reducers'
import {
    getTimelineInitialLastChangeEventID,
    getTimelineJobID,
} from '../timeline/selectors'

export const getTheJobCurrentlyDisplayed: Selector<
    StateOfSelector<typeof getTimelineJobID> &
        StateOfSelector<typeof getNextUnfetchedAlbumID>,
    JobID | undefined
> = createSelector(
    getCurrentLocation,
    getTimelineJobID,
    getNextUnfetchedAlbumID,
    (
        currentURL: string | undefined,
        timelineJobID: JobID | undefined,
        albumJob?: JobID | undefined,
        forceAlb?: boolean,
    ): JobID | undefined => {
        if (forceAlb && albumJob) {
            return albumJob
        }
        if (!currentURL) {
            return timelineJobID
        }
        if (currentURL === RoutePath.Albums) {
            return albumJob
        }
        return getJobUUIDFromCaptureWebURL(currentURL) || timelineJobID
    },
)

export const getJobInfoStatusForCurrentJob = (
    state: StateOfSelector<typeof getStatusOfJob> &
        StateOfSelector<typeof getTheJobCurrentlyDisplayed>,
): JobInfoStatus =>
    getStatusOfJob(state, getTheJobCurrentlyDisplayed(state) || '')

export const getTheJobCurrentlyFetchingChangesFor = createSelector(
    getTheJobCurrentlyDisplayed,
    getJobInfoStatusForCurrentJob,
    getTimelineJobID,
    getTimelineInitialLastChangeEventID,
    (
        currentlyInFocus: JobID | undefined,
        status,
        timelineJobID,
        timelineEventID,
    ): JobID | undefined => {
        // Only fetch jobChanges when JobInfo is fetched
        if (status !== JobInfoStatus.FETCHED) {
            return
        }

        if (currentlyInFocus === timelineJobID && timelineEventID === -1) {
            return
        }

        return currentlyInFocus
    },
)

export const getInitialChangeEventID = (
    state: StateWithTimeline,
    jobID: JobID,
): number => {
    if (getTimelineJobID(state) === jobID) {
        return getTimelineInitialLastChangeEventID(state)
    }
    return 0
}
