import { createSelector } from 'reselect'
import { _ } from '~/assets/localization/util'
import { getGroupSelectionStatus } from '~/components/TimelineScroller/makeScrollerGroups'
import { FileTarget, getFileExtension } from '~/utilities/fileTarget'
import { calcImagesPerRow } from '~/utilities/gridElementSizeCalculator'
import type { ImageGroupStyle } from '~/utilities/imageGroupStyle'
import {
    computeImageGroupHeight,
    makeImageGroupStyle,
} from '~/utilities/imageGroupStyle'
import type { AvailableActions } from '../albumList/selectors'
import { getAvailableActions } from '../albumList/selectors'
import type { FileComment } from '../comments/reducer'
import { UserActionStatus } from '../comments/reducer'
import { getCommentsGroupedByImage } from '../comments/selectors'
import { getCurrentUserUUID } from '../currentUser/selectors'
import type { FileMetadata } from '../fileMetadata/actions'
import type { ExtendedJobFile } from '../files/reducer'
import { CommentStatus } from '../files/reducer'
import type { CaptureFile } from '../files/selectors'
import {
    getAllJobFiles,
    getCaptureFilesForJob,
    getFileByID,
    getFileType,
} from '../files/selectors'
import type { JobInfoElement, StoryJobInfo } from '../jobInfo/reducer'
import {
    getJobInfoElement,
    getJobInformation,
    getStoryJobInformation,
} from '../jobInfo/selectors'
import { getLastSeenElementForJob } from '../lastSeenElement/selectors'
import { Reactions } from '../reaction/actions'
import {
    getCurrentUserLovedFiles,
    getTotalLoveCountPerFile,
} from '../reaction/selectors'
import type { ImageGroup } from '../timeline/selectors'
import type { FileInformation } from '../uploader/reducer'
import {
    getAllCurrentFiles,
    getEnquedFiles,
    getPendingFiles,
    getUploadingFiles,
} from '../uploader/selectors'
import { UserActionsStatus } from '../userActions/reducer'
import { getUsersInfo } from '../users/selectors'
import {
    getViewportHeight,
    getViewportWidth,
    isMobileMode,
} from '../viewMode/selectors'
import { getFileMetadata } from '../fileMetadata/selectors'
import type { User } from '../users/reducer'
import { getSubscribersForJob } from '../subscribers/selectors'
import type { AlbumSortOrder } from './../albumList/reducer'
import { getSelectedFileIDsforJob } from './../selectedFiles/selectors'
import type { StateWithAlbumCreation } from './reducer'

export type AlbumFileComment = {
    commentID: CommentID
    fileID: FileID
    albumID: JobID
    text: string
    author: User
    timeCreated: Date
    currentUserCan: {
        editComment: boolean
        deleteComment: boolean
    }
    awaitsDecisionToBeDeleted: boolean
    isEditing: boolean
    isPendingServerEdit: boolean
    isPendingServerDelete: boolean
    hasBeenEdited: boolean
    editFailed: boolean
    deleteFailed: boolean
}

export type AlbumFile = CaptureFile & {
    addedBy_obj: User
    thumbURL?: string
    comments: AlbumFileComment[]
    ctime: number
    currentUserCan: {
        deleteFile: boolean
        setFileAsAlbumCoverPhoto: boolean
        addComment: boolean
        addToAlbum: boolean
    }
    isPendingNewComment: boolean
    lovedByCurrentUser: boolean
    totalLoveCount: number
}

export type Album = {
    id: JobID
    title: string
    owner: User
    files: AlbumFile[]
    sortOrder: AlbumSortOrder
    dateCreated: Date
    filesDateRange?: DateRange
    subscribersCount: number
    currentUserCan: AvailableActions
    isHealthy: boolean
    awaitsDecisionToBeDeleted: boolean
    awaitsServerResponseDelete: boolean
    deleteFailed: boolean
    isShared: boolean
    size: number
    hasHEIC: boolean
}

const unknownUser: User = { userID: 'unknown', name: '' }
const makeGetUserFunc =
    (users: DictionaryOf<User>) =>
    (userID: UserID): User =>
        users[userID] || unknownUser

const transformComment =
    (
        albumID: JobID,
        getUser: (u: UserID) => User,
        currentUser?: UserID,
        albumCreator?: UserID,
    ) =>
    (c: FileComment): AlbumFileComment => ({
        commentID: c.commentUUID,
        fileID: c.fileID,
        albumID,
        text: c.comment,
        author: getUser(c.userUUID),
        timeCreated: new Date(c.timestamp * 1000),
        currentUserCan: {
            editComment: c.userUUID === currentUser,
            deleteComment:
                c.userUUID === currentUser || currentUser === albumCreator,
        },
        awaitsDecisionToBeDeleted:
            c.userActionStatus === UserActionStatus.PENDING_USER__DELETE,
        isPendingServerDelete:
            c.userActionStatus === UserActionStatus.PENDING_SERVER__DELETE,
        deleteFailed:
            c.userActionStatus === UserActionStatus.SERVER_ERROR__DELETE,
        isEditing: c.userActionStatus === UserActionStatus.PENDING_USER__EDIT,
        isPendingServerEdit:
            c.userActionStatus === UserActionStatus.PENDING_SERVER__EDIT,
        hasBeenEdited: c.lastEditTimestamp !== undefined,
        editFailed: c.userActionStatus === UserActionStatus.SERVER_ERROR__EDIT,
    })

const albumFileComments = (
    file: ExtendedJobFile,
    getUser: (userID: UserID) => User,
    allComments: DictionaryOf<FileComment[]>,
    currentUser: UserID | undefined,
    jobOwnerID: UserID,
): AlbumFileComment[] => {
    return (allComments[file.fileID] || []).map(
        transformComment(file.jobID, getUser, currentUser, jobOwnerID),
    )
}

const albumFileLoveInfo = (
    file: ExtendedJobFile,
    currentUserLovedFiles: DictionaryOf<boolean>,
    totalLoveCountPerFile: DictionaryOf<number>,
) => {
    let lovedByCurrentUser = currentUserLovedFiles[file.fileID] || false
    let totalLoveCount = totalLoveCountPerFile[file.fileID] || 0

    if (file.pendingReaction === Reactions.None && lovedByCurrentUser) {
        lovedByCurrentUser = false
        totalLoveCount--
    }

    if (file.pendingReaction === Reactions.Love && !lovedByCurrentUser) {
        lovedByCurrentUser = true
        totalLoveCount++
    }

    return {
        lovedByCurrentUser,
        totalLoveCount,
    }
}

export const makeAlbumFiles = (
    jobFiles: CaptureFile[],
    users: DictionaryOf<User>,
    allComments: DictionaryOf<FileComment[]>,
    currentUserLovedFiles: DictionaryOf<boolean>,
    totalLoveCountPerFile: DictionaryOf<number>,
    currentUser: UserID | undefined,
    albumOwnerID: UserID,
    fileMetadata: DictionaryOf<FileMetadata>,
    canAddComment: boolean,
): AlbumFile[] => {
    const getUser = makeGetUserFunc(users)

    return jobFiles
        .map((file: CaptureFile): AlbumFile => {
            return {
                ...file,
                type: getFileType(file),
                addedBy_obj: getUser(file.addedBy),
                metadata: fileMetadata[file.fileID],
                comments: albumFileComments(
                    file,
                    getUser,
                    allComments,
                    currentUser,
                    albumOwnerID,
                ),
                ctime: file.ctime || file.mtime,
                currentUserCan: {
                    deleteFile:
                        file.addedBy === currentUser ||
                        albumOwnerID === currentUser,
                    setFileAsAlbumCoverPhoto: albumOwnerID === currentUser,
                    addComment: canAddComment,
                    addToAlbum: typeof currentUser !== 'undefined', // user is logged in
                },
                isPendingNewComment:
                    file.pendingCommentStatus === CommentStatus.PENDING,
                ...albumFileLoveInfo(
                    file,
                    currentUserLovedFiles,
                    totalLoveCountPerFile,
                ),
            }
        })
        .filter(
            (file) =>
                file.type === FileTarget.Pictures ||
                file.type === FileTarget.Movies,
        )
}

const getAvailableActionsForJob = (
    state: StateOfSelector<typeof getAvailableActions>,
    jobId: JobID,
) => getAvailableActions(state)[jobId]

const albumSortMethods: Record<
    AlbumSortOrder,
    (a: ExtendedJobFile, b: ExtendedJobFile) => number
> = {
    ADDED: (a, b) => b.timestamp - a.timestamp,
    OLDEST_TAKEN: (a, b) => (a.ctime || a.mtime) - (b.ctime || b.mtime),
    NEWEST_TAKEN: (a, b) => (b.ctime || b.mtime) - (a.ctime || a.mtime),
}
export const getAlbumSortOrder = createSelector(
    getJobInformation,
    (jobInfo): AlbumSortOrder => {
        if (
            jobInfo === undefined ||
            jobInfo.type !== 'story' ||
            jobInfo.sort_order === undefined
        )
            return 'ADDED'
        return jobInfo.sort_order
    },
)

const getSortedAlbumFiles = createSelector(
    getCaptureFilesForJob,
    getAlbumSortOrder,
    (jobFiles: CaptureFile[], sortOrder: AlbumSortOrder): CaptureFile[] => {
        return [...jobFiles].sort(albumSortMethods[sortOrder])
    },
)

export const getAlbumFiles = createSelector(
    getJobInfoElement,
    getSortedAlbumFiles,
    getCommentsGroupedByImage,
    getUsersInfo,
    getCurrentUserUUID,
    getCurrentUserLovedFiles,
    getTotalLoveCountPerFile,
    getFileMetadata,
    getAvailableActionsForJob,
    (
        jobInfoElement: JobInfoElement | undefined,
        jobFiles: CaptureFile[],
        allComments: DictionaryOf<FileComment[]>,
        users: DictionaryOf<User>,
        currentUserUUID: UserID | undefined,
        currentUserLovedFiles: DictionaryOf<boolean>,
        totalLoveCountPerFile: DictionaryOf<number>,
        metadata: DictionaryOf<FileMetadata>,
        availableActionsForJob: AvailableActions,
    ): AlbumFile[] => {
        // If job info is not fetched; the album is not ready to be composed
        if (
            jobInfoElement === undefined ||
            jobInfoElement.jobInfo === undefined
        ) {
            return []
        }

        const jobInfo = jobInfoElement.jobInfo

        return makeAlbumFiles(
            jobFiles,
            users,
            allComments,
            currentUserLovedFiles,
            totalLoveCountPerFile,
            currentUserUUID,
            jobInfo.owner,
            metadata,
            availableActionsForJob.addComment,
        )
    },
)

export const getAlbumFilesExtensions = createSelector(
    getAlbumFiles,
    (albumFiles): string[] =>
        albumFiles
            .map((file) => getFileExtension(file.path))
            .filter((e): e is string => e !== undefined),
)

export type AlbumFilter = 'imagesOnly' | 'all'
const AlbumFilters: Record<AlbumFilter, (f: AlbumFile) => boolean> = {
    imagesOnly: (f) => f.type === FileTarget.Pictures,
    all: (_f) => true,
}
export const getFilteredAlbumFiles = createSelector(
    getAlbumFiles,
    (
        _state: StateOfSelector<typeof getAlbumFiles>,
        _jobID: JobID,
        filterBy?: AlbumFilter,
    ): AlbumFilter => filterBy || 'all',
    (files, filter) => files.filter(AlbumFilters[filter]),
)

export type DateRange = { start: Date; end: Date }

export const getAlbumFilesDateRange = createSelector(
    getAlbumFiles,
    getAlbumSortOrder,
    (files, currentSortOrder): DateRange | undefined => {
        if (files.length === 0) {
            return undefined
        }

        if (currentSortOrder !== 'NEWEST_TAKEN') {
            const { min, max } = files.reduce<{ min: number; max: number }>(
                (c, { ctime }) => ({
                    min: Math.min(c.min, ctime),
                    max: Math.max(c.max, ctime),
                }),
                { max: -Infinity, min: Infinity },
            )
            return { start: new Date(min * 1000), end: new Date(max * 1000) }
        }

        return {
            start: new Date(files[files.length - 1].ctime * 1000),
            end: new Date(files[0].ctime * 1000),
        }
    },
)

export const makeAlbum = (
    id: JobID,
    title: string,
    ownerID: UserID,
    files: AlbumFile[],
    sortOrder: AlbumSortOrder,
    filesDateRange: DateRange | undefined,
    ctime: number, // seconds
    currentUserCan: AvailableActions,
    users: DictionaryOf<User>,
    subscribers: User[] | undefined,
    deleteStatus: UserActionsStatus | undefined,
    isShared: boolean,
    size: number,
    hasHeic: boolean,
): Album => {
    const getUser = makeGetUserFunc(users)

    let isHealthy = subscribers ? subscribers.length > 1 : false
    let i = files.length
    while (!isHealthy && i > 0) {
        i-- // before accessing to offset 0-indexing
        isHealthy = files[i].comments.some((c) => c.author.userID !== ownerID)
    }

    return {
        id,
        title,
        owner: getUser(ownerID),
        files,
        sortOrder,
        filesDateRange,
        dateCreated: new Date(ctime * 1000),
        subscribersCount: isShared && subscribers ? subscribers.length : 0,
        currentUserCan,
        isHealthy,
        awaitsDecisionToBeDeleted:
            deleteStatus === UserActionsStatus.PENDING_USER,
        awaitsServerResponseDelete:
            deleteStatus === UserActionsStatus.PENDING_SERVER,
        deleteFailed: deleteStatus === UserActionsStatus.SERVER_ERROR,
        isShared,
        size,
        hasHEIC: hasHeic,
    }
}

export const getAlbum = createSelector(
    getJobInfoElement,
    getAlbumFiles,
    getAlbumFilesDateRange,
    getUsersInfo,
    getSubscribersForJob,
    getAvailableActionsForJob,
    (
        jobInfoElement: JobInfoElement | undefined,
        files: AlbumFile[],
        filesDateRange: DateRange | undefined,
        users: DictionaryOf<User>,
        subscribers: User[] | undefined,
        currentUserCan: AvailableActions,
    ): Album | undefined => {
        // If job info is not fetched; the album is not ready to be composed
        if (
            jobInfoElement === undefined ||
            jobInfoElement.jobInfo === undefined ||
            jobInfoElement.jobInfo.type !== 'story'
        ) {
            return
        }

        const jobInfo = jobInfoElement.jobInfo
        return makeAlbum(
            jobInfoElement.jobID,
            jobInfo.title || _('unnamed_album_placeholder'),
            jobInfo.owner,
            files,
            jobInfo.sort_order || 'ADDED',
            filesDateRange,
            jobInfo.ctime || jobInfo.mtime,
            currentUserCan,
            users,
            subscribers,
            undefined,
            jobInfo.isShared,
            jobInfo.size || 0,
            jobInfo.has_heic || false,
        )
    },
)

export const getLastFetchedChangesSerial = (
    state: StateOfSelector<typeof getJobInfoElement>,
    jobID: JobID,
): number | undefined => {
    const jobInfo = getJobInfoElement(state, jobID)
    return jobInfo?.lastSeenSerial
} // if returns undefined
export const defaultAlbumViewMode = 'flow'
export type AlbumViewMode = 'flow' | 'grid'
export const getAlbumViewMode = (
    state: StateOfSelector<typeof getJobInfoElement>,
    albumID: JobID,
): AlbumViewMode => {
    const jobInfoElement = getJobInfoElement(state, albumID)
    return jobInfoElement?.viewMode || defaultAlbumViewMode
}

export const getTitleForAlbum = createSelector(
    getJobInfoElement,
    (jobInfoElement: JobInfoElement | undefined): string | undefined => {
        if (
            jobInfoElement?.jobInfo &&
            jobInfoElement?.jobInfo.type === 'story'
        ) {
            return jobInfoElement.jobInfo.title
        }
    },
)

export const getAlbumOwner = createSelector(
    getJobInfoElement,
    (jobInfoElement: JobInfoElement | undefined): UserID | undefined => {
        if (jobInfoElement?.jobInfo) {
            return jobInfoElement.jobInfo.owner
        }
    },
)
export const isAlbumOwner = createSelector(
    getAlbumOwner,
    getCurrentUserUUID,
    (ownerID, userID): boolean => ownerID === userID,
)
export const isPublicAlbum = createSelector(
    getJobInfoElement,
    (jobInfoElement: JobInfoElement | undefined): boolean => {
        if (
            jobInfoElement?.jobInfo &&
            jobInfoElement?.jobInfo.type === 'story'
        ) {
            return jobInfoElement.jobInfo.isShared
        }

        return false
    },
)

export type PendingAlbum = {
    title: string
    pendingFiles: FileInformation[]
    uploadedFiles: AlbumFile[]
    isShared: boolean
}

export const getPendingAlbum = createSelector(
    getAlbum,
    getAllJobFiles,
    getAllCurrentFiles,
    (
        album,
        jobFiles: ExtendedJobFile[],
        allPendingFiles: FileInformation[],
    ): PendingAlbum | undefined => {
        if (album) {
            const albumFileList: DictionaryOf<boolean> = {}
            jobFiles.forEach((file) => {
                albumFileList[file.fileID] = true
            })
            const pendingFiles = allPendingFiles.filter((file) => {
                return (
                    file.targetJob === album.id &&
                    (file.fileUUID === undefined ||
                        !albumFileList[file.fileUUID])
                )
            })
            return {
                title: album.title === '__new_album__' ? '' : album.title,
                pendingFiles,
                uploadedFiles: album.files,
                isShared: album.isShared,
            }
        }
    },
)

export const getComments = (
    state: StateOfSelector<typeof getUsersInfo> &
        StateOfSelector<typeof getFileByID> &
        StateOfSelector<typeof getJobInfoElement> &
        StateOfSelector<typeof getCommentsGroupedByImage> &
        StateOfSelector<typeof getCurrentUserUUID>,
    fileID: FileID,
): AlbumFileComment[] => {
    const users = getUsersInfo(state)
    const file = getFileByID(state, fileID)
    if (file === undefined) {
        return []
    }
    const jobInfoElement = getJobInfoElement(state, file.jobID)
    return (getCommentsGroupedByImage(state)[fileID] || []).map(
        transformComment(
            file.jobID,
            (user: UserID) => (users[user] ? users[user] : unknownUser),
            getCurrentUserUUID(state),
            jobInfoElement?.jobInfo?.owner,
        ),
    )
}

export const canCurrentUserCommentOnAlbum = (
    state: StateOfSelector<typeof getAlbum>,
    albumID: JobID,
): boolean => {
    const album = getAlbum(state, albumID)
    if (album) {
        return album.currentUserCan.addComment
    }
    return false
}

const albumGridStyleMobile = {
    elementWidth: 176,
    elementHeight: 176,
    elementSpaceAround: 2,
    headerHeight: 30,
    headerBottomGap: 12,
    groupBottomGap: 0,
}
const albumGridStyleDesktop = {
    elementWidth: 228,
    elementHeight: 228,
    elementSpaceAround: 2,
    headerHeight: 30,
    headerBottomGap: 8,
    groupBottomGap: 0,
}

const albumHeaderElementHeight = 180
export const maxAlbumContainerWidth = 928

export const getAlbumImageGroupStyle = createSelector(
    getViewportWidth,
    isMobileMode,
    (vpWidth, isMobile): ImageGroupStyle =>
        makeImageGroupStyle(
            vpWidth,
            isMobile,
            {
                desktop: albumGridStyleDesktop,
                mobile: albumGridStyleMobile,
            },
            maxAlbumContainerWidth,
        ),
)

export type GridOffset = { offset: number; fileIndex: number }
export const getLastViewedGridOffset = createSelector(
    getAlbumFiles,
    getLastSeenElementForJob,
    getAlbumImageGroupStyle,
    getViewportHeight,
    (
        files,
        lastSeenFileID,
        gridStyle,
        viewportHeight,
    ): GridOffset | undefined => {
        if (lastSeenFileID) {
            const fileIndex = files.map((f) => f.fileID).indexOf(lastSeenFileID)
            const rowHeight =
                gridStyle.elementHeight + gridStyle.elementSpaceAround * 2
            const offset =
                albumHeaderElementHeight +
                Math.floor(fileIndex / calcImagesPerRow(gridStyle)) *
                    rowHeight +
                rowHeight / 2 -
                viewportHeight / 2

            return { offset, fileIndex }
        }
    },
)

export const makeAlbumImageGroup = (
    files: AlbumFile[],
    albumInfo: StoryJobInfo | undefined,
    groupStyle: ImageGroupStyle,
): ImageGroup => ({
    groupKey: albumInfo?.title || '',
    images: files,
    height: computeImageGroupHeight(files.length, groupStyle),
    position: groupStyle.verticalOffset || 0,
})

export const getAlbumImageGroup = createSelector(
    getFilteredAlbumFiles,
    getStoryJobInformation,
    getAlbumImageGroupStyle,
    makeAlbumImageGroup,
)

export const getAlbumGroupWithSelection = createSelector(
    getAlbumImageGroup,
    getSelectedFileIDsforJob,
    (group, selectedFiles): ImageGroup => ({
        ...group,
        selectionStatus: getGroupSelectionStatus(
            selectedFiles.length,
            group.images.length,
        ),
    }),
)

export const getUploadFlag = (
    state: StateWithAlbumCreation,
): boolean | undefined => state.albumCreation.createdFromUpload

export const getAlbumUploadingStatus = createSelector(
    getPendingFiles,
    getEnquedFiles,
    getUploadingFiles,
    (pendingFiles, enquedFiles, uploadingFiles): boolean => {
        return (
            pendingFiles.length === 0 &&
            enquedFiles.length === 0 &&
            uploadingFiles.length === 0
        )
    },
)
