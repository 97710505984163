/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    CAPBAKEmptyTrashCanGetParams,
    CAPBAKEmptyTrashCanPostParams,
    CAPBAKHTTPValidationError,
} from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class EmptyTrashCan<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * @description empty_trash_can for a given file
     *
     * @tags trash_can
     * @name EmptyTrashCanGet
     * @summary Get Empty Trash Can
     * @request GET:/empty_trash_can
     */
    emptyTrashCanGet = (
        query: CAPBAKEmptyTrashCanGetParams,
        params: RequestParams = {},
    ) =>
        this.request<any, CAPBAKHTTPValidationError>({
            path: `/empty_trash_can`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description empty_trash_can for a given file
     *
     * @tags trash_can
     * @name EmptyTrashCanPost
     * @summary Post Empty Trash Can
     * @request POST:/empty_trash_can
     */
    emptyTrashCanPost = (
        query: CAPBAKEmptyTrashCanPostParams,
        params: RequestParams = {},
    ) =>
        this.request<any, CAPBAKHTTPValidationError>({
            path: `/empty_trash_can`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
}
