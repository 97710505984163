import { _ } from '@capture/client/assets/localization/util'
import { RequireLoginActionPage } from '@capture/client/components/Info/RequireLoginActionPage'
import { PRODUCT_NAME } from '@capture/client/config/constants'
import type { Dispatch } from '@capture/client/state/common/actions'
import { JobUserLoggedIn } from '@capture/client/state/jobInfo/actions'
import * as React from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { LoadingPage } from '@capture/client/components/Common/LoadingPage'
import { RippleLoader } from '@capture/client/components/Common/RippleLoader'
import { ShareNotFoundPage } from '@capture/client/components/Info/AlbumNotFound'
import { ProvidePasswordSharePage } from '@capture/client/components/Info/ProvidePassword'
import { Pages } from '@capture/client/routing'
import { JobInfoStatus } from '@capture/client/state/jobInfo/reducer'
import { getStatusOfJob } from '@capture/client/state/jobInfo/selectors'
import type { Share } from '@capture/client/state/share/selectors'
import { getShare } from '@capture/client/state/share/selectors'
import { b64ToUuid } from '@capture/client/utilities/uuid'
import { ShareReceiverPage } from './ShareReceiverPage'

type OwnProps = {
    component: React.ComponentType<{ share: Share }>
    shareID: JobID
}
type StateProps = {
    fetchStatus: JobInfoStatus
    share: Share | undefined
}
type DispatchProps = {
    onLogin: () => void
}
type Props = OwnProps & StateProps & DispatchProps

class _ShareContainer extends React.Component<Props> {
    public render() {
        const InnerComp = this.props.component
        switch (this.props.fetchStatus) {
            case JobInfoStatus.FETCHED:
            case JobInfoStatus.PARTIALLY_FETCHED:
                return this.props.share ? (
                    <InnerComp share={this.props.share} />
                ) : (
                    <RippleLoader />
                )
            case JobInfoStatus.NOT_FOUND:
            case JobInfoStatus.FETCH_FAILED:
                return <ShareNotFoundPage />
            case JobInfoStatus.PASSWORD_REQUIRED:
                return <ProvidePasswordSharePage shareID={this.props.shareID} />
            case JobInfoStatus.LOGIN_REQUIRED:
                return (
                    <RequireLoginActionPage
                        text={_('needs_login_to_view_share').replace(
                            '%product_name%',
                            PRODUCT_NAME,
                        )}
                        targetAfterLogin={Pages.Share(this.props.shareID)}
                        afterLoginAction={this.props.onLogin}
                        showCreateAccount={false}
                    />
                )

            case JobInfoStatus.NOT_STARTED:
            case JobInfoStatus.PENDING:
            default:
                return <LoadingPage />
        }
    }
}

const mapStateToProps = (
    state: StateOfSelector<typeof getStatusOfJob> &
        StateOfSelector<typeof getShare>,
    ownProps: OwnProps,
): StateProps => ({
    fetchStatus: getStatusOfJob(state, ownProps.shareID),
    share: getShare(state, ownProps.shareID),
})

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => ({
    onLogin: () => dispatch(JobUserLoggedIn(ownProps.shareID)),
})

export const ShareContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(_ShareContainer)

export const ShareMountUnpackedUUID: React.FunctionComponent = () => {
    const { shareID } = useParams<{ shareID: string }>()
    if (shareID) {
        return (
            <ShareContainer component={ShareReceiverPage} shareID={shareID} />
        )
    } else {
        return null
    }
}
export const ShareMount: React.FunctionComponent = () => {
    const { shareIDB64 } = useParams<{ shareIDB64: string }>()
    if (shareIDB64) {
        return (
            <ShareContainer
                component={ShareReceiverPage}
                shareID={b64ToUuid(shareIDB64)}
            />
        )
    } else {
        return null
    }
}
