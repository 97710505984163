import type { Store } from '@reduxjs/toolkit'
import { connectRecentFilesSyncer } from '~/state/recentFiles/recentFilesSyncer'
import { connectFileMetadataSyncer } from './FileMetadataSyncer'
import { connectJobChangesSyncer } from './JobChangesSyncer'
import { connectJobInfoSyncer } from './JobInfoSyncer'
import { connectTimelineChunkSyncer } from './TimelineChunkSyncer'
import { connectCastSyncer } from './CastSyncer'

export const connectSyncers = (store: Store) => {
    connectJobInfoSyncer(store)
    connectJobChangesSyncer(store)
    connectTimelineChunkSyncer(store)
    connectFileMetadataSyncer(store)
    connectRecentFilesSyncer(store)
    connectCastSyncer(store)
}
