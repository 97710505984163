import type { SVGProps } from 'react'
const SvgExpand = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill={props.color ?? 'currentColor'}
            d="M6.4 19H8c.283 0 .52.096.713.288.191.191.287.429.287.712s-.096.52-.287.712A.967.967 0 0 1 8 21H4a.967.967 0 0 1-.712-.288A.968.968 0 0 1 3 20v-4c0-.283.096-.52.288-.713A.967.967 0 0 1 4 15c.283 0 .52.096.713.287.191.192.287.43.287.713v1.6l2.4-2.4a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7zM19 6.4l-2.4 2.4a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7L17.6 5H16a.968.968 0 0 1-.713-.287A.967.967 0 0 1 15 4c0-.283.096-.52.287-.712A.968.968 0 0 1 16 3h4c.283 0 .52.096.712.288.192.191.288.429.288.712v4c0 .283-.096.52-.288.713A.968.968 0 0 1 20 9a.968.968 0 0 1-.712-.287A.967.967 0 0 1 19 8z"
        />
    </svg>
)
export default SvgExpand
