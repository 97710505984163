import styled from 'styled-components'
import { useSelector } from 'react-redux'
import SvgArrowBack from '@capture/capture-components/src/icons/ArrowBack'
import { TwoAreasTopNavBar } from '~/components/Navigation/TwoAreasTopNavBar'
import { HeaderText } from '~/components/Common/StandardPageElements'
import { IconTextButton } from '~/components/Common/IconTextButton'
import { _ } from '~/assets/localization/util'
import { fontSize } from '~/assets/styleConstants'
import { goBack } from '~/utilities/navigation'
import { getImageGroupStyle } from '~/state/viewMode/selectors'
import { PageWrapper } from '../Common/PageWrapper'
import { ConnectedDevicesComponent } from './ConnectedDevices'

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: ${(props: { width: number }) => props.width}px;
    align-items: start;
`

const Header = styled(HeaderText)`
    width: 100%;
    text-align: left;
`

export const LoggedInDevicesPage: React.FunctionComponent = () => {
    const width = useSelector(getImageGroupStyle).width

    const leftButton = (
        <IconTextButton
            onClick={goBack}
            text={_('settings')}
            icon={SvgArrowBack}
            fontSize={fontSize.small_14}
        />
    )

    const navBar = <TwoAreasTopNavBar left={leftButton} />

    return (
        <PageWrapper navBar={navBar}>
            <ContentWrapper width={width}>
                <Header>{_('connected_devices')}</Header>
                <ConnectedDevicesComponent />
            </ContentWrapper>
        </PageWrapper>
    )
}
