import React, { useEffect } from 'react'
import styled from 'styled-components'
import { _ } from '~/assets/localization/util'
import { trackEventInternal } from '~/analytics/eventTracking'
import { colors, zIndex } from '~/assets/styleConstants'
import { stopPropagation } from '~/utilities/eventHandling'

const PositionedWrapper = styled.div`
    position: absolute;
    z-index: ${zIndex.hint};
    color: white;
    background: ${colors.captureBlue};
    max-width: 360px;
    font-size: 13px;
    padding: 12px;
    border-radius: 4px;
    cursor: default;
    top: ${(p: { isInSelect: boolean }) => (p.isInSelect ? '92' : '79')}px;
    right: ${(p: { isInSelect: boolean }) => (p.isInSelect ? '120' : '87')}px;
    &::before {
        content: '';
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 12px solid ${colors.captureBlue};
        position: absolute;
        transform: translate(0px, 32px) rotate(90deg);
        top: 0;
        left: 98%;
        z-index: ${zIndex.hint};
    }
    & > div {
        white-space: nowrap;
    }
`
const ButtonsWrapper = styled.div`
    border-top: 1px solid rgba(255, 255, 255, 0.6);
    margin-top: 10px;
    padding-top: 10px;
    text-align: right;
    & > span {
        cursor: pointer;
        padding: 8px;
        margin: -8px;
    }
`
type Props = {
    onGotIt: () => void
    isInSelectMode: boolean
}
export const FastScrollerTooltip: React.FC<Props> = ({
    onGotIt,
    isInSelectMode,
}) => {
    const gotIt = () => {
        onGotIt()
        trackEventInternal('timeline_fastscroller_tooltip_ok')
    }

    useEffect(() => {
        trackEventInternal('timeline_fastscroller_tooltip_seen')
    }, [])
    return (
        <PositionedWrapper
            onClick={stopPropagation}
            role="presentation"
            isInSelect={isInSelectMode}>
            <div>
                {isInSelectMode
                    ? _('fastscroller_select_hint_body')
                    : _('fastscroller_hint_body')}
            </div>
            <ButtonsWrapper
                onClick={gotIt}
                onKeyUp={gotIt}
                role="button"
                tabIndex={0}>
                <span>
                    {_('ok')}, {_('got_it').toLowerCase()}
                </span>
            </ButtonsWrapper>
        </PositionedWrapper>
    )
}
