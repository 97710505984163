import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import SvgArrowBack from '@capture/capture-components/src/icons/ArrowBack'
import type { Dispatch } from '~/state/common/actions'
import { _ } from '~/assets/localization/util'
import { setProfilePicture } from '~/API/currentUser'
import { initTimeline } from '~/API/job'
import { fontSize } from '~/assets/styleConstants'
import type { CaptureFile } from '~/state/files/selectors'
import { getCaptureFilesByID } from '~/state/files/selectors'
import type { ImageGroup } from '~/state/timeline/selectors'
import {
    getTimelineImageGroupsOnlyImages,
    getTimelineJobID,
    getTimelineScrollerGroupsOnlyImages,
} from '~/state/timeline/selectors'
import { ProfilePictureChangeFailed } from '~/state/users/actions'
import {
    getImageGroupStyle,
    getViewportWidth,
    isMobileMode,
} from '~/state/viewMode/selectors'
import { getCroppedImage } from '~/utilities/image'
import type { ImageGroupStyle } from '~/utilities/imageGroupStyle'
import { goBack } from '~/utilities/navigation'
import type { CropArea } from '../Common/CropPlacement'
import { CropPlacement } from '../Common/CropPlacement'
import { IconButton } from '../Common/IconTextButton'
import { PageWrapper } from '../Common/PageWrapper'
import { ThreeAreasNavBar } from '../Navigation/ThreeAreasNavBar'
import { ImageGroupList } from '../Timeline/ImageGroupList'
import type { ScrollerGroup } from '../TimelineScroller/FastScrollerContent'
import { TimelineScrollerPlacement } from '../TimelineScroller/TimelineScrollerPlacement'

const Title = styled.div`
    font-size: ${fontSize.medium_18};
`
type StateProps = {
    timelineJobID: JobID | undefined
    imagesGrouped: ImageGroup[]
    imageGroupStyle: ImageGroupStyle
    scrollerGroups: ScrollerGroup[]
    viewportWidth: number
    isMobileMode: boolean
    file: (fileID: FileID, jobID: JobID) => CaptureFile[] | undefined
}
type DispatchProps = {
    fetchTimeline: (job?: string) => void
    doSetProfilePicture: (file: Blob) => void
    setProfilePictureFailed: () => void
}
type Props = StateProps & DispatchProps
type ComponentState = { selectedFile: CaptureFile | undefined }

class SelectProfilePhotoPage extends React.Component<Props, ComponentState> {
    public state: ComponentState = {
        selectedFile: undefined,
    }

    private doHandleFile = (fileID: FileID) => {
        const file =
            this.props.timelineJobID &&
            this.props.file(fileID, this.props.timelineJobID)
        if (file) {
            this.setState({ selectedFile: file[0] })
        }
    }
    private setCropping = (cropData: CropArea) => {
        if (this.state.selectedFile) {
            getCroppedImage(this.state.selectedFile.thumbURLLarge, cropData)
                .then(this.props.doSetProfilePicture)
                .catch(this.props.setProfilePictureFailed)
        }
    }
    private cancelCropping = () => {
        this.setState({ selectedFile: undefined })
    }
    public componentDidMount() {
        if (this.props.imagesGrouped.length === 0) {
            this.props.fetchTimeline(this.props.timelineJobID)
        }
    }
    public render() {
        const title = <Title>{_('update_profile_picture')}</Title>
        const navBar = (
            <ThreeAreasNavBar
                left={<IconButton onClick={goBack} icon={SvgArrowBack} />}
                middle={title}
                right={''}
            />
        )

        const croppingPlacement = this.state.selectedFile && (
            <CropPlacement
                fileURL={this.state.selectedFile.thumbURLLarge}
                viewportSize={{ width: 310, height: 310 }}
                viewportType={'circle'}
                onCancel={this.cancelCropping}
                onCroppingComplete={this.setCropping}
            />
        )

        return (
            <PageWrapper navBar={navBar} cyKey="SelectProfilePhotoPage">
                <div style={{ marginTop: 24 }}>
                    <ImageGroupList
                        imagesGrouped={this.props.imagesGrouped}
                        groupStyle={this.props.imageGroupStyle}
                        isInSelectMode={false}
                        disableSelection={true}
                        onItemClickOverride={this.doHandleFile}
                    />
                </div>
                <TimelineScrollerPlacement
                    scrollerGroups={this.props.scrollerGroups}
                    imageGroupStyle={this.props.imageGroupStyle}
                    isMobileMode={this.props.isMobileMode}
                    viewportWidth={this.props.viewportWidth}
                />
                {croppingPlacement}
            </PageWrapper>
        )
    }
}

type SelectProfilePhotoPageStoreState = StateOfSelector<
    typeof getTimelineJobID
> &
    StateOfSelector<typeof getTimelineImageGroupsOnlyImages> &
    StateOfSelector<typeof getTimelineScrollerGroupsOnlyImages> &
    StateOfSelector<typeof getImageGroupStyle> &
    StateOfSelector<typeof getViewportWidth> &
    StateOfSelector<typeof isMobileMode> &
    StateOfSelector<typeof getCaptureFilesByID>

const mapStateToProps = (
    state: SelectProfilePhotoPageStoreState,
): StateProps => ({
    timelineJobID: getTimelineJobID(state),
    imagesGrouped: getTimelineImageGroupsOnlyImages(state),
    scrollerGroups: getTimelineScrollerGroupsOnlyImages(state),
    imageGroupStyle: getImageGroupStyle(state),
    viewportWidth: getViewportWidth(state),
    isMobileMode: isMobileMode(state),
    file: (fileID: FileID, jobID: JobID) =>
        getCaptureFilesByID(state, jobID, [fileID]),
})
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    fetchTimeline: async (job?: string) => initTimeline(dispatch, job),
    doSetProfilePicture: (file: Blob) => {
        setProfilePicture(dispatch, file)
        goBack()
    },
    setProfilePictureFailed: () => dispatch(ProfilePictureChangeFailed()),
})
export const SelectProfilePicturePage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SelectProfilePhotoPage)
