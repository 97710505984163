import type { SVGProps } from 'react'
const SvgSadFace = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill={props.color ?? 'currentColor'}
            d="M15.5 11c.417 0 .77-.146 1.063-.437.291-.292.437-.646.437-1.063 0-.417-.146-.77-.437-1.062A1.447 1.447 0 0 0 15.5 8c-.417 0-.77.146-1.062.438A1.446 1.446 0 0 0 14 9.5c0 .417.146.77.438 1.063.291.291.645.437 1.062.437m-7 0c.417 0 .77-.146 1.063-.437.291-.292.437-.646.437-1.063 0-.417-.146-.77-.437-1.062A1.447 1.447 0 0 0 8.5 8c-.417 0-.77.146-1.062.438A1.447 1.447 0 0 0 7 9.5c0 .417.146.77.438 1.063.291.291.645.437 1.062.437m3.5 2.5c-1.133 0-2.162.32-3.088.963A5.485 5.485 0 0 0 6.9 17h1.65a4.099 4.099 0 0 1 1.462-1.463A3.846 3.846 0 0 1 12 15c.717 0 1.38.18 1.988.537A4.1 4.1 0 0 1 15.45 17h1.65a5.485 5.485 0 0 0-2.013-2.537A5.294 5.294 0 0 0 12 13.5m0 8.5a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12a9.738 9.738 0 0 1-.788 3.9 10.098 10.098 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0 1 12 22m0-2c2.233 0 4.125-.775 5.675-2.325C19.225 16.125 20 14.233 20 12c0-2.233-.775-4.125-2.325-5.675C16.125 4.775 14.233 4 12 4c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 2.233.775 4.125 2.325 5.675C7.875 19.225 9.767 20 12 20"
        />
    </svg>
)
export default SvgSadFace
