import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export type CursorType =
    | 'auto'
    | 'default'
    | 'none'
    | 'context-menu'
    | 'help'
    | 'pointer'
    | 'progress'
    | 'wait'
    | 'cell'
    | 'crosshair'
    | 'text'
    | 'vertical-text'
    | 'alias'
    | 'copy'
    | 'move'
    | 'no-drop'
    | 'not-allowed'
    | 'e-resize'
    | 'n-resize'
    | 'ne-resize'
    | 'nw-resize'
    | 's-resize'
    | 'se-resize'
    | 'sw-resize'
    | 'w-resize'
    | 'ew-resize'
    | 'ns-resize'
    | 'nesw-resize'
    | 'nwse-resize'
    | 'col-resize'
    | 'row-resize'
    | 'all-scroll'
    | 'zoom-in'
    | 'zoom-out'
    | 'grab'
    | 'grabbing'

export type CursorState = {
    type: CursorType
}

// Define the initial state using that type
const initialState: CursorState = {
    type: 'auto',
}

const cursorSlice = createSlice({
    name: 'cursor',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setCursorType: (state, action: PayloadAction<CursorType>) => {
            state.type = action.payload
        },
    },
})

export const { setCursorType } = cursorSlice.actions

export const getCursorState = (state: StateWithCursor) => state.cursor.type

export default cursorSlice.reducer

export const cursorReducerMapObj = {
    [cursorSlice.name]: cursorSlice.reducer,
}

export type StateWithCursor = StateOfReducerMapObj<typeof cursorReducerMapObj>
