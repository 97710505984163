import type { ServiceDict } from '~/API/externals'
import type { ConfigurableAlbumDetails } from '../albumList/reducer'
import { createActionCreator } from '../common/actions'
import type { JobInfo } from './reducer'

export const StartedFetchingJobInfo = createActionCreator<JobID>(
    'STARTED_FETCHING_JOB_INFO',
)
export const FetchedJobHosts = createActionCreator<{
    job: JobID
    hosts: ServiceDict
}>('FETCHED_JOB_HOSTS')

export type JobInfoReference = { job: JobID; info: JobInfo }
export const FetchedJobInfo =
    createActionCreator<JobInfoReference>('FETCHED_JOB_INFO')
export const JobListWasFetched = createActionCreator<JobInfoReference[]>(
    'JOB_LIST_WAS_FETCHED',
)

export const JobNotFound = createActionCreator<JobID>('JOB_NOT_FOUND')
export const JobRequiresPassword = createActionCreator<JobID>(
    'JOB_REQUIRES_PASSWORD',
)
export const JobRequiresLoggedInUser = createActionCreator<JobID>(
    'JOB_REQUIRES_LOGGED_IN',
)
export const JobUserLoggedIn = createActionCreator<JobID>('JOB_USER_LOGGED_IN')
export const ErrorWhenFetchingJobInfo = createActionCreator<JobID>(
    'ERROR_WHEN_FETCHING_JOB_INFO',
) //  Network error, other unknown errors

export const JobDeletionStarted = createActionCreator<JobID>(
    'JOB_DELETION_STARTED',
)
export const JobWasDeleted = createActionCreator<JobID>('JOB_WAS_DELETED')
export const JobDeletionFailed = createActionCreator<JobID>(
    'JOB_DELETION_FAILED',
)

export const JobPasswordProvided = createActionCreator<{
    job: JobID
    password: string
}>('JOB_PASSWORD_PROVIDED')

type ChangeableKeys = keyof ConfigurableAlbumDetails
type _ChangeJobPropertyPayload<T extends ChangeableKeys> = {
    job: JobID
    property: T // only properties are changeable allowed by backend
    value: ConfigurableAlbumDetails[T]
}
type ChangeJobPropertyPayload = _ChangeJobPropertyPayload<ChangeableKeys>

export const ChangeJobPropertySucceeded =
    createActionCreator<ChangeJobPropertyPayload>(
        'CHANGE_JOB_PROPERTY_SUCCEEDED',
    )
export const ChangeJobPropertyFailed = createActionCreator<{
    job: JobID
    property: ChangeableKeys
}>('CHANGE_JOB_PROPERTY_FAILED')
