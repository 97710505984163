import type { ImageGroup } from '~/state/timeline/selectors'
import { withoutTheUndefined } from '~/utilities/arrayUtils'
import type { GroupSelectionStatus, ScrollerGroup } from './FastScrollerContent'

export const getGroupSelectionStatus = (
    selectedFileCount: number,
    totalFiles: number,
): GroupSelectionStatus => {
    if (selectedFileCount === 0) {
        return 'none-selected'
    }

    if (selectedFileCount === totalFiles) {
        return 'all-selected'
    }

    return 'some-selected'
}

const getMonthSelectionStatus = (
    files: FileID[],
    checkFileSelectionMethod: (file: FileID) => boolean,
) => {
    const selectedFileCount = files.filter(checkFileSelectionMethod).length
    return getGroupSelectionStatus(selectedFileCount, files.length)
}

export const makeTimelineScrollerGroups = (
    groupList: ImageGroup[],
    checkFileSelectionMethod?: (file: FileID) => boolean,
): ScrollerGroup[] => {
    const yearList: DictionaryOf<ScrollerGroup> = {}
    const years: string[] = []

    groupList.forEach((group) => {
        const [year, _month] = group.groupKey.split('-')

        if (yearList[year] === undefined) {
            years.push(year)
            const groupsInYear = withoutTheUndefined(groupList).filter(
                (group) => group.groupKey.split('-')[0] === year,
            )
            yearList[year] = {
                groupKey: year,
                groupItems: [],
                groupSelectionStatus: checkFileSelectionMethod
                    ? getGroupSelectionStatus(
                          groupsInYear
                              .map((group) => withoutTheUndefined(group.images))
                              .reduce((pre, cur) => pre.concat(cur), [])
                              .map((f) => f.fileID)
                              .filter(checkFileSelectionMethod).length,
                          groupsInYear.reduce(
                              (pre, cur) => pre + cur.images.length,
                              0,
                          ),
                      )
                    : undefined,
            }
        }

        yearList[year].groupItems.push({
            itemKey: group.groupKey,
            groupSelectionStatus: checkFileSelectionMethod
                ? getMonthSelectionStatus(
                      withoutTheUndefined(group.images).map((i) => i.fileID),
                      checkFileSelectionMethod,
                  )
                : undefined,
            pageYStart: group.position,
            pageYEnd: group.position + group.height,
        })
    })

    return years.map((y) => yearList[y])
}
