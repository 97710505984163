import type { IconProps } from '.'

/* tslint:disable:max-line-length jsx-self-close */
export const EmptyIcon: React.FunctionComponent<IconProps> = (props) => (
    <svg
        width={props.size}
        height={props.size}
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    />
)
