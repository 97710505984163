import styled from 'styled-components'
import { mediaQueries } from '~/assets/styleConstants'
import { FullscreenOverlay } from './FullscreenOverlay'
import { CenteredElementWrapper } from './LayoutComponents'
import { RippleLoader } from './RippleLoader'

const ComponentWrapper = styled(CenteredElementWrapper)`
    width: 375px;
    height: 20%;
    border-radius: 3px;

    background-color: white;
    padding: 16px;
    box-sizing: border-box;
    transition: height 0.3s;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${mediaQueries.mobile} {
        width: 250px;
    }
`
export const RippleLoaderModal: React.FunctionComponent<{
    loadingText: string
    loaderSize?: number
}> = (props) => {
    return (
        <FullscreenOverlay>
            <ComponentWrapper>
                <RippleLoader size={props.loaderSize} />
                {props.loadingText}
            </ComponentWrapper>
        </FullscreenOverlay>
    )
}
