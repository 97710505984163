import { _ } from '~/assets/localization/util'
import type { ExtendedJobFile } from '~/state/files/reducer'
import type { TimelineSection } from '~/state/timeline/selectors'

const months = [
    _('january'),
    _('february'),
    _('march'),
    _('april'),
    _('may'),
    _('june'),
    _('july'),
    _('august'),
    _('september'),
    _('october'),
    _('november'),
    _('december'),
]

const monthsAbbreviated = [
    _('jan'),
    _('feb'),
    _('mar'),
    _('apr'),
    _('may'),
    _('jun'),
    _('jul'),
    _('aug'),
    _('sep'),
    _('oct'),
    _('nov'),
    _('dec'),
]

export const getMonthFromNumberString = (month: string) =>
    getMonthFromNumber(parseInt(month, 10))
export const getShortMonthFromNumberString = (month: string) =>
    getShortMonthFromNumber(parseInt(month, 10))

export const getMonthFromNumber = (month: number) => months.at(month - 1)! // eslint-disable-line @typescript-eslint/no-non-null-assertion -- we know it's there

export const getShortMonthFromNumber = (month: number) =>
    monthsAbbreviated.at(month - 1)! // eslint-disable-line @typescript-eslint/no-non-null-assertion -- we know it's there

export const Month_asNumber = (m: MonthRef) => m.year * 12 + m.month
export const Month_fromNumber = (monthNum: number) => {
    const month = monthNum % 12 || 12
    return {
        year: (monthNum - month) / 12,
        month,
    }
}

export const Day_asString = (d: DayRef) => [d.year, d.month, d.day].join('-')

export const compareMonth = (a: MonthRef, b: MonthRef) =>
    Month_asNumber(b) - Month_asNumber(a)
export const areSameMonths = (a: MonthRef, b: MonthRef) =>
    a.year === b.year && a.month === b.month

export const compareDay = (a: DayRef, b: DayRef) =>
    100 * (Month_asNumber(b) - Month_asNumber(a)) + (b.day - a.day)

export const getFileTime = (f: Pick<ExtendedJobFile, 'ctime' | 'mtime'>) =>
    new Date((f.ctime || f.mtime) * 1000)

export const makeMonthGroupHeaderByFile = (
    f: Pick<ExtendedJobFile, 'ctime' | 'mtime'>,
): string => {
    return getFileTime(f).toISOString().slice(0, 7)
}

export const getFileMonth = (
    file: Pick<ExtendedJobFile, 'ctime' | 'mtime'>,
) => {
    const fileTime = getFileTime(file)
    return { month: fileTime.getMonth() + 1, year: fileTime.getFullYear() }
}

export const getFileDay = (
    file: Pick<ExtendedJobFile, 'ctime' | 'mtime'>,
): DayRef => {
    const fileTime = getFileTime(file)
    return {
        month: fileTime.getMonth() + 1,
        year: fileTime.getFullYear(),
        day: fileTime.getDate(),
    }
}

export const getSectionMonth = (section: TimelineSection): MonthRef => ({
    year: section.year,
    month: section.month,
})

const digitToString = (digit: number): string => {
    return ('00' + digit).slice(-2)
}

export const msToHMS = (ms: number): string => {
    const seconds = Math.floor(ms / 1000)
    const secDiff = Math.floor(seconds % 60)
    const minDiff = Math.floor((seconds % 3600) / 60)
    const hDiff = Math.floor(seconds / 3600)

    const timestamp = (hDiff > 0 ? [hDiff] : []).concat([minDiff, secDiff])
    return timestamp.map((d) => digitToString(d)).join(':')
}

export type TimeMeasurement = 'year' | 'month' | 'day' | 'hour' | 'minute'
export const timeIntervals: Record<TimeMeasurement, number> = {
    year: 60 * 60 * 24 * 365,
    month: 60 * 60 * 24 * 30,
    day: 60 * 60 * 24,
    hour: 60 * 60,
    minute: 60,
}
export const getTimeSince = (then: Date, now: Date, unit?: TimeMeasurement) => {
    const seconds = Math.round((now.getTime() - then.getTime()) / 1000)
    return Math.round(seconds / (unit ? timeIntervals[unit] : 1))
}

const getFileUploadTime = (f: Pick<ExtendedJobFile, 'timestamp'>) =>
    new Date(f.timestamp * 1000)

export const dayInMs = 1000 * 60 * 60 * 24

/**
 * Utility function to evaluate if a file is uploaded recently, based on the number of days and current date
 * @param file The file that must be evaluated
 * @param days Number of days that must pass for the file not to be considered recent
 */
export const isFileRecent = (
    file: Pick<ExtendedJobFile, 'timestamp'>,
    days: number,
) => {
    const fileUploadDate = getFileUploadTime(file)
    fileUploadDate.setUTCHours(1, 0, 0, 0)

    const referenceDate = new Date(Date.now() - dayInMs * days)
    referenceDate.setUTCHours(1, 0, 0, 0)

    return referenceDate < fileUploadDate
}
