import { Prompt, Typography } from '@capture/capture-components'
import { _ } from '~/assets/localization/util'

interface RestorePromptProps {
    handleConfirm: () => void
}

export const RestorePrompt = ({ handleConfirm }: RestorePromptProps) => {
    return (
        <Prompt.Content title={_('album_trash__restore_albums_title')}>
            <Typography.Paragraph>
                {_('album_trash__restore_albums_body')}
            </Typography.Paragraph>
            <Prompt.ButtonsSection>
                <Prompt.ActionButton key="cancel" variant="secondary">
                    {_('cancel')}
                </Prompt.ActionButton>
                <Prompt.ActionButton
                    variant="primary"
                    key="submit"
                    onPress={handleConfirm}>
                    {_('restore')}
                </Prompt.ActionButton>
            </Prompt.ButtonsSection>
        </Prompt.Content>
    )
}
