/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    CAPBAKClientStatsPostParams,
    CAPBAKClientStatsPostPayload,
    CAPBAKHTTPValidationError,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class ClientStats<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * @description this call updates the client status
     *
     * @tags client_stats
     * @name ClientStatsPost
     * @summary Client Stats
     * @request POST:/client_stats
     */
    clientStatsPost = (
        query: CAPBAKClientStatsPostParams,
        data: CAPBAKClientStatsPostPayload,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/client_stats`,
            method: 'POST',
            query: query,
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
        })
}
