import { useEffect, useState } from 'react'

type Opts = {
    onDragEnter?: () => void
    onDrop?: () => void
}
export const useDragDetectionEffect = ({ onDragEnter, onDrop }: Opts = {}) => {
    const [isDragging, setIsDragging] = useState(false)

    useEffect(() => {
        let lastKnownTarget: EventTarget | null = null
        const handleDragEnter = (e: DragEvent) => {
            e.preventDefault()
            e.stopPropagation()

            if (lastKnownTarget === null && onDragEnter) {
                onDragEnter()
            }

            lastKnownTarget = e.target
            setIsDragging(true)

            return false
        }

        const handleDragLeave = (e: DragEvent) => {
            if (lastKnownTarget === e.target) {
                e.preventDefault()
                e.stopPropagation()
                setIsDragging(false)
            }
        }

        const handleDrop = () => {
            if (onDrop) {
                onDrop()
            }
            setIsDragging(false)
        }

        window.addEventListener('dragenter', handleDragEnter)
        window.addEventListener('dragleave', handleDragLeave)
        window.addEventListener('drop', handleDrop)

        return () => {
            window.removeEventListener('dragenter', handleDragEnter)
            window.removeEventListener('dragleave', handleDragLeave)
            window.removeEventListener('drop', handleDrop)
        }
    }, [onDragEnter, onDrop])

    return isDragging
}
