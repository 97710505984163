import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit'
import type {
    Store,
    ListenerMiddlewareInstance,
    Middleware,
} from '@reduxjs/toolkit'
import { isStagingEnv, isTestEnv } from '../config/constants'
import { whenUnauthenticated } from '../API/toolbox'
import { DetectedThatUserIsUnauthenticated } from './currentUser/actions'
import { rootReducer } from './store'
import { ClientViewportSizeChanged } from './viewMode/actions'
import { bulkDispatchMiddleware } from './common/bulkDispatchMiddleware'

type StoreConnector = (store: Store) => void

export const makeStore = (
    middlewares: Middleware[],
    connectors: StoreConnector[],
    listenerMiddleware: ListenerMiddlewareInstance = createListenerMiddleware(),
) => {
    const store = configureStore({
        reducer: rootReducer(),
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware()
                .prepend(listenerMiddleware.middleware as Middleware)
                .concat([bulkDispatchMiddleware, ...middlewares]),
        devTools: isTestEnv || isStagingEnv ? { traceLimit: 15 } : false,
    })

    for (const connector of connectors) {
        connector(store)
    }

    window.addEventListener('resize', () =>
        store.dispatch(
            ClientViewportSizeChanged({
                width: document.body.clientWidth,
                height: window.innerHeight,
            }),
        ),
    )

    // handle user becoming logged out during session
    whenUnauthenticated(() =>
        store.dispatch(DetectedThatUserIsUnauthenticated()),
    )

    return store
}
