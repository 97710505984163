import styled from 'styled-components'
import { mediaQueries } from '~/assets/styleConstants'
import {
    getMonthFromNumberString,
    getShortMonthFromNumberString,
} from '~/utilities/dateOperations'

const MonthTitleWrapper = styled.span`
    & > span:last-child {
        display: none;
    }

    ${mediaQueries.mobile} {
        & > span:last-child {
            display: inline;
        }
        & > span:first-child {
            display: none;
        }
    }
`

type Props = {
    rawHeader: string
}

export const MonthGroupTitle: React.FunctionComponent<Props> = (props) => {
    const [year, monthNum] = props.rawHeader.split('-')
    return (
        <MonthTitleWrapper>
            <span>{`${getMonthFromNumberString(monthNum)} `}</span>
            <span>{`${getShortMonthFromNumberString(monthNum)} `}</span>
            {year}
        </MonthTitleWrapper>
    )
}
