import * as React from 'react'
import styled from 'styled-components'
import SvgSadFace from '@capture/capture-components/src/icons/SadFace'
import { _ } from '~/assets/localization/util'
import { trackEvent, trackEventInternal } from '~/analytics/eventTracking'
import { preventDefault } from '~/utilities/eventHandling'
import { colors } from '~/assets/styleConstants'

const AlbumImg = styled.img`
    max-width: 100%;
    display: block;
    flex: 0 0 auto;
    user-drag: none;
    user-select: none;
    pointer-events: none;
`
const ErrorMessage = styled.div`
    color: ${colors.white};
    font-size: 14px;
    text-align: center;
    padding: 40px;
`
const ErrorIcon = styled.div`
    margin: 8px;
`

export type ImageResolveValue =
    | { succeeded: true; dimensions: TwoDimensions }
    | { succeeded: false }
export const ImageResolveSuccess = (
    img: HTMLImageElement,
): ImageResolveValue => ({
    succeeded: true,
    dimensions: { width: img.naturalWidth, height: img.naturalHeight },
})
export const ImageResolveError: ImageResolveValue = { succeeded: false }

type AlbumImageProps = {
    thumbURL: string | undefined
    onImageResolve?: (value: ImageResolveValue) => void
}

type AlbumImageState = {
    failedToLoadImage: boolean
}

export class AlbumImage extends React.PureComponent<
    AlbumImageProps,
    AlbumImageState
> {
    public state = { failedToLoadImage: false }

    private imageLoaded = (e: React.SyntheticEvent<HTMLImageElement>) => {
        if (this.props.onImageResolve) {
            this.props.onImageResolve(ImageResolveSuccess(e.currentTarget))
        }
    }

    private errorHandler = () => {
        this.setState({ failedToLoadImage: true })
        trackEvent('AlbumPage', 'Failed_to_load_unsupported_media_type')
        trackEventInternal('album_failed_to_load_unsupported_media_type')
        if (this.props.onImageResolve) {
            // TODO: does this actually do anything?
            this.props.onImageResolve(ImageResolveError)
        }
    }

    public render() {
        if (this.state.failedToLoadImage) {
            return (
                <ErrorMessage>
                    <ErrorIcon>
                        <SvgSadFace
                            width={38}
                            height={38}
                            color={colors.white}
                        />
                    </ErrorIcon>
                    <div>{_('something_went_wrong')}</div>
                </ErrorMessage>
            )
        }

        return (
            <AlbumImg
                src={this.props.thumbURL}
                onLoad={this.imageLoaded}
                onError={this.errorHandler}
                onDragStart={preventDefault} // Needed to prevent item from being draggable on Firefox..
                alt="album file"
            />
        )
    }
}
