import styled from 'styled-components'

const Row = styled.div`
    display: flex;
    flex-direction: row;

    padding: 5px 10px;
    box-sizing: border-box;
    justify-content: ${(props: { isMobile: boolean }) =>
        props.isMobile ? 'space-between' : 'space-around'};

    > div {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: nowrap;
        min-width: 32px;
        width: ${(props) => (props.isMobile ? '65%' : '33%')};
    }

    > div:nth-of-type(2) {
        width: ${(props) => (props.isMobile ? '20%' : '')};
    }

    > div:last-of-type {
        width: ${(props) => (props.isMobile ? '15%' : '')};
        justify-content: ${(props) =>
            props.isMobile ? 'flex-end' : 'space-between'};
    }
`

export type ThreeColumns = [React.ReactNode, React.ReactNode, React.ReactNode]
type Props = {
    isMobile: boolean
    columns: ThreeColumns
}

export const ThreeColumnsRow: React.FunctionComponent<Props> = ({
    isMobile,
    columns,
}) => <Row isMobile={isMobile}>{columns}</Row>
