import styled from 'styled-components'
import { _ } from '~/assets/localization/util'
import { colors, mediaQueries } from '~/assets/styleConstants'

const UnavailablePlan = styled.div<{
    isSelected: boolean
    isSubscribed: boolean
}>`
    width: 202px;
    height: 72px;
    padding: 4px;
    box-sizing: border-box;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.captureGrey100};
    border-radius: 3px;
    color: ${(props) => (props.isSubscribed ? colors.captureBlue : 'inherit')};
    ${mediaQueries.mobile} {
        width: 96px;
    }
`

const AvailablePlan = styled(UnavailablePlan)`
    user-select: none;
    border: solid 1px ${colors.captureBlue};
    background-color: ${(props) =>
        props.isSelected && `rgba(${colors.captureBlueRGB}, 0.1)`};
    cursor: pointer;

    &:hover {
        background-color: ${(props) =>
            props.isSelected ? colors.captureBlue50 : 'inherit'};
    }
`

type Props = {
    price: string
    isSelected: boolean
    isSubscribed: boolean
    canPurchase: boolean
    onClick?: () => void
}

const PlanLowerText = styled.div`
    position: absolute;
    width: 100%;
    margin: 0 auto;
    font-size: 14px;
    padding-top: 40px;
    color: ${colors.captureGrey500};
`

export const StoragePlanSection = (props: Props) => {
    const isAvailable = props.canPurchase && !props.isSubscribed
    const SubscriptionPlan = isAvailable ? AvailablePlan : UnavailablePlan

    return (
        <div style={{ position: 'relative' }}>
            <SubscriptionPlan
                onClick={isAvailable ? props.onClick : () => undefined}
                isSubscribed={props.isSubscribed}
                isSelected={props.isSelected}>
                {`${props.price} / ${_('month').toLowerCase()}`}
                {props.isSubscribed && (
                    <PlanLowerText>{_('active')}</PlanLowerText>
                )}
            </SubscriptionPlan>
        </div>
    )
}
