import type { Action } from '../common/actions'
import { isType } from '../common/actions'
import type { User } from '../users/reducer'
import { SubscribersWereFetched } from './actions'

export type SubscribersState = DictionaryOf<User[]>

export const subscribersReducer = (
    state: SubscribersState = {},
    action: Action,
): SubscribersState => {
    if (isType(action, SubscribersWereFetched)) {
        return {
            ...state,
            [action.payload.jobID]: action.payload.subscribers,
        }
    }

    return state
}

export const subscribersReducerMapObj = {
    subscribers: subscribersReducer,
}

export type StateWithSubscribers = StateOfReducerMapObj<
    typeof subscribersReducerMapObj
>
