import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { _ } from '~/assets/localization/util'
import { colors, fontSize, mediaQueries } from '~/assets/styleConstants'
import { QuotaBar } from '~/components/Common/QuotaBar'
import { getMaxStorage, getUsedStorage } from '~/state/currentUser/selectors'
import {
    getCaptureIncludedStoragePlan,
    getCurrentStripeStoragePlan,
} from '~/state/storagePlan/selectors'
import { bytesToSize } from '~/utilities/fileSizeFormatting'
import { StorageInfoText } from './StorageInfoText'

const PlanInfo = styled.div`
    border-radius: 2px;
    border: 1px solid ${colors.captureGrey300};
    background-color: ${colors.captureGrey100};
    display: grid;
    grid-template-columns: 5fr 2fr;
    align-items: flex-end;
    padding: 16px;
    margin-bottom: 10px;

    ${mediaQueries.mobile} {
        display: block;
    }
`

const StorageSize = styled.span`
    color: ${colors.captureBlue};
    font-size: ${fontSize.xLarge_36};
    line-height: 36px;
    font-weight: bold;
    margin-right: 8px;
`

const SourceInfo = styled.span`
    color: ${colors.captureGrey600};
    font-size: ${fontSize.large_24};

    ${mediaQueries.mobile} {
        font-size: ${fontSize.medium_18};
    }
`

const PeriodInfo = styled.div`
    font-size: ${fontSize.large_24};
    color: ${colors.captureBlue};

    ${mediaQueries.mobile} {
        font-size: ${fontSize.medium_18};
        margin-top: 8px;
    }
`

export const PaidPlanDetails = () => {
    const currentStripePlan = useSelector(getCurrentStripeStoragePlan)
    const captureIncludedStoragePlan = useSelector(
        getCaptureIncludedStoragePlan,
    )
    const usedStorage = useSelector(getUsedStorage)
    const totalStorage = useSelector(getMaxStorage)

    const { priceString, period } = currentStripePlan || {}
    const periodText =
        period === 'monthly'
            ? _('month').toLowerCase()
            : _('year').toLowerCase()

    const billingCycleText = `${priceString}/${periodText}`

    return (
        <>
            <QuotaBar
                usedRatio={usedStorage / totalStorage}
                usedQuotaColor={colors.captureBlue900}
            />
            <div style={{ margin: '12px 0 32px' }}>
                <StorageInfoText
                    usedStorage={usedStorage}
                    totalStorage={totalStorage}
                    currentPlanSize={currentStripePlan?.size}
                />
            </div>
            {captureIncludedStoragePlan && (
                <PlanInfo>
                    <div>
                        <StorageSize>
                            {bytesToSize(captureIncludedStoragePlan.size)}
                        </StorageSize>
                        <SourceInfo>
                            (
                            {_('storage_source_b2b__format').replace(
                                '%BU_name%',
                                'Capture',
                            )}
                            )
                        </SourceInfo>
                    </div>
                    <SourceInfo>
                        <i>{_('free__as_in_no_cost')}</i>
                    </SourceInfo>
                </PlanInfo>
            )}
            {currentStripePlan && (
                <PlanInfo>
                    <div>
                        <StorageSize>
                            {bytesToSize(currentStripePlan?.size)}
                        </StorageSize>
                        <SourceInfo>({_('storage_source_web')})</SourceInfo>
                    </div>
                    <PeriodInfo>{billingCycleText}</PeriodInfo>
                </PlanInfo>
            )}
        </>
    )
}
