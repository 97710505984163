import type { EmptyObject, Middleware } from '@reduxjs/toolkit'
import { loadTrashContent } from '~/API/job'
import { FileUploadSucceeded } from '../uploader/actions'
import { isType } from '../common/actions'
import { FilesDeletionSucceeded } from '../job/actions'
import { getTimelineJobID } from '../timeline/selectors'
import { timelineReducerMapObj } from '../timeline/reducers'
import { FetchMoreTrashTriggered, TrashContentOutdated } from './actions'
import { getTrashFilesNextResultOffset } from './selectors'
import { trashReducerMapObj } from './reducer'

export const trashMiddlewareReducerMapObj = {
    ...timelineReducerMapObj,
    ...trashReducerMapObj,
}

type TrashMiddlewareState = StateOfReducerMapObj<
    typeof trashMiddlewareReducerMapObj
>

export const trashMiddleware: Middleware<EmptyObject, TrashMiddlewareState> =
    (store) => (next) => (action) => {
        const state = store.getState()
        const timelineID = getTimelineJobID(state)

        if (
            isType(action, FilesDeletionSucceeded) &&
            action.payload.jobID === timelineID
        ) {
            store.dispatch(TrashContentOutdated())
        }

        // Handling the case when uploading a deleted file
        if (isType(action, FileUploadSucceeded)) {
            store.dispatch(TrashContentOutdated())
        }

        if (isType(action, FetchMoreTrashTriggered)) {
            const offset = getTrashFilesNextResultOffset(state)
            loadTrashContent(store.dispatch, offset)
        }

        next(action)
    }
