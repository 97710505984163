import { HostUrl, getJSON } from '../toolbox'

export type VideoQuality = 'v-low' | 'v-high'

type VideoSubOption = VideoQuality | 'v-check'

type CheckVideoResponse = {
    result: string
}

export class VideoService {
    private hostUrl: HostUrl

    constructor(hostname: string, authToken: string) {
        this.hostUrl = new HostUrl(hostname, {
            key: import.meta.env.VITE_API_KEY,
            auth: authToken,
            client_v: import.meta.env.VITE_VERSION,
        })
    }

    private getFileUrl(
        jobID: JobID,
        videoID: VideoID,
        sub: VideoSubOption,
    ): string {
        return this.hostUrl.getPath(
            `/st/4/jobs/${jobID}/files_by_id/${videoID}`,
            { sub },
        )
    }

    public checkVideo(
        jobID: JobID,
        videoID: VideoID,
    ): Promise<CheckVideoResponse> {
        return getJSON<CheckVideoResponse>(
            this.getFileUrl(jobID, videoID, 'v-check'),
        )
    }

    public getVideoUrl(
        jobID: JobID,
        videoID: VideoID,
        quality: VideoQuality,
    ): string {
        return this.getFileUrl(jobID, videoID, quality)
    }
}
