import { createActionCreator, createVoidActionCreator } from '../common/actions'
import type { DownloaderModalInfo } from './reducer'

export const SetClickedLinkStatus = createActionCreator<number>(
    'DOWNLOADER_PAGE_LINK_STATUS_UPDATED',
)
export const StartPaginatedDownloadFlow =
    createActionCreator<DownloaderModalInfo>('START_PAGINATED_DOWNLOAD_FLOW')

export const MinimizeDownloaderModal = createVoidActionCreator(
    'MINIMIZE_DOWNLOADER_MODAL',
)
export const MaximizeDownloaderModal = createVoidActionCreator(
    'MAXIMIZE_DOWNLOADER_MODAL',
)
export const ClearDownloaderState = createVoidActionCreator(
    'DOWNLOADER_STATE_CLEARED',
)
