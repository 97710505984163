import * as React from 'react'
import styled from 'styled-components'
import { _ } from '~/assets/localization/util'
import { colors, fontSize } from '~/assets/styleConstants'
import type { DocumentFileGroup } from '~/state/timeline/selectors'
import { getMonthFromNumberString } from '~/utilities/dateOperations'
import { getFileExtension } from '~/utilities/fileTarget'
import { FileListEntry } from '../Common/FileListEntry'
import { FilesTable } from '../Common/FilesTable'
import type { ThreeColumns } from '../Common/ThreeColumnsRow'

const TitleArea = styled.div`
    margin: 0 8px 16px 8px;
    color: ${colors.captureGrey800};
    font-size: ${fontSize.large_24};
    user-select: none;

    display: flex;
    align-items: center;
`

const GroupHeader: React.FunctionComponent<{ header: string }> = ({
    header,
}) => {
    const [year, monthNum] = header.split('-')
    const groupTitle = `${getMonthFromNumberString(monthNum)} ${year}`
    return (
        <TitleArea>
            <span>{groupTitle}</span>
        </TitleArea>
    )
}

type Props = {
    isMobile: boolean
    isInSelectMode: boolean
    documentGroups: DocumentFileGroup[]
    getFileSelectionStatus: (fileID: FileID) => boolean
    onFileSelected: (fileID: FileID) => void
    onFileDeSelected: (fileID: FileID) => void
}

export class DocumentsList extends React.Component<Props> {
    private getTableRows = () =>
        this.props.documentGroups.map((docGroup) => {
            const rows = docGroup.files.map((f) => {
                return (
                    <FileListEntry
                        key={f.fileID}
                        doFileSelected={this.props.onFileSelected}
                        doFileDeSelected={this.props.onFileDeSelected}
                        isMobile={this.props.isMobile}
                        fileName={f.fileName}
                        fileExtension={
                            getFileExtension(f.fileName) || _('unknown')
                        }
                        fileID={f.fileID}
                        dateInfo={f.uploadedTime}
                        isSelected={this.props.getFileSelectionStatus(f.fileID)}
                        isInSelectMode={this.props.isInSelectMode}
                    />
                )
            })

            return (
                <div key={docGroup.header}>
                    <GroupHeader header={docGroup.header} />
                    {rows}
                </div>
            )
        })

    public render() {
        const columns: ThreeColumns = [
            <div key="header-1">{_('filename')}</div>,
            <div key="header-2">{_('file_type')}</div>,
            <div key="header-3">
                {!this.props.isMobile && <div>{_('uploaded')}</div>}
            </div>,
        ]

        return (
            <FilesTable
                isMobile={this.props.isMobile}
                headerColumns={columns}
                rows={this.getTableRows()}
            />
        )
    }
}
