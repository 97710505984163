import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import SvgSettings from '@capture/capture-components/src/icons/Settings'
import SvgLogout from '@capture/capture-components/src/icons/Logout'
import { withoutTheBools } from '~/utilities/arrayUtils'
import { MakeMenuOptions } from '~/components/Common/OverflowMenu'
import type { ButtonProps } from '~/components/Common/Button'
import { Button } from '~/components/Common/Button'
import { _ } from '~/assets/localization/util'
import { logoutAndRedirectToOpenWeb } from '~/API/login'
import {
    getCurrentUserInfo,
    getEmailAdressOfLoggedInUser,
} from '~/state/currentUser/selectors'
import { Pages } from '~/routing'
import { colors, fontSize, layout } from '~/assets/styleConstants'
import { trackEventInternal } from '~/analytics/eventTracking'
import { Avatar, AvatarWithNameAndContact } from '../Common/Avatar'

const PageSpecificElement = styled.div`
    padding-left: 32px;
    display: inline-flex;
    cursor: pointer;
`

const AvatarWrapper = styled(PageSpecificElement)`
    padding-left: 12px;
    ${(props: { showSeparator?: boolean }) =>
        props.showSeparator &&
        `
    border-left: 1px solid ${colors.captureGrey300};
    margin-left: 12px;
    `}
    cursor: pointer;
`

const UserMenuWrapper = styled.div`
    position: fixed;
    top: ${layout.topNavBarHeight - 15}px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: rgba(255, 255, 255, 0.95);
    width: 240px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 4px;
    padding: 10px 0px 16px 0px;
    box-sizing: border-box;
    transform: ${(props: { isExpanded: boolean }) =>
        props.isExpanded ? 'scale(1)' : 'scale(0)'};
    transition: transform 0.2s;
    transform-origin: right top;
    cursor: initial;
    font-size: ${fontSize.medium_18};
`

const AvatarMenuWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
`
type UserMenuProps = {
    showSeparator: boolean
    size: number
}
// The round avatar and the drop down menu included with it
export const UserMenu: React.FunctionComponent<UserMenuProps> = (props) => {
    const [userMenuState, setUserMenuState] = useState(false)
    const onMouseEnter = () => setUserMenuState(true)
    const onMouseLeave = () => setUserMenuState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const navigateToSettings = () => {
        trackEventInternal('usermenu_navigate_to_settings')
        navigate(Pages.Settings.url)
    }

    const userData = useSelector(getCurrentUserInfo)
    const email = useSelector(getEmailAdressOfLoggedInUser)

    const menuItems: ButtonProps[] = withoutTheBools([
        Button(_('settings'), navigateToSettings, { icon: SvgSettings }),
        Button(
            _('log_out'),
            () => {
                trackEventInternal('usermenu_logout')
                logoutAndRedirectToOpenWeb(dispatch)
            },
            { icon: SvgLogout },
        ),
    ])

    if (userData) {
        return (
            <AvatarWrapper
                key="avi_area"
                showSeparator={props.showSeparator}
                onClick={navigateToSettings}
                onKeyUp={navigateToSettings}
                onMouseEnter={onMouseEnter}
                onFocus={onMouseEnter}
                onMouseLeave={onMouseLeave}
                data-cy={'avatarBtn__settings'}
                role="button"
                tabIndex={0}>
                <Avatar
                    size={props.size}
                    name={userData.name}
                    img={userData.profilePicture}
                />
                <UserMenuWrapper isExpanded={userMenuState}>
                    <AvatarMenuWrapper>
                        <AvatarWithNameAndContact
                            name={userData.name}
                            img={userData.profilePicture}
                            contact={email}
                        />
                    </AvatarMenuWrapper>
                    <MakeMenuOptions
                        options={menuItems}
                        afterClick={onMouseLeave}
                    />
                </UserMenuWrapper>
            </AvatarWrapper>
        )
    }

    return (
        <AvatarWrapper
            key="avi_area"
            showSeparator={props.showSeparator}
            onClick={navigateToSettings}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onKeyUp={navigateToSettings}
            onFocus={onMouseEnter}
            role="button"
            tabIndex={0}>
            <Avatar size={32} name={''} img={''} />
        </AvatarWrapper>
    )
}
