import type { SVGProps } from 'react'
const SvgLivePhoto = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill={props.color ?? 'currentColor'}
            d="M12.75 2.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0M12.75 21.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0M21.25 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5M2.75 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5M15.119 3.26a.75.75 0 1 1-1.45-.389.75.75 0 0 1 1.45.388M10.33 21.129a.75.75 0 1 1-1.449-.388.75.75 0 0 1 1.45.388M20.74 15.119a.75.75 0 1 1 .389-1.45.75.75 0 0 1-.388 1.45M2.871 10.33a.75.75 0 1 1 .388-1.449.75.75 0 0 1-.388 1.45M17.275 4.364a.75.75 0 1 1-1.3-.75.75.75 0 0 1 1.3.75M8.025 20.386a.75.75 0 1 1-1.3-.75.75.75 0 0 1 1.3.75M19.636 17.275a.75.75 0 1 1 .75-1.3.75.75 0 0 1-.75 1.3M3.614 8.025a.75.75 0 1 1 .75-1.3.75.75 0 0 1-.75 1.3M19.071 5.99a.75.75 0 1 1-1.06-1.061.75.75 0 0 1 1.06 1.06M5.99 19.071a.75.75 0 1 1-1.061-1.06.75.75 0 0 1 1.06 1.06M18.01 19.071a.75.75 0 1 1 1.061-1.06.75.75 0 0 1-1.06 1.06M4.929 5.99a.75.75 0 1 1 1.06-1.061.75.75 0 0 1-1.06 1.06M20.386 8.025a.75.75 0 1 1-.75-1.3.75.75 0 0 1 .75 1.3M4.364 17.275a.75.75 0 1 1-.75-1.3.75.75 0 0 1 .75 1.3M15.976 20.386a.75.75 0 1 1 1.299-.75.75.75 0 0 1-1.3.75M6.726 4.364a.75.75 0 1 1 1.299-.75.75.75 0 0 1-1.3.75M21.129 10.33a.75.75 0 1 1-.388-1.449.75.75 0 0 1 .388 1.45M3.26 15.119a.75.75 0 1 1-.389-1.45.75.75 0 0 1 .388 1.45M13.67 21.129a.75.75 0 1 1 1.448-.388.75.75 0 0 1-1.448.388M8.882 3.26a.75.75 0 1 1 1.448-.389.75.75 0 0 1-1.448.388"
        />
        <path
            fill={props.color ?? 'currentColor'}
            fillRule="evenodd"
            d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4m0 2a4 4 0 1 0 0-8 4 4 0 0 0 0 8"
            clipRule="evenodd"
        />
        <path
            fill={props.color ?? 'currentColor'}
            fillRule="evenodd"
            d="M12 18a6 6 0 1 0 0-12 6 6 0 0 0 0 12m0 1a7 7 0 1 0 0-14 7 7 0 0 0 0 14"
            clipRule="evenodd"
        />
    </svg>
)
export default SvgLivePhoto
