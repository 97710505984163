import type { Store } from '@reduxjs/toolkit'
import type {
    CommentedJobChange,
    JobChange,
    ReactionJobChange,
} from '~/@types/backend-types'
import { trackEvent, trackEventInternal } from '~/analytics/eventTracking'
import type { Action } from '~/state/common/actions'
import { BulkOfActions, isType } from '~/state/common/actions'
import {
    getCurrentAuthToken,
    getCurrentUserUUID,
    isJobCreatedByCurrentUser,
} from '~/state/currentUser/selectors'
import {
    CommentWasDeleted,
    FileWasCommented,
    IgnoredFileEvent,
} from '~/state/files/actions'
import { getThumbHost } from '~/state/hosts/selectors'
import type { FileDescription } from '~/state/job/actions'
import {
    FileWasRemovedFromJob,
    FilesWereAddedToJob,
    JobChangesWasFetched,
    JobInfoChangeDetected,
    UnableToFetchJobChanges,
} from '~/state/job/actions'
import { isStoryJob } from '~/state/jobInfo/selectors'
import {
    getInitialChangeEventID,
    getTheJobCurrentlyFetchingChangesFor,
} from '~/state/jobSyncing/selectors'
import {
    ReactionAdded,
    ReactionDeleted,
    Reactions,
} from '~/state/reaction/actions'
import { filesDescriptionToCaptureFiles } from '~/state/recentFiles/recentFilesProcessing'
import {
    addRecentFiles,
    removeRecentFile,
} from '~/state/recentFiles/recentFilesSlice'
import { getTimelineJobID } from '~/state/timeline/selectors'
import { FileUploadSucceeded, UploaderFinished } from '~/state/uploader/actions'
import {
    getBackendSucceededFileIDByFileUUID,
    getSucceededFiles,
    isUploaderDone,
} from '~/state/uploader/selectors'
import { getServiceProvider } from '../HostProvider'
import { fetchContributingUsers } from '../job'
import type { AppService } from '../services/AppService'
import type { PollResponseStatus, PollService } from '../services/PollService'

class JobChangesSyncer {
    private currentSeq = 0
    private isRunning = false
    private isCurrentlyFetching = false
    public currentPoller?: AbortablePromise<PollResponseStatus>
    constructor(
        private jobID: JobID,
        private handleChanges: (
            changes: JobChange[],
            newestSerial: number,
        ) => void,
        private errorHandler: (error: Error) => void,
        private newestSerialSeen: number,
    ) {}

    private handlePollerResponse = (resp: PollResponseStatus) => {
        this.currentPoller = undefined
        switch (resp.status) {
            case 'ok':
                this.currentSeq = resp.seq
                this.lookForChanges(true)
                break
            case 'aborted':
                this.isRunning = false
                break
            default:
                // Probable cause of error is longPoller timeout. This implies no changes for the lifetime of the request.
                // Wait 1s (to avoid flooding if caused by network error) and poll to again fetch new changes if they occur.
                setTimeout(this.poll, 1000)
                break
        }
    }

    public resetNewestSerialSeen = () => (this.newestSerialSeen = 0)

    private poll = () => {
        getServiceProvider()
            .getPollServiceForJob(this.jobID)
            .then((pollService: PollService) => {
                if (this.isRunning && !this.currentPoller) {
                    this.currentPoller = pollService.pollForChanges(
                        this.jobID,
                        this.currentSeq,
                    )
                    this.currentPoller.then(this.handlePollerResponse)
                }
            })
    }

    public start = () => {
        this.isRunning = true
        this.lookForChanges()
        this.poll()
    }

    public stop = () => {
        this.isRunning = false
        if (this.currentPoller) {
            this.currentPoller.abort()
        }
    }

    public lookForChanges = (retryIfNoChanges = false) => {
        if (!this.isRunning || this.isCurrentlyFetching) {
            return
        }
        this.isCurrentlyFetching = true

        getServiceProvider()
            .getAppServiceForJob(this.jobID)
            .then((ah: AppService) =>
                ah
                    .getJobChanges(this.jobID, this.newestSerialSeen)
                    .then((changes: JobChange[]) => {
                        if (changes.length) {
                            this.newestSerialSeen = changes.reduce(
                                (a, c) => Math.max(a, c.serial),
                                this.newestSerialSeen,
                            )
                        } else {
                            // No changes detected from the poller. Maybe we were to quick? Retry later unless is a retry
                            if (retryIfNoChanges) {
                                setTimeout(this.lookForChanges, 400)
                                return
                            }
                        }
                        this.handleChanges(changes, this.newestSerialSeen)
                    }, this.errorHandler)
                    .then(() => {
                        this.isCurrentlyFetching = false
                        this.poll()
                    }),
            )
    }
}

const jobChangeToJobAction = (
    store: Store,
    jobID: JobID,
    change: JobChange,
    currentUser: UserID,
): Action<unknown> => {
    const state = store.getState()
    switch (change.type) {
        case 0: {
            // Image was created
            const fileID = getBackendSucceededFileIDByFileUUID(state, change.id)
            if (typeof fileID === 'number') {
                store.dispatch(
                    FileUploadSucceeded({
                        fileID: fileID,
                        fileUUID: change.id,
                        usedStorage: change.size,
                    }),
                )
                if (isUploaderDone(state)) {
                    store.dispatch(
                        UploaderFinished({
                            filesCount: getSucceededFiles(state).length,
                        }),
                    )
                }
            }

            return FilesWereAddedToJob([
                {
                    jobID,
                    fileID: change.id,
                    file_type: change.file_type,
                    user_uuid: change.user_uuid || currentUser,
                    duration: change.duration,
                    path: change.path,
                    size: change.size,
                    checksum: change.checksum,
                    ctime: change.ctime,
                    mtime: change.mtime,
                    timestamp: change.timestamp,
                    width: change.width,
                    height: change.height,
                    group_type: change.group_type,
                    group_id: change.group_id,
                    master: change.master,
                },
            ])
        }
        case 1: // Image was deleted
            return FileWasRemovedFromJob({
                jobID,
                fileID: change.id,
            })
        case 10: {
            // If the comment-text is an empty string, it means that the comment has been deleted
            // If the comment exists already it is supposed to be overwritten (handled by reducer as if it was added)
            // Type 10 is used backend for any comment-related change.
            // Comment added/changed
            const commentedChange = change as CommentedJobChange
            if (commentedChange.comment === '') {
                return CommentWasDeleted(commentedChange.comment_uuid)
            }
            return FileWasCommented({
                fileID: commentedChange.id,
                comment: commentedChange.comment,
                commentUUID: commentedChange.comment_uuid,
                timestamp: commentedChange.timestamp,
                userUUID: commentedChange.user_uuid || currentUser,
            })
        }
        case 11: // Property-changes on JobInfo (changes on job not related to a single file)
            return JobInfoChangeDetected({ jobID, eventID: change.serial })
        case 12: {
            // reaction: '' -> deleted any reaction
            // reaction: 'love' -> added love reaction
            // Reaction added/deleted
            const reactionChange = change as ReactionJobChange
            switch (reactionChange.reaction) {
                case 'love':
                    return ReactionAdded({
                        fileID: reactionChange.id,
                        reaction: Reactions.Love,
                        userUUID: reactionChange.user_uuid || currentUser,
                    })
                case '':
                    return ReactionDeleted({
                        fileID: reactionChange.id,
                        userUUID: reactionChange.user_uuid || currentUser,
                    })
                default:
                    console.warn(
                        'Unknown reaction change: ',
                        reactionChange.reaction,
                    )
                    return IgnoredFileEvent()
            }
        }
        default:
            console.warn('Unknown jobChange-type: ' + change.type, change)
            trackEvent('JobChanges', 'UnknownJobChangeType_' + change.type)
            trackEventInternal('job_changes_unknown_job_change_type', {
                type: change.type,
            })
            return IgnoredFileEvent() // To return some Action
    }
}

/**
 * The connected job-syncer observes the current redux-store to detect which job is currently in focus and observes the
 * state for that job and make changes available to the store (by dispatching actions when changes are detected).
 * It waits until the jobInfo for the job in focus is fetched to make sure the job is available for the user.
 */
class ConnectedJobChangesSyncer {
    private syncers: { [key: string]: JobChangesSyncer } = {}
    private currentJob: JobID | undefined

    constructor(private store: Store) {
        store.subscribe(() => {
            const state = store.getState()

            if (state === undefined) {
                console.warn('JobChangesSyncer: undefined state.')
                return
            }

            const displayedJob = getTheJobCurrentlyFetchingChangesFor(state)
            if (displayedJob !== this.currentJob) {
                this.handleChangedFocus(displayedJob)
            }

            if (displayedJob && this.syncers[displayedJob]) {
                !this.syncers[displayedJob].currentPoller &&
                    this.handleChangedFocus(displayedJob)
            }
        })
    }

    private handleChangedFocus(newJobToWatch: JobID | undefined) {
        // Stop the poller for current job.
        if (this.currentJob && this.syncers[this.currentJob]) {
            this.syncers[this.currentJob].stop()
        }

        this.currentJob = newJobToWatch
        if (newJobToWatch === undefined) {
            return
        }

        // Reuse if we have fetched this job before
        if (!this.syncers[newJobToWatch]) {
            this.syncers[newJobToWatch] = new JobChangesSyncer(
                newJobToWatch,
                (changes, lastSerial) => {
                    // Since the changes-api is not including UUID for changes performed by current user,
                    // provide it as fallback here.
                    const currentUser: UserID =
                        getCurrentUserUUID(this.store.getState()) || ''
                    let fileAddedChanges: FileDescription[] = []
                    const actions: Action[] = []

                    // Handle recents actions
                    const recentsActions: Action[] = []
                    const timelineJob = getTimelineJobID(this.store.getState())
                    const authToken = getCurrentAuthToken(this.store.getState())
                    const thumbHost = getThumbHost(this.store.getState())
                    const handleRecentsAction = (action: Action) => {
                        if (
                            timelineJob &&
                            this.currentJob === timelineJob &&
                            thumbHost &&
                            authToken
                        ) {
                            if (isType(action, FilesWereAddedToJob)) {
                                let masterOnlyFiles
                                const captureFiles =
                                    filesDescriptionToCaptureFiles(
                                        timelineJob,
                                        thumbHost,
                                        authToken,
                                        masterOnlyFiles || action.payload,
                                    )

                                recentsActions.push(
                                    addRecentFiles(captureFiles),
                                )
                            }
                            if (isType(action, FileWasRemovedFromJob)) {
                                recentsActions.push(
                                    removeRecentFile(action.payload.fileID),
                                )
                            }
                        }
                    }

                    changes.forEach((change) => {
                        const action = jobChangeToJobAction(
                            this.store,
                            newJobToWatch,
                            change,
                            currentUser,
                        )
                        if (isType(action, FilesWereAddedToJob)) {
                            let masterOnlyFiles
                            fileAddedChanges = fileAddedChanges.concat(
                                masterOnlyFiles || action.payload,
                            )
                        } else {
                            if (fileAddedChanges.length > 0) {
                                actions.push(
                                    FilesWereAddedToJob(fileAddedChanges),
                                )
                                fileAddedChanges = []
                            }
                            actions.push(action)
                        }
                        handleRecentsAction(action)
                    })
                    if (fileAddedChanges.length > 0) {
                        actions.push(FilesWereAddedToJob(fileAddedChanges))
                    }
                    actions.push(...recentsActions)
                    actions.push(
                        JobChangesWasFetched({
                            jobID: newJobToWatch,
                            lastSerial,
                        }),
                    )
                    this.store.dispatch(BulkOfActions(actions))

                    // Whenever changes to a job is detected, fetch the list of contributing users.
                    // TODO: check if changes introduces a new user and only fetch when new users are introduced
                    if (isStoryJob(this.store.getState(), newJobToWatch)) {
                        fetchContributingUsers(
                            this.store.dispatch,
                            newJobToWatch,
                            isJobCreatedByCurrentUser(
                                this.store.getState(),
                                currentUser,
                            ) === false
                                ? currentUser
                                : undefined,
                        )
                    }
                },
                () => {
                    this.store.dispatch(UnableToFetchJobChanges(newJobToWatch))
                },
                getInitialChangeEventID(this.store.getState(), newJobToWatch),
            )
        }
        this.syncers[newJobToWatch].start()
    }

    public triggerManually() {
        if (this.currentJob && this.currentJob in this.syncers) {
            this.syncers[this.currentJob].lookForChanges()
        }
    }

    public resetSyncer = (jobID: JobID) => {
        if (this.syncers[jobID]) {
            this.syncers[jobID].resetNewestSerialSeen()
        }
    }
}

let instance: ConnectedJobChangesSyncer | undefined
export const connectJobChangesSyncer = (store: Store) => {
    instance = new ConnectedJobChangesSyncer(store)
}
export const triggerManualChangesPolling = () => {
    if (instance) {
        instance.triggerManually()
    } else {
        console.warn('Must connectJobChangesSyncer before using it')
    }
}

export const resetSyncersForJobs = (jobIDs: JobID[]) => {
    jobIDs.forEach((jobID) => {
        instance && instance.resetSyncer(jobID)
    })
}
