import type { SVGProps } from 'react'
const SvgPen = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill={props.color ?? 'currentColor'}
            d="M5 19h1.425L16.2 9.225 14.775 7.8 5 17.575zm-1 2a.967.967 0 0 1-.712-.288A.968.968 0 0 1 3 20v-2.425a1.975 1.975 0 0 1 .575-1.4L16.2 3.575c.2-.183.42-.325.663-.425.241-.1.495-.15.762-.15s.525.05.775.15c.25.1.467.25.65.45L20.425 5c.2.183.346.4.438.65a2.165 2.165 0 0 1 0 1.512 1.874 1.874 0 0 1-.438.663l-12.6 12.6a1.975 1.975 0 0 1-1.4.575zM15.475 8.525l-.7-.725L16.2 9.225z"
        />
    </svg>
)
export default SvgPen
