import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import SvgClose from '@capture/capture-components/src/icons/Close'
import { fetchConnectedDevices } from '~/API/job'
import { colors, fontSize } from '~/assets/styleConstants'
import { getConnectedDevices } from '~/state/currentUser/selectors'
import { DeleteConnectedDeviceInitiated } from '~/state/userActions/actions'
import { RippleLoader } from '../Common/RippleLoader'

const DeviceEntry = styled.div`
    background-color: ${colors.captureGrey100};
    padding: 4px 12px;
    box-sizing: border-box;
    width: 100%;

    display: flex;
    align-items: center;
    margin: 8px 0;
    font-size: ${fontSize.small_14};
    user-select: none;

    &:hover {
        background-color: ${colors.captureGrey200};
    }
`
const IconWrapper = styled.span`
    margin-right: 24px;
    height: 24px;
    cursor: pointer;
`

const RippleWrapper = styled.div`
    align-self: center;
`

export const ConnectedDevicesComponent: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState(true)

    const dispatch = useDispatch()

    useEffect(() => {
        fetchConnectedDevices(dispatch).then(() => setIsLoading(false))
    }, [])

    const connectedDevices = useSelector(getConnectedDevices)

    if (isLoading) {
        return (
            <RippleWrapper>
                <RippleLoader />
            </RippleWrapper>
        )
    }

    return (
        <>
            {connectedDevices.map((d) => (
                <DeviceEntry key={d.id}>
                    <IconWrapper
                        onClick={() =>
                            dispatch(DeleteConnectedDeviceInitiated(d.id))
                        }
                        onKeyUp={() =>
                            dispatch(DeleteConnectedDeviceInitiated(d.id))
                        }
                        role="button"
                        tabIndex={0}>
                        <SvgClose color={colors.captureGrey500} />
                    </IconWrapper>
                    {d.name}
                </DeviceEntry>
            ))}
        </>
    )
}
