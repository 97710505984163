import type { SVGProps } from 'react'
const SvgCastStop = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill={props.color ?? 'currentColor'}
            d="M15.002 20h5c.55 0 1.02-.196 1.413-.588.391-.391.587-.862.587-1.412V7.74l-2 1.6V18h-5.15a13.592 13.592 0 0 1 .15 2M11.158 13.855a11.393 11.393 0 0 0-1.406-1.693c-1.883-1.875-4.167-2.92-6.85-3.137a.685.685 0 0 0-.638.25c-.175.2-.262.442-.262.725 0 .267.08.5.237.7.159.2.371.317.638.35 2.133.217 3.954 1.067 5.462 2.55.48.472.895.976 1.246 1.513l1.573-1.258M8 16.38a7.154 7.154 0 0 0-1.06-1.355 6.697 6.697 0 0 0-4.013-1.975.725.725 0 0 0-.65.237.99.99 0 0 0-.275.713c0 .267.07.504.212.712a.81.81 0 0 0 .588.363 5.07 5.07 0 0 1 2.712 1.375c.372.36.675.757.909 1.193L8 16.38M7.154 19.62l1.634-1.308c.058.24.104.486.139.738a.771.771 0 0 1-.225.675.948.948 0 0 1-.7.275 1.095 1.095 0 0 1-.848-.38M4.4 19.261l-.895.716c-.081.015-.165.023-.253.023-.35 0-.646-.12-.888-.363a1.207 1.207 0 0 1-.362-.887c0-.35.12-.646.362-.888.242-.241.538-.362.888-.362s.646.12.887.362c.242.242.363.538.363.888 0 .186-.034.356-.102.511M10.49 16.951l1.639-1.312a11.18 11.18 0 0 1 .823 3.336.854.854 0 0 1-.238.725c-.191.2-.429.3-.712.3a.99.99 0 0 1-.713-.275 1.116 1.116 0 0 1-.337-.7 9.32 9.32 0 0 0-.463-2.074M20.002 6.78V6h-16v1.15a13.592 13.592 0 0 0-2-.15V6c0-.55.196-1.02.587-1.413A1.926 1.926 0 0 1 4.002 4h16c.55 0 1.02.196 1.413.587.208.21.362.44.46.695l-1.873 1.497"
        />
        <path
            fill={props.color ?? 'currentColor'}
            fillRule="evenodd"
            d="M1.266 20.508a1 1 0 0 1 .156-1.406l19.91-15.807a1 1 0 0 1 1.249 1.561L2.67 20.664a1 1 0 0 1-1.405-.156"
            clipRule="evenodd"
        />
    </svg>
)
export default SvgCastStop
