import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import SvgClose from '@capture/capture-components/src/icons/Close'
import type { Dispatch } from '~/state/common/actions'
import { _ } from '~/assets/localization/util'
import { doLogin, loginAndGoToPage } from '~/API/login'
import { trackEvent } from '~/analytics/eventTracking'
import { fontSize, mediaQueries } from '~/assets/styleConstants'
import { Pages } from '~/routing'
import {
    ValidUserInfoRequestDeniedByUser,
    type RequireUserInfoReason,
} from '~/state/currentUser/actions'
import {
    getProvideUserInfoReason,
    mustProvideUserInfo,
} from '~/state/currentUser/selectors'
import { IconButton } from '../Common/IconTextButton'
import { OverlayModal } from '../Common/OverlayModal'
import { RequireUserInfoOptions } from './RequireUserInfoOptions'

const OverlayWrapper = styled.div`
    width: 400px;
    padding: 8px;

    ${mediaQueries.mobile} {
        width: 100%;
        padding: 8px 0;
    }
`

const OverlayHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: ${fontSize.medium_18};
    font-weight: bold;
    margin-bottom: 8px;
`

type OwnProps = {
    jobID: JobID
}
type StateProps = {
    mustProvideUserInfo: boolean
    requireUserInfoReason?: RequireUserInfoReason
}
type DispatchProps = {
    denyUserInfoRequest: () => void
}

type Props = OwnProps & StateProps & DispatchProps

type ComponentState = {
    showOverlay?: boolean
}

class _RequireUserInfo extends React.Component<Props, ComponentState> {
    constructor(props: Readonly<Props>) {
        super(props)
        this.state = {
            showOverlay: props.mustProvideUserInfo,
        }
    }

    private doSignIn = () => {
        trackEvent('ProvideNameOverlay', 'clickedRegister/SignIn')
        if (this.props.requireUserInfoReason === 'addPhotoToAlbum') {
            const pageToNavigateTo = {
                ...Pages.Album(this.props.jobID),
                hash: 'showUploadOverlay',
            }
            loginAndGoToPage(pageToNavigateTo)
        } else {
            doLogin()
        }
    }

    private doCancelOverlay = () => {
        if (this.props.mustProvideUserInfo) {
            trackEvent('ProvideNameOverlay', 'PromptCanceled')
            this.props.denyUserInfoRequest()
        }
        this.resetOverlay()
    }

    private resetOverlay = () => {
        this.setState({ showOverlay: undefined })
    }

    public componentDidUpdate(prevProps: Readonly<Props>) {
        if (this.props.mustProvideUserInfo && !prevProps.mustProvideUserInfo) {
            this.setState({ showOverlay: true })
        }
    }

    public render() {
        if (this.state.showOverlay) {
            const header = (
                <OverlayHeaderWrapper>
                    <div style={{ width: 24 }} />
                    {_('hey')}
                    <IconButton
                        icon={SvgClose}
                        onClick={this.doCancelOverlay}
                    />
                </OverlayHeaderWrapper>
            )
            return (
                <OverlayModal
                    onClose={this.doCancelOverlay}
                    headerElements={header}>
                    <OverlayWrapper>
                        <RequireUserInfoOptions onClickSignIn={this.doSignIn} />
                    </OverlayWrapper>
                </OverlayModal>
            )
        }
        return null
    }
}

const mapStateToProps = (
    state: StateOfSelector<typeof mustProvideUserInfo> &
        StateOfSelector<typeof getProvideUserInfoReason>,
): StateProps => ({
    mustProvideUserInfo: mustProvideUserInfo(state),
    requireUserInfoReason: getProvideUserInfoReason(state),
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    denyUserInfoRequest: () => dispatch(ValidUserInfoRequestDeniedByUser()),
})

export const RequireUserInfoPlacement = connect(
    mapStateToProps,
    mapDispatchToProps,
)(_RequireUserInfo)
