import * as React from 'react'
import { connect } from 'react-redux'
import { getTimelineJobID } from '~/state/timeline/selectors'
import type { Dispatch } from '~/state/common/actions'
import { _, getStringWithAmount } from '~/assets/localization/util'
import {
    copyAlbumFilesToTimeline,
    deleteMultipleFiles,
    deleteMultipleTrashFiles,
    restoreMultipleFiles,
} from '~/API/job'
import {
    ConfirmableSelectionAction,
    FilesWereDeselected,
    SelectionActionConfirmHandled,
} from '~/state/selectedFiles/actions'
import {
    getSelectedFileIDsforJob,
    getSelectionAction,
} from '~/state/selectedFiles/selectors'
import { ConfirmPromptOverlay } from '../Common/DialoguePromptOverlay'

type StateProps = {
    selectedFiles: FileID[]
    selectionAction: ConfirmableSelectionAction | undefined
    timelineId?: JobID
}

type DispatchProps = {
    onConfirmUserAction: (
        type: ConfirmableSelectionAction,
        jobID: JobID,
        files: FileID[],
        timelineId?: JobID,
    ) => void
    onConfirmPromptHandled: () => void
}

type OwnProps = {
    jobID: JobID // could infer this frome files if we always select files from the same job
    isInAlbum?: boolean
    selectionOverride?: FileID[]
    onSelectionActionCompleted?: () => void
}

type Props = StateProps & DispatchProps & OwnProps

type DialogContent = {
    text: () => string
    confirmLabel: string
}

type ConfirmUserAction = (
    dispatch: Dispatch,
    jobID: JobID,
    files: FileID[],
    timelineId?: JobID,
) => void
const selectionActionConfirm: Record<
    ConfirmableSelectionAction,
    ConfirmUserAction
> = {
    [ConfirmableSelectionAction.DELETE]: deleteMultipleFiles,
    [ConfirmableSelectionAction.RESTORE]: restoreMultipleFiles,
    [ConfirmableSelectionAction.COPY_TO_TIMELINE]: copyAlbumFilesToTimeline,
    [ConfirmableSelectionAction.PERM_DELETE]: (dispatch, _, files) =>
        deleteMultipleTrashFiles(dispatch, files),
}

class DialogComponent extends React.PureComponent<
    Props,
    Record<string, never>
> {
    private confirms: Record<ConfirmableSelectionAction, DialogContent> = {
        [ConfirmableSelectionAction.DELETE]: {
            text: () => {
                if (this.props.isInAlbum) {
                    return _('delete_album_file_prompt_text')
                }
                return getStringWithAmount(
                    this.props.selectedFiles.length,
                    _('delete_single_file_prompt'),
                    _('delete_files_prompt__format'),
                )
            },
            confirmLabel: _('delete_'),
        },
        [ConfirmableSelectionAction.RESTORE]: {
            text: () =>
                getStringWithAmount(
                    this.props.selectedFiles.length,
                    _('restore_single_file_prompt'),
                    _('restore_files_prompt__format'),
                ),
            confirmLabel: _('restore'),
        },
        [ConfirmableSelectionAction.COPY_TO_TIMELINE]: {
            text: () => _('copy_files_to_timeline'),
            confirmLabel: _('add_files'),
        },
        [ConfirmableSelectionAction.PERM_DELETE]: {
            text: () =>
                getStringWithAmount(
                    this.props.selectedFiles.length,
                    _('permanently_delete_single_file_prompt'),
                    _('permanently_delete_files_prompt__format'),
                ),
            confirmLabel: _('delete_'),
        },
    }

    private onSelectionActionConfirm = () => {
        if (this.props.selectionAction !== undefined) {
            this.props.onConfirmUserAction(
                this.props.selectionAction,
                this.props.jobID,
                this.props.selectedFiles,
                this.props.timelineId,
            )
        }

        if (this.props.onSelectionActionCompleted) {
            this.props.onSelectionActionCompleted()
        }
        this.props.onConfirmPromptHandled()
    }

    public render() {
        const { selectionAction } = this.props
        if (selectionAction !== undefined) {
            return (
                <ConfirmPromptOverlay
                    onConfirm={this.onSelectionActionConfirm}
                    onCancel={this.props.onConfirmPromptHandled}
                    confirmText={this.confirms[selectionAction].confirmLabel}>
                    {this.confirms[selectionAction].text()}
                </ConfirmPromptOverlay>
            )
        }

        return null
    }
}

const mapStateToProps = (
    state: StateOfSelector<typeof getSelectedFileIDsforJob> &
        StateOfSelector<typeof getSelectionAction> &
        StateOfSelector<typeof getTimelineJobID>,
    ownProps: OwnProps,
): StateProps => ({
    selectedFiles:
        ownProps.selectionOverride ||
        getSelectedFileIDsforJob(state, ownProps.jobID),
    selectionAction: getSelectionAction(state),
    timelineId: getTimelineJobID(state),
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    onConfirmUserAction: (
        type: ConfirmableSelectionAction,
        jobID: JobID,
        files: FileID[],
        timelineId?: JobID,
    ) => {
        selectionActionConfirm[type](dispatch, jobID, files, timelineId)
        // all actions other than copy to timeline should deselect files
        if (type !== ConfirmableSelectionAction.COPY_TO_TIMELINE) {
            dispatch(FilesWereDeselected(files))
        }
    },
    onConfirmPromptHandled: () => dispatch(SelectionActionConfirmHandled()),
})

export const SelectionActionDialogPlacement = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DialogComponent)
