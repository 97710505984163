import styled from 'styled-components'
import { colors, fontSize, mediaQueries } from '~/assets/styleConstants'
import type {
    CaptureStripeProduct,
    PlanGroup,
} from '~/state/storagePlan/selectors'
import { bytesToSize } from '~/utilities/fileSizeFormatting'
import { StoragePlanSection } from './StoragePlanSection'

export const ProductRow = styled.div`
    grid-column: 1/4;
    display: grid;
    grid-template-columns: 2fr 3fr 3fr;
    justify-items: center;
    align-items: center;
    text-align: center;
    padding: ${(props: { isNewSignup?: boolean }) =>
        props.isNewSignup ? '16px 16px 0px 16px' : '16px 24px'};
    font-size: ${fontSize.small_14};
    font-weight: 600;

    ${mediaQueries.mobile} {
        padding: ${(props) => (props.isNewSignup ? '4px 0px' : '16px 0px')};
    }
`

export const ProductSize = styled.div`
    font-size: ${fontSize.large_24};
    font-weight: bold;
    color: ${(props: { isCurrent?: boolean; isNewSignup?: boolean }) =>
        props.isCurrent && colors.captureBlue};

    ${mediaQueries.mobile} {
        font-size: ${(props) =>
            props.isNewSignup ? fontSize.medium_18 : 'inherit'};
    }
`

type Props = {
    group: PlanGroup
    selectedPlan: CaptureStripeProduct | undefined
    currentPlan: CaptureStripeProduct | undefined
    selectPlan: (plan?: CaptureStripeProduct) => void
    hideWarningText: (arg0: boolean) => void
    isNewSignup?: boolean
}

export const StoragePlanProduct = (props: Props) => {
    const {
        group,
        selectedPlan,
        currentPlan,
        selectPlan,
        hideWarningText,
        isNewSignup,
    } = props
    const { monthly, yearly, size } = group
    const [monthlySelected, yearlySelected] = [
        monthly.id === selectedPlan?.id,
        yearly.id === selectedPlan?.id,
    ]
    const [monthlySubscribed, yearlySubscribed] = [
        monthly.id === currentPlan?.id,
        yearly.id === currentPlan?.id,
    ]
    return (
        <ProductRow isNewSignup={isNewSignup}>
            <ProductSize
                isCurrent={monthlySubscribed || yearlySubscribed}
                isNewSignup={isNewSignup}>
                {bytesToSize(size)}
            </ProductSize>
            <StoragePlanSection
                price={monthly.priceString}
                onClick={() => {
                    selectPlan(monthly)
                    hideWarningText(false)
                }}
                isSelected={monthlySelected}
                isSubscribed={monthlySubscribed}
                canPurchase={monthly.unavailableReason === undefined}
            />
            <StoragePlanSection
                price={`${yearly.currency} ${Math.ceil(yearly.price / 1200)}`}
                onClick={() => {
                    selectPlan(yearly)
                    hideWarningText(false)
                }}
                isSelected={yearlySelected}
                isSubscribed={yearlySubscribed}
                canPurchase={yearly.unavailableReason === undefined}
            />
        </ProductRow>
    )
}
