import * as React from 'react'
import styled from 'styled-components'
import { _ } from '~/assets/localization/util'
import { colors, fontSize } from '~/assets/styleConstants'
import {
    LEGAL_LINK,
    PRIVACY_STATEMENT_LINK,
    TERMS_OF_SERVICE_LINK,
} from '~/config/constants'
import { TelenorCaptureLogo } from '../Icons/TelenorCaptureLogo'
import { BorderedTextButton } from './IconTextButton'

const Wrapper = styled.div`
    width: 100%;
    margin-top: 40px;
    padding: 0 24px;
    box-sizing: border-box;
`
const FooterWrapper = styled.div`
    border-top: 1px solid rgba(0, 0, 0, 0.14);
    padding: 24px 0;
    font-size: ${fontSize.xSmall_10};
    text-align: center;
`

const LogoWrapper = styled.div`
    display: flex;
    justify-content: center;
`

const ButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 16px 0;
`

const FooterButton = styled(BorderedTextButton)`
    max-width: 152px;
    min-height: 25px;
    flex: 1 1 100%;
    border: 1px solid
        ${(props: { color?: string; isFocused?: boolean }) =>
            props.color || colors.captureBlue};
    margin: 0 6px;
    font-size: ${fontSize.small_12};
    color: ${(props) => props.color || colors.captureBlue};
    padding: 2px 5px;

    ${(props) => (props.isFocused ? '&, ' : '')} &:focus {
        box-shadow: 0 0 18px 4px white;
    }
`

const SimpleFooterWrapper = styled.div`
    font-size: ${fontSize.small_14};
    font-weight: lighter;
    text-align: center;
    user-select: none;
`

const VersionNumber = styled.div`
    font-size: ${fontSize.small_12};
    font-weight: lighter;
    text-align: right;
`

export const SimpleTelenorCaptureFooter: React.FunctionComponent = () => {
    const d = new Date()
    const currentYear = d.getFullYear()
    return (
        <>
            <SimpleFooterWrapper>
                {currentYear} © Telenor Norway
            </SimpleFooterWrapper>
            <VersionNumber>v. {import.meta.env.VITE_VERSION}</VersionNumber>
        </>
    )
}

export const footerBtnItems: Record<
    FooterItem,
    { text: string; onItemClick: () => void; cyKey: string }
> = {
    ToS: {
        text: _('terms_of_service'),
        onItemClick: () => window.open(TERMS_OF_SERVICE_LINK, '_blank'),
        cyKey: 'footer__ToSBtn',
    },
    pS: {
        text: _('privacy_statement'),
        onItemClick: () => window.open(PRIVACY_STATEMENT_LINK, '_blank'),
        cyKey: 'footer__pSBtn',
    },
    legal: {
        text: _('legal'),
        onItemClick: () => window.open(LEGAL_LINK, '_blank'),
        cyKey: 'footer__legalBtn',
    },
}
class Footer extends React.Component<{
    logo: React.ReactNode
    linkColor?: string
    focusedItem?: FooterItem
}> {
    public render() {
        const buttons = footerItems.map((b) => (
            <FooterButton
                key={b}
                isFocused={this.props.focusedItem === b}
                color={this.props.linkColor}
                onClick={footerBtnItems[b].onItemClick}
                text={footerBtnItems[b].text}
                data-cy={footerBtnItems[b].cyKey}
            />
        ))

        return (
            <Wrapper>
                <FooterWrapper id="telenor-capture-footer">
                    <LogoWrapper>{this.props.logo}</LogoWrapper>
                    <ButtonDiv>{buttons}</ButtonDiv>
                    <SimpleTelenorCaptureFooter />
                </FooterWrapper>
            </Wrapper>
        )
    }
}

const telenorCaptureLogo = <TelenorCaptureLogo width={153} height={43} />
export const TelenorCaptureFooter = () => <Footer logo={telenorCaptureLogo} />

export type FooterItem = 'ToS' | 'pS' | 'legal'
export const footerItems: FooterItem[] = ['ToS', 'pS', 'legal']
