import {
    AlbumThumbnail,
    ContextMenu,
    DropdownMenu,
} from '@capture/capture-components'
import SvgDeleteForever from '@capture/capture-components/src/icons/DeleteForever'
import SvgRestore from '@capture/capture-components/src/icons/Restore'
import SvgExport from '@capture/capture-components/src/icons/Export'
import { _ } from '~/assets/localization/util'
import { ReadonlyTooltip } from '~/components/Common/ReadonlyTooltip'

interface TrashAlbumEntryProps {
    coverUrl?: URLstring
    name: string
    remainingDays: string
    subtitle: string
    deleteAlbum: () => void
    exportAlbum: () => void
    restoreAlbum: () => void
}

export const TrashAlbumEntry = ({
    coverUrl,
    name,
    remainingDays,
    subtitle,
    deleteAlbum,
    exportAlbum,
    restoreAlbum,
}: TrashAlbumEntryProps) => {
    return (
        <ContextMenu.Root>
            <ContextMenu.Trigger>
                <AlbumThumbnail
                    title={name || _('unnamed_album_placeholder')}
                    subtitle={subtitle}
                    coverPhoto={coverUrl}>
                    <AlbumThumbnail.TopRight>
                        <AlbumThumbnail.MenuButton>
                            <ReadonlyTooltip
                                renderElement={(isReadOnlyUser) => (
                                    <DropdownMenu.Item
                                        onSelect={restoreAlbum}
                                        id="restore"
                                        icon={SvgRestore}
                                        disabled={isReadOnlyUser}>
                                        {_('restore')}
                                    </DropdownMenu.Item>
                                )}
                            />
                            <DropdownMenu.Item
                                onSelect={exportAlbum}
                                id="export"
                                icon={SvgExport}>
                                {_('export')}
                            </DropdownMenu.Item>
                            <DropdownMenu.Item
                                onSelect={deleteAlbum}
                                id="delete"
                                icon={SvgDeleteForever}>
                                {_('delete_')}
                            </DropdownMenu.Item>
                        </AlbumThumbnail.MenuButton>
                    </AlbumThumbnail.TopRight>

                    <AlbumThumbnail.BottomRight>
                        {remainingDays}
                    </AlbumThumbnail.BottomRight>
                </AlbumThumbnail>
                <ContextMenu.Content>
                    <ReadonlyTooltip
                        renderElement={(isReadOnlyUser) => (
                            <ContextMenu.Item
                                onSelect={restoreAlbum}
                                id="restore"
                                icon={SvgRestore}
                                disabled={isReadOnlyUser}>
                                {_('restore')}
                            </ContextMenu.Item>
                        )}
                    />
                    <ContextMenu.Item
                        onSelect={exportAlbum}
                        id="export"
                        icon={SvgExport}>
                        {_('export')}
                    </ContextMenu.Item>
                    <ContextMenu.Item
                        onSelect={deleteAlbum}
                        id="delete"
                        icon={SvgDeleteForever}>
                        {_('delete_')}
                    </ContextMenu.Item>
                </ContextMenu.Content>
            </ContextMenu.Trigger>
        </ContextMenu.Root>
    )
}
