import { createSelector } from 'reselect'
import type { CarouselViewerNode } from '~/components/CarouselView'
import { getElementIndex, inArray } from '~/utilities/arrayUtils'
import { elementSizeCalculator } from '~/utilities/elementSizeCalculator'
import type { AlbumFile } from '../album/selectors'
import { getAlbumFiles, isPublicAlbum } from '../album/selectors'
import {
    getContextMenuTarget,
    getSideFileInfoState,
} from '../contextMenu/selectors'
import type { FileMetadata } from '../fileMetadata/actions'
import { getFileMetadata } from '../fileMetadata/selectors'
import type { ExtendedJobFile } from '../files/reducer'
import type { BasicViewFile, CaptureFile } from '../files/selectors'
import {
    getCaptureFilesForJob,
    makeThumbForJobFilesSelector,
} from '../files/selectors'
import { isShareJob, isStoryJob } from '../jobInfo/selectors'
import { getLastSeenElementForJob } from '../lastSeenElement/selectors'
import type { TimelineSectionReference } from '../timeline'
import type { ImageGroup } from '../timeline/selectors'
import {
    getFilteredTimelineSectionCounts,
    getIsRecentFilterActive,
    getRecentFilesFiltered,
    getTimelineImageGroups,
} from '../timeline/selectors'
import {
    getViewportHeight,
    getViewportWidth,
    isMobileMode,
} from '../viewMode/selectors'
import type { SideModuleContentType } from './actions'
import {
    getCurrentSideModuleContent,
    getCurrentViewInfo,
    isFullscreenMode,
    isSideModuleExpanded,
} from './pureSelectors'
import type { StateWithCarouselViewer } from './reducer'

type JobType = 'timeline' | 'albumPrivate' | 'albumPublic' | 'share'
export const getCurrentViewJobType = (
    state: StateOfSelector<typeof getCurrentViewInfo> &
        StateOfSelector<typeof isShareJob> &
        StateOfSelector<typeof isStoryJob>,
): JobType | undefined => {
    const current = getCurrentViewInfo(state)

    if (current !== undefined) {
        const { jobID } = current
        if (isStoryJob(state, jobID)) {
            return isPublicAlbum(state, jobID) ? 'albumPublic' : 'albumPrivate'
        }
        if (isShareJob(state, jobID)) {
            return 'share'
        }
        return 'timeline'
    }
}

type ViewJobTypeState = StateOfSelector<typeof getCurrentViewJobType> &
    StateOfSelector<typeof getCurrentViewInfo> &
    StateOfSelector<typeof getAlbumFiles> &
    StateOfSelector<typeof getCaptureFilesForJob> &
    StateOfSelector<typeof getTimelineCarouselFiles>

type JobTypeSelector<T> = (
    state: ViewJobTypeState,
    currentJob: JobID,
    type: JobType,
) => T
type JobTypeCases<T> = Record<JobType, JobTypeSelector<T>>
const makeViewJobTypeCaseSelector =
    <T>(caseSelectors: JobTypeCases<T>) =>
    (state: ViewJobTypeState): T | undefined => {
        const type = getCurrentViewJobType(state)
        const current = getCurrentViewInfo(state)

        if (type && current) {
            switch (type) {
                case 'albumPublic':
                    return caseSelectors.albumPublic(state, current.jobID, type)
                case 'albumPrivate':
                    return caseSelectors.albumPrivate(
                        state,
                        current.jobID,
                        type,
                    )
                case 'share':
                    return caseSelectors.share(state, current.jobID, type)
                case 'timeline':
                    return caseSelectors.timeline(state, current.jobID, type)
            }
        }
    }

type NotLoadedFile = {
    timelineMonth: MonthRef
}
type TimelineCarouselFile = CaptureFile | NotLoadedFile | BasicViewFile
export const isNotLoadedFile = (
    f: CaptureFile | AlbumFile | ThumbFile | BasicViewFile | NotLoadedFile,
): f is NotLoadedFile => 'timelineMonth' in f

export const getTimelineCarouselFiles = createSelector(
    getTimelineImageGroups,
    (timelineGroups: ImageGroup[]): TimelineCarouselFile[] => {
        return Array.prototype.concat.apply(
            [],
            timelineGroups.map(
                (
                    g, // TODO: Use groups.flatMap with Node>11
                ) =>
                    g.images.map<TimelineCarouselFile>((file) => {
                        if (file) {
                            return file
                        }

                        // return not loaded file
                        const [year, month] = g.groupKey
                            .split('-')
                            .map((s) => parseInt(s, 10))
                        return { timelineMonth: { year, month } }
                    }),
            ),
        )
    },
)

export const getCurrentViewerFiles = makeViewJobTypeCaseSelector({
    albumPrivate: (state, currentJob, type) => ({
        type,
        files: getAlbumFiles(state, currentJob),
    }),
    albumPublic: (state, currentJob, type) => ({
        type,
        files: getAlbumFiles(state, currentJob),
    }),
    share: (state, currentJob, type) => ({
        type,
        files: [...getCaptureFilesForJob(state, currentJob)].reverse(),
    }),
    timeline: (state, _currentJob, type) => ({
        type,
        files: getTimelineCarouselFiles(state),
    }),
})

export const getCurrentThumbIndex = createSelector(
    getCurrentViewerFiles,
    getCurrentViewInfo,
    (viewerFiles, currentViewInfo): number => {
        if (currentViewInfo && viewerFiles) {
            return getElementIndex(viewerFiles.files, (f) => {
                if (!isNotLoadedFile(f)) {
                    return f.fileID === currentViewInfo.fileID
                }
                return false
            })
        }
        return -1
    },
)

type DisplayedTimelineSectionsState = StateOfSelector<
    typeof getFilteredTimelineSectionCounts
> &
    StateOfSelector<typeof getCurrentThumbIndex> &
    StateOfSelector<typeof getCurrentViewJobType>

export const getDisplayedTimelineSections = createSelector(
    getFilteredTimelineSectionCounts,
    getCurrentThumbIndex,
    getCurrentViewJobType,
    (_: DisplayedTimelineSectionsState, padding?: number) => padding || 6,
    (
        sections,
        thumbIndex,
        currentJobType,
        padding,
    ): TimelineSectionReference[] => {
        if (currentJobType !== 'timeline' || thumbIndex === -1) {
            return []
        }
        const visibleGroups: TimelineSectionReference[] = []
        const firstIndex = thumbIndex - padding
        const lastIndex = thumbIndex + padding
        let groupStartIndex = 0
        sections.forEach(({ sectionLength, ...section }) => {
            if (
                groupStartIndex <= lastIndex &&
                groupStartIndex + sectionLength > firstIndex &&
                sectionLength > 0
            ) {
                visibleGroups.push(section)
            }
            groupStartIndex += sectionLength
        })
        return visibleGroups
    },
)

const getValidNode = (
    file: CaptureFile | AlbumFile | BasicViewFile | NotLoadedFile,
) => {
    return isNotLoadedFile(file) ? null : file
}

export const shouldCarouselAutoNavigate = (state: StateWithCarouselViewer) =>
    state.carouselViewer.shouldAutoNavigate &&
    inArray([undefined, 'finished'], state.carouselViewer.videoPlayStatus)

export const getPlaybackOrder = (state: StateWithCarouselViewer) =>
    state.carouselViewer.playbackOrder

export const getCurrentViewerNode = createSelector(
    getCurrentThumbIndex,
    getCurrentViewerFiles,
    (currentOffset, viewerFiles): CarouselViewerNode | undefined => {
        if (currentOffset !== -1 && viewerFiles) {
            const { files, type } = viewerFiles
            const prevFile = files[currentOffset - 1] || null
            const nextFile = files[currentOffset + 1] || null
            const firstFile = getValidNode(files[0])
            const lastFile = getValidNode(files[viewerFiles.files.length - 1])
            // AlbumFile is superset of CaptureFile,
            // which makes ts infer type as 'album' instead of 'timeline' | 'share' | 'album'
            return {
                type,
                file: files[currentOffset],
                prev: prevFile,
                next: nextFile,
                first: firstFile,
                last: lastFile,
            } as CarouselViewerNode
        }
    },
)

export const getFileInNeedOfMetadata = createSelector(
    getCurrentViewerNode,
    getFileMetadata,
    getCurrentSideModuleContent,
    getContextMenuTarget,
    getSideFileInfoState,
    (
        currentNode: CarouselViewerNode | undefined,
        metadata: DictionaryOf<FileMetadata>,
        sideModuleContent: SideModuleContentType | undefined,
        contextMenuTarget: BasicViewFile | undefined,
        sideFileInfoState: boolean,
    ): BasicViewFile | undefined => {
        if (
            currentNode &&
            metadata[currentNode.file.fileID] === undefined &&
            sideModuleContent === 'info'
        ) {
            return currentNode.file
        }

        // Allowing file metadata syncing on file context menu info open
        if (
            contextMenuTarget &&
            metadata[contextMenuTarget.fileID] === undefined &&
            sideFileInfoState === true
        ) {
            return contextMenuTarget
        }
    },
)

const getCarouselContainerSize = createSelector(
    isMobileMode,
    getViewportWidth,
    getViewportHeight,
    isSideModuleExpanded,
    isFullscreenMode,
    (
        isMobile: boolean,
        viewWidth: number,
        viewHeight: number,
        isSideModuleExpanded: boolean,
        isFullscreen: boolean,
    ): TwoDimensions => {
        return {
            width:
                viewWidth -
                (isSideModuleExpanded && !(isMobile || isFullscreen) ? 360 : 0),
            height: viewHeight,
        }
    },
)

export const getFullscreenAlbumFileSizeProvider = createSelector(
    isMobileMode,
    getCarouselContainerSize,
    (isMobile, containerSize) => {
        const cal = elementSizeCalculator(containerSize, isMobile ? 0.2 : 0)

        return (file: ExtendedJobFile): TwoDimensions => {
            if (file.width === undefined || file.height === undefined) {
                return containerSize
            }

            return cal({
                width: file.width,
                height: file.height,
            })
        }
    },
)

export const getTVFileSizeProvider = createSelector(
    getViewportHeight,
    getViewportWidth,
    (height, width) => {
        const containerSize = { width: width * 0.96, height: height * 0.96 } // take safe area into account
        const cal = elementSizeCalculator(containerSize)

        return (file: ExtendedJobFile): TwoDimensions => {
            if (file.width === undefined || file.height === undefined) {
                return containerSize
            }

            return cal({
                width: file.width,
                height: file.height,
            })
        }
    },
)

type CarouselThumbURLsState = StateOfSelector<typeof getIsRecentFilterActive> &
    StateOfSelector<typeof getRecentFilesFiltered> &
    StateOfSelector<typeof getCurrentViewInfo> &
    StateOfSelector<ReturnType<typeof makeThumbForJobFilesSelector>>
const getCarouselThumbURLs = (
    state: CarouselThumbURLsState,
): Record<FileID, string> => {
    const recentsFilter = getIsRecentFilterActive(state)
    if (recentsFilter) {
        const files = getRecentFilesFiltered(state)
        const thumbs: Record<FileID, string> = {}
        files.forEach(
            (file: CaptureFile) => (thumbs[file.fileID] = file.thumbURLSmall),
        )
        return thumbs
    }
    const current = getCurrentViewInfo(state)
    if (current !== undefined) {
        const urlSelector = makeThumbForJobFilesSelector(256)
        return urlSelector(state, current.jobID)
    }

    return {}
}

export type ThumbFile = {
    fileID: FileID
    url: string
}
export type CarouselThumbFile = ThumbFile | NotLoadedFile
export const getCurrentThumbFiles = createSelector(
    getCurrentViewerFiles,
    getCarouselThumbURLs,
    (viewerFiles, thumbURLs): CarouselThumbFile[] => {
        if (viewerFiles !== undefined) {
            const { files } = viewerFiles
            return files.map((f) => {
                if (!isNotLoadedFile(f)) {
                    return {
                        fileID: f.fileID,
                        url: thumbURLs[f.fileID],
                    }
                }

                return f
            })
        }

        return []
    },
)

export const getLastViewAlbumFileIndex = (
    state: StateOfSelector<typeof getLastSeenElementForJob> &
        StateOfSelector<typeof isStoryJob> &
        StateOfSelector<typeof getAlbumFiles>,
    albumID: JobID,
) => {
    const lastSeenFile = getLastSeenElementForJob(state, albumID)
    if (lastSeenFile && isStoryJob(state, albumID)) {
        return getElementIndex(getAlbumFiles(state, albumID), (f) => {
            return f.fileID === lastSeenFile
        })
    }
}
