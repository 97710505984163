import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import type { Dispatch } from '~/state/common/actions'
import type { FileDimensions } from '~/state/files/actions'
import { FileDimensionsDiscovered } from '~/state/files/actions'
import type { BasicViewFile } from '~/state/files/selectors'
import type { ImageResolveValue } from '../Image/AlbumImage'
import { AlbumImage } from '../Image/AlbumImage'
import { FadeInContainer } from './StyledComponents'

const MediaWrapper = styled(FadeInContainer)`
    position: absolute;
    top: 0;
    left: 0;
`

type OwnProps = { file: BasicViewFile }
type DispatchProps = {
    doUpdateFileDimensions: (dimensions: FileDimensions) => void
}
type Props = OwnProps & DispatchProps
type ComponentState = { showFullThumb: boolean }

class _ImageDisplayer extends React.Component<Props, ComponentState> {
    public state: ComponentState = { showFullThumb: false }
    public componentDidUpdate(prevProps: Props) {
        if (
            this.props.file.fileID !== prevProps.file.fileID &&
            this.state.showFullThumb
        ) {
            this.setState({ showFullThumb: false })
        }
    }

    private onLargeThumbLoaded = (resolve: ImageResolveValue) => {
        if (resolve.succeeded) {
            this.setState({ showFullThumb: true })
            const hasNoDimensions =
                this.props.file.width === undefined ||
                this.props.file.height === undefined

            if (hasNoDimensions) {
                const { width, height } = resolve.dimensions
                this.props.doUpdateFileDimensions({
                    fileID: this.props.file.fileID,
                    width,
                    height,
                })
            }
        }
    }

    public render() {
        const { file } = this.props
        // small thumb should be preloaded by the time ImageDisplayer is mounted
        const imageSmall = (
            <AlbumImage
                key={
                    file.fileID /* Key added to avoid dom-node-reuse when image changes */
                }
                thumbURL={file.thumbURLSmall}
            />
        )
        const imageLarge = (
            <AlbumImage
                key={
                    file.fileID /* Key added to avoid dom-node-reuse when image changes */
                }
                thumbURL={file.thumbURLLarge}
                onImageResolve={this.onLargeThumbLoaded}
            />
        )
        // Gotcha (CAPWEB-1420):
        // When height/width is unknown, the AlbumImage will render a stretched image while loading it.
        // When it is loaded it will dispatch the size, and we'll get new props with file.height set.
        // Load hidden (to detect size) and redraw in visible state once the size is known.
        if (file.height === undefined) {
            return <div style={{ visibility: 'hidden' }}>{imageLarge}</div>
        }
        return (
            <>
                {imageSmall}
                <MediaWrapper isVisible={this.state.showFullThumb}>
                    {imageLarge}
                </MediaWrapper>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    doUpdateFileDimensions: (dimensions: FileDimensions) =>
        dispatch(FileDimensionsDiscovered([dimensions])),
})

export const ImageDisplayer = connect(null, mapDispatchToProps)(_ImageDisplayer)
