import type { PendingUserAction } from './actions'
import type { StateWithUserActions, UserActionsState } from './reducer'

export const getUserActionStatus = (
    state: StateWithUserActions,
): UserActionsState => state.userActions || {}

export const getUserActionPendingConfirm = (
    state: StateWithUserActions,
): PendingUserAction | undefined => {
    return state.userActions.pendingConfirm
}

export const getUserActionAlert = (
    state: StateWithUserActions,
): PendingUserAction | undefined => {
    return state.userActions.alerts[0]
}
