import * as React from 'react'
import styled from 'styled-components'
import SvgArrowBack from '@capture/capture-components/src/icons/ArrowBack'
import { _ } from '~/assets/localization/util'
import { colors, fontSize } from '~/assets/styleConstants'
import {
    BorderedTextButton,
    FilledTextButton,
    IconTextButton,
} from '../Common/IconTextButton'
import { PageWrapper } from '../Common/PageWrapper'
import { CaptureLogoVertical } from '../Icons/CaptureLogoVertical'
import { TwoAreasTopNavBar } from '../Navigation/TwoAreasTopNavBar'

const Content = styled.div`
    text-align: center;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Text = styled.div`
    font-size: ${fontSize.large_22};
    color: ${colors.captureGrey800};
    margin: 32px;
`

const Buttons = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    & > div {
        margin: 8px;
        flex: 0 1 144px;
    }
`

type Props = {
    text: string
    onRegister: () => void
    onSignIn: () => void
    goBack: () => void
    fromInsideApp?: boolean
    showCreateAccount?: boolean
}
export class LoginRequiredPage extends React.Component<Props> {
    public render() {
        const backButton = (
            <IconTextButton
                onClick={this.props.goBack}
                icon={SvgArrowBack}
                text={_('back')}
            />
        )

        return (
            <PageWrapper
                navBar={
                    this.props.fromInsideApp ? undefined : (
                        <TwoAreasTopNavBar left={backButton} />
                    )
                }
                hideFooter={true}>
                <Content>
                    <CaptureLogoVertical size={92} />
                    <Text>{this.props.text}</Text>
                    <Buttons>
                        {this.props.fromInsideApp ||
                            this.props.showCreateAccount === false || (
                                <BorderedTextButton
                                    onClick={this.props.onRegister}
                                    text={_('create_account')}
                                    color={colors.captureBlue}
                                    hoverColor={colors.captureBlue}
                                    hoverFillColor={colors.captureBlue100}
                                    data-cy={'create_account_button'}
                                />
                            )}
                        <FilledTextButton
                            fontSize={
                                this.props.fromInsideApp
                                    ? fontSize.large_22
                                    : undefined
                            }
                            onClick={this.props.onSignIn}
                            text={_('login')}
                            hoverColor={colors.captureBlue}
                            hoverFillColor={colors.captureBlue100}
                            data-cy={'sign_in_button'}
                        />
                    </Buttons>
                </Content>
            </PageWrapper>
        )
    }
}
