import styled from 'styled-components'
import { _, getStringWithAmount } from '~/assets/localization/util'
import { fontSize } from '~/assets/styleConstants'

const NoticeWrapper = styled.span`
    font-size: ${fontSize.small_14};
    color: white;
`

export const DeletionNotice: React.FunctionComponent<{
    daysRemaining: number
}> = ({ daysRemaining }) => (
    <NoticeWrapper>
        <span>
            {daysRemaining === 0
                ? _('deleted_today')
                : getStringWithAmount(
                      daysRemaining,
                      _('one_day'),
                      _('num_days__format'),
                  )}
        </span>
    </NoticeWrapper>
)
