import styled from 'styled-components'
import { VideoIndicator } from '@capture/client/components/Common/VideoIndicator'
import { GridElement } from '@capture/client/components/GridView/GridElement'
import type { CaptureFile } from '@capture/client/state/files/selectors'
import type { GridElementProps } from '@capture/client/utilities/gridElementSizeCalculator'

const ThumbContainer = styled(GridElement).attrs({
    as: 'div',
})`
    position: relative;
`

const Thumb = styled.img`
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
`

const BottomThumbArea = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-height: 50px;
    height: 30%;
    padding: 5%;
    box-sizing: border-box;

    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
`

type Props = {
    file: CaptureFile
    gridElementStyle: GridElementProps
    onThumbClicked: (fileID: FileID) => void
}

const ThumbSectionItem = (props: Props) => {
    const { duration, fileID, thumbURLSmall, thumbURLMedium } = props.file

    const handleThumbClicked = () => {
        props.onThumbClicked(fileID)
    }

    const thumbURL =
        props.gridElementStyle.elementWidth < 200
            ? thumbURLSmall
            : thumbURLMedium

    return (
        <ThumbContainer {...props.gridElementStyle}>
            <Thumb
                alt="thumbnail"
                src={thumbURL}
                onClick={handleThumbClicked}
                onKeyUp={handleThumbClicked}
                role="presentation"
            />
            {duration && (
                <BottomThumbArea>
                    <VideoIndicator duration={duration} />
                </BottomThumbArea>
            )}
        </ThumbContainer>
    )
}

export default ThumbSectionItem
