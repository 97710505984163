import * as React from 'react'
import styled from 'styled-components'
import SvgClose from '@capture/capture-components/src/icons/Close'
import { colors } from '@capture/client/assets/styleConstants'
import { KeyEventProvider } from '@capture/client/components/Common/KeyEventProvider'
import { FullscreenPhotoOverlay } from '@capture/client/components/PhotoSelection/FullscreenPhotoOverlay'
import type { BasicViewFile } from '@capture/client/state/files/selectors'

const CloseBtnDiv = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 12px;
    cursor: pointer;
    z-index: 1001;
`

type Props = { file: BasicViewFile; onClose: () => void }
export const FullscreenMediaOverlay: React.FunctionComponent<Props> = ({
    file,
    onClose,
}) => (
    <KeyEventProvider methods={{ Escape: onClose }}>
        <FullscreenPhotoOverlay currentFile={file} />
        <CloseBtnDiv
            onClick={onClose}
            onKeyUp={onClose}
            role="button"
            tabIndex={0}>
            <SvgClose color={colors.white} />
        </CloseBtnDiv>
    </KeyEventProvider>
)
