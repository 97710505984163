import type { Store } from '@reduxjs/toolkit'
import { getAlbumFiles } from '~/state/album/selectors'
import {
    CarouselVideoPlayStatusChanged,
    CarouselViewEntered,
    CarouselCastingStarted,
    CarouselCastingStopped,
} from '~/state/carouselViewer/actions'
import { type CaptureFile, getVideoURL } from '~/state/files/selectors'
import { FileTarget } from '~/utilities/fileTarget'
import {
    isCarouselAutoplaying,
    isCarouselCasting,
} from '~/state/carouselViewer/pureSelectors'
import { getCurrentViewerNode } from '~/state/carouselViewer/selectors'
import { CHROMECAST_MESSAGE_CHANNEL } from '~/config/constants'
import { loadChromecast } from '../3rdParty/Chromecast'

type ContentType = 'image/jpeg' | 'video/mp4'
export type CaptureMediaItem = {
    url: string
    fileID: FileID
    jobID: JobID
    type: ContentType
    duration: number // milliseconds
}
export type SenderMessageData =
    | {
          type: 'loadSingleItem'
          itemInfo: CaptureMediaItem
          nextItems: CaptureMediaItem[]
      }
    | { type: 'autoPlayChanged'; isAutoPlaying: boolean }
    | { type: 'playOrPauseMedia' }
    | { type: 'seekVideo'; newTime: number }
    | { type: 'getReceiverState' }

export type ReceiverMessageData = {
    type: 'receiverState'
    itemInfo?: CaptureMediaItem
}

type CastCommands = {
    loadSingleItem: (itemInfo: CaptureFile, nextItems?: CaptureFile[]) => void
    autoPlayChanged: (isAutoPlaying: boolean) => void
    playOrPause: () => void
    seekVideo: (newTimeInSeconds: number) => void
    endCastSession: () => void
}

export type CastInstanceProps = {
    deviceName?: string
    remotePlayer: cast.framework.RemotePlayer
    remotePlayerController: cast.framework.RemotePlayerController
}
class CastSyncer {
    private instance: CastInstanceProps | null = null
    private currCastingFile?: JobFileReference = undefined
    private isAutoplaying = false

    private timeChangeSubs: Array<(time: number) => void> = []

    public constructor(private store: Store) {
        loadChromecast().then(({ remotePlayer, remotePlayerController }) => {
            remotePlayerController.addEventListener(
                cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
                this.onRemotePlayerConnectChange,
            )
            remotePlayerController.addEventListener(
                cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED,
                this.onRemotePlayerStateChange,
            )
            remotePlayerController.addEventListener(
                cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
                this.onRemotePlayerTimeChange,
            )
            this.instance = {
                remotePlayer,
                remotePlayerController,
            }
            window.addEventListener('beforeunload', () => {
                if (isCarouselCasting(this.store.getState())) {
                    this.castCommands.endCastSession()
                }
            })
            store.subscribe(this.consume)
        })
    }
    private fileToCaptureMediaItem = (file: CaptureFile): CaptureMediaItem => ({
        url:
            file.type === FileTarget.Pictures
                ? file.thumbURLLarge
                : getVideoURL(
                      this.store.getState(),
                      file.jobID,
                      file.fileID,
                      'v-high',
                  )!,
        fileID: file.fileID,
        jobID: file.jobID,
        type: file.type === FileTarget.Pictures ? 'image/jpeg' : 'video/mp4',
        duration: file.duration ? file.duration : 5000,
    })

    public subToPlayerTimeUpdates = (cb: (newTimeSecs: number) => void) =>
        this.timeChangeSubs.push(cb)
    public unsubFromPlayerTimeUpdates = (cb: (newTimeSecs: number) => void) =>
        (this.timeChangeSubs = this.timeChangeSubs.filter(
            (curr) => curr !== cb,
        ))

    public castCommands: CastCommands = {
        loadSingleItem: (itemInfo, nextItems) =>
            this.sendMessageToReceiver({
                type: 'loadSingleItem',
                itemInfo: this.fileToCaptureMediaItem(itemInfo),
                nextItems: nextItems
                    ? nextItems.map(this.fileToCaptureMediaItem)
                    : [],
            }),
        autoPlayChanged: (isAutoPlaying) =>
            this.sendMessageToReceiver({
                type: 'autoPlayChanged',
                isAutoPlaying,
            }),
        playOrPause: () =>
            this.sendMessageToReceiver({ type: 'playOrPauseMedia' }),
        seekVideo: (
            newTimeInSeconds, // convert to seconds here?
        ) =>
            this.sendMessageToReceiver({
                type: 'seekVideo',
                newTime: newTimeInSeconds,
            }),
        endCastSession: () =>
            cast.framework.CastContext.getInstance().endCurrentSession(true),
    }
    private sendMessageToReceiver = (data: SenderMessageData) => {
        const session =
            cast.framework.CastContext.getInstance().getCurrentSession()
        if (session) {
            session
                .sendMessage(CHROMECAST_MESSAGE_CHANNEL, data)
                .then((error) => error && console.log('message error:', error))
        } else {
            console.warn('no session...')
        }
    }
    private handleReceiverMsg = (_namespace: string, message: string) => {
        const parsed: ReceiverMessageData = JSON.parse(message)

        if (parsed.type === 'receiverState' && parsed.itemInfo) {
            this.store.dispatch(CarouselViewEntered(parsed.itemInfo))

            const session =
                cast.framework.CastContext.getInstance().getCurrentSession()
            if (session) {
                session.removeMessageListener(
                    CHROMECAST_MESSAGE_CHANNEL,
                    this.handleReceiverMsg,
                )
            }
        }
    }
    private onRemotePlayerConnectChange = (_event: { value: boolean }) => {
        const session =
            cast.framework.CastContext.getInstance().getCurrentSession()

        if (this.instance) {
            if (this.instance.remotePlayer.isConnected && session) {
                this.store.dispatch(
                    CarouselCastingStarted({
                        castDeviceName: session.getCastDevice().friendlyName,
                    }),
                )

                if (session.getMediaSession()) {
                    // receiver is already casting something
                    session.addMessageListener(
                        CHROMECAST_MESSAGE_CHANNEL,
                        this.handleReceiverMsg,
                    )
                    this.sendMessageToReceiver({ type: 'getReceiverState' })
                }
            } else if (this.instance.remotePlayer.isConnected === false) {
                this.store.dispatch(CarouselCastingStopped())
                this.currCastingFile = undefined
            }
        }
    }
    private onRemotePlayerStateChange = (
        event: cast.framework.RemotePlayerChangedEvent,
    ) => {
        const viewerNode = getCurrentViewerNode(this.store.getState())
        if (viewerNode && viewerNode.file.type === FileTarget.Movies) {
            switch (event.value) {
                case 'IDLE':
                    this.store.dispatch(
                        CarouselVideoPlayStatusChanged('finished'),
                    )
                    break
                case 'PAUSED':
                    this.store.dispatch(
                        CarouselVideoPlayStatusChanged('paused'),
                    )
                    break
                case 'PLAYING':
                case 'BUFFERING':
                    this.store.dispatch(
                        CarouselVideoPlayStatusChanged('playing'),
                    )
                    break
            }
        }
    }
    // seconds
    private onRemotePlayerTimeChange = (event: { value: number }) => {
        this.timeChangeSubs.forEach((cb) => cb(event.value))
    }

    private castItem = (item: CaptureFile) => {
        const allJobFiles = getAlbumFiles(this.store.getState(), item.jobID)
        const currIndex = allJobFiles.findIndex((f) => f.fileID === item.fileID)
        if (currIndex !== -1) {
            this.castCommands.loadSingleItem(
                item,
                allJobFiles.slice(currIndex + 1, currIndex + 5),
            )
        }
    }

    private consume = () => {
        const state = this.store.getState()

        if (state === undefined) {
            console.warn('CastSyncer: undefined state.')
            return
        }

        const viewerNode = getCurrentViewerNode(state)
        const isCasting = isCarouselCasting(state)

        if (!viewerNode || !isCasting) {
            return
        }

        const newAutoplayStatus = isCarouselAutoplaying(state)
        if (this.isAutoplaying !== newAutoplayStatus) {
            this.isAutoplaying = newAutoplayStatus
            this.castCommands.autoPlayChanged(newAutoplayStatus)
        }

        if (
            this.currCastingFile === undefined ||
            this.currCastingFile.fileID !== viewerNode.file.fileID
        ) {
            this.castItem(viewerNode.file)
        }

        this.currCastingFile = viewerNode.file
    }
}
let instance: CastSyncer | null = null
export const connectCastSyncer = (store: Store) =>
    (instance = new CastSyncer(store))
const getInstance = () => {
    if (instance === null) {
        throw new Error()
    }

    return instance
}

export const getCastCommander = () => getInstance().castCommands
export const PlayerTimeChanges_subscribe = (cb: (newTime: number) => void) =>
    getInstance().subToPlayerTimeUpdates(cb)
export const PlayerTimeChanges_unsubscribe = (cb: (newTime: number) => void) =>
    getInstance().unsubFromPlayerTimeUpdates(cb)
