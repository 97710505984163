import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import {
    downloadDeletedFiles,
    downloadFiles,
    downloadJob,
} from '~/API/download'
import { trackEvent, trackEventInternal } from '~/analytics/eventTracking'
import { _ } from '~/assets/localization/util'
import { colors } from '~/assets/styleConstants'
import {
    BorderedTextButton,
    FilledTextButton,
} from '~/components/Common/IconTextButton'
import { OptionsOverlay } from '~/components/Common/Overlay'
import type { FileOptionContext } from '~/components/FilesOptions/FilesOptionsPlacement'
import { SIZE_DOWNLOAD_LIMIT } from '~/config/constants'
import { FilesOptionsClosed } from '~/state/FilesOptions/actions'
import { BulkOfActions } from '~/state/common/actions'
import { StartPaginatedDownloadFlow } from '~/state/downloader/actions'
import type { FilesDownloadType } from '~/state/job/actions'
import { type FileInfoForPagination, paginateFiles } from '~/utilities/download'
import { bytesToSize } from '~/utilities/fileSizeFormatting'
import { isHEICFile } from '~/utilities/fileTarget'

const DownloadBody = styled.div`
    max-width: 500px;
    padding: 0 48px;
    display: flex;
    flex-direction: column;
`

const DownloadContent = styled.div`
    margin-bottom: 16px;
`

const BoldText = styled.span`
    font-weight: 600;
`

const ButtonWrapper = styled.div`
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;

    & > div {
        width: 250px;
    }
`

export type MaybePaginatedDownloadModalProps = {
    downloadType: FilesDownloadType
    jobID: JobID
    fileInfos: FileInfoForPagination[]
    size: number
    context: FileOptionContext
    zipFileName: string
    onCancel: () => void
}

const MaybePaginatedDownloadModal = ({
    downloadType,
    jobID,
    fileInfos,
    size,
    context,
    zipFileName,
    onCancel,
}: MaybePaginatedDownloadModalProps) => {
    const dispatch = useDispatch()

    const downloadAllAtOnce = async () => {
        dispatch(FilesOptionsClosed())
        trackEventInternal(
            `${context.toLowerCase()}_maybepaginated_download_files_all_at_once`,
            {
                count: fileInfos.length,
                downloadType,
                size,
            },
        )
        trackEvent(context, 'MultiFileDownload')

        if (downloadType === 'takeout') {
            await downloadJob(dispatch, {
                type: 'takeout',
                jobID,
                zipFileName,
            })
            return
        }

        if (downloadType === 'download_deleted') {
            await downloadDeletedFiles(dispatch, {
                jobID,
                fileIDs: fileInfos.map((f) => f.fileID),
                zipFileName,
            })
            return
        }

        await downloadFiles(dispatch, {
            type: downloadType,
            jobID,
            fileIDs: fileInfos.map((f) => f.fileID),
            hasHEIC: fileInfos.some((f) => isHEICFile(f.path)),
        })
    }

    const downloadPaginated = () => {
        const pages = paginateFiles(fileInfos, SIZE_DOWNLOAD_LIMIT, zipFileName)

        dispatch(
            BulkOfActions([
                StartPaginatedDownloadFlow({
                    downloadType,
                    jobID,
                    count: fileInfos.length,
                    size: size,
                    pages: pages,
                }),
                FilesOptionsClosed(),
            ]),
        )
        trackEventInternal(
            `${context.toLowerCase()}maybepaginated_download_files_paginated`,
            {
                count: fileInfos.length,
                downloadType,
                size,
                pages: pages.length,
            },
        )
        trackEvent(context, 'PaginatedDownload')
    }

    if (fileInfos.length === 0) {
        return null
    }

    const [beforeCount, afterCount] = _(
        'about_to_download_files__format',
    ).split('%file_count_size%')
    const fileCountText = _('files__format').replace(
        '%d',
        fileInfos.length.toString(),
    )

    const fileSizeText = `${fileCountText} (${bytesToSize(size)}) ${afterCount}`

    return (
        <OptionsOverlay
            onClose={onCancel}
            cancelButton={{
                onClick: onCancel,
                text: '',
            }}>
            <DownloadBody>
                <DownloadContent>
                    <p>
                        {beforeCount}
                        <BoldText>{fileSizeText}</BoldText>
                    </p>
                    <p>{_('about_to_download_paginated_description')}</p>
                </DownloadContent>
                <ButtonWrapper>
                    <FilledTextButton
                        text={_('download_in_smaller_parts')}
                        onClick={downloadPaginated}
                    />

                    <BorderedTextButton
                        text={_('download_all_at_once')}
                        onClick={downloadAllAtOnce}
                        color={colors.captureBlue}
                    />
                </ButtonWrapper>
            </DownloadBody>
        </OptionsOverlay>
    )
}

export default MaybePaginatedDownloadModal
