import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import type { CursorType } from '~/state/cursor/cursorSlice'
import { getCursorState } from '~/state/cursor/cursorSlice'
import type { Dispatch } from '~/state/common/actions'
import { _ } from '~/assets/localization/util'
import { setCurrentVisibleRanges } from '~/API/syncers/TimelineChunkSyncer'
import { TimelineCarousel } from '~/routing/pages'
import {
    FilesWereDeselected,
    FilesWereSelected,
} from '~/state/selectedFiles/actions'
import {
    getSelectedFileIDs,
    makeIsFileSelected,
} from '~/state/selectedFiles/selectors'
import type { TimelineSectionReference } from '~/state/timeline'
import type { TimelineFilterMode } from '~/state/timeline/reducers'
import type {
    ImageGroup,
    TimelineSection,
    ImageGroupItemReference,
} from '~/state/timeline/selectors'
import {
    getIsRecentFilterActive,
    getTimelineSections,
} from '~/state/timeline/selectors'
import { getElementIndex, inArray } from '~/utilities/arrayUtils'
import { isMobileDevice } from '~/utilities/device'
import {
    calcImagesPerRow,
    getElementSize,
} from '~/utilities/gridElementSizeCalculator'
import type { ImageGroupStyle } from '~/utilities/imageGroupStyle'
import type { WithRouterProps } from '~/utilities/navigation'
import { withRouter } from '~/utilities/navigation'
import { isBodyScrollable } from '~/utilities/preventBodyScroll'
import { localStorageGet, localStorageSet } from '~/utilities/webStorage'
import { ConditionalZoomExplainer } from '../Gesture/ZoomExplanation'
import type { VisibleElementInfo } from '../VirtualList/WindowedVirtualList'
import { WindowedVirtualList } from '../VirtualList/WindowedVirtualList'
import { GroupListEntry } from './GroupListEntry'
import {
    subscribeToShiftClick,
    unsubscribeFromShiftClick,
    withSelectionKeyControls,
} from './ShiftClickSelection'

const GroupsContainer = styled.div`
    position: relative;
    width: ${(props: { width: number; cursor: CursorType }) => props.width}px;
    margin: 0 auto;
    cursor: ${(props) => props.cursor};
`

const getGroupHeaderHeight = (style: ImageGroupStyle) => {
    return style.headerHeight + style.headerBottomGap
}

const layoutChanged = (
    oldStyle: ImageGroupStyle,
    newStyle: ImageGroupStyle,
) => {
    return (
        calcImagesPerRow(oldStyle) !== calcImagesPerRow(newStyle) ||
        getElementSize(oldStyle).width !== getElementSize(newStyle).width ||
        getElementSize(oldStyle).height !== getElementSize(newStyle).height ||
        getGroupHeaderHeight(oldStyle) !== getGroupHeaderHeight(newStyle)
    )
}

// Find the month, row index, and image index that makes sense to pivot around when layout changes.
const getPivotImageData = (
    topPos: number,
    style: ImageGroupStyle,
    imagesGrouped: ImageGroup[],
) => {
    const elementSize = getElementSize(style)
    const boundaryPos = topPos + elementSize.height
    // Lower edge of month image group must be below the boundary position for the pivot image.
    const month =
        imagesGrouped.filter(
            ({ height, position }) => position + height >= boundaryPos,
        )[0] || imagesGrouped[imagesGrouped.length - 1]
    const monthFirstRowPos = month.position + getGroupHeaderHeight(style)
    const rowIndex = Math.max(
        0,
        Math.floor((boundaryPos - monthFirstRowPos) / elementSize.height),
    )
    const imgIndex = rowIndex * calcImagesPerRow(style)

    return { month, rowIndex, imgIndex }
}

export type FileSelectionStatus =
    | 'NotSelected'
    | 'Selected'
    | 'ToBeSelected'
    | 'ToBeDeselected'
type OwnProps = {
    isInSelectMode: boolean
    imagesGrouped: ImageGroup[]
    filterBy?: TimelineFilterMode
    groupStyle: ImageGroupStyle
    disableSelection?: boolean
    showGroupHeaders?: boolean
    focusedItem?: ImageGroupItemReference
    scaleFocusedItem?: boolean
    updateLastSeenThumb?: (fileID: FileID) => void
    onItemClickOverride?: (fileID: FileID) => void
    onItemDoubleClick?: (fileID: FileID) => void
    transformHeader?: (groupKey: string) => React.ReactNode | string
    getAdditionalBottomAreaElements?: (
        fileID: FileID,
        indexInGroup: number,
    ) => React.ReactNode
    isAlbum?: boolean
    isSharedAlbum?: boolean
} & WithRouterProps
type StateProps = {
    getFileSelectionStatus: (id: FileID) => FileSelectionStatus
    selectedFiles: FileID[]
    timelineSections: TimelineSection[]
    hasRecentsFilter: boolean
    cursor: CursorType
}
type DispatchProps = {
    selectFile: (fileInfo: {
        fileID: FileID
        ctime?: number
        mtime: number
    }) => void
    selectGroup: (fileIDs: FileID[]) => void
    deselectFile: (fileInfo: {
        fileID: FileID
        ctime?: number
        mtime: number
    }) => void
    deselectGroup: (fileIDs: FileID[]) => void
}
export type Props = OwnProps & StateProps & DispatchProps

export type VisibleRange = { top: number; bottom: number }
type ComponentState = {
    currentlyVisibleGroups: string[]
    currentVisibleRange: VisibleRange // The range that the currentlyVisibleGroups are calculated from
    fileWithShiftSelectHint?: FileID // If set: File with this ID will have a shift-select-hint
}

const getVisibleRange = (relativeOffset = 0): VisibleRange => ({
    top: window.pageYOffset - relativeOffset,
    bottom: window.pageYOffset - relativeOffset + window.innerHeight,
})

const storedShiftSelectHint = localStorageGet('shiftSelectHint')
let allowShiftHint =
    !isMobileDevice.any() &&
    (!storedShiftSelectHint || // Never seen shift-hint before
        storedShiftSelectHint === 'shownOnce' || // Saw it on first image last time
        ((d) => d.setMonth(d.getMonth() - 1))(new Date()) >
            parseInt(storedShiftSelectHint, 10)) // More than a month since last shown
const selectionLengthForTrigger = !storedShiftSelectHint ? 1 : 3

class ImageGroupListInner_ extends React.Component<Props, ComponentState> {
    public state: ComponentState = {
        currentlyVisibleGroups: [],
        currentVisibleRange: { top: 0, bottom: window.innerHeight },
    }
    private groupListElem = React.createRef<HTMLDivElement>()
    private lastHandledScrollOffset = 0
    private scrollPositionAfterUpdate: number | undefined

    private getGroupsInRange = (
        props: Props,
        top: number,
        bottom: number,
    ): string[] => {
        return props.imagesGrouped
            .filter(
                (g) =>
                    // Bottom of group is below top of view and top of group is above bottom of view
                    g.position + g.height > top && g.position < bottom,
            )
            .map((g) => g.groupKey)
    }

    private maybeUpdateCurrentlyVisibleGroups = (props: Props) => {
        if (!this.groupListElem.current) {
            // We are not yet mounted or in the process of being unmounted while scrolling. No changes available
            return
        }

        const range = getVisibleRange(
            Math.min(window.pageYOffset, this.groupListElem.current.offsetTop),
        )

        const mustBeVisible = this.getGroupsInRange(
            props,
            range.top - 200,
            range.bottom + 200,
        )
        const mayBeKeptVisible = this.getGroupsInRange(
            props,
            range.top - 400,
            range.bottom + 400,
        )

        const current = this.state.currentlyVisibleGroups
        const next = mayBeKeptVisible.filter(
            (e) => inArray(mustBeVisible, e) || inArray(current, e),
        )

        // Convert header-strings to months and pass that to the TimelineSyncher to get months fetched.
        const sections = next.map((s): TimelineSectionReference => {
            const [y, m] = s.split('-')
            return {
                year: parseInt(y, 10),
                month: parseInt(m, 10),
                startDay: 1,
                endDay: 31,
            }
        })
        if (!this.props.hasRecentsFilter) {
            // The recent files are already fetched
            setCurrentVisibleRanges(sections)
        }

        this.setState({
            currentlyVisibleGroups: next,
            currentVisibleRange: range,
        })
    }

    private handleScroll = () => {
        if (
            isBodyScrollable() &&
            Math.abs(this.lastHandledScrollOffset - window.pageYOffset) > 30
        ) {
            this.lastHandledScrollOffset = window.pageYOffset
            // Handle the update outside the scroll-handler
            setTimeout(
                () => this.maybeUpdateCurrentlyVisibleGroups(this.props),
                1,
            )
        }
    }
    private clearShiftSelectHint = () => {
        allowShiftHint = false
        this.setState({ fileWithShiftSelectHint: undefined })
    }

    private getVisibleGroupData = (group: ImageGroup): VisibleElementInfo => {
        const selectionProps = this.props.disableSelection
            ? undefined
            : {
                  isInSelectMode: this.props.isInSelectMode,
                  onSelectFile: this.props.selectFile,
                  onSelectGroup: this.props.selectGroup,
                  onDeselectFile: this.props.deselectFile,
                  onDeselectGroup: this.props.deselectGroup,
                  fileWithShiftSelectHint: this.state.fileWithShiftSelectHint,
                  clearShiftSelectHint: this.clearShiftSelectHint,
              }

        return {
            key: group.groupKey,
            positionTop: group.position,
            height: group.height,
            content: (
                <GroupListEntry
                    {...group}
                    groupStyle={this.props.groupStyle}
                    visibleRange={this.state.currentVisibleRange}
                    onItemClick={
                        this.props.onItemClickOverride ||
                        ((fileID: FileID) =>
                            this.props.navigate(TimelineCarousel(fileID).url))
                    }
                    onItemDoubleClick={this.props.onItemDoubleClick}
                    selection={selectionProps}
                    focusedItem={this.props.focusedItem}
                    scaleFocusedItem={this.props.scaleFocusedItem}
                    transformHeader={this.props.transformHeader}
                    showHeader={this.props.showGroupHeaders}
                    isAlbum={this.props.isAlbum}
                    isSharedAlbum={this.props.isSharedAlbum}
                    getFileSelectionStatus={this.props.getFileSelectionStatus}
                    groupSelectionStatus={
                        group.selectionStatus || 'none-selected'
                    }
                    getAdditionalBottomAreaElements={
                        this.props.getAdditionalBottomAreaElements
                    }
                    isFirstVisibleMonth={group.position === 0 ? true : false} // img loading: 'eager' for first visible month
                />
            ),
        }
    }
    public componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)
        subscribeToShiftClick(this.clearShiftSelectHint)
        // make sure page is updated before checking scroll position
        setTimeout(() => {
            this.maybeUpdateCurrentlyVisibleGroups(this.props)
            this.lastHandledScrollOffset = window.pageYOffset
        }, 100)
    }

    public componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
        unsubscribeFromShiftClick(this.clearShiftSelectHint)
        if (this.props.updateLastSeenThumb) {
            let focusedFileID: string | undefined
            if (this.props.focusedItem) {
                const { group: focusedGroup, index } = this.props.focusedItem
                const groupIndex = getElementIndex(
                    this.props.imagesGrouped,
                    (g) => g.groupKey === focusedGroup,
                )

                const image = this.props.imagesGrouped[groupIndex].images[index]
                if (groupIndex !== -1 && image) {
                    focusedFileID = image.fileID
                }
            }

            const fileID = focusedFileID || this.getFileIDOfPivotImage()
            fileID && this.props.updateLastSeenThumb(fileID)
        }
    }

    public componentDidUpdate(prevProps: Props) {
        const doScrollToPositionAfterUpdate = this.getNextScrollPosition(
            this.props,
            prevProps,
        )
        if (doScrollToPositionAfterUpdate) {
            window.scrollTo(window.pageXOffset, this.scrollPositionAfterUpdate!)
        }
        // change in number of displayed months
        if (
            prevProps.imagesGrouped.length !== this.props.imagesGrouped.length
        ) {
            this.maybeUpdateCurrentlyVisibleGroups(this.props)
        }
        // Show shift-select-hint: on first file selected or when selecting 3rd file in a row
        if (
            allowShiftHint &&
            this.state.fileWithShiftSelectHint === undefined &&
            this.props.selectedFiles.length >= selectionLengthForTrigger &&
            this.props.selectedFiles.length ===
                prevProps.selectedFiles.length + 1
        ) {
            const recentlySelectedFiles = this.props.selectedFiles.slice(
                -selectionLengthForTrigger,
            )
            const group = this.props.imagesGrouped.find((g) =>
                g.images.some((f) => f?.fileID === recentlySelectedFiles[0]),
            )
            if (group) {
                const groupImgIDs = group.images.map((i) => i?.fileID)
                const firstIndex = groupImgIDs.findIndex(
                    (i) => i === recentlySelectedFiles[0],
                )
                // Validate that the recently selected images are neighbours in same group;
                if (
                    recentlySelectedFiles.every(
                        (id, i) => groupImgIDs[firstIndex + i] === id,
                    )
                ) {
                    // Shift-select hint will be triggered on last image in current selection
                    this.setState({
                        fileWithShiftSelectHint:
                            groupImgIDs[
                                firstIndex + selectionLengthForTrigger - 1
                            ],
                    })
                    localStorageSet(
                        'shiftSelectHint',
                        selectionLengthForTrigger === 1
                            ? 'shownOnce'
                            : Date.now().toString(),
                    )
                }
            }
        }
        // Remove visible selection-hint if selectMode closes or the user keeps selecting multiple times
        const shouldResetShiftSelectHint =
            this.state.fileWithShiftSelectHint !== undefined &&
            (!this.props.isInSelectMode ||
                (this.props.selectedFiles[
                    this.props.selectedFiles.length - 1
                ] !== this.state.fileWithShiftSelectHint &&
                    prevProps.selectedFiles[
                        prevProps.selectedFiles.length - 1
                    ] !== this.state.fileWithShiftSelectHint))
        if (shouldResetShiftSelectHint) {
            this.clearShiftSelectHint()
        }

        if (this.props.isAlbum) {
            const newName = prevProps.imagesGrouped[0].groupKey
            const oldName = this.state.currentlyVisibleGroups[0]
            const hasAlbumNameChanged = newName !== oldName

            if (
                this.state.currentlyVisibleGroups.length !== 0 &&
                hasAlbumNameChanged
            ) {
                this.setState({ currentlyVisibleGroups: [newName] })
            }
        }
    }
    private getFileIDOfPivotImage = () => {
        const { month, imgIndex } = getPivotImageData(
            this.state.currentVisibleRange.top,
            this.props.groupStyle,
            this.props.imagesGrouped,
        )

        return month.images[imgIndex]?.fileID
    }
    private findNewOffset = (
        newStyle: ImageGroupStyle,
        newImagesGrouped: ImageGroup[],
    ) => {
        const { month, rowIndex } = getPivotImageData(
            this.state.currentVisibleRange.top,
            this.props.groupStyle,
            this.props.imagesGrouped,
        )
        const newMonth = newImagesGrouped.filter(
            ({ groupKey }) => groupKey === month.groupKey,
        )[0]
        const monthDelta = newMonth.position - month.position
        const oldRowLength = calcImagesPerRow(this.props.groupStyle)
        const newRowLength = calcImagesPerRow(newStyle)
        const newRowIndex = Math.floor((rowIndex * oldRowLength) / newRowLength)

        const imageDelta =
            newRowIndex * getElementSize(newStyle).height -
            rowIndex * getElementSize(this.props.groupStyle).height
        const headerDelta =
            getGroupHeaderHeight(newStyle) -
            getGroupHeaderHeight(this.props.groupStyle)
        return window.pageYOffset + monthDelta + headerDelta + imageDelta
    }
    private getNextScrollPosition = (nextProps: Props, prevProps: Props) => {
        if (layoutChanged(prevProps.groupStyle, nextProps.groupStyle)) {
            this.scrollPositionAfterUpdate = this.findNewOffset(
                nextProps.groupStyle,
                nextProps.imagesGrouped,
            )
            return true
        }
        if (prevProps.filterBy !== nextProps.filterBy) {
            if (nextProps.hasRecentsFilter) {
                return true
            }
            // filter changes: Compensate for content-shift by scrolling to where the currently visible content will be
            const topGroup = this.state.currentlyVisibleGroups[0]
            const oldMonth = prevProps.imagesGrouped.find(
                ({ groupKey }) => groupKey === topGroup,
            )
            const newMonth = nextProps.imagesGrouped.find(
                ({ groupKey }) => groupKey <= topGroup,
            )
            if (oldMonth && newMonth) {
                // Align top of old and new months
                let delta = newMonth.position - oldMonth.position
                // If top of month is not visible: Take actions to avoid aligning things off screen (= odd jumps)
                const { top, bottom } = this.state.currentVisibleRange
                if (oldMonth.position < top) {
                    if (
                        oldMonth.position + oldMonth.height < bottom &&
                        oldMonth.position + oldMonth.height >= top
                    ) {
                        // The bottom of month is visible: Align the bottoms of the months
                        delta += newMonth.height - oldMonth.height
                    } else {
                        // Neither bottom or top of the month is visible: Align month so that its header is visible.
                        // Vertically align month on screen if entire month fits in view.
                        const gapForCenter = Math.max(
                            (window.innerHeight - newMonth.height) / 2,
                            0,
                        )
                        delta = newMonth.position - top - gapForCenter
                    }
                }
                if (delta !== 0) {
                    this.scrollPositionAfterUpdate = window.pageYOffset + delta
                    return true
                }
            }
        }
        return false
    }
    public render(): JSX.Element {
        const last = this.props.imagesGrouped.slice(-1)[0]
        const visibleListData = this.props.imagesGrouped
            .filter((g) =>
                inArray(this.state.currentlyVisibleGroups, g.groupKey),
            )
            .map(this.getVisibleGroupData)

        return (
            <GroupsContainer
                ref={this.groupListElem}
                width={this.props.groupStyle.width}
                cursor={this.props.cursor}>
                <ConditionalZoomExplainer
                    shouldExplainZoom={this.props.isInSelectMode}
                    hintText={_('hint_longpress_show_full_image')}>
                    <WindowedVirtualList
                        totalHeight={
                            last &&
                            last.position +
                                last.height +
                                (this.props.groupStyle.verticalOffset || 0)
                        }
                        elements={visibleListData}
                    />
                </ConditionalZoomExplainer>
            </GroupsContainer>
        )
    }
}

type ImageGroupListStoreState = StateOfSelector<
    ReturnType<typeof makeIsFileSelected>
> &
    StateOfSelector<typeof getSelectedFileIDs> &
    StateOfSelector<typeof getTimelineSections> &
    StateOfSelector<typeof getIsRecentFilterActive> &
    StateOfSelector<typeof getCursorState>

const mapStateToProps = (state: ImageGroupListStoreState): StateProps => ({
    getFileSelectionStatus: (fileID: FileID) =>
        makeIsFileSelected(fileID)(state) ? 'Selected' : 'NotSelected',
    selectedFiles: getSelectedFileIDs(state),
    timelineSections: getTimelineSections(state),
    hasRecentsFilter: getIsRecentFilterActive(state),
    cursor: getCursorState(state),
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    selectFile: (file) => dispatch(FilesWereSelected([file.fileID])),
    selectGroup: (fileIDs: FileID[]) => dispatch(FilesWereSelected(fileIDs)),
    deselectFile: (file) => dispatch(FilesWereDeselected([file.fileID])),
    deselectGroup: (fileIDs: FileID[]) =>
        dispatch(FilesWereDeselected(fileIDs)),
})

const ImageGroupListInner = withRouter(ImageGroupListInner_)

const ConnectedGroupList = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ImageGroupListInner)

const ImageGroupListWithShiftSelection = connect(
    mapStateToProps,
    mapDispatchToProps,
)(withSelectionKeyControls(ImageGroupListInner))

export const ImageGroupList = isMobileDevice.any()
    ? ConnectedGroupList
    : ImageGroupListWithShiftSelection
