import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { _, getStringWithAmount } from '~/assets/localization/util'
import {
    deleteAlbum,
    setAlbumCoverPhoto,
    unsubscribeFromAlbum,
} from '~/API/album'
import { deleteMultipleTrashFiles, restoreMultipleFiles } from '~/API/job'
import { colors, fontSize } from '~/assets/styleConstants'
import { Pages } from '~/routing'
import { Album } from '~/routing/pages'
import { StatusNotificationDismissed } from '~/state/statusNotifications/actions'
import type { StatusNotification } from '~/state/statusNotifications/reducer'
import { RippleLoader } from '../Common/RippleLoader'

const ToastWrapper = styled.div`
    width: 100%;
    height: 48px;
    padding: 4px 16px;
    background: ${(props: { toastColor: string }) => props.toastColor};
    box-shadow: rgba(0, 0, 0, 0.3) 1px 2px 5px;
    color: white;
    box-sizing: border-box;
    display: flex;
    align-items: center;
`

const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    font-size: ${fontSize.small_14};
    justify-content: space-between;

    & span {
        cursor: hover;
    }
`

const ToastActionWrapper = styled.span`
    cursor: pointer;
    white-space: nowrap;
`

type ToastOptions = {
    supressRetry?: boolean
}

type Props = {
    toast: StatusNotification
    isOwnerOfJob: (job: JobID) => boolean
    getJobName: (job: JobID) => string
    options?: ToastOptions
}

const useToastComposition = (
    toast: StatusNotification,
    isOwnerOfJob: (job: JobID) => boolean,
    getJobName: (job: JobID) => string,
    options?: ToastOptions,
) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const dismissToast = () => dispatch(StatusNotificationDismissed(toast.id))
    switch (toast.type) {
        case 'albumCoverPhotoSet':
            return {
                label: _('toast__coverPhoto_was_set'),
                dismissToast,
            }
        case 'setAlbumCoverPhotoFailed': {
            const { relatedJobID, relatedFile } = toast
            return {
                label: _('set_cover_photo_failed_message'),
                toastColor: colors.captureMagenta,
                action: {
                    label: _('retry'),
                    method: () =>
                        setAlbumCoverPhoto(dispatch, relatedJobID, relatedFile),
                },
                dismissToast,
            }
        }
        case 'autoCreatedAlbum': {
            const job = toast.relatedJobID
            return {
                label: _('toast__album_auto_crated__format').replace(
                    '%album_name%',
                    toast.relatedJobName,
                ),
                action: {
                    label: _('toast__see_album'),
                    method: () => navigate(Album(job).url),
                },
                dismissToast,
            }
        }
        case 'albumChangedToPrivate':
            return {
                label: _('toast__album_changed_to_private'),
                dismissToast,
            }
        case 'deleteAlbumFailed': {
            const { relatedJobID } = toast
            return {
                label: _('toast__delete_album_failed_message'),
                toastColor: colors.captureMagenta,
                action: {
                    label: _('retry'),
                    method: () => deleteAlbum(dispatch, relatedJobID, navigate),
                },
                dismissToast,
            }
        }
        case 'unsubscribeAlbumFailed': {
            const { relatedJobID } = toast
            return {
                label: _('toast__unsubscribe_album_failed_message'),
                toastColor: colors.captureMagenta,
                action: {
                    label: _('retry'),
                    method: () => unsubscribeFromAlbum(dispatch, relatedJobID),
                },
                dismissToast,
            }
        }
        case 'filesDeleted': {
            const { relatedJob, relatedFiles } = toast
            const label = getStringWithAmount(
                relatedFiles.length,
                _('toast__single_file_deleted'),
                _('toast__multiple_files_deleted__format'),
            )
            const restoreAction = {
                label: _('undo'),
                method: () => {
                    restoreMultipleFiles(dispatch, relatedJob, relatedFiles)
                },
            }
            return {
                label,
                action: isOwnerOfJob(relatedJob) ? restoreAction : undefined,
                dismissToast,
            }
        }
        case 'filesDeletionFailed': {
            const { relatedFiles } = toast
            const label = getStringWithAmount(
                relatedFiles.length,
                _('delete_file_failed_message'),
                _('toast__delete_multiple_files_failed__format'),
            ).replace('%d', relatedFiles.length.toString())

            return {
                label,
                toastColor: colors.captureMagenta,
                action: {
                    label: options?.supressRetry ? _('close') : _('retry'),
                    method: options?.supressRetry
                        ? () => dismissToast
                        : () =>
                              deleteMultipleTrashFiles(dispatch, relatedFiles),
                },
                dismissToast,
            }
        }
        case 'trashFilesDeleted': {
            const label = getStringWithAmount(
                toast.numFiles,
                _('toast__single_file_perm_deleted'),
                _('toast__multiple_files_perm_deleted__format'),
            )

            return {
                label,
                dismissToast,
            }
        }
        case 'trashFilesDeletionFailed': {
            const { relatedFiles } = toast
            const label = getStringWithAmount(
                relatedFiles.length,
                _('toast__single_file_perm_deleted_failed'),
                _('toast__multiple_files_perm_deleted_failed__format'),
            )
            const action = {
                label: _('retry'),
                method: () => deleteMultipleTrashFiles(dispatch, relatedFiles),
            }

            return {
                label,
                action,
                toastColor: colors.captureMagenta,
                dismissToast,
            }
        }
        case 'filesWereRestored': {
            const label = getStringWithAmount(
                toast.numFiles,
                _('toast__single_file_restored'),
                _('toast__multiple_files_restored__format'),
            )

            return {
                label,
                dismissToast,
            }
        }
        case 'jobCopiedToTimeline':
            return {
                label: _('toast__album_added_to_timeline'),
                dismissToast,
            }
        case 'deleteCardSuccess':
            return {
                label: _('toast__delete_user_card_success'),
                dismissToast,
            }
        case 'deleteCardFailed':
            return {
                label: _('toast__delete_user_card_failed'),
                dismissToast,
            }
        case 'imageCopiedToClipboard':
            return {
                label: _('toast__file_copied_to_clipboard'),
                dismissToast,
            }
        case 'imageCopyToClipboardFailed':
            return {
                label: _('toast__file_copy_to_clipboard_failed'),
                toastColor: colors.captureMagenta,
                dismissToast,
            }
        case 'jobCopiedToTimelineFailed':
            return {
                label: _('toast__album_added_to_timeline_failed'),
                toastColor: colors.captureMagenta,
                dismissToast,
            }
        case 'filesCopiedToTimeline':
            return {
                label: getStringWithAmount(
                    toast.relatedFiles.length,
                    _('toast__file_added_to_timeline'),
                    _('toast__multiple_files_added_to_timeline'),
                ),
                dismissToast,
            }
        case 'filesCopiedToTimelineFailed':
            return {
                label: _('toast__file_added_to_timeline_failed'),
                toastColor: colors.captureMagenta,
                dismissToast,
            }
        case 'filesCopiedToAlbum': {
            const { relatedJob, showAlbumLink } = toast
            const title = getJobName(relatedJob)
            if (title === _('unknown')) {
                return {}
            }
            const albumName = title === '' ? '' : `"${title}"`
            const action = {
                label: _('see_album'),
                method: () => navigate(Pages.Album(relatedJob).url),
            }
            return {
                label: getStringWithAmount(
                    toast.relatedFiles.length,
                    _('toast__single_file_added_to_album__format'),
                    _('toast__multiple_files_added_to_album__format'),
                ).replace('%album_name%', albumName),
                action: showAlbumLink ? action : undefined,
                dismissToast,
            }
        }
        case 'filesCopiedToAlbumFailed':
            return {
                label: getStringWithAmount(
                    toast.relatedFiles.length,
                    _('toast__single_file_added_to_album_failed__format'),
                    _('toast__multiple_file_added_to_album_failed__format'),
                ),
                toastColor: colors.warningRed,
                dismissToast,
                customDismissTimeout: 5500,
            }
        case 'shareCopiedToTimeline':
            return {
                label: _('share_added_to_timeline'),
                dismissToast,
            }
        case 'shareCopiedToTimelineFailed':
            return {
                label: _('share_added_to_timeline_failed'),
                toastColor: colors.captureMagenta,
                dismissToast,
            }
        case 'filesWereShared':
            return {
                label: _('toast__files_were_shared'),
                dismissToast,
            }
        case 'shareDeletionFailed':
            return {
                label: _('share__deletion_failed'),
                toastColor: colors.captureMagenta,
                dismissToast,
            }
        case 'shareCreationFailed':
            return {
                label: _('share_failed'),
                toastColor: colors.captureMagenta,
                dismissToast,
            }
        case 'filesDownloadFailed':
            return {
                label: _('download_files_failed_message'),
                toastColor: colors.captureMagenta,
                dismissToast,
            }
        case 'filesRestorationFailed': {
            const { relatedJob, relatedFiles } = toast
            const label = getStringWithAmount(
                toast.relatedFiles.length,
                _('toast__single_file_restored_failed'),
                _('toast__multiple_files_restored_failed__format'),
            )

            const retryAction = {
                label: _('retry'),
                method: () => {
                    restoreMultipleFiles(dispatch, relatedJob, relatedFiles)
                },
            }
            return {
                label,
                toastColor: colors.captureMagenta,
                action: retryAction,
                dismissToast,
            }
        }
        case 'linkWasCopied':
            return {
                label: _('link_copied'),
                dismissToast,
            }
        case 'filesAreBeingDeleted':
            return {
                label: _('toast__files_are_deleting'),
            }
        case 'filesAreBeingRestored':
            return {
                label: _('toast__files_are_restoring'),
            }
        case 'filesAreBeingCopied':
            return {
                label: _('toast__files_are_copying'),
            }
        case 'purchaseSuccessful':
            return {
                label: _('toast__purchase_successful'),
                dismissToast,
            }
        case 'purchaseFailed':
            return {
                label: _('toast__purchase_failed'),
                toastColor: colors.captureMagenta,
                dismissToast,
            }
        case 'creditCardUpdated':
            return {
                label: _('toast__credit_card_updated'),
                dismissToast,
            }
        case 'creditCardUpdateFailed':
            return {
                label: _('toast__credit_card_update_failed'),
                toastColor: colors.captureMagenta,
                dismissToast,
            }
        case 'planCanceled':
            return {
                label: _('toast__plan_canceled'),
                dismissToast,
            }
        case 'planCancelFailed':
            return {
                label: _('toast__plan_cancel_failed'),
                toastColor: colors.captureMagenta,
                dismissToast,
            }
        case 'planChangeSucceeded':
            return {
                label: _('toast__plan_change_succeeded'),
                dismissToast,
            }
        case 'planChangeFailed':
            return {
                label: _('toast__plan_change_failed'),
                toastColor: colors.captureMagenta,
                dismissToast,
            }
        case 'planReactivated':
            return {
                label: _('toast__plan_reactivated'),
                dismissToast,
            }
        case 'planReactivationFailed':
            return {
                label: _('toast__plan_reactivation_failed'),
                toastColor: colors.captureMagenta,
                dismissToast,
            }
        case 'changeProfilePictureFailed':
            return {
                label: _('toast__change_profile_picture_failed'),
                toastColor: colors.captureMagenta,
                dismissToast,
            }
        case 'maxSelectionCountReached':
            return {
                label: _('toast__selection_count_limit_reached'),
                toastColor: colors.captureMagenta,
                dismissToast,
            }
        case 'userNameChangeFailed':
            return {
                label: _('toast__change_user_name_failed'),
                toastColor: colors.captureMagenta,
                dismissToast,
            }
        case 'preparingDownload':
            return {
                label: _('toast__preparing_to_download'),
            }
        case 'trashAlbumsRestoreSuccess': {
            const { singular } = toast
            return {
                label: singular
                    ? _('toast__album_restored')
                    : _('toast__albums_restored'),
                action: {
                    label: _('toast__goto_albums'),
                    method: () => navigate(Pages.Albums.url),
                },
                dismissToast,
            }
        }
        case 'trashAlbumsRestoreFailed':
            return {
                label: _('toast__album_restore_failed'),
                toastColor: colors.captureMagenta,
                dismissToast,
            }
        case 'trashAlbumsDeleteSuccess': {
            const { singular } = toast
            return {
                label: singular
                    ? _('toast__album_deleted')
                    : _('toast__albums_deleted'),
                dismissToast,
            }
        }
        case 'trashAlbumsDeleteFailed':
            return {
                label: _('toast__album_delete_failed'),
                toastColor: colors.captureMagenta,
                dismissToast,
            }
        default:
            throw new Error('Unhandled condition: unknown toast type!')
    }
}

export const Toast = (props: Props) => {
    const { label, action, dismissToast, toastColor, customDismissTimeout } =
        useToastComposition(
            props.toast,
            props.isOwnerOfJob,
            props.getJobName,
            props.options,
        )
    const handleActionClicked = () => {
        if (action) {
            action.method()
        }
        if (dismissToast) {
            dismissToast()
        }
    }

    useEffect(() => {
        let dismissTimer: number | undefined
        if (dismissToast) {
            dismissTimer = window.setTimeout(() => {
                dismissToast()
            }, customDismissTimeout || 4000)
        }

        return () => window.clearTimeout(dismissTimer)
    }, [dismissToast, customDismissTimeout])

    if (label === undefined) {
        return null
    }

    return (
        <ToastWrapper
            data-cy="toast__wrapperTxt"
            toastColor={toastColor || colors.captureBlue}>
            <ContentWrapper>
                {label}
                {!dismissToast ? (
                    <RippleLoader size={32} color={'white'} />
                ) : (
                    // We don't want a key event for a toast that goes away on its own
                    // eslint-disable-next-line styled-components-a11y/click-events-have-key-events, styled-components-a11y/no-static-element-interactions
                    <ToastActionWrapper
                        data-cy="toast__actionBtn"
                        onClick={handleActionClicked}>
                        {action?.label ?? _('ok')}
                    </ToastActionWrapper>
                )}
            </ContentWrapper>
        </ToastWrapper>
    )
}
