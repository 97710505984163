import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import type { Dispatch } from '~/state/common/actions'
import { _ } from '~/assets/localization/util'
import {
    MissingNameModalContext,
    ShowMissingNameModal,
} from '~/state/currentUser/actions'
import { Pages } from '~/routing'
import { PRODUCT_NAME } from '~/config/constants'
import { subscribeToAlbum } from '~/API/album'
import { b64ToUuid } from '~/utilities/uuid'
import { RequireLoginActionPage } from './RequireLoginActionPage'

export const RequireLoginSubscribeToAlbum: React.FC = () => {
    const dispatch = useDispatch()
    const { albumIDB64 } = useParams<{ albumIDB64: string }>()
    if (!albumIDB64) {
        throw Error('Must have params for albumIDB64')
    }
    const subscribeToCurrentAlbum = (d: Dispatch, userName?: string) => {
        if (userName) {
            const albumID = b64ToUuid(albumIDB64)
            subscribeToAlbum(d, albumID)
        } else {
            dispatch(
                ShowMissingNameModal(MissingNameModalContext.AlbumReceiver),
            )
        }
    }

    return (
        <RequireLoginActionPage
            text={_('needs_login_subscribe_to_album').replace(
                '%product_name%',
                PRODUCT_NAME,
            )}
            targetAfterLogin={Pages.AlbumB64(albumIDB64)}
            afterLoginAction={subscribeToCurrentAlbum}
        />
    )
}
