/* tslint:disable:max-line-length jsx-self-close */
export const AppStoreBadge: React.FunctionComponent<{
    height: string
    width: string
}> = (props) => (
    <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 135 40"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
                d="M130.197,40 L4.729,40 C2.122,40 0,37.872 0,35.267 L0,4.726 C0,2.12 2.122,0 4.729,0 L130.197,0 C132.803,0 135,2.12 135,4.726 L135,35.267 C135,37.872 132.803,40 130.197,40 L130.197,40 Z"
                fill="#A6A6A6"></path>
            <path
                d="M134.032,35.268 C134.032,37.384 132.318,39.098 130.198,39.098 L4.729,39.098 C2.61,39.098 0.89,37.384 0.89,35.268 L0.89,4.725 C0.89,2.61 2.61,0.89 4.729,0.89 L130.197,0.89 C132.318,0.89 134.031,2.61 134.031,4.725 L134.032,35.268 L134.032,35.268 Z"
                fill="#000000"></path>
            <g transform="translate(12.000000, 6.000000)" fill="#FFFFFF">
                <path d="M18.128,13.784 C18.099,10.561 20.767,8.993 20.889,8.92 C19.378,6.717 17.036,6.416 16.213,6.392 C14.246,6.185 12.338,7.569 11.336,7.569 C10.314,7.569 8.771,6.412 7.108,6.446 C4.968,6.479 2.966,7.718 1.868,9.642 C-0.398,13.565 1.292,19.33 3.463,22.501 C4.549,24.054 5.818,25.788 7.479,25.727 C9.104,25.66 9.711,24.691 11.672,24.691 C13.615,24.691 14.185,25.727 15.879,25.688 C17.623,25.66 18.721,24.128 19.769,22.561 C21.024,20.781 21.528,19.028 21.548,18.938 C21.507,18.924 18.161,17.647 18.128,13.784 Z"></path>
                <path d="M14.928,4.306 C15.802,3.213 16.4,1.726 16.234,0.217 C14.969,0.273 13.387,1.092 12.476,2.161 C11.67,3.103 10.95,4.647 11.136,6.099 C12.557,6.205 14.016,5.382 14.928,4.306 Z"></path>
            </g>
            <g transform="translate(42.000000, 18.000000)" fill="#FFFFFF">
                <path d="M11.645,13.504 L9.374,13.504 L8.13,9.595 L3.806,9.595 L2.621,13.504 L0.41,13.504 L4.694,0.196 L7.34,0.196 L11.645,13.504 Z M7.755,7.955 L6.63,4.48 C6.511,4.125 6.288,3.289 5.959,1.973 L5.919,1.973 C5.788,2.539 5.577,3.375 5.287,4.48 L4.182,7.955 L7.755,7.955 L7.755,7.955 Z"></path>
                <path d="M22.662,8.588 C22.662,10.22 22.221,11.51 21.339,12.457 C20.549,13.3 19.568,13.721 18.397,13.721 C17.133,13.721 16.225,13.267 15.672,12.359 L15.632,12.359 L15.632,17.414 L13.5,17.414 L13.5,7.067 C13.5,6.041 13.473,4.988 13.421,3.908 L15.296,3.908 L15.415,5.429 L15.455,5.429 C16.166,4.283 17.245,3.711 18.693,3.711 C19.825,3.711 20.77,4.158 21.526,5.053 C22.284,5.949 22.662,7.127 22.662,8.588 Z M20.49,8.666 C20.49,7.732 20.28,6.962 19.858,6.356 C19.397,5.724 18.778,5.408 18.002,5.408 C17.476,5.408 16.998,5.584 16.571,5.931 C16.143,6.281 15.863,6.738 15.732,7.304 C15.666,7.568 15.633,7.784 15.633,7.954 L15.633,9.554 C15.633,10.252 15.847,10.841 16.275,11.322 C16.703,11.803 17.259,12.043 17.943,12.043 C18.746,12.043 19.371,11.733 19.818,11.115 C20.266,10.496 20.49,9.68 20.49,8.666 Z"></path>
                <path d="M33.699,8.588 C33.699,10.22 33.258,11.51 32.375,12.457 C31.586,13.3 30.605,13.721 29.434,13.721 C28.17,13.721 27.262,13.267 26.71,12.359 L26.67,12.359 L26.67,17.414 L24.538,17.414 L24.538,7.067 C24.538,6.041 24.511,4.988 24.459,3.908 L26.334,3.908 L26.453,5.429 L26.493,5.429 C27.203,4.283 28.282,3.711 29.731,3.711 C30.862,3.711 31.807,4.158 32.565,5.053 C33.32,5.949 33.699,7.127 33.699,8.588 Z M31.527,8.666 C31.527,7.732 31.316,6.962 30.894,6.356 C30.433,5.724 29.816,5.408 29.039,5.408 C28.512,5.408 28.035,5.584 27.607,5.931 C27.179,6.281 26.9,6.738 26.769,7.304 C26.704,7.568 26.67,7.784 26.67,7.954 L26.67,9.554 C26.67,10.252 26.884,10.841 27.31,11.322 C27.738,11.802 28.294,12.043 28.98,12.043 C29.783,12.043 30.408,11.733 30.855,11.115 C31.303,10.496 31.527,9.68 31.527,8.666 Z"></path>
                <path d="M46.039,9.772 C46.039,10.904 45.646,11.825 44.857,12.536 C43.99,13.313 42.783,13.701 41.232,13.701 C39.8,13.701 38.652,13.425 37.783,12.872 L38.277,11.095 C39.213,11.661 40.24,11.945 41.359,11.945 C42.162,11.945 42.787,11.763 43.236,11.401 C43.683,11.039 43.906,10.553 43.906,9.947 C43.906,9.407 43.722,8.952 43.353,8.583 C42.986,8.214 42.373,7.871 41.517,7.554 C39.187,6.685 38.023,5.412 38.023,3.738 C38.023,2.644 38.431,1.747 39.248,1.049 C40.062,0.35 41.148,0.001 42.506,0.001 C43.717,0.001 44.723,0.212 45.526,0.633 L44.993,2.371 C44.243,1.963 43.395,1.759 42.446,1.759 C41.696,1.759 41.11,1.944 40.69,2.312 C40.335,2.641 40.157,3.042 40.157,3.517 C40.157,4.043 40.36,4.478 40.768,4.82 C41.123,5.136 41.768,5.478 42.704,5.847 C43.849,6.308 44.69,6.847 45.231,7.465 C45.77,8.081 46.039,8.852 46.039,9.772 Z"></path>
                <path d="M53.088,5.508 L50.738,5.508 L50.738,10.167 C50.738,11.352 51.152,11.944 51.982,11.944 C52.363,11.944 52.679,11.911 52.929,11.845 L52.988,13.464 C52.568,13.621 52.015,13.7 51.33,13.7 C50.488,13.7 49.83,13.443 49.355,12.93 C48.882,12.416 48.644,11.554 48.644,10.343 L48.644,5.506 L47.244,5.506 L47.244,3.906 L48.644,3.906 L48.644,2.149 L50.738,1.517 L50.738,3.906 L53.088,3.906 L53.088,5.508 Z"></path>
                <path d="M63.691,8.627 C63.691,10.102 63.269,11.313 62.427,12.26 C61.544,13.235 60.372,13.721 58.911,13.721 C57.503,13.721 56.382,13.254 55.546,12.32 C54.71,11.386 54.292,10.207 54.292,8.786 C54.292,7.299 54.722,6.081 55.585,5.134 C56.446,4.186 57.608,3.712 59.069,3.712 C60.477,3.712 61.61,4.179 62.465,5.114 C63.283,6.021 63.691,7.192 63.691,8.627 Z M61.479,8.696 C61.479,7.811 61.29,7.052 60.907,6.419 C60.46,5.653 59.821,5.271 58.993,5.271 C58.136,5.271 57.485,5.654 57.038,6.419 C56.655,7.053 56.466,7.824 56.466,8.736 C56.466,9.621 56.655,10.38 57.038,11.012 C57.499,11.778 58.143,12.16 58.974,12.16 C59.788,12.16 60.427,11.77 60.888,10.992 C61.281,10.347 61.479,9.58 61.479,8.696 Z"></path>
                <path d="M70.621,5.783 C70.41,5.744 70.185,5.724 69.949,5.724 C69.199,5.724 68.619,6.007 68.211,6.574 C67.856,7.074 67.678,7.706 67.678,8.469 L67.678,13.504 L65.547,13.504 L65.567,6.93 C65.567,5.824 65.54,4.817 65.487,3.909 L67.344,3.909 L67.422,5.745 L67.481,5.745 C67.706,5.114 68.061,4.606 68.547,4.225 C69.022,3.882 69.535,3.711 70.088,3.711 C70.285,3.711 70.463,3.725 70.621,3.75 L70.621,5.783 Z"></path>
                <path d="M80.156,8.252 C80.156,8.634 80.131,8.956 80.078,9.219 L73.682,9.219 C73.707,10.167 74.016,10.892 74.61,11.392 C75.149,11.839 75.846,12.063 76.702,12.063 C77.649,12.063 78.513,11.912 79.29,11.609 L79.624,13.089 C78.716,13.485 77.644,13.682 76.407,13.682 C74.919,13.682 73.751,13.244 72.901,12.369 C72.053,11.494 71.628,10.319 71.628,8.845 C71.628,7.398 72.023,6.193 72.814,5.232 C73.642,4.206 74.761,3.693 76.169,3.693 C77.552,3.693 78.599,4.206 79.31,5.232 C79.873,6.047 80.156,7.055 80.156,8.252 Z M78.123,7.699 C78.137,7.067 77.998,6.521 77.709,6.06 C77.34,5.467 76.773,5.171 76.01,5.171 C75.313,5.171 74.746,5.46 74.313,6.04 C73.958,6.501 73.747,7.054 73.682,7.698 L78.123,7.698 L78.123,7.699 Z"></path>
            </g>
            <g transform="translate(43.000000, 6.000000)" fill="#FFFFFF">
                <path d="M6.05,4.009 C6.05,5.186 5.697,6.072 4.992,6.667 C4.339,7.216 3.411,7.491 2.209,7.491 C1.613,7.491 1.103,7.465 0.676,7.413 L0.676,0.982 C1.233,0.892 1.833,0.846 2.481,0.846 C3.626,0.846 4.489,1.095 5.071,1.593 C5.723,2.156 6.05,2.961 6.05,4.009 Z M4.945,4.038 C4.945,3.275 4.743,2.69 4.339,2.282 C3.935,1.875 3.345,1.671 2.568,1.671 C2.238,1.671 1.957,1.693 1.724,1.739 L1.724,6.628 C1.853,6.648 2.089,6.657 2.432,6.657 C3.234,6.657 3.853,6.434 4.289,5.988 C4.725,5.542 4.945,4.892 4.945,4.038 Z"></path>
                <path d="M11.909,5.037 C11.909,5.762 11.702,6.356 11.288,6.822 C10.854,7.301 10.279,7.54 9.561,7.54 C8.869,7.54 8.318,7.311 7.907,6.851 C7.497,6.392 7.292,5.813 7.292,5.115 C7.292,4.385 7.503,3.786 7.927,3.321 C8.351,2.856 8.921,2.623 9.639,2.623 C10.331,2.623 10.887,2.852 11.308,3.311 C11.708,3.757 11.909,4.333 11.909,5.037 Z M10.822,5.071 C10.822,4.636 10.728,4.263 10.541,3.952 C10.321,3.576 10.008,3.388 9.601,3.388 C9.18,3.388 8.86,3.576 8.64,3.952 C8.452,4.263 8.359,4.642 8.359,5.09 C8.359,5.525 8.453,5.898 8.64,6.209 C8.867,6.585 9.183,6.773 9.591,6.773 C9.991,6.773 10.305,6.582 10.531,6.199 C10.725,5.882 10.822,5.506 10.822,5.071 Z"></path>
                <path d="M19.765,2.719 L18.29,7.433 L17.33,7.433 L16.719,5.386 C16.564,4.875 16.438,4.367 16.34,3.863 L16.321,3.863 C16.23,4.381 16.104,4.888 15.942,5.386 L15.293,7.433 L14.322,7.433 L12.935,2.719 L14.012,2.719 L14.545,4.96 C14.674,5.49 14.78,5.995 14.865,6.473 L14.884,6.473 C14.962,6.079 15.091,5.577 15.273,4.97 L15.942,2.72 L16.796,2.72 L17.437,4.922 C17.592,5.459 17.718,5.976 17.815,6.474 L17.844,6.474 C17.915,5.989 18.022,5.472 18.164,4.922 L18.736,2.72 L19.765,2.72 L19.765,2.719 Z"></path>
                <path d="M25.198,7.433 L24.15,7.433 L24.15,4.733 C24.15,3.901 23.834,3.485 23.2,3.485 C22.889,3.485 22.638,3.599 22.443,3.828 C22.25,4.057 22.152,4.327 22.152,4.636 L22.152,7.432 L21.104,7.432 L21.104,4.066 C21.104,3.652 21.091,3.203 21.066,2.717 L21.987,2.717 L22.036,3.454 L22.065,3.454 C22.187,3.225 22.369,3.036 22.608,2.885 C22.892,2.709 23.21,2.62 23.558,2.62 C23.998,2.62 24.364,2.762 24.655,3.047 C25.017,3.396 25.198,3.917 25.198,4.609 L25.198,7.433 L25.198,7.433 Z"></path>
                <polygon points="28.088 7.433 27.041 7.433 27.041 0.556 28.088 0.556"></polygon>
                <path d="M34.258,5.037 C34.258,5.762 34.051,6.356 33.637,6.822 C33.203,7.301 32.627,7.54 31.91,7.54 C31.217,7.54 30.666,7.311 30.256,6.851 C29.846,6.392 29.641,5.813 29.641,5.115 C29.641,4.385 29.852,3.786 30.276,3.321 C30.7,2.856 31.27,2.623 31.987,2.623 C32.68,2.623 33.235,2.852 33.657,3.311 C34.057,3.757 34.258,4.333 34.258,5.037 Z M33.17,5.071 C33.17,4.636 33.076,4.263 32.889,3.952 C32.67,3.576 32.356,3.388 31.95,3.388 C31.528,3.388 31.208,3.576 30.989,3.952 C30.801,4.263 30.708,4.642 30.708,5.09 C30.708,5.525 30.802,5.898 30.989,6.209 C31.216,6.585 31.532,6.773 31.94,6.773 C32.34,6.773 32.653,6.582 32.879,6.199 C33.074,5.882 33.17,5.506 33.17,5.071 Z"></path>
                <path d="M39.33,7.433 L38.389,7.433 L38.311,6.89 L38.282,6.89 C37.96,7.323 37.501,7.54 36.905,7.54 C36.46,7.54 36.1,7.397 35.829,7.113 C35.583,6.855 35.46,6.534 35.46,6.153 C35.46,5.577 35.7,5.138 36.183,4.834 C36.665,4.53 37.343,4.381 38.216,4.388 L38.216,4.3 C38.216,3.679 37.89,3.369 37.237,3.369 C36.772,3.369 36.362,3.486 36.008,3.718 L35.795,3.03 C36.233,2.759 36.774,2.623 37.412,2.623 C38.644,2.623 39.262,3.273 39.262,4.573 L39.262,6.309 C39.262,6.78 39.285,7.155 39.33,7.433 Z M38.242,5.813 L38.242,5.086 C37.086,5.066 36.508,5.383 36.508,6.036 C36.508,6.282 36.574,6.466 36.709,6.589 C36.844,6.712 37.016,6.773 37.221,6.773 C37.451,6.773 37.666,6.7 37.862,6.555 C38.059,6.409 38.18,6.224 38.225,5.997 C38.236,5.946 38.242,5.884 38.242,5.813 Z"></path>
                <path d="M45.285,7.433 L44.355,7.433 L44.306,6.676 L44.277,6.676 C43.98,7.252 43.474,7.54 42.763,7.54 C42.195,7.54 41.722,7.317 41.347,6.871 C40.972,6.425 40.785,5.846 40.785,5.135 C40.785,4.372 40.988,3.754 41.396,3.282 C41.791,2.842 42.275,2.622 42.851,2.622 C43.484,2.622 43.927,2.835 44.179,3.262 L44.199,3.262 L44.199,0.556 L45.248,0.556 L45.248,6.163 C45.248,6.622 45.26,7.045 45.285,7.433 Z M44.199,5.445 L44.199,4.659 C44.199,4.523 44.189,4.413 44.17,4.329 C44.111,4.077 43.984,3.865 43.791,3.694 C43.596,3.523 43.361,3.437 43.09,3.437 C42.699,3.437 42.393,3.592 42.168,3.903 C41.945,4.214 41.832,4.611 41.832,5.096 C41.832,5.562 41.939,5.94 42.154,6.231 C42.381,6.541 42.687,6.696 43.07,6.696 C43.414,6.696 43.689,6.567 43.898,6.308 C44.1,6.069 44.199,5.781 44.199,5.445 Z"></path>
                <path d="M54.248,5.037 C54.248,5.762 54.041,6.356 53.627,6.822 C53.193,7.301 52.619,7.54 51.9,7.54 C51.209,7.54 50.658,7.311 50.246,6.851 C49.836,6.392 49.631,5.813 49.631,5.115 C49.631,4.385 49.842,3.786 50.266,3.321 C50.69,2.856 51.26,2.623 51.979,2.623 C52.67,2.623 53.227,2.852 53.647,3.311 C54.047,3.757 54.248,4.333 54.248,5.037 Z M53.162,5.071 C53.162,4.636 53.068,4.263 52.881,3.952 C52.66,3.576 52.348,3.388 51.94,3.388 C51.52,3.388 51.2,3.576 50.979,3.952 C50.791,4.263 50.698,4.642 50.698,5.09 C50.698,5.525 50.792,5.898 50.979,6.209 C51.206,6.585 51.522,6.773 51.93,6.773 C52.33,6.773 52.645,6.582 52.871,6.199 C53.064,5.882 53.162,5.506 53.162,5.071 Z"></path>
                <path d="M59.883,7.433 L58.836,7.433 L58.836,4.733 C58.836,3.901 58.52,3.485 57.885,3.485 C57.574,3.485 57.323,3.599 57.129,3.828 C56.935,4.057 56.838,4.327 56.838,4.636 L56.838,7.432 L55.789,7.432 L55.789,4.066 C55.789,3.652 55.777,3.203 55.752,2.717 L56.672,2.717 L56.721,3.454 L56.75,3.454 C56.873,3.225 57.055,3.036 57.293,2.885 C57.578,2.709 57.895,2.62 58.244,2.62 C58.683,2.62 59.049,2.762 59.34,3.047 C59.703,3.396 59.883,3.917 59.883,4.609 L59.883,7.433 L59.883,7.433 Z"></path>
                <path d="M66.936,3.504 L65.782,3.504 L65.782,5.794 C65.782,6.376 65.987,6.667 66.393,6.667 C66.581,6.667 66.737,6.651 66.86,6.618 L66.887,7.413 C66.68,7.491 66.408,7.53 66.073,7.53 C65.659,7.53 65.337,7.404 65.104,7.152 C64.87,6.9 64.754,6.476 64.754,5.881 L64.754,3.504 L64.065,3.504 L64.065,2.719 L64.754,2.719 L64.754,1.855 L65.781,1.545 L65.781,2.718 L66.935,2.718 L66.935,3.504 L66.936,3.504 Z"></path>
                <path d="M72.484,7.433 L71.435,7.433 L71.435,4.753 C71.435,3.908 71.119,3.485 70.486,3.485 C70,3.485 69.668,3.73 69.486,4.22 C69.455,4.323 69.437,4.449 69.437,4.597 L69.437,7.432 L68.39,7.432 L68.39,0.556 L69.437,0.556 L69.437,3.397 L69.457,3.397 C69.787,2.88 70.26,2.622 70.873,2.622 C71.307,2.622 71.666,2.764 71.951,3.049 C72.306,3.404 72.484,3.932 72.484,4.63 L72.484,7.433 L72.484,7.433 Z"></path>
                <path d="M78.207,4.853 C78.207,5.041 78.193,5.199 78.168,5.328 L75.025,5.328 C75.039,5.794 75.189,6.149 75.48,6.395 C75.746,6.615 76.089,6.725 76.509,6.725 C76.974,6.725 77.398,6.651 77.78,6.502 L77.944,7.23 C77.497,7.424 76.971,7.521 76.362,7.521 C75.632,7.521 75.057,7.306 74.641,6.876 C74.223,6.446 74.016,5.869 74.016,5.145 C74.016,4.434 74.209,3.842 74.598,3.37 C75.004,2.866 75.553,2.614 76.246,2.614 C76.924,2.614 77.439,2.866 77.787,3.37 C78.068,3.77 78.207,4.265 78.207,4.853 Z M77.207,4.582 C77.215,4.271 77.146,4.003 77.004,3.777 C76.822,3.486 76.545,3.34 76.17,3.34 C75.828,3.34 75.549,3.482 75.336,3.767 C75.162,3.994 75.059,4.265 75.025,4.582 L77.207,4.582 Z"></path>
            </g>
        </g>
    </svg>
)
