import * as React from 'react'
import styled from 'styled-components'
import { colors } from '~/assets/styleConstants'

const TextWrapper = styled.div`
    line-height: ${(props: { maxHeight: number; lineHeight: number }) =>
        props.lineHeight}px;
    max-height: ${(props) => props.maxHeight}px;
    white-space: pre-line;
    overflow: hidden;
`

const ReadMore = styled.span`
    color: ${colors.captureBlue};
    padding: 8px;
    padding-left: 0;
    cursor: pointer;
`

type ComponentState = { showReadMore?: boolean }
type TruncateableTextProps = {
    onlinkClick: () => void
    lineHeight: number
    linkText: string
    maxLines: number
    children?: React.ReactNode
}
export class TruncateableText extends React.Component<
    TruncateableTextProps,
    ComponentState
> {
    public state: ComponentState = {}
    private commentTextContainer = React.createRef<HTMLDivElement>()

    public componentDidMount() {
        const el = this.commentTextContainer.current
        if (el && el.scrollHeight > el.offsetHeight) {
            this.setState({ showReadMore: true })
        }
    }
    public render() {
        const heightFromLines = this.props.lineHeight * this.props.maxLines
        const maxWrapperHeight = this.state.showReadMore
            ? heightFromLines - this.props.lineHeight
            : heightFromLines
        return (
            <>
                <TextWrapper
                    maxHeight={maxWrapperHeight}
                    lineHeight={this.props.lineHeight}
                    ref={this.commentTextContainer}>
                    {this.props.children}
                </TextWrapper>
                {this.state.showReadMore && (
                    <ReadMore
                        onClick={this.props.onlinkClick}
                        onKeyUp={this.props.onlinkClick}
                        role="button"
                        tabIndex={0}>
                        ...{this.props.linkText}
                    </ReadMore>
                )}
            </>
        )
    }
}
