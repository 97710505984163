export class BasePath {
    constructor(public rootPath: string) {}

    public subPath(...subs: string[]): string {
        return [this.rootPath].concat(subs).join('/')
    }
}

const NotLoggedInAlbumPath = new BasePath('/a')
const LoggedInAlbumPath = new BasePath('/albums')
const SharePath = new BasePath('/share')
const SelectForExternalPath = new BasePath('/selectPhotos')
const CarouselPath = new BasePath('/carousel')
const TimelinePath = new BasePath('/photos')
const AlbumImageAsShare = new BasePath('/albumImage')

export type InAppSearchParams = {
    fromNav?: boolean
}

export const RoutePath = {
    Timeline: '/photos',
    Albums: '/albums',
    TrashNavigation: '/deleted_items',
    TrashPhotos: '/deleted_items/photos',
    TrashAlbums: '/deleted_items/albums',
    InAppTrashNavigation: '/_from_inside_app/deleted_items',
    Documents: '/documents',
    Settings: '/settings',
    SettingsPrivacy: '/settings/privacy',
    SettingsTakeout: '/settings/takeout',
    SettingsUnsubscribe: '/settings/unsubscribe',
    PrivacySettingsInsideMobileApp: '/_from_inside_app/settings/privacy',
    SettingsStorage: '/settings/storage',
    SettingsLoggedInDevices: '/settings/devices',
    Help: '/help',
    SelectForExternalStart: '/selectPhotos',
    SelectProfilePicture: '/selectProfilePicture',
    getInAppTrashPhotosPath: (searchParams?: InAppSearchParams) =>
        '/_from_inside_app/deleted_items/photos' +
        (searchParams?.fromNav ? '?fromNav=1' : ''),
    getInAppTrashAlbumsPath: (searchParams?: InAppSearchParams) =>
        '/_from_inside_app/deleted_items/albums' +
        (searchParams?.fromNav ? '?fromNav=1' : ''),
    getAlbumImageAsSharePath: (aIDParam: string, fIDParam: string) =>
        AlbumImageAsShare.subPath(aIDParam, fIDParam),
    getNotLoggedInAlbumPath: (aIDParam: string) =>
        NotLoggedInAlbumPath.subPath(aIDParam),
    getLoggedInAlbumPath: (aIDParam: string) =>
        LoggedInAlbumPath.subPath(aIDParam),
    getEditAlbumPath: (aIDParam: string, goToAfter: string) =>
        LoggedInAlbumPath.subPath(aIDParam, 'edit', goToAfter),
    getCreateAlbumLoginPath: (aIDParam: string) =>
        NotLoggedInAlbumPath.subPath(aIDParam, 'createNew'),
    getSubscribeToAlbumLoginPath: (aIDParam: string) =>
        NotLoggedInAlbumPath.subPath(aIDParam, 'subscribe'),
    getCopyAlbumLoginPath: (aIDParam: string) =>
        NotLoggedInAlbumPath.subPath(aIDParam, 'copyAlbum'),
    getCopyAlbumFileLoginPath: (aIDParam: string, fIDParam: string) =>
        NotLoggedInAlbumPath.subPath(aIDParam, fIDParam, 'copyAlbum'),
    getAlbumFileCommentsPath: (aIDParam: string, fIDParam: string) =>
        new BasePath('/comments').subPath(aIDParam, fIDParam),
    morePhotosCreateAlbum: (aIDParam: string) =>
        LoggedInAlbumPath.subPath(aIDParam, 'edit/addPhotos'),
    morePhotosAlbum: (aIDParam: string) =>
        new BasePath('/addPhotos').subPath(aIDParam),
    getShareReceiverPath: (sIDParam: string) => SharePath.subPath(sIDParam),
    getCopyShareLoginPath: (sIDParam: string) =>
        SharePath.subPath(sIDParam, 'copyShare'),
    getDownloadSharePath: (sIDParam: string) =>
        SharePath.subPath(sIDParam, 'download'),
    getAlbumCarouselPath: (aIDParam: string, fIDParam: string) =>
        CarouselPath.subPath(aIDParam, fIDParam),
    getTimelineCarouselPath: (fIDParam: string) =>
        TimelinePath.subPath(fIDParam),
    getSelectForExternalEndPath: (jobSourceParam: string) =>
        SelectForExternalPath.subPath(jobSourceParam),
    getErrorPath: (errorParam: string) => `/error/${errorParam}`,
}
