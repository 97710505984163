import SvgPen from '@capture/capture-components/src/icons/Pen'
import { colors } from '~/assets/styleConstants'
import type { SVGIcon } from './IconTextButton'
import type { InputFieldProps } from './TextFieldWithButton'
import { TextFieldWithButton } from './TextFieldWithButton'

type InputFieldWithIconProps = InputFieldProps & {
    icon: SVGIcon
    iconSize?: number
    iconColor?: string
    onIconClick?: () => void
    hasBottomBorder?: boolean
}
const TextFieldWithIcon: React.FunctionComponent<InputFieldWithIconProps> = (
    props,
) => {
    const button = (
        <props.icon
            width={props.iconSize || 24}
            height={props.iconSize || 24}
            color={props.iconColor || colors.captureBlue}
        />
    )
    return (
        <TextFieldWithButton
            button={button}
            placeholderSize={props.iconSize}
            onButtonClick={props.onIconClick}
            {...props}
        />
    )
}
export const EditableInput: React.FunctionComponent<
    InputFieldProps & {
        iconSize?: number
        iconColor?: string
        hasBottomBorder?: boolean
    }
> = (props) => <TextFieldWithIcon icon={SvgPen} {...props} />
