import { useState } from 'react'

import type { Token } from '@stripe/stripe-js'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { _ } from '~/assets/localization/util'
import { colors, fontSize, mediaQueries } from '~/assets/styleConstants'
import {
    BorderedTextButton,
    FilledTextButton,
} from '~/components/Common/IconTextButton'
import { getEmailAdressOfLoggedInUser } from '~/state/currentUser/selectors'
import type { StripeStoragePlan } from '~/state/storagePlan/selectors'
import {
    getCreditCardInfo,
    getPriceString,
} from '~/state/storagePlan/selectors'
import { PRODUCT_NAME } from '~/config/constants'
import {
    PlanCancelInitiated,
    PlanReactivationInitiated,
} from '~/state/userActions/actions'
import { BuyStorageForm } from './BuyStorageForm'

const BillingDetails = styled.div<{ isCanceled: boolean }>`
    font-weight: bold;
    font-size: ${fontSize.medium_18};
    color: ${(props) => (props.isCanceled ? colors.warningRed : 'auto')};
    margin-top: 16px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const CreditDetails = styled.div`
    font-size: ${fontSize.medium_16};
    font-weight: 400;
`

const ButtonsWrapper = styled.div`
    & > div {
        display: inline-flex;
        margin-right: 24px;
    }

    ${mediaQueries.mobile} {
        & > div {
            display: flex;
            margin-right: 0;
            margin-bottom: 12px;
        }
    }
`

interface BillingInfoProps {
    currentPlan: StripeStoragePlan
    handleBuyPlan: () => void
    handleUpdateCreditCard: (token: Token) => Promise<void>
}

export const BillingInfo = (props: BillingInfoProps) => {
    const { currentPlan, handleBuyPlan, handleUpdateCreditCard } = props

    const [showAddCreditCardSection, setShowAddCreditCardSection] =
        useState(false)

    const dispatch = useDispatch()
    const userEmail = useSelector(getEmailAdressOfLoggedInUser)
    const paymentInfo = useSelector(getCreditCardInfo)

    const doCancelPlan = (currentPlan: StripeStoragePlan) => {
        currentPlan.planActionLink &&
            dispatch(PlanCancelInitiated(currentPlan.planActionLink))
    }

    const handlePlanDetailMainBtnClick = () => {
        if (currentPlan) {
            const { isCanceled } = currentPlan
            if (isCanceled) {
                if (paymentInfo) {
                    doReactivatePlan(currentPlan)
                } else {
                    setShowAddCreditCardSection(true)
                }
            } else {
                handleBuyPlan()
            }
        }
    }

    const handlePlanDetailSecondaryBtnClick = () => {
        if (currentPlan) {
            const { isCanceled } = currentPlan
            isCanceled ? handleBuyPlan() : doCancelPlan(currentPlan)
        }
    }

    const handleReactivatePlan = async (token: Token, _email: string) => {
        await handleUpdateCreditCard(token)
        currentPlan && doReactivatePlan(currentPlan)
        setShowAddCreditCardSection(false)
    }

    const doReactivatePlan = (currentPlan: StripeStoragePlan) => {
        currentPlan.planActionLink &&
            dispatch(PlanReactivationInitiated(currentPlan.planActionLink))
    }

    if (showAddCreditCardSection) {
        return (
            <>
                <BuyStorageForm
                    onPaymentDetailsAccepted={handleReactivatePlan}
                    onCancel={() => setShowAddCreditCardSection(false)}
                    userEmail={userEmail}
                />
            </>
        )
    }

    const { validToDate, isCanceled } = currentPlan

    const billingDetailsText =
        validToDate &&
        (isCanceled
            ? _('expires_date__format').replace('%date%', validToDate)
            : _('next_billing_date__format').replace('%date%', validToDate))

    const getCreditDetails = () => {
        if (
            paymentInfo?.account_balance &&
            paymentInfo?.account_balance < 0 &&
            paymentInfo?.currency
        ) {
            const [beforeAmount, afterAmount] = _('has_credit')
                .replace('%product_name%', PRODUCT_NAME)
                .split('%credit_amount%')
            const creditAmount = getPriceString(
                Math.abs(paymentInfo.account_balance),
                paymentInfo.currency,
            )
            return (
                <CreditDetails>
                    <span>{beforeAmount}</span>
                    <strong>{creditAmount}</strong>
                    <span>{afterAmount}</span>
                </CreditDetails>
            )
        }
        return null
    }

    const secondaryText = isCanceled
        ? _('upgrade_storage_plan')
        : _('cancel_storage_plan')
    const primaryText = isCanceled
        ? _('reactivate_storage_plan')
        : _('upgrade_storage_plan')

    return (
        <>
            <BillingDetails isCanceled={currentPlan.isCanceled}>
                {billingDetailsText}
                {getCreditDetails()}
            </BillingDetails>
            <ButtonsWrapper>
                <BorderedTextButton
                    text={secondaryText}
                    onClick={handlePlanDetailSecondaryBtnClick}
                    color={colors.captureBlue}
                    hoverFillColor={colors.captureBlue50}
                />
                <FilledTextButton
                    text={primaryText}
                    onClick={handlePlanDetailMainBtnClick}
                    hoverFillColor={colors.captureAccentBlue}
                />
            </ButtonsWrapper>
        </>
    )
}
