/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    CAPBAKApproveTosPostParams,
    CAPBAKHTTPValidationError,
} from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class ApproveTos<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * @description Approve the provided ToS version
     *
     * @tags terms_of_service
     * @name ApproveTosPost
     * @summary Post Approve Tos
     * @request POST:/approve_tos
     */
    approveTosPost = (
        query: CAPBAKApproveTosPostParams,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/approve_tos`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
}
