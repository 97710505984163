import type { ConfigurableAlbumDetails } from '../albumList/reducer'
import type { Action } from '../common/actions'
import { isType } from '../common/actions'
import {
    ChangeJobPropertyFailed,
    ChangeJobPropertySucceeded,
} from '../jobInfo/actions'
import {
    AlbumOptionsOverlayClosed,
    AlbumOptionsOverlayTriggered,
} from './actions'

type GeneralOption<T> = { type: T }
type AlbumOption<T> = GeneralOption<T> & { albumID: JobID }
type AlbumFileOption<T> = AlbumOption<T> & { fileID: FileID }

export type AlbumOptions =
    | GeneralOption<'albums_filter'>
    | GeneralOption<'albums_sort'>
    | GeneralOption<'create_album'>
    | AlbumOption<'share'>
    | AlbumOption<'share_prompt'>
    | AlbumOption<'settings'>
    | AlbumOption<'participantsList'>
    | AlbumFileOption<'loveList'>
    | AlbumOption<'album_files_sort'>

export type AlbumOptionsState = {
    options?: AlbumOptions
    settingErrors: Array<keyof ConfigurableAlbumDetails>
}

const initialState: AlbumOptionsState = {
    options: undefined,
    settingErrors: [],
}

export const albumOptionsReducer = (
    state: AlbumOptionsState = initialState,
    action: Action,
): AlbumOptionsState => {
    if (isType(action, AlbumOptionsOverlayTriggered)) {
        return {
            ...state,
            options: action.payload,
        }
    }
    if (isType(action, AlbumOptionsOverlayClosed)) {
        return initialState
    }

    if (isType(action, ChangeJobPropertySucceeded)) {
        return {
            ...state,
            settingErrors: state.settingErrors.filter(
                (error) => error !== action.payload.property,
            ),
        }
    }

    if (isType(action, ChangeJobPropertyFailed)) {
        const settingErrorsSet = new Set(state.settingErrors)
        settingErrorsSet.add(action.payload.property)
        return {
            ...state,
            settingErrors: Array.from(settingErrorsSet),
        }
    }

    return state
}

export const albumOptionsReducerMapObj = {
    albumOptions: albumOptionsReducer,
}

export type StateWithAlbumOptions = StateOfReducerMapObj<
    typeof albumOptionsReducerMapObj
>
