import { useParams } from 'react-router-dom'
import type { Dispatch } from '~/state/common/actions'
import { _ } from '~/assets/localization/util'
import { PRODUCT_NAME } from '~/config/constants'
import { copyAlbumFilesToTimeline, copyAlbumToTimeline } from '~/API/job'
import { AlbumB64 } from '~/routing/pages'
import { b64ToUuid } from '~/utilities/uuid'
import { RequireLoginActionPage } from './RequireLoginActionPage'

export const LoginRequiredCopyAlbum: React.FC = () => {
    const { albumIDB64, fileID } = useParams<{
        albumIDB64: string
        fileID?: FileID
    }>()
    if (!albumIDB64) {
        throw Error('Must have params for albumIDB64')
    }
    const copyToTimeline = (d: Dispatch) => {
        const albumID = b64ToUuid(albumIDB64)
        if (fileID !== undefined) {
            copyAlbumFilesToTimeline(d, albumID, [fileID])
        } else {
            copyAlbumToTimeline(d, albumID)
        }
    }

    return (
        <RequireLoginActionPage
            text={_('needs_login_to_copy_album_to_timeline').replace(
                '%product_name%',
                PRODUCT_NAME,
            )}
            targetAfterLogin={AlbumB64(albumIDB64)}
            afterLoginAction={copyToTimeline}
        />
    )
}
