import { _ } from '~/assets/localization/util'
import { doLogin } from '~/API/login'
import { colors } from '~/assets/styleConstants'
import { TextButton } from '../Common/IconTextButton'
import { CaptureLogoHorizontal } from '../Icons/CaptureLogoHorizontal'
import { TwoAreasTopNavBar } from './TwoAreasTopNavBar'

export const NotLoggedInTopNavBar: React.FunctionComponent = () => {
    const loginButton = (
        <TextButton
            onClick={() => doLogin()}
            text={_('login').toLocaleUpperCase()}
            color={colors.captureBlue}
        />
    )

    return (
        <TwoAreasTopNavBar
            left={<CaptureLogoHorizontal />}
            right={loginButton}
            hideUserAvi={true}
        />
    )
}
