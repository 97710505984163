import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom'
import { sessionStorageGet } from './webStorage'

export type PageDescription = {
    url: string
    pageTitle?: string
    queryParams?: DictionaryOf<string>
    hash?: string
}

export const goBack = () => {
    const beforeLength = parseInt(
        sessionStorageGet('historyLengthBeforeLogin') || 'NaN',
        10,
    )
    const afterLength = parseInt(
        sessionStorageGet('historyLengthAfterLogin') || 'NaN',
        10,
    )

    if (history.length === afterLength && afterLength - beforeLength > 1) {
        history.go(beforeLength - afterLength)
    } else {
        history.back()
    }
}

export const getCurrentLocation = () => location.pathname

export const isCurrentLocation = (location: PageDescription) =>
    getCurrentLocation() === location.url

export const isCurrentLocationMatching = (pageToTest: PageDescription) =>
    new RegExp(pageToTest.url).test(getCurrentLocation())

export const getCurrentQueryParams = () => location.search

export interface WithRouterProps<T = unknown> {
    location: ReturnType<typeof useLocation>
    navigate: ReturnType<typeof useNavigate>
    params: T
    searchParams: ReturnType<typeof useSearchParams>[0]
    setSearchParams: ReturnType<typeof useSearchParams>[1]
}

// Wrapper used to provide the react-router-6 hook to class components
export const withRouter = <P extends object>(
    Component: React.ComponentType<P>,
) =>
    function WithRouterComponent(
        props: Omit<P, keyof WithRouterProps<object>>,
    ) {
        const location = useLocation()
        const navigate = useNavigate()
        const params = useParams()
        const [searchParams, setSearchParams] = useSearchParams()

        return (
            <Component
                {...(props as P)}
                {...{
                    location,
                    navigate,
                    params,
                    searchParams,
                    setSearchParams,
                }}
            />
        )
    }
