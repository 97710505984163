import type { MaybePaginatedDownloadModalProps } from '~/components/Downloader/MaybePaginatedDownloadModal'
import type { Action } from '../common/actions'
import { isType } from '../common/actions'
import { FilesOptionsClosed, FilesOptionsTriggered } from './actions'

type GeneralOption<T> = { type: T }

export type DownloadHugeSelectionOptions = Omit<
    MaybePaginatedDownloadModalProps,
    'onCancel'
>
// onCancel is injected by the placement
export type DownloadHugeSelectionFilesOptions =
    GeneralOption<'download_huge_selection'> & DownloadHugeSelectionOptions
export type FilesOptions =
    | GeneralOption<'add_to_album'>
    | GeneralOption<'share_to_album'>
    | GeneralOption<'share'>
    | GeneralOption<'filter'>
    | DownloadHugeSelectionFilesOptions

export type FilesOptionsState = {
    options?: FilesOptions
}

const initialState: FilesOptionsState = {
    options: undefined,
}

export const filesOptionsReducer = (
    state: FilesOptionsState = initialState,
    action: Action,
): FilesOptionsState => {
    if (isType(action, FilesOptionsTriggered)) {
        return {
            ...state,
            options: action.payload,
        }
    }
    if (isType(action, FilesOptionsClosed)) {
        return {
            ...state,
            options: undefined,
        }
    }

    return state
}

export const filesOptionsReducerMapObj = {
    filesOptions: filesOptionsReducer,
}
export type StateWithFilesOptions = StateOfReducerMapObj<
    typeof filesOptionsReducerMapObj
>
