import { useDispatch, useSelector } from 'react-redux'
import { setAlbumCoverPhoto } from '~/API/album'
import { copyAlbumFilesToTimeline } from '~/API/job'
import { FilesOptionsTriggered } from '~/state/FilesOptions/actions'
import { hideSideInfoMenu, showSideInfoMenu } from '~/state/contextMenu/actions'
import {
    getContextMenuTarget,
    getIsTargetCoverPhoto,
} from '~/state/contextMenu/selectors'
import {
    getIsReadOnlyUser,
    isLoggedIn as isLoggedInSel,
} from '~/state/currentUser/selectors'
import { getViewportHeight, getViewportWidth } from '~/state/viewMode/selectors'
import { copyImageToClipboard } from '~/utilities/copyImageToClipboard'
import { getFileExtensionUpperCase } from '~/utilities/fileTarget'
import { trackEvent, trackEventInternal } from '~/analytics/eventTracking'
import { FileDeletionInitiated } from '~/state/userActions/actions'
import { downloadSingleFile } from '~/API/download'

const emitFromAlbum = {
    shareRequest: () => {
        trackEventInternal('album_contextmenu_share_start')
    },
    addToAlbumRequest: () => {
        trackEventInternal('album_contextmenu_add_to_album_start')
    },
    addToTimelineRequest: () => {
        trackEventInternal('album_contextmenu_copy_to_timeline')
    },
    setCoverRequest: () => {
        trackEvent('AlbumPage', 'SetPhotoAsCoverPhoto_click')
        trackEventInternal('albumlist_set_photo_as_cover_photo_click')
    },
    deleteRequest: () => {
        trackEventInternal('album_contextmenu_delete')
    },
    infoRequest: () => {
        trackEventInternal('album_contextmenu_info')
    },
    copyRequest: () => {
        trackEventInternal('album_contextmenu_copy')
    },
    menuLoaded: () => {
        trackEventInternal('album_contextmenu_loaded')
    },
}

const emitFromTimeline = {
    shareRequest: () => {
        trackEventInternal('timeline_contextmenu_share_start')
    },
    addToAlbumRequest: () => {
        trackEventInternal('timeline_contextmenu_add_to_album_start')
    },
    deleteRequest: () => {
        trackEventInternal('timeline_contextmenu_delete')
    },
    infoRequest: () => {
        trackEventInternal('timeline_contextmenu_info')
    },
    copyRequest: () => {
        trackEventInternal('timeline_contextmenu_copy')
    },
    menuLoaded: () => {
        trackEventInternal('timeline_contextmenu_loaded')
    },
}

const useFileContextMenu = (isAlbum?: boolean) => {
    const windowWidth = useSelector(getViewportWidth)
    const windowHeight = useSelector(getViewportHeight)
    const file = useSelector(getContextMenuTarget)
    const fileExtension = file && getFileExtensionUpperCase(file.path)
    const isLoggedIn = useSelector(isLoggedInSel)
    const isAlbumCover = useSelector(getIsTargetCoverPhoto)
    const isReadOnlyUser = useSelector(getIsReadOnlyUser)

    const dispatch = useDispatch()

    const downloadFile = (type: 'download' | 'export') => {
        if (file) {
            downloadSingleFile(dispatch, {
                type,
                jobID: file.jobID,
                fileID: file.fileID,
                path: file.path,
                context: isAlbum ? 'AlbumPage' : 'TimelinePage',
            })
        }
    }

    const shareFile = () => {
        dispatch(FilesOptionsTriggered({ type: 'share' }))
        isAlbum ? emitFromAlbum.shareRequest() : emitFromTimeline.shareRequest()
    }

    const copyFileToClipboard = () => {
        if (file) {
            copyImageToClipboard(dispatch, file)
            isAlbum
                ? emitFromAlbum.copyRequest()
                : emitFromTimeline.copyRequest()
        }
    }

    const addFileToAlbum = () => {
        dispatch(FilesOptionsTriggered({ type: 'add_to_album' }))
        isAlbum
            ? emitFromAlbum.addToAlbumRequest()
            : emitFromTimeline.addToAlbumRequest()
    }

    const copyToTimeline = () => {
        if (file) {
            copyAlbumFilesToTimeline(dispatch, file.jobID, [file.fileID])
            if (isAlbum) emitFromAlbum.addToTimelineRequest()
        }
    }

    const setAsCoverPhoto = () => {
        if (file) {
            setAlbumCoverPhoto(dispatch, file.jobID, file.fileID)
            emitFromAlbum.setCoverRequest()
        }
    }

    const deleteFile = () => {
        if (file) {
            dispatch(FileDeletionInitiated(file.fileID))
            isAlbum
                ? emitFromAlbum.deleteRequest()
                : emitFromTimeline.deleteRequest()
        }
    }

    const showSideFileInfo = () => {
        dispatch(showSideInfoMenu())
        isAlbum ? emitFromAlbum.infoRequest() : emitFromTimeline.infoRequest()
    }

    const hideSideFileInfo = () => dispatch(hideSideInfoMenu())
    const emitMenuLoaded = () =>
        isAlbum ? emitFromAlbum.menuLoaded() : emitFromTimeline.menuLoaded()

    return {
        windowWidth,
        windowHeight,
        file,
        fileExtension,
        isLoggedIn,
        isAlbumCover,
        isReadOnlyUser,
        downloadFile,
        shareFile,
        copyFileToClipboard,
        addFileToAlbum,
        copyToTimeline,
        setAsCoverPhoto,
        deleteFile,
        showSideFileInfo,
        hideSideFileInfo,
        emitMenuLoaded,
    }
}

export default useFileContextMenu
