import type { SVGProps } from 'react'
const SvgLike = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill={props.color ?? 'currentColor'}
            d="M12 20.325c-.233 0-.47-.042-.713-.125a1.653 1.653 0 0 1-.637-.4l-1.725-1.575a68.377 68.377 0 0 1-4.788-4.813C2.714 11.821 2 10.067 2 8.15c0-1.567.525-2.875 1.575-3.925C4.625 3.175 5.933 2.65 7.5 2.65c.883 0 1.717.187 2.5.562.783.375 1.45.888 2 1.538a5.956 5.956 0 0 1 2-1.538 5.717 5.717 0 0 1 2.5-.562c1.567 0 2.875.525 3.925 1.575C21.475 5.275 22 6.583 22 8.15c0 1.917-.708 3.675-2.125 5.275a59.863 59.863 0 0 1-4.825 4.825l-1.7 1.55a1.653 1.653 0 0 1-.637.4c-.242.083-.48.125-.713.125M11.05 6.75c-.483-.683-1-1.204-1.55-1.563-.55-.358-1.217-.537-2-.537-1 0-1.833.333-2.5 1s-1 1.5-1 2.5c0 .867.308 1.787.925 2.762.617.975 1.354 1.921 2.213 2.838a38.412 38.412 0 0 0 2.65 2.575c.908.8 1.645 1.458 2.212 1.975.567-.517 1.304-1.175 2.213-1.975.908-.8 1.791-1.658 2.65-2.575a19.097 19.097 0 0 0 2.212-2.838c.617-.975.925-1.895.925-2.762 0-1-.333-1.833-1-2.5s-1.5-1-2.5-1c-.783 0-1.45.18-2 .537-.55.359-1.067.88-1.55 1.563a1.138 1.138 0 0 1-.95.5 1.138 1.138 0 0 1-.95-.5"
        />
    </svg>
)
export default SvgLike
