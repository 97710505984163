import { isMobileDevice } from './device'

export const shareToLine = (content: string): void => {
    if (isMobileDevice.iOS()) {
        window.location.href = `line://msg/text/${content}`
    } else if (isMobileDevice.Android()) {
        window.location.href = `intent://msg/text/${content}#Intent;scheme=line;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=jp.naver.line.android;end;`
    }
}

export const shareToSMS = (content: string): void => {
    const linkDelimiter = isMobileDevice.Android() ? `?` : `&`
    window.location.href = `sms:${linkDelimiter}body=${content}`
}

export const shareToEmail = (content: string): void => {
    window.location.href = `mailto:?body=${content}`
}
