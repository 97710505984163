import * as React from 'react'
import styled from 'styled-components'
import type { AlbumFileComment } from '~/state/album/selectors'
import { isMobileDevice } from '~/utilities/device'
import { AlbumFileLoveInfo } from '../Reaction/AlbumFileLoveInfo'
import { CommentInputComponent } from './CommentInputComponent'
import { CommentsListComponent } from './CommentsListComponent'

type Props = {
    fileID: FileID
    albumID: JobID
    comments: AlbumFileComment[]
    commentsCanBeAdded: boolean
    isPendingNewComment: boolean
    truncateLongComments: boolean
    lovedByCurrentUser: boolean
    totalLoveCount: number
    onLoveChanged: (ifLove: boolean) => void
    doShowLoveList: () => void
}

const CommentsWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

const CommentsListWrapper = styled.div`
    height: 100%;
    margin-top: 1rem;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    position: relative;
`

export class CommentsComponent extends React.Component<Props> {
    private list = React.createRef<HTMLDivElement>()

    public componentDidMount() {
        if (this.list.current) {
            this.list.current.scrollTop = this.list.current.scrollHeight
            if (isMobileDevice.iOS()) {
                this.list.current.addEventListener(
                    'touchmove',
                    this.handleScroll,
                )
            } else if (isMobileDevice.any()) {
                this.list.current.addEventListener('scroll', this.handleScroll)
            } else {
                this.list.current.addEventListener(
                    'mousewheel',
                    this.handleScroll,
                )
            }
        }
    }

    public componentDidUpdate(prevProps: Props) {
        if (this.list.current && this.props.isPendingNewComment) {
            // user posted new comment
            this.list.current.scrollTop = this.list.current.scrollHeight
        } else if (
            // new comment detected
            this.list.current &&
            this.props.comments.length > prevProps.comments.length
        ) {
            // TODO: the following suggests that new comments by other users might appear
            // this does not seem to be possible currently (but should be)
            const didCurrentUserComment: boolean =
                this.props.comments[this.props.comments.length - 1]
                    .currentUserCan.editComment
            const amAtBottom: boolean =
                this.list.current.scrollTop ===
                this.list.current.scrollHeight - this.list.current.clientHeight
            if (didCurrentUserComment || amAtBottom) {
                this.list.current.scrollTop = this.list.current.scrollHeight
            }
        }
    }

    private handleScroll = (e: Event) => {
        e.stopPropagation()
    }

    public render() {
        return (
            <CommentsWrapper>
                {this.props.totalLoveCount > 0 && (
                    <AlbumFileLoveInfo
                        isLoved={this.props.lovedByCurrentUser}
                        totalLoveCounts={this.props.totalLoveCount}
                        onLoveChanged={this.props.onLoveChanged}
                        doShowLoveList={this.props.doShowLoveList}
                    />
                )}
                <CommentsListWrapper ref={this.list}>
                    <CommentsListComponent
                        showNumberOfComments={this.props.comments.length}
                        comments={this.props.comments}
                        isPendingNewComment={this.props.isPendingNewComment}
                        truncateLongComments={this.props.truncateLongComments}
                    />
                </CommentsListWrapper>
                {this.props.commentsCanBeAdded && (
                    <CommentInputComponent
                        albumID={this.props.albumID}
                        fileID={this.props.fileID}
                        maxHeight={216}
                    />
                )}
            </CommentsWrapper>
        )
    }
}
