import { colors } from '~/assets/styleConstants'
import type { IconProps } from '.'

export const RainingCloudIcon: React.FunctionComponent<IconProps> = (props) => (
    <svg
        width={props.size}
        height={props.size}
        viewBox="0 0 223 284"
        fill={props.color || colors.defaultIconColor}
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M84.1778 266.324H91.2425V269.654C91.2519 269.976 91.1415 270.29 90.9328 270.536C90.8087 270.663 90.6581 270.761 90.4919 270.823C90.3256 270.885 90.1477 270.909 89.9709 270.895H84.1778V283.204H79.0586V270.895H58.3699C57.9628 270.904 57.5651 270.771 57.245 270.519C56.9416 270.289 56.7282 269.96 56.6418 269.589L56.0386 266.634L78.7652 236.433H84.1996L84.1778 266.324ZM79.0586 247.044C79.0586 246.478 79.0749 245.869 79.1075 245.216C79.1428 244.538 79.2135 243.862 79.3195 243.192L62.3316 266.34H79.0586V247.044Z"
            fill="#0091D2"
        />
        <path
            d="M128.425 259.827C128.489 263.41 128.044 266.985 127.104 270.443C126.377 273.123 125.155 275.642 123.501 277.871C122.081 279.738 120.229 281.232 118.105 282.224C116.009 283.171 113.736 283.661 111.437 283.661C109.137 283.661 106.864 283.171 104.769 282.224C102.658 281.225 100.818 279.732 99.4049 277.871C97.7518 275.644 96.5348 273.124 95.8182 270.443C94.0791 263.46 94.0791 256.156 95.8182 249.172C96.5351 246.482 97.7519 243.951 99.4049 241.712C100.812 239.835 102.653 238.328 104.769 237.32C106.864 236.373 109.137 235.884 111.437 235.884C113.736 235.884 116.009 236.373 118.105 237.32C120.234 238.321 122.087 239.829 123.501 241.712C125.155 243.952 126.377 246.482 127.104 249.172C128.045 252.643 128.49 256.23 128.425 259.827ZM122.392 259.827C122.448 256.811 122.147 253.799 121.496 250.854C121.043 248.773 120.226 246.788 119.083 244.993C118.215 243.641 117.006 242.543 115.578 241.81C114.289 241.176 112.872 240.847 111.437 240.847C110.001 240.847 108.584 241.176 107.296 241.81C105.87 242.541 104.666 243.64 103.807 244.993C102.674 246.792 101.863 248.775 101.41 250.854C100.217 256.78 100.217 262.885 101.41 268.811C101.864 270.883 102.675 272.861 103.807 274.655C104.666 276.008 105.87 277.107 107.296 277.838C108.584 278.472 110.001 278.801 111.437 278.801C112.872 278.801 114.289 278.472 115.578 277.838C117.006 277.105 118.215 276.007 119.083 274.655C120.225 272.864 121.042 270.886 121.496 268.811C122.15 265.862 122.451 262.846 122.392 259.827Z"
            fill="#0091D2"
        />
        <path
            d="M159.825 266.324H166.889V269.654C166.899 269.976 166.788 270.29 166.58 270.536C166.456 270.663 166.305 270.761 166.139 270.823C165.973 270.885 165.795 270.909 165.618 270.895H159.825V283.204H154.706V270.895H134.001C133.593 270.904 133.196 270.771 132.876 270.519C132.572 270.289 132.359 269.96 132.272 269.589L131.686 266.634L154.412 236.433H159.847L159.825 266.324ZM154.706 247.044C154.706 246.478 154.722 245.869 154.755 245.216C154.79 244.538 154.86 243.862 154.966 243.192L137.979 266.34H154.706V247.044Z"
            fill="#0091D2"
        />
        <g clipPath="url(#clip0_1850_2515)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M180.736 47.2736C171.966 19.2651 145.644 0 114.058 0C77.2069 0 45.6249 28.022 43.8701 63.0315H36.8509C17.5481 63.0315 0 78.7894 0 98.0726C0 115.586 12.2836 129.588 29.8317 133.1H175.481C200.043 133.1 222.847 113.835 222.847 89.3157C221.092 68.2992 203.544 50.7899 180.731 47.2872"
                fill="#0091D2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M48.2192 149.016C48.1735 150.295 47.6043 151.506 46.6317 152.395C45.6591 153.284 44.3592 153.78 43.0064 153.78C41.6535 153.78 40.3536 153.284 39.381 152.395C38.4084 151.506 37.8392 150.295 37.7935 149.016C37.7935 146.298 40.1275 140.748 43.0093 140.748C45.8912 140.748 48.2192 146.298 48.2192 149.016Z"
                fill="#0091D2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M184.988 175.24C184.988 176.521 184.475 177.749 183.562 178.655C182.649 179.561 181.411 180.07 180.12 180.07C178.829 180.07 177.591 179.561 176.678 178.655C175.765 177.749 175.252 176.521 175.252 175.24C175.252 172.574 177.433 167.131 180.12 167.131C182.807 167.131 184.988 172.557 184.988 175.24Z"
                fill="#0091D2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M151.452 151.518C151.452 152.799 150.939 154.028 150.026 154.933C149.114 155.839 147.875 156.348 146.584 156.348C145.293 156.348 144.055 155.839 143.142 154.933C142.229 154.028 141.716 152.799 141.716 151.518C141.716 148.852 143.897 143.409 146.584 143.409C149.271 143.409 151.452 148.835 151.452 151.518Z"
                fill="#0091D2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M100.608 167.294C100.551 168.986 99.8419 170.589 98.6309 171.766C97.4198 172.943 95.8013 173.6 94.1168 173.6C92.4323 173.6 90.8137 172.943 89.6027 171.766C88.3916 170.589 87.6829 168.986 87.626 167.294C87.626 163.695 90.5322 156.348 94.1131 156.348C97.694 156.348 100.608 163.732 100.608 167.294Z"
                fill="#0091D2"
            />
        </g>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.0064 215.229C42.9378 217.274 42.0839 219.212 40.625 220.634C39.1661 222.056 37.2163 222.851 35.187 222.851C33.1578 222.851 31.2079 222.056 29.749 220.634C28.2901 219.212 27.4362 217.274 27.3677 215.229C27.3677 210.879 30.8687 202 35.1825 202C39.4964 202 43.0064 210.924 43.0064 215.229Z"
            fill="#0091D2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M136.838 203.981C136.838 205.53 136.221 207.014 135.121 208.109C134.021 209.204 132.529 209.819 130.974 209.819C129.419 209.819 127.927 209.204 126.827 208.109C125.727 207.014 125.109 205.53 125.109 203.981C125.109 200.759 127.737 194.18 130.974 194.18C134.211 194.18 136.838 200.739 136.838 203.981Z"
            fill="#0091D2"
        />
        <defs>
            <clipPath id="clip0_1850_2515">
                <rect width="222.851" height="181.148" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
