import {
    CarouselViewEntered,
    ChangedCurrentCarouselFile,
} from '../carouselViewer/actions'
import type { Action } from '../common/actions'
import { isType } from '../common/actions'
import {
    LastSeenAlbumUpdated,
    LastSeenElementCleared,
    LastSeenElementUpdated,
} from './actions'

export type LastSeenElementState = {
    activeFile: DictionaryOf<FileID | undefined>
    currentAlbum: JobID | undefined
}

const initialState: LastSeenElementState = {
    activeFile: {},
    currentAlbum: undefined,
}

export const lastSeenElementReducer = (
    state: LastSeenElementState = initialState,
    action: Action,
): LastSeenElementState => {
    if (
        isType(action, LastSeenElementUpdated) ||
        isType(action, CarouselViewEntered) ||
        isType(action, ChangedCurrentCarouselFile)
    ) {
        return {
            ...state,
            activeFile: {
                ...state.activeFile,
                [action.payload.jobID]: action.payload.fileID,
            },
        }
    }

    if (isType(action, LastSeenElementCleared)) {
        return {
            ...state,
            activeFile: {
                ...state.activeFile,
                [action.payload]: undefined,
            },
        }
    }
    if (isType(action, LastSeenAlbumUpdated)) {
        return {
            ...state,
            currentAlbum: action.payload,
        }
    }

    return state
}

export const lastSeenElementReducerMapObj = {
    lastSeenElement: lastSeenElementReducer,
}

export type StateWithLastSeenElement = StateOfReducerMapObj<
    typeof lastSeenElementReducerMapObj
>
