import styled from 'styled-components'
import { UserMenu } from '~/components/Common/UserMenu'
import { fontSize, mediaQueries } from '~/assets/styleConstants'
import { TopNavBar } from './TopNavBar'

const Wrapper = styled.div`
    height: 100%;
    padding: 8px 24px;
    box-sizing: border-box;
    font-size: ${fontSize.small_14};
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${mediaQueries.mobile} {
        padding: 8px;
        font-size: ${fontSize.small_12};
    }
`

const LeftArea = styled.div`
    display: flex;
    flex: 1;
    justify-self: flex-start;
    align-self: center;
    display: flex;
    align-items: center;
`

const RightArea = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

type Props = {
    left?: React.ReactNode
    right?: React.ReactNode
    hideUserAvi?: boolean
}

export const TwoAreasTopNavBar: React.FunctionComponent<Props> = (props) => {
    const avatar = !props.hideUserAvi && (
        <UserMenu
            key="avi_area"
            showSeparator={typeof props.right !== 'undefined'}
            size={32}
        />
    )
    return (
        <TopNavBar>
            <Wrapper>
                <LeftArea>{props.left}</LeftArea>
                <RightArea>
                    {props.right}
                    {avatar}
                </RightArea>
            </Wrapper>
        </TopNavBar>
    )
}
