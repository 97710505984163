import { withoutTheUndefined } from './arrayUtils'

// Method for packing Hex-uuid as Base64-string (used when passing UUIDs to mobile)
export const uuidToB64 = (input: string) => {
    const primedInput = input.replace(/-/g, '')
    const binary = hexToBytes(primedInput)
    const output = bytesToBase64(binary)
    return output.replace(/\+/g, '-').replace(/\//g, '_').replace(/==$/, '')
}

// Method for unpacking Hex-uuid stored as Base64-string
export const b64ToUuid = (input: string) => {
    // Input was url-friendly:
    input = input.replace(/-/g, '+').replace(/_/g, '/')
    const binary = base64ToBytes(input)
    const charString = bytesToHex(binary)

    // Format the string as UUID
    return (
        charString.slice(0, 8) +
        '-' +
        charString.slice(8, 12) +
        '-' +
        charString.slice(12, 16) +
        '-' +
        charString.slice(16, 20) +
        '-' +
        charString.slice(20)
    )
}

export const getRandomUUID = () => {
    // Modified UUID.v4-generator from https://stackoverflow.com/a/8809472/1419036
    let d = Date.now() + (window.performance?.now?.() ?? 0)
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
}

export function hexToBytes(input: string) {
    const m = input.match(/.{1,2}/g)
    if (m === null) {
        throw new Error('Provided hex string is empty!')
    }
    const curatedBytes = m.map((byte) => {
        const parsed = parseInt(byte, 16)
        if (!Number.isNaN(parsed)) {
            return parsed
        }
    })
    return Uint8Array.from(withoutTheUndefined(curatedBytes))
}

export function bytesToHex(bytes: Uint8Array) {
    return bytes.reduce(
        (str, byte) => str + byte.toString(16).padStart(2, '0'),
        '',
    )
}
//  https://developer.mozilla.org/en-US/docs/Glossary/Base64
export function base64ToBytes(input: string) {
    const binString = atob(input)
    return Uint8Array.from(binString, (char) => char.charCodeAt(0))
}

export function bytesToBase64(bytes: Uint8Array) {
    const binString = String.fromCodePoint(...bytes)
    return btoa(binString) // Not deprecated in browser
}
