import * as React from 'react'
import { trackEvent } from '~/analytics/eventTracking'

type Props = {
    context: string
    whenScrolledDown?: string
    whenPageLoads?: string
    children?: React.ReactNode
}

/**
 * Common analytics-component for things that typically are tracked on Pages
 * Wrap the page-element in this component and provide the events to track when the actions occur
 */
export class PageEventTrigger extends React.Component<
    Props,
    { haveTrackedScrollDown: boolean }
> {
    public state = { haveTrackedScrollDown: false }
    public mounted = false

    public componentDidMount() {
        this.mounted = true
        if (this.props.whenPageLoads !== undefined) {
            trackEvent(this.props.context, this.props.whenPageLoads)
        }
        if (this.props.whenScrolledDown !== undefined) {
            window.addEventListener('scroll', this.handleScroll)
        }
    }

    public componentWillUnmount() {
        this.mounted = false
        window.removeEventListener('scroll', this.handleScroll)
    }

    public render() {
        return React.Children.only(this.props.children)
    }

    private handleScroll = () => {
        const doTrigger =
            !this.state.haveTrackedScrollDown &&
            window.pageYOffset > window.innerHeight
        if (doTrigger && this.props.whenScrolledDown !== undefined) {
            trackEvent(this.props.context, this.props.whenScrolledDown)
            if (this.mounted) {
                this.setState({ haveTrackedScrollDown: true })
            }
        }
    }
}
