import type { PropsWithChildren } from 'react'
import { useEffect } from 'react'
import styled from 'styled-components'
import { colors, zIndex } from '~/assets/styleConstants'
import {
    disableBodyScroll,
    enableBodyScroll,
} from '~/utilities/preventBodyScroll'

// we dont want keyboard navigation on this components
/* eslint-disable styled-components-a11y/no-static-element-interactions */
/* eslint-disable styled-components-a11y/click-events-have-key-events */

type OverlayProps = {
    color: string
    doStack: boolean
}

const Overlay = styled.div`
    background-color: ${(props: OverlayProps) => props.color};
    z-index: ${(props: { doStack: boolean }) =>
        props.doStack ? zIndex.fullscreen + 1 : zIndex.fullscreen};

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: ${(props: { align?: Alignment }) => props?.align ?? 'center'};
`

type Alignment = 'baseline' | 'center' | 'end'

type FullscreenOverlayProps = {
    onOverlayClick?: (event: React.MouseEvent<HTMLDivElement>) => void
    color?: string
    shouldStackOnTop?: boolean
    align?: Alignment
}

export const FullscreenOverlay = (
    props: PropsWithChildren<FullscreenOverlayProps>,
) => {
    const clickOverlayHandler = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        if (event.target === event.currentTarget) {
            props?.onOverlayClick?.(event)
        }
    }

    useEffect(() => {
        disableBodyScroll()
        return () => {
            enableBodyScroll()
        }
    }, [])

    const color = props.color || colors.blackSemitransparent
    const shouldStackOnTop = props.shouldStackOnTop ?? true
    return (
        <Overlay color={color} doStack={shouldStackOnTop}>
            <Wrapper onClick={clickOverlayHandler} align={props.align}>
                {props.children}
            </Wrapper>
        </Overlay>
    )
}
