import type { Store } from '@reduxjs/toolkit'
import { getFileInNeedOfMetadata } from '~/state/carouselViewer/selectors'
import { fetchFileMetadata } from '../job'

/**
 * The job of the FileMetadataSyncer:
 * - fetch the metadata for current viewer node file if it is not present
 */

class FileMetadataSyncer {
    private current?: FileID

    constructor(private store: Store) {
        store.subscribe(() => this.digestState(store.getState()))
    }

    private digestState = async (
        newState?: StateOfSelector<typeof getFileInNeedOfMetadata>,
    ) => {
        if (newState === undefined) {
            console.warn('FileMetadataSyncer: undefined state.')
            return
        }

        const currentFile = getFileInNeedOfMetadata(newState)
        // Only fetch metadata for file that requires fetching
        if (currentFile === undefined || this.current === currentFile.fileID) {
            return
        }
        this.current = currentFile.fileID
        await fetchFileMetadata(
            this.store.dispatch,
            currentFile.jobID,
            currentFile.fileID,
        )
    }
}

export const connectFileMetadataSyncer = (store: Store) => {
    new FileMetadataSyncer(store)
}
