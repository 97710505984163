import { createSelector } from 'reselect'
import {
    mediaBreakpoint__TVsmall,
    mediaBreakpoint__mobile,
    mediaBreakpoint__tabletLandscape,
    mediaBreakpoint__tabletPortrait,
} from '~/assets/styleConstants'
import type { ImageGroupStyle } from '~/utilities/imageGroupStyle'
import { makeImageGroupStyle } from '~/utilities/imageGroupStyle'
import type { StateWithViewMode } from './reducer'

export const getViewportWidth = (state: StateWithViewMode) =>
    state.viewMode.width
export const getViewportHeight = (state: StateWithViewMode) =>
    state.viewMode.height

// If window width is less than breakpoint -> mobile, else desktop
export const isMobileMode = (state: StateWithViewMode) =>
    getViewportWidth(state) <= mediaBreakpoint__mobile
export const isLargerThanTablet = (state: StateWithViewMode) =>
    getViewportWidth(state) > mediaBreakpoint__tabletLandscape
export const isTabletPortraitOrSmaller = (state: StateWithViewMode) =>
    getViewportWidth(state) < mediaBreakpoint__tabletPortrait

export const isLandscapeOrientation = (state: StateWithViewMode) =>
    getViewportWidth(state) > getViewportHeight(state)

export const isTVSmall = (state: StateWithViewMode) =>
    getViewportWidth(state) < mediaBreakpoint__TVsmall

export const getImageGroupStyle: (state: StateWithViewMode) => ImageGroupStyle =
    createSelector(getViewportWidth, isMobileMode, makeImageGroupStyle)
