import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { trackEvent, trackEventInternal } from '~/analytics/eventTracking'
import { deleteFile } from '~/API/job'
import { Pages } from '~/routing'
import { AlbumCreatedFromUploadFilesFlag } from '~/state/album/actions'
import type { PendingAlbum } from '~/state/album/selectors'
import { getAlbumImageGroupStyle } from '~/state/album/selectors'
import { getConnectedInstance } from '~/state/uploader/uploadQueue'
import { isMobileMode } from '~/state/viewMode/selectors'
import { UploadOptions } from '../Common/UploadOptionsModal'
import type { Folder } from '../Uploader/FileAcceptZone'
import { flattenFolder } from '../Uploader/FileAcceptZone'
import { EditAlbumFileList } from './EditAlbumFileList'

const emitFromEditAlbumFileSection = {
    dropFiles: (count: number) => {
        trackEvent('Uploader', 'FilesAddedToAlbumByDrop', '', count)
        trackEventInternal('albumedit_uploader_files_added_by_drop', {
            count: count,
        })
    },
    dropFolders: (count: number) => {
        trackEvent('Uploader', 'FoldersAddedByDrop', '', count)
        trackEventInternal('albumedit_uploader_folders_added_by_drop', {
            count: count,
        })
    },
    selectedFiles: (count: number) => {
        trackEvent('Uploader', 'FilesAddedBySelection', '', count)
    },
}

export const EditAlbumFileSection = ({
    album,
    albumID,
}: {
    album: PendingAlbum
    albumID: string
}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isMobile = useSelector(isMobileMode)
    const styling = useSelector(getAlbumImageGroupStyle)

    const handleFileDrop = async (folder: Folder) => {
        const files = flattenFolder(folder)
        emitFromEditAlbumFileSection.dropFiles(files.length)
        getConnectedInstance().addFiles(files, albumID)
        if (folder.children.length > 0) {
            emitFromEditAlbumFileSection.dropFolders(folder.children.length)
        }
        dispatch(AlbumCreatedFromUploadFilesFlag())
    }
    const handleFilesFromSelection = (files: File[]) => {
        emitFromEditAlbumFileSection.selectedFiles(files.length)
        getConnectedInstance().addFiles(files, albumID)
        dispatch(AlbumCreatedFromUploadFilesFlag())
    }
    const addPhotosFromCapture = () => {
        navigate(Pages.CreateAlbumAddPhotos(albumID).url)
    }
    const doRemoveFile = (fileID: FileID) =>
        deleteFile(dispatch, albumID, fileID)

    if (album?.uploadedFiles.length === 0 && album?.pendingFiles.length === 0) {
        return (
            <UploadOptions
                doHandleDraggedFiles={handleFileDrop}
                doHandleFiles={handleFilesFromSelection}
                doAddFromCapture={addPhotosFromCapture}
            />
        )
    }

    return (
        <EditAlbumFileList
            isMobile={isMobile}
            onClickAddMore={addPhotosFromCapture}
            onRemoveFile={doRemoveFile}
            pendingAlbum={album}
            styling={styling}
        />
    )
}
