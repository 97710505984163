import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit'
import { type Action, BulkOfActions, isType } from './actions'

export const bulkDispatchMiddleware: Middleware = (store: MiddlewareAPI) => {
    function dispatchChildActions(store: MiddlewareAPI, action: Action) {
        if (isType(action, BulkOfActions)) {
            action.payload.forEach(function (childAction) {
                dispatchChildActions(store, childAction)
            })
        } else {
            store.dispatch(action)
        }
    }

    return (next) => (action) => {
        if (isType(action, BulkOfActions)) {
            dispatchChildActions(store, action)
        }
        return next(action)
    }
}
