import type { AlbumFile } from '~/state/album/selectors'
import type { BasicViewFile } from '~/state/files/selectors'
import type { Action } from '../common/actions'
import { isType } from '../common/actions'
import {
    hideSideInfoMenu,
    setContextMenuTarget,
    showSideInfoMenu,
    unsetContextMenuTarget,
} from './actions'

export type ContextMenuState = {
    targetFile?: BasicViewFile | AlbumFile
    sideInfoMenu: boolean
}

const initialState: ContextMenuState = {
    targetFile: undefined,
    sideInfoMenu: false,
}

/*
On rightClick set the contextMenu open and set the target file
Receives the target file as payload
*/
export const contextMenuReducer = (
    state: ContextMenuState = initialState,
    action: Action,
): ContextMenuState => {
    if (isType(action, setContextMenuTarget)) {
        return {
            ...state,
            targetFile: action.payload,
        }
    }
    if (isType(action, unsetContextMenuTarget)) {
        return {
            ...state,
            targetFile: undefined,
        }
    }
    if (isType(action, showSideInfoMenu)) {
        return {
            ...state,
            sideInfoMenu: true,
        }
    }
    if (isType(action, hideSideInfoMenu)) {
        return {
            ...state,
            sideInfoMenu: false,
        }
    }

    return state
}

export const contextMenuReducerMapObj = {
    contextMenu: contextMenuReducer,
}

export type StateWithContextMenu = StateOfReducerMapObj<
    typeof contextMenuReducerMapObj
>
