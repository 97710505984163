import type { CaptureMediaItem } from '~/API/syncers/CastSyncer'
import { createActionCreator, createVoidActionCreator } from '../common/actions'
import type { PlaybackOrder, VideoPlayStatus } from './reducer'

export const CarouselViewEntered = createActionCreator<
    JobFileReference & { sourcePage?: 'AlbumPage' | 'TimelinePage' }
>('CAROUSEL_VIEW_ENTERED')

export const CarouselViewExited = createVoidActionCreator(
    'CAROUSEL_VIEW_EXITED',
)
export const ChangedCurrentCarouselFile = createActionCreator<JobFileReference>(
    'CHANGED_CURRENT_CAROUSEL_FILE',
)

export type SideModuleContentType = 'comments' | 'info'
export const CarouselViewSideModuleOpened =
    createActionCreator<SideModuleContentType>(
        'SIDE_MODULE_CAROUSEL_VIEW_WAS_OPENED',
    )
export const CarouselViewSideModuleClosed = createVoidActionCreator(
    'SIDE_MODULE_CAROUSEL_VIEW_WAS_CLOSED',
)
export const EmailFormOpened = createVoidActionCreator('EMAIL_FORM_WAS_OPENED')
export const EmailFormClosed = createVoidActionCreator('EMAIL_FORM_WAS_CLOSED')

export const FullscreenModeEntered = createVoidActionCreator(
    'ENTERED_FULLSCREEN_MODE',
)
export const FullscreenModeExited = createVoidActionCreator(
    'EXITED_FULLSCREEN_MODE',
)

export const CarouselCastingStarted = createActionCreator<{
    castDeviceName: string
}>('CAROUSEL_CASTING_STARTED')
export const CarouselCastingStopped = createVoidActionCreator(
    'CAROUSEL_CASTING_STOPPED',
)
export const ChangedCastedFile = createActionCreator<JobFileReference>(
    'CHANGED_CASTED_FILE',
)
export const CastSessionWasResumed = createActionCreator<{
    castedItem: CaptureMediaItem
}>('CAST_SESSION_WAS_RESUMED')

export const AutoNavigateSet = createActionCreator<boolean>('AUTO_NAVIGATE_SET')

export const PlaybackOrderSet =
    createActionCreator<PlaybackOrder>('PLAYBACK_ORDER_SET')

export const CarouselVideoPlayStatusChanged =
    createActionCreator<VideoPlayStatus>('CAROUSEL_VIDEO_PLAY_STATUS_CHANGED')
