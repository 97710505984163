import { createSelector } from 'reselect'
import type { GridStyle } from '~/utilities/gridElementSizeCalculator'
import {
    calcImagesPerRow,
    computeGridContainerWidth,
} from '~/utilities/gridElementSizeCalculator'
import type { ExtendedJobFile, StateWithFiles } from '../files/reducer'
import type { CaptureFile } from '../files/selectors'
import {
    getCaptureFilesForJob,
    getDocumentFilesForJob,
} from '../files/selectors'
import type { JobInfoElement, StateWithJobInfo } from '../jobInfo/reducer'
import { getJobInfoElement } from '../jobInfo/selectors'
import { getLastSeenElementForJob } from '../lastSeenElement/selectors'
import type { User, StateWithUsers } from '../users/reducer'
import { getUsersInfo } from '../users/selectors'
import {
    getViewportHeight,
    getViewportWidth,
    isMobileMode,
} from '../viewMode/selectors'

export type Share = {
    id: JobID
    owner: User
    thumbFiles: CaptureFile[]
    docFiles: ExtendedJobFile[]
}

const unknownUser: User = { userID: 'unknown', name: '', email: '' }

export const getShare = createSelector(
    getJobInfoElement,
    getCaptureFilesForJob,
    getDocumentFilesForJob,
    getUsersInfo,
    (
        jobInfoElement: JobInfoElement | undefined,
        thumbFiles: CaptureFile[],
        docFiles: ExtendedJobFile[],
        users: DictionaryOf<User>,
    ): Share | undefined => {
        // If job info is not fetched; the album is not ready to be composed
        if (
            jobInfoElement === undefined ||
            jobInfoElement.jobInfo === undefined
        ) {
            return
        }

        const jobInfo = jobInfoElement.jobInfo
        const owner = users[jobInfo.owner] || unknownUser
        const thumbs = [...thumbFiles].reverse()

        return {
            id: jobInfoElement.jobID,
            owner,
            thumbFiles: thumbs,
            docFiles,
        }
    },
)

export const getSingleFileShare = createSelector(
    getShare,
    (
        _: StateWithJobInfo & StateWithFiles & StateWithUsers,
        __: JobID,
        fileID: FileID,
    ) => fileID,
    (share, fileID): Share | undefined =>
        share && {
            ...share,
            thumbFiles: share.thumbFiles.filter((f) => f.fileID === fileID),
        },
)

const bigThumbSize = {
    elementWidth: 296,
    elementHeight: 296,
    elementSpaceAround: 2,
}
const mediumThumbSize = {
    elementWidth: 128,
    elementHeight: 128,
    elementSpaceAround: 2,
}
const smallThumbSize = {
    elementWidth: 88,
    elementHeight: 88,
    elementSpaceAround: 2,
}

export const calculateShareGridStyle = (
    share: Share | undefined,
    isMobile: boolean,
    viewportWidth: number,
): GridStyle => {
    let style = null
    const shareFileNumber = share ? share.thumbFiles.length : 0
    switch (shareFileNumber) {
        case 1:
            style = bigThumbSize
            break
        case 2:
            style = mediumThumbSize
            break
        default:
            style = isMobile ? smallThumbSize : mediumThumbSize
    }
    const totalElementWidth = style.elementWidth + style.elementSpaceAround * 2
    const maxWidth = totalElementWidth * Math.min(shareFileNumber, 5)

    return {
        ...style,
        width: computeGridContainerWidth(
            viewportWidth,
            maxWidth,
            style.elementWidth,
            style.elementSpaceAround,
        ),
    }
}

export const getShareGridStyle = createSelector(
    getShare,
    isMobileMode,
    getViewportWidth,
    calculateShareGridStyle,
)

export const getLastViewOffset = createSelector(
    getCaptureFilesForJob,
    getShareGridStyle,
    getLastSeenElementForJob,
    getViewportHeight,
    (files, shareGridStyle, lastSeenElement, vpHeight): number | undefined => {
        if (lastSeenElement) {
            const fileIndex = [...files]
                .reverse()
                .map((f) => f.fileID)
                .indexOf(lastSeenElement)
            const offset =
                Math.floor(fileIndex / calcImagesPerRow(shareGridStyle)) *
                shareGridStyle.elementWidth

            return offset > vpHeight ? offset : undefined
        }
    },
)
