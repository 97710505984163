import { type Reducer, combineReducers } from '@reduxjs/toolkit'
import { albumCreationReducerMapObj } from './album/reducer'
import { albumListReducerMapObj } from './albumList/reducer'
import { albumOptionsReducerMapObj } from './albumOptions/reducer'
import { carouselViewerReducerMapObj } from './carouselViewer/reducer'
import { commentsReducerMapObj } from './comments/reducer'
import { isType } from './common/actions'
import { contextMenuReducerMapObj } from './contextMenu/reducer'
import { currentUserReducerMapObj } from './currentUser/reducer'
import { downloaderReducerMapObj } from './downloader/reducer'
import { fileMetadataReducerMapObj } from './fileMetadata/reducer'
import { filesReducerMapObj } from './files/reducer'
import { filesOptionsReducerMapObj } from './FilesOptions/reducer'
import { hostsReducerMapObj } from './hosts/reducer'
import { jobInfoReducerMapObj } from './jobInfo/reducer'
import { lastSeenElementReducerMapObj } from './lastSeenElement/reducer'
import { popupReducerMapObj } from './popup/reducer'
import { reactionReducerMapObj } from './reaction/reducer'
import { selectedFilesReducerMapObj } from './selectedFiles/reducer'
import { statusNotificationsReducerMapObj } from './statusNotifications/reducer'
import { storagePlanReducerMapObj } from './storagePlan/reducer'
import { subscribersReducerMapObj } from './subscribers/reducer'
import { timelineReducerMapObj } from './timeline/reducers'
import { trashReducerMapObj } from './trash/reducer'
import { uploaderReducerMapObj } from './uploader/reducer'
import { userActionsReducerMapObj } from './userActions/reducer'
import { usersReducerMapObj } from './users/reducer'
import { viewModeReducerMapObj } from './viewMode/reducer'
import { accountAttributeReducerMapObj } from './currentUser/accountAttributesSlice'
import { UserLoggedOut } from './currentUser/actions'
import { cursorReducerMapObj } from './cursor/cursorSlice'
import { modalManagerReducerMapObj } from './modalManager/modalManagerSlice'
import { recentFilesReducerMapObj } from './recentFiles/recentFilesSlice'
import { resumeReducerMapObj } from './resume/resumeSlice'

const _mainReducer = () =>
    combineReducers({
        ...accountAttributeReducerMapObj,
        ...albumCreationReducerMapObj,
        ...albumListReducerMapObj,
        ...albumOptionsReducerMapObj,
        ...carouselViewerReducerMapObj,
        ...commentsReducerMapObj,
        ...contextMenuReducerMapObj,
        ...currentUserReducerMapObj,
        ...cursorReducerMapObj,
        ...downloaderReducerMapObj,
        ...fileMetadataReducerMapObj,
        ...filesOptionsReducerMapObj,
        ...filesReducerMapObj,
        ...hostsReducerMapObj,
        ...jobInfoReducerMapObj,
        ...lastSeenElementReducerMapObj,
        ...modalManagerReducerMapObj,
        ...popupReducerMapObj,
        ...reactionReducerMapObj,
        ...recentFilesReducerMapObj,
        ...resumeReducerMapObj,
        ...selectedFilesReducerMapObj,
        ...statusNotificationsReducerMapObj,
        ...storagePlanReducerMapObj,
        ...subscribersReducerMapObj,
        ...timelineReducerMapObj,
        ...trashReducerMapObj,
        ...uploaderReducerMapObj,
        ...userActionsReducerMapObj,
        ...usersReducerMapObj,
        ...viewModeReducerMapObj,
    })

export const rootReducer = (): Reducer => {
    return (state = {}, action) => {
        if (isType(action, UserLoggedOut)) {
            // Reset state when logout occurs
            return _mainReducer()(undefined, action)
        }
        return _mainReducer()(state, action)
    }
}
