import { createActionCreator, createVoidActionCreator } from '../common/actions'

export enum ConfirmableSelectionAction {
    DELETE = 'DELETE',
    RESTORE = 'RESTORE',
    PERM_DELETE = 'PERM_DELETE',
    COPY_TO_TIMELINE = 'COPY_TO_TIMELINE',
}

export const FilesWereSelected = createActionCreator<FileID[]>(
    'FILES_WERE_SELECTED',
)
export const FilesWereDeselected = createActionCreator<FileID[]>(
    'FILES_WERE_DESELECTED',
)

export const SelectionActionInitiated =
    createActionCreator<ConfirmableSelectionAction>(
        'SELECTION_ACTION_INITIATED',
    )
export const SelectionActionConfirmHandled = createVoidActionCreator(
    'SELECTION_ACTION_CONFIRM_HANDLED',
)
