import { lightTheme } from '@capture/capture-components'
import { connectAnalyticsConsent } from '@capture/client/analytics/analyticsConsent'
import { analyticsMiddleware } from '@capture/client/analytics/analyticsMiddleware'
import { addTracker } from '@capture/client/analytics/eventTracking'
import { LeanplumTracker } from '@capture/client/analytics/trackers/LeanplumTracker'
import { connectServiceProvider } from '@capture/client/API/HostProvider'
import { overrideAccountFromSearchParams } from '@capture/client/API/login'
import { connectIntervalPoller } from '@capture/client/API/services/IntervalPoller'
import { TestFlagService } from '@capture/client/API/services/TestFlagService'
import { connectSyncers } from '@capture/client/API/syncers'
import '@capture/client/assets/globalStyle.scss'
import { cacheLocaleStrings } from '@capture/client/assets/localization/util'
import { SuggestModernBrowser } from '@capture/client/components/Info/SuggestModernBrowser'
import {
    LEANPLUM_API_KEY,
    LEANPLUM_APP_ID,
    getCurrentLocale,
} from '@capture/client/config/constants'
import { makeSentryMiddleware } from '@capture/client/errorReporting/sentryMiddleware'
import { makeStore } from '@capture/client/state'
import { albumMiddleware } from '@capture/client/state/album/autoAlbumMiddleware'
import { carouselViewMiddleware } from '@capture/client/state/carouselViewer/carouselMiddleware'
import { connectUserGuarantee } from '@capture/client/state/currentUser/UserExistenceGuarantee'
import { changesSyncerMiddleware } from '@capture/client/state/job/changesSyncerMiddleware'
import { popupMiddleware } from '@capture/client/state/popup/popupMiddleware'
import { addResumeListeners } from '@capture/client/state/resume/resumeSlice'
import { trashMiddleware } from '@capture/client/state/trash/trashMiddleware'
import { connectUploader } from '@capture/client/state/uploader/uploadQueue'
import { isModernBrowser } from '@capture/client/utilities/device'
import { setDocumentTitle } from '@capture/client/utilities/documentTitle'
import { consoleLog } from '@capture/client/utilities/logging'
import {
    type TypedStartListening,
    createListenerMiddleware,
} from '@reduxjs/toolkit'
import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { addModalListeners } from '@capture/client/state/modalManager/modalManagerSlice'
import { SENTRY_DSN__FULL_WEB } from './config/constants'
import { MainRouter } from './routing/MainRouter'

setDocumentTitle() // Setting the default-title to have the localized version set by default

const locale = getCurrentLocale()
cacheLocaleStrings(locale)

addTracker(new LeanplumTracker(LEANPLUM_APP_ID, LEANPLUM_API_KEY))

const container = document.getElementById('app')
const root = createRoot(container!)

if (!isModernBrowser()) {
    root.render(
        <React.StrictMode>
            <SuggestModernBrowser />
        </React.StrictMode>,
    )
}

// convert eventual params to test flags
TestFlagService.paramsToFlags()
overrideAccountFromSearchParams()

const listenerMiddleware = createListenerMiddleware()
export const startAppListening =
    listenerMiddleware.startListening as TypedStartListening<
        RootState,
        AppDispatch
    >

// register the resume listeners
addResumeListeners(startAppListening)
addModalListeners(startAppListening)

const store = makeStore(
    [
        makeSentryMiddleware({ dsn: SENTRY_DSN__FULL_WEB }),
        analyticsMiddleware,
        changesSyncerMiddleware,
        popupMiddleware,
        carouselViewMiddleware,
        albumMiddleware,
        trashMiddleware,
    ],
    [
        connectUploader,
        connectServiceProvider,
        connectSyncers,
        connectUserGuarantee,
        connectIntervalPoller,
        connectAnalyticsConsent,
    ],
    listenerMiddleware,
)

consoleLog(`Launching CaptureWeb version: ${import.meta.env.VITE_VERSION}`)
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={lightTheme}>
                <MainRouter />
            </ThemeProvider>
        </Provider>
    </React.StrictMode>,
)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
