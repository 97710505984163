import { getStoredServiceDict } from '@capture/client/API/externals'
import { zIndex } from '@capture/client/assets/styleConstants'
import { TestFlagService } from '@capture/client/API/services/TestFlagService'
import styled from 'styled-components'

const Marker = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: ${zIndex.sudo};
    width: 2rem;
    height: 2rem;
    clip-path: polygon(0 0, 100% 0, 0 100%);
    pointer-events: none;
`

const MarkerWithFlags = styled(Marker)`
    background-color: red;
`

const MarkerEnvironment = styled(Marker)`
    background-color: gold;
`

const MarkerAll = styled(Marker)`
    background: linear-gradient(to right, red, gold);
`

export const QAPlacement = () => {
    const calledBackend = getStoredServiceDict()?.appHost
    const isBackendStaging = calledBackend?.includes('staging')

    const hasActiveFlags = TestFlagService.hasActiveFlags()

    if (hasActiveFlags && isBackendStaging) {
        return <MarkerAll id="qa-placement" />
    }

    if (hasActiveFlags) {
        return <MarkerWithFlags id="qa-placement" />
    }

    if (isBackendStaging) {
        return <MarkerEnvironment id="qa-placement" />
    }

    return null
}
