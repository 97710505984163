import * as React from 'react'
import styled, { keyframes } from 'styled-components'

const Container = styled.div`
    position: relative;
`

type RippleProps = {
    top: number
    left: number
    width: number
    height: number
    color: string
    baseOpacity: number
    scale: number
    animationDuration: number
}
const RippleWrapper = styled.svg`
    width: ${(props: RippleProps) => props.width * props.scale}px;
    height: ${(props) => props.height * props.scale}px;
    position: absolute;
    display: block;
    top: ${(props) => props.top}px;
    left: ${(props) => props.left}px;
`

const rippleAnimation = (scale: number, baseOpacity: number) => keyframes`
    0% {
        transform: scale(1);
        transform-origin: center;
        opacity: ${baseOpacity};
    }
    100% {
        transform: scale(${scale});
        transform-origin: center;
        opacity: 0.0;
    }
`
const createRipple = (props: RippleProps): React.ReactNode => {
    const rippleEffect = rippleAnimation(props.scale, props.baseOpacity)
    const AnimatedCircle1 = styled.circle`
        animation: ${rippleEffect} ${props.animationDuration}s linear infinite;
    `
    const AnimatedCircle2 = styled(AnimatedCircle1)`
        animation-delay: ${props.animationDuration / 2}s;
    `

    const circleProps = {
        r: props.width / 2,
        cx: props.width * (props.scale / 2),
        cy: props.height * (props.scale / 2),
        fill: props.color,
    }
    return (
        <RippleWrapper {...props}>
            <AnimatedCircle1 {...circleProps} />
            <AnimatedCircle2 {...circleProps} />
        </RippleWrapper>
    )
}

type Props = {
    illustration: React.ReactNode
    rippleSelector: string
    rippleColor: string
    baseOpacity: number
    scale: number
    animationDuration: number
}
export class RippleAnimationElement extends React.Component<Props> {
    private wrapperDiv: HTMLDivElement | null = null
    private rippleLayer: React.ReactNode | null = null
    private illustrationRect?: ClientRect

    public componentDidMount() {
        if (this.wrapperDiv) {
            this.illustrationRect = this.wrapperDiv.getBoundingClientRect()
            const dotSVG = this.wrapperDiv.querySelector(
                this.props.rippleSelector,
            )

            if (dotSVG !== null) {
                const dotRect = dotSVG.getBoundingClientRect()
                const diffTop =
                    dotRect.top -
                    this.illustrationRect.top -
                    (dotRect.height * (this.props.scale - 1)) / 2
                const diffLeft =
                    dotRect.left -
                    this.illustrationRect.left -
                    (dotRect.width * (this.props.scale - 1)) / 2
                this.makeRippleLayer({
                    top: diffTop,
                    left: diffLeft,
                    width: dotRect.width,
                    height: dotRect.height,
                    color: this.props.rippleColor,
                    baseOpacity: this.props.baseOpacity,
                    scale: this.props.scale,
                    animationDuration: this.props.animationDuration,
                })

                this.forceUpdate()
            }
        }
    }

    public makeRippleLayer(rippleProps: RippleProps) {
        this.rippleLayer = createRipple(rippleProps)
    }

    private binWrapperDiv = (el: HTMLDivElement | null) => {
        this.wrapperDiv = el
    }

    public render() {
        return (
            <Container>
                {this.rippleLayer}
                <div ref={this.binWrapperDiv}>{this.props.illustration}</div>
            </Container>
        )
    }
}
