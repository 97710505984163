import * as React from 'react'
import styled from 'styled-components'
import { isMobileDevice } from '~/utilities/device'

type Props = {
    blockScrolling?: boolean // If set tot true, all touch-events will block, disabling scrolling etc.
    threshold?: number // Pixel-length of the drag before recognizing it as a swipe. Defaults to 20
    onPinching?: () => void // nice to have: pass delta of distance between fingers for event streams?
    onSpreading?: () => void
    children?: React.ReactNode
}

type Direction = 'pinch' | 'spread'
const Wrapper = styled.div`
    width: 100%;
    height: 100%;

    -ms-touch-action: pan-x pan-y;
    touch-action: pan-x pan-y;
`

type PagePoint = { pageX: number; pageY: number }
const getDistanceBetweenPoints = (point1: PagePoint, point2: PagePoint) => {
    const dx = point1.pageX - point2.pageX
    const dy = point1.pageY - point2.pageY
    return Math.sqrt(dx * dx + dy * dy) //  Math.hypot is unsupported in IE
}

export class PinchRecognizer extends React.Component<Props> {
    private wrapper = React.createRef<HTMLDivElement>()
    private initialDistance = -1
    private events: PointerEvent[] = []

    private handleGesture = (points: [PagePoint, PagePoint]) => {
        const dist = getDistanceBetweenPoints(points[0], points[1])
        if (this.initialDistance === -1) {
            // stop consecutive gesture from automatically getting a direction
            this.initialDistance = dist
            return
        }

        if (
            Math.abs(this.initialDistance - dist) >=
            (this.props.threshold || 20)
        ) {
            this.dealWithPinch(dist > this.initialDistance ? 'spread' : 'pinch')
            this.initialDistance = dist
        }
    }

    private dealWithPinch(dir: Direction) {
        switch (dir) {
            case 'pinch':
                if (this.props.onPinching) {
                    this.props.onPinching()
                }
                break
            case 'spread':
                if (this.props.onSpreading) {
                    this.props.onSpreading()
                }
                break
        }
    }

    private onPointerDown = (e: PointerEvent) => {
        this.events.push(e)
    }
    private onPointerMove = (e: PointerEvent) => {
        if (this.props.blockScrolling) {
            e.preventDefault()
        }

        // update last known event
        this.events.forEach((ev, i) => {
            if (ev.pointerId === e.pointerId) {
                this.events[i] = e
            }
        })

        if (this.events.length === 2) {
            this.handleGesture([this.events[0], this.events[1]])
        }
    }
    private onPointerUp = (ev: PointerEvent) => {
        this.events = this.events.filter((e) => e.pointerId !== ev.pointerId)
        if (this.events.length < 2) {
            this.initialDistance = -1
        }
    }

    private onTouchMove = (e: TouchEvent) => {
        if (this.props.blockScrolling) {
            e.preventDefault()
        }

        if (e.touches.length === 2) {
            this.handleGesture([e.touches[0], e.touches[1]])
        }
    }
    private onTouchEnd = (_e: TouchEvent) => {
        this.initialDistance = -1
    }

    public componentDidMount() {
        if (this.wrapper.current) {
            if (isMobileDevice.Windows()) {
                this.wrapper.current.addEventListener(
                    'pointerdown',
                    this.onPointerDown,
                )
                this.wrapper.current.addEventListener(
                    'pointermove',
                    this.onPointerMove,
                )
                this.wrapper.current.addEventListener(
                    'pointerup',
                    this.onPointerUp,
                )
            } else {
                this.wrapper.current.addEventListener(
                    'touchmove',
                    this.onTouchMove,
                )
                this.wrapper.current.addEventListener(
                    'touchend',
                    this.onTouchEnd,
                )
            }
        }
    }

    public componentWillUnmount() {
        if (this.wrapper.current) {
            if (isMobileDevice.Windows()) {
                this.wrapper.current.removeEventListener(
                    'pointerdown',
                    this.onPointerDown,
                )
                this.wrapper.current.removeEventListener(
                    'pointermove',
                    this.onPointerMove,
                )
                this.wrapper.current.removeEventListener(
                    'pointerup',
                    this.onPointerUp,
                )
            } else {
                this.wrapper.current.removeEventListener(
                    'touchmove',
                    this.onTouchMove,
                )
                this.wrapper.current.removeEventListener(
                    'touchend',
                    this.onTouchEnd,
                )
            }
        }
    }

    public render() {
        return <Wrapper ref={this.wrapper}>{this.props.children}</Wrapper>
    }
}
