import * as React from 'react'
import styled from 'styled-components'
import { _ } from '~/assets/localization/util'
import { fontSize } from '~/assets/styleConstants'

const colors = {
    telenorTextBlack: '#1a1a1a',
    telenorDarkGray: '#767676',
    telenorDarkGrayRGB: '118, 118, 118',
    bgColor: '#e6eef4',
    bgColorDarkerShade: '#d7e5ef',
    telenorYellow: '#fdf192',
    telenorRed: '#ffb4b4',
    telenorRedRGB: '255, 180, 180',
    telenorDarkRed: '#f55b5b',
    warningBgColor: '#f3c7cd',
    telenorGreen: '#b0f0b2',
    telenorBlue: '#007ad0',
}

const RowWrapper = styled.div`
    position: relative;
`
const animateLabel = `
    transform: translate3d(0, -12px, 0);
    font-size: ${fontSize.small_14};
`
const FieldLabel = styled.label`
    position: absolute;
    font-size: ${fontSize.medium_16};
    color: ${colors.telenorDarkGray};
    left: 16px;
    top: 20px;
    transition: all 0.2s;

    ${(props: { doTranslate: boolean }) =>
        props.doTranslate ? animateLabel : ''}
    input:-webkit-autofill + & {
        ${animateLabel}
    }
`

type InputFieldProps = { hasError: boolean; compOpacity: number }
const InputField = styled.input`
    height: 60px;
    font-size: ${fontSize.medium_16};
    background-color: ${(props: InputFieldProps) =>
        props.hasError ? 'rgba(' + colors.telenorRedRGB + ', 0.2)' : '#fff'};
    vertical-align: middle;
    outline: none;
    border-style: none;
    box-sizing: border-box;
    padding: 24px 16px 4px 16px;
    width: 100%;
    opacity: ${(props) => props.compOpacity};

    &:focus {
        background-color: #fff;
    }
`

const ErrorMessage = styled.div`
    position: absolute;
    right: 16px;
    top: 8px;
    color: ${colors.telenorDarkRed};
    font-size: ${fontSize.small_14};
    font-weight: bold;
    text-align: right;
`

export type FormField = {
    value: string
    isValid?: boolean
    errorMsg?: string
}

export type RowProps = {
    header: string
    onChange: (newVal: string) => void
    errorMsg?: string
    showError: boolean
    maxLength?: number
    type?: string
    name: string
    autoComplete: string
    preFilledValue?: string
    isDisabled?: boolean
    cyKey?: string
}

export class FormRow extends React.Component<
    RowProps,
    { isFocused: boolean; currentValue: string }
> {
    public state = {
        isFocused: false,
        currentValue: this.props.preFilledValue || '',
    }
    private inputField = React.createRef<HTMLInputElement>()
    private handleBlur: React.FormEventHandler<HTMLInputElement> = (evt) => {
        const trimmedValue = evt.currentTarget.value.trim() // some browsers do not trim automatically on blur
        this.props.onChange(trimmedValue)
        this.setState({ isFocused: false, currentValue: trimmedValue })
    }
    private handleFocus: React.FormEventHandler<HTMLInputElement> = (_evt) => {
        this.setState({ isFocused: true })
    }
    private handleChange: React.FormEventHandler<HTMLInputElement> = (evt) => {
        this.props.onChange(evt.currentTarget.value)
        this.setState({ currentValue: evt.currentTarget.value })
    }
    public render() {
        const errorMsg = (
            <ErrorMessage>
                {this.state.currentValue.length > 0
                    ? this.props.errorMsg || _('invalid_field')
                    : _('mandatory_field')}
            </ErrorMessage>
        )
        const shouldAnimateLabel =
            this.state.isFocused || this.state.currentValue.length > 0

        return (
            <RowWrapper>
                <InputField
                    ref={this.inputField}
                    id={`chk${this.props.name}`}
                    type={this.props.type || 'text'}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    maxLength={this.props.maxLength}
                    autoComplete={this.props.autoComplete}
                    value={this.state.currentValue}
                    disabled={this.props.isDisabled}
                    compOpacity={this.props.isDisabled ? 0.5 : 1}
                    hasError={this.props.showError}
                    data-cy={this.props.cyKey}
                />
                <FieldLabel
                    htmlFor={`chk${this.props.name}`}
                    doTranslate={shouldAnimateLabel}>
                    {this.props.header}
                </FieldLabel>
                {this.props.showError && !this.state.isFocused && errorMsg}
            </RowWrapper>
        )
    }
}
