import * as React from 'react'
import styled from 'styled-components'
import { colors, fontSize } from '~/assets/styleConstants'
import { FullscreenOverlay } from '../Common/FullscreenOverlay'

const Wrapper = styled.div`
    width: 70%;
    max-width: 569px;
    background: white;
    padding: 54px 44px 24px;
    box-sizing: border-box;
    border-radius: 3px;
`

const InfoContent = styled.div`
    display: flex;
    margin-bottom: 16px;
`
const LeftSection = styled.div`
    margin-right: 32px;
`
const RightSection = styled.div`
    flex: 1;
`
const Header = styled.div`
    font-size: ${fontSize.xLarge_32};
    color: ${colors.captureBlue};
    margin-bottom: 8px;
`
const TextContent = styled.div`
    font-size: ${fontSize.small_14};
`
const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`
const ButtonDiv = styled.div`
    margin: 8px;
    width: 240px;
`
const Illustration = styled.img`
    object-fit: contain;
`

export type ElementOrImgSrc = React.ReactNode | { src: string }
export type PopupProps = {
    headerText: string
    mainText: string
    illustration: ElementOrImgSrc
    buttons: React.ReactNode[]
}

const isImgSrc = (prop: ElementOrImgSrc): prop is { src: string } =>
    Object.hasOwnProperty.call(prop, 'src')

export const getIllustrationFromSource = (illustration: ElementOrImgSrc) => {
    if (isImgSrc(illustration)) {
        return <Illustration src={illustration.src} alt={'illustration'} />
    }
    return illustration
}

export class PopupHorizontalLayout extends React.Component<PopupProps> {
    public render() {
        const buttons = this.props.buttons.map((button: React.ReactNode, i) => (
            <ButtonDiv key={`popupButton${i}`}>{button}</ButtonDiv>
        ))
        return (
            <FullscreenOverlay>
                <Wrapper>
                    <InfoContent>
                        <LeftSection>
                            {getIllustrationFromSource(this.props.illustration)}
                        </LeftSection>
                        <RightSection>
                            <Header>{this.props.headerText}</Header>
                            <TextContent>{this.props.mainText}</TextContent>
                        </RightSection>
                    </InfoContent>
                    <ButtonWrapper>{buttons}</ButtonWrapper>
                </Wrapper>
            </FullscreenOverlay>
        )
    }
}
