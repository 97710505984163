import type { SVGProps } from 'react'
const SvgList = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill={props.color ?? 'currentColor'}
            d="M8 9a.968.968 0 0 1-.713-.287A.968.968 0 0 1 7 8c0-.283.096-.52.287-.713A.968.968 0 0 1 8 7h12c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0 1 20 9zm0 4a.967.967 0 0 1-.713-.287A.968.968 0 0 1 7 12c0-.283.096-.52.287-.713A.967.967 0 0 1 8 11h12c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0 1 20 13zm0 4a.967.967 0 0 1-.713-.288A.968.968 0 0 1 7 16c0-.283.096-.52.287-.713A.967.967 0 0 1 8 15h12c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.968.968 0 0 1 20 17zM4 9a.968.968 0 0 1-.712-.287A.968.968 0 0 1 3 8c0-.283.096-.52.288-.713A.968.968 0 0 1 4 7c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.713A.968.968 0 0 1 4 9m0 4a.967.967 0 0 1-.712-.287A.968.968 0 0 1 3 12c0-.283.096-.52.288-.713A.967.967 0 0 1 4 11c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.713A.967.967 0 0 1 4 13m0 4a.967.967 0 0 1-.712-.288A.968.968 0 0 1 3 16c0-.283.096-.52.288-.713A.967.967 0 0 1 4 15c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.712A.967.967 0 0 1 4 17"
        />
    </svg>
)
export default SvgList
