import styled, { keyframes } from 'styled-components'

const rotate360 = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`
const SpinnerWrapper = styled.div`
    width: ${(props: { size: number }) => props.size}px;
    height: ${(props) => props.size}px;
    animation: ${rotate360} 1s infinite linear;
    margin: 0 auto;
`

const SpinnerBackground = styled.div`
    position: absolute;
    background: rgba(255, 255, 255, 0.6);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
`

export const SpinnerComponent: React.FunctionComponent<{ size: number }> = (
    props,
) => {
    return (
        <SpinnerWrapper size={props.size}>
            <svg
                width={props.size}
                height={props.size}
                viewBox="0 0 32 32"
                version="1.1">
                <defs>
                    <linearGradient
                        x1="50%"
                        y1="0%"
                        x2="50%"
                        y2="100%"
                        id="spinner-gradient">
                        <stop stopColor="#86D6C6" offset="0%"></stop>
                        <stop stopColor="#0091D2" offset="100%"></stop>
                    </linearGradient>
                </defs>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <mask id="spinner-mask" fill="white">
                        <path d="M16,15.2411295 L0,15.2411295 L0,32 L24,32 L32,32 L32,1.77635684e-15 L16,-1.16279548e-15 L16,15.2411295 Z"></path>
                    </mask>
                    <path
                        d="M16,29 C23.1797017,29 29,23.1797017 29,16 C29,8.82029825 23.1797017,3 16,3 C8.82029825,3 3,8.82029825 3,16 C3,23.1797017 8.82029825,29 16,29 Z"
                        stroke="url(#spinner-gradient)"
                        strokeWidth="5"
                        mask="url(#spinner-mask)"></path>
                </g>
            </svg>
        </SpinnerWrapper>
    )
}

export const ContainerFillingSpinnerOverlay: React.FunctionComponent<{
    spinnerSize: number
}> = (props) => {
    return (
        <SpinnerBackground>
            <SpinnerComponent size={props.spinnerSize} />
        </SpinnerBackground>
    )
}
