import styled from 'styled-components'
import { _ } from '~/assets/localization/util'
import { colors, zIndex } from '~/assets/styleConstants'
import { stopPropagation } from '~/utilities/eventHandling'

const PositionedWrapper = styled.div`
    position: absolute;
    bottom: -14px;
    right: 50%;
    transform: translate(
        ${(p: { adjustment: number }) => 100 - p.adjustment}%,
        100%
    );
    z-index: ${zIndex.hint};

    color: white;
    background: ${colors.captureBlue};
    max-width: 360px;
    font-size: 13px;
    padding: 12px;
    border-radius: 4px;
    cursor: default;

    &::before {
        content: '';
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 12px solid ${colors.captureBlue};
        position: absolute;
        transform: translate(-50%, -100%);
        top: 0;
        left: ${(p: { adjustment: number }) => p.adjustment}%;
        z-index: ${zIndex.hint};
    }
    & > div {
        white-space: nowrap;
    }
`

const ButtonsWrapper = styled.div`
    border-top: 1px solid rgba(255, 255, 255, 0.6);
    margin-top: 10px;
    padding-top: 10px;
    text-align: right;

    & > span {
        cursor: pointer;
        padding: 8px;
        margin: -8px;
    }
`

type Props = {
    onGotIt: () => void
    placement?: number // From 0 (fully left) to 100 (fully right) - default: 50 (center)
}
export const ShiftSelectionHint: React.FC<Props> = ({ onGotIt, placement }) => (
    <PositionedWrapper
        adjustment={placement || 50}
        onClick={stopPropagation}
        role="presentation">
        <div>{_('shift_select_hint_body')}</div>
        <ButtonsWrapper
            onClick={onGotIt}
            onKeyUp={onGotIt}
            role="button"
            tabIndex={0}>
            <span>
                {_('ok')}, {_('got_it').toLowerCase()}
            </span>
        </ButtonsWrapper>
    </PositionedWrapper>
)
