import { _, getLinkParts } from '@capture/client/assets/localization/util'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
    HOME_SITE_ADDRESS,
    PRODUCT_NAME,
    isMinSky,
} from '@capture/client/config/constants'
import { doLogin } from '@capture/client/API/login'
import {
    trackEvent,
    trackEventInternal,
} from '@capture/client/analytics/eventTracking'
import { colors, fontSize } from '@capture/client/assets/styleConstants'
import { PageEventTrigger } from '@capture/client/components/Analytics/PageEventTrigger'
import { TextButton } from '@capture/client/components/Common/IconTextButton'
import { PageWrapper } from '@capture/client/components/Common/PageWrapper'
import { StatusNotificationPlacement } from '@capture/client/components/Common/StatusNotificationPlacement'
import { EmbeddedLink } from '@capture/client/components/Common/StyledComponents'
import { TwoAreasTopNavBar } from '@capture/client/components/Navigation/TwoAreasTopNavBar'
import * as Pages from '@capture/client/routing/pages'
import { isLoggedIn } from '@capture/client/state/currentUser/selectors'
import SvgInfo from '@capture/capture-components/src/icons/Info'
import { CaptureLogoHorizontal } from '@capture/client/components/Icons/CaptureLogoHorizontal'
import { useAppSelector } from '../../hooks'

const LogoWrapper = styled.span`
    cursor: pointer;
`

const LoginBtn = styled.div`
    text-transform: uppercase;
    color: ${colors.captureGrey500};
    font-weight: bold;
`

type OwnProps = {
    context: string
    children?: React.ReactNode
}

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 48px;
`

const InfoText = styled.div`
    font-size: ${fontSize.small_12};
    max-width: 584px;
    text-align: left;
    margin: 32px;
`
const SubText = styled.div`
    color: ${colors.captureGrey500};
    font-size: ${fontSize.xSmall_10};
    white-space: pre-line;
`

const DeleteShareInfoArea = styled.div`
    display: flex;
    flex-direction: row;
    width: 256px;
    margin-top: 24px;
    font-size: ${fontSize.small_12};
`

export const SharePageOutline: React.FunctionComponent<OwnProps> = (props) => {
    const navigate = useNavigate()

    const onLogoClickLoggedIn = () => {
        trackEvent(props.context, 'CaptureLogo_clicked', 'goToTimeline')
        trackEventInternal(
            `${
                props.context ? props.context.toLocaleLowerCase() + '_' : ''
            }capturelogo_click`,
        )
        navigate(Pages.Timeline.url)
    }

    const onLogoClickNotLoggedIn = () => {
        trackEvent(props.context, 'CaptureLogo_clicked', 'goToOpenWeb')
        window.location.href = HOME_SITE_ADDRESS
    }
    const login = () => doLogin('share_receiver')

    const loggedIn = useAppSelector(isLoggedIn)

    const captureLogo = (
        <LogoWrapper
            onClick={loggedIn ? onLogoClickLoggedIn : onLogoClickNotLoggedIn}
            onKeyUp={loggedIn ? onLogoClickLoggedIn : onLogoClickNotLoggedIn}
            role="button"
            tabIndex={0}>
            <CaptureLogoHorizontal />
        </LogoWrapper>
    )

    const loginBtn = loggedIn ? null : (
        <LoginBtn>
            <TextButton onClick={login} text={_('login')} />
        </LoginBtn>
    )

    const captureTextPart1 = _('share__service_info_part1__format').replace(
        '%product_name%',
        PRODUCT_NAME,
    )

    const captureTextPart2 = (
        isMinSky
            ? _('share__service_info_part2__minSky__format')
            : _('share__service_info_part2__format')
    )
        .split('%product_name%')
        .join(PRODUCT_NAME)
        .replace('%storage_amount%', '2 GB')

    const captureTextSmall = _('share__service_info_footer__format')
        .split('%product_name%')
        .join(PRODUCT_NAME)
        .split('%break%')
        .join('\n')

    const [beforeLinkText, linkInnerText, afterLinkText] = getLinkParts(
        _('share_login_to_stop_sharing'),
    )

    return (
        <PageEventTrigger context="SharePage" whenScrolledDown="didScrollDown">
            <PageWrapper
                navBar={
                    <TwoAreasTopNavBar
                        left={captureLogo}
                        right={loginBtn}
                        hideUserAvi={true}
                    />
                }>
                <ContentWrapper>
                    {props.children}
                    {!isLoggedIn && (
                        <DeleteShareInfoArea>
                            <SvgInfo width={32} height={32} />
                            <div style={{ padding: 6 }}>
                                <b>{_('share_stop_sharing')}</b>
                                <br />
                                {beforeLinkText}
                                <EmbeddedLink onClick={login}>
                                    {linkInnerText}
                                </EmbeddedLink>
                                {afterLinkText}
                            </div>
                        </DeleteShareInfoArea>
                    )}
                    <InfoText>
                        <p>{captureTextPart1}</p>
                        <p>
                            {captureTextPart2}
                            {isMinSky ? '' : '*'}
                        </p>
                        {!isMinSky && <SubText>*{captureTextSmall}</SubText>}
                    </InfoText>
                </ContentWrapper>
                <StatusNotificationPlacement />
            </PageWrapper>
        </PageEventTrigger>
    )
}
