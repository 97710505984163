import { getCurrentLangDefinition, getDeviceProps } from '~/config/constants'
import { parseSearchString } from '~/utilities/urlParsing'
import { sessionStorageGet, sessionStorageSet } from '~/utilities/webStorage'
import {
    BrowserFetchObject,
    HostUrl,
    SHA256,
    base64URLEncode,
    generatePkce,
    getQueryString,
} from '../toolbox'
import { TestFlagService } from './TestFlagService'

export type ServiceDictResponse = {
    'thumb-host': string
    'app-host': string
    'video-host': string
    'poll-host': string
    'search-host': string
    'download-host': string
}

type OAuthCallbackData = {
    callbackURL: string
    codeVerifier: string
}

export class LoginService {
    public hostUrl: HostUrl

    constructor(hostname: string) {
        this.hostUrl = new HostUrl(hostname, {
            key: import.meta.env.VITE_API_KEY,
            client_v: import.meta.env.VITE_VERSION,
        })
    }

    private _makeCallbackData = (): OAuthCallbackData => {
        const existingCallbackData = sessionStorageGet('callbackData')

        if (existingCallbackData) {
            return JSON.parse(existingCallbackData)
        }

        // Generate the PKCE
        const pkce = generatePkce()

        // Save the path and the PKCE for the postflight request
        const callbackPath = this.hostUrl.getCleanPath(
            '/st/4/telenorid/postflight_oauth2callback',
        )

        const callbackData: OAuthCallbackData = {
            callbackURL: callbackPath,
            codeVerifier: pkce,
        }

        sessionStorageSet('callbackData', JSON.stringify(callbackData))

        return callbackData
    }

    public login = async (utmSource?: string) => {
        const callbackData = this._makeCallbackData()

        // Generate PKCE challenge
        const pkceChallenge = await SHA256(callbackData.codeVerifier)

        // Set the parameters for the preflight call
        const baseParams = {
            redir: window.location.host.split(':')[0],
            locale: getCurrentLangDefinition().connectKey,
            utm_source:
                utmSource ||
                parseSearchString(window.location.search).utm_source ||
                import.meta.env.VITE_ARTIFACT,
            code_challenge: base64URLEncode(pkceChallenge),
            key: import.meta.env.VITE_API_KEY,
            oauth_redirect_uri: `https://${window.location.host}/oauth2callback.html`,
            use_telenor_id_pluss: TestFlagService.isEnabled(
                'use-telenor-id-pluss',
            ),
        }
        const preflightPath = '/st/4/telenorid/preflight_oauth2login'

        const preflightParams = {
            ...baseParams,
            ...getDeviceProps(),
        }

        const preflightRequest = this.hostUrl.getPath(
            preflightPath,
            preflightParams,
        )

        // Make the preflight REST call to backend
        BrowserFetchObject.get(preflightRequest)
            .asJson()
            // Process the response from the backend and prepare the call for Telenor ID
            .then((response: any) => {
                const mainPath: string = response.authorization_url
                // Make the browser call to telenor ID
                window.location.href =
                    mainPath + getQueryString(response.authorization_params)
            })
            .catch((error) => console.log(error))
    }
}
