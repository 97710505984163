import type { EmptyObject, Middleware } from '@reduxjs/toolkit'
import {
    Album,
    AlbumCarousel,
    AlbumCarouselMatcher,
    AlbumNotLoggedIn,
    Share,
    Timeline,
    TimelineCarousel,
} from '~/routing/pages'
import { isCurrentLocationMatching } from '~/utilities/navigation'
import { isType } from '../common/actions'
import { isLoggedIn } from '../currentUser/selectors'
import { FileWasRemovedFromJob, FilesWereAddedToJob } from '../job/actions'
import { isTimelineJob } from '../jobInfo/selectors'
import { jobInfoReducerMapObj } from '../jobInfo/reducer'
import { timelineReducerMapObj } from '../timeline/reducers'
import { currentUserReducerMapObj } from '../currentUser/reducer'
import { filesReducerMapObj } from '../files/reducer'
import { hostsReducerMapObj } from '../hosts/reducer'
import { fileMetadataReducerMapObj } from '../fileMetadata/reducer'
import { commentsReducerMapObj } from '../comments/reducer'
import { usersReducerMapObj } from '../users/reducer'
import { reactionReducerMapObj } from '../reaction/reducer'
import { recentFilesReducerMapObj } from '../recentFiles/recentFilesSlice'
import { viewModeReducerMapObj } from '../viewMode/reducer'
import { ChangedCurrentCarouselFile } from './actions'
import { getCurrentViewInfo, isCarouselViewOpen } from './pureSelectors'
import { carouselViewerReducerMapObj } from './reducer'
import { getCurrentViewJobType, getCurrentViewerNode } from './selectors'

export const carouselViewMiddlewareReducerMapObj = {
    ...carouselViewerReducerMapObj,
    ...commentsReducerMapObj,
    ...currentUserReducerMapObj,
    ...fileMetadataReducerMapObj,
    ...filesReducerMapObj,
    ...hostsReducerMapObj,
    ...jobInfoReducerMapObj,
    ...reactionReducerMapObj,
    ...recentFilesReducerMapObj,
    ...timelineReducerMapObj,
    ...usersReducerMapObj,
    ...viewModeReducerMapObj,
}

type CarouselMiddlewareState = StateOfReducerMapObj<
    typeof carouselViewMiddlewareReducerMapObj
>

export const carouselViewMiddleware: Middleware<
    EmptyObject,
    CarouselMiddlewareState
> = (store) => (next) => (action) => {
    const state = store.getState()

    if (isType(action, FileWasRemovedFromJob) && isCarouselViewOpen(state)) {
        const currentViewerNode = getCurrentViewerNode(state)
        if (
            currentViewerNode &&
            currentViewerNode.file.fileID === action.payload.fileID
        ) {
            const goTo = currentViewerNode.next || currentViewerNode.prev
            if (goTo) {
                store.dispatch(
                    ChangedCurrentCarouselFile({
                        jobID: currentViewerNode.file.jobID,
                        fileID: goTo.fileID,
                    }),
                )
            } else {
                const currentJobType = getCurrentViewJobType(state)
                const currentView = getCurrentViewInfo(state)
                if (currentJobType && currentView) {
                    switch (currentJobType) {
                        case 'timeline':
                            location.assign(Timeline.url)
                            break
                        case 'share':
                            location.assign(Share(currentView.jobID).url)
                            break
                        case 'albumPrivate':
                            location.assign(Album(currentView.jobID).url)
                            break
                        case 'albumPublic': {
                            const albumPath = isLoggedIn(state)
                                ? Album
                                : AlbumNotLoggedIn
                            location.assign(albumPath(currentView.jobID).url)
                            break
                        }
                    }
                }
            }
        }
    }

    if (isType(action, FilesWereAddedToJob)) {
        // go back to deleted file after 'undo'
        const { prevView } = state.carouselViewer
        if (
            prevView &&
            action.payload.length === 1 &&
            prevView.fileID === action.payload[0].fileID
        ) {
            store.dispatch(ChangedCurrentCarouselFile(prevView))
        }
    }

    if (isType(action, ChangedCurrentCarouselFile)) {
        const { jobID, fileID } = action.payload
        if (
            isTimelineJob(state, jobID) &&
            isCurrentLocationMatching(TimelineCarousel('([^/]+)'))
        ) {
            window.history.replaceState(null, '', TimelineCarousel(fileID).url)
        } else if (
            isCurrentLocationMatching(AlbumCarouselMatcher('[^/]+', '[^/]+'))
        ) {
            window.history.replaceState(
                null,
                '',
                AlbumCarousel(jobID, fileID).url,
            )
        }
    }

    return next(action)
}
