import * as React from 'react'
import styled from 'styled-components'
import SvgDocument from '@capture/capture-components/src/icons/Document'
import { colors, fontSize } from '~/assets/styleConstants'
import { isMobileDevice } from '~/utilities/device'
import { SelectionIndicator } from './SelectionIndicator'
import type { ThreeColumns } from './ThreeColumnsRow'
import { ThreeColumnsRow } from './ThreeColumnsRow'

type ListRowProps = {
    isSelected: boolean
    isClickable: boolean
}
const ListRowWrapper = styled.div`
    border: 1px solid transparent;
    align-items: center;

    font-size: ${fontSize.small_14};
    background-color: #fff;
    ${(props: ListRowProps) =>
        props.isSelected ? `border: 1px solid ${colors.captureBlue}` : ''};
    margin-bottom: 8px;
    transition: border 0.3s ease-out;

    ${!isMobileDevice.any()
        ? `
        &:hover {
            background-color: ${colors.captureBlue50};
        }

        &:hover .item-select-indicator {
            visibility: visible;
        }
    `
        : ''}

    cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};
`

const FileInfoSection = styled.div`
    display: inline-block;
    max-width: 60%;
    overflow: hidden;
    text-overflow: ellipsis;
`

const ThumbWrapper = styled.div`
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-right: ${(props: { isMobile: boolean }) =>
        props.isMobile ? '16' : '50'}px;
`

const Image = styled.div`
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
`

type FileRowProps = {
    fileID: FileID
    fileName: string
    fileExtension?: string
    dateInfo: Date
    fileThumb?: string
    isMobile: boolean
    isSelected: boolean
    isInSelectMode: boolean
    doFileSelected: (fileID: FileID) => void
    doFileDeSelected: (fileID: FileID) => void
}

export class FileListEntry extends React.PureComponent<FileRowProps> {
    private handleSelect = (e: React.SyntheticEvent<HTMLDivElement>) => {
        e.stopPropagation()
        if (!this.props.isSelected) {
            this.props.doFileSelected(this.props.fileID)
        } else {
            this.props.doFileDeSelected(this.props.fileID)
        }
    }

    public render() {
        const thumb = this.props.fileThumb ? (
            <Image
                style={{ backgroundImage: `url(${this.props.fileThumb})` }}
            />
        ) : (
            <SvgDocument />
        )

        const columns: ThreeColumns = [
            <div key="entry-column-1">
                <ThumbWrapper isMobile={this.props.isMobile}>
                    {thumb}
                </ThumbWrapper>
                <FileInfoSection>{this.props.fileName}</FileInfoSection>
            </div>,
            <div key="entry-column-2">{this.props.fileExtension}</div>,
            <div key="entry-column-3">
                {!this.props.isMobile && (
                    <div>{this.props.dateInfo.toLocaleDateString()}</div>
                )}
                <SelectionIndicator
                    onClick={this.handleSelect}
                    isSelected={this.props.isSelected}
                    showPlaceholder={
                        this.props.isMobile || this.props.isInSelectMode
                    }
                    fillColor={colors.captureBlue}
                    borderColor={colors.captureGrey400}
                    checkmarkColor={'white'}
                />
            </div>,
        ]

        return (
            <ListRowWrapper
                isSelected={this.props.isSelected}
                isClickable={this.props.isInSelectMode || this.props.isMobile}>
                <ThreeColumnsRow
                    isMobile={this.props.isMobile}
                    columns={columns}
                />
            </ListRowWrapper>
        )
    }
}
