import type { SideModuleContentType } from './actions'
import type {
    CarouselViewInfo,
    CastingDetail,
    StateWithCarouselViewer,
} from './reducer'

// Selectors that only access the relevant substate (CarouselViewerState)

export const getCurrentViewInfo = (
    state: StateWithCarouselViewer,
): CarouselViewInfo | undefined => state.carouselViewer?.currentView
export const getPreviousViewInfo = (
    state: StateWithCarouselViewer,
): CarouselViewInfo | undefined => state.carouselViewer.prevView

export const isSideModuleExpanded = (state: StateWithCarouselViewer): boolean =>
    state.carouselViewer.currentSideModule !== undefined
export const getCurrentSideModuleContent = (
    state: StateWithCarouselViewer,
): SideModuleContentType | undefined => state.carouselViewer.currentSideModule
export const isCarouselViewOpen = (state: StateWithCarouselViewer): boolean =>
    state.carouselViewer.isCarouselOpen
export const isEmailFormOpen = (state: StateWithCarouselViewer): boolean =>
    state.carouselViewer.isEmailFormOpen
export const isFullscreenMode = (state: StateWithCarouselViewer): boolean =>
    state.carouselViewer.isInFullscreenMode

export const isCarouselCasting = (state: StateWithCarouselViewer): boolean =>
    state.carouselViewer.castingDetail !== undefined
export const getCastDetails = (
    state: StateWithCarouselViewer,
): CastingDetail | undefined => state.carouselViewer.castingDetail

export const isCarouselAutoplaying = (state: StateWithCarouselViewer) =>
    state.carouselViewer.shouldAutoNavigate
export const getVideoPlayStatus = (state: StateWithCarouselViewer) =>
    state.carouselViewer.videoPlayStatus
