import type { Store } from '@reduxjs/toolkit'
import { AccountAttributesStatus } from '~/state/currentUser/accountAttributesSlice'
import { getAccountAttributesStatus } from '~/state/currentUser/selectors'
import { fetchAccountAttributes } from '../currentUser'

class IntervalPoller {
    constructor(private store: Store) {
        this.runPolls()
    }
    // The callbacks passed here should:
    //  - catch their own errors
    //  - handle overlapping calls (in case of async callbacks longer than the interval)
    private polls: Array<{
        callback: () => void
        interval: number
    }> = [
        {
            callback: () => {
                const state = this.store.getState()

                if (state === undefined) {
                    console.warn('IntervalPoller: undefined state.')
                    return
                }

                const status = getAccountAttributesStatus(state)
                if (status !== AccountAttributesStatus.FETCHING) {
                    fetchAccountAttributes(this.store.dispatch)
                }
            },
            interval: 30000,
        },
    ]

    private intervals: Record<string, number> = {}

    private runPolls() {
        this.polls.forEach((poll, index) => {
            const intervalName = 'interval' + index
            if (this.intervals[intervalName]) {
                clearInterval(this.intervals[intervalName])
            }
            this.intervals[intervalName] = window.setInterval(
                poll.callback,
                poll.interval,
            )
        })
    }
}

let poller: IntervalPoller | undefined
export const connectIntervalPoller = (store: Store) => {
    if (poller === undefined) {
        poller = new IntervalPoller(store)
    }

    return poller
}
