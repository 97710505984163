let scrollOffsetWhenDisabled: number | undefined
export const disableBodyScroll = () => {
    scrollOffsetWhenDisabled = window.pageYOffset

    document.body.style.top = `${-scrollOffsetWhenDisabled}px`
    document.body.style.position = 'fixed'
    document.body.style.overflow = 'hidden'
    document.body.style.left = '0'
    document.body.style.right = '0'
}

export const enableBodyScroll = () => {
    document.body.style.position = 'relative'
    document.body.style.overflow = 'auto'
    document.body.style.top = '0'
    window.scroll(0, scrollOffsetWhenDisabled ?? 0)
}

export const isBodyScrollable = () => document.body.style.overflow !== 'hidden'
