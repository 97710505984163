import styled from 'styled-components'

import { TopNavPlaceholder } from '../Navigation/TopNavBar'
import { TelenorCaptureFooter } from './TelenorCaptureFooter'

type Props = {
    navBar?: React.ReactNode
    filtersBar?: React.ReactNode
    hideFooter?: boolean
    isContentReady?: boolean
    cyKey?: string
    children?: React.ReactNode
}
const Wrapper = styled.main`
    height: 100%;
    display: flex;
    flex-direction: column;
`
const Content = styled.section`
    display: flex;
    justify-content: center;
    flex: 1 0 auto;
`

export const PageWrapper: React.FunctionComponent<Props> = (props) => {
    return (
        <Wrapper data-cy={props.cyKey}>
            {props.isContentReady !== false && <TopNavPlaceholder />}
            {props?.filtersBar}
            <Content>{props.children}</Content>
            {props.hideFooter !== true && <TelenorCaptureFooter />}
            {props.navBar}
            <div id="upload-overlay-portal" />
        </Wrapper>
    )
}
