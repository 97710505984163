import { _, getStringByAmount } from '@capture/client/assets/localization/util'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { PRODUCT_NAME } from '@capture/client/config/constants'
import { copyShareToTimeline } from '@capture/client/API/job'
import {
    copyShareFilesToTimeline,
    deleteShare,
    downloadShare,
    flushShare,
} from '@capture/client/API/share'
import { colors, fontSize } from '@capture/client/assets/styleConstants'
import {
    ConfirmPromptOverlay,
    OkPromptOverlay,
} from '@capture/client/components/Common/DialoguePromptOverlay'
import {
    BorderedTextButton,
    FilledTextButton,
} from '@capture/client/components/Common/IconTextButton'
import { FadeInContainer } from '@capture/client/components/Common/StyledComponents'
import * as Pages from '@capture/client/routing/pages'
import {
    getCurrentUserUUID,
    getIsReadOnlyUser,
    isLoggedIn,
} from '@capture/client/state/currentUser/selectors'
import type { CaptureFile } from '@capture/client/state/files/selectors'
import { LastSeenElementCleared } from '@capture/client/state/lastSeenElement/actions'
import type { Share } from '@capture/client/state/share/selectors'
import {
    calculateShareGridStyle,
    getLastViewOffset,
} from '@capture/client/state/share/selectors'
import { getTimelineJobID } from '@capture/client/state/timeline/selectors'
import {
    getViewportWidth,
    isMobileMode,
} from '@capture/client/state/viewMode/selectors'
import { isMobileDevice } from '@capture/client/utilities/device'
import {
    localStorageGet,
    localStorageRemove,
} from '@capture/client/utilities/webStorage'
import {
    Resumable,
    requiredSubscription,
} from '@capture/client/state/resume/resumeSlice'
import { downloadSingleFile } from '@capture/client/API/download'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { DocSection } from './DocSection'
import { SharePageOutline } from './SharePageOutline'
import { ThumbSection } from './ThumbSection'

const HeaderText = styled.div`
    font-size: ${fontSize.small_14};
    text-align: center;
    margin: 0 16px;
`
export const ButtonWrapper = styled.div`
    width: 256px;
    margin: 4px auto;
    font-size: ${fontSize.small_14};
`

type SharePageProps = {
    share: Share
}

enum DeleteFlowStep {
    NOT_STARTED,
    SHOW_CONFIRM_DIALOG,
    SHOW_DELETED_DIALOG,
}

const makeGetLastViewOffsetSelecotr =
    (shareID: JobID) => (state: StateOfSelector<typeof getLastViewOffset>) =>
        getLastViewOffset(state, shareID)

export const ShareReceiverPage = (props: SharePageProps) => {
    const [deleteFlow, setDeleteFlow] = React.useState<DeleteFlowStep>(
        DeleteFlowStep.NOT_STARTED,
    )

    const isMobile = useAppSelector(isMobileMode)
    const viewportWidth = useAppSelector(getViewportWidth)
    const timelineJobID = useAppSelector(getTimelineJobID)
    const isUserLoggedIn = useAppSelector(isLoggedIn)
    const currentUserUUID = useAppSelector(getCurrentUserUUID)
    const lastViewOffset = useAppSelector(
        makeGetLastViewOffsetSelecotr(props.share.id),
    )
    const isReadOnlyUser = useAppSelector(getIsReadOnlyUser)

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const doDownloadFiles = () => downloadShare(dispatch, props.share.id)
    const doDownloadSingleFile = (fileID: FileID, path: string) =>
        downloadSingleFile(dispatch, {
            type: 'download',
            jobID: props.share.id,
            fileID,
            path,
            context: 'ShareReceiver',
        })
    const copyFilesToTimeline = React.useCallback(
        (files: CaptureFile[], timelineJobID: JobID) => {
            copyShareFilesToTimeline(dispatch, timelineJobID, files)
        },
        [dispatch],
    )
    const onStopSharing = () => deleteShare(dispatch, props.share.id)
    const doFlushCurrentShare = () => flushShare(dispatch, props.share.id)
    const doClearLastKnownPos = (jobID: JobID) =>
        dispatch(LastSeenElementCleared(jobID))

    const doEnterCarouselView = (fileID: FileID) =>
        navigate(Pages.AlbumCarousel(props.share.id, fileID).url)

    const goToTimeline = () => navigate(Pages.Timeline.url)

    const goToDownloadPage = () =>
        navigate(Pages.DownloadShareContent(props.share.id).url)
    const goToRequireLoginCopyShare = () =>
        navigate(Pages.CopyShareRequireLogin(props.share.id).url)

    const addFilesToCapture = () => {
        if (isUserLoggedIn && timelineJobID) {
            copyFilesToTimeline(props.share.thumbFiles, timelineJobID)
            goToTimeline()
        } else {
            goToRequireLoginCopyShare()
        }
    }

    const showConfirmStopSharingDialog = () =>
        setDeleteFlow(DeleteFlowStep.SHOW_CONFIRM_DIALOG)
    const cancelStopSharing = () => setDeleteFlow(DeleteFlowStep.NOT_STARTED)
    const stopSharing = () => {
        setDeleteFlow(DeleteFlowStep.SHOW_DELETED_DIALOG)
        onStopSharing()
    }

    const flushShareAndNavigateToTimeline = () => {
        doFlushCurrentShare()
        goToTimeline()
    }

    // resumes addShareFilesToCapture if necessary
    React.useEffect(() => {
        const shareToCopy = localStorageGet('addShareFilesToCapture')
        if (
            props.share &&
            props.share.thumbFiles.length &&
            shareToCopy === props.share.id &&
            isUserLoggedIn
        ) {
            if (isReadOnlyUser) {
                dispatch(
                    requiredSubscription({
                        type: Resumable.CopyShareToTimeline,
                        payload: shareToCopy,
                    }),
                )
            } else {
                copyShareToTimeline(dispatch, shareToCopy)
            }
            localStorageRemove('addShareFilesToCapture')
            navigate(Pages.Timeline.url)
        }
    }, [
        copyFilesToTimeline,
        navigate,
        props.share,
        isUserLoggedIn,
        isReadOnlyUser,
        dispatch,
    ])

    // restore scroll
    React.useEffect(() => {
        if (lastViewOffset) {
            setTimeout(() => {
                window.scrollTo(0, lastViewOffset ?? 0)
                doClearLastKnownPos(props.share.id)
            }, 0)
        }
    }, [])

    const getThumbSection = (thumbFiles: CaptureFile[]) => {
        if (thumbFiles.length == 0) {
            return null
        }

        const addFilesBtnText = getStringByAmount(
            thumbFiles.length,
            _('share__add_singleFile_to_capture__format'),
            _('share__add_multipleFiles_to_capture__format'),
        ).replace('%s', PRODUCT_NAME)

        return (
            <div>
                <ThumbSection
                    files={thumbFiles}
                    gridStyle={gridStyle}
                    enterCarouselView={doEnterCarouselView}
                    isMobileDisplay={isMobile}
                />
                {showAddToTimelineButton && (
                    <ButtonWrapper>
                        <FilledTextButton
                            onClick={addFilesToCapture}
                            text={addFilesBtnText}
                            fillColor={colors.captureBlue}
                        />
                    </ButtonWrapper>
                )}
            </div>
        )
    }

    const getStopSharingFlowComponents = (totalFileCount: number) => {
        switch (deleteFlow) {
            case DeleteFlowStep.NOT_STARTED:
                return null
            case DeleteFlowStep.SHOW_CONFIRM_DIALOG:
                return (
                    <ConfirmPromptOverlay
                        onConfirm={stopSharing}
                        onCancel={cancelStopSharing}>
                        {_('share__confirm_stop')}
                    </ConfirmPromptOverlay>
                )
            case DeleteFlowStep.SHOW_DELETED_DIALOG:
                return (
                    <OkPromptOverlay onOK={flushShareAndNavigateToTimeline}>
                        {getStringByAmount(
                            totalFileCount,
                            _('share__deleted_SingleFile'),
                            _('share__deleted_MultipleFiles'),
                        )}
                    </OkPromptOverlay>
                )
        }
    }

    const isShareOwner =
        isUserLoggedIn && currentUserUUID === props.share.owner.userID
    const gridStyle = calculateShareGridStyle(
        props.share,
        isMobile,
        viewportWidth,
    )
    const showAddToTimelineButton = !isUserLoggedIn || !isReadOnlyUser

    const totalFileCount =
        props.share.thumbFiles.length + props.share.docFiles.length

    const headerText = isShareOwner
        ? getStringByAmount(
              totalFileCount,
              _('share__header_SingleFile_owner__format'),
              _('share__header_MultipleFiles_owner__format'),
          )
        : getStringByAmount(
              totalFileCount,
              _('share__header_SingleFile__format'),
              _('share__header_MultipleFiles__format'),
          )

    const formattedHeaderText = headerText
        .replace(
            '%user_name%',
            props.share.owner.name || props.share.owner.email || '',
        )
        .replace('%product_name%', PRODUCT_NAME)

    const DownloadButton = showAddToTimelineButton
        ? BorderedTextButton
        : FilledTextButton

    return (
        <SharePageOutline context="SharePage">
            <FadeInContainer isVisible={!lastViewOffset}>
                <HeaderText>{formattedHeaderText}</HeaderText>
                {getThumbSection(props.share.thumbFiles)}
                {props.share.docFiles.length > 0 && (
                    <DocSection
                        files={props.share.docFiles}
                        onDownloadSingleFile={doDownloadSingleFile}
                    />
                )}
            </FadeInContainer>
            <ButtonWrapper>
                <DownloadButton
                    onClick={
                        isMobileDevice.iOS()
                            ? goToDownloadPage
                            : doDownloadFiles
                    }
                    text={getStringByAmount(
                        totalFileCount,
                        _('download_file'),
                        _('download_all'),
                    )}
                    color={
                        showAddToTimelineButton ? colors.captureBlue : undefined
                    }
                    fillColor={
                        showAddToTimelineButton ? undefined : colors.captureBlue
                    }
                />
            </ButtonWrapper>
            {isShareOwner && (
                <>
                    <ButtonWrapper>
                        <BorderedTextButton
                            onClick={showConfirmStopSharingDialog}
                            text={_('share__stop')}
                            color={colors.captureBlue}
                        />
                    </ButtonWrapper>
                    {getStopSharingFlowComponents(totalFileCount)}
                </>
            )}
        </SharePageOutline>
    )
}
