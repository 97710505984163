import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import SvgAlbumPageFilled from '@capture/capture-components/src/icons/AlbumPageFilled'
import SvgAlbumPage from '@capture/capture-components/src/icons/AlbumPage'
import SvgHamburgerMenu from '@capture/capture-components/src/icons/HamburgerMenu'
import SvgLogout from '@capture/capture-components/src/icons/Logout'
import SvgPhotoDetailsFilled from '@capture/capture-components/src/icons/PhotoDetailsFilled'
import SvgPhotoDetails from '@capture/capture-components/src/icons/PhotoDetails'
import SvgSettings from '@capture/capture-components/src/icons/Settings'
import { logoutAndRedirectToOpenWeb } from '~/API/login'
import { _ } from '~/assets/localization/util'
import type { Dispatch } from '~/state/common/actions'
import { colors, fontSize, layout, zIndex } from '~/assets/styleConstants'
import { Pages } from '~/routing'
import { getCurrentUserInfo } from '~/state/currentUser/selectors'
import type { User } from '~/state/users/reducer'
import { isMobileMode } from '~/state/viewMode/selectors'
import type { PageDescription, WithRouterProps } from '~/utilities/navigation'
import { withRouter } from '~/utilities/navigation'
import { Avatar } from '../Common/Avatar'
import type { ButtonProps } from '../Common/Button'
import { IconTextListButton } from '../Common/IconTextButton'
import { CaptureLogoHorizontal } from '../Icons/CaptureLogoHorizontal'
import { EmptyIcon } from '../Icons/EmptyIcon'

const Logo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px 16px;
    box-sizing: border-box;
`

const InvisibleClickTarget = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: ${(props: { isExpanded: boolean }) =>
        props.isExpanded ? 'block' : 'none'};
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`

type MenuWrapperProps = { isExpanded: boolean; isMobile: boolean }
const MenuWrapper = styled.div`
    position: fixed;
    top: ${(props: MenuWrapperProps) =>
        props.isMobile ? 0 : layout.topNavBarHeight}px;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: ${colors.mainBackground};
    width: 240px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 4px;
    padding: 16px 0;
    box-sizing: border-box;
    transform: ${(props) =>
        props.isExpanded ? 'translateX(0)' : 'translateX(-242px)'};
    transition: transform 0.2s;
    transform-origin: left top;
    cursor: initial;
    font-size: ${fontSize.medium_18};
    z-index: ${zIndex.drawer};
`
const BurgerWrapper = styled.div`
    height: 24px;
`

const MenuGroupWrapper = styled.div`
    padding: 12px 0;
    width: 100%;

    &:first-of-type {
        padding-top: 24px;
    }
    &:last-of-type {
        font-weight: lighter;
    }
`
const Separator = styled.div`
    width: 56px;
    margin-left: 16px;
    border-top: 1px solid ${colors.captureGrey300};
`
const ItemWrapper = styled.div`
    width: 100%;
    padding: 4px 0;
    border-left: 8px solid
        ${(props: { highlightItem: boolean }) =>
            props.highlightItem ? colors.captureBlue : 'transparent'};
    box-sizing: border-box;
`

type MenuItemProps = ButtonProps & { isActive: boolean }

type StateProps = {
    isMobile: boolean
    currentUserInfo?: User
}
type DispatchProps = {
    logoutToOpenWeb: () => void
}

type OwnProps = {
    currentPage?: PageDescription
}
type Props = OwnProps & StateProps & DispatchProps & WithRouterProps

type LocalState = { isMenuExpanded: boolean }
class _SlideInBurgerMenu extends React.Component<Props, LocalState> {
    public state: LocalState = { isMenuExpanded: false }

    private expandMenu = (e: any) => {
        e.stopPropagation()
        this.setState({ isMenuExpanded: true })
    }
    private collapseMenu = () => {
        this.setState({ isMenuExpanded: false })
    }
    private toggleMenu = (e: any) => {
        this.state.isMenuExpanded ? this.collapseMenu() : this.expandMenu(e)
    }
    private handleOptionClick = (opt: ButtonProps) => {
        opt.onClick()
        this.collapseMenu()
    }
    private onClickOutside = (e: any) => {
        e.stopPropagation()
        this.collapseMenu()
    }
    private transitionToHandlerFactory = (page: string) => {
        return () => this.props.navigate(page)
    }

    private createMenuItem = (opt: MenuItemProps) => (
        <ItemWrapper key={opt.text} highlightItem={opt.isActive}>
            <IconTextListButton
                onClick={() => this.handleOptionClick(opt)}
                icon={opt.icon || EmptyIcon}
                text={opt.text}
                color={
                    opt.isActive ? colors.captureBlue : colors.captureGrey800
                }
                hoverColor={colors.captureBlue}
                data-cy={opt.cyKey}
            />
        </ItemWrapper>
    )

    public render() {
        const isPhotoActive = Pages.Timeline === this.props.currentPage
        const isAlbumActive = Pages.Albums === this.props.currentPage
        const menuLinksGroup1: MenuItemProps[] = [
            {
                text: _('timeline'),
                onClick: this.transitionToHandlerFactory(Pages.Timeline.url),
                icon: isPhotoActive ? SvgPhotoDetailsFilled : SvgPhotoDetails,
                isActive: isPhotoActive,
                cyKey: 'menu__photosBtn',
            },
            {
                text: _('albums'),
                onClick: this.transitionToHandlerFactory(Pages.Albums.url),
                icon: isAlbumActive ? SvgAlbumPageFilled : SvgAlbumPage,
                isActive: isAlbumActive,
                cyKey: 'menu__albumsBtn',
            },
        ]

        const menuLinksGroup2: MenuItemProps[] = [
            {
                text: _('settings'),
                onClick: this.transitionToHandlerFactory(Pages.Settings.url),
                icon: SvgSettings,
                isActive: Pages.Settings === this.props.currentPage,
                cyKey: 'menu__settingsBtn',
            },
        ]

        const menuLinksGroup3: MenuItemProps[] = [
            {
                text: _('log_out'),
                onClick: this.props.logoutToOpenWeb,
                icon: SvgLogout,
                isActive: false,
                cyKey: 'menu__exitBtn',
            },
        ]
        const mobileOnlyArea = this.props.isMobile && (
            <Logo>
                <CaptureLogoHorizontal />
                {this.props.currentUserInfo && (
                    <Avatar
                        size={28}
                        name={this.props.currentUserInfo.name}
                        img={this.props.currentUserInfo.profilePicture}
                    />
                )}
            </Logo>
        )
        return (
            <div>
                {
                    //eslint-disable-next-line styled-components-a11y/no-static-element-interactions, styled-components-a11y/click-events-have-key-events
                    <InvisibleClickTarget
                        isExpanded={this.state.isMenuExpanded}
                        onClick={this.onClickOutside}
                    />
                }
                <BurgerWrapper
                    data-cy="burgerMenuBtn"
                    onClick={this.toggleMenu}
                    onKeyUp={this.toggleMenu}
                    role="button"
                    tabIndex={0}>
                    <SvgHamburgerMenu />
                </BurgerWrapper>
                <MenuWrapper
                    isExpanded={this.state.isMenuExpanded}
                    isMobile={this.props.isMobile}>
                    {mobileOnlyArea}
                    <MenuGroupWrapper>
                        {menuLinksGroup1.map(this.createMenuItem)}
                    </MenuGroupWrapper>
                    <Separator />
                    <MenuGroupWrapper>
                        {menuLinksGroup2.map(this.createMenuItem)}
                    </MenuGroupWrapper>
                    <Separator />
                    <MenuGroupWrapper>
                        {menuLinksGroup3.map(this.createMenuItem)}
                    </MenuGroupWrapper>
                </MenuWrapper>
            </div>
        )
    }
}

const mapStateToProps = (
    state: StateOfSelector<typeof isMobileMode> &
        StateOfSelector<typeof getCurrentUserInfo>,
): StateProps => ({
    isMobile: isMobileMode(state),
    currentUserInfo: getCurrentUserInfo(state),
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    logoutToOpenWeb: () => logoutAndRedirectToOpenWeb(dispatch),
})

const SlideInBurgerMenuCon = connect(
    mapStateToProps,
    mapDispatchToProps,
)(_SlideInBurgerMenu)

export const SlideInBurgerMenu = withRouter(SlideInBurgerMenuCon)
