import * as React from 'react'
import styled from 'styled-components'
import { acceptedExtensions } from '~/utilities/fileTarget'
import { _ } from '~/assets/localization/util'
import { trackEvent } from '~/analytics/eventTracking'
import { colors, fontSize } from '~/assets/styleConstants'
import { EmptyStatePage } from '../Common/EmptyStatePage'
import { FilledTextButton } from '../Common/IconTextButton'
import { FileAcceptElement } from '../Uploader/FileAcceptElement'

const AddPhotosButton = styled.div`
    text-transform: uppercase;
    font-size: ${fontSize.xSmall_10};
    letter-spacing: 1.25px;
    display: inline-block;
`

type Props = {
    fileHandler?: (files: File[]) => void
}

export class SelectFromTimelineEmptyState extends React.Component<Props> {
    public componentDidMount() {
        trackEvent('SelectFileFromTimeline', 'EmptyStateMounted')
    }
    public render() {
        const addPhotosBtn = this.props.fileHandler && (
            <FileAcceptElement
                fileHandler={this.props.fileHandler}
                acceptTypes={acceptedExtensions}>
                <AddPhotosButton>
                    <FilledTextButton
                        text={_('add_photos')}
                        fillColor={colors.captureBlue}
                        color={'white'}
                    />
                </AddPhotosButton>
            </FileAcceptElement>
        )

        const header = (
            <div>
                <div>{_('timeline_is_empty')}</div>
                <div>
                    {this.props.fileHandler && _('upload_photos_add_album')}
                </div>
            </div>
        )

        return <EmptyStatePage header={header}>{addPhotosBtn}</EmptyStatePage>
    }
}
