export type VisibleElementInfo = {
    key?: string
    height: number
    positionTop: number
    content: React.ReactNode
}
type Props = {
    totalHeight: number
    elements: VisibleElementInfo[]
}

export const WindowedVirtualList: React.FunctionComponent<Props> = (props) => {
    const elements = props.elements.map((el) => (
        <div
            key={el.key}
            style={{
                height: el.height,
                width: '100%',
                position: 'absolute',
                top: el.positionTop,
            }}>
            {el.content}
        </div>
    ))

    return (
        <div style={{ height: props.totalHeight, position: 'relative' }}>
            {elements}
        </div>
    )
}
