import * as React from 'react'
import styled from 'styled-components'
import { colors } from '~/assets/styleConstants'
import type { Icon } from '../Icons'

type BtnProps = {
    isDisabled?: boolean
    fontSize?: string
    color?: string
    padding: 'regular' | 'relaxed'
    fillColor?: string
}

const Btn = styled.div<BtnProps>`
    // button is disabled
    pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};

    // button looks disabled
    cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
    opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};

    // button style
    display: flex;
    justify-content: center;
    align-items: center;

    background-clip: padding-box;
    font-size: ${(props) => props.fontSize || 'inherit'};
    color: ${(props) => props.color || 'inherit'};
    user-select: none;
    border-radius: 3px;
`
const LeftAlignedBtn = styled(Btn)`
    justify-content: flex-start;
    padding: 8px 12px;

    & > div > div:last-child {
        margin-left: 8px;
    }
`
const FilledBtn = styled(Btn)`
    color: ${(props) => props.color || 'white'};
    background-color: ${(props) => props.fillColor || colors.captureBlue};
    border: solid 1px ${(props) => props.fillColor || colors.captureBlue};
    padding: ${(props: BtnProps) =>
        props?.padding === 'relaxed' ? '8px 32px' : '8px 16px'};
`

const BorderedBtn = styled(Btn)`
    border: solid 1px ${(props) => props.color || colors.captureGrey800};
    background-color: ${(props) => props.fillColor || 'transparent'};
    padding: ${(props: BtnProps) =>
        props?.padding === 'relaxed' ? '8px 32px' : '8px 16px'};
`

const IconTextWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`
const IconBtnText = styled.div`
    margin: ${(props: { iconOnRight?: boolean }) =>
        props?.iconOnRight ? '0px 4px 0px 0px' : '0px 0px 0px 4px'};
`

type TWithColor<T> = T & {
    color?: string
    hoverColor?: string
    hoverFillColor?: string
}
type Base = {
    color?: string
    fillColor?: string
    onMouseEnter?: () => void
    onMouseLeave?: () => void
}
const withHoverColor = <T extends Base>(
    Button: React.ComponentType<T>,
): React.FC<TWithColor<T>> => {
    // A function-component with hooks for state to allow styled(withHoverColor(...))
    // eslint-disable-next-line react/display-name
    return (props: TWithColor<T>) => {
        const [isHovering, setHover] = React.useState<boolean>(false)
        const color =
            isHovering && props.hoverColor ? props.hoverColor : props.color
        const fillColor =
            isHovering && props.hoverFillColor
                ? props.hoverFillColor
                : props.fillColor
        return (
            <Button
                {...props}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                color={color}
                fillColor={fillColor}
            />
        )
    }
}

// Do not export. The internal component props do not do type checking.
const combinedStyledWrapperAndContent = <T1, T2>(
    C1: React.ComponentType<T1>,
    C2: React.ComponentType<T2>,
) =>
    class Combined extends React.Component<
        T1 & T2 & Base & { children?: React.ReactNode }
    > {
        public render() {
            return (
                <C1 {...this.props}>
                    <C2 {...this.props}>{this.props.children}</C2>
                </C1>
            )
        }
    }

// Button-content-components
type IconContentProps = {
    icon: Icon
    color?: string
    iconSize?: number
}

const IconContent = ({ icon: IconComp, color, iconSize }: IconContentProps) => (
    <IconComp color={color || colors.captureGrey800} size={iconSize || 24} />
)

type TextContentProps = { text: string }
const TextContent = (p: TextContentProps) => <div>{p.text}</div>

const IconTextContent = (p: IconContentProps & TextContentProps) => (
    <IconTextWrapper>
        <IconContent {...p} />
        <IconBtnText>{p.text}</IconBtnText>
    </IconTextWrapper>
)

const RightIconTextContent = (p: IconContentProps & TextContentProps) => (
    <IconTextWrapper>
        <IconBtnText iconOnRight={true}>{p.text}</IconBtnText>
        <IconContent {...p} />
    </IconTextWrapper>
)

export type SVGIcon = React.FC<React.SVGProps<SVGSVGElement>>

// The actual Button-classes
export const IconTextButton = withHoverColor(
    combinedStyledWrapperAndContent(Btn, IconTextContent),
)
export const IconTextListButton = withHoverColor(
    combinedStyledWrapperAndContent(LeftAlignedBtn, IconTextContent),
)
export const FilledIconTextButton = withHoverColor(
    combinedStyledWrapperAndContent(FilledBtn, IconTextContent),
)
export const FilledRightIconTextButton = withHoverColor(
    combinedStyledWrapperAndContent(FilledBtn, RightIconTextContent),
)
export const BorderedIconTextButton = withHoverColor(
    combinedStyledWrapperAndContent(BorderedBtn, IconTextContent),
)
export const BorderedRightIconTextButton = withHoverColor(
    combinedStyledWrapperAndContent(BorderedBtn, RightIconTextContent),
)

export const TextButton = withHoverColor(
    combinedStyledWrapperAndContent(Btn, TextContent),
)
export const FilledTextButton = withHoverColor(
    combinedStyledWrapperAndContent(FilledBtn, TextContent),
)
export const BorderedTextButton = withHoverColor(
    combinedStyledWrapperAndContent(BorderedBtn, TextContent),
)

export const IconButton = withHoverColor(
    combinedStyledWrapperAndContent(Btn, IconContent),
)

export const FilledIconButton = withHoverColor(
    combinedStyledWrapperAndContent(FilledBtn, IconContent),
)
