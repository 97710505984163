import { createActionCreator } from '../common/actions'

export const LastSeenElementUpdated = createActionCreator<JobFileReference>(
    'LAST_SEEN_ELEMENT_UPDATED',
)
export const LastSeenElementCleared = createActionCreator<JobID>(
    'LAST_SEEN_ELEMENT_CLEARED',
)

export const LastSeenAlbumUpdated = createActionCreator<JobID>(
    'LAST_SEEN_ALBUM_UPDATED',
)
