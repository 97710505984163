import styled from 'styled-components'

export const CenteredElementWrapper = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`

const FlexContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
`

const LeftArea = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`
const MidArea = styled.div`
    justify-self: center;
    text-align: center;
    display: flex;
`
const RightArea = styled.div`
    flex: 1;
    text-align: right;
    justify-self: end;

    display: flex;
    justify-content: flex-end;
    align-items: center;
`
type Props = {
    left?: React.ReactNode
    middle?: React.ReactNode
    right?: React.ReactNode
}
export const ThreeAreasSymetric: React.FunctionComponent<Props> = (props) => (
    <FlexContainer>
        <LeftArea>{props.left}</LeftArea>
        <MidArea>{props.middle}</MidArea>
        <RightArea>{props.right}</RightArea>
    </FlexContainer>
)
