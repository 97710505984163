import { PageCard, Typography } from '@capture/capture-components'
import SvgArrowBack from '@capture/capture-components/src/icons/ArrowBack'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { fetchListOfJobs } from '~/API/job'
import { _ } from '~/assets/localization/util'
import { fontSize } from '~/assets/styleConstants'
import { IconTextButton } from '~/components/Common/IconTextButton'
import { PageWrapper } from '~/components/Common/PageWrapper'
import { RippleLoaderPage } from '~/components/Common/RippleLoader'
import { FilesOptionsPlacement } from '~/components/FilesOptions/FilesOptionsPlacement'
import { TwoAreasTopNavBar } from '~/components/Navigation/TwoAreasTopNavBar'
import { HOME_SITE_ADDRESS, getCurrentLangDefinition } from '~/config/constants'
import { SettingsPrivacy } from '~/routing/pages'
import { getCurrentUserAlbumList } from '~/state/albumList/selectors'
import {
    getCurrentUserUUID,
    getFileCount,
    getUsedStorage,
} from '~/state/currentUser/selectors'
import { getTimelineJobID } from '~/state/timeline/selectors'
import { isMobileDevice } from '~/utilities/device'
import { bytesToSize } from '~/utilities/fileSizeFormatting'
import { AlbumDownloadEntry } from './AlbumDownloadEntry'
import { takeoutAnalytics } from './takeoutAnalytics'
import { _TakeoutPageMobile } from './TakeoutPageMobile'
import { TimelineDownloadEntry } from './TimelineDownloadEntry'

const PageLayout = styled(PageCard)`
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
`

const Section = styled.section`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;

    // we want the gap between title and content to be smaller than the gap between elements
    & > h2,
    & > h3 {
        margin-bottom: -0.5rem;
    }
`

export const TakeoutPage = () => {
    return isMobileDevice.any() ? (
        <_TakeoutPageMobile />
    ) : (
        <_TakeoutPageDesktop />
    )
}

const _TakeoutPageDesktop = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const currentUser = useSelector(getCurrentUserUUID)
    const albumListData = useSelector(getCurrentUserAlbumList)
    const [albumListLoading, setAlbumListLoading] = useState(false)
    const navigateToSettings = () => navigate(SettingsPrivacy.url)
    const usedSpace = useSelector(getUsedStorage)
    const timelineJobID = useSelector(getTimelineJobID)
    const fileCount = useSelector(getFileCount)
    const timelineCount =
        (fileCount?.picture_count ?? 0) + (fileCount?.video_count ?? 0)

    const usageStatsString = _('takeout_stats')
        .replace('%num_files', `${timelineCount || '0'}`)
        .replace('%total_size', `${bytesToSize(usedSpace)}`)

    useEffect(() => {
        if (typeof timelineCount === 'undefined') {
            return
        }
        takeoutAnalytics.landedInTakeout(timelineCount || 0, usedSpace)
    }, [timelineCount, usedSpace])

    useEffect(() => {
        if (currentUser) {
            setAlbumListLoading(true)
            fetchListOfJobs(dispatch, currentUser).then(() =>
                setAlbumListLoading(false),
            )
        }
    }, [currentUser, dispatch])

    if (albumListLoading) {
        return <RippleLoaderPage />
    }

    const resolveSupportUrl = () => {
        const { connectKey } = getCurrentLangDefinition()
        const resolveSupportHash = (lang: string) => {
            if (lang === 'sv') {
                return 'uppladdning-och-nedladdning'
            }
            if (lang === 'no') {
                return 'opplasting-og-nedlasting'
            }
            return 'uploading-and-downloading'
        }
        return {
            key: connectKey,
            hash: resolveSupportHash(connectKey),
        }
    }

    const DownloadBodyText = () => {
        const [before, lastSection] = _('takeout_download_all_body').split(
            '%support_url%',
        )
        const [lastBefore, lastAfter] = lastSection.split('%capture_settings%')
        const supportUrl = resolveSupportUrl()

        return (
            <>
                {before}
                <Typography.Link
                    href={`${HOME_SITE_ADDRESS}/support?language=${supportUrl.key}#${supportUrl.hash}`}
                    target="_blank"
                    rel="noreferrer">
                    {_('help')}
                </Typography.Link>
                {lastBefore}
                <Typography.Link href="/settings/storage">
                    {_('capture_settings')}
                </Typography.Link>
                {lastAfter}
            </>
        )
    }

    const PhotosBodyText = () => {
        const [before, last] = _('takeout_body_photos').split('%support_url%')
        const supportUrl = resolveSupportUrl()

        return (
            <>
                {before}
                <Typography.Link
                    href={`${HOME_SITE_ADDRESS}/support?language=${supportUrl.key}#${supportUrl.hash}`}
                    target="_blank"
                    rel="noreferrer">
                    {_('help')}
                </Typography.Link>
                {last}
            </>
        )
    }

    return (
        <PageWrapper
            navBar={
                <TwoAreasTopNavBar
                    left={
                        <IconTextButton
                            onClick={navigateToSettings}
                            text={_('privacy')}
                            icon={SvgArrowBack}
                            fontSize={fontSize.small_14}
                        />
                    }
                />
            }>
            <FilesOptionsPlacement
                jobID={timelineJobID || ''}
                context="TimelinePage"
                files={[]}
            />
            <PageLayout>
                <Section>
                    <Typography.SectionHeader>
                        {_('takeout_download_all_title')}
                    </Typography.SectionHeader>
                    <Typography.Paragraph>
                        <DownloadBodyText />
                    </Typography.Paragraph>
                </Section>
                <Section>
                    <Typography.Subheader>
                        {_('takeout_subheader_photos')}
                    </Typography.Subheader>
                    <Typography.Paragraph>
                        <PhotosBodyText />
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        <Typography.Bold>{usageStatsString}</Typography.Bold>
                    </Typography.Paragraph>
                    <TimelineDownloadEntry
                        timelineCount={timelineCount || 0}
                        timelineJobID={timelineJobID || ''}
                    />
                </Section>

                {albumListData.length > 0 && (
                    <Section>
                        <Typography.Subheader>
                            {_('takeout_subheader_albums')}
                        </Typography.Subheader>
                        <Typography.Paragraph>
                            {_('takeout_body_albums')}
                        </Typography.Paragraph>
                        {albumListData
                            .filter((album) => (album.numberOf?.files ?? 0) > 0)
                            .map((album) => (
                                <AlbumDownloadEntry
                                    key={album.id}
                                    album={album}
                                />
                            ))}
                    </Section>
                )}
            </PageLayout>
        </PageWrapper>
    )
}
