/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    CAPBAKAccountInfo,
    CAPBAKAccountInfoGetParams,
    CAPBAKHTTPValidationError,
} from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class AccountInfo<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * @description This call is used in the login. It retrieves the user account information In the inlogging we call this method with https://a0-test.univex.no/account_info?validity=86400&device_id=fae886eb-0be9-471a-a174-6ea95a002f86&device_name=Capture%20web%201.5&key=KVEz-WDMsj&auth=5xYX44S_Q5SF-KKBTxkvAAuV8yeZ8H8u8Bi6J0Berj&uniquets=1452154975416 Seems like we call this method with some parameters not used on the backend
     *
     * @tags account_info
     * @name AccountInfoGet
     * @summary Account Info
     * @request GET:/account_info
     */
    accountInfoGet = (
        query: CAPBAKAccountInfoGetParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKAccountInfo, void | CAPBAKHTTPValidationError>({
            path: `/account_info`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
}
