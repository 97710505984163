import type { Middleware } from '@reduxjs/toolkit'
import { FetchedAccountInfo } from '~/state/currentUser/actions'
import { type Action, BulkOfActions, isType } from '~/state/common/actions'
import {
    trackAction,
    trackActionInternal,
    trackingConsentHasBeenGranted,
} from './eventTracking'

export const analyticsMiddleware: Middleware = (store) => {
    const handle = (action: Action) => {
        if (isType(action, BulkOfActions)) {
            action.payload.forEach(handle)
            return
        }
        if (isType(action, FetchedAccountInfo)) {
            if (action.payload.allowAnalytics) {
                trackingConsentHasBeenGranted()
            }
        }
        trackAction(action, store.getState)
        trackActionInternal(action)
    }
    return (next) => (action) => {
        handle(action)
        next(action)
    }
}
