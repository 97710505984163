import * as React from 'react'
import { trackEventInternal } from '../../analytics/eventTracking'
import { colors } from '../../assets/styleConstants'
import { MethodProvider } from '../Common/MethodProvider'
import { SelectionIndicator } from '../Common/SelectionIndicator'

export type GroupSelectionStatus =
    | 'none-selected'
    | 'some-selected'
    | 'all-selected'
export type ScrollerGroupItem = {
    itemKey: string
    pageYStart: number
    pageYEnd: number
    groupSelectionStatus?: GroupSelectionStatus
}

export type ScrollerGroup = {
    groupKey: string
    groupItems: ScrollerGroupItem[]
    groupSelectionStatus?: GroupSelectionStatus
}

export type ScrollerItemInfo = {
    targetGroupPosition: number
    scrollerItemKey: string
}
type ScrollerItemProps = {
    onSelect: (itemInfo: ScrollerItemInfo) => void
    itemKey: string
    targetPosition: number
    isSelected: boolean
    scrollerWrapper: HTMLDivElement | null
    isFocused: boolean
    children?: React.ReactNode
}

class ScrollerItem extends React.Component<ScrollerItemProps> {
    private itemDiv = React.createRef<HTMLDivElement>()

    private handleClick = () => {
        trackEventInternal('timeline_fastscroller_item_click')
        this.props.onSelect({
            targetGroupPosition: this.props.targetPosition,
            scrollerItemKey: this.props.itemKey,
        })
    }

    private isCompletelyScrolledIntoView = (): boolean => {
        if (
            this.itemDiv.current &&
            this.props.scrollerWrapper &&
            this.itemDiv.current.offsetParent
        ) {
            const elemTop =
                this.itemDiv.current.offsetTop -
                this.props.scrollerWrapper.scrollTop
            const elemBottom: number =
                this.itemDiv.current.offsetTop -
                this.props.scrollerWrapper.scrollTop +
                this.itemDiv.current.clientHeight

            return (
                elemTop >= 0 &&
                elemBottom <= this.itemDiv.current.offsetParent.clientHeight
            )
        }
        return true
    }

    public componentDidUpdate(prevProps: ScrollerItemProps) {
        if (
            this.itemDiv.current?.offsetParent &&
            this.props.scrollerWrapper &&
            (this.props.isFocused ||
                (!prevProps.isSelected && this.props.isSelected)) &&
            !this.isCompletelyScrolledIntoView()
        ) {
            this.props.scrollerWrapper.scrollTop =
                this.itemDiv.current.offsetTop -
                this.itemDiv.current.offsetParent.clientHeight / 2
        }
    }

    public render() {
        return (
            <div
                ref={this.itemDiv}
                onClick={this.handleClick}
                onKeyUp={this.handleClick}
                role="button"
                tabIndex={0}
                className="scroller-item">
                {this.props.children}
            </div>
        )
    }
}

type FastScrollerProps = {
    scrollerGroups: ScrollerGroup[]
    selectedItemKey: string
    scrollerItemOnSelected: (scrollerItemInfo: ScrollerItemInfo) => void
    onSelectionBtnClicked?: (
        scrollerItemInfo: ScrollerGroupItem | ScrollerGroup,
    ) => void
    scrollerWrapper: HTMLDivElement | null
    focusedGroup?: string
}
export type FastScrollerItemProps = {
    isSelected: boolean
    isFocused?: boolean
    children?: React.ReactNode
}

export const makeFastScroller = (
    Wrapper: React.ElementType,
    Header: React.ElementType,
    ItemContentWrapper: React.ComponentType<FastScrollerItemProps>,
    transformItemLabel?: (label: string) => string,
) =>
    class FastScroller extends React.Component<FastScrollerProps> {
        handleOnSelectionClicked = (
            item: ScrollerGroupItem | ScrollerGroup,
            event: React.SyntheticEvent,
        ) => {
            if (this.props.onSelectionBtnClicked) {
                this.props.onSelectionBtnClicked(item)
                event.stopPropagation()
            }
        }
        getItem = (item: ScrollerGroupItem) => {
            const isSelected = item.itemKey === this.props.selectedItemKey
            const isFocused = item.itemKey === this.props.focusedGroup
            const selectionBtn = item.groupSelectionStatus !== undefined &&
                this.props.onSelectionBtnClicked && (
                    <MethodProvider
                        arg={item}
                        method={this.handleOnSelectionClicked}>
                        {(method) => (
                            <SelectionIndicator
                                onClick={method}
                                iconSize={20}
                                showPlaceholder={true}
                                isSelected={
                                    item.groupSelectionStatus !==
                                    'none-selected'
                                }
                                checkmarkColor={
                                    item.groupSelectionStatus === 'all-selected'
                                        ? 'white'
                                        : colors.captureBlue
                                }
                                fillColor={
                                    item.groupSelectionStatus === 'all-selected'
                                        ? colors.captureBlue
                                        : 'white'
                                }
                            />
                        )}
                    </MethodProvider>
                )

            return (
                <ScrollerItem
                    key={item.itemKey}
                    itemKey={item.itemKey}
                    targetPosition={item.pageYStart}
                    onSelect={this.props.scrollerItemOnSelected}
                    scrollerWrapper={this.props.scrollerWrapper}
                    isSelected={isSelected}
                    isFocused={isFocused}>
                    <ItemContentWrapper
                        isSelected={isSelected}
                        isFocused={isFocused}>
                        {transformItemLabel
                            ? transformItemLabel(item.itemKey)
                            : item.itemKey}
                        {selectionBtn}
                    </ItemContentWrapper>
                </ScrollerItem>
            )
        }

        getHeader = (item: ScrollerGroup) => {
            const selectionBtn = item.groupSelectionStatus !== undefined &&
                this.props.onSelectionBtnClicked && (
                    <MethodProvider
                        arg={item}
                        method={this.handleOnSelectionClicked}>
                        {(method) => (
                            <SelectionIndicator
                                onClick={method}
                                iconSize={20}
                                showPlaceholder={true}
                                isSelected={
                                    item.groupSelectionStatus !==
                                    'none-selected'
                                }
                                checkmarkColor={
                                    item.groupSelectionStatus === 'all-selected'
                                        ? 'white'
                                        : colors.captureBlue
                                }
                                fillColor={
                                    item.groupSelectionStatus === 'all-selected'
                                        ? colors.captureBlue
                                        : 'white'
                                }
                            />
                        )}
                    </MethodProvider>
                )

            return (
                <Header>
                    <div>{item.groupKey}</div>
                    {selectionBtn}
                </Header>
            )
        }

        public render() {
            return this.props.scrollerGroups.map((group) => {
                return (
                    <Wrapper key={group.groupKey}>
                        {this.getHeader(group)}
                        <div>{group.groupItems.map(this.getItem)}</div>
                    </Wrapper>
                )
            })
        }
    }
