import { trackEventInternal } from '~/analytics/eventTracking'

export const takeoutAnalytics = {
    takeoutInMobile: () => trackEventInternal('attempted_takeout_in_mobile'),
    landedInTakeout: (numFiles: number, totalSize: number) =>
        trackEventInternal('takeout_landed', {
            num_files: numFiles,
            total_size: totalSize,
        }),
    downloadedTimeline: () => trackEventInternal('takeout_timeline_download'),
    downloadedAlbum: (numFiles: number) =>
        trackEventInternal('takeout_album_download', { num_files: numFiles }),
}
