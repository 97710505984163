import type { Action } from '../common/actions'
import { isType } from '../common/actions'
import {
    AlbumCreatedFromUploadFilesFlag,
    AlbumCreationEnded,
    AlbumWasCreated,
    ClearAlbumCreatedFromUploadFiles,
} from './actions'

export type AlbumCreationState = {
    createdFromUpload?: boolean
    albumJustCreated?: string
}

const initialState: AlbumCreationState = {
    createdFromUpload: undefined,
    albumJustCreated: undefined,
}

export const albumCreationReducer = (
    state: AlbumCreationState = initialState,
    action: Action,
): AlbumCreationState => {
    if (isType(action, AlbumCreatedFromUploadFilesFlag)) {
        return {
            ...state,
            createdFromUpload: true,
        }
    }
    if (isType(action, ClearAlbumCreatedFromUploadFiles)) {
        return {
            ...state,
            createdFromUpload: undefined,
        }
    }
    if (isType(action, AlbumWasCreated)) {
        return {
            ...state,
            albumJustCreated: action.payload.jobID,
        }
    }
    if (isType(action, AlbumCreationEnded)) {
        return {
            ...state,
            albumJustCreated: undefined,
        }
    }
    return state
}

export const albumCreationReducerMapObj = {
    albumCreation: albumCreationReducer,
}
export type StateWithAlbumCreation = StateOfReducerMapObj<
    typeof albumCreationReducerMapObj
>
