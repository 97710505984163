import { useNavigate } from 'react-router-dom'
import { _ } from '~/assets/localization/util'
import { PRODUCT_NAME } from '~/config/constants'
import { createAlbum } from '~/API/album'
import { Pages } from '~/routing'
import type { Dispatch } from '~/state/common/actions'
import { RequireLoginActionPage } from './RequireLoginActionPage'

export const RequireLoginCreateNewAlbum: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const createAlbumAndContinue = async (dispatch: Dispatch) => {
        const albumID = await createAlbum(dispatch)
        navigate(Pages.EditAlbum(albumID).url, { replace: true })
    }
    return (
        <RequireLoginActionPage
            text={_('needs_connect_to_create_album').replace(
                '%product_name%',
                PRODUCT_NAME,
            )}
            afterLoginAction={createAlbumAndContinue}
        />
    )
}
