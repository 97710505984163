import { createSelector } from 'reselect'
import type { SetRequired } from 'type-fest'
import { ThumbService } from '~/API/services/ThumbService'
import { _ } from '~/assets/localization/util'
import type { GridStyle } from '~/utilities/gridElementSizeCalculator'
import { calcImagesPerRow } from '~/utilities/gridElementSizeCalculator'
import type { AutoGeneratedAlbumInfo } from '../album/actions'
import type { FileComment } from '../comments/reducer'
import { getCommentsGroupedByImage } from '../comments/selectors'
import type { StateWithAccountAttributes } from '../currentUser/accountAttributesSlice'
import {
    getAccountAttribute,
    getCurrentAuthToken,
    getCurrentUserUUID,
    getSubscribedJobs,
} from '../currentUser/selectors'
import type { ExtendedJobFile } from '../files/reducer'
import { getFilesByJob, getFilesByJobAsArray } from '../files/selectors'
import { getThumbHost } from '../hosts/selectors'
import type {
    JobInfoElement,
    StateWithJobInfo,
    StoryJobInfo,
} from '../jobInfo/reducer'
import { JobInfoStatus } from '../jobInfo/reducer'
import { getJobInfoElements } from '../jobInfo/selectors'
import { getIdOfLastSeenAlbum } from '../lastSeenElement/selectors'
import { getTotalLoveCountPerFile } from '../reaction/selectors'
import {
    getEnquedFiles,
    getFileInformationByUploaderId,
} from '../uploader/selectors'
import type { User } from '../users/reducer'
import { getUsersInfo } from '../users/selectors'
import { getViewportWidth, isMobileMode } from '../viewMode/selectors'
import type {
    AlbumListFilter,
    AlbumListSortOrder,
    AlbumNumberOf,
    StateWithAlbumList,
} from './reducer'

const getAlbumDetailsList = (state: StateWithAlbumList) =>
    state.albumList.albumDetailsList

export type AlbumJobInfo = {
    id: JobID
    dateCreated: Date
    timestamp: { created: number; modified: number }
    title: string
    owner?: User
    coverPhoto?: string
    numberOf?: AlbumNumberOf
    currentUserCan: AvailableActions
    isShared: boolean
    size: number
    hasHEIC: boolean
}

export const getCoverPhotoIDByAlbum = createSelector(
    getFilesByJob,
    getJobInfoElements,
    (
        files: DictionaryOf<DictionaryOf<ExtendedJobFile>>,
        jobInfos: JobInfoElement[],
    ): Record<JobID, FileID> => {
        const result: DictionaryOf<FileID> = {}
        jobInfos.forEach(({ jobID, jobInfo }) => {
            if (jobInfo) {
                const coverPhoto =
                    'coverPhoto' in jobInfo ? jobInfo.coverPhoto : undefined
                const jobFiles = files[jobID] || {}
                const firstFile = Object.keys(jobFiles)[0]

                // Use given CoverPhoto if it is set
                // and (files are not yet loaded or we know the coverPhoto is not deleted)
                const useCoverPhoto =
                    coverPhoto !== undefined &&
                    (firstFile === undefined ||
                        jobFiles[coverPhoto] !== undefined)
                result[jobID] = useCoverPhoto
                    ? (coverPhoto as FileID)
                    : firstFile
            }
        })

        return result
    },
)

export const getCoverPhotoURLByAlbum = createSelector(
    getJobInfoElements,
    getCoverPhotoIDByAlbum,
    getThumbHost,
    getCurrentAuthToken,
    (
        jobInfos: JobInfoElement[],
        coverPhotoID: DictionaryOf<FileID>,
        thumbHost: string | null,
        authToken: string | undefined,
    ): Record<JobID, URLstring> => {
        if (!authToken) {
            return {}
        }
        const result: DictionaryOf<URLstring> = {}
        jobInfos.forEach(({ jobID, jobInfo }) => {
            if (jobInfo && thumbHost) {
                if (coverPhotoID[jobID]) {
                    const th = new ThumbService(thumbHost, authToken)
                    result[jobID] = th.getThumbUrl(
                        jobID,
                        coverPhotoID[jobID],
                        512,
                    )
                }
            }
        })
        return result
    },
)

export type AvailableActions = {
    deleteAlbum: boolean
    leaveAlbum: boolean
    subscribeToAlbum: boolean
    addPhotos: boolean
    addComment: boolean
    editAlbum: boolean
}

export const getAvailableActions = createSelector(
    getJobInfoElements,
    getCurrentUserUUID,
    getSubscribedJobs,
    (
        jobInfo: JobInfoElement[],
        currentUser: UserID | undefined,
        subscribedJobIDs: JobID[],
    ): Record<JobID, AvailableActions> => {
        const result: Record<JobID, AvailableActions> = {}
        jobInfo.forEach((job) => {
            if (!job.jobInfo || job.jobInfo.type !== 'story') {
                return
            }
            const isSubscribed = subscribedJobIDs.indexOf(job.jobID) !== -1
            const isOwnAlbum = job.jobInfo.owner === currentUser

            result[job.jobID] = {
                deleteAlbum: job.jobInfo.owner === currentUser,
                leaveAlbum: isSubscribed,
                subscribeToAlbum: !isOwnAlbum && !isSubscribed,
                addPhotos: isOwnAlbum || job.jobInfo.allow_uploads,
                addComment: isOwnAlbum || job.jobInfo.allow_comments,
                editAlbum: isOwnAlbum,
            }
        })

        return result
    },
)

export const getAlbumNumberOfs = createSelector(
    getJobInfoElements,
    getCommentsGroupedByImage,
    getFilesByJobAsArray,
    getTotalLoveCountPerFile,
    (
        jobInfo: JobInfoElement[],
        allComments: DictionaryOf<FileComment[]>,
        allFiles: DictionaryOf<ExtendedJobFile[]>,
        loveCounts: DictionaryOf<number>,
    ): Record<JobID, AlbumNumberOf> => {
        const result: Record<JobID, AlbumNumberOf> = {}
        jobInfo.forEach((j) => {
            if (!j.jobInfo) {
                return
            }

            const contributors: Record<UserID, boolean> = {
                [j.jobInfo.owner]: true,
            }
            const files: ExtendedJobFile[] = allFiles[j.jobID] || []
            let comments = 0
            let loves = 0

            files.forEach((f) => {
                contributors[f.addedBy] = true
                comments += (allComments[f.fileID] || []).length
                loves += loveCounts[f.fileID] || 0
            })

            result[j.jobID] = {
                contributors: Object.keys(contributors).length,
                files: files.length,
                comments,
                loves,
            }
        })

        return result
    },
)

export const getAlbumListFilter = (state: StateWithAlbumList) =>
    state.albumList.filter

export const privacyModeFilter: Record<
    AlbumListFilter,
    (album: AlbumJobInfo) => boolean
> = {
    all: (_album) => true,
    shared: (album) => album.isShared,
    private: (album) => !album.isShared,
}

export const getAlbumListSortMode = (
    state: StateWithAccountAttributes,
): AlbumListSortOrder | undefined =>
    getAccountAttribute(state, 'album_list_sort')?.value

const albumListSortMethods: Record<
    AlbumListSortOrder,
    (a: AlbumJobInfo, b: AlbumJobInfo) => number
> = {
    RECENT_ACTIVITY: (a, b) => b.timestamp.modified - a.timestamp.modified,
    NEWEST_CREATED: (a, b) => b.timestamp.created - a.timestamp.created,
    OLDEST_CREATED: (a, b) => a.timestamp.created - b.timestamp.created,
    NAME: (a, b) => a.title.localeCompare(b.title),
}

export const getFullAlbumListInfo = createSelector(
    getJobInfoElements,
    getAlbumNumberOfs,
    getUsersInfo,
    getCoverPhotoURLByAlbum,
    getAvailableActions,
    getAlbumDetailsList, // TODO: refactor to replace jobInfo
    getAlbumListSortMode,
    (
        infos,
        numbers,
        users,
        coverPhoto,
        currentUserCan,
        detailsList,
        sortMode,
    ): AlbumJobInfo[] => {
        return infos
            .filter(
                (
                    info,
                ): info is SetRequired<
                    JobInfoElement<StoryJobInfo>,
                    'jobInfo'
                > =>
                    info.jobInfo !== undefined && info.jobInfo.type === 'story',
            )
            .map((info) => {
                // TODO: refactor to replace jobInfo
                if (detailsList[info.jobID]) {
                    const d = detailsList[info.jobID]
                    return {
                        id: d.albumID,
                        title: d.title || _('unnamed_album_placeholder'),
                        owner: d.owner,
                        coverPhoto: coverPhoto[d.albumID],
                        dateCreated: new Date(d.ctime * 1000),
                        timestamp: { created: d.ctime, modified: d.mtime },
                        numberOf: d.numberOf,
                        currentUserCan: currentUserCan[d.albumID],
                        isShared: d.isShared,
                        hasHEIC: d.hasHEIC,
                        size: d.size,
                    }
                }

                return {
                    id: info.jobID,
                    title: info.jobInfo.title || _('unnamed_album_placeholder'),
                    owner: users[info.jobInfo.owner],
                    coverPhoto: coverPhoto[info.jobID],
                    dateCreated: new Date(
                        (info.jobInfo.ctime || info.jobInfo.mtime) * 1000,
                    ),
                    timestamp: {
                        created: info.jobInfo.ctime || info.jobInfo.mtime,
                        modified: info.jobInfo.mtime,
                    },
                    numberOf: numbers[info.jobID],
                    currentUserCan: currentUserCan[info.jobID] || {},
                    isShared: info.jobInfo.isShared,
                    size: info.jobInfo.size,
                    hasHEIC: info.jobInfo.has_heic,
                }
            })
            .sort(albumListSortMethods[sortMode ?? 'RECENT_ACTIVITY'])
    },
)

export const getCurrentUserAlbumList = createSelector(
    getFullAlbumListInfo,
    (
        albumList, // just use !canFollow?
    ): AlbumJobInfo[] =>
        albumList.filter((a) => !a.currentUserCan.subscribeToAlbum),
)

export const getRecentlyDeletedAlbums = (state: StateWithJobInfo) => {
    const deleted = Object.keys(state.jobInfo).filter(
        (job) => state.jobInfo[job].status === JobInfoStatus.RECENTLY_DELETED,
    )
    return deleted
}

export const getPrivacyModeFilteredAlbumList = createSelector(
    getCurrentUserAlbumList,
    getAlbumListFilter,
    (albums: AlbumJobInfo[], filter: AlbumListFilter): AlbumJobInfo[] => {
        return albums.filter(privacyModeFilter[filter])
    },
)

export const getNonEmptyAlbumList = createSelector(
    getFullAlbumListInfo,
    (albums): AlbumJobInfo[] =>
        albums.filter((a) => a.numberOf && a.numberOf.files > 0),
)

export const getAlbumsCurrentUserCanAdd = createSelector(
    getCurrentUserAlbumList,
    (albumList): AlbumJobInfo[] =>
        albumList.filter((album) => album.currentUserCan.addPhotos),
)

export const getNextUnfetchedAlbumID = createSelector(
    getFullAlbumListInfo,
    (albumList): JobID | undefined =>
        albumList
            .filter((e) => (e.numberOf ? e.numberOf.files === 0 : true))
            .map((e) => e.id)[0],
)

export const getPendingAlbums = (state: StateWithAlbumList) =>
    state.albumList.pendingAlbums

export const getRecentlyCompletedAlbumFromUploadedFileID = createSelector(
    getFileInformationByUploaderId,
    getEnquedFiles,
    getPendingAlbums,
    (file, enqueuedFiles, albums): AutoGeneratedAlbumInfo | undefined => {
        if (file) {
            const album = albums.filter((a) => a.tempID === file.targetJob)[0]
            if (
                album &&
                enqueuedFiles.every(
                    (f) => f.targetJob !== file.targetJob || f.id === file.id,
                )
            ) {
                return album
            }
        }
    },
)
export const isPendingAutoCreatedAlbum = (
    state: StateWithAlbumList,
    jobID: JobID,
): boolean => {
    return getPendingAlbums(state).some(
        (a) => a.tempID === jobID || a.jobID === jobID,
    )
}
export const havePendingAutoCreatedAlbums = (
    state: StateWithAlbumList,
): boolean => getPendingAlbums(state).length > 0

const albumListStyleMobile = {
    elementHeight: 190,
    elementSpaceAround: 2,
}
const albumListStyleDesktop = {
    elementHeight: 320,
    elementWidth: 296,
    elementSpaceAround: 2,
}

const maxContainerWidth = 1500

export const getAlbumListStyle = createSelector(
    getViewportWidth,
    isMobileMode,
    (screenWidth: number, isMobile: boolean): GridStyle => {
        if (isMobile) {
            return {
                ...albumListStyleMobile,
                elementWidth:
                    screenWidth - albumListStyleMobile.elementSpaceAround * 2,
                width: screenWidth,
            }
        }

        const availableContainerWidth = Math.min(maxContainerWidth, screenWidth)
        const elementsInOneRow = Math.floor(
            availableContainerWidth / albumListStyleDesktop.elementWidth,
        )
        return {
            ...albumListStyleDesktop,
            width:
                elementsInOneRow *
                (albumListStyleDesktop.elementWidth +
                    albumListStyleDesktop.elementSpaceAround * 2),
        }
    },
)

export const getLastViewedAlbumOffset = createSelector(
    getPrivacyModeFilteredAlbumList,
    getIdOfLastSeenAlbum,
    getAlbumListStyle,
    (albums, lastSeenAlbumID, gridStyle): number | undefined => {
        if (lastSeenAlbumID) {
            const albumIndex = albums.map((a) => a.id).indexOf(lastSeenAlbumID)
            const rowHeight =
                gridStyle.elementHeight + gridStyle.elementSpaceAround * 2
            return (
                Math.floor(albumIndex / calcImagesPerRow(gridStyle)) * rowHeight
            )
        }
        return undefined
    },
)
