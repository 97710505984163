import type { SVGProps } from 'react'
const SvgExport = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill={props.color ?? 'currentColor'}
            d="M11 14.825V18c0 .283.096.52.287.712.192.192.43.288.713.288s.52-.096.713-.288A.968.968 0 0 0 13 18v-3.175l.9.9c.1.1.213.175.338.225.125.05.25.07.375.063a1.038 1.038 0 0 0 .688-.312c.182-.201.278-.434.287-.701a.916.916 0 0 0-.288-.7l-2.6-2.6a.877.877 0 0 0-.325-.212 1.106 1.106 0 0 0-.375-.063c-.133 0-.258.02-.375.063a.877.877 0 0 0-.325.212l-2.6 2.6c-.2.2-.296.433-.287.7.008.267.112.5.312.7.2.183.433.28.7.288.267.008.5-.088.7-.288zM6 22c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 4 20V4c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 6 2h7.175a1.975 1.975 0 0 1 1.4.575l4.85 4.85a1.975 1.975 0 0 1 .575 1.4V20c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 18 22zm7-14V4H6v16h12V9h-4a.968.968 0 0 1-.713-.287A.967.967 0 0 1 13 8"
        />
    </svg>
)
export default SvgExport
