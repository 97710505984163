import type { SVGProps } from 'react'
const SvgPhotosAndVideos = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill={props.color ?? 'currentColor'}
            d="M13.25 12.5 12.1 11a.475.475 0 0 0-.4-.2c-.167 0-.3.067-.4.2l-1.675 2.2c-.133.167-.154.342-.062.525.091.183.245.275.462.275h7.95c.217 0 .37-.092.462-.275.092-.183.071-.358-.062-.525l-2.425-3.175a.475.475 0 0 0-.4-.2c-.167 0-.3.067-.4.2zM8 18c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 6 16V4c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 8 2h12c.55 0 1.02.196 1.413.587C21.804 2.98 22 3.45 22 4v12c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 20 18zm0-2h12V4H8zm-4 6c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 2 20V7c0-.283.096-.52.288-.713A.968.968 0 0 1 3 6c.283 0 .52.096.712.287.192.192.288.43.288.713v13h13c.283 0 .52.096.712.288.192.191.288.429.288.712s-.096.52-.288.712A.968.968 0 0 1 17 22z"
        />
    </svg>
)
export default SvgPhotosAndVideos
