import styled from 'styled-components'
import { mediaQueries } from '~/assets/styleConstants'
import { useKeyPress } from '~/utilities/useKeypress'
import { FullscreenOverlay } from './FullscreenOverlay'
import { CenteredElementWrapper } from './LayoutComponents'

const ComponentWrapper = styled(CenteredElementWrapper)`
    max-height: 90%;
    border-radius: 3px;

    background-color: white;
    padding: 16px;
    box-sizing: border-box;
    transition: height 0.3s;

    display: flex;
    flex-direction: column;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    ${mediaQueries.mobile} {
        width: 96%;
        max-width: 400px;
        max-height: 96%;
    }
`

const Content = styled.div`
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
`

type Props = {
    onClose: () => void
    /** Header elements will not be part of the scrollable overflow area. */
    headerElements?: React.ReactNode
    /** Footer elements will not be part of the scrollable overflow area. */
    footerElements?: React.ReactNode
    ignoreCloseOnClickOutside?: boolean
    children?: React.ReactNode
}

export const OverlayModal: React.FunctionComponent<Props> = (props) => {
    useKeyPress(props.onClose, 'Escape', 'keyup')

    return (
        <FullscreenOverlay
            onOverlayClick={
                props.ignoreCloseOnClickOutside ? undefined : props.onClose
            }>
            <ComponentWrapper>
                <div>{props.headerElements}</div>
                <Content>{props.children}</Content>
                <div>{props.footerElements}</div>
            </ComponentWrapper>
        </FullscreenOverlay>
    )
}
