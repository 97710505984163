import type * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import type { BasicViewFile } from '~/state/files/selectors'
import { getVideoURL } from '~/state/files/selectors'

const VideoThumbElement = styled.video.attrs({
    loop: true,
    muted: true,
    preload: 'none',
})`
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
`

type VideoPreviewOwnProps = {
    file: BasicViewFile
    autoPlay?: boolean
    videoRef?: React.RefObject<HTMLVideoElement>
}

// TODO: Required only in FullScreenPhotoOverlay. Check if is still used
export const VideoPreview: React.ComponentType<
    VideoPreviewOwnProps & { ref?: React.Ref<HTMLVideoElement> }
> = connect(
    (
        state: StateOfSelector<typeof getVideoURL>,
        { file }: VideoPreviewOwnProps,
    ) => ({
        src: getVideoURL(state, file.jobID, file.fileID, 'v-low'),
        poster: file.thumbURLLarge,
    }),
    null,
    null,
    { forwardRef: true },
)(VideoThumbElement)
