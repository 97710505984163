import type { AlbumFile } from '~/state/album/selectors'
import type { BasicViewFile } from '~/state/files/selectors'
import type { StateWithJobInfo } from '../jobInfo/reducer'
import type { StateWithContextMenu } from './reducer'

export const getContextMenuTarget = (
    state: StateWithContextMenu,
): BasicViewFile | AlbumFile | undefined => state.contextMenu.targetFile

export const getIsTargetCoverPhoto = (
    state: StateWithContextMenu & StateWithJobInfo,
): boolean => {
    const currentTargetId = state.contextMenu.targetFile?.fileID
    const currentJobId = state.contextMenu.targetFile?.jobID
    if (
        !currentTargetId ||
        !currentJobId ||
        !state.jobInfo[currentJobId].jobInfo ||
        state.jobInfo[currentJobId].jobInfo.type !== 'story'
    )
        return false
    return currentTargetId === state.jobInfo[currentJobId].jobInfo?.coverPhoto
}

export const getSideFileInfoState = (state: StateWithContextMenu): boolean =>
    state.contextMenu.sideInfoMenu
