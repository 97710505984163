import * as React from 'react'
import { isMobileDevice } from '~/utilities/device'

type Props = {
    onLongPress?: () => void
    onLongPressEnd?: () => void
    children: (isPressing: boolean) => React.ReactNode
    pressLength?: number
}

type ComponentState = { isLongPressing: boolean }

export class LongPressProvider extends React.Component<Props, ComponentState> {
    public state = { isLongPressing: false }
    private longTouchTimer?: number

    private startTouch = () => {
        this.longTouchTimer = window.setTimeout(() => {
            this.props.onLongPress?.()
            this.setState({ isLongPressing: true })
        }, this.props.pressLength || 500)
    }

    private stopTouch = () => {
        clearTimeout(this.longTouchTimer)
        if (this.state.isLongPressing) {
            this.setState({ isLongPressing: false })
            this.props.onLongPressEnd?.()
        }
    }

    private handleScroll = () => {
        // Moved the isLongPressing under the clearTimeout to fix the issue with slow scrolling in mobile mode
        if (isMobileDevice.any()) {
            clearTimeout(this.longTouchTimer)
            this.state.isLongPressing &&
                this.setState({ isLongPressing: false })
            return
        }
    }

    public componentDidMount() {
        if (isMobileDevice.any()) {
            document.addEventListener('scroll', this.handleScroll)
        }
    }
    public componentWillUnmount() {
        if (isMobileDevice.any()) {
            document.removeEventListener('scroll', this.handleScroll)
        }
    }
    public render() {
        if (!isMobileDevice.any()) {
            return this.props.children(false)
        }

        return (
            <span onTouchStart={this.startTouch} onTouchEnd={this.stopTouch}>
                {this.props.children(this.state.isLongPressing)}
            </span>
        )
    }
}
