import type { DeletedFileResponse } from '~/@types/backend-types'
import { createActionCreator, createVoidActionCreator } from '../common/actions'

export const TrashLoadingStarted = createVoidActionCreator(
    'TRASH_LOADING_STARTED',
)
export const TrashLoadingSucceeded = createActionCreator<DeletedFileResponse>(
    'TRASH_LOADING_SUCCEEDED',
)
export const TrashLoadingFailed = createVoidActionCreator(
    'TRASH_LOADING_FAILED',
)
export const TrashContentOutdated = createVoidActionCreator(
    'TRASH_CONTENT_OUTDATED',
)

export const TrashFileDeleted =
    createActionCreator<FileID>('TRASH_FILE_DELETED') // use file removed already in use?
export const TrashFileDeleteFailed = createActionCreator<FileID>(
    'TRASH_FILE_DELETE_FAILED',
)
export const TrashFilesDeletionStarted = createActionCreator<FileID[]>(
    'TRASH_FILES_DELETION_STARTED',
)
export const TrashFilesDeletionSucceeded = createActionCreator<FileID[]>(
    'TRASH_FILES_DELETION_SUCCEEDED',
)
export const TrashFilesDeletionFailed = createActionCreator<FileID[]>(
    'TRASH_FILES_DELETION_FAILED',
)

export const FetchMoreTrashTriggered = createVoidActionCreator(
    'FETCH_MORE_TRASH_TRIGGERED',
)

// Trash album page
export const TrashAlbumsRestoreSuccess = createActionCreator<JobID[]>(
    'TRASH_ALBUMS_RESTORE_SUCCESS',
)

export const TrashAlbumsRestoreFailed = createVoidActionCreator(
    'TRASH_ALBUMS_RESTORE_FAILED',
)

export const TrashAlbumsDeleteSuccess = createActionCreator<JobID[]>(
    'TRASH_ALBUMS_DELETE_SUCCESS',
)

export const TrashAlbumsDeleteFailed = createVoidActionCreator(
    'TRASH_ALBUMS_DELETE_FAILED',
)
