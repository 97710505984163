import type { Action } from '@reduxjs/toolkit'
import { isType } from '~/state/common/actions'
import { getDeviceProps } from '~/config/constants'
import { RoutePath } from '~/routing/routePath'
import { FetchedAccountInfo, UserLoggedOut } from '~/state/currentUser/actions'
import { makeDecomposedPromise } from '~/utilities/promises'
import { getAnalyticsSafeURL } from '~/utilities/urlParsing'
import type { EventTracker } from '../eventTracking'

declare global {
    interface Window {
        Leanplum: LPObject
    }
}

const LeanplumAwaiter = makeDecomposedPromise<LPObject>()
let haveInjectedScript = false
const injectScriptTag = () => {
    const a = document.createElement('script')
    a.src =
        'https://cdn.jsdelivr.net/npm/leanplum-sdk@1.8.2/dist/leanplum.min.js'
    a.onload = () => {
        LeanplumAwaiter.resolve(window.Leanplum)
    }
    const m = document.getElementsByTagName('script')[0]
    if (m?.parentNode) {
        m.parentNode.insertBefore(a, m)
    }
    haveInjectedScript = true
}

type LPObject = {
    // TODO: Get real types?
    setAppIdForDevelopmentMode: (appID: string, apiKey: string) => void
    setAppIdForProductionMode: (appID: string, apiKey: string) => void
    setDeviceId: (id: string) => void
    start: (user: string, callback: (success: boolean) => void) => void
    advanceTo: (page: string) => void
    track: (event: string) => void
    stop: () => void
}

const eventMap: DictionaryOf<
    DictionaryOf<string | ((label?: string, value?: number) => string)>
> = {
    AlbumList: {
        ShareAlbum_click: 'Album shared from album list',
        CreateAlbumButton_click: (label) =>
            `Clicked button to create a ${
                label === 'PrivateAlbum' ? 'private' : 'shared'
            } album from album list`,
    },
    AlbumPage: {
        AddPhotosButton_click: (label) =>
            label === 'Logged_in_user_Shared_album'
                ? 'Clicked button to add photos to a shared album'
                : 'Clicked button to add photos to a private album',
        SelectionLinkCopied: 'Shared photos by link copy from an album',
        SelectionSharedToEmail: 'Shared photos to email from an album',
    },
    CarouselView: {
        SingleFileDownload: 'Single file downloaded from carousel view',
        ItemAddedToAlbum: 'Added photo to album from carousel view',
        SelectionLinkCopied: 'Shared photo by link copy from carousel view',
        SelectionSharedToEmail: 'Shared photo to email from carousel view',
    },
    CreateAlbum: {
        ConfirmCreateAlbumClicked: (label) =>
            `Finished creation of a ${
                label === 'PrivateAlbum' ? 'private' : 'shared'
            } album`,
    },
    ShareAlbum: {
        shareAlbumClicked: 'Album-link shared from album page',
    },
    TimelinePage: {
        MultiFileDownload: 'Downloaded a selection of photos from timeline',
        PaginatedDownload:
            'Downloaded a part of a selection of photos from timeline',
        SelectionAddedToAlbum: 'Added photos to album from timeline',
        SelectionLinkCopied: 'Shared photos by link copy from timeline',
        SelectionSharedToEmail: 'Shared photos by email from timeline',
        ScrollerMonthSelected: 'Selected a month in the fastscroller',
        ScrollerMonthDeselected: 'Deselected a month in the fastscroller',
    },
    Uploader: {
        FilesAddedBySelection: 'Uploaded files by selection',
        FilesAddedByDrop: 'Uploaded files by drag and drop',
        FilesAddedToAlbumByDrop: 'Uploaded files by drag and drop',
        FoldersAddedByDrop: 'Uploaded folders by drag and drop',
        PhotoEditorExport: 'Uploaded an edited photo',
    },
}

export class LeanplumTracker implements EventTracker {
    private readonly onUsername: (username: string) => void
    private LPObj: undefined | LPObject
    constructor(
        private appID: string,
        private apiKey: string,
    ) {
        const _username = makeDecomposedPromise<string>()
        this.onUsername = _username.resolve
        ;(async () => {
            const LPObj = await LeanplumAwaiter.promise
            const username = await _username.promise

            if (import.meta.env.DEV && this.apiKey.match(/^dev_.*$/)) {
                LPObj.setAppIdForDevelopmentMode(this.appID, this.apiKey)
            } else {
                LPObj.setAppIdForProductionMode(this.appID, this.apiKey)
            }

            LPObj.setDeviceId(getDeviceProps().device_id)
            LPObj.start(username, (success: boolean) => {
                if (success) {
                    LPObj.track('Web: User entered app')
                    this.LPObj = LPObj
                }
            })
        })()
    }

    public handleTrackingConsentGranted() {
        if (!haveInjectedScript) {
            injectScriptTag()
        }
    }

    public trackEvent(
        context: string,
        event: string,
        label?: string,
        value?: number,
    ) {
        const t = eventMap[context]?.[event]
        if (this.LPObj && t) {
            this.LPObj.track(
                `Web: ${typeof t === 'function' ? t(label, value) : t}`,
            )
        }
    }

    private lastPageSent = ''
    public trackPageChange(newPath: string, hash?: string) {
        const currentPage = getAnalyticsSafeURL(newPath) + hash
        if (this.LPObj && this.lastPageSent !== currentPage) {
            if (
                newPath.match(
                    new RegExp(RoutePath.getTimelineCarouselPath('.*')),
                )
            ) {
                this.LPObj.track('Web: Entered carousel view from timeline')
            }
            if (
                newPath.match(
                    new RegExp(RoutePath.getAlbumCarouselPath('.*', '.*')),
                )
            ) {
                this.LPObj.track('Web: Entered carousel view from album')
            }

            if (newPath === RoutePath.Albums) {
                this.LPObj.track('Web: Opened album list')
            }
            if (
                newPath.match(new RegExp(RoutePath.getLoggedInAlbumPath('.*')))
            ) {
                this.LPObj.track('Web: Opened album')
            }

            this.lastPageSent = currentPage
            // this.LPObj.advanceTo('Web.location: ' + currentPage);
        }
    }

    public trackUserID(_userID: string) {
        // This is not the right userID - get from FetchedAccountInfo-action
    }

    public trackAction(action: Action, _getState: () => unknown) {
        if (isType(action, FetchedAccountInfo)) {
            this.onUsername(action.payload.name)
        }
        if (isType(action, UserLoggedOut) && this.LPObj) {
            this.LPObj.track('Web: User logged out')
            this.LPObj.stop()
            this.LPObj = undefined
        }
    }
}
