export const wrappedIn =
    (Outer: React.ComponentType<{ children?: React.ReactNode }>) =>
    <T extends object>(
        Inner: React.ComponentType<T>,
    ): React.FunctionComponent<T> =>
        function WrappedInComponent(props) {
            return (
                <Outer>
                    <Inner {...props} />
                </Outer>
            )
        }
