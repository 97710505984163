import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import SvgAvatar from '@capture/capture-components/src/icons/Avatar'
import SvgCheck from '@capture/capture-components/src/icons/Check'
import type { Dispatch } from '~/state/common/actions'
import { _ } from '~/assets/localization/util'
import { colors } from '~/assets/styleConstants'
import {
    EmailFormClosed,
    EmailFormOpened,
} from '~/state/carouselViewer/actions'
import { isEmail } from '~/utilities/inputValidation'
import { Button } from '../Common/Button'
import { FilledTextButton } from '../Common/IconTextButton'
import { TextInputFormRow } from '../Common/TextInputFormRow'

const Wrapper = styled.div`
    width: ${(props: { isMobile: boolean }) =>
        props.isMobile ? '100%' : '400px'};
    position: relative;
`

export type EmailSharingOptions = {
    recipients: string
    subject: string
    message: string
}

type OwnProps = {
    isMobile: boolean
    onConfirm: (options: EmailSharingOptions) => void
}

type DispatchProps = {
    openedEmailForm: () => void
    closedEmailForm: () => void
}

type Props = DispatchProps & OwnProps

type FormField = {
    value: string
    isValid?: boolean
}
type State = Record<keyof EmailSharingOptions, FormField>

export class _ShareToEmailForm extends React.Component<Props, State> {
    public state: State = {
        recipients: { value: '' },
        subject: { value: '' },
        message: { value: '', isValid: true }, // optional field
    }

    public componentDidMount() {
        this.props.openedEmailForm()
    }

    public componentWillUnmount() {
        this.props.closedEmailForm()
    }

    private validateAllFormFields = (): boolean => {
        const isValidRecipients = this.isRecipientValid(
            this.state.recipients.value,
        )
        const isValidSubject = this.isSubjectValid(this.state.subject.value)

        this.setState({
            ...this.state,
            recipients: {
                ...this.state.recipients,
                isValid: isValidRecipients,
            },
            subject: {
                ...this.state.subject,
                isValid: isValidSubject,
            },
        })

        return isValidRecipients && isValidSubject
    }

    private isRecipientValid = (value: string): boolean => {
        return value.split(',').every(isEmail)
    }

    private isSubjectValid = (value: string): boolean => {
        return value !== ''
    }

    private isAllFieldsValid = (): boolean => {
        return Object.keys(this.state).every(
            (k) => this.state[k as keyof EmailSharingOptions].isValid === true,
        )
    }

    private handleRecipientsChanged = (newValue: string) => {
        this.setState({
            ...this.state,
            recipients: {
                value: newValue,
                isValid: this.isRecipientValid(newValue),
            },
        })
    }

    private handleSubjectChanged = (newValue: string) => {
        this.setState({
            ...this.state,
            subject: {
                value: newValue,
                isValid: this.isSubjectValid(newValue),
            },
        })
    }

    private handleMessageChanged = (newValue: string) => {
        this.setState({
            ...this.state,
            message: {
                value: newValue,
                isValid: true,
            },
        })
    }

    private handleEmailOptionsConfirmed = () => {
        if (this.validateAllFormFields()) {
            this.props.onConfirm({
                recipients: this.state.recipients.value,
                subject: this.state.subject.value,
                message: this.state.message.value,
            })
        }
    }

    public render() {
        const isFormComplete = this.isAllFieldsValid()
        const confirmButton = Button(
            _('done'),
            this.handleEmailOptionsConfirmed,
            {
                icon: SvgCheck,
                isDisabled: !isFormComplete,
            },
        )
        return (
            <Wrapper isMobile={this.props.isMobile}>
                <TextInputFormRow
                    title={_('send_email_to')}
                    isInvalid={this.state.recipients.isValid === false}
                    value={this.state.recipients.value}
                    placeholder={_('enter_receivers_address')}
                    icon={
                        <SvgAvatar
                            width={16}
                            height={16}
                            color={colors.captureGrey800}
                        />
                    }
                    onChange={this.handleRecipientsChanged}
                />
                <TextInputFormRow
                    title={_('email_subject')}
                    isInvalid={this.state.subject.isValid === false}
                    value={this.state.subject.value}
                    placeholder={_('enter_subject')}
                    onChange={this.handleSubjectChanged}
                />
                <TextInputFormRow
                    title={_('email_message')}
                    value={this.state.message.value}
                    placeholder={_('enter_message')}
                    multiLine={true}
                    onChange={this.handleMessageChanged}
                />
                <FilledTextButton {...confirmButton} />
            </Wrapper>
        )
    }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    openedEmailForm: () => {
        dispatch(EmailFormOpened())
    },
    closedEmailForm: () => {
        dispatch(EmailFormClosed())
    },
})

export const ShareToEmailForm = connect(
    null,
    mapDispatchToProps,
)(_ShareToEmailForm)
