import { createSelector } from 'reselect'
import { isFileRecent } from '~/utilities/dateOperations'
import type { CaptureFile } from '../files/selectors'
import type { StateWithRecentFiles } from './recentFilesSlice'
import { recentFilesAdapter } from './recentFilesSlice'

export const getRecentFilesStatus = (state: StateWithRecentFiles) =>
    state.recentFiles.status

export const getRecentFiles = (state: StateWithRecentFiles) =>
    recentFilesAdapter.getSelectors().selectAll(state.recentFiles.files)

export type RecentCounts = {
    '1': number
    '7': number
    '30': number
}

export const getRecentFilesCounts: (
    state: StateWithRecentFiles,
) => RecentCounts = createSelector(getRecentFiles, (files: CaptureFile[]) => {
    const counts = {
        '1': 0,
        '7': 0,
        '30': 0,
    }
    for (let index = 0; index < files.length; index++) {
        const file = files[index]
        if (isFileRecent(file, 1)) counts[1] += 1
        if (isFileRecent(file, 7)) counts[7] += 1
        if (isFileRecent(file, 30)) counts[30] += 1
    }
    return counts
})
