import type { FileGroupType, JobFile } from '~/@types/backend-types'
import { ThumbService } from '~/API/services/ThumbService'
import { getGroupSelectionStatus } from '~/components/TimelineScroller/makeScrollerGroups'
import type { FileMetadataState } from '~/state/fileMetadata/reducer'
import type { FileDescription } from '~/state/job/actions'
import { cachedInArray, getRange, groupArray } from '~/utilities/arrayUtils'
import {
    isFileRecent,
    makeMonthGroupHeaderByFile,
} from '~/utilities/dateOperations'
import { FileTarget } from '~/utilities/fileTarget'
import type { ImageGroupStyle } from '~/utilities/imageGroupStyle'
import { computeImageGroupHeight } from '~/utilities/imageGroupStyle'
import type { ExtendedJobFile } from '../files/reducer'
import { CommentStatus } from '../files/reducer'
import type { CaptureFile, FileWithGrouping } from '../files/selectors'
import { getFileType } from '../files/selectors'
import type { ImageGroup } from '../timeline/selectors'

// We have a couple of file types used in diverse cases through the client:
// - JobFile is what we get from the API requests
// - FileDescription adds the user info and changes the shape
// - ExtendedJobFile changes the shape again based on the file being a master file for a live/burst and adds several new fields
// - CaptureFile that adds more fields like thumbPaths fileType and file dimensions

// These filetypes are created and spreaded through the selectors. The folowing functions are just some
// file transformes extracted for convenience from the selectors.

const makeFileDescription = (
    file: JobFile,
    jobID: JobID,
    user_uuid: UserID,
): FileDescription => ({
    ...file,
    jobID,
    fileID: file.id,
    user_uuid,
})

const makeExtendedJobFile = (file: FileDescription): ExtendedJobFile => {
    const group =
        file.group_type !== undefined && file.group_id !== undefined
            ? {
                  type: file.group_type,
                  id: file.group_id,
                  isMaster: file.master === '1',
              }
            : undefined
    return {
        ...file,
        addedBy: file.user_uuid,
        pendingComment: '',
        pendingCommentStatus: CommentStatus.NOT_YET_SUBMITTED,
        isPendingServerDelete: false,
        isDeleted: false,
        group,
    }
}

const makeCaptureFile = (
    file: ExtendedJobFile,
    thumbHost: string,
    authToken: string,
    timelineId: JobID,
    metadata?: FileMetadataState,
): CaptureFile => {
    const service = new ThumbService(thumbHost, authToken)

    const smallThumb = service.getThumbUrl(timelineId, file.fileID, 256)
    const mediumThumb = service.getThumbUrl(timelineId, file.fileID, 512)
    const largeThumb = service.getThumbUrl(timelineId, file.fileID, 1280)

    const needsBiggerThumb = false
    if (file.width && file.height) {
        file.width > file.height * 2 || file.height > file.width * 2
    }

    return {
        ...file,
        type: getFileType(file),
        thumbURLSmall: needsBiggerThumb ? mediumThumb : smallThumb,
        thumbURLMedium: mediumThumb,
        thumbURLLarge: largeThumb,
        metadata: metadata?.[file.fileID],
    }
}

export const filesDescriptionToCaptureFiles = (
    timelineId: string,
    thumbHost: string,
    authToken: string,
    files: FileDescription[],
) => {
    const captureFiles: CaptureFile[] = []
    for (let index = 0; index < files.length; index++) {
        const file = files[index]
        const extendedFile = makeExtendedJobFile(file)
        const captureFile = makeCaptureFile(
            extendedFile,
            thumbHost,
            authToken,
            timelineId,
        )
        captureFiles.push(captureFile)
    }
    return captureFiles
}

export const jobFilesToCaptureFiles = (
    timelineId: string,
    thumbHost: string,
    authToken: string,
    userId: string,
    files: JobFile[],
) => {
    const captureFiles: CaptureFile[] = []
    for (let index = 0; index < files.length; index++) {
        const jobFile = files[index]
        const fileDescription = makeFileDescription(jobFile, timelineId, userId)
        const exendedFile = makeExtendedJobFile(fileDescription)
        const captureFile = makeCaptureFile(
            exendedFile,
            thumbHost,
            authToken,
            timelineId,
        )
        captureFiles.push(captureFile)
    }
    return captureFiles
}

/**
 * Takes an array of Capture files and prepares the burst and live files for timeline
 * @param files CaptureFile[]
 * @returns Array<FileWithGrouping<CaptureFile>>
 */
export const makeTimelineFiles = (
    files: CaptureFile[],
): Array<FileWithGrouping<CaptureFile>> => {
    // From getJobGroupFilesDict
    const initGroupsDict: DictionaryOf<{
        type: FileGroupType
        masterID: FileID
        files: CaptureFile[]
    }> = {}

    const looseFilesDict: DictionaryOf<CaptureFile[]> = {}

    files.forEach((f) => {
        if (f.group) {
            if (f.group.isMaster) {
                initGroupsDict[f.group.id] = {
                    type: f.group.type,
                    masterID: f.fileID,
                    files: [],
                }
            } else {
                looseFilesDict[f.group.id] = [
                    ...(looseFilesDict[f.group.id] || []),
                    f,
                ]
            }
        }
    })

    const groupDict: DictionaryOf<{
        type: FileGroupType
        masterID: FileID
        files: CaptureFile[]
    }> = {}

    Object.keys(initGroupsDict).forEach((k) => {
        groupDict[k] = {
            ...initGroupsDict[k],
            files: looseFilesDict[k] || [],
        }
    })

    const masterOnlyFiles = files.filter(
        (capFile) =>
            (capFile.type === FileTarget.Pictures ||
                capFile.type === FileTarget.Movies) &&
            (capFile.group === undefined || capFile.group.isMaster),
    )

    return masterOnlyFiles.map((file) => {
        let burstFiles
        let livePhotoFile

        if (file.group && groupDict && groupDict[file.group.id]) {
            if (file.group.type === 'live') {
                livePhotoFile = groupDict[file.group.id].files[0]
            } else if (file.group.type === 'burst') {
                burstFiles = groupDict[file.group.id].files
            }
        }

        return {
            ...file,
            burstFiles,
            livePhotoFile,
        }
    })
}

/**
 * Processes CaptureFiles into monthly groups to be consumed by the timeline page
 * @param files
 * @param groupStyle
 * @returns ImageGroup[]
 */
export const makeTimelineGroups = (
    files: Array<FileWithGrouping<CaptureFile>>,
    groupStyle: ImageGroupStyle,
): ImageGroup[] => {
    const timelineImages: DictionaryOf<CaptureFile[]> = groupArray(
        files,
        makeMonthGroupHeaderByFile,
    )
    const groupKeys = new Set([...Object.keys(timelineImages)])
    let accumulatHeight = groupStyle.verticalOffset || 0
    return Array.from(groupKeys)
        .sort((a, b) => b.localeCompare(a))
        .map((groupKey: string): ImageGroup => {
            const _imgs = timelineImages[groupKey] || []
            const imageCount = _imgs.length
            const images =
                _imgs.length < imageCount
                    ? getRange(imageCount).map((i) => _imgs[i])
                    : _imgs
            const height = computeImageGroupHeight(imageCount, groupStyle)
            const position = accumulatHeight
            accumulatHeight += height

            return {
                groupKey,
                images,
                height,
                position,
            }
        })
}

export const addSelectedStatusToTimelineGroups = (
    groups: ImageGroup[],
    selectedFiles: FileID[],
) => {
    const isFileSelected = cachedInArray(selectedFiles)
    return groups.map((group) => {
        const selectedGroupFiles = group.images.filter((f) =>
            f ? isFileSelected(f.fileID) : false,
        )
        return {
            ...group,
            selectionStatus: getGroupSelectionStatus(
                selectedGroupFiles.length,
                group.images.length,
            ),
        }
    })
}

export const filterRecentFiles = (files: CaptureFile[], days: number) => {
    files.filter((file) => isFileRecent(file, days))
}
