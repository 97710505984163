import * as React from 'react'
import styled from 'styled-components'
import type { CaptureFile } from '@capture/client/state/files/selectors'
import type { GridStyle } from '@capture/client/utilities/gridElementSizeCalculator'
import ThumbSectionItem from './ThumbSectionItem'

type ThumbWrapperProps = { width: number; alignCentered: boolean }
const ThumbWrapper = styled.div`
    margin: 0 auto;
    width: ${(props: ThumbWrapperProps) => props.width}px;
    margin: 16px auto 24px auto;

    display: flex;
    flex-wrap: wrap;
    justify-content: ${(props) =>
        props.alignCentered ? 'center' : 'flex-start'};
`

type Props = {
    files: CaptureFile[]
    gridStyle: GridStyle
    enterCarouselView: (fileID: FileID) => void
    isMobileDisplay: boolean
}

export const ThumbSection: React.FunctionComponent<Props> = (props) => {
    const { width: _omit, ...gridStyleProps } = props.gridStyle
    const photos = props.files.map((photo) => {
        return (
            <ThumbSectionItem
                key={photo.fileID}
                file={photo}
                gridElementStyle={gridStyleProps}
                onThumbClicked={props.enterCarouselView}
            />
        )
    })

    return (
        <ThumbWrapper
            width={props.gridStyle.width}
            alignCentered={props.files.length <= 4}>
            {photos}
        </ThumbWrapper>
    )
}
