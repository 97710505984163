/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    CAPBAKHTTPValidationError,
    CAPBAKPostCumulusEventsResponse,
    CAPBAKUserStatisticsEventsPostParams,
    CAPBAKUserStatsEventsModel,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class UserStatisticsEvents<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * @description This call adds user statistics events
     *
     * @tags client_stats
     * @name UserStatisticsEventsPost
     * @summary Post User Statistics Events
     * @request POST:/user_statistics_events
     */
    userStatisticsEventsPost = (
        query: CAPBAKUserStatisticsEventsPostParams,
        data: CAPBAKUserStatsEventsModel,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKPostCumulusEventsResponse,
            void | CAPBAKHTTPValidationError
        >({
            path: `/user_statistics_events`,
            method: 'POST',
            query: query,
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
        })
}
