import { RoutePath } from '~/routing'
import type { PageDescription } from '~/utilities/navigation'

/**
 * Tool-method to parse `window.location.search`
 * use: `const searchParams ? parseSearchString(window.location.search)`;
 *
 * @param {string} search
 * @returns {DictionaryOf<string>}
 */
export const parseSearchString = (search: string): DictionaryOf<string> => {
    return search
        .substring(1)
        .split('&')
        .reduce((dict: DictionaryOf<string>, kv: string) => {
            const [key, value] = kv.split('=')
            if (value !== undefined) {
                dict[key] = decodeURIComponent(value)
            }
            return dict
        }, {})
}

const capturedSegment = '([^/]+)'
const _ = '[^/]+'
const getJobPartOfUrl = (url: URLstring): RegExpMatchArray | null =>
    url.match(RoutePath.getLoggedInAlbumPath(capturedSegment)) ||
    url.match(RoutePath.getNotLoggedInAlbumPath(capturedSegment)) ||
    url.match(RoutePath.getEditAlbumPath(capturedSegment, _)) ||
    url.match(RoutePath.getCreateAlbumLoginPath(capturedSegment)) ||
    url.match(RoutePath.getCopyAlbumLoginPath(capturedSegment)) ||
    url.match(RoutePath.getCopyAlbumFileLoginPath(capturedSegment, _)) ||
    url.match(RoutePath.getAlbumFileCommentsPath(capturedSegment, _)) ||
    url.match(RoutePath.morePhotosAlbum(capturedSegment)) ||
    url.match(RoutePath.morePhotosCreateAlbum(capturedSegment)) ||
    url.match(RoutePath.getShareReceiverPath(capturedSegment)) ||
    url.match(RoutePath.getCopyShareLoginPath(capturedSegment)) ||
    url.match(RoutePath.getDownloadSharePath(capturedSegment)) ||
    url.match(RoutePath.getAlbumCarouselPath(capturedSegment, _)) ||
    url.match(RoutePath.getSelectForExternalEndPath(capturedSegment)) ||
    url.match(RoutePath.getAlbumImageAsSharePath(capturedSegment, _))

const getFileIDPartOfUrl = (url: URLstring): RegExpMatchArray | null =>
    url.match(RoutePath.getCopyAlbumFileLoginPath(_, capturedSegment)) ||
    url.match(RoutePath.getAlbumFileCommentsPath(_, capturedSegment)) ||
    url.match(RoutePath.getAlbumCarouselPath(_, capturedSegment)) ||
    url.match(RoutePath.getTimelineCarouselPath(capturedSegment)) ||
    url.match(RoutePath.getAlbumImageAsSharePath(_, capturedSegment))

export const getAnalyticsSafeURL = (url: string) => {
    const jobPart = getJobPartOfUrl(url)
    if (jobPart !== null) {
        url = url.replace(jobPart[1], '<hidden_job_id>')
    }

    const filePart = getFileIDPartOfUrl(url)
    if (filePart !== null) {
        url = url.replace(filePart[1], '<hidden_file_id>')
    }

    return url
}

export const getAbsoluteURLOfPage = (page: PageDescription): URLstring =>
    [
        window.location.origin,
        import.meta.env.BASE_URL,
        page.url.replace(/^\/*/, ''),
        page.queryParams ? `?${page.queryParams}` : '',
        page.hash ? `#${page.hash}` : '',
    ].join('')
