import * as React from 'react'
import { connect } from 'react-redux'
import { doLogin } from '~/API/login'
import { LoadingPage } from '~/components/Common/LoadingPage'
import { fetchAccount } from '~/components/Common/RequireLoggedIn'
import { isLoggedIn, isLoggingIn } from '~/state/currentUser/selectors'
import type { PageDescription, WithRouterProps } from '~/utilities/navigation'
import { withRouter } from '~/utilities/navigation'
import { Timeline } from './pages'

type StateProps = {
    isLoggingIn: boolean
    isLoggedIn: boolean
}

const mapStateToProps = (
    state: StateOfSelector<typeof isLoggingIn> &
        StateOfSelector<typeof isLoggedIn>,
): StateProps => ({
    isLoggingIn: isLoggingIn(state),
    isLoggedIn: isLoggedIn(state),
})

const RedirectOrLoginComponent = (target: PageDescription) =>
    withRouter(
        connect(
            mapStateToProps,
            null,
        )(
            class RedirectOrLogin extends React.Component<
                StateProps & WithRouterProps
            > {
                goTo = (page: PageDescription) =>
                    this.props.navigate(page.url, { replace: true })

                handleLoginSetlement = (loggedIn: boolean) => {
                    if (loggedIn) {
                        this.goTo(target)
                    } else {
                        doLogin()
                    }
                }

                public componentDidMount() {
                    if (!this.props.isLoggingIn) {
                        this.handleLoginSetlement(this.props.isLoggedIn)
                    }
                }

                public componentDidUpdate(
                    prevProps: StateProps & WithRouterProps,
                ) {
                    if (prevProps.isLoggingIn && !this.props.isLoggingIn) {
                        this.handleLoginSetlement(this.props.isLoggedIn)
                    }
                }

                public render() {
                    return <LoadingPage />
                }
            },
        ),
    )

export const RedirectToTimelineOrLogin = fetchAccount(
    RedirectOrLoginComponent(Timeline),
)
