import * as React from 'react'
import styled from 'styled-components'
import { colors } from '~/assets/styleConstants'
import { AnimatedGradient } from '../Common/AnimatedGradient'

const ProgressDiv = styled.div`
    will-change: transform;
    width: 100%;
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    transform-origin: 0 0;
    height: 100%;
    overflow-x: hidden;
    transition: transform 0.3s linear;
    animation-fill-mode: forwards;
    transform: scaleX(${(props: { progress: number }) => props.progress});
`

const Gray = styled.div`
    background: ${colors.captureGrey600};
    width: 100%;
    height: 100%;
`

type Props = {
    progress: number
    shouldBeGray?: boolean
    runAnimation: boolean
}
export class UploadStatusBar extends React.Component<Props> {
    public render() {
        const animatedGradient = (
            <AnimatedGradient
                color1={colors.gradientGreen}
                color2={colors.captureBlue}
                iterationCount={this.props.runAnimation ? 'infinite' : '1'}
            />
        )
        return (
            <ProgressDiv progress={this.props.progress}>
                {this.props.shouldBeGray ? <Gray /> : animatedGradient}
            </ProgressDiv>
        )
    }
}
