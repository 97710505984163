import { useEffect } from 'react'

import styled from 'styled-components'
import { trackEventInternal } from '~/analytics/eventTracking'
import { HOME_SITE_ADDRESS } from '~/config/constants'
import { _ } from '~/assets/localization/util'
import { colors } from '~/assets/styleConstants'
import { TelenorCaptureFooter } from '../Common/TelenorCaptureFooter'
import { CaptureIconInactive } from '../Icons/CaptureLogo'

const Container = styled.div`
    min-height: 100vh;
    height: 100vh;
    width: 100%;
    padding: 48px 16px 16px;
    box-sizing: border-box;
    display: block;
`

const Content = styled.div`
    display: table;
    height: 65%;
    width: 100%;
`

const Wrapper = styled.div`
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    padding: 0 30px;
`

const Title = styled.div`
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin: 31px auto 0;
    width: 100%;
    display: inline-block;
`

const Description = styled.div`
    font-size: 14px;
    margin: 44px auto 0;
    width: 100%;
    display: inline-block;
`

const ExtLink = styled.a`
    width: 100%;
    display: inline-block;
    font-size: 14px;
    color: ${colors.captureBlue};
    text-align: center;
    text-decoration: underline;
    margin-top: 45px;
`
const LogoWrapper = styled.div`
    width: 100%;
    display: inline-block;
`

export const SuggestModernBrowser = () => {
    useEffect(() => {
        trackEventInternal('legacy_browser', {
            ua: navigator.userAgent,
        })
    }, [])

    return (
        <Container>
            <Content>
                <Wrapper>
                    <LogoWrapper>
                        <CaptureIconInactive size={78} />
                    </LogoWrapper>
                    <Title>{_('upgrade_your_browser')}</Title>
                    <Description>{_('upgrade_your_browser_des')}</Description>
                    <ExtLink href={HOME_SITE_ADDRESS}>
                        {_('capture_more_link')}
                    </ExtLink>
                </Wrapper>
            </Content>
            <TelenorCaptureFooter />
        </Container>
    )
}
