import type { SVGProps } from 'react'
const SvgRestore = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill={props.color ?? 'currentColor'}
            d="M12 22c-1.25 0-2.42-.238-3.512-.712a9.149 9.149 0 0 1-2.85-1.925 9.147 9.147 0 0 1-1.925-2.85A8.707 8.707 0 0 1 3 13c0-.283.096-.52.288-.713A.967.967 0 0 1 4 12c.283 0 .52.096.713.287.191.192.287.43.287.713 0 1.95.68 3.604 2.037 4.962C8.397 19.322 10.05 20 12 20c1.95 0 3.604-.68 4.962-2.038C18.322 16.604 19 14.95 19 13c0-1.95-.68-3.604-2.038-4.962C15.604 6.679 13.95 6 12 6h-.15l.85.85c.2.2.296.433.288.7-.009.267-.105.5-.288.7-.2.2-.437.304-.712.313a.93.93 0 0 1-.713-.288L8.7 5.7a.96.96 0 0 1-.3-.7c0-.267.1-.5.3-.7l2.575-2.575a.93.93 0 0 1 .713-.287c.274.008.512.112.712.312.183.2.28.433.288.7a.916.916 0 0 1-.288.7l-.85.85H12c1.25 0 2.42.238 3.512.713a9.147 9.147 0 0 1 2.85 1.925 9.149 9.149 0 0 1 1.926 2.85A8.707 8.707 0 0 1 21 13c0 1.25-.238 2.42-.712 3.512a9.148 9.148 0 0 1-1.925 2.85 9.148 9.148 0 0 1-2.85 1.926A8.707 8.707 0 0 1 12 22"
        />
    </svg>
)
export default SvgRestore
