import { PRODUCT_NAME } from '~/config/constants'
import type { DownloaderPage } from '~/state/downloader/reducer'
import type { ExtendedJobFile } from '~/state/files/reducer'
import { getFileTime } from './dateOperations'
import { getFileSize } from './fileSize'
import { isHEICFile } from './fileTarget'

type FileInfoForSplitByYear = Pick<
    ExtendedJobFile,
    'ctime' | 'mtime' | 'size' | 'path'
>
/**
 * Split the given array of files into groups based on their creation year.
 *
 * @param fileInfos - The file infos to be grouped by creation year.
 * @returns An object containing the files grouped by their creation year, where the keys are the years and the values are arrays of `FileInfoForSplitByYear` objects.
 */
export const splitFilesByYear = <TFileInfo extends FileInfoForSplitByYear>(
    fileInfos: TFileInfo[],
): Record<number, TFileInfo[]> => {
    const filesByYear: Record<number, TFileInfo[]> = {}

    for (let i = 0; i < fileInfos.length; i++) {
        const file = fileInfos[i]
        const year = getFileTime(file).getFullYear()
        const size = getFileSize(file)

        if (size === 0 || file.path.endsWith('/')) {
            continue
        }

        const yearFiles = filesByYear[year]

        if (yearFiles) {
            yearFiles.push(file)
        } else {
            filesByYear[year] = [file]
        }
    }

    return filesByYear
}

type FileInfoForSplitToGroups = FileInfoForTotalSize &
    Pick<ExtendedJobFile, 'path'>
/**
 * Split the given array of files into groups, where each group contains files with a total size less than or equal to the specified minimum group size.
 *
 * @param fileInfos - The files to be split into groups.
 * @param maxGroupSize - The minimum total size of files in each group.
 * @returns An array of file groups, where each group is an array of `FileInfoForTotalSize` objects.
 */
export const splitFilesIntoGroups = (
    fileInfos: FileInfoForSplitToGroups[],
    maxGroupSize: number,
): FileInfoForSplitToGroups[][] => {
    const groups: FileInfoForSplitToGroups[][] = []
    let currentGroup: FileInfoForSplitToGroups[] = []
    let currentGroupSize = 0

    if (fileInfos.length === 0) return groups

    for (let i = 0; i < fileInfos.length; i++) {
        const file = fileInfos[i]
        const size = getFileSize(file)

        if (currentGroup.length === 0) {
            currentGroup = [file]
            currentGroupSize = size
        } else if (currentGroupSize + size > maxGroupSize) {
            groups.push(currentGroup)
            currentGroup = [file]
            currentGroupSize = size
        } else {
            currentGroup.push(file)
            currentGroupSize += size
        }
    }
    groups.push(currentGroup)
    return groups
}

/**
 * Split the given files into downloader pages, where each page contains a group of files that meet the specified minimum group size.
 *
 * @param fileInfos - The files to split into downloader pages.
 * @param minGroupSize - The minimum size of each group of files.
 * @param name - The name of the downloader page.
 * @returns The downloader pages, where each page contains a group of files with their total size, a unique name, and a clicked status.
 */
export const splitFilesIntoDownloaderPages = (
    fileInfos: FileInfoForSplitToGroups[],
    minGroupSize: number,
    name: string,
): DownloaderPage[] => {
    const downloaderPages: DownloaderPage[] = []
    const groups = splitFilesIntoGroups(fileInfos, minGroupSize)

    for (let i = 0; i < groups.length; i++) {
        const group = groups[i]
        const { size, fileIDs } = findIdsAndTotalSize(group)
        downloaderPages.push({
            fileIDs,
            totalSize: size,
            clicked: false,
            name: `${name}(${i + 1})`,
            hasHEIC: group.some((f) => isHEICFile(f.path)),
        })
    }

    return downloaderPages
}

type FileInfoForTotalSize = Pick<ExtendedJobFile, 'fileID' | 'size'>
/**
 * Calculates the total size of all the files in the given array, and returns it as a number,
 * along with an array of FileID objects that correspond to each file in the original array.
 * @param fileInfos An array of FileInfoForTotalSize objects to process.
 * @returns An object containing the total size of all the files, and an array of FileIDs.
 */
export const findIdsAndTotalSize = (
    fileInfos: FileInfoForTotalSize[],
): {
    size: number
    fileIDs: FileID[]
} => {
    let totalSize = 0
    const ids: FileID[] = new Array(fileInfos.length)

    for (let i = 0; i < fileInfos.length; i++) {
        totalSize += getFileSize(fileInfos[i])
        ids[i] = fileInfos[i].fileID
    }

    return { size: totalSize, fileIDs: ids }
}

function splitSizeEqually(size: number, maxSize: number): number {
    if (size > maxSize) {
        return maxSize
    }
    const numParts = Math.ceil(size / maxSize) // Number of parts to split the size into
    const partSize = Math.ceil(size / numParts) // Size of each part
    return partSize
}

export type FileInfoForPagination = FileInfoForTotalSize &
    FileInfoForSplitByYear
/**
 * Paginates an array of job files for a timeline job into downloader pages based on the maximum page size.
 *
 * @param fileInfos - An array of file infos to paginate.
 * @param  maxPageSizeInBytes - The maximum size of a downloader page in bytes.
 * @returns An array of downloader pages containing the job files.
 */
const paginateTimelineFiles = (
    fileInfos: FileInfoForPagination[],
    maxPageSizeInBytes: number,
): DownloaderPage[] => {
    const downloaderPages: DownloaderPage[] = []

    const filesByYears = splitFilesByYear(fileInfos)
    const years = Object.keys(filesByYears)

    for (let i = 0; i < years.length; i++) {
        const year: number = parseInt(years[i])
        const yearFiles = filesByYears[year]
        const { size, fileIDs } = findIdsAndTotalSize(yearFiles)

        if (size === 0) {
            continue
        }

        if (size > maxPageSizeInBytes) {
            const maxPartSize = splitSizeEqually(size, maxPageSizeInBytes)
            const yearPages = splitFilesIntoDownloaderPages(
                yearFiles,
                maxPartSize,
                `${PRODUCT_NAME}_${year}`,
            )
            downloaderPages.push(...yearPages)
        } else {
            downloaderPages.push({
                fileIDs,
                totalSize: size,
                clicked: false,
                name: `${PRODUCT_NAME}_${year}`,
                hasHEIC: yearFiles.some((f) => isHEICFile(f.path)),
            })
        }
    }

    return downloaderPages
}

/**
 * Paginates an array of job files into downloader pages based on the maximum page size.
 *
 * @param fileInfos - An array of file infos to paginate.
 * @param maxPageSizeInBytes - The maximum size of a downloader page in bytes.
 * @param chunkPrefix - Prefix of chunk name.
 * @returns An array of downloader pages containing the job files.
 */
const paginateAlbumFiles = (
    fileInfos: FileInfoForSplitToGroups[],
    maxPageSizeInBytes: number,
    chunkPrefix: string,
): DownloaderPage[] => {
    const { size } = findIdsAndTotalSize(fileInfos)
    const maxPartSize = splitSizeEqually(size, maxPageSizeInBytes)
    const albumPages = splitFilesIntoDownloaderPages(
        fileInfos,
        maxPartSize,
        chunkPrefix,
    )
    return albumPages
}

/**
 * Paginates an array of job files into downloader pages based on the maximum page size and job name.
 *
 * @param fileInfos - An array of file infos to paginate.
 * @param maxPageSizeInBytes - The maximum size of a downloader page in bytes.
 * @param chunkPrefix - Prefix of chunk name, which is product name or album name.
 * @returns An array of downloader pages containing the job files.
 * @throws {Error} If the fileInfos array is empty.
 */
export const paginateFiles = (
    fileInfos: FileInfoForPagination[],
    maxPageSizeInBytes: number,
    chunkPrefix: string,
): DownloaderPage[] => {
    if (fileInfos.length === 0) {
        throw new Error('Cannot paginate empty files array')
    }

    if (chunkPrefix === PRODUCT_NAME) {
        return paginateTimelineFiles(fileInfos, maxPageSizeInBytes)
    }

    return paginateAlbumFiles(fileInfos, maxPageSizeInBytes, chunkPrefix)
}
