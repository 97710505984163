import styled from 'styled-components'
import SvgPhotoDetails from '@capture/capture-components/src/icons/PhotoDetails'
import SvgPlus from '@capture/capture-components/src/icons/Plus'
import { _ } from '~/assets/localization/util'
import emptyAlbumIllustration from '~/assets/illustrations/album-illustration.svg'
import { colors, fontSize, mediaQueries } from '~/assets/styleConstants'
import thumbnailPlaceholder from '~/assets/svg/ThumbnailPlaceholder.svg'
import type { AlbumJobInfo } from '~/state/albumList/selectors'
import type { GridElementProps } from '~/utilities/gridElementSizeCalculator'
import { WhiteHoverEffect } from './HoverEffects'
import { MaybeNumberOf } from './MaybeNumberOf'
import { MethodProvider } from './MethodProvider'
import { localizedDateStringShort } from './TimeStrings'

const AlbumListContainer = styled.div`
    width: ${(props: { width: number }) => props.width}px;
    display: flex;
    flex-wrap: wrap;
    flex-basis: auto;

    ${mediaQueries.mobile} {
        width: 100%;
    }
`

const Item = styled.div`
    display: flex;
    width: ${(props: { width: number; height: number; spaceAround: number }) =>
        props.width}px;
    height: ${(props) => props.height}px;
    margin: ${(props) => props.spaceAround}px;
    border-radius: 5px;
    border-color: ${colors.captureBlue};
    box-sizing: border-box;
    cursor: pointer;
    ${WhiteHoverEffect};

    ${mediaQueries.mobile} {
        width: 100%;
        height: 128px;
    }
`

type AlbumItemProps = {
    isSelected: boolean
    coverPhoto?: string
    isDisabled: boolean
}
const AlbumItemWrapper = styled(Item)`
    position: relative;
    align-content: flex-end;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    background-image: ${(props: AlbumItemProps) =>
        props.coverPhoto
            ? `url('${props.coverPhoto}'), url(${thumbnailPlaceholder})`
            : `url('${emptyAlbumIllustration}')`};
    background-color: ${(props) =>
        props.coverPhoto ? colors.captureGrey500 : 'white'};
    background-size: ${(props: AlbumItemProps) =>
        props.coverPhoto ? 'cover' : 'contain'};
    background-repeat: no-repeat;
    background-position: center;
    opacity: ${(props) =>
        props.isDisabled ? '0.3' : props.isSelected ? '0.7' : '1'};
    border: ${(props) =>
        props.isSelected ? `3px solid ${colors.captureBlue}` : '0'};
`
const CreateNewAlbumItem = styled(Item)`
    border-width: 1px;
    border-style: dashed;
    color: ${colors.captureBlue};
    font-size: ${fontSize.medium_16};
    font-weight: bold;

    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const InfoArea = styled.div`
    width: 100%;
    align-self: flex-end;
    background: linear-gradient(rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 1));
    padding: ${(props: { isSelected: boolean }) =>
        props.isSelected ? '20px 5px 5px' : '20px 8px 8px'};
    box-sizing: border-box;
    color: white;
    border-radius: inherit;
`

const Title = styled.div`
    width: 100%;
    font-size: ${fontSize.medium_16};
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: flex-end;
`

const Details = styled(Title)`
    font-size: ${fontSize.small_12};
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

const DateText = styled.div`
    margin-left: 8px;
`

export type AlbumSelectInfo = {
    id: AlbumJobInfo['id']
    title: AlbumJobInfo['title']
    dateCreated?: AlbumJobInfo['dateCreated']
    coverPhoto?: AlbumJobInfo['coverPhoto']
    numberOf?: AlbumJobInfo['numberOf']
}

type AlbumSelectItemProps = AlbumSelectInfo & {
    onSelect: (id: JobID) => void
    isSelected: boolean
    width: number
    height: number
    spaceAround: number
    isDisabled: boolean
}
const AlbumSelectItem: React.FunctionComponent<AlbumSelectItemProps> = (
    props,
) => (
    <MethodProvider method={props.onSelect} arg={props.id}>
        {(clickHandler) => (
            <AlbumItemWrapper
                isDisabled={props.isDisabled}
                coverPhoto={props.coverPhoto}
                isSelected={props.isSelected}
                onClick={!props.isDisabled ? clickHandler : undefined}
                onKeyPress={!props.isDisabled ? clickHandler : undefined}
                width={props.width}
                height={props.height}
                spaceAround={props.spaceAround}
                data-cy={'option_for_album_or_timeline'}
                role="button"
                tabIndex={0}>
                <InfoArea isSelected={props.isSelected}>
                    <Title>{props.title}</Title>
                    <Details>
                        {props.numberOf && (
                            <MaybeNumberOf
                                icon={SvgPhotoDetails}
                                numberOf={props.numberOf.files}
                            />
                        )}
                        {props.dateCreated && (
                            <DateText>
                                {localizedDateStringShort(props.dateCreated)}
                            </DateText>
                        )}
                    </Details>
                </InfoArea>
            </AlbumItemWrapper>
        )}
    </MethodProvider>
)

type AlbumSelectListProps = {
    albums: AlbumSelectInfo[]
    onSelect: (id: JobID) => void
    listElementStyle: GridElementProps
    listWidth: number
    selectedID?: JobID
    onCreateNew?: () => void
    jobID: JobID
}

export const AlbumSelectList: React.FunctionComponent<AlbumSelectListProps> = ({
    albums,
    onSelect,
    selectedID,
    onCreateNew,
    listElementStyle,
    listWidth,
    jobID,
}) => {
    const { elementWidth, elementHeight, elementSpaceAround } = listElementStyle
    const itemSize = {
        width: elementWidth,
        height: elementHeight,
        spaceAround: elementSpaceAround,
    }
    const albumItems = albums.map(
        ({ id, title, dateCreated, coverPhoto, numberOf }) => (
            <AlbumSelectItem
                key={id}
                id={id}
                isDisabled={id === jobID}
                title={title}
                dateCreated={dateCreated}
                onSelect={onSelect}
                isSelected={selectedID === id}
                coverPhoto={coverPhoto}
                numberOf={numberOf}
                {...itemSize}
            />
        ),
    )

    const createNewItem = onCreateNew && (
        <CreateNewAlbumItem
            data-cy="albumList__newAlbumSelector"
            onClick={onCreateNew}
            onKeyUp={onCreateNew}
            role="button"
            tabIndex={0}
            {...itemSize}>
            <SvgPlus color={colors.captureBlue} />
            {_('new_album')}
        </CreateNewAlbumItem>
    )

    return (
        <AlbumListContainer width={listWidth}>
            {createNewItem}
            {albumItems}
        </AlbumListContainer>
    )
}
