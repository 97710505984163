import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { inArray } from '~/utilities/arrayUtils'
import { getPopupQueue } from '~/state/popup/selector'
import { _ } from '~/assets/localization/util'
import { colors } from '~/assets/styleConstants'
import { SettingsStorage } from '~/routing/pages'
import {
    getMaxStorage,
    getUsedStorage,
    getUsedStorageRatio,
} from '~/state/currentUser/selectors'
import { PopupClosed } from '~/state/popup/actions'
import type { StoragePopupType } from '~/state/popup/reducer'
import { isMobileMode } from '~/state/viewMode/selectors'
import { BorderedTextButton, FilledTextButton } from '../Common/IconTextButton'
import { StorageChart } from '../Common/StorageChart'
import { PopupHorizontalLayout } from '../Popup/PopupHorizontalLayout'
import { PopupVerticalLayout } from '../Popup/PopupVerticalLayout'

const popupTexts: Record<
    StoragePopupType,
    { headerText: string; mainText: string }
> = {
    AddedMoreFilesThanAvailableStorage: {
        headerText: _('not_enough_storage_for_add'),
        mainText: _('not_enough_storage_for_add_subText'),
    },
    RestoredMoreFilesThanAvailableStorage: {
        headerText: _('not_enough_storage_for_restore'),
        mainText: _('not_enough_storage_for_restore_subText'),
    },
    UsedMoreThan80PercentOfStorage: {
        headerText: _('reached_given_storage_ratio'),
        mainText: _('reached_given_storage_ratio_subText'),
    },
    UsedMoreThan95PercentOfStorage: {
        headerText: _('reached_given_storage_ratio'),
        mainText: _('reached_given_storage_ratio_subText'),
    },
}

export const OutOfStoragePopupPlacement = () => {
    const popupFirstQueueItem = useSelector(getPopupQueue)[0]
    const usedStorageRatio = useSelector(getUsedStorageRatio)
    const usedStorage = useSelector(getUsedStorage)
    const maxStorage = useSelector(getMaxStorage)
    const isMobile = useSelector(isMobileMode)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const doClosePopup = () => {
        dispatch(PopupClosed({ type: popupFirstQueueItem }))
    }

    const gotoShop = () => {
        doClosePopup()
        navigate(SettingsStorage.url)
    }

    const getMainText = () => {
        const storageSoonFullTypes = [
            'UsedMoreThan80PercentOfStorage',
            'UsedMoreThan95PercentOfStorage',
        ]
        // Include storage info of a user in the text
        if (inArray(storageSoonFullTypes, popupFirstQueueItem)) {
            const usedStorageVal = (usedStorage / Math.pow(1024, 3)).toFixed(1)
            const maxStorageVal = (maxStorage / Math.pow(1024, 3)).toFixed(0)

            return popupTexts[popupFirstQueueItem].mainText
                .replace('%used_storage%', usedStorageVal.toString())
                .replace('%max_storage%', maxStorageVal.toString())
        }

        return popupTexts[popupFirstQueueItem].mainText
    }

    const Popup = isMobile ? PopupVerticalLayout : PopupHorizontalLayout

    if (!popupFirstQueueItem) {
        return null
    }

    return (
        <Popup
            headerText={popupTexts[popupFirstQueueItem].headerText}
            mainText={getMainText()}
            illustration={
                <StorageChart
                    size={isMobile ? 80 : 120}
                    coloredRatio={usedStorageRatio}
                />
            }
            buttons={[
                <BorderedTextButton
                    data-cy="popup__out_of_storage__ok_button"
                    onClick={doClosePopup}
                    text={_('close')}
                    color={colors.captureBlue}
                    key="closeButton"
                />,
                <FilledTextButton
                    data-cy="popup__out_of_storage__buy_button"
                    onClick={gotoShop}
                    text={_('buy_more_storage')}
                    fillColor={colors.captureBlue}
                    key="buyMoreStorageButton"
                />,
            ]}
        />
    )
}
