import type { Action } from '../common/actions'
import { isType } from '../common/actions'
import { FileWasRemovedFromJob } from '../job/actions'
import type { ConfirmableSelectionAction } from './actions'
import {
    FilesWereDeselected,
    FilesWereSelected,
    SelectionActionConfirmHandled,
    SelectionActionInitiated,
} from './actions'

export type SelectedFilesState = {
    files: FileID[]
    selectionAction?: ConfirmableSelectionAction
}
const initialState: SelectedFilesState = { files: [] }

export const selectedFilesReducer = (
    state: SelectedFilesState = initialState,
    action: Action,
): SelectedFilesState => {
    if (isType(action, FilesWereDeselected)) {
        const selectedFiles = new Set(state.files)
        action.payload.forEach((f) => selectedFiles.delete(f))
        return { ...state, files: Array.from(selectedFiles) }
    }

    if (isType(action, FilesWereSelected)) {
        const selectedFiles = new Set(state.files)
        action.payload.forEach((f) => selectedFiles.add(f))
        return { ...state, files: Array.from(selectedFiles) }
    }

    if (isType(action, FileWasRemovedFromJob)) {
        const selectedFiles = new Set(state.files)
        selectedFiles.delete(action.payload.fileID)
        return { ...state, files: Array.from(selectedFiles) }
    }

    if (isType(action, SelectionActionInitiated)) {
        return { ...state, selectionAction: action.payload }
    }

    if (isType(action, SelectionActionConfirmHandled)) {
        return { ...state, selectionAction: undefined }
    }

    return state
}

export const selectedFilesReducerMapObj = {
    selectedFiles: selectedFilesReducer,
}

export type StateWithSelectedFiles = StateOfReducerMapObj<
    typeof selectedFilesReducerMapObj
>
