import type { NavigateFunction } from 'react-router-dom'
import { createActionCreator, createVoidActionCreator } from '../common/actions'
import type { RejectReason } from './reducer'

export type FileWasAddedPayload = {
    id: number
    targetJob: JobID
    targetFolder?: string
    alsoTargetTimeline?: boolean
    name: string
    size: number
}

export const FileWasAddedToUploadQueue =
    createActionCreator<FileWasAddedPayload>('FILE_WAS_ADDED_TO_UPLOAD_QUEUE')
export const FileWasAcceptedToUploadQueue = createActionCreator<{
    fileID: number
}>('FILE_WAS_ACCEPTED_TO_UPLOAD_QUEUE')
export const FileWasRemovedFromUploadQueue = createActionCreator<{
    fileID: number
}>('FILE_WAS_REMOVED_FROM_UPLOAD_QUEUE')
export const FileUploadStarted = createActionCreator<{ fileID: number }>(
    'FILE_UPLOAD_STARTED',
)
export const FileUploadProgress = createActionCreator<{
    fileID: number
    percentComplete: number
}>('FILE_UPLOAD_PROGRESS')
export const FileUploadSucceeded = createActionCreator<{
    fileID: number
    fileUUID: FileID
    usedStorage: number
}>('FILE_UPLOAD_SUCCEEDED')
export const FileWasRejected = createActionCreator<{
    fileID: number
    reason: RejectReason
}>('FILE_WAS_REJECTED') // Something wrong with the file
export const FileUploadFailed = createActionCreator<{
    fileID: number
    message?: string
}>('FILE_UPLOAD_FAILED') // Something wrong with the infrastructure
export const FileUploadBackendSucceeded = createActionCreator<{
    fileID: number
    fileUUID: FileID
}>('FILE_UPLOAD_BACKEND_SUCCEEDED')

export const FileUploadRetry = createVoidActionCreator('FILE_UPLOAD_RETRY') // Try again after something wrong with the infrastructure

export const UploaderPaused = createVoidActionCreator('UPLOADER_PAUSED')
export const UploaderResumed = createVoidActionCreator('UPLOADER_RESUMED')
export const UploaderStopPrompted = createVoidActionCreator(
    'UPLOADER_STOP_PROMPTED',
)
export const UploaderStopAborted = createVoidActionCreator(
    'UPLOADER_STOP_ABORTED',
)
export const UploaderStopped = createActionCreator<{
    navigate?: NavigateFunction
}>('UPLOADER_STOPPED')

export const UploaderFinished = createActionCreator<{ filesCount: number }>(
    'UPLOADER_FINISHED',
)

export const UploaderStatusBoxShown = createVoidActionCreator(
    'UPLOADER_STATUS_BOX_SHOWN',
)
export const UploaderStatusBoxDismissed = createVoidActionCreator(
    'UPLOADER_STATUS_BOX_DISMISSED',
)
export const UploaderStatusBoxExpanded = createVoidActionCreator(
    'UPLOADER_STATUS_BOX_EXPANDED',
)
export const UploaderStatusBoxCollapsed = createVoidActionCreator(
    'UPLOADER_STATUS_BOX_COLLAPSED',
)

export const UploadStatusListFiltered = createVoidActionCreator(
    'UPLOAD_STATUS_LIST_FILTERED',
)

export const AddedMoreFilesThanAvailableStorage = createVoidActionCreator(
    'ADDED_MORE_FILES_THAN_AVAILABLE_STORAGE',
)

// Uploader-actions to track in the analytical platform
export const ActionsForAnalytics = [
    FileWasAddedToUploadQueue,
    FileWasRemovedFromUploadQueue,
    FileUploadSucceeded,
    FileUploadFailed,
    AddedMoreFilesThanAvailableStorage,
    UploaderFinished,
    UploaderStatusBoxExpanded,
    UploaderStatusBoxCollapsed,
    UploaderStopPrompted,
    UploaderStopAborted,
    UploaderStopped,
]
