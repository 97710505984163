import { useParams } from 'react-router-dom'
import { b64ToUuid } from '~/utilities/uuid'
import type { FetchedPageType } from './AlbumContainer'
import { AlbumContainer } from './AlbumContainer'

/**
 * Mount-point for Album-page in router, accepting either a Base64-encoded or a PainText-encoded album-ID
 */

export const makeAlbumMount = (fPage: FetchedPageType) => {
    const AlbumMount: React.FunctionComponent = () => {
        const params = useParams<{ albumID?: JobID; albumIDB64?: string }>()
        if (params.albumID) {
            return (
                <AlbumContainer fetchedPage={fPage} albumID={params.albumID} />
            )
        }
        if (params.albumIDB64) {
            return (
                <AlbumContainer
                    fetchedPage={fPage}
                    albumID={b64ToUuid(params.albumIDB64)}
                />
            )
        }
        throw Error('Must have params for either albumID or albumIDB64')
    }
    return AlbumMount
}
