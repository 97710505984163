import {
    ContentLoader,
    ErrorMessage,
    Prompt,
    Typography,
} from '@capture/capture-components'
import SvgArrowBack from '@capture/capture-components/src/icons/ArrowBack'
import SvgDeleteForever from '@capture/capture-components/src/icons/DeleteForever'
import SvgRestore from '@capture/capture-components/src/icons/Restore'
import { useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { downloadDeletedAlbum } from '~/API/download'
import trashIllustration from '~/assets/illustrations/trash-illustration.svg'
import { _ } from '~/assets/localization/util'
import { colors, fontSize } from '~/assets/styleConstants'
import { IconButton, IconTextButton } from '~/components/Common/IconTextButton'
import { PageWrapper } from '~/components/Common/PageWrapper'
import { TwoAreasTopNavBar } from '~/components/Navigation/TwoAreasTopNavBar'
import { ReadonlyTooltip } from '~/components/Common/ReadonlyTooltip'
import { DeletePrompt } from '~/components/Trash/DeletePrompt'
import { RestorePrompt } from '~/components/Trash/RestorePrompt'
import { TrashAlbumEntry } from '~/components/Trash/TrashAlbumEntry'
import { useDeletedAlbums } from '~/components/Trash/useDeletedAlbums'
import { RoutePath } from '~/routing'
import { isMobileMode } from '~/state/viewMode/selectors'
import {
    TrashAlbumsDeleteFailed,
    TrashAlbumsDeleteSuccess,
    TrashAlbumsRestoreFailed,
    TrashAlbumsRestoreSuccess,
} from '~/state/trash/actions'
import { EmptyStatePage } from '../Common/EmptyStatePage'

const ButtonContainer = styled.section`
    display: flex;
    gap: 1rem;
`

const PageContent = styled.main`
    padding: 1.5rem 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
`

const ImageGrid = styled.section`
    display: grid;
    // fill with as many as possible, keeping each thumbnail at least 160px wide
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    column-gap: 1rem;
    row-gap: 1.5rem;
`

type TrashAlbumsPageProps = {
    inAppMode?: boolean
}

type ActionsState = {
    prompt: JobAction['type'] | null
    albumIds: JobID[]
}

type JobAction = {
    type: 'restore' | 'delete'
    payload: JobID[]
}

type CloseAction = {
    type: 'close'
}

const albumActionsReducer = (
    state: ActionsState,
    action: JobAction | CloseAction,
): ActionsState => {
    const { type } = action
    switch (type) {
        case 'restore':
        case 'delete':
            return { prompt: type, albumIds: action.payload }
        case 'close':
            return { prompt: null, albumIds: [] }
        default:
            return state
    }
}

export const TrashAlbumsPage = ({
    inAppMode = false,
}: TrashAlbumsPageProps) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams, _setSearchParams] = useSearchParams()
    const isFromInAppNavigation = searchParams.get('fromNav') === '1'
    const shouldShowNav = !inAppMode || isFromInAppNavigation

    const [promptState, dispatchAlbumAction] = useReducer(albumActionsReducer, {
        prompt: null,
        albumIds: [],
    })

    const {
        deletedAlbums,
        deletedAlbumIds,
        error,
        isLoading,
        deleteAlbums,
        restoreAlbums,
    } = useDeletedAlbums()

    const hasAlbums = deletedAlbumIds && deletedAlbumIds.length > 0
    const isMobile = useSelector(isMobileMode)
    const TextOrIconButton = isMobile ? IconButton : IconTextButton

    const goToTrashEntry = () => {
        navigate(
            inAppMode
                ? RoutePath.InAppTrashNavigation
                : RoutePath.TrashNavigation,
        )
    }

    const handleDelete = async (albumIds: JobID[]) => {
        try {
            await deleteAlbums(albumIds)
            dispatch(TrashAlbumsDeleteSuccess(albumIds))
        } catch (error) {
            dispatch(TrashAlbumsDeleteFailed())
        }
    }

    const handleRestore = async (albumIds: JobID[]) => {
        try {
            await restoreAlbums(albumIds)
            dispatch(TrashAlbumsRestoreSuccess(albumIds))
        } catch (error) {
            dispatch(TrashAlbumsRestoreFailed())
        }
    }

    const content = (() => {
        if (isLoading) {
            return <ContentLoader />
        }

        if (error) {
            return <ErrorMessage>{_('something_went_wrong')}</ErrorMessage>
        }

        if (deletedAlbums === undefined) {
            return null
        }

        if (deletedAlbums.length === 0) {
            return (
                <EmptyStatePage
                    illustration={
                        <img src={trashIllustration} alt="Empty trash" />
                    }
                    header={_('trash_no_albums')}
                    subHeader={
                        <Typography.Paragraph>
                            {_('trash_delete_info_albums')}
                        </Typography.Paragraph>
                    }
                />
            )
        }
        return (
            <PageContent>
                <Typography.SectionHeader>
                    {_('recently_deleted_albums')}
                </Typography.SectionHeader>
                <ImageGrid>
                    {deletedAlbums.map(
                        ({ id, coverUrl, name, remainingDays, subtitle }) => {
                            return (
                                <TrashAlbumEntry
                                    key={id}
                                    coverUrl={coverUrl}
                                    name={name}
                                    remainingDays={remainingDays}
                                    subtitle={subtitle}
                                    deleteAlbum={() =>
                                        dispatchAlbumAction({
                                            type: 'delete',
                                            payload: [id],
                                        })
                                    }
                                    exportAlbum={() =>
                                        downloadDeletedAlbum(dispatch, id, name)
                                    }
                                    restoreAlbum={() => handleRestore([id])}
                                />
                            )
                        },
                    )}
                </ImageGrid>
                <Prompt.Root
                    open={promptState.prompt === 'restore'}
                    onOpenChange={(open) =>
                        !open && dispatchAlbumAction({ type: 'close' })
                    }>
                    <RestorePrompt
                        handleConfirm={() =>
                            handleRestore(promptState.albumIds)
                        }
                    />
                </Prompt.Root>
                <Prompt.Root
                    open={promptState.prompt === 'delete'}
                    onOpenChange={(open) =>
                        !open && dispatchAlbumAction({ type: 'close' })
                    }>
                    <DeletePrompt
                        singular={promptState.albumIds.length === 1}
                        handleConfirm={() => handleDelete(promptState.albumIds)}
                    />
                </Prompt.Root>
            </PageContent>
        )
    })()

    return (
        <PageWrapper
            isContentReady={!isLoading}
            hideFooter={inAppMode}
            navBar={
                shouldShowNav && (
                    <TwoAreasTopNavBar
                        hideUserAvi
                        left={
                            <IconTextButton
                                onClick={goToTrashEntry}
                                text={_('recently_deleted')}
                                icon={SvgArrowBack}
                                fontSize={fontSize.small_14}
                            />
                        }
                        right={
                            <ButtonContainer>
                                <TextOrIconButton
                                    onClick={() => {
                                        if (!hasAlbums) return
                                        dispatchAlbumAction({
                                            type: 'delete',
                                            payload: deletedAlbumIds,
                                        })
                                    }}
                                    text={_('delete_all')}
                                    icon={SvgDeleteForever}
                                    hoverColor={colors.captureBlue}
                                    isDisabled={!hasAlbums}
                                />
                                <ReadonlyTooltip
                                    renderElement={(isReadOnlyUser) => (
                                        <TextOrIconButton
                                            onClick={() => {
                                                if (!hasAlbums) return
                                                dispatchAlbumAction({
                                                    type: 'restore',
                                                    payload: deletedAlbumIds,
                                                })
                                            }}
                                            text={_('restore_all')}
                                            icon={SvgRestore}
                                            hoverColor={colors.captureBlue}
                                            isDisabled={
                                                isReadOnlyUser || !hasAlbums
                                            }
                                        />
                                    )}
                                />
                            </ButtonContainer>
                        }
                    />
                )
            }>
            {content}
        </PageWrapper>
    )
}
