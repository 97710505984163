import * as React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { _ } from '~/assets/localization/util'
import { colors, fontSize } from '~/assets/styleConstants'
import { setCursorType } from '~/state/cursor/cursorSlice'
import { preventDefault } from '~/utilities/eventHandling'
import { CopyLinkOutlinedBtn } from '../AlbumOptions/ShareToOptions'
import { FilledTextButton } from '../Common/IconTextButton'

const Wrapper = styled.div`
    max-width: ${(props: { isMobile: boolean; height?: number }) =>
        props.isMobile ? '250px' : '600px'};
    position: relative;
    height: ${(props) => (props.height ? `${props.height}px` : 'auto')};
    padding: 0 8%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: center;
`

const ShareLink = styled.a`
    display: block;
    margin-bottom: 10px;
    font-size: ${fontSize.small_14};
    user-select: none;
    color: ${colors.captureBlue};
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const IconRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin: 12px 0;
`

type Props = {
    link: string
    isMobile: boolean
    isMobileDevice: boolean
    onConfirm: () => void
    height?: number
    otherOptions?: React.ReactNode[]
    bottomElement?: React.ReactNode
}

export const CopyShareLinkComponent: React.FunctionComponent<Props> = (
    props,
) => {
    const dispatch = useDispatch()
    const copyUrl = async () => {
        try {
            dispatch(setCursorType('wait'))
            await navigator.clipboard.writeText(`${props.link}#sc`)
            dispatch(setCursorType('auto'))
            props.onConfirm()
        } catch (error) {
            console.error(error)
            dispatch(setCursorType('auto'))
        }
    }

    const getContent = () => {
        const shareIcons = props.otherOptions?.map((option, i) => (
            <div key={`option${i}`} style={{ margin: 12 }}>
                {option}
            </div>
        ))

        let copyLinkBtn = (
            <div style={{ margin: '0 auto', width: '80%' }}>
                <FilledTextButton
                    onClick={copyUrl}
                    text={_('copy_link')}
                    fontSize={fontSize.small_14}
                    fillColor={colors.captureBlue}
                    hoverColor={colors.captureBlue900}
                    color="white"
                />
            </div>
        )
        if (props.isMobileDevice) {
            copyLinkBtn = (
                <>
                    <CopyLinkOutlinedBtn
                        href={props.link}
                        onClick={preventDefault}>
                        {_('hold_to_copy_link')}
                    </CopyLinkOutlinedBtn>
                </>
            )
        }
        return (
            <>
                <IconRow>{shareIcons}</IconRow>
                <div style={{ paddingBottom: 12, marginTop: 12 }}>
                    <ShareLink href={props.link} draggable={false}>
                        {props.link}
                    </ShareLink>
                    {copyLinkBtn}
                </div>
            </>
        )
    }

    React.useEffect(() => {
        document.addEventListener('copy', props.onConfirm)

        return () => {
            document.removeEventListener('copy', props.onConfirm)
        }
    }, [])

    return (
        <Wrapper isMobile={props.isMobile} height={props.height}>
            {getContent()}
            {props.bottomElement}
        </Wrapper>
    )
}
