import styled, { keyframes } from 'styled-components'

const revealAnimation = keyframes`
    from {
        width: 0px;
    }
    to {
        width: 1em;
    }
`

const Ellipsis = styled.div`
    padding-right: 1em;
    font: inherit;
    &: after {
        position: absolute;
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        animation: ${revealAnimation} steps(4, end) 1s infinite;
        content: '…';
    }
`

interface AnimatedEllipsisProps {
    text: string
}

export const AnimatedEllipsis = (props: AnimatedEllipsisProps) => {
    return <Ellipsis>{props.text}</Ellipsis>
}
