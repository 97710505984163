import * as React from 'react'
import type { GridStyle } from '~/utilities/gridElementSizeCalculator'
import {
    calcImagesPerRow,
    computeGridHeight,
    getElementSize,
} from '~/utilities/gridElementSizeCalculator'

type Props = {
    elements: React.ReactNode[]
    groupStyle: GridStyle
    startIndex?: number
}

export class VirtualGrid extends React.Component<Props> {
    public render() {
        const elementWidth = getElementSize(this.props.groupStyle).width
        const elementHeight = getElementSize(this.props.groupStyle).height
        const itemsPerRow = calcImagesPerRow(this.props.groupStyle)

        const elements = this.props.elements.map((element, i) => (
            <div
                key={'virtualGridElement_' + ((this.props.startIndex || 0) + i)}
                style={{
                    position: 'absolute',
                    top: Math.floor(i / itemsPerRow) * elementHeight,
                    left: (i % itemsPerRow) * elementWidth,
                }}>
                {element}
            </div>
        ))
        const containerHeight = computeGridHeight(
            this.props.groupStyle,
            this.props.elements.length,
        )
        return (
            <div
                style={{
                    position: 'relative',
                    width: this.props.groupStyle.width,
                    height: containerHeight,
                }}>
                {elements}
            </div>
        )
    }
}
