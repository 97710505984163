import type { Action } from '../common/actions'
import { isType } from '../common/actions'
import type { SideModuleContentType } from './actions'
import {
    AutoNavigateSet,
    CarouselCastingStarted,
    CarouselCastingStopped,
    CarouselVideoPlayStatusChanged,
    CarouselViewEntered,
    CarouselViewExited,
    CarouselViewSideModuleClosed,
    CarouselViewSideModuleOpened,
    CastSessionWasResumed,
    ChangedCastedFile,
    ChangedCurrentCarouselFile,
    EmailFormClosed,
    EmailFormOpened,
    FullscreenModeEntered,
    FullscreenModeExited,
    PlaybackOrderSet,
} from './actions'

export type VideoPlayStatus = 'playing' | 'paused' | 'finished'
export type PlaybackOrder = 'forward' | 'backward'
export type CastingDetail = { deviceName: string }
export type CarouselViewInfo = { jobID: JobID; fileID: FileID }
export type CarouselViewerState = {
    currentView?: CarouselViewInfo
    prevView?: CarouselViewInfo
    currentSideModule: SideModuleContentType | undefined
    isEmailFormOpen: boolean
    isInFullscreenMode: boolean
    isCarouselOpen: boolean
    shouldAutoNavigate: boolean
    playbackOrder?: PlaybackOrder
    videoPlayStatus?: VideoPlayStatus
    castingDetail?: CastingDetail // add more as needed
}

const initialState = {
    currentSideModule: undefined,
    isInFullscreenMode: false,
    isCarouselOpen: false,
    shouldAutoNavigate: false,
    isEmailFormOpen: false,
}

export function carouselViewerReducer(
    state: CarouselViewerState = initialState,
    action: Action,
): CarouselViewerState {
    if (isType(action, CarouselViewEntered)) {
        const resetSideModule =
            action.payload.sourcePage === 'TimelinePage' &&
            state.currentSideModule === 'comments'
        return {
            ...state,
            ...(resetSideModule ? { currentSideModule: undefined } : {}),
            currentView: action.payload,
            prevView: undefined,
            isCarouselOpen: true,
        }
    }
    // don't need to handle this since carousel view is its own route now?
    if (isType(action, CarouselViewExited)) {
        return { ...state, isCarouselOpen: false }
    }
    if (isType(action, EmailFormOpened)) {
        return { ...state, isEmailFormOpen: true }
    }
    if (isType(action, EmailFormClosed)) {
        return { ...state, isEmailFormOpen: false }
    }
    if (
        isType(action, ChangedCurrentCarouselFile) ||
        isType(action, ChangedCastedFile)
    ) {
        return {
            ...state,
            currentView: action.payload,
            prevView: state.currentView,
            videoPlayStatus: undefined,
            shouldAutoNavigate:
                state.currentView &&
                state.currentView.jobID !== action.payload.jobID
                    ? false
                    : state.shouldAutoNavigate,
        }
    }

    if (isType(action, CarouselViewSideModuleOpened)) {
        return {
            ...state,
            currentSideModule: action.payload,
        }
    }
    if (isType(action, CarouselViewSideModuleClosed)) {
        return {
            ...state,
            currentSideModule: undefined,
        }
    }

    if (isType(action, FullscreenModeEntered)) {
        return {
            ...state,
            isInFullscreenMode: true,
        }
    }

    if (isType(action, FullscreenModeExited)) {
        return {
            ...state,
            isInFullscreenMode: false,
        }
    }

    if (isType(action, CarouselCastingStarted)) {
        return {
            ...state,
            castingDetail: {
                deviceName: action.payload.castDeviceName,
            },
        }
    }

    if (isType(action, CarouselCastingStopped)) {
        return {
            ...state,
            castingDetail: undefined,
        }
    }
    if (isType(action, AutoNavigateSet)) {
        return {
            ...state,
            shouldAutoNavigate: action.payload,
        }
    }
    if (isType(action, PlaybackOrderSet)) {
        return {
            ...state,
            playbackOrder: action.payload,
        }
    }
    if (isType(action, CarouselVideoPlayStatusChanged)) {
        return {
            ...state,
            videoPlayStatus: action.payload,
        }
    }

    if (isType(action, CastSessionWasResumed)) {
        return {
            ...state,
            currentView: {
                jobID: action.payload.castedItem.jobID,
                fileID: action.payload.castedItem.fileID,
            },
        }
    }

    return state
}

export const carouselViewerReducerMapObj = {
    carouselViewer: carouselViewerReducer,
}

export type StateWithCarouselViewer = StateOfReducerMapObj<
    typeof carouselViewerReducerMapObj
>
