type BlockMethod = () => boolean
let blocks: BlockMethod[] = []

const bindGlobal = () => {
    window.onbeforeunload = () => {
        if (blocks.reduce((prev, method) => prev || method(), false)) {
            return 'Changes might be lost'
        }
    }
}

export const addOnBeforeUnloadBlock = (handler: BlockMethod) => {
    blocks = [...blocks, handler]
    bindGlobal()
}
export const removeOnBeforeUnloadBlock = (handler: BlockMethod) => {
    blocks = blocks.filter((b) => b !== handler)
}
