import type { StateWithHosts } from './reducer'

export const getHostDirectory = (state: StateWithHosts) =>
    state.hosts.appHost === 'N/A' ? null : state.hosts

export const getAppHost = (state: StateWithHosts) =>
    state.hosts.appHost === 'N/A' ? null : state.hosts.appHost

export const getPollHost = (state: StateWithHosts) =>
    state.hosts.pollHost === 'N/A' ? null : state.hosts.pollHost

export const getThumbHost = (state: StateWithHosts) =>
    state.hosts.thumbHost === 'N/A' ? null : state.hosts.thumbHost

export const getVideoHost = (state: StateWithHosts) =>
    state.hosts.videoHost === 'N/A' ? null : state.hosts.videoHost

export const getDownloadHost = (state: StateWithHosts) =>
    state.hosts.downloadHost === 'N/A' ? null : state.hosts.downloadHost
