import type { SVGProps } from 'react'
const SvgUpload = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill={props.color ?? 'currentColor'}
            d="m11 12.85-.9.875a.933.933 0 0 1-.687.275.973.973 0 0 1-.713-.3.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7l2.6-2.6c.2-.2.433-.3.7-.3.267 0 .5.1.7.3l2.6 2.6c.183.183.28.412.287.688a.93.93 0 0 1-.287.712.977.977 0 0 1-.687.288.973.973 0 0 1-.713-.263l-.9-.875V18h5.5c.7 0 1.292-.242 1.775-.725.483-.483.725-1.075.725-1.775s-.242-1.292-.725-1.775C19.792 13.242 19.2 13 18.5 13H17v-2c0-1.383-.488-2.562-1.463-3.537C14.563 6.487 13.384 6 12 6s-2.562.487-3.537 1.463C7.488 8.437 7 9.617 7 11h-.5c-.967 0-1.792.342-2.475 1.025A3.372 3.372 0 0 0 3 14.5c0 .967.342 1.792 1.025 2.475A3.372 3.372 0 0 0 6.5 18H8c.283 0 .52.096.713.288.191.191.287.429.287.712s-.096.52-.287.712A.967.967 0 0 1 8 20H6.5c-1.517 0-2.812-.525-3.887-1.575C1.537 17.375 1 16.092 1 14.575c0-1.3.392-2.458 1.175-3.475S3.983 9.433 5.25 9.15c.417-1.533 1.25-2.775 2.5-3.725C9 4.475 10.417 4 12 4c1.95 0 3.604.68 4.962 2.037C18.322 7.397 19 9.05 19 11c1.15.133 2.104.63 2.863 1.488A4.407 4.407 0 0 1 23 15.5c0 1.25-.437 2.313-1.312 3.188S19.75 20 18.5 20H13c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 11 18z"
        />
    </svg>
)
export default SvgUpload
