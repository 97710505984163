import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import SvgClose from '@capture/capture-components/src/icons/Close'
import type { Dispatch } from '~/state/common/actions'
import { loveAlbumFile, unloveAlbumFile } from '~/API/album'
import { colors } from '~/assets/styleConstants'
import { DisplayListOfLoversInitiated } from '~/state/album/actions'
import type { AlbumFileComment } from '~/state/album/selectors'
import {
    canCurrentUserCommentOnAlbum,
    getComments,
} from '~/state/album/selectors'
import { CommentStatus } from '~/state/files/reducer'
import { getCommentStatus } from '~/state/files/selectors'
import {
    getTotalLoveCountPerFile,
    isCurrentUserLovedFile,
} from '~/state/reaction/selectors'
import type { WithRouterProps } from '~/utilities/navigation'
import { goBack, withRouter } from '~/utilities/navigation'
import { b64ToUuid } from '~/utilities/uuid'
import { RequireUserInfoPlacement } from '../ProvideName/RequireUserInfoPlacement'
import { CommentsComponent } from './CommentsComponent'

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 3px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    & > div:last-child {
        flex: 1;
    }
`
const TopElement = styled.div`
    font-size: 11px;
    padding: 16px 4px 8px;
    margin: 0 8px;
    box-sizing: border-box;
    border-bottom: 1px solid ${colors.captureGrey400};
    text-align: right;
    div {
        display: inline-block;
        height: 24px;
    }
`

type InnerProps = {
    fileID: FileID
    albumID: JobID
    comments: AlbumFileComment[]
    commentsCanBeAdded: boolean
    isPendingNewComment: boolean
    totalLoveCount: number
    lovedByCurrentUser: boolean
}
type DispatchProps = {
    onLoveChanged: (isLove: boolean) => void
    doShowLoveList: () => void
}

class CommentsListOverlayInner extends React.Component<
    InnerProps & DispatchProps
> {
    public render() {
        return (
            <Wrapper>
                <TopElement>
                    <div
                        onKeyUp={goBack}
                        onClick={goBack}
                        role="button"
                        tabIndex={0}>
                        <SvgClose />
                    </div>
                </TopElement>
                <CommentsComponent
                    fileID={this.props.fileID}
                    albumID={this.props.albumID}
                    comments={this.props.comments}
                    commentsCanBeAdded={this.props.commentsCanBeAdded}
                    isPendingNewComment={this.props.isPendingNewComment}
                    truncateLongComments={false}
                    totalLoveCount={this.props.totalLoveCount}
                    lovedByCurrentUser={this.props.lovedByCurrentUser}
                    onLoveChanged={this.props.onLoveChanged}
                    doShowLoveList={this.props.doShowLoveList}
                />
                <RequireUserInfoPlacement jobID={this.props.albumID} />
            </Wrapper>
        )
    }
}

type OwnProps = WithRouterProps<{ albumIDB64: string; fileID: FileID }>
type CommentsListOverlayStoreState = StateOfSelector<typeof getComments> &
    StateOfSelector<typeof canCurrentUserCommentOnAlbum> &
    StateOfSelector<typeof getCommentStatus> &
    StateOfSelector<typeof getTotalLoveCountPerFile> &
    StateOfSelector<typeof isCurrentUserLovedFile>

const mapStateToProps = (
    state: CommentsListOverlayStoreState,
    ownProps: OwnProps,
): InnerProps => {
    const albumID = b64ToUuid(ownProps.params.albumIDB64)
    const fileID = ownProps.params.fileID
    return {
        albumID,
        fileID,
        comments: getComments(state, fileID),
        commentsCanBeAdded: canCurrentUserCommentOnAlbum(state, albumID),
        isPendingNewComment:
            getCommentStatus(state, ownProps.params.fileID) ===
            CommentStatus.PENDING,
        totalLoveCount: getTotalLoveCountPerFile(state)[fileID],
        lovedByCurrentUser: isCurrentUserLovedFile(state, fileID),
    }
}
const mapDispatchToProps = (
    dispatch: Dispatch,
    ownProps: OwnProps,
): DispatchProps => {
    const albumID = b64ToUuid(ownProps.params.albumIDB64)
    const fileID = ownProps.params.fileID
    return {
        onLoveChanged: (ifLove: boolean) => {
            ifLove
                ? loveAlbumFile(dispatch, albumID, fileID)
                : unloveAlbumFile(dispatch, albumID, fileID)
        },
        doShowLoveList: () =>
            dispatch(DisplayListOfLoversInitiated(albumID, fileID)),
    }
}

// Only works for Albums with Job-type backend (ie Capture)
export const CommentsListOverlay = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CommentsListOverlayInner),
)
