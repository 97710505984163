import type { SVGProps } from 'react'
const SvgSettings = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill={props.color ?? 'currentColor'}
            d="M10.125 22a.934.934 0 0 1-.65-.25.997.997 0 0 1-.325-.625l-.3-2.325a3.79 3.79 0 0 1-.613-.3 8.266 8.266 0 0 1-.562-.375l-2.175.9a.97.97 0 0 1-.7.038.983.983 0 0 1-.55-.438L2.4 15.4a.94.94 0 0 1-.125-.7.96.96 0 0 1 .375-.6l1.875-1.425a2.387 2.387 0 0 1-.025-.338v-.675c0-.108.008-.22.025-.337L2.65 9.9a.96.96 0 0 1-.375-.6.94.94 0 0 1 .125-.7l1.85-3.225a.983.983 0 0 1 .55-.437.97.97 0 0 1 .7.037l2.175.9c.183-.133.375-.258.575-.375.2-.117.4-.217.6-.3l.3-2.325a.997.997 0 0 1 .325-.625c.183-.167.4-.25.65-.25h3.75c.25 0 .467.083.65.25a.997.997 0 0 1 .325.625l.3 2.325c.217.083.42.183.612.3.192.117.38.242.563.375l2.175-.9a.97.97 0 0 1 .7-.037c.233.075.417.22.55.437L21.6 8.6a.94.94 0 0 1 .125.7.96.96 0 0 1-.375.6l-1.875 1.425c.017.117.025.23.025.338v.675c0 .108-.017.22-.05.337l1.875 1.425c.2.15.325.35.375.6a.94.94 0 0 1-.125.7l-1.85 3.2a1.04 1.04 0 0 1-.562.45.958.958 0 0 1-.713-.025l-2.125-.9a6.842 6.842 0 0 1-.575.375c-.2.117-.4.217-.6.3l-.3 2.325a.997.997 0 0 1-.325.625.934.934 0 0 1-.65.25zM11 20h1.975l.35-2.65c.517-.133.996-.33 1.438-.588.441-.258.845-.57 1.212-.937l2.475 1.025.975-1.7-2.15-1.625c.083-.233.142-.48.175-.738a6.153 6.153 0 0 0 0-1.575 3.535 3.535 0 0 0-.175-.737l2.15-1.625-.975-1.7-2.475 1.05a5.556 5.556 0 0 0-1.213-.962 5.607 5.607 0 0 0-1.437-.588L13 4h-1.975l-.35 2.65c-.517.133-.996.33-1.438.587-.441.259-.845.571-1.212.938L5.55 7.15l-.975 1.7 2.15 1.6c-.083.25-.142.5-.175.75s-.05.517-.05.8c0 .267.017.525.05.775s.092.5.175.75l-2.15 1.625.975 1.7 2.475-1.05c.367.383.77.704 1.212.962.442.259.921.455 1.438.588zm1.05-4.5c.967 0 1.792-.342 2.475-1.025A3.372 3.372 0 0 0 15.55 12c0-.967-.342-1.792-1.025-2.475A3.373 3.373 0 0 0 12.05 8.5c-.983 0-1.812.342-2.488 1.025A3.393 3.393 0 0 0 8.55 12c0 .967.337 1.792 1.012 2.475.676.683 1.505 1.025 2.488 1.025"
        />
    </svg>
)
export default SvgSettings
