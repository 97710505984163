import { createSelector } from '@reduxjs/toolkit'
import type {
    DownloaderModalInfo,
    ModalStatus,
    StateWithDownloader,
} from './reducer'

export const getDownloaderModalState = (
    state: StateWithDownloader,
): ModalStatus => state.downloader.modalStatus
export const getDownloaderModalInfo = (
    state: StateWithDownloader,
): DownloaderModalInfo | undefined => state.downloader.modalInfo
export const getDownloaderJobID = createSelector(
    getDownloaderModalInfo,
    (info) => info?.jobID,
)
export const getDownloaderPages = createSelector(
    getDownloaderModalInfo,
    (info) => info?.pages,
)
export const getDownloaderCount = createSelector(
    getDownloaderModalInfo,
    (info) => info?.count,
)
export const getDownloaderSize = createSelector(
    getDownloaderModalInfo,
    (info) => info?.size,
)
export const getDownloaderType = createSelector(
    getDownloaderModalInfo,
    (info) => info?.downloadType,
)
