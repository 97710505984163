import * as React from 'react'
import styled from 'styled-components'
import { stopPropagation } from '~/utilities/eventHandling'

type vPos = 'top' | 'center' | 'bottom'
type hPos = 'left' | 'center' | 'right'
export type Position = { v: vPos; h: hPos }

const vPosInverse: Record<vPos, vPos> = {
    top: 'bottom',
    center: 'center',
    bottom: 'top',
}
const hPosInverse: Record<hPos, hPos> = {
    left: 'right',
    center: 'center',
    right: 'left',
}
const inversePosition = (pos: Position): Position => ({
    v: vPosInverse[pos.v],
    h: hPosInverse[pos.h],
})

const RelativeWrapper = styled.div`
    position: relative;
`

const vPosMap: Record<vPos, number> = {
    top: 100,
    center: 50,
    bottom: 0,
}
const hPosMap: Record<hPos, number> = {
    left: 100,
    center: 50,
    right: 0,
}

/**
 * Low-level component to make content hide/show by scaling the content.
 */
type ExpandableElementProps = {
    origin: Position
    isExpanded: boolean
    expandTime?: string // Defaults to '0.2s'
    collapseTime?: string // Defaults to '0.2s'
}
export const RelativePlacement = styled.div`
    position: absolute;
    bottom: ${(props: { position: Position; anchorPos: Position }) =>
        vPosMap[props.position.v]}%;
    right: ${(props) => hPosMap[props.position.h]}%;
    transform: translateX(${(props) => hPosMap[props.anchorPos.h]}%)
        translateY(${(props) => vPosMap[props.anchorPos.v]}%);
`

export const ExpandableWrapper = styled.div`
    transform: ${(props: ExpandableElementProps) =>
        props.isExpanded ? 'scale(1)' : 'scale(0)'};
    transition: transform
        ${(props) =>
            (props.isExpanded ? props.expandTime : props.collapseTime) ||
            '0.2s'};
    transform-origin: ${(props) => `${props.origin.v} ${props.origin.h}`};
`

/**
 * Mid-level component to place the `placed`-node scale at the `position` relative to the component children
 * based on the `isExpanded`-flag
 */
type ExpandablePlacementProps = {
    content: React.ReactNode
    isExpanded: boolean
    position: Position
    anchorPos?: Position
    stopPropagation?: boolean
    children?: React.ReactNode
}
export const ExpandablePlacement: React.FunctionComponent<
    ExpandablePlacementProps
> = (props) => {
    const anchor = props.anchorPos || inversePosition(props.position)

    return (
        <RelativeWrapper
            onClick={
                props.stopPropagation !== false ? stopPropagation : undefined
            }
            role="presentation">
            <RelativePlacement
                position={props.position}
                anchorPos={props.anchorPos || anchor}>
                <ExpandableWrapper
                    origin={props.anchorPos || anchor}
                    isExpanded={props.isExpanded}>
                    {props.content}
                </ExpandableWrapper>
            </RelativePlacement>
            {props.children}
        </RelativeWrapper>
    )
}

/**
 * Higher order-component for making an element toggle the visibility of its children when clicked.
 * Provide position of child-element relative to the button and a FunctionComponent-method for making the actual button-element
 */
type AddedButtonProps = {
    isExpanded: boolean
    toggleExpand: () => void
    doExpand: () => void
}
type ButtonProps<T = object> = T & AddedButtonProps
type AddedContentProps = { isExpanded: boolean; doCollapse: () => void }
export type ContentProps<T = object> = T & AddedContentProps
export const ButtonWithExpandingContent =
    (position: Position, anchor?: Position) =>
    <TB = object, TC = object>(
        Button: React.ComponentType<ButtonProps<TB>>,
        Content: React.ComponentType<ContentProps<TC>>,
    ) => {
        type State = { isExpanded: boolean }
        return class SomeButtonWithExpandingOptions extends React.Component<
            TB & TC,
            State
        > {
            public state: State = { isExpanded: false }
            toggle = () => {
                this.setState({ isExpanded: !this.state.isExpanded })
            }
            collapse = () => {
                this.setState({ isExpanded: false })
            }
            expand = () => {
                this.setState({ isExpanded: true })
            }

            public render() {
                const addedButtonProps: AddedButtonProps = {
                    isExpanded: this.state.isExpanded,
                    toggleExpand: this.toggle,
                    doExpand: this.expand,
                }
                const addedContentProps: AddedContentProps = {
                    isExpanded: this.state.isExpanded,
                    doCollapse: this.collapse,
                }
                return (
                    <ExpandablePlacement
                        position={position}
                        isExpanded={this.state.isExpanded}
                        content={
                            <Content {...addedContentProps} {...this.props} />
                        }
                        anchorPos={anchor || position}>
                        <Button {...addedButtonProps} {...this.props} />
                    </ExpandablePlacement>
                )
            }
        }
    }

export const ButtonWithBottomRightExpandingContent = ButtonWithExpandingContent(
    { v: 'bottom', h: 'right' },
    { v: 'top', h: 'right' },
)
