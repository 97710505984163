import type { CropData, CroppieOptions } from 'croppie'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import styled from 'styled-components'
import SvgRotateCrop from '@capture/capture-components/src/icons/CropRotate'
import { _ } from '~/assets/localization/util'
import { colors, mediaQueries } from '~/assets/styleConstants'
import { CroppieComponent } from './CroppieComp'
import { FullscreenOverlay } from './FullscreenOverlay'
import { IconButton, TextButton } from './IconTextButton'

const CroppieContainer = styled.div`
    width: 600px;
    height: 75%;

    & .croppie-container .cr-slider-wrap > input[type='range' i] {
        &::-webkit-slider-runnable-track {
            background: white;
        }
        &::-moz-range-track {
            background: white;
        }
        &::-ms-track {
            background: white;
        }
    }

    ${mediaQueries.mobile} {
        width: 335px;
        height: ${(props: { cropViewportHeight: number }) =>
            props.cropViewportHeight + 65}px;
        transform: translateY(-65px);
    }
`

const CropButtonsWrapper = styled.div`
    width: 335px;
    margin: 45px auto 0;
    display: flex;
    flex-direction: row;
    justify-content: center;

    div {
        margin-right: 15px;
    }
`

export type CropArea = {
    top: number
    left: number
    width: number
    height: number
}
export type CropProps = {
    fileURL: string
    viewportSize: TwoDimensions
    viewportType?: 'square' | 'circle'
    viewportOverlay?: React.ReactElement<unknown>
    initialCropArea?: CropArea
    originalPhotoSize?: TwoDimensions
    onCroppingComplete: (data: CropArea) => void
    onCropChanged?: (data: CropArea) => void
    onCancel: () => void
    onFlipPressed?: () => void
}

type ComponentState = { sourceDimensions?: TwoDimensions }
export class CropPlacement extends React.Component<CropProps, ComponentState> {
    public state: ComponentState = {}
    private savedCropArea: CropArea | undefined = undefined

    private handleKeypress = (event: KeyboardEvent) => {
        // Edge & IE uses non-standard 'Esc' still
        if (
            event.key === 'Escape' ||
            event.key === 'Esc' ||
            event.keyCode === 27
        ) {
            this.props.onCancel()
        }
    }

    private recordCrop = (data?: CropData) => {
        if (data?.points) {
            const { width, height } = this.props.viewportSize
            let originalPhotoScaleFactor = 1
            if (this.state.sourceDimensions && this.props.originalPhotoSize) {
                originalPhotoScaleFactor = Math.min(
                    this.props.originalPhotoSize.width /
                        this.state.sourceDimensions.width,
                    this.props.originalPhotoSize.height /
                        this.state.sourceDimensions.height,
                )
            }
            this.savedCropArea = {
                width:
                    ((width + 4) / (data.zoom || 1)) * originalPhotoScaleFactor,
                height:
                    ((height + 4) / (data.zoom || 1)) *
                    originalPhotoScaleFactor,
                top: data.points[1] * originalPhotoScaleFactor || 0,
                left: data.points[0] * originalPhotoScaleFactor || 0,
            }
            this.props.onCropChanged?.(this.savedCropArea)
        }
    }
    private privateHandleCroppingComplete = () => {
        if (this.savedCropArea) {
            this.props.onCroppingComplete(this.savedCropArea)
        }
    }

    private setSourceDimensions = (
        e: React.SyntheticEvent<HTMLImageElement>,
    ) => {
        // thumbnail natural dimensions
        if (!this.state.sourceDimensions) {
            this.setState({
                sourceDimensions: {
                    width: e.currentTarget.naturalWidth,
                    height: e.currentTarget.naturalHeight,
                },
            })
        }
    }
    private getViewportOverlay() {
        const croppieViewport =
            document.getElementsByClassName('cr-viewport')[0]
        if (croppieViewport && this.props.viewportOverlay) {
            return ReactDOM.createPortal(
                this.props.viewportOverlay,
                croppieViewport,
            )
        }
    }
    public componentDidMount() {
        document.addEventListener('keyup', this.handleKeypress)
    }
    public componentWillUnmount() {
        document.removeEventListener('keyup', this.handleKeypress)
    }
    public render() {
        if (!this.state.sourceDimensions) {
            return (
                <img
                    src={this.props.fileURL}
                    onLoad={this.setSourceDimensions}
                    style={{ display: 'none' }}
                    alt="source"
                />
            )
        }

        const cropOptions: Partial<CroppieOptions> = {
            viewport: {
                ...this.props.viewportSize,
                type: this.props.viewportType || 'square',
            },
        }

        let initOptions:
            | { points: [number, number, number, number] }
            | undefined
        if (
            this.props.initialCropArea &&
            this.props.originalPhotoSize &&
            !this.savedCropArea
        ) {
            const scaleFactor = Math.min(
                this.state.sourceDimensions.width /
                    this.props.originalPhotoSize.width,
                this.state.sourceDimensions.height /
                    this.props.originalPhotoSize.height,
            )
            initOptions = {
                points: [
                    this.props.initialCropArea.left * scaleFactor,
                    this.props.initialCropArea.top * scaleFactor,
                    (this.props.initialCropArea.left +
                        this.props.initialCropArea.width) *
                        scaleFactor,
                    (this.props.initialCropArea.top +
                        this.props.initialCropArea.height) *
                        scaleFactor,
                ],
            }
        }
        const flipFrameBtn = this.props.onFlipPressed && (
            <IconButton
                onClick={this.props.onFlipPressed}
                color={colors.white}
                icon={SvgRotateCrop}
            />
        )
        return (
            <FullscreenOverlay color={colors.darkBackground}>
                <CroppieContainer
                    cropViewportHeight={this.props.viewportSize.height}>
                    <CroppieComponent
                        key={`${this.props.viewportSize.height}_${this.props.viewportSize.width}`}
                        url={this.props.fileURL}
                        options={cropOptions}
                        onCropChanged={this.recordCrop}
                        initOptions={initOptions}
                    />
                    <CropButtonsWrapper>
                        <TextButton
                            onClick={this.props.onCancel}
                            text={_('cancel')}
                            color="white"
                            data-cy="croppie-cancel"
                        />
                        <TextButton
                            onClick={this.privateHandleCroppingComplete}
                            text={_('ok')}
                            color="white"
                            data-cy="croppie-ok"
                        />
                        {flipFrameBtn}
                        {this.getViewportOverlay()}
                    </CropButtonsWrapper>
                </CroppieContainer>
            </FullscreenOverlay>
        )
    }
}
