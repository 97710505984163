import styled from 'styled-components'
import { fontSize } from '~/assets/styleConstants'

type BulletProps = { fontSize?: string; color?: string }

const BulletSpan = styled.span`
    font-size: ${(props: BulletProps) => props.fontSize || fontSize.small_12};
    margin: 0 4px;
    color: ${(props) => props.color || 'inherit'};
`

export const Bullet: React.FunctionComponent<BulletProps> = (props) => (
    <BulletSpan fontSize={props.fontSize} color={props.color}>
        •
    </BulletSpan>
)
