import {
    APP_STORE_LINK_ANDROID,
    APP_STORE_LINK_IOS,
    HOME_SITE_ADDRESS,
} from '~/config/constants'

import { isMobileDevice } from './device'
import { uuidToB64 } from './uuid'

export const openAlbumInApp = (albumID: JobID) => {
    if (isMobileDevice.iOS()) {
        goToAlbumOniOS(albumID)
    } else if (isMobileDevice.Android()) {
        goToAlbumOnAndroid(albumID)
    }
}

const getAppIntentURL = (intent: string) =>
    `https://goto.captu.re/?intent=${intent}`

export const goToAlbumOniOSLink = (albumID: JobID) =>
    getAppIntentURL(`a/${uuidToB64(albumID)}`)

export const goToAlbumOnAndroidLink = (albumID: JobID) =>
    `intent://captu.re/a/${uuidToB64(
        albumID,
    )}#Intent;scheme=https;package=com.comoyo.capture;S.browser_fallback_url=${encodeURIComponent(
        APP_STORE_LINK_ANDROID,
    )};end`

export const goToAlbumOniOS = (albumID: JobID) => {
    window.location.href = getAppIntentURL(`a/${uuidToB64(albumID)}`)
}

export const createAlbumOniOS = () => {
    window.location.href = 'capture-app://create_story'
}

export const goToAlbumOnAndroid = (albumID: JobID) => {
    // Redirect to intent-link which fallbacks to Play-store for installing the app if not present
    // TODO: Use mockery-service as fallback-url to transfer intent to the app post-install
    window.location.href = goToAlbumOnAndroidLink(albumID)
}

export const goToAppStore = () => {
    window.location.href = isMobileDevice.iOS()
        ? APP_STORE_LINK_IOS
        : APP_STORE_LINK_ANDROID
}

export const goToOpenWeb = () => {
    window.location.href = HOME_SITE_ADDRESS
}

export const buyMoreStorage = () => {
    window.location.href = `${location.host.replace(
        /^web\./,
        '',
    )}/deeplink/buy-more-storage`
}
