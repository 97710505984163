import * as React from 'react'
import styled from 'styled-components'
import { animations, colors, fontSize, layout } from '~/assets/styleConstants'
import { isMobileDevice } from '~/utilities/device'
import { PinchRecognizer } from './PinchRecognizer'

const Hint = styled.div`
    width: 100%;
    position: fixed;
    top: ${layout.topNavBarHeight}px;
    left: 50vw;
    transform: translateX(-50%);

    font-size: ${fontSize.small_14};
    color: white;
    text-align: center;
    padding: 8px;
    background-color: ${colors.captureGrey600};

    animation: ${animations.fadeIn} 0.3s;
`

export class ZoomExplainer extends React.PureComponent<
    { hintText: string; children?: React.ReactNode },
    { showHint: boolean }
> {
    public state: { showHint: boolean } = { showHint: false }
    private hintTimer?: number

    private onGestureAttempted = () => {
        if (!this.state.showHint) {
            this.setState({ showHint: true })
        }
        if (this.hintTimer) {
            window.clearTimeout(this.hintTimer)
        }

        this.hintTimer = window.setTimeout(
            () => this.setState({ showHint: false }),
            3000,
        )
    }
    public render() {
        if (!isMobileDevice.any() || isMobileDevice.iOS()) {
            // preventing zoom on iOS is buggy
            return this.props.children
        }

        return (
            <>
                <PinchRecognizer
                    onSpreading={this.onGestureAttempted}
                    onPinching={this.onGestureAttempted}>
                    {this.props.children}
                </PinchRecognizer>
                {this.state.showHint && <Hint>{this.props.hintText}</Hint>}
            </>
        )
    }
}

type ConditionalExplainerProps = {
    shouldExplainZoom: boolean
    hintText: string
    children?: React.ReactNode
}
export const ConditionalZoomExplainer: React.FunctionComponent<
    ConditionalExplainerProps
> = (props) => {
    if (!props.shouldExplainZoom) {
        return <>{props.children}</>
    }

    return (
        <ZoomExplainer hintText={props.hintText}>
            {props.children}
        </ZoomExplainer>
    )
}
