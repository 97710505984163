/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    CAPBAKAsyncUploadDetailByJobUuidAndChecksumParams,
    CAPBAKAsyncUploadExistsResponse,
    CAPBAKAsyncUploadPostByJobUuidAndChecksumParams,
    CAPBAKAsyncUploadResponse,
    CAPBAKAsyncUploadStatusAndChangesResponse,
    CAPBAKAsyncUploadStatusResponse,
    CAPBAKAttributeUpdateByJobUuidAndAttributeParams,
    CAPBAKChangesAndAsyncUploadStatusDetailParams,
    CAPBAKChangesDetailParams,
    CAPBAKChangesOption,
    CAPBAKClassificationDetailParams,
    CAPBAKCommentResponse,
    CAPBAKCoverDeleteParams,
    CAPBAKCoverPostParams,
    CAPBAKDefaultGetParams,
    CAPBAKDefaultJob,
    CAPBAKDeleteAlbumFromTrashCanDeleteParams,
    CAPBAKDeleteFilesFromTrashCanDeleteParams,
    CAPBAKDeletePostParams2,
    CAPBAKDeletedFilesInfoDetailParams,
    CAPBAKDeletedFilesInfoResponse,
    CAPBAKDetailedStoryInfoResponse,
    CAPBAKDetailedStoryResponse,
    CAPBAKExpirationDatePostParams,
    CAPBAKFileExistsByChecksumDetailByJobUuidAndChecksumParams,
    CAPBAKFileObjectResponse,
    CAPBAKFilePropResponse,
    CAPBAKFileUUIDList,
    CAPBAKFilesAsArchiveDetailParams,
    CAPBAKFilesAsArchivePostParams,
    CAPBAKFilesByIdCommentsDeleteByJobUuidAndFileUuidAndCommentUuidParams,
    CAPBAKFilesByIdCommentsPostParams,
    CAPBAKFilesByIdCommentsUpdateByJobUuidAndFileUuidAndCommentUuidParams,
    CAPBAKFilesByIdDeleteByJobUuidAndFileUuidParams,
    CAPBAKFilesByIdDeletePostParams,
    CAPBAKFilesByIdDetailByJobUuidAndFileUuidAndNameParams,
    CAPBAKFilesByIdDetailByJobUuidAndFileUuidAndPathParams,
    CAPBAKFilesByIdDetailByJobUuidAndFileUuidParams,
    CAPBAKFilesByIdReactionDeleteParams,
    CAPBAKFilesByIdReactionPostParams,
    CAPBAKFilesByUuidPostParams,
    CAPBAKFilesDedupDetailParams,
    CAPBAKFilesDedupPostParams,
    CAPBAKFilesDetailParams,
    CAPBAKFilesExistsByChecksumDetailParams,
    CAPBAKFilesFromTrashAsArchiveDetailParams,
    CAPBAKFilesFromTrashAsArchivePostParams,
    CAPBAKFilesPostParams,
    CAPBAKFilesPropertiesDetailParams,
    CAPBAKFilesPropertiesPostParams,
    CAPBAKHTTPValidationError,
    CAPBAKInfoDetailParams,
    CAPBAKInstant,
    CAPBAKInstantsDetailByJobUuidAndDateRangeParams,
    CAPBAKJobPermissionsResponse,
    CAPBAKJobPrivacyModeResponse,
    CAPBAKJobResponse,
    CAPBAKJobServiceResp,
    CAPBAKJobSetPermissionsResponse,
    CAPBAKJobsDeleteByJobUuidParams,
    CAPBAKJobsGetParams,
    CAPBAKJobsPostParams,
    CAPBAKJobsUpdateByJobUuidParams,
    CAPBAKKeepAllFilesPostParams,
    CAPBAKKeepFilesPostParams,
    CAPBAKKeepFilesPostPayload,
    CAPBAKKeepFilesQuotaExceeded,
    CAPBAKMessageResponse,
    CAPBAKMetadataDetailByJobUuidAndFileUuidParams,
    CAPBAKNamePostParams2,
    CAPBAKOptionsJobsParams,
    CAPBAKPartialMetadataResponse,
    CAPBAKPasswordPostParams,
    CAPBAKPermissionsDetailParams,
    CAPBAKPermissionsPostParams,
    CAPBAKPrivacyModeDetailParams,
    CAPBAKPrivacyModePostParams,
    CAPBAKPublishPostParams,
    CAPBAKPublishShareResponse,
    CAPBAKReactionsDetailParams,
    CAPBAKReactionsResponse,
    CAPBAKRecipientList,
    CAPBAKRecipientsPostParams,
    CAPBAKRollbackJobPostParams,
    CAPBAKRollbackPostParams,
    CAPBAKSetJobDataResponse,
    CAPBAKSetJobRecipientResponse,
    CAPBAKSetReactionResponse,
    CAPBAKShareExpiration,
    CAPBAKStorePasswordPostParams,
    CAPBAKStoryInfoResponse,
    CAPBAKStoryResponse,
    CAPBAKSubFileStatusResponse,
    CAPBAKSubscribePostParams,
    CAPBAKSyncUploadExistsResponse,
    CAPBAKSyncUploadResponse,
    CAPBAKTimelineDays,
    CAPBAKTimelineDaysDetailParams,
    CAPBAKTimelineMonths,
    CAPBAKTimelineMonthsDetailParams,
    CAPBAKUnsubscribePostParams,
    CAPBAKUploadPostParams,
    CAPBAKUploadResponse,
    CAPBAKUsageAndQuotaResponse,
    CAPBAKUsersDetailParams,
    CAPBAKUsersResponse,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Jobs<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * @description This is the method to create a new job. If the job is of type story, it is possible to specify the permission settings
     *
     * @tags jobs
     * @name JobsPost
     * @summary Post Job
     * @request POST:/jobs
     */
    jobsPost = (query: CAPBAKJobsPostParams, params: RequestParams = {}) =>
        this.request<
            CAPBAKSetJobDataResponse,
            void | CAPBAKHTTPValidationError
        >({
            path: `/jobs`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description The list of all jobs, shares, albums owned or shared with the user. Timeline jobs and shares are of the type `JobResponse`, albums, cover and profile jobs are of the type `JobListStoryResponse`. If `include_details` is `True`, albums will be of this type
     *
     * @tags jobs
     * @name JobsGet
     * @summary Get Job List
     * @request GET:/jobs
     */
    jobsGet = (query: CAPBAKJobsGetParams, params: RequestParams = {}) =>
        this.request<
            (
                | CAPBAKJobResponse
                | CAPBAKStoryResponse
                | CAPBAKDetailedStoryResponse
            )[],
            CAPBAKHTTPValidationError
        >({
            path: `/jobs`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Returns information on a specific job
     *
     * @tags jobs
     * @name InfoDetail
     * @summary Get Job Info
     * @request GET:/jobs/{job_uuid}/info
     */
    infoDetail = (
        { jobUuid, ...query }: CAPBAKInfoDetailParams,
        params: RequestParams = {},
    ) =>
        this.request<
            | CAPBAKJobResponse
            | CAPBAKStoryInfoResponse
            | CAPBAKDetailedStoryInfoResponse,
            void | CAPBAKHTTPValidationError
        >({
            path: `/jobs/${jobUuid}/info`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description This api call returns the service for the job_uuid. Note that the poll-host might be different for https (see description)
     *
     * @tags jobs
     * @name ServiceDetail
     * @summary Job Service Get Handler
     * @request GET:/jobs/{job_uuid}/service
     */
    serviceDetail = (jobUuid: string, params: RequestParams = {}) =>
        this.request<CAPBAKJobServiceResp, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/service`,
            method: 'GET',
            format: 'json',
            ...params,
        })
    /**
     * @description Returns users that are subscribers, or have previously been subscribers to an album
     *
     * @tags jobs
     * @name UsersDetail
     * @summary Get Album Users
     * @request GET:/jobs/{job_uuid}/users
     */
    usersDetail = (
        { jobUuid, ...query }: CAPBAKUsersDetailParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKUsersResponse, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/users`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description This api call returns the amount of images per day in the timeline.
     *
     * @tags jobs
     * @name TimelineDaysDetail
     * @summary Timeline Days
     * @request GET:/jobs/{job_uuid}/timeline/days
     */
    timelineDaysDetail = (
        { jobUuid, ...query }: CAPBAKTimelineDaysDetailParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKTimelineDays, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/timeline/days`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description This api call returns the amount of images per months in the timeline.
     *
     * @tags jobs
     * @name TimelineMonthsDetail
     * @summary Timeline Months
     * @request GET:/jobs/{job_uuid}/timeline/months
     */
    timelineMonthsDetail = (
        { jobUuid, ...query }: CAPBAKTimelineMonthsDetailParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKTimelineMonths, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/timeline/months`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description this call can be used to map the int type returned from the get changes into the textual type such as comment etc
     *
     * @tags changes
     * @name OptionsJobs
     * @summary Job Changes Options
     * @request OPTIONS:/jobs/changes
     */
    optionsJobs = (
        query: CAPBAKOptionsJobsParams,
        params: RequestParams = {},
    ) =>
        this.request<
            Record<string, CAPBAKChangesOption>,
            CAPBAKHTTPValidationError
        >({
            path: `/jobs/changes`,
            method: 'OPTIONS',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Add an attribute to a job. If attributes for a job is set, it will be part of the job object
     *
     * @tags jobs
     * @name AttributeUpdateByJobUuidAndAttribute
     * @summary Put Attribute On Job
     * @request PUT:/jobs/{job_uuid}/attribute/{attribute}
     */
    attributeUpdateByJobUuidAndAttribute = (
        {
            jobUuid,
            attribute,
            ...query
        }: CAPBAKAttributeUpdateByJobUuidAndAttributeParams,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/attribute/${attribute}`,
            method: 'PUT',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description This call returns the metadata of the specified file. The returned json object includes the standard Photo/Image exif fields of the image without the entire namespace tree. If it is called without the optional *full* argument it returns an object which may include the following tags * Make * Model * ApertureValue * ExposureTime * FocalLength * ISOSpeedRatings * Capture.GpsPosition * Capture.Country * Capture.Municipality There are capturized custom tags which are created for convenience reasons. These are added into *Capture* namespace and are the following * Capture.Parser * Capture.OrigWidth * Capture.OrigHeight * Capture.GpsPosition The *Capture.GpsPosition* is provided as a three-elements float list where indexes represent the *longitude*, *latitude* and *altitude* coordinates correspondingly. *NOTE* that this call could take long if the metadata is not in cache or if it hasn't been acquired yet (e.g. files already uploaded) since the for grabbing the metadata the original file must be fetched and processed.
     *
     * @tags files
     * @name MetadataDetailByJobUuidAndFileUuid
     * @summary Get File Metadata
     * @request GET:/jobs/{job_uuid}/metadata/{file_uuid}
     */
    metadataDetailByJobUuidAndFileUuid = (
        {
            jobUuid,
            fileUuid,
            ...query
        }: CAPBAKMetadataDetailByJobUuidAndFileUuidParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKPartialMetadataResponse | object,
            void | CAPBAKHTTPValidationError
        >({
            path: `/jobs/${jobUuid}/metadata/${fileUuid}`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Upload a file and connect it to the specified job. - Note when using a mobile API key and uploading a file with a name that already exists it will have file_name+checksum as filename. When using a PC client API key the existing file will be overwritten. - Note when uploading a file that would overwrite an existing path no FileEvent will be created for the deleted file.
     *
     * @tags upload
     * @name FilesPost
     * @summary Post Sync Upload
     * @request POST:/jobs/{job_uuid}/files
     */
    filesPost = (
        { jobUuid, ...query }: CAPBAKFilesPostParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKUploadResponse, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/files`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Lists files for a specific job that can be used in following ways: - If _path_ parameter specified every files in the directory will be returned (excluding paths from subdirectories) for the job. The query can be combined with _dirs_only_, _recursive_ or file filters such as  _file_type_filters_, __uploaded_since__ or __reaction__ parameters to reduce or extend the files to be returned. If _dirs_only_ specified  any value in file filters will be ignored. - If _path_ is not provided it returns all files belonging to the job. The returned files may be limited with _dirs_only_, _start_, _end_ or file filters query arguments. The _recursive_ parameter will be ignored if provided.
     *
     * @tags files
     * @name FilesDetail
     * @summary Get Job Files
     * @request GET:/jobs/{job_uuid}/files
     */
    filesDetail = (
        { jobUuid, ...query }: CAPBAKFilesDetailParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKFileObjectResponse[],
            void | CAPBAKHTTPValidationError
        >({
            path: `/jobs/${jobUuid}/files`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Upload a file to cache and schedule it for asynchronous processing. The call returns the file uuid. The endpoint validates user inputs and checksum if it is provided. If the transfer has been interrupted either by the client or due to unstable network, upload should be restarted from beginning.
     *
     * @tags upload
     * @name UploadPost
     * @summary Post Sync Upload With Async Processing
     * @request POST:/jobs/{job_uuid}/upload
     */
    uploadPost = (
        { jobUuid, ...query }: CAPBAKUploadPostParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKSyncUploadExistsResponse | CAPBAKSyncUploadResponse,
            void | CAPBAKHTTPValidationError
        >({
            path: `/jobs/${jobUuid}/upload`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description this api call is used to download a file. TODO: add header information
     *
     * @tags download
     * @name FilesByIdDetailByJobUuidAndFileUuid
     * @summary Get File
     * @request GET:/jobs/{job_uuid}/files_by_id/{file_uuid}
     */
    filesByIdDetailByJobUuidAndFileUuid = (
        {
            jobUuid,
            fileUuid,
            ...query
        }: CAPBAKFilesByIdDetailByJobUuidAndFileUuidParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKSubFileStatusResponse,
            void | CAPBAKHTTPValidationError
        >({
            path: `/jobs/${jobUuid}/files_by_id/${fileUuid}`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description this call archive the files for a job
     *
     * @tags jobs
     * @name FilesByIdDeleteByJobUuidAndFileUuid
     * @summary Delete File Delete
     * @request DELETE:/jobs/{job_uuid}/files_by_id/{file_uuid}
     */
    filesByIdDeleteByJobUuidAndFileUuid = (
        {
            jobUuid,
            fileUuid,
            ...query
        }: CAPBAKFilesByIdDeleteByJobUuidAndFileUuidParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKUsageAndQuotaResponse | null,
            void | CAPBAKHTTPValidationError
        >({
            path: `/jobs/${jobUuid}/files_by_id/${fileUuid}`,
            method: 'DELETE',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags upload
     * @name FilesDedupDetail
     * @summary Dedup File
     * @request GET:/jobs/{job_uuid}/files_dedup
     */
    filesDedupDetail = (
        { jobUuid, ...query }: CAPBAKFilesDedupDetailParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKUploadResponse, CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/files_dedup`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Add files to a job with no need to perform the actual upload of the file. Using the checksum of the file to link the job to an already uploaded file if Capture contains the file checksum. - Note when using a mobile API key and uploading a file with a name that already exists, it will have file_name+checksum as filename. When using a PC client API key the existing file will be overwritten. - Note when uploading a file that would overwrite an existing path no FileEvent will be created for the deleted file. - Due to some clients that are unable to POST with no data we also have a GET endpoint but it is not supported. - If *group_id* is specified, the file will be added to a group. One must also provide the *group_type*, and optionally the *master* flag, which defaults to 0.
     *
     * @tags upload
     * @name FilesDedupPost
     * @summary Dedup File
     * @request POST:/jobs/{job_uuid}/files_dedup
     */
    filesDedupPost = (
        { jobUuid, ...query }: CAPBAKFilesDedupPostParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKUploadResponse, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/files_dedup`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Get the status of a not yet complete upload
     *
     * @tags upload
     * @name AsyncUploadDetailByJobUuidAndChecksum
     * @summary Async Upload Check
     * @request GET:/jobs/{job_uuid}/async_upload/{checksum}
     */
    asyncUploadDetailByJobUuidAndChecksum = (
        {
            jobUuid,
            checksum,
            ...query
        }: CAPBAKAsyncUploadDetailByJobUuidAndChecksumParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKAsyncUploadStatusResponse,
            void | CAPBAKHTTPValidationError
        >({
            path: `/jobs/${jobUuid}/async_upload/${checksum}`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Upload a file and schedule it for asynchronous processing after the transfer has been completed. The endpoint validates user inputs and verifies the received data against the provided checksum. If the transfer has been interrupted either by the client  or due to unstable network the partial content will still be stored together with the known byte offset. The client can continue to upload the rest of the data by providing the correct offset. Note that the offset is not arbitrary. Discontinuity in the provided offset is not allowed and will be rejected. If the client offset differs the known offset will be returned in the error response.
     *
     * @tags upload
     * @name AsyncUploadPostByJobUuidAndChecksum
     * @summary Async Upload File
     * @request POST:/jobs/{job_uuid}/async_upload/{checksum}
     */
    asyncUploadPostByJobUuidAndChecksum = (
        {
            jobUuid,
            checksum,
            ...query
        }: CAPBAKAsyncUploadPostByJobUuidAndChecksumParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKAsyncUploadResponse | CAPBAKAsyncUploadExistsResponse,
            void | CAPBAKHTTPValidationError
        >({
            path: `/jobs/${jobUuid}/async_upload/${checksum}`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Get the status of a not yet complete uploads together with changes
     *
     * @tags upload
     * @name ChangesAndAsyncUploadStatusDetail
     * @summary Changes And Async Upload Status
     * @request GET:/jobs/{job_uuid}/changes_and_async_upload_status
     */
    changesAndAsyncUploadStatusDetail = (
        { jobUuid, ...query }: CAPBAKChangesAndAsyncUploadStatusDetailParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKAsyncUploadStatusAndChangesResponse,
            CAPBAKHTTPValidationError
        >({
            path: `/jobs/${jobUuid}/changes_and_async_upload_status`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description This call is deprecated use the same call minus name This api call is used to download a file. The name parameter is mandatory but it is not used to get file, since the file uuid is distinct so no name is needed TODO: add header information
     *
     * @tags download
     * @name FilesByIdDetailByJobUuidAndFileUuidAndName
     * @summary Get File With Name
     * @request GET:/jobs/{job_uuid}/files_by_id/{file_uuid}/{name}
     */
    filesByIdDetailByJobUuidAndFileUuidAndName = (
        {
            jobUuid,
            fileUuid,
            name,
            ...query
        }: CAPBAKFilesByIdDetailByJobUuidAndFileUuidAndNameParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKSubFileStatusResponse,
            void | CAPBAKHTTPValidationError
        >({
            path: `/jobs/${jobUuid}/files_by_id/${fileUuid}/${name}`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags download
     * @name FilesByIdDetailByJobUuidAndFileUuidAndPath
     * @summary Get File By Path
     * @request GET:/jobs/{job_uuid}/files_by_id/{file_uuid}/{path}
     */
    filesByIdDetailByJobUuidAndFileUuidAndPath = (
        {
            jobUuid,
            fileUuid,
            path,
            ...query
        }: CAPBAKFilesByIdDetailByJobUuidAndFileUuidAndPathParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKSubFileStatusResponse, CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/files_by_id/${fileUuid}/${path}`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description This is a method to download all files from a job.
     *
     * @tags download
     * @name FilesAsArchiveDetail
     * @summary Get Files As Archive
     * @request GET:/jobs/{job_uuid}/files_as_archive
     */
    filesAsArchiveDetail = (
        { jobUuid, ...query }: CAPBAKFilesAsArchiveDetailParams,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/files_as_archive`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description This is a method to get files as archived file.
     *
     * @tags download
     * @name FilesAsArchivePost
     * @summary Post Files As Archive
     * @request POST:/jobs/{job_uuid}/files_as_archive
     */
    filesAsArchivePost = (
        { jobUuid, ...query }: CAPBAKFilesAsArchivePostParams,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/files_as_archive`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description This is a method to download all deleted files from trash can for a job.
     *
     * @tags download
     * @name FilesFromTrashAsArchiveDetail
     * @summary Get Files From Trash As Archive
     * @request GET:/jobs/{job_uuid}/files_from_trash_as_archive
     */
    filesFromTrashAsArchiveDetail = (
        { jobUuid, ...query }: CAPBAKFilesFromTrashAsArchiveDetailParams,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/files_from_trash_as_archive`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description This is a method to get deleted files from trash can as archived file.
     *
     * @tags download
     * @name FilesFromTrashAsArchivePost
     * @summary Post Files From Trash As Archive
     * @request POST:/jobs/{job_uuid}/files_from_trash_as_archive
     */
    filesFromTrashAsArchivePost = (
        { jobUuid, ...query }: CAPBAKFilesFromTrashAsArchivePostParams,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/files_from_trash_as_archive`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description This endpoint is used to send share email and/or get the share url for a job
     *
     * @tags share
     * @name PublishPost
     * @summary Publish Share
     * @request POST:/jobs/{job_uuid}/publish
     */
    publishPost = (
        { jobUuid, ...query }: CAPBAKPublishPostParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKPublishShareResponse,
            void | CAPBAKHTTPValidationError
        >({
            path: `/jobs/${jobUuid}/publish`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description This method is used to update the job title and password
     *
     * @tags jobs
     * @name JobsUpdateByJobUuid
     * @summary Put Job
     * @request PUT:/jobs/{job_uuid}
     */
    jobsUpdateByJobUuid = (
        { jobUuid, ...query }: CAPBAKJobsUpdateByJobUuidParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKSetJobDataResponse,
            void | CAPBAKHTTPValidationError
        >({
            path: `/jobs/${jobUuid}`,
            method: 'PUT',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Delete a job
     *
     * @tags jobs
     * @name JobsDeleteByJobUuid
     * @summary Delete Job Delete
     * @request DELETE:/jobs/{job_uuid}
     */
    jobsDeleteByJobUuid = (
        { jobUuid, ...query }: CAPBAKJobsDeleteByJobUuidParams,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}`,
            method: 'DELETE',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description It extracts and returns changes occurred since the given file event id.
     *
     * @tags changes
     * @name ChangesDetail
     * @summary Get Changes
     * @request GET:/jobs/{job_uuid}/changes
     */
    changesDetail = (
        { jobUuid, ...query }: CAPBAKChangesDetailParams,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/changes`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Returns the default job for a user, if the default job does not exists it will be created
     *
     * @tags jobs
     * @name DefaultGet
     * @summary Get Default Job
     * @request GET:/jobs/default
     */
    defaultGet = (query: CAPBAKDefaultGetParams, params: RequestParams = {}) =>
        this.request<CAPBAKDefaultJob, CAPBAKHTTPValidationError>({
            path: `/jobs/default`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description this call is used to permanently delete files from trash can.
     *
     * @tags trash_can
     * @name DeleteFilesFromTrashCanDelete
     * @summary Delete Files From Trash Can
     * @request DELETE:/jobs/{job_uuid}/delete_files_from_trash_can
     */
    deleteFilesFromTrashCanDelete = (
        { jobUuid, ...query }: CAPBAKDeleteFilesFromTrashCanDeleteParams,
        data: CAPBAKFileUUIDList,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/delete_files_from_trash_can`,
            method: 'DELETE',
            query: query,
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
        })
    /**
     * @description this call is used to permanently delete album from trash can
     *
     * @tags trash_can
     * @name DeleteAlbumFromTrashCanDelete
     * @summary Delete Album From Trash Can
     * @request DELETE:/jobs/{job_uuid}/delete_album_from_trash_can
     */
    deleteAlbumFromTrashCanDelete = (
        { jobUuid, ...query }: CAPBAKDeleteAlbumFromTrashCanDeleteParams,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/delete_album_from_trash_can`,
            method: 'DELETE',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description List with deleted file information
     *
     * @tags files
     * @name DeletedFilesInfoDetail
     * @summary Get Deleted Files Info
     * @request GET:/jobs/{job_uuid}/deleted_files_info
     */
    deletedFilesInfoDetail = (
        { jobUuid, ...query }: CAPBAKDeletedFilesInfoDetailParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKDeletedFilesInfoResponse, CAPBAKHTTPValidationError>(
            {
                path: `/jobs/${jobUuid}/deleted_files_info`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            },
        )
    /**
     * @description Set properties of a file. Is currently used to set info on the grouping of a live/burst photo, but may be extended in the future to support more properties.
     *
     * @tags files
     * @name FilesPropertiesPost
     * @summary Post File Props
     * @request POST:/jobs/{job_uuid}/files/{file_uuid}/properties
     */
    filesPropertiesPost = (
        { jobUuid, fileUuid, ...query }: CAPBAKFilesPropertiesPostParams,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/files/${fileUuid}/properties`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Get the properties that are set on a file. Is currently used to return info on the grouping of a live/burst photo, but may be extended in the future to support more properties.
     *
     * @tags files
     * @name FilesPropertiesDetail
     * @summary Get File Props
     * @request GET:/jobs/{job_uuid}/files/{file_uuid}/properties
     */
    filesPropertiesDetail = (
        { jobUuid, fileUuid, ...query }: CAPBAKFilesPropertiesDetailParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKFilePropResponse, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/files/${fileUuid}/properties`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Check if a file exists in a job
     *
     * @tags files
     * @name FileExistsByChecksumDetailByJobUuidAndChecksum
     * @summary Get File Exists By Checksum Handler
     * @request GET:/jobs/{job_uuid}/file_exists_by_checksum/{checksum}
     */
    fileExistsByChecksumDetailByJobUuidAndChecksum = (
        {
            jobUuid,
            checksum,
            ...query
        }: CAPBAKFileExistsByChecksumDetailByJobUuidAndChecksumParams,
        params: RequestParams = {},
    ) =>
        this.request<Record<string, boolean>, void | CAPBAKHTTPValidationError>(
            {
                path: `/jobs/${jobUuid}/file_exists_by_checksum/${checksum}`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            },
        )
    /**
     * @description Check if files belong in a job
     *
     * @tags files
     * @name FilesExistsByChecksumDetail
     * @summary Get Files Exists By Checksum Handler
     * @request GET:/jobs/{job_uuid}/files_exists_by_checksum
     */
    filesExistsByChecksumDetail = (
        { jobUuid, ...query }: CAPBAKFilesExistsByChecksumDetailParams,
        params: RequestParams = {},
    ) =>
        this.request<Record<string, boolean>, void | CAPBAKHTTPValidationError>(
            {
                path: `/jobs/${jobUuid}/files_exists_by_checksum`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            },
        )
    /**
     * @description adds a comment to a file.
     *
     * @tags comments
     * @name FilesByIdCommentsPost
     * @summary Post Comment
     * @request POST:/jobs/{job_uuid}/files_by_id/{file_uuid}/comments
     */
    filesByIdCommentsPost = (
        { jobUuid, fileUuid, ...query }: CAPBAKFilesByIdCommentsPostParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKCommentResponse, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/files_by_id/${fileUuid}/comments`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description edit an existing comment
     *
     * @tags comments
     * @name FilesByIdCommentsUpdateByJobUuidAndFileUuidAndCommentUuid
     * @summary Edit Comment
     * @request PUT:/jobs/{job_uuid}/files_by_id/{file_uuid}/comments/{comment_uuid}
     */
    filesByIdCommentsUpdateByJobUuidAndFileUuidAndCommentUuid = (
        {
            jobUuid,
            fileUuid,
            commentUuid,
            ...query
        }: CAPBAKFilesByIdCommentsUpdateByJobUuidAndFileUuidAndCommentUuidParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKCommentResponse, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/files_by_id/${fileUuid}/comments/${commentUuid}`,
            method: 'PUT',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description delete an existing comment
     *
     * @tags comments
     * @name FilesByIdCommentsDeleteByJobUuidAndFileUuidAndCommentUuid
     * @summary Delete Comment
     * @request DELETE:/jobs/{job_uuid}/files_by_id/{file_uuid}/comments/{comment_uuid}
     */
    filesByIdCommentsDeleteByJobUuidAndFileUuidAndCommentUuid = (
        {
            jobUuid,
            fileUuid,
            commentUuid,
            ...query
        }: CAPBAKFilesByIdCommentsDeleteByJobUuidAndFileUuidAndCommentUuidParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKCommentResponse, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/files_by_id/${fileUuid}/comments/${commentUuid}`,
            method: 'DELETE',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description adds a reaction
     *
     * @tags reactions
     * @name FilesByIdReactionPost
     * @summary Post Reaction
     * @request POST:/jobs/{job_uuid}/files_by_id/{file_uuid}/reaction
     */
    filesByIdReactionPost = (
        { jobUuid, fileUuid, ...query }: CAPBAKFilesByIdReactionPostParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKSetReactionResponse,
            void | CAPBAKHTTPValidationError
        >({
            path: `/jobs/${jobUuid}/files_by_id/${fileUuid}/reaction`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description removes a reaction
     *
     * @tags reactions
     * @name FilesByIdReactionDelete
     * @summary Delete Reaction
     * @request DELETE:/jobs/{job_uuid}/files_by_id/{file_uuid}/reaction
     */
    filesByIdReactionDelete = (
        { jobUuid, fileUuid, ...query }: CAPBAKFilesByIdReactionDeleteParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKSetReactionResponse,
            void | CAPBAKHTTPValidationError
        >({
            path: `/jobs/${jobUuid}/files_by_id/${fileUuid}/reaction`,
            method: 'DELETE',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Get information for all active files that have a reaction connected to the file. Only owner can query this information
     *
     * @tags reactions
     * @name ReactionsDetail
     * @summary Get Reactions For Job
     * @request GET:/jobs/{job_uuid}/reactions
     */
    reactionsDetail = (
        { jobUuid, ...query }: CAPBAKReactionsDetailParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKReactionsResponse, void | CAPBAKHTTPValidationError>(
            {
                path: `/jobs/${jobUuid}/reactions`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            },
        )
    /**
     * @description Change the privacy mode of a story job. The underlying implementation manipulates the three flags allow_sharing, allow_anonymous_access and allow_discovery to conform to the privacy mode. Optionally, if the privacy mode is set to either protected or shared, a timestamp may be provided to specify the expiration date of the sharing. This can also be used to update the expiration date on a share, by providing the same privacy mode and an updated expiration timestamp. If private mode is provided, the expiration will always be removed.
     *
     * @tags jobs
     * @name PrivacyModePost
     * @summary Set Privacy Mode
     * @request POST:/jobs/{job_uuid}/privacy_mode
     */
    privacyModePost = (
        { jobUuid, ...query }: CAPBAKPrivacyModePostParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKJobSetPermissionsResponse,
            void | CAPBAKHTTPValidationError
        >({
            path: `/jobs/${jobUuid}/privacy_mode`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Get the current privacy mode of a story job.
     *
     * @tags jobs
     * @name PrivacyModeDetail
     * @summary Get Privacy Mode
     * @request GET:/jobs/{job_uuid}/privacy_mode
     */
    privacyModeDetail = (
        { jobUuid, ...query }: CAPBAKPrivacyModeDetailParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKJobPrivacyModeResponse,
            void | CAPBAKHTTPValidationError
        >({
            path: `/jobs/${jobUuid}/privacy_mode`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Change the permission settings on a story job. At least one permission setting is needed. Any unspecified settings is left unchanged
     *
     * @tags jobs
     * @name PermissionsPost
     * @summary Set Permissions
     * @request POST:/jobs/{job_uuid}/permissions
     */
    permissionsPost = (
        { jobUuid, ...query }: CAPBAKPermissionsPostParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKJobSetPermissionsResponse,
            void | CAPBAKHTTPValidationError
        >({
            path: `/jobs/${jobUuid}/permissions`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Get the current permission settings of a story job.
     *
     * @tags jobs
     * @name PermissionsDetail
     * @summary Get Permissions
     * @request GET:/jobs/{job_uuid}/permissions
     */
    permissionsDetail = (
        { jobUuid, ...query }: CAPBAKPermissionsDetailParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKJobPermissionsResponse,
            void | CAPBAKHTTPValidationError
        >({
            path: `/jobs/${jobUuid}/permissions`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description add story subscription
     *
     * @tags jobs
     * @name SubscribePost
     * @summary Subscribe To Story
     * @request POST:/jobs/{job_uuid}/subscribe
     */
    subscribePost = (
        { jobUuid, ...query }: CAPBAKSubscribePostParams,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/subscribe`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description unsubscription
     *
     * @tags jobs
     * @name UnsubscribePost
     * @summary Unsubscribe To Story
     * @request POST:/jobs/{job_uuid}/unsubscribe
     */
    unsubscribePost = (
        { jobUuid, ...query }: CAPBAKUnsubscribePostParams,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/unsubscribe`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description this call is used to restore a deleted album
     *
     * @tags jobs
     * @name RollbackJobPost
     * @summary Rollback Album
     * @request POST:/jobs/{job_uuid}/rollback_job
     */
    rollbackJobPost = (
        { jobUuid, ...query }: CAPBAKRollbackJobPostParams,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/rollback_job`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description this call is used to restore files from the trash can
     *
     * @tags jobs
     * @name RollbackPost
     * @summary Rollback File
     * @request POST:/jobs/{job_uuid}/rollback
     */
    rollbackPost = (
        { jobUuid, ...query }: CAPBAKRollbackPostParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKUsageAndQuotaResponse,
            void | CAPBAKHTTPValidationError
        >({
            path: `/jobs/${jobUuid}/rollback`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description this call archives the files for a job
     *
     * @tags jobs
     * @name FilesByIdDeletePost
     * @summary Post File Delete
     * @request POST:/jobs/{job_uuid}/files_by_id/{file_uuid}/delete
     * @deprecated
     */
    filesByIdDeletePost = (
        { jobUuid, fileUuid, ...query }: CAPBAKFilesByIdDeletePostParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKUsageAndQuotaResponse | null,
            void | CAPBAKHTTPValidationError
        >({
            path: `/jobs/${jobUuid}/files_by_id/${fileUuid}/delete`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Delete a job
     *
     * @tags jobs
     * @name DeletePost
     * @summary Post Job Delete
     * @request POST:/jobs/{job_uuid}/delete
     * @deprecated
     */
    deletePost = (
        { jobUuid, ...query }: CAPBAKDeletePostParams2,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/delete`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description This call saves all the files from a story or share to a timeline. Before attempting to keep any files, it will first check if the user has sufficient storage space available to save all the files. Note that it is possible to pass this initial test, but still receive a 413 if files are added to the account in question while this call is processed.
     *
     * @tags jobs
     * @name KeepAllFilesPost
     * @summary Keep All Files
     * @request POST:/jobs/{job_uuid}/keep-all-files
     */
    keepAllFilesPost = (
        { jobUuid, ...query }: CAPBAKKeepAllFilesPostParams,
        params: RequestParams = {},
    ) =>
        this.request<
            any,
            | void
            | CAPBAKMessageResponse
            | CAPBAKKeepFilesQuotaExceeded
            | CAPBAKHTTPValidationError
        >({
            path: `/jobs/${jobUuid}/keep-all-files`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description This call keeps all specified files from a story or share to a timeline Before attempting to keep any files, it will first check if the user has sufficient storage space available to save all the files. Note that it is possible to pass this initial test, but still receive a 413, if files are added to the account in question while this call is processed. If any of the files specified is the master of a group, all group members will be kept as well
     *
     * @tags jobs
     * @name KeepFilesPost
     * @summary Keep Files
     * @request POST:/jobs/{job_uuid}/keep-files
     */
    keepFilesPost = (
        { jobUuid, ...query }: CAPBAKKeepFilesPostParams,
        data: CAPBAKKeepFilesPostPayload,
        params: RequestParams = {},
    ) =>
        this.request<
            object,
            | void
            | CAPBAKMessageResponse
            | CAPBAKKeepFilesQuotaExceeded
            | CAPBAKHTTPValidationError
        >({
            path: `/jobs/${jobUuid}/keep-files`,
            method: 'POST',
            query: query,
            body: data,
            type: ContentType.Text,
            format: 'json',
            ...params,
        })
    /**
     * @description Set the allowed recipients of a shared job. The users can be provided by the recipients user_id or with an msisdn or email associated with the recipients TelenorID account. If an msisdn or email not associated with a TelenorID account is provided, a temporary link is created which can be used by the recipient when a TelenorID account is created and the share is accessed.
     *
     * @tags access_control
     * @name RecipientsPost
     * @summary Post Recipients
     * @request POST:/jobs/{job_uuid}/recipients
     */
    recipientsPost = (
        { jobUuid, ...query }: CAPBAKRecipientsPostParams,
        data: CAPBAKRecipientList,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKSetJobRecipientResponse,
            void | CAPBAKHTTPValidationError
        >({
            path: `/jobs/${jobUuid}/recipients`,
            method: 'POST',
            query: query,
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
        })
    /**
     * @description Set the expiration date of a shared job. This overrides the default share lifetime of 30 days
     *
     * @tags access_control
     * @name ExpirationDatePost
     * @summary Post Job Expiration
     * @request POST:/jobs/{job_uuid}/expiration_date
     */
    expirationDatePost = (
        { jobUuid, ...query }: CAPBAKExpirationDatePostParams,
        data: CAPBAKShareExpiration,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/expiration_date`,
            method: 'POST',
            query: query,
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
        })
    /**
     * @description this call is used to change the password for a story job.
     *
     * @tags access_control
     * @name PasswordPost
     * @summary Post Job Password
     * @request POST:/jobs/{job_uuid}/password
     */
    passwordPost = (
        { jobUuid, ...query }: CAPBAKPasswordPostParams,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/password`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description this call sets the cover id on a story job
     *
     * @tags jobs
     * @name CoverPost
     * @summary Post Job Cover
     * @request POST:/jobs/{job_uuid}/cover
     */
    coverPost = (
        { jobUuid, ...query }: CAPBAKCoverPostParams,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/cover`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description this call deletes the cover id on a story job
     *
     * @tags jobs
     * @name CoverDelete
     * @summary Delete Job Cover
     * @request DELETE:/jobs/{job_uuid}/cover
     */
    coverDelete = (
        { jobUuid, ...query }: CAPBAKCoverDeleteParams,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/cover`,
            method: 'DELETE',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description this call is used to change the title for shared and story jobs.
     *
     * @tags jobs
     * @name NamePost
     * @summary Post Job Name
     * @request POST:/jobs/{job_uuid}/name
     */
    namePost = (
        { jobUuid, ...query }: CAPBAKNamePostParams2,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/name`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description this call is used to update the password for a user to a story
     *
     * @tags access_control
     * @name StorePasswordPost
     * @summary Post Job Store Password
     * @request POST:/jobs/{job_uuid}/store-password
     */
    storePasswordPost = (
        { jobUuid, ...query }: CAPBAKStorePasswordPostParams,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/store-password`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Get the list of photos that might fall into the provided category.
     *
     * @tags search
     * @name ClassificationDetail
     * @summary Get Photos Classified As Documents
     * @request GET:/jobs/{job_uuid}/classification
     */
    classificationDetail = (
        { jobUuid, ...query }: CAPBAKClassificationDetailParams,
        params: RequestParams = {},
    ) =>
        this.request<object, CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/classification`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Lists files given by uuid for a specific job. The returned files may be reduced with file filters such as _file_type_filters_, _uploaded_since_ or _reaction_ parameters
     *
     * @tags files
     * @name FilesByUuidPost
     * @summary Post Files By Uuid
     * @request POST:/jobs/{job_uuid}/files_by_uuid
     */
    filesByUuidPost = (
        { jobUuid, ...query }: CAPBAKFilesByUuidPostParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKFileObjectResponse[],
            void | CAPBAKHTTPValidationError
        >({
            path: `/jobs/${jobUuid}/files_by_uuid`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Get memories from a job for a date range
     *
     * @tags jobs
     * @name InstantsDetailByJobUuidAndDateRange
     * @summary Get Instant By Date
     * @request GET:/jobs/{job_uuid}/instants/{date_range}
     */
    instantsDetailByJobUuidAndDateRange = (
        {
            jobUuid,
            dateRange,
            ...query
        }: CAPBAKInstantsDetailByJobUuidAndDateRangeParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKInstant, void | CAPBAKHTTPValidationError>({
            path: `/jobs/${jobUuid}/instants/${dateRange}`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
}
