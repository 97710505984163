import type { Action } from '../common/actions'
import { isType } from '../common/actions'
import type { FileMetadata } from './actions'
import { FileMetadataWasFetched } from './actions'

export type FileMetadataState = Record<FileID, FileMetadata>

const initialState = {}

export function fileMetadataReducer(
    state: FileMetadataState = initialState,
    action: Action,
): FileMetadataState {
    if (isType(action, FileMetadataWasFetched)) {
        return {
            ...state,
            [action.payload.fileID]: action.payload,
        }
    }

    return state
}

export const fileMetadataReducerMapObj = {
    fileMetadata: fileMetadataReducer,
}

export type StateWithFileMetadata = StateOfReducerMapObj<
    typeof fileMetadataReducerMapObj
>
