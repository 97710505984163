import type { SVGProps } from 'react'
const SvgPhotoDetailsFilled = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill={props.color ?? 'currentColor'}
            d="M5 21c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 3 19V5c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 5 3h14c.55 0 1.02.196 1.413.587C20.804 3.98 21 4.45 21 5v14c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 19 21zm2-4h10c.2 0 .35-.092.45-.275a.44.44 0 0 0-.05-.525l-2.75-3.675a.475.475 0 0 0-.4-.2c-.167 0-.3.067-.4.2L11.25 16 9.4 13.525a.475.475 0 0 0-.4-.2c-.167 0-.3.067-.4.2l-2 2.675a.44.44 0 0 0-.05.525c.1.183.25.275.45.275m1.5-7c.417 0 .77-.146 1.063-.437C9.854 9.27 10 8.917 10 8.5c0-.417-.146-.77-.437-1.062A1.447 1.447 0 0 0 8.5 7c-.417 0-.77.146-1.062.438A1.447 1.447 0 0 0 7 8.5c0 .417.146.77.438 1.063.291.291.645.437 1.062.437"
        />
    </svg>
)
export default SvgPhotoDetailsFilled
