import type { SVGProps } from 'react'
const SvgSetAsCoverPhoto = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill={props.color ?? 'currentColor'}
            d="M5 21c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 3 19v-5c0-.283.096-.52.288-.713A.967.967 0 0 1 4 13c.283 0 .52.096.713.287.191.192.287.43.287.713v5h5c.283 0 .52.096.713.288.191.191.287.429.287.712s-.096.52-.287.712A.968.968 0 0 1 10 21zm14 0h-5a.968.968 0 0 1-.713-.288A.968.968 0 0 1 13 20c0-.283.096-.52.287-.712A.968.968 0 0 1 14 19h5v-5c0-.283.096-.52.288-.713A.968.968 0 0 1 20 13c.283 0 .52.096.712.287.192.192.288.43.288.713v5c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 19 21m-7.75-5 2.6-3.475c.1-.133.233-.2.4-.2s.3.067.4.2L17.4 16.2a.44.44 0 0 1 .05.525A.484.484 0 0 1 17 17H7c-.2 0-.35-.092-.45-.275a.44.44 0 0 1 .05-.525l2-2.675c.1-.133.233-.2.4-.2s.3.067.4.2zM3 5c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 5 3h5c.283 0 .52.096.713.288.191.191.287.429.287.712s-.096.52-.287.713A.968.968 0 0 1 10 5H5v5c0 .283-.096.52-.287.713A.967.967 0 0 1 4 11a.967.967 0 0 1-.712-.287A.968.968 0 0 1 3 10zm18 0v5c0 .283-.096.52-.288.713A.968.968 0 0 1 20 11a.968.968 0 0 1-.712-.287A.968.968 0 0 1 19 10V5h-5a.968.968 0 0 1-.713-.287A.967.967 0 0 1 13 4c0-.283.096-.52.287-.712A.968.968 0 0 1 14 3h5c.55 0 1.02.196 1.413.587C20.804 3.98 21 4.45 21 5m-5.5 5c-.433 0-.792-.142-1.075-.425C14.142 9.292 14 8.933 14 8.5c0-.433.142-.792.425-1.075.283-.283.642-.425 1.075-.425.433 0 .792.142 1.075.425.283.283.425.642.425 1.075 0 .433-.142.792-.425 1.075-.283.283-.642.425-1.075.425"
        />
    </svg>
)
export default SvgSetAsCoverPhoto
