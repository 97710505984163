/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    CAPBAKDeletePostParams,
    CAPBAKDevicesDeleteByDeviceIdParams,
    CAPBAKDevicesGetParams,
    CAPBAKDevicesWithAuth,
    CAPBAKHTTPValidationError,
    CAPBAKMessageResponse,
} from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class Devices<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * @description This call list out the different devices
     *
     * @tags account_info
     * @name DevicesGet
     * @summary Get Devices
     * @request GET:/devices
     */
    devicesGet = (query: CAPBAKDevicesGetParams, params: RequestParams = {}) =>
        this.request<CAPBAKDevicesWithAuth, CAPBAKHTTPValidationError>({
            path: `/devices`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description This api call is used to invalidate the auth token for the device
     *
     * @tags logout
     * @name DevicesDeleteByDeviceId
     * @summary Delete Devices
     * @request DELETE:/devices/{device_id}
     */
    devicesDeleteByDeviceId = (
        { deviceId, ...query }: CAPBAKDevicesDeleteByDeviceIdParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKMessageResponse, CAPBAKHTTPValidationError>({
            path: `/devices/${deviceId}`,
            method: 'DELETE',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Use DELETE /devices/{device_id}
     *
     * @tags logout
     * @name DeletePost
     * @summary Delete Devices
     * @request POST:/devices/{device_id}/delete
     * @deprecated
     */
    deletePost = (
        { deviceId, ...query }: CAPBAKDeletePostParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKMessageResponse, CAPBAKHTTPValidationError>({
            path: `/devices/${deviceId}/delete`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
}
