import type { Middleware } from '@reduxjs/toolkit'
import {
    init,
    addBreadcrumb,
    configureScope,
    BrowserTracing,
    type Event,
} from '@sentry/react'
import { isTestEnv } from '~/config/constants'
import { getAnalyticsSafeURL } from '~/utilities/urlParsing'

type SentryOptions = {
    dsn?: string
    blackList?: Array<string | RegExp>
    showActionPayload?: boolean
}

export const makeSentryMiddleware = ({
    dsn,
    blackList,
    showActionPayload = true,
}: SentryOptions): Middleware => {
    if (dsn) {
        init({
            dsn: isTestEnv ? undefined : dsn,
            release: import.meta.env.VITE_VERSION as string,
            integrations: [new BrowserTracing()],
            ignoreErrors: [
                // Ignore this error as it's flooding our logs and is impossible to find #YOLO (#CAPTURE-ERRORS-AV)
                "Cannot read property 'innerHTML' of null",
                // Ignore ResizeObserver error since it is not standard specification which only supported by Chrome 64+,
                // and it does not break app
                'ResizeObserver loop limit exceeded',
            ],
            tracesSampleRate: 0.5,
            denyUrls: blackList || [/staging./, /univex.no/],
            beforeSend: (event: Event) => ({
                // obfuscate URLs with IDs
                ...event,
                request: event.request && {
                    ...event.request,
                    url: getAnalyticsSafeURL(event.request.url || ''),
                },
            }),
        })
    }

    return (store) => {
        configureScope((scope) => {
            scope.addEventProcessor((event) => ({
                ...event,
                extra: {
                    ...event.extra,
                    currentState: store.getState(),
                },
            }))
        })

        return (next) => (action) => {
            // TODO: Divide large months into smaller sections, so files fetched payloads won't be enormous
            addBreadcrumb({
                message: action.type,
                category: 'redux action',
                level: 'info',
                data: showActionPayload
                    ? { payload: action.payload }
                    : undefined,
            })
            next(action)
        }
    }
}
