export const bytesToSize = (bytes: number, decimals?: number): string => {
    if (bytes <= 0) {
        return '0 Bytes'
    }
    if (bytes === Infinity) {
        return 'Infinity'
    }
    const c = 1024
    const sizes = ['Bytes', 'KB', 'MB', 'GB']
    const i = Math.floor(Math.log(bytes) / Math.log(c))
    const size = bytes / Math.pow(c, Math.min(i, sizes.length - 1))
    if (decimals === undefined) {
        decimals = size % 1 === 0 ? 0 : 2
    }
    return (
        size.toFixed(i < 2 ? 0 : decimals) +
        ' ' +
        sizes[Math.min(i, sizes.length - 1)]
    )
}

/**
 * Calculates megapixels from an image resolution
 */
export const getMegapixels = (width: number, height: number): number => {
    // TODO: standard resolutions have names (eg FHD, QHD), it could be useful to add a map of standard resolutions
    return Math.round((width * height) / 100000) / 10
}
