import type { Selector } from 'reselect'
import { createSelector } from 'reselect'
import type { FileGroupType, JobFile } from '~/@types/backend-types'
import { ThumbService } from '~/API/services/ThumbService'
import type { VideoQuality } from '~/API/services/VideoService'
import { VideoService } from '~/API/services/VideoService'
import { cachedInArray } from '~/utilities/arrayUtils'
import {
    Dictionary_asArray,
    Dictionary_filter,
    Dictionary_map,
    Dictionary_reduce,
} from '~/utilities/dictionaryUtils'
import { FileTarget, getFileTargetFromName } from '~/utilities/fileTarget'
import { getAuthTokenForJob } from '../currentUser/selectors'
import type { FileMetadata } from '../fileMetadata/actions'
import { getFileMetadata } from '../fileMetadata/selectors'
import { getThumbHost, getVideoHost } from '../hosts/selectors'
import type { EncodingStatus, ExtendedJobFile, StateWithFiles } from './reducer'
import { CommentStatus } from './reducer'

const isFolder = (file: ExtendedJobFile) => {
    return file.path && file.path.charAt(file.path.length - 1) === '/'
}
const isPresentableFile = (f: ExtendedJobFile) => !f.isDeleted && !isFolder(f)

export const getFilesByJob = createSelector(
    (state: StateWithFiles) => state.files.files,
    (files): Record<JobID, Record<FileID, ExtendedJobFile>> =>
        Dictionary_map(files, (v) => Dictionary_filter(v, isPresentableFile)),
)
export const getAllJobFiles = createSelector(
    (state: StateWithFiles, jobID: JobID) => state.files.files[jobID] || {},
    (files): ExtendedJobFile[] =>
        Dictionary_asArray(files)
            .map((e) => e.value)
            .filter((f) => !isFolder(f)),
)
export const getJobFiles = createSelector(
    (state: StateWithFiles, jobID: JobID) => state.files.files[jobID] || {},
    (files): ExtendedJobFile[] =>
        Dictionary_asArray(files)
            .map((e) => e.value)
            .filter(isPresentableFile),
)

export const getFilesByJobAsArray = createSelector(
    getFilesByJob,
    (files): Record<JobID, ExtendedJobFile[]> =>
        Dictionary_map(files, (f) =>
            Dictionary_asArray(f).map(({ value }) => value),
        ),
)

// includes deletedFiles
export const getTotalFileCount = (state: StateWithFiles): number =>
    Dictionary_reduce(
        state.files.files,
        (acc, val) => acc + Object.keys(val).length,
        0,
    )

/**
 * @deprecated Use getFilesByJob or getJobFiles instead since state is restructured
 */
export const getFiles = createSelector(
    getFilesByJob,
    (files): Record<FileID, ExtendedJobFile> => {
        return Object.assign(
            {},
            ...Dictionary_asArray(files).map(({ value }) => value),
        )
    },
)

export const getFileByID = (
    state: StateWithFiles,
    fileID: FileID,
): ExtendedJobFile | undefined => {
    const job = state.files._fileJobIDMap[fileID]
    return job && state.files.files[job]
        ? state.files.files[job][fileID]
        : undefined
}

export type ThumbSelector = Selector<
    StateOfSelector<typeof getThumbHost> &
        StateOfSelector<typeof getAuthTokenForJob> &
        StateOfSelector<typeof getJobFiles>,
    Record<FileID, string>,
    [JobID]
>

export const makeThumbForJobFilesSelector = (size: number): ThumbSelector =>
    createSelector(
        getThumbHost,
        getAuthTokenForJob,
        getJobFiles,
        (
            host: string | null,
            auth: string,
            files: ExtendedJobFile[],
        ): Record<FileID, string> => {
            const thumbURLDictionary: Record<FileID, string> = {}
            if (host) {
                const service = new ThumbService(host, auth)

                files.forEach((file: ExtendedJobFile) => {
                    const { fileID, jobID } = file
                    thumbURLDictionary[fileID] = service.getThumbUrl(
                        jobID,
                        fileID,
                        size,
                    )
                })
            }

            return thumbURLDictionary
        },
    )

export const compareFileTime = (a: ExtendedJobFile, b: ExtendedJobFile) => {
    let sort = (b.ctime || b.mtime) - (a.ctime || a.mtime)
    if (sort === 0) {
        // Time is equal - sort by path (filename)
        sort = a.path.localeCompare(b.path)
    }
    return sort
}

const getAllCaptureFilesForJob = createSelector(
    getJobFiles,
    makeThumbForJobFilesSelector(256),
    makeThumbForJobFilesSelector(512),
    makeThumbForJobFilesSelector(1280),
    getFileMetadata,
    (
        files: ExtendedJobFile[],
        thumbSmall: Record<FileID, string>,
        thumbMedium: Record<FileID, string>,
        thumbLarge: Record<FileID, string>,
        metadata: Record<FileID, FileMetadata>,
    ): CaptureFile[] => {
        return files
            .map((file) => {
                let aspectRatio = 1
                if (file.width && file.height) {
                    aspectRatio = Math.max(
                        file.width / file.height,
                        file.height / file.width,
                    )
                }

                return {
                    ...file,
                    type: getFileType(file),
                    thumbURLSmall:
                        aspectRatio > 2
                            ? thumbMedium[file.fileID]
                            : thumbSmall[file.fileID],
                    thumbURLMedium: thumbMedium[file.fileID],
                    thumbURLLarge: thumbLarge[file.fileID],
                    metadata: metadata[file.fileID],
                }
            })
            .sort(compareFileTime)
    },
)
export type CaptureFile = ExtendedJobFile & {
    type: FileTarget
    thumbURLSmall: string
    thumbURLMedium: string
    thumbURLLarge: string
    metadata?: FileMetadata
    dtime?: number
}

export type BasicViewFile = Omit<
    CaptureFile,
    | 'addedBy'
    | 'checksum'
    | 'timestamp'
    | 'size'
    | 'pendingComment'
    | 'pendingCommentStatus'
    | 'isPendingServerDelete'
    | 'isDeleted'
>

type FileGroupsDictionary = Record<
    string,
    {
        type: FileGroupType
        masterID: FileID
        files: CaptureFile[]
    }
>
export const getJobGroupFilesDict = createSelector(
    getAllCaptureFilesForJob,
    (capFiles): FileGroupsDictionary => {
        const initGroupsDict: Record<
            string,
            {
                type: FileGroupType
                masterID: FileID
                files: CaptureFile[]
            }
        > = {}
        const looseFilesDict: Record<string, CaptureFile[]> = {}
        capFiles.forEach((f) => {
            if (f.group) {
                if (f.group.isMaster) {
                    initGroupsDict[f.group.id] = {
                        type: f.group.type,
                        masterID: f.fileID,
                        files: [],
                    }
                } else {
                    looseFilesDict[f.group.id] = [
                        ...(looseFilesDict[f.group.id] || []),
                        f,
                    ]
                }
            }
        })
        const groupDict: Record<
            string,
            {
                type: FileGroupType
                masterID: FileID
                files: CaptureFile[]
            }
        > = {}
        Object.keys(initGroupsDict).forEach((k) => {
            groupDict[k] = {
                ...initGroupsDict[k],
                files: looseFilesDict[k] || [],
            }
        })
        return groupDict
    },
)

export type FileWithGrouping<T extends object = ExtendedJobFile> = T & {
    livePhotoFile?: T
    burstFiles?: T[]
}

export const getCaptureFilesForJob = createSelector(
    // only includes Pictures and Movies
    getAllCaptureFilesForJob,
    getJobGroupFilesDict,
    (files: CaptureFile[], groupDict): Array<FileWithGrouping<CaptureFile>> => {
        const masterOnlyFiles = files.filter(
            (capFile) =>
                (capFile.type === FileTarget.Pictures ||
                    capFile.type === FileTarget.Movies) &&
                (capFile.group === undefined || capFile.group.isMaster),
        )

        return masterOnlyFiles.map((file) => {
            let burstFiles
            let livePhotoFile

            if (file.group && groupDict && groupDict[file.group.id]) {
                if (file.group.type === 'live') {
                    livePhotoFile = groupDict[file.group.id].files[0]
                } else if (file.group.type === 'burst') {
                    burstFiles = groupDict[file.group.id].files
                }
            }

            return {
                ...file,
                burstFiles,
                livePhotoFile,
            }
        })
    },
)
export const getJobFilesWithGrouping = createSelector(
    getJobFiles,
    getJobGroupFilesDict,
    (files, groupDict): FileWithGrouping[] => {
        return files.map((file) => {
            let burstFiles
            let livePhotoFile

            if (file.group?.isMaster && groupDict && groupDict[file.group.id]) {
                if (file.group.type === 'live') {
                    livePhotoFile = groupDict[file.group.id].files[0]
                } else if (file.group.type === 'burst') {
                    burstFiles = groupDict[file.group.id].files
                }
            }

            return {
                ...file,
                burstFiles,
                livePhotoFile,
            }
        })
    },
)

export const getCaptureFilesByID = (
    state: StateOfSelector<typeof getCaptureFilesForJob>,
    jobID: JobID,
    fileIDs: FileID[],
): CaptureFile[] => {
    const captureFiles = getCaptureFilesForJob(state, jobID)
    const files = cachedInArray(fileIDs)
    return captureFiles.filter((f) => files(f.fileID))
}

export const getDocumentFilesForJob: (
    state: StateWithFiles,
    jobID: JobID,
) => ExtendedJobFile[] = createSelector(
    getJobFiles,
    (files: ExtendedJobFile[]) => {
        return files
            .filter((f) => getFileType(f) === FileTarget.Documents)
            .sort(compareFileTime)
    },
)

// Extra memoization to avoid reference-checking causing re-calculating in child selectors
// (as files-state changes a lot while id of first file in job changes almost never)
let prev_getFirstFileIDByJob: Record<JobID, FileID> = {}
export const getFirstFileIDByJob = createSelector(
    getFilesByJobAsArray,
    (files): Record<JobID, FileID> => {
        const changes: Record<JobID, FileID> = {}
        Object.keys(files).forEach((jobID) => {
            const firstFile = files[jobID][0] || {}
            if (prev_getFirstFileIDByJob[jobID] !== firstFile.fileID) {
                changes[jobID] = firstFile.fileID
            }
        })
        Object.keys(prev_getFirstFileIDByJob).forEach((jobID) => {
            const jobFiles = files[jobID] || []
            if (jobFiles.length === 0) {
                const { [jobID]: _omit, ...rest } = prev_getFirstFileIDByJob
                prev_getFirstFileIDByJob = rest
            }
        })
        if (Object.keys(changes).length > 0) {
            prev_getFirstFileIDByJob = {
                ...prev_getFirstFileIDByJob,
                ...changes,
            }
        }
        return prev_getFirstFileIDByJob
    },
)

export const getCurrentCommentText = (
    state: StateWithFiles,
    fileID: FileID,
): string => {
    const file = getFileByID(state, fileID)
    return file ? file.pendingComment : ''
}

export const getCommentStatus = (
    state: StateWithFiles,
    fileID: FileID,
): CommentStatus => {
    const file = getFileByID(state, fileID)
    return file ? file.pendingCommentStatus : CommentStatus.NOT_YET_SUBMITTED
}

export const getEncodingStatus = (
    state: StateWithFiles,
    fileID: FileID,
): EncodingStatus | undefined => {
    const file = getFileByID(state, fileID)
    return file ? file.encodingStatus : undefined
}

export const getVideoURL = (
    state: StateOfSelector<typeof getVideoHost> &
        StateOfSelector<typeof getAuthTokenForJob>,
    jobID: JobID,
    videoID: FileID,
    quality: VideoQuality,
): string | undefined => {
    const host = getVideoHost(state)
    if (host) {
        const auth = getAuthTokenForJob(state, jobID)
        const service = new VideoService(host, auth)
        return service.getVideoUrl(jobID, videoID, quality)
    }
}

export const getFileType = (file: ExtendedJobFile | JobFile): FileTarget => {
    if (file.duration !== undefined) {
        // newly uploaded file
        return file.duration !== 0 ? FileTarget.Movies : FileTarget.Pictures
    }
    // fallback to check file name
    return getFileTargetFromName(file.path)
}
