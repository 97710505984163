import { createActionCreator, createVoidActionCreator } from '../common/actions'

export const DownloadShareClicked = createActionCreator<JobID>(
    'DOWNLOAD_SHARE_CLICKED',
)
export const DownloadShareSucceeded = createActionCreator<JobID>(
    'DOWNLOAD_SHARE_SUCCEEDED',
)
export const DownloadShareFailed = createActionCreator<JobID>(
    'DOWNLOAD_SHARE_FAILED',
)

export const ShareCopiedToTimeline = createVoidActionCreator(
    'SHARE_COPIED_TO_TIMELINE',
)
export const ShareCopiedToTimelineFailed = createVoidActionCreator(
    'SHARE_COPIED_TO_TIMELINE_FAILED',
)

export const DeleteShareSucceeded = createActionCreator<JobID>(
    'DELETE_SHARE_SUCCEEDED',
)
export const DeleteShareFailed = createActionCreator<JobID>(
    'DELETE_SHARE_FAILED',
)
