import type { SVGProps } from 'react'
const SvgShareAsAlbum = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill={props.color ?? 'currentColor'}
            d="M6.5 16c.783 0 1.546.087 2.287.262.742.175 1.48.438 2.213.788V7.2c-.683-.4-1.408-.7-2.175-.9a9.175 9.175 0 0 0-4.112-.125A8.665 8.665 0 0 0 3 6.7v9.9c.583-.2 1.162-.35 1.737-.45C5.313 16.05 5.9 16 6.5 16m6.5 1.05c.733-.35 1.47-.613 2.213-.788a10.268 10.268 0 0 1 4.05-.113c.575.101 1.154.251 1.737.451V6.7a8.664 8.664 0 0 0-1.712-.525 9.218 9.218 0 0 0-4.113.125c-.767.2-1.492.5-2.175.9zm-1 2.425c-.233 0-.454-.03-.662-.088a2.796 2.796 0 0 1-.588-.237 8.182 8.182 0 0 0-2.05-.862A8.477 8.477 0 0 0 6.5 18c-.7 0-1.388.092-2.062.275a9.05 9.05 0 0 0-1.938.775c-.35.183-.687.175-1.012-.025A.966.966 0 0 1 1 18.15V6.1c0-.183.046-.358.137-.525A.863.863 0 0 1 1.55 5.2c.767-.4 1.567-.7 2.4-.9.833-.2 1.683-.3 2.55-.3A10.78 10.78 0 0 1 12 5.5 10.78 10.78 0 0 1 17.5 4c.867 0 1.717.1 2.55.3.833.2 1.633.5 2.4.9.183.083.32.208.413.375.091.167.137.342.137.525v12.05a.966.966 0 0 1-.488.875c-.325.2-.662.208-1.012.025a9.05 9.05 0 0 0-1.937-.775A7.836 7.836 0 0 0 17.5 18c-.75 0-1.483.096-2.2.288-.717.191-1.4.479-2.05.862-.183.1-.38.18-.588.238-.208.058-.429.087-.662.087"
        />
        <path
            fill={props.color ?? 'currentColor'}
            d="M9.648 14.148a1.156 1.156 0 0 1-.848.352c-.33 0-.613-.117-.848-.352A1.156 1.156 0 0 1 7.6 13.3v-.09l-1.605-.81c-.11.09-.232.162-.367.217a1.122 1.122 0 0 1-.428.083c-.33 0-.612-.117-.848-.352A1.156 1.156 0 0 1 4 11.5c0-.33.117-.613.353-.848.235-.234.517-.352.847-.352.15 0 .292.027.428.082.135.056.257.128.367.218L7.6 9.79V9.7c0-.33.118-.612.353-.848.234-.235.517-.352.847-.352.33 0 .612.117.848.352.235.236.352.518.352.848 0 .33-.117.613-.352.848a1.156 1.156 0 0 1-.848.352c-.15 0-.293-.027-.428-.082a1.426 1.426 0 0 1-.367-.218l-1.605.81v.18l1.605.81c.11-.09.232-.162.367-.218.136-.055.278-.082.428-.082.33 0 .612.117.848.353.235.235.352.517.352.847 0 .33-.117.612-.352.848"
        />
    </svg>
)
export default SvgShareAsAlbum
