import { _ } from '@capture/client/assets/localization/util'
import * as React from 'react'
import styled from 'styled-components'
import { Toggle, Typography } from '@capture/capture-components'
import type { ConsentValue } from './PrivacyPage'

const ConsentToggleWrapper = styled.section`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

type ConsentToggleProps = {
    title: string
    description: string
    item: ConsentValue
}
export const ConsentToggle = ({
    item,
    title,
    description,
}: ConsentToggleProps) => (
    <ConsentToggleWrapper>
        <Toggle
            checked={item.val}
            disabled={item.isSaving}
            onClick={item.toggle}>
            <Typography.Bold>{title}</Typography.Bold>
        </Toggle>
        <Typography.Paragraph>{description}</Typography.Paragraph>
    </ConsentToggleWrapper>
)
