import { isTestEnv } from '~/config/constants'
import {
    localStorageGet,
    localStorageSet,
    sessionStorageGet,
    sessionStorageSet,
    webStorageClear,
} from '~/utilities/webStorage'
import { TestFlagService } from './services/TestFlagService'

export type ServiceDict = {
    appHost: string
    thumbHost: string
    videoHost: string
    pollHost: string
    downloadHost: string
}

const verifyServiceDict = (serviceDictStr: string) => {
    const serviceDict = JSON.parse(serviceDictStr)
    if (
        serviceDict.appHost &&
        serviceDict.thumbHost &&
        serviceDict.videoHost &&
        serviceDict.pollHost &&
        serviceDict.downloadHost
    ) {
        return serviceDict
    }
}

/**
 * The API Externals are elements that are considered app-constants, but needs to be loaded dynamically.
 * The values are stored in localStorage and may be updated by the fetchAccountInfo-method in ./login.ts
 * (as it is the method validating login-state and fetching these otherwise unchangeable values)
 */

// Essentially a method for doing the same things as auth.html (used when getting these values async)
export const setInitialAuthValues = (
    authToken: string,
    servicesStr: string,
    longLivedSession = false,
) => {
    if (verifyServiceDict(servicesStr) === undefined) {
        throw new Error('Invalid service dict')
    }

    sessionStorageSet('authToken', authToken)
    sessionStorageSet('serviceDict', servicesStr)

    if (longLivedSession) {
        localStorageSet('authToken', authToken)
        localStorageSet('serviceDict', servicesStr)
    }
}

export const getAuthToken = (): string =>
    sessionStorageGet('authToken') || localStorageGet('authToken') || ''

export const getStoredServiceDict = (): ServiceDict | undefined => {
    const dictStr =
        sessionStorageGet('serviceDict') || localStorageGet('serviceDict')
    return dictStr ? verifyServiceDict(dictStr) : undefined
}

export const getIfNewUserCreated = (): boolean => {
    return (
        sessionStorageGet('newUserCreated') === '1' ||
        TestFlagService.isEnabled('new-signup')
    )
}

export const clearLoginInfo = () => {
    webStorageClear('sessionStorage', [
        // callbackData is used for oauth postflight,
        // it should be cleared after oauth redirect
        'callbackData',
        ...TestFlagService.getStorageKeys(),
    ])
    webStorageClear('localStorage', [
        'showedEditorHint',
        'fastScrollerTooltip',
        'shiftSelectHint',
    ])
}

export enum LoginOption {
    Production = 'login.cptr.no',
    Testing = 'login-hotfix.cptr.no',
    Staging = 'login-staging.cptr.no',
    Client = 'login-client.univex.no',
    CI = 'login-ci.univex.no',
}

// The LOGIN_HOST is the start-point for all interaction with the app.
// It is used for user log in and the other hosts to be used by the API are provided from here
const resolveLoginHost = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const preferStaging = searchParams.has('isStaging')

    if (preferStaging) {
        return LoginOption.Staging
    }
    return isTestEnv ? LoginOption.Client : LoginOption.Production
}

export const LOGIN_HOST = resolveLoginHost()
