import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import SvgArrowBack from '@capture/capture-components/src/icons/ArrowBack'
import { fetchStoragePlanInfo, startPlanSubscription } from '~/API/storagePlan'
import { trackEventInternal } from '~/analytics/eventTracking'
import { _ } from '~/assets/localization/util'
import { colors, fontSize, mediaQueries } from '~/assets/styleConstants'
import { Button } from '~/components/Common/Button'
import {
    BorderedTextButton,
    FilledTextButton,
} from '~/components/Common/IconTextButton'
import { OptionsOverlay } from '~/components/Common/Overlay'
import { FormRow } from '~/components/Form/FormField'
import {
    BuyMoreWrapper,
    ProductHeader,
} from '~/components/Settings/Storage/BuyMoreStorage'
import { PaidPerksTable } from '~/components/Settings/Storage/PaidPerksTable'
import { StoragePlanProduct } from '~/components/Settings/Storage/StoragePlanProduct'
import type { CreditCardFieldMethods } from '~/components/Stripe/StripeCreditCardForm'
import { makeStripeCreditCardField } from '~/components/Stripe/StripeCreditCardForm'
import {
    PRODUCT_NAME,
    TELENOR_MINE_SIDER_LINK,
    WELCOME_FAQ,
    isMinSky,
} from '~/config/constants'
import { getEmailAdressOfLoggedInUser } from '~/state/currentUser/selectors'
import type {
    CaptureStripeProduct,
    PlanGroup,
} from '~/state/storagePlan/selectors'
import {
    getAvailableTrialPeriod,
    getStoragePlanGroups,
} from '~/state/storagePlan/selectors'
import { bytesToSize } from '~/utilities/fileSizeFormatting'
import { isEmail } from '~/utilities/inputValidation'
import { RefusedSubscription } from '~/state/storagePlan/actions'
import { getIfNewUserCreated } from '~/API/externals'

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: auto;
    width: 544px;
    padding: 32px 32px 12px 32px;

    ${mediaQueries.mobile} {
        width: 92%;
        max-width: 400px;
        max-height: 96%;
        gap: 20px;
        padding: 30px 8px 8px 20px;
    }
`
const TitleWrapper = styled.div`
    font-size: ${fontSize.large_22};
    font-weight: 700;
    color: ${colors.captureGrey800};
    margin-top: ${(props: { showLessMargin: boolean }) =>
        props.showLessMargin ? '-20px' : '0px'};
`

const ExternalLink = styled.a`
    text-underline-offset: 2px;
    text-decoration: underline;
    color: ${colors.info};
`
const WelcomeText = styled.div`
    color: ${colors.defaultIconColor};
    font-size: ${fontSize.medium_16};
    line-height: 2em;
`
const SubTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: ${colors.defaultIconColor};
    font-size: ${fontSize.medium_16};
    margin-bottom: 16px;
`
const NoSubWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
`
const SubOverProceedButtonText = styled.div`
    display: flex;
    color: ${colors.captureGreen};
    justify-content: center;
    text-align: center;
    margin: ${(props: { isSubStep?: boolean }) =>
        props.isSubStep ? '30px 0px 10px 0px' : '0'};
    padding: 0;
`
const SubUnderProceedButtonText = styled.div`
    display: flex;
    color: ${colors.warningRed};
    justify-content: center;
    font-size: ${fontSize.small_14};
    margin: 12px;
    padding: 0;
`
const StripeBuyForm = styled.div`
    align-items: center;
    text-align: center;
`
const StripeBuyHeader = styled.div`
    font-size: ${fontSize.large_24};
    font-weight: 900;
    margin-bottom: 10px;
`
const StripeBuySubHeader = styled.div`
    font-size: ${fontSize.medium_16};
    color: ${colors.captureGrey700};
`
const StripeFormRow = styled.div`
    margin: 8px 0 8px;
    border: 1px solid ${colors.captureGrey400};
`
const StripeEmailInfo = styled.span`
    display: block;
    font-size: ${fontSize.small_14};
    color: ${colors.captureGrey700};
    margin: 5px 0 15px;
    font-style: italic;
`

const ButtonWrapper = styled.div`
    text-align: center;
    margin: 0px 30px 20px 30px;
`
const FirstButton = styled(FilledTextButton)`
    margin-bottom: 10px;
`
const StripeButtonWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    text-align: center;
    margin: 20px 20px 30px 20px;
`

const BusinessNote = styled.div`
    font-size: ${fontSize.small_14};
    margin: 20px 32px;
`

/** Modal Step
 *  (1) Welcome --> (2-1) Sub   --> (3) Stripe
 *              --> (2-2) NoSub
 */
enum ModalStep {
    WELCOME = 'WELCOME', // 1
    SUB = 'SUB', // 2-1
    NOSUB = 'NOSUB', // 2-2
    STRIPE = 'STRIPE', // 3
}

const CreditCardField = makeStripeCreditCardField()

export const SubscribeModal = () => {
    const planGroups = useSelector(getStoragePlanGroups)
    const userEmailDefault = useSelector(getEmailAdressOfLoggedInUser)
    const availableTrialPeriod = useSelector(getAvailableTrialPeriod)
    const isNewUser = getIfNewUserCreated()
    const dispatch = useDispatch()

    // 1 (Welcome). 2-1 (Sub). 2-2 (NoSub). 3 (Stripe)
    const [currentStep, setCurrentStep] = useState<ModalStep>(ModalStep.WELCOME)
    const [selectedPlan, setSelectedPlan] = useState<CaptureStripeProduct>()
    const [showWarningText, setShowWarningText] = useState(false)
    const [userEmail, setUserEmail] = useState(userEmailDefault)
    const [isUserEmailValid, setIsUserEmailValid] = useState(
        isEmail(userEmailDefault || ''),
    )
    const credRef = useRef<CreditCardFieldMethods>()

    const handlePlanSelection = () => {
        if (selectedPlan) {
            setCurrentStep(ModalStep.STRIPE)
        } else {
            setShowWarningText(true)
        }
    }

    const updateEmail = (newValue: string) => {
        setUserEmail(newValue)
        setIsUserEmailValid(isEmail(newValue))
    }

    const refuseSubscription = () => {
        dispatch(RefusedSubscription())
    }

    const handlePayButtonClicked = async () => {
        if (isUserEmailValid !== true) {
            setUserEmail('')
            setIsUserEmailValid(false)
            return
        }
        if (credRef.current && isUserEmailValid && selectedPlan && userEmail) {
            const { token } = await credRef.current.getToken(userEmail)
            if (token) {
                startPlanSubscription(
                    dispatch,
                    selectedPlan.id,
                    token.id,
                    token.card!.id,
                )
            }
        }
    }

    const getWelcomeHeaderText = () => {
        const welcome_text_before = _('subscribe_welcome_text').replace(
            '%product_name%',
            PRODUCT_NAME,
        )
        const welcome_text_after = _('months_free_trial').replace(
            '%num_free_trial%',
            availableTrialPeriod.toString(),
        )

        const [beforeLink, afterLink] = _('subscribe_welcome_text2').split(
            '%faq_pages%',
        )

        return (
            <>
                {welcome_text_before}
                <b>{welcome_text_after}.</b> <br />
                {beforeLink.replace('%product_name%', PRODUCT_NAME)}
                <ExternalLink
                    target="_blank"
                    href={WELCOME_FAQ}
                    rel="noreferrer">
                    {_('here')}
                </ExternalLink>
                {afterLink.replace('%faq_pages%', '')}
            </>
        )
    }

    const getSubscriptionText = () => {
        return (
            <SubTextWrapper>
                <div>{_('select_plan_to_see_details')}</div>
            </SubTextWrapper>
        )
    }

    const getSubOverProceedButtonText = () => {
        if (selectedPlan) {
            const monthlyOrYearly =
                selectedPlan!.period === 'monthly'
                    ? _('month').toLowerCase()
                    : _('year').toLowerCase()
            return _('with_free_trial_detail')
                .replace('%num_free_trial%', availableTrialPeriod.toString())
                .replace('%price_string%', selectedPlan!.priceString)
                .replace('%period%', monthlyOrYearly)
        }
    }

    const getSubInsideProceedButtonText = () => {
        return _('subscribe_with_free_trial').replace(
            '%num_free_trial%',
            availableTrialPeriod.toString(),
        )
    }

    // fetch available storage plans
    useEffect(() => {
        fetchStoragePlanInfo(dispatch)
    }, [dispatch])

    const getCloseButton = () => {
        switch (currentStep) {
            case ModalStep.WELCOME: {
                // Close button not available for new signups
                if (!isNewUser) return Button('', refuseSubscription)
                return undefined
            }
            case ModalStep.SUB:
                return Button('', () => setCurrentStep(ModalStep.WELCOME), {
                    icon: SvgArrowBack,
                })
            case ModalStep.NOSUB:
                return undefined
            case ModalStep.STRIPE:
                return Button('', () => setCurrentStep(ModalStep.SUB), {
                    icon: SvgArrowBack,
                })
        }
    }

    const getContent = () => {
        switch (currentStep) {
            case ModalStep.WELCOME:
                return (
                    <>
                        <TitleWrapper showLessMargin={!isNewUser}>
                            {_('subscribe_title').replace(
                                '%product_name%',
                                PRODUCT_NAME,
                            )}
                        </TitleWrapper>
                        <WelcomeText>{getWelcomeHeaderText()}</WelcomeText>
                        <PaidPerksTable />
                    </>
                )
            case ModalStep.SUB:
                return (
                    <>
                        <TitleWrapper showLessMargin={true}>
                            {_('choose_subscription')}
                        </TitleWrapper>
                        <BuyMoreWrapper>
                            {getSubscriptionText()}
                            <ProductHeader />
                            {planGroups.map((planGroup: PlanGroup) => (
                                <StoragePlanProduct
                                    key={'prod_' + planGroup.size}
                                    group={planGroup}
                                    currentPlan={undefined}
                                    selectedPlan={selectedPlan}
                                    selectPlan={setSelectedPlan}
                                    hideWarningText={() =>
                                        setShowWarningText(false)
                                    }
                                    isNewSignup={true}
                                />
                            ))}
                        </BuyMoreWrapper>
                    </>
                )
            case ModalStep.NOSUB:
                return (
                    <>
                        <TitleWrapper showLessMargin={false}>
                            {_('no_subscription_title')}
                        </TitleWrapper>
                        <NoSubWrapper>
                            <div>{_('no_subscription_limit')}</div>
                            <div>{_('no_subscription_reconsider')}</div>
                        </NoSubWrapper>
                    </>
                )
            case ModalStep.STRIPE:
                return (
                    <>
                        <TitleWrapper showLessMargin={true}>
                            {_('enter_payment_information')}
                        </TitleWrapper>
                        <StripeBuyForm>
                            <StripeBuyHeader>
                                {bytesToSize(selectedPlan!.size)}
                            </StripeBuyHeader>
                            <StripeBuySubHeader>
                                {`${selectedPlan!.priceString} ${
                                    selectedPlan!.period === 'monthly'
                                        ? _('monthly')
                                        : _('yearly')
                                }`}
                            </StripeBuySubHeader>
                            <form>
                                <StripeFormRow>
                                    <FormRow
                                        header={_('email')}
                                        onChange={updateEmail}
                                        showError={isUserEmailValid === false}
                                        name="email"
                                        autoComplete="email"
                                        preFilledValue={userEmail}
                                    />
                                </StripeFormRow>
                                <StripeEmailInfo>
                                    {_('email_request_explanation')}
                                </StripeEmailInfo>
                                <CreditCardField
                                    ref={credRef}
                                    key="signup__CreditCardField"
                                />
                            </form>
                        </StripeBuyForm>
                        <SubOverProceedButtonText>
                            {getSubOverProceedButtonText()}
                        </SubOverProceedButtonText>
                    </>
                )
        }
    }

    const getFooter = () => {
        switch (currentStep) {
            case ModalStep.WELCOME: {
                return (
                    <ButtonWrapper>
                        <FirstButton
                            onClick={() => {
                                setCurrentStep(ModalStep.SUB)
                                trackEventInternal(
                                    'signup_modal_choose_subscription_clicked',
                                )
                            }}
                            text={_(
                                'timeline_subscription_required_sub',
                            ).replace('!', '')}
                        />
                        <BorderedTextButton
                            onClick={() => {
                                setCurrentStep(ModalStep.NOSUB)
                                trackEventInternal(
                                    'signup_modal_continue_without_subscription_clicked',
                                )
                            }}
                            text={_('subscribe_skip')}
                            color={colors.captureBlue}
                        />
                    </ButtonWrapper>
                )
            }
            case ModalStep.SUB: {
                const [beforeLink, afterLink] = _('business_users').split(
                    '%telenor_mine_sider%',
                )
                return (
                    <>
                        <ButtonWrapper>
                            <SubOverProceedButtonText isSubStep>
                                {getSubOverProceedButtonText()}
                            </SubOverProceedButtonText>
                            <FilledTextButton
                                onClick={handlePlanSelection}
                                text={getSubInsideProceedButtonText()}
                                fillColor={
                                    selectedPlan
                                        ? colors.captureBlue
                                        : colors.captureBlueDisabled
                                }
                            />
                            {showWarningText && (
                                <SubUnderProceedButtonText>
                                    {_(
                                        'choose_subscription_plan_to_start_free_trial',
                                    )}
                                </SubUnderProceedButtonText>
                            )}
                        </ButtonWrapper>
                        {isMinSky && (
                            <BusinessNote>
                                {beforeLink}
                                <ExternalLink
                                    target="_blank"
                                    href={TELENOR_MINE_SIDER_LINK}
                                    rel="noreferrer">
                                    {_('mine_sider')}
                                </ExternalLink>
                                {afterLink}
                            </BusinessNote>
                        )}
                    </>
                )
            }
            case ModalStep.NOSUB:
                return (
                    <ButtonWrapper>
                        <FirstButton
                            onClick={refuseSubscription}
                            text={_('subscribe_skip')}
                        />
                        <BorderedTextButton
                            onClick={() => setCurrentStep(ModalStep.WELCOME)}
                            text={_('go_back').replace('<', '')}
                            color={colors.captureBlue}
                        />
                    </ButtonWrapper>
                )
            case ModalStep.STRIPE:
                return (
                    <StripeButtonWrapper>
                        <BorderedTextButton
                            onClick={() => setCurrentStep(ModalStep.SUB)}
                            text={_('cancel')}
                            color={colors.captureBlue}
                            style={{ width: '40%' }}
                        />
                        <FilledTextButton
                            onClick={handlePayButtonClicked}
                            text={getSubInsideProceedButtonText()}
                            style={{ width: '40%' }}
                        />
                    </StripeButtonWrapper>
                )
        }
    }

    return (
        <OptionsOverlay
            onClose={refuseSubscription}
            ignoreCloseOnClickOutside
            cancelButton={getCloseButton()}
            footer={getFooter()}>
            <ContentWrapper>{getContent()}</ContentWrapper>
        </OptionsOverlay>
    )
}
