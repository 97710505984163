/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    CAPBAKHTTPValidationError,
    CAPBAKOptionResponse,
    CAPBAKOptionsDeleteByNameParams,
    CAPBAKOptionsDetailByNameParams,
    CAPBAKOptionsGetParams,
    CAPBAKOptionsUpdateByNameParams,
} from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class Options<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * @description this will list up the options available for a user
     *
     * @tags options
     * @name OptionsGet
     * @summary Get Option Names
     * @request GET:/options
     */
    optionsGet = (query: CAPBAKOptionsGetParams, params: RequestParams = {}) =>
        this.request<string[], CAPBAKHTTPValidationError>({
            path: `/options`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description this call returns the option_value for a the given option and user
     *
     * @tags options
     * @name OptionsDetailByName
     * @summary Get Option
     * @request GET:/options/{name}
     */
    optionsDetailByName = (
        { name, ...query }: CAPBAKOptionsDetailByNameParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKOptionResponse | string, CAPBAKHTTPValidationError>({
            path: `/options/${name}`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description this call deletes an option for a user
     *
     * @tags options
     * @name OptionsDeleteByName
     * @summary Delete Option
     * @request DELETE:/options/{name}
     */
    optionsDeleteByName = (
        { name, ...query }: CAPBAKOptionsDeleteByNameParams,
        params: RequestParams = {},
    ) =>
        this.request<any, CAPBAKHTTPValidationError>({
            path: `/options/${name}`,
            method: 'DELETE',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description this call updates if exists or inserts a new options for a user
     *
     * @tags options
     * @name OptionsUpdateByName
     * @summary Put Option
     * @request PUT:/options/{name}
     */
    optionsUpdateByName = (
        { name, ...query }: CAPBAKOptionsUpdateByNameParams,
        params: RequestParams = {},
    ) =>
        this.request<any, CAPBAKHTTPValidationError>({
            path: `/options/${name}`,
            method: 'PUT',
            query: query,
            format: 'json',
            ...params,
        })
}
