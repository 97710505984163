import styled from 'styled-components'
import { colors } from '~/assets/styleConstants'

export const FadeInContainer = styled.div`
    position: relative;
    display: block;
    opacity: ${(props: { isVisible: boolean; transitionSeconds?: number }) =>
        props.isVisible ? 1 : 0};
    transition: opacity ${(props) => props.transitionSeconds || '0.3'}s
        ease-in-out;
`

export const Line = styled.div`
    width: ${(props: {
        width: number | string
        spaceUnderneath: number | string
        color?: string
    }) => (typeof props.width === 'string' ? props.width : `${props.width}px`)};
    margin: 0 auto
        ${({ spaceUnderneath }) =>
            typeof spaceUnderneath === 'string'
                ? spaceUnderneath
                : `${spaceUnderneath}px`};
    border-top: 1px solid ${(props) => props.color || '#fff'};
`

export const EmbeddedLink = styled.a`
    text-decoration: underline;
    cursor: pointer;
    color: ${colors.captureBlue};
`
