import type { CropArea } from '~/components/Common/CropPlacement'

export const getPreloadedImage = async (imgUrl: URLstring) => {
    const imgObject = new Image()
    imgObject.crossOrigin = 'Anonymous'
    const onload = new Promise((r, f) => {
        imgObject.onload = r
        imgObject.onerror = f
    })
    imgObject.src = imgUrl
    await onload
    return imgObject
}

export const getCroppedImage = async (
    imgURL: URLstring,
    cropArea: CropArea,
): Promise<Blob> => {
    const imgObj: HTMLImageElement = await getPreloadedImage(imgURL)

    return new Promise((resolve, reject) => {
        const canvas: HTMLCanvasElement = document.createElement('canvas')
        const canvasContext = canvas.getContext('2d')

        if (canvasContext !== null) {
            canvas.width = cropArea.width
            canvas.height = cropArea.height

            canvasContext.drawImage(
                imgObj,
                -cropArea.left,
                -cropArea.top,
                imgObj.width,
                imgObj.height,
            )
            canvas.toBlob((f) => (f === null ? reject() : resolve(f)))
        } else {
            reject()
        }
    })
}

export const getCenterFittedCropArea = <T extends TwoDimensions>(
    f: T,
    cropRatio: number,
): CropArea => {
    const calcHeight = Math.min(f.height, f.width / cropRatio)
    const height = Math.floor(calcHeight)
    const width = Math.floor(calcHeight * cropRatio)
    const top = Math.floor((f.height - height) / 2)
    const left = Math.floor((f.width - width) / 2)
    return { width, height, top, left }
}

export const getInitial10x13CropArea = <T extends TwoDimensions>(
    f: T,
): CropArea => {
    const aspectRatio = f.width >= f.height ? 13 / 10 : 10 / 13
    return getCenterFittedCropArea(f, aspectRatio)
}

export const getInitialSquareCropArea = <T extends TwoDimensions>(
    f: T,
): CropArea => {
    return getCenterFittedCropArea(f, 1)
}

export const cropAreaAreEqual = (
    currentCropArea: CropArea,
    originalCropArea: CropArea,
) => {
    return (
        currentCropArea.left === originalCropArea.left &&
        currentCropArea.top === originalCropArea.top &&
        currentCropArea.width === originalCropArea.width &&
        currentCropArea.height === originalCropArea.height
    )
}
