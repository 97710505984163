import type { Store } from '@reduxjs/toolkit'
import { fetchAllRecentFiles } from '~/API/job'
import type { StateWithCurrentUser } from '../currentUser/reducer'
import { getCurrentUserUUID } from '../currentUser/selectors'
import type { StateWithRecentFiles } from './recentFilesSlice'
import { RecentFilesStatus } from './recentFilesSlice'
import { getRecentFilesStatus } from './selectors'

type RecentFilesSyncerState = StateWithRecentFiles & StateWithCurrentUser
class RecentFilesSyncer {
    constructor(private store: Store<RecentFilesSyncerState>) {
        store.subscribe(() => this.fetchRecentFiles(store.getState()))
    }

    public fetchRecentFiles = (newState?: RecentFilesSyncerState) => {
        if (newState === undefined) {
            console.warn('RecentFilesSyncer: undefined state.')
            return
        }

        const userId = getCurrentUserUUID(newState)
        const filesStatus = getRecentFilesStatus(newState)

        if (userId && filesStatus === RecentFilesStatus.EMPTY) {
            fetchAllRecentFiles(userId, this.store.dispatch)
        }
    }
}

export const connectRecentFilesSyncer = (
    store: Store<RecentFilesSyncerState>,
) => {
    new RecentFilesSyncer(store)
}
