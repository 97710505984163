/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    CAPBAKGrantInfoResponse,
    CAPBAKHTTPValidationError,
    CAPBAKUserGrantsGetParams,
} from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class UserGrants<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * @description This call is used to get current valid grants for a user
     *
     * @tags account_info
     * @name UserGrantsGet
     * @summary Get User Grants
     * @request GET:/user_grants
     */
    userGrantsGet = (
        query: CAPBAKUserGrantsGetParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKGrantInfoResponse, CAPBAKHTTPValidationError>({
            path: `/user_grants`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
}
