import type { Icon } from '../Icons'

export type ButtonProps = {
    onClick: () => void
    text: string
    isDisabled?: boolean
    isDisabledForReadonly?: boolean
    icon?: Icon
    altText?: string
    cyKey?: string
    subMenu?: ButtonProps[]
}

export function Button(
    text: string,
    onClick: () => void,
    optional: Partial<ButtonProps> = {},
): ButtonProps {
    return { ...optional, text, onClick }
}

export type OverflowButtonProps = Omit<ButtonProps, 'onClick'> & {
    buttons: ButtonProps[]
}

export function OverflowButton(
    text: string,
    buttons: ButtonProps[],
    optional: Partial<OverflowButtonProps> = {},
): OverflowButtonProps {
    return {
        text,
        buttons,
        isDisabled: optional.isDisabled,
        icon: optional.icon,
        cyKey: optional.cyKey,
    }
}

export const isRegularButton = (
    b: OverflowButtonProps | ButtonProps,
): b is ButtonProps => Object.prototype.hasOwnProperty.call(b, 'onClick')

export const isOverflowButton = (
    b: OverflowButtonProps | ButtonProps,
): b is OverflowButtonProps =>
    Object.prototype.hasOwnProperty.call(b, 'buttons')
