import * as React from 'react'
import styled from 'styled-components'
import { _ } from '~/assets/localization/util'
import { colors } from '~/assets/styleConstants'
import type { User } from '~/state/users/reducer'
import { Avatar } from '../Common/Avatar'
import { Bullet } from '../Common/Bullet'
import type { ButtonProps } from '../Common/Button'
import { TimeSinceString } from '../Common/TimeStrings'
import { TruncateableText } from '../Common/TruncateableText'
import { CommentActionComponent } from './CommentActionComponent'

const CommentWrapper = styled.div`
    display: flex;
    margin: 8px 0;
`

const AvatarWrapper = styled.div`
    text-align: center;
    display: inline-block;
    margin-right: 8px;
    height: 24px;
`

const CommentContent = styled.div`
    align-self: center;
    flex: 1;

    font-size: 14px;
    word-break: break-word;
    word-wrap: break-word;
    max-width: 95%;

    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    span {
        line-height: 16px;
    }
`

const CommentText = styled.span`
    color: ${colors.captureGrey800};
`
const Name = styled.span`
    color: ${colors.captureGrey500};
    margin-right: 4px;
`

const SubText = styled.div`
    color: ${colors.captureGrey500};
    display: ${(props: { showMore: boolean }) =>
        props.showMore ? 'block' : 'inline-block'};
`

const Comment = styled.div`
    margin-bottom: 4px;
    white-space: pre-line;
`
const Edited = styled.span`
    color: ${colors.captureGrey500};
    margin-left: 4px;
`

const ErrorMessage = styled.span`
    color: ${colors.captureMagenta};
`

type Props = {
    now: Date
    text: string
    author: User
    timeCreated: Date
    currentUserCan: {
        editComment: boolean
        deleteComment: boolean
    }
    commentActions: ButtonProps[]
    isMobileMode: boolean
    hasBeenEdited: boolean
    editFailed: boolean
    deleteFailed: boolean
    onShowFullCommentClick?: () => void
}

export class SingleComment extends React.Component<Props> {
    public render() {
        const canSeeMore =
            this.props.currentUserCan.deleteComment ||
            this.props.currentUserCan.editComment
        const moreElements = canSeeMore && (
            <CommentActionComponent
                commentActions={this.props.commentActions}
                isMobileMode={this.props.isMobileMode}
            />
        )

        let message = null
        if (this.props.editFailed) {
            message = <ErrorMessage>{_('edit_comment_error')}</ErrorMessage>
        } else if (this.props.deleteFailed) {
            message = <ErrorMessage>{_('delete_comment_error')}</ErrorMessage>
        } else {
            message = (
                <TimeSinceString
                    then={this.props.timeCreated}
                    now={this.props.now}
                />
            )
        }
        const commentContent = (
            <>
                <Name>{this.props.author.name}:</Name>
                <CommentText>{this.props.text}</CommentText>
                {this.props.hasBeenEdited ? (
                    <Edited>{`(${_('edited')})`}</Edited>
                ) : null}
            </>
        )
        let commentSection = <Comment>{commentContent}</Comment>

        if (this.props.isMobileMode && this.props.onShowFullCommentClick) {
            commentSection = (
                <TruncateableText
                    lineHeight={16}
                    maxLines={4}
                    linkText={_('read_more')}
                    onlinkClick={this.props.onShowFullCommentClick}>
                    {commentContent}
                </TruncateableText>
            )
        }

        return (
            <CommentWrapper data-cy="album__commentsList" className="comment">
                <AvatarWrapper>
                    <Avatar
                        name={this.props.author.name}
                        img={this.props.author.profilePicture}
                        size={24}
                    />
                </AvatarWrapper>
                <CommentContent>
                    {commentSection}
                    <SubText showMore={canSeeMore}>
                        {moreElements}
                        {canSeeMore && <Bullet />}
                        {message}
                    </SubText>
                </CommentContent>
            </CommentWrapper>
        )
    }
}
