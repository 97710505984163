import { Suspense, lazy } from 'react'
import { isMinSky } from '~/config/constants'

// lazy load logos, we will likely ever need one of these varian
const CaptureLogoHorizontalMinSky = lazy(
    () => import('./CaptureLogoHorizontalMinSky'),
)
const CaptureLogoHorizontalCapture = lazy(
    () => import('./CaptureLogoHorizontalCapture'),
)

// This component is a wrapper around lazy loaded svgs
export const CaptureLogoHorizontal = (props: {
    color?: string
    width?: number
    height?: number
}) => {
    return (
        <Suspense fallback={null}>
            {isMinSky ? (
                <CaptureLogoHorizontalMinSky
                    color={props.color}
                    width={props.width}
                    height={props.height}
                />
            ) : (
                <CaptureLogoHorizontalCapture
                    color={props.color}
                    width={props.width}
                    height={props.height}
                />
            )}
        </Suspense>
    )
}
