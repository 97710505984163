import { _, getStringByAmount } from '~/assets/localization/util'
import { PRODUCT_NAME } from '~/config/constants'
import { YesNoPromptOverlay } from '../Common/PromptOverlay'

type SingleAlbumTargetProps = {
    albumName: string
    onDecide: (result: boolean) => void
}
export const AlsoUploadAlbumFilesToTimelinePrompt: React.FC<
    SingleAlbumTargetProps
> = ({ albumName, onDecide }) => (
    <YesNoPromptOverlay
        header={_('also_upload_to_timeline')}
        onResponse={onDecide}>
        <p>
            {_('uploading_to_album_name__format').replace(
                '%album_name%',
                albumName,
            )}
        </p>
        <p>
            {_('uploading_to_album_also_to_timeline__format').replace(
                '%product_name%',
                PRODUCT_NAME,
            )}
        </p>
    </YesNoPromptOverlay>
)

type AlbumListTargetProps = {
    albumCount: number
    onDecide: (result: boolean) => void
}
export const AlsoUploadAlbumsToTimelinePrompt: React.FC<
    AlbumListTargetProps
> = ({ albumCount, onDecide }) => {
    const mainText =
        albumCount === 1
            ? _('uploading_files_to_new_album')
            : _('uploading_files_to_multiple_albums__format').replace(
                  '%album_count%',
                  albumCount.toString(),
              )
    return (
        <YesNoPromptOverlay
            header={_('also_upload_to_timeline')}
            onResponse={onDecide}>
            <p>{mainText}</p>
            <p>
                {_('uploading_to_album_also_to_timeline__format').replace(
                    '%product_name%',
                    PRODUCT_NAME,
                )}
            </p>
        </YesNoPromptOverlay>
    )
}

type FolderSourceProps = {
    onDecide: (result: boolean) => void
    folderNames: string[]
}

export const AlsoUploadFoldersAsAlbumsPrompt: React.FC<FolderSourceProps> = ({
    folderNames,
    onDecide,
}) => {
    const mainText =
        folderNames.length === 1
            ? _('also_create_albums__format').replace(
                  '%album_name%',
                  folderNames[0],
              )
            : _('also_create_albums_multiple__format').replace(
                  '%d',
                  folderNames.length.toString(),
              )

    const header = getStringByAmount(
        folderNames.length,
        _('create_album_prompt'),
        _('create_albums_prompt'),
    )
    return (
        <YesNoPromptOverlay header={header} onResponse={onDecide}>
            <p>{mainText}</p>
        </YesNoPromptOverlay>
    )
}
