import * as React from 'react'
import styled from 'styled-components'
import { _ } from '~/assets/localization/util'
import { colors, fontSize } from '~/assets/styleConstants'
import type { UserActionsStatus } from '~/state/userActions/reducer'
import { stopPropagation } from '~/utilities/eventHandling'
import type { ButtonProps } from './Button'
import { Button } from './Button'

const Wrapper = styled.div`
    position: relative;
    max-width: 400px;
    background-color: white;
    box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.2);
    padding: 24px 24px 12px;
    box-sizing: border-box;
    border-radius: 3px;
    font-size: ${fontSize.small_14};
    margin: 0 4px;
`

const ButtonsWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    border-top: 1px solid ${colors.captureGrey300};
    margin-top: 8px;

    button {
        background-color: transparent;
        box-sizing: border-box;
        border: none;
        padding: 12px 8px 4px;
        text-align: center;
        cursor: pointer;
        margin-right: 12px;
        font-size: ${fontSize.small_14};
        color: ${colors.captureBlue};
        outline: none;
    }
    button[disabled] {
        color: ${colors.captureGrey500};
        cursor: default;
    }
    button:last-child {
        margin-right: 0px;
    }
`
export type Props = {
    buttons: ButtonProps[]
    header?: string
    children?: React.ReactNode
}

export class DialogBox extends React.Component<Props> {
    public render() {
        const makeButton = (p: ButtonProps, i: number) => (
            <button
                key={i}
                data-cy={p.cyKey}
                onClick={p.onClick}
                disabled={p.isDisabled}>
                {p.text}
            </button>
        )
        return (
            <Wrapper
                data-cy="dialogue__boxPrompt"
                onClick={stopPropagation}
                role="presentation">
                {this.props.header && (
                    <div style={{ fontWeight: 'bold', marginBottom: 8 }}>
                        {this.props.header}
                    </div>
                )}
                <div>{this.props.children}</div>
                <ButtonsWrapper>
                    <div style={{ transform: 'translateX(8px)' }}>
                        {this.props.buttons.map(makeButton)}
                    </div>
                </ButtonsWrapper>
            </Wrapper>
        )
    }
}

export type ConfirmProps = {
    onConfirm: () => void
    onCancel: () => void
    confirmText?: string
    cancelText?: string
    children?: React.ReactNode
}

export const ConfirmPromptComponent: React.FunctionComponent<ConfirmProps> = (
    props,
) => {
    const buttons = [
        Button(props.cancelText || _('cancel'), props.onCancel, {
            cyKey: 'dialogue__cancelBtn',
        }),
        Button(props.confirmText || _('ok'), props.onConfirm, {
            cyKey: 'dialogue__OKBtn',
        }),
    ]

    return <DialogBox buttons={buttons}>{props.children}</DialogBox>
}

export const OkPromptComponent = (props: {
    onOK: () => void
    header?: string
    children?: React.ReactNode
}) => (
    <DialogBox buttons={[Button(_('ok'), props.onOK)]} header={props.header}>
        {props.children}
    </DialogBox>
)

export type DialogPromptProps = {
    userActionStatus: UserActionsStatus
    onConfirm: () => void
    onDeny: () => void
    confirmText: string
    onOK: () => void
    okText: string
}

type LoggedOutPromptProps = {
    doSignIn: () => void
}
export const LoggedOutPromptComponent: React.FunctionComponent<
    LoggedOutPromptProps
> = ({ doSignIn }) => (
    <DialogBox buttons={[Button(_('sign_in'), doSignIn)]}>
        {_('logged_out_prompt')}
    </DialogBox>
)
