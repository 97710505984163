import { cachedInArray } from '~/utilities/arrayUtils'
import type { DeletedFile } from '~/@types/backend-types'
import type { Action } from '../common/actions'
import { isType } from '../common/actions'
import { FileDimensionsDiscovered } from '../files/actions'
import {
    FilesRestorationFailed,
    FilesRestorationStarted,
    FilesRestorationSucceeded,
} from '../job/actions'
import {
    TrashContentOutdated,
    TrashFilesDeletionFailed,
    TrashFilesDeletionStarted,
    TrashFilesDeletionSucceeded,
    TrashLoadingFailed,
    TrashLoadingStarted,
    TrashLoadingSucceeded,
} from './actions'

export type TrashState = {
    fetchState: 'not_started' | 'pending' | 'fetched' | 'failed'
    files: DeletedFile[]
    processingFiles: FileID[]
    limit: number
    lastResultCount: number
    nextResultOffset: number
}

const initialState: TrashState = {
    fetchState: 'not_started',
    files: [],
    processingFiles: [],
    limit: 0,
    lastResultCount: 0,
    nextResultOffset: 0,
}

const addProcessingFilesFromState = (
    state: TrashState,
    targetFiles: FileID[],
): TrashState => {
    const isInProcessing = cachedInArray(state.processingFiles)
    const processingFiles = [
        ...state.processingFiles,
        ...targetFiles.filter((f) => !isInProcessing(f)),
    ]
    return { ...state, processingFiles }
}

const removeProcessingFilesFromState = (
    state: TrashState,
    targetFiles: FileID[],
): TrashState => {
    const isRemoveTarget = cachedInArray(targetFiles)
    const processingFiles = state.processingFiles.filter(
        (f) => !isRemoveTarget(f),
    )
    return { ...state, processingFiles }
}

const removeDeletedFilesFromState = (
    state: TrashState,
    targetFiles: FileID[],
): TrashState => {
    const isRemoveTarget = cachedInArray(targetFiles)
    return {
        ...state,
        processingFiles: state.processingFiles.filter(
            (f) => !isRemoveTarget(f),
        ),
        files: state.files.filter((f) => !isRemoveTarget(f.id)),
    }
}

export const trashReducer = (
    state: TrashState = initialState,
    action: Action,
): TrashState => {
    if (isType(action, TrashContentOutdated)) {
        return initialState
    }
    if (isType(action, TrashLoadingStarted)) {
        return { ...state, fetchState: 'pending' }
    }
    if (isType(action, TrashLoadingSucceeded)) {
        return {
            ...state,
            fetchState: 'fetched',
            files: action.payload.deletedFiles,
            limit: action.payload.limit,
            lastResultCount: action.payload.resultCount,
            nextResultOffset: state.nextResultOffset + action.payload.limit,
        }
    }
    if (isType(action, TrashLoadingFailed)) {
        return { ...state, fetchState: 'failed' }
    }

    if (isType(action, TrashFilesDeletionStarted)) {
        return addProcessingFilesFromState(state, action.payload)
    }
    if (isType(action, TrashFilesDeletionSucceeded)) {
        return removeDeletedFilesFromState(state, action.payload)
    }
    if (isType(action, TrashFilesDeletionFailed)) {
        return removeProcessingFilesFromState(state, action.payload)
    }

    if (isType(action, FilesRestorationStarted)) {
        return addProcessingFilesFromState(state, action.payload.files)
    }
    if (isType(action, FilesRestorationSucceeded)) {
        return removeDeletedFilesFromState(state, action.payload.files)
    }
    if (isType(action, FilesRestorationFailed)) {
        return removeProcessingFilesFromState(state, action.payload.files)
    }
    if (isType(action, FileDimensionsDiscovered)) {
        const fileIDsToUpdate = action.payload.map(
            (dimensionsObject) => dimensionsObject.fileID,
        )
        const updatedFiles = state.files.map((file) => {
            const index = fileIDsToUpdate.indexOf(file.id)
            if (index !== -1) {
                return {
                    ...file,
                    width: action.payload[index].width,
                    height: action.payload[index].height,
                }
            }

            return file
        })

        if (updatedFiles.length === 0) {
            return state
        }
        return { ...state, files: updatedFiles }
    }

    return state
}

export const trashReducerMapObj = {
    trash: trashReducer,
}

export type StateWithTrash = StateOfReducerMapObj<typeof trashReducerMapObj>
