/**
 * This component exists as a workaround as anonymous users may not create Shares.
 * This means that anonymous users cannot generate a Share link for sharing a single image in a public album.
 * The aim of this component is to simulate a share with one image, based on a specific image in an album.
 */

import { _ } from '@capture/client/assets/localization/util'
import * as React from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { PRODUCT_NAME } from '@capture/client/config/constants'
import { downloadSingleFile } from '@capture/client/API/download'
import { loginToLoggedInWebPage } from '@capture/client/API/login'
import { copyShareFilesToTimeline } from '@capture/client/API/share'
import { colors } from '@capture/client/assets/styleConstants'
import {
    BorderedTextButton,
    FilledTextButton,
} from '@capture/client/components/Common/IconTextButton'
import { LoadingPage } from '@capture/client/components/Common/LoadingPage'
import * as Pages from '@capture/client/routing/pages'
import type { Dispatch } from '@capture/client/state/common/actions'
import type { CaptureFile } from '@capture/client/state/files/selectors'
import type { Share } from '@capture/client/state/share/selectors'
import {
    calculateShareGridStyle,
    getSingleFileShare,
} from '@capture/client/state/share/selectors'
import { getTimelineJobID } from '@capture/client/state/timeline/selectors'
import {
    getViewportWidth,
    isMobileMode,
} from '@capture/client/state/viewMode/selectors'
import { isMobileDevice } from '@capture/client/utilities/device'
import type { GridStyle } from '@capture/client/utilities/gridElementSizeCalculator'
import type { WithRouterProps } from '@capture/client/utilities/navigation'
import { withRouter } from '@capture/client/utilities/navigation'
import { b64ToUuid } from '@capture/client/utilities/uuid'
import { FullscreenMediaOverlay } from './FullscreenMediaOverlay'
import { SharePageOutline } from './SharePageOutline'
import { ButtonWrapper } from './ShareReceiverPage'
import { ThumbSection } from './ThumbSection'

type StateProps = {
    gridStyle: GridStyle
    isMobileMode: boolean
    simulatedShare?: Share
    timelineJobID: JobID | undefined
}
type DispatchProps = {
    copyFilesToTimeline: (files: CaptureFile[], timelineJobID: JobID) => void
    doDownloadFile: (path: string) => void
    loginAndGoToTimeline: () => void
}
type OwnProps = {
    albumID: JobID
    fileID: FileID
}

type ComponentState = {
    isShowingFullscreenImage: boolean
}

class AlbumFileAsShareInner extends React.Component<
    StateProps & DispatchProps & OwnProps & WithRouterProps,
    ComponentState
> {
    public state: ComponentState = { isShowingFullscreenImage: false }

    private goToTimeline = () => this.props.navigate(Pages.Timeline.url)

    private doEnterFullscreen = () => {
        this.setState({ isShowingFullscreenImage: true })
    }
    private doLeaveFullscreen = () => {
        this.setState({ isShowingFullscreenImage: false })
    }

    private addFilesToCapture = () => {
        if (this.props.simulatedShare && this.props.timelineJobID) {
            this.props.copyFilesToTimeline(
                this.props.simulatedShare.thumbFiles,
                this.props.timelineJobID,
            )
            this.goToTimeline()
        } else {
            // TODO: Require login (existing flow only allows logging in to keep entire album/share)
        }
    }

    public render() {
        const {
            simulatedShare,
            timelineJobID,
            doDownloadFile,
            gridStyle,
            isMobileMode,
        } = this.props

        if (simulatedShare === undefined) {
            return <LoadingPage />
        }

        const downloadButton = !isMobileDevice.iOS() && (
            <ButtonWrapper>
                <BorderedTextButton
                    onClick={() =>
                        doDownloadFile(simulatedShare.thumbFiles[0].path)
                    }
                    text={_('download_file')}
                    color={colors.captureBlue}
                />
            </ButtonWrapper>
        )
        const addFilesBtnText = _(
            'share__add_singleFile_to_capture__format',
        ).replace('%s', PRODUCT_NAME)

        const fullscreenImage = this.state.isShowingFullscreenImage && (
            <FullscreenMediaOverlay
                file={simulatedShare.thumbFiles[0]}
                onClose={this.doLeaveFullscreen}
            />
        )

        return (
            <SharePageOutline context="SingleAlbumFileShare">
                <div>
                    <ThumbSection
                        files={simulatedShare.thumbFiles}
                        gridStyle={gridStyle}
                        enterCarouselView={this.doEnterFullscreen}
                        isMobileDisplay={isMobileMode}
                    />
                    <ButtonWrapper>
                        <FilledTextButton
                            isDisabled={!timelineJobID}
                            onClick={this.addFilesToCapture}
                            text={addFilesBtnText}
                            fillColor={
                                timelineJobID === undefined
                                    ? colors.captureGrey500
                                    : colors.captureBlue
                            }
                        />
                    </ButtonWrapper>
                </div>
                {downloadButton}
                {fullscreenImage}
            </SharePageOutline>
        )
    }
}

const mapStateToProps = (
    state: StateOfSelector<typeof getSingleFileShare> &
        StateOfSelector<typeof getTimelineJobID> &
        StateOfSelector<typeof isMobileMode> &
        StateOfSelector<typeof getViewportWidth>,
    ownProps: OwnProps,
): StateProps => {
    const simulatedShare = getSingleFileShare(
        state,
        ownProps.albumID,
        ownProps.fileID,
    )
    return {
        simulatedShare,
        gridStyle: calculateShareGridStyle(
            simulatedShare,
            isMobileMode(state),
            getViewportWidth(state),
        ),
        isMobileMode: isMobileMode(state),
        timelineJobID: getTimelineJobID(state),
    }
}

const mapDispatchToProps = (
    dispatch: Dispatch,
    ownProps: OwnProps,
): DispatchProps => ({
    loginAndGoToTimeline: () => loginToLoggedInWebPage(Pages.Timeline),
    doDownloadFile: (path: string) =>
        downloadSingleFile(dispatch, {
            type: 'download',
            jobID: ownProps.albumID,
            fileID: ownProps.fileID,
            path,
            context: 'AlbumFileAsShare',
        }),
    copyFilesToTimeline: (files: CaptureFile[], timelineJobID: JobID) =>
        copyShareFilesToTimeline(dispatch, timelineJobID, files),
})

export const AlbumFileAsShare = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AlbumFileAsShareInner),
)

export const AlbumFileAsShareMount: React.FunctionComponent = () => {
    const { albumIDB64, fileID } = useParams<{
        albumIDB64: string
        fileID: string
    }>()
    if (albumIDB64 && fileID) {
        return (
            <AlbumFileAsShare albumID={b64ToUuid(albumIDB64)} fileID={fileID} />
        )
    }
    return null
}
