import * as React from 'react'
import { getRange } from '~/utilities/arrayUtils'
import type { GridStyle } from '~/utilities/gridElementSizeCalculator'
import {
    calcImagesPerRow,
    computeGridHeight,
    getElementSize,
} from '~/utilities/gridElementSizeCalculator'
import type { VisibleRange } from '../Timeline/ImageGroupList'
import { VirtualGrid } from './WindowedVirtualGrid'

type Props = {
    visibleRange: VisibleRange
    totalItems: number
    groupStyle: GridStyle
    groupPosition?: number
    cutoffThreshold?: number
    getVisibleElement: (index: number) => React.ReactNode
}

export class VisibleGridItems extends React.Component<Props> {
    private getVisibleGridItemIndexesEtc = () => {
        const elementHeight = getElementSize(this.props.groupStyle).height
        const gridHeight = computeGridHeight(
            this.props.groupStyle,
            this.props.totalItems,
        )
        const groupPositionTop = this.props.groupPosition || 0
        const farOutCutoff = this.props.cutoffThreshold || window.innerHeight

        const topPixelsToIgnore = Math.max(
            this.props.visibleRange.top - groupPositionTop - farOutCutoff,
            0,
        )
        const bottomPixelsToIgnore = Math.max(
            groupPositionTop +
                gridHeight -
                this.props.visibleRange.bottom -
                farOutCutoff,
            0,
        )
        const itemsPerRow = calcImagesPerRow(this.props.groupStyle)

        const topItemsToIgnore =
            Math.floor(topPixelsToIgnore / elementHeight) * itemsPerRow
        const bottomItemsToIgnore =
            Math.floor(bottomPixelsToIgnore / elementHeight) * itemsPerRow

        return {
            gridHeight,
            gridOffset: (topItemsToIgnore * elementHeight) / itemsPerRow,
            visibleIndexes: getRange(
                topItemsToIgnore,
                this.props.totalItems - bottomItemsToIgnore,
            ),
        }
    }

    public render() {
        const { visibleIndexes, gridHeight, gridOffset } =
            this.getVisibleGridItemIndexesEtc()

        return (
            <div style={{ height: gridHeight, position: 'relative' }}>
                <div style={{ position: 'relative', top: gridOffset }}>
                    <VirtualGrid
                        elements={visibleIndexes.map(
                            this.props.getVisibleElement,
                        )}
                        groupStyle={this.props.groupStyle}
                        startIndex={visibleIndexes[0]}
                    />
                </div>
            </div>
        )
    }
}
