import type { Store } from '@reduxjs/toolkit'
import { getHostDirectory } from '~/state/hosts/selectors'
import {
    getAuthTokenForJob,
    getForeignAuthForJob,
} from '~/state/currentUser/selectors'
import { FetchedHostDirectory } from '~/state/hosts/actions'
import { getServiceDict } from './apiUtils'
import {
    LOGIN_HOST,
    type ServiceDict,
    getAuthToken,
    getStoredServiceDict,
} from './externals'
import { AppService } from './services/AppService'
import { PollService } from './services/PollService'
import { ThumbService } from './services/ThumbService'
import { VideoService } from './services/VideoService'
import { BrowserFetchObject } from './toolbox'

class ServiceProvider {
    constructor(private store: Store) {}

    private getHostDirectory(job: JobID): Promise<ServiceDict> {
        const hostDirectory = getHostDirectory(this.store.getState())
        if (hostDirectory) {
            return Promise.resolve<ServiceDict>(hostDirectory)
        }

        return getServiceDict(LOGIN_HOST, job).then(
            ({ hosts }) => {
                this.store.dispatch(FetchedHostDirectory({ hosts }))
                return hosts
            },
            (error) => {
                // we cannot recover from not having hosts
                throw error
            },
        )
    }

    private getAuthTokenForJob(job: JobID): string {
        return getAuthTokenForJob(this.store.getState(), job)
    }

    // ForeignAuth is used when the current user is not the owner of the job and is required to perform write-operations on the job
    private getForeignAuthForJob(job: JobID): string | undefined {
        return getForeignAuthForJob(this.store.getState(), job)
    }

    public async getAppServiceForLoggedInUserDefaults(): Promise<AppService> {
        const hosts = getStoredServiceDict()
        const token = getAuthToken()
        if (hosts === undefined || token === undefined) {
            throw new Error(
                'Service dict must be made available (by logging in etc)',
            )
        }
        return new AppService(BrowserFetchObject, hosts, token)
    }

    public async getAppServiceForJob(job: JobID): Promise<AppService> {
        return this.getHostDirectory(job).then(
            (hosts) =>
                new AppService(
                    BrowserFetchObject,
                    hosts,
                    this.getAuthTokenForJob(job),
                    this.getForeignAuthForJob(job),
                ),
        )
    }

    public async getPollServiceForJob(job: JobID): Promise<PollService> {
        return this.getHostDirectory(job).then(
            (hosts: ServiceDict) => new PollService(hosts.pollHost),
        )
    }

    public async getThumbServiceForJob(job: JobID): Promise<ThumbService> {
        return this.getHostDirectory(job).then(
            (hosts: ServiceDict) =>
                new ThumbService(hosts.thumbHost, this.getAuthTokenForJob(job)),
        )
    }

    public async getVideoServiceForJob(job: JobID): Promise<VideoService> {
        return this.getHostDirectory(job).then(
            (hosts: ServiceDict) =>
                new VideoService(hosts.videoHost, this.getAuthTokenForJob(job)),
        )
    }
}

let instance: ServiceProvider

// To connect the ServiceProvider to the store so that it can
export const connectServiceProvider = (store: Store) => {
    instance = new ServiceProvider(store)
}

// Allow setting other serviceProviders too (primarily for testing purposes)
export const setServiceProvider = (provider: ServiceProvider) => {
    instance = provider
}

export const getServiceProvider = () => {
    if (instance === undefined) {
        // Getting this error? It is because connectHostProvider-method (above) has not been called
        throw new Error(
            'HostProvider must be connected before it is being used',
        )
    }
    return instance
}
