import * as React from 'react'

type Props<T, U> = {
    method: (val: T, ...args: U[]) => void
    arg: T
    children: (handleClick: () => void) => React.ReactNode
}
export class MethodProvider<T, U> extends React.Component<Props<T, U>> {
    private callback = (...cbArgs: U[]) =>
        this.props.method(this.props.arg, ...cbArgs)

    public render() {
        return this.props.children(this.callback)
    }
}
