import type {
    Dispatch,
    PayloadAction,
    TypedStartListening,
} from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { sessionStorageGet, sessionStorageSet } from '~/utilities/webStorage'
import {
    FetchedAccountInfo,
    NewUserCreated,
    TOSTermsVerified,
} from '../currentUser/actions'
import { requiredSubscription } from '../resume/resumeSlice'
import type { getSunsetDeletionDate } from '../currentUser/selectors'
import { getIsReadOnlyUser } from '../currentUser/selectors'
import { PurchaseSucceeded, RefusedSubscription } from '../storagePlan/actions'

/**
 * Declare list of modals managed by the placement
 */
export enum ManagedModal {
    Subscription = 'Subscription',
    TermsOfService = 'TermsOfService',
    SunsetDeletion = 'SunsetDeletion',
}

export type ModalManagerState = {
    visibleModals: ManagedModal[]
}

const initialState: ModalManagerState = {
    visibleModals: [],
}

const modalManagerSlice = createSlice({
    name: 'modalManager',
    initialState: initialState,
    reducers: {
        showModal: (state, action: PayloadAction<ManagedModal>) => {
            if (!state.visibleModals.includes(action.payload)) {
                state.visibleModals.push(action.payload)
            }
        },
        hideModal: (state) => {
            state.visibleModals.shift()
        },
        dismissSunsetDeletionModal: (state) => {
            if (state.visibleModals[0] === ManagedModal.SunsetDeletion) {
                state.visibleModals.shift()
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(requiredSubscription, (state, _action) => {
                state.visibleModals.push(ManagedModal.Subscription)
            })
            .addCase(TOSTermsVerified, (state, _action) => {
                if (state.visibleModals[0] === ManagedModal.TermsOfService) {
                    state.visibleModals.shift()
                }
            })
            .addCase(RefusedSubscription, (state, _action) => {
                if (state.visibleModals[0] === ManagedModal.Subscription) {
                    state.visibleModals.shift()
                }
            })
            .addCase(PurchaseSucceeded, (state, _action) => {
                if (state.visibleModals[0] === ManagedModal.Subscription) {
                    state.visibleModals.shift()
                }
            })
    },
})

type ModalListenerState = StateWithModalManager &
    StateOfSelector<typeof getIsReadOnlyUser> &
    StateOfSelector<typeof getSunsetDeletionDate>
export const addModalListeners = (
    startListening: TypedStartListening<ModalListenerState, Dispatch>,
) => {
    startListening({
        actionCreator: dismissSunsetDeletionModal,
        effect: () => {
            sessionStorageSet('hasSeenSunsetDeletionPrompt', 'true')
        },
    })

    startListening({
        actionCreator: FetchedAccountInfo,
        effect: (action, listenerApi) => {
            const accountInfo = action.payload
            if (accountInfo.tos_to_approve !== null) {
                listenerApi.dispatch(showModal(ManagedModal.TermsOfService))
            }

            const hasSeenSunsetDeletionPrompt =
                sessionStorageGet('hasSeenSunsetDeletionPrompt') !== null

            if (
                accountInfo.sunset_delete_date !== undefined &&
                !hasSeenSunsetDeletionPrompt
            ) {
                listenerApi.dispatch(showModal(ManagedModal.SunsetDeletion))
            }
        },
    })

    startListening({
        actionCreator: NewUserCreated,
        effect: (_action, listenerApi) => {
            const currentState = listenerApi.getState()
            const isReadOnlyUser = getIsReadOnlyUser(currentState)

            if (isReadOnlyUser) {
                listenerApi.dispatch(showModal(ManagedModal.Subscription))
            }
        },
    })
}

export const { showModal, hideModal, dismissSunsetDeletionModal } =
    modalManagerSlice.actions

export const modalManagerReducerMapObj = {
    [modalManagerSlice.name]: modalManagerSlice.reducer,
}

export type StateWithModalManager = StateOfReducerMapObj<
    typeof modalManagerReducerMapObj
>
