import { getRange } from '~/utilities/arrayUtils'

export type TimelineSectionReference = {
    year: number
    month: number
    startDay: number
    endDay: number
}

export const TimelineSectionReference__asDays = (
    ref: TimelineSectionReference,
): { start: DayRef; end: DayRef } => ({
    start: { year: ref.year, month: ref.month, day: ref.startDay },
    end: { year: ref.year, month: ref.month, day: ref.endDay },
})

export const TimelineSectionReference__allDays = ({
    year,
    month,
    startDay,
    endDay,
}: TimelineSectionReference): DayRef[] =>
    getRange(startDay, endDay + 1 /* Include endDay in range */).map((day) => ({
        year,
        month,
        day,
    }))

export const TimelineSectionReference__asString = (
    ref: TimelineSectionReference,
): string => {
    return [ref.year, ref.month, ref.startDay, ref.endDay].join('-')
}

export const TimelineSectionReference__fromString = (
    str: string,
): TimelineSectionReference => {
    // TODO: Add tests, make robust
    const [year, month, startDay, endDay] = str
        .split('-')
        .map((v) => Number.parseInt(v, 10))
    return { year, month, startDay, endDay }
}

export const TimelineSectionReference__compare = (
    a: TimelineSectionReference,
    b: TimelineSectionReference,
) =>
    // Alternative: TimelineSectionReference__asString(a).localeCompare(TimelineSectionReference__asString(b))
    1000 * (b.year - a.year) +
    50 * (b.month - a.month) +
    (b.startDay - a.startDay)
