import styled from 'styled-components'
import { colors } from '~/assets/styleConstants'
import { ContainerWithStickyHeader } from './ContainerWithStickyHeader'
import type { ThreeColumns } from './ThreeColumnsRow'
import { ThreeColumnsRow } from './ThreeColumnsRow'

const HeaderWrapper = styled.div`
    margin: auto;
    color: ${colors.captureGrey400};
    text-transform: capitalize;
`

const ContentWrapper = styled.div`
    margin: 10px auto;
`

type Props = {
    isMobile: boolean
    headerColumns: ThreeColumns
    rows: React.ReactNode[]
    stickyHeaderOffset?: number
}

export const FilesTable = (props: Props) => {
    return (
        <ContainerWithStickyHeader
            header={
                <HeaderWrapper>
                    <ThreeColumnsRow
                        isMobile={props.isMobile}
                        columns={props.headerColumns}
                    />
                </HeaderWrapper>
            }
            fixedPositionOffset={props.stickyHeaderOffset || 0}>
            <ContentWrapper>{props.rows}</ContentWrapper>
        </ContainerWithStickyHeader>
    )
}
