/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    CAPBAKDeleteAlbumsFromTrashCanDeleteParams,
    CAPBAKDeleteAlbumsResponse,
    CAPBAKHTTPValidationError,
    CAPBAKJobUUIDList,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class DeleteAlbumsFromTrashCan<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * @description this call is used to permanently delete albums from trash can
     *
     * @tags trash_can
     * @name DeleteAlbumsFromTrashCanDelete
     * @summary Delete Albums From Trash Can
     * @request DELETE:/delete_albums_from_trash_can
     */
    deleteAlbumsFromTrashCanDelete = (
        query: CAPBAKDeleteAlbumsFromTrashCanDeleteParams,
        data: CAPBAKJobUUIDList,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKDeleteAlbumsResponse,
            void | CAPBAKHTTPValidationError
        >({
            path: `/delete_albums_from_trash_can`,
            method: 'DELETE',
            query: query,
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
        })
}
