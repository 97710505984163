import * as React from 'react'
import styled from 'styled-components'
import { colors } from '~/assets/styleConstants'
import { TextButton } from './IconTextButton'
import { TextInput } from './Input'

const EditableTextInput = styled(TextInput)`
    margin-right: 8px;
    flex-grow: 1;
    background-color: inherit;
    text-overflow: ellipsis;
    text-align: ${(props: { alignCentered: boolean | undefined }) =>
        props.alignCentered ? 'center' : 'inherit'};
    font-size: inherit;
    max-width: 100%;
    width: ${(props) => props.width}px;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;
    border-bottom: ${(props: { hasBottomBorder: boolean | undefined }) =>
        props.hasBottomBorder ? `solid 1px ${colors.black}` : ''};
`

const Placeholder = styled.div`
    width: ${(props: { size: number }) => props.size}px;
    height: ${(props: { size: number }) => props.size}px;
    flex-shrink: 0;
    margin-right: 8px;
`
const Button = styled.div`
    cursor: pointer;
`

export type InputFieldProps = {
    initialValue?: string
    onBlur?: (value: string) => void
    placeholder?: string
    onChange?: (value: string) => void
    onFocus?: () => void
    maxLength?: number
    shouldClearInput?: boolean
    alignCentered?: boolean
    focusOnMount?: boolean
}

type State = {
    editedText: string
}

type TextFieldWithButtonProps = InputFieldProps & {
    button: React.ReactNode
    placeholderSize?: number
    onButtonClick?: (text?: string) => void
    hasBottomBorder?: boolean
}

export class TextFieldWithButton extends React.Component<
    TextFieldWithButtonProps,
    State
> {
    public state: State = {
        editedText: this.props.initialValue || '',
    }
    private input = React.createRef<HTMLInputElement>()

    private handleFocus = () => {
        if (this.props.onFocus) {
            this.props.onFocus()
        }
        if (this.props.shouldClearInput) {
            this.setState({
                ...this.state,
                editedText: '',
            })
        }
    }

    private handleTextChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
        if (this.props.onChange) {
            this.props.onChange(evt.target.value)
        }
        this.setState({
            ...this.state,
            editedText: evt.target.value,
        })
    }

    private handleInputBlur = () => {
        if (this.props.onBlur) {
            this.props.onBlur(this.state.editedText)
        }
    }

    private handleButtonClick = () => {
        if (this.props.onButtonClick) {
            this.props.onButtonClick(this.state.editedText)
        }
    }
    private refocusInput = () => {
        if (this.input.current) {
            this.input.current.focus()
            // focus to end of textarea content
            this.input.current.selectionStart =
                this.input.current.selectionEnd =
                    this.input.current.value.length
        }
    }
    private handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (this.input.current && e.keyCode === 13) {
            this.input.current.blur()
        }
    }

    public componentDidMount() {
        if (this.props.focusOnMount && this.input.current) {
            this.input.current.focus()
        }
    }

    public render() {
        return (
            <Wrapper hasBottomBorder={this.props.hasBottomBorder}>
                {this.props.alignCentered && (
                    <Placeholder size={this.props.placeholderSize || 0} />
                )}
                <EditableTextInput
                    data-cy="editableTextInput"
                    ref={this.input}
                    value={this.state.editedText}
                    placeholder={this.props.placeholder}
                    onChange={this.handleTextChanged}
                    onBlur={this.handleInputBlur}
                    maxLength={this.props.maxLength}
                    onFocus={this.handleFocus}
                    onKeyDown={this.handleKeyPress}
                    alignCentered={this.props.alignCentered}
                    width={(this.state.editedText.length + 1) * 11}
                />
                <Button
                    onClick={
                        this.props.onButtonClick
                            ? this.handleButtonClick
                            : this.refocusInput
                    }
                    onKeyUp={
                        this.props.onButtonClick
                            ? this.handleButtonClick
                            : this.refocusInput
                    }
                    role="button"
                    tabIndex={0}>
                    {this.props.button}
                </Button>
            </Wrapper>
        )
    }
}

type TextWithCaptureBtnProps = {
    buttonText: string
    onButtonClick: (text?: string) => void
    fontSize?: string
}
export const TextFieldWithCaptureButton: React.FunctionComponent<
    InputFieldProps & TextWithCaptureBtnProps
> = (props) => (
    <TextFieldWithButton
        button={
            <TextButton
                text={props.buttonText}
                color={colors.captureBlue}
                fontSize={props.fontSize}
            />
        }
        {...props}
    />
)
