import type { Reaction } from '~/@types/backend-types'
import type { Action } from '../common/actions'
import { isType } from '../common/actions'
import { ReactionAdded, ReactionDeleted, Reactions } from './actions'

export type FileReaction = Record<UserID, Reaction>

const initialFileReaction = {}

export type ReactionState = Record<FileID, FileReaction>

const initialState: ReactionState = {}

const updateStateWithChanges = (
    state: ReactionState,
    fileID: FileID,
    changes: FileReaction,
): ReactionState => {
    return {
        ...state,
        [fileID]: {
            ...(state[fileID] || initialFileReaction),
            ...changes,
        },
    }
}

export const reactionReducer = (
    state: ReactionState = initialState,
    action: Action,
): ReactionState => {
    if (isType(action, ReactionAdded)) {
        const { fileID, userUUID, reaction } = action.payload
        return updateStateWithChanges(state, fileID, {
            [userUUID]: reaction,
        })
    }

    if (isType(action, ReactionDeleted)) {
        const { fileID, userUUID } = action.payload
        return updateStateWithChanges(state, fileID, {
            [userUUID]: Reactions.None,
        })
    }

    return state
}

export const reactionReducerMapObj = {
    reaction: reactionReducer,
}

export type StateWithReaction = StateOfReducerMapObj<
    typeof reactionReducerMapObj
>
