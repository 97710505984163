import type { CropData, CroppieOptions } from 'croppie'
import Croppie from 'croppie'
import React, { useEffect, useRef } from 'react'
import '~/assets/croppie.scss'

type CroppieInitOptions = {
    points?: [number, number, number, number] // Position of [left, top, right, bottom] relative to (top, left)
    zoom?: number
    orientation?: number /* 1-8 */
    useCanvas?: boolean
}

type Props = {
    url: string
    options?: CroppieOptions
    initOptions?: CroppieInitOptions
    onCropChanged: (data?: CropData) => void
}

export const CroppieComponent: React.FunctionComponent<Props> = (props) => {
    let croppieClass: Croppie | null = null
    const targetElement = useRef<HTMLDivElement>(null)
    // We use this to prevent the premature croppieClass.destroy() before  await croppieClass.bind
    let binded = false

    const onUpdate = () => props.onCropChanged(getCroppingData())

    const getCroppingData = (): CropData | undefined => {
        if (croppieClass !== null) {
            return croppieClass.get()
        }

        return undefined
    }

    useEffect(() => {
        const initCroppie = async () => {
            if (targetElement.current && croppieClass === null) {
                croppieClass = new Croppie(targetElement.current, {
                    ...props.options,
                })
                await croppieClass.bind({
                    url: props.url,
                    ...props.initOptions,
                })
                binded = true

                targetElement.current.addEventListener('update', onUpdate)
                props.onCropChanged(getCroppingData())
            }
        }
        initCroppie().catch(console.error)

        return () => {
            if (targetElement.current && croppieClass !== null && binded) {
                targetElement.current.removeEventListener('update', onUpdate)

                croppieClass.destroy()
                croppieClass = null
            }
        }
    }, [props.url])

    return <div ref={targetElement} data-cy={'croppie'} />
}
