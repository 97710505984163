import { createActionCreator } from '../common/actions'
import type { StoragePopupType } from './reducer'

type TriggeredDisplayOfPopupPayload = {
    type: StoragePopupType
    triggeredAt: number
}
export const TriggeredDisplayOfPopup =
    createActionCreator<TriggeredDisplayOfPopupPayload>(
        'TRIGGERED_DISPLAY_OF_POPUP',
    )
export const PopupTriggered = (type: StoragePopupType) =>
    TriggeredDisplayOfPopup({ type, triggeredAt: new Date().getTime() })

type PopupClosedPayload = { type: StoragePopupType; silencedUntil?: number }
export const PopupClosed =
    createActionCreator<PopupClosedPayload>('POPUP_CLOSED')
