import type { Store } from '@reduxjs/toolkit'
import { getCurrentUserUUID } from '~/state/currentUser/selectors'
import type { TimelineSectionReference } from '~/state/timeline'
import {
    TimelineSectionReference__asDays,
    TimelineSectionReference__asString,
    TimelineSectionReference__compare,
} from '~/state/timeline'
import {
    getTimelineJobID,
    getUnfetchedTimelineSections,
} from '~/state/timeline/selectors'
import { tranformIntoCachedInArray } from '~/utilities/arrayUtils'
import { timelineReducerMapObj } from '~/state/timeline/reducers'
import { currentUserReducerMapObj } from '~/state/currentUser/reducer'
import { filesReducerMapObj } from '~/state/files/reducer'
import { hostsReducerMapObj } from '~/state/hosts/reducer'
import { jobInfoReducerMapObj } from '~/state/jobInfo/reducer'
import { fileMetadataReducerMapObj } from '~/state/fileMetadata/reducer'
import type { FetchFileRangeMethod } from '../job'
import { fetchFileRange } from '../job'

export const timelineChunkSyncerReducerMapObj = {
    ...timelineReducerMapObj,
    ...currentUserReducerMapObj,
    ...filesReducerMapObj,
    ...hostsReducerMapObj,
    ...jobInfoReducerMapObj,
    ...fileMetadataReducerMapObj,
}

type TimelineChunkSyncerState = StateOfReducerMapObj<
    typeof timelineChunkSyncerReducerMapObj
>

export class TimelineChunkSyncer {
    constructor(
        private store: Store<TimelineChunkSyncerState>,
        private fetchRange: FetchFileRangeMethod,
    ) {}

    public async setCurrentVisibleRanges(
        visibleSections: TimelineSectionReference[],
    ) {
        const state = this.store.getState()

        if (state === undefined) {
            console.warn('TimelineChunkSyncer: undefined state.')
            return
        }

        const jobID = getTimelineJobID(state)
        const currentUser = getCurrentUserUUID(state)

        const isUnfetched = tranformIntoCachedInArray(
            getUnfetchedTimelineSections(state),
            TimelineSectionReference__asString,
        )

        const ranges2fetch = visibleSections
            .filter(isUnfetched)
            .sort(TimelineSectionReference__compare)

        if (jobID && currentUser && ranges2fetch.length > 0) {
            const newest = ranges2fetch[0]
            const oldest = ranges2fetch[ranges2fetch.length - 1]
            await this.fetchRange(
                this.store.dispatch,
                jobID,
                currentUser,
                TimelineSectionReference__asDays(oldest).start,
                TimelineSectionReference__asDays(newest).end,
            )
        }
    }
}

let instance: TimelineChunkSyncer
export const connectTimelineChunkSyncer = (store: Store) => {
    instance = new TimelineChunkSyncer(store, fetchFileRange)
}
// To be called from Timeline when visible ranges changes. Maybe better to dispatch these and get the "pending visible"
// -ranges from selector.
export const setCurrentVisibleRanges = (ranges: TimelineSectionReference[]) => {
    if (instance === undefined) {
        console.warn('setCurrentVisibleRanges ignored: Must be connected first')
        return
    }
    instance.setCurrentVisibleRanges(ranges)
}
