import type { EntityState, PayloadAction, Slice } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { FileWasRemovedFromJob } from '~/state/job/actions'
import type { CaptureFile } from '../files/selectors'
import { compareFileTime } from '../files/selectors'

export enum RecentFilesStatus {
    EMPTY = 'EMPTY',
    FETCHING = 'FETCHING',
    SUCCEEDED = 'SUCCEEDED',
    FAILED = 'FAILED',
}

export type RecentFilesState = {
    files: EntityState<CaptureFile>
    status: RecentFilesStatus
    jobId?: JobID
    error?: string
}

// Normalizing file state
export const recentFilesAdapter = createEntityAdapter<CaptureFile>({
    selectId: (file) => file.fileID,
    sortComparer: compareFileTime,
})

const initialState: RecentFilesState = {
    files: recentFilesAdapter.getInitialState(),
    status: RecentFilesStatus.EMPTY,
}

const caseReducers = {
    addRecentFile: (
        state: RecentFilesState,
        action: PayloadAction<CaptureFile>,
    ) => {
        recentFilesAdapter.addOne(state.files, action.payload)
    },
    removeRecentFile: (
        state: RecentFilesState,
        action: PayloadAction<FileID>,
    ) => {
        recentFilesAdapter.removeOne(state.files, action.payload)
    },
    addRecentFiles: (
        state: RecentFilesState,
        action: PayloadAction<CaptureFile[]>,
    ) => {
        recentFilesAdapter.addMany(state.files, action.payload)
    },
    removeRecentFiles: (
        state: RecentFilesState,
        action: PayloadAction<CaptureFile>,
    ) => {
        recentFilesAdapter.removeMany(state.files, [action.payload.fileID])
    },
    updateRecentsStatus: (
        state: RecentFilesState,
        action: PayloadAction<RecentFilesStatus>,
    ) => {
        state.status = action.payload
    },
    updateRecentsError: (
        state: RecentFilesState,
        action: PayloadAction<string>,
    ) => {
        state.error = action.payload
    },
}

export const recentFilesSlice: Slice<
    RecentFilesState,
    typeof caseReducers,
    'recentFiles'
> = createSlice({
    name: 'recentFiles',
    initialState,
    reducers: caseReducers,
    extraReducers: (builder) => {
        builder.addCase(FileWasRemovedFromJob, (state, action) => {
            if (action.payload.jobID === state.jobId) {
                recentFilesAdapter.removeOne(state.files, action.payload.fileID)
            }
        })
    },
})

export const {
    addRecentFile,
    addRecentFiles,
    removeRecentFile,
    removeRecentFiles,
    updateRecentsStatus,
    updateRecentsError,
} = recentFilesSlice.actions

export const recentFilesReducerMapObj = {
    [recentFilesSlice.name]: recentFilesSlice.reducer,
}

export type StateWithRecentFiles = StateOfReducerMapObj<
    typeof recentFilesReducerMapObj
>
