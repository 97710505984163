import { createSelector } from 'reselect'
import { getFileSize } from '~/utilities/fileSize'
import type { FileWithGrouping } from '../files/selectors'
import { getJobFilesWithGrouping } from '../files/selectors'
import type { StateWithSelectedFiles } from './reducer'

export const getSelectedFileIDs = (state: StateWithSelectedFiles) =>
    state.selectedFiles.files

export const getSelectedFilesForJob = createSelector(
    getSelectedFileIDs,
    getJobFilesWithGrouping,
    (fileIDs, jobFiles: FileWithGrouping[]): FileWithGrouping[] => {
        return jobFiles.filter((jobFile) => fileIDs.includes(jobFile.fileID))
    },
)

export const getSelectedFileIDsforJob = createSelector(
    getSelectedFilesForJob,
    (files): FileID[] => files.map((f) => f.fileID),
)

export const getSelectedFileSizeforJob = createSelector(
    getSelectedFilesForJob,
    (files): number => files.reduce((acc, f) => acc + getFileSize(f), 0),
)

export const makeIsFileSelected = (fileID: FileID) =>
    createSelector(getSelectedFileIDs, (files) => files.includes(fileID))

export const getSelectionAction = (state: StateWithSelectedFiles) =>
    state.selectedFiles.selectionAction
