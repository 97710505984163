import type { SVGProps } from 'react'
const SvgLogout = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill={props.color ?? 'currentColor'}
            d="M5 21c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 3 19V5c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 5 3h6c.283 0 .52.096.713.288.191.191.287.429.287.712s-.096.52-.287.713A.968.968 0 0 1 11 5H5v14h6c.283 0 .52.096.713.288.191.191.287.429.287.712s-.096.52-.287.712A.968.968 0 0 1 11 21zm12.175-8H10a.967.967 0 0 1-.713-.287A.968.968 0 0 1 9 12c0-.283.096-.52.287-.713A.967.967 0 0 1 10 11h7.175L15.3 9.125a.918.918 0 0 1-.275-.675c0-.267.092-.5.275-.7a.948.948 0 0 1 .7-.312.946.946 0 0 1 .725.287L20.3 11.3c.2.2.3.433.3.7 0 .267-.1.5-.3.7l-3.575 3.575a.93.93 0 0 1-.713.288 1.02 1.02 0 0 1-.712-.313.973.973 0 0 1-.263-.713.977.977 0 0 1 .288-.687z"
        />
    </svg>
)
export default SvgLogout
