import * as React from 'react'
import styled from 'styled-components'
import type { BasicViewFile } from '../../state/files/selectors'
import { elementSizeCalculator } from '../../utilities/elementSizeCalculator'
import { FileTarget } from '../../utilities/fileTarget'
import { FullscreenOverlay } from '../Common/FullscreenOverlay'
import { ImageDisplayer } from '../Common/ImageDisplayer'
import { VideoPreview } from '../Video/VideoPreview'

const MediaWrapper = styled.div`
    padding: 4px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    //prevent long press from triggering native image options
    pointer-events: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
`

const MediaSizer = styled.div`
    position: relative;
    width: ${(props: { dimensions: TwoDimensions }) =>
        props.dimensions.width}px;
    height: ${(props) => props.dimensions.height}px;
    img {
        width: ${(props) => props.dimensions.width}px;
        height: ${(props) => props.dimensions.height}px;
    }
`

type Props = {
    onOverlayClick?: (event: React.MouseEvent<HTMLDivElement>) => void
    currentFile: BasicViewFile
}

export class FullscreenPhotoOverlay extends React.Component<Props> {
    private getViewerNodeSize = () => {
        const viewportSize = {
            width: window.innerWidth,
            height: window.innerHeight,
        }
        if (
            this.props.currentFile.width === undefined ||
            this.props.currentFile.height === undefined
        ) {
            return viewportSize
        }
        const cal = elementSizeCalculator({
            width: window.innerWidth,
            height: window.innerHeight,
        })
        return cal({
            width: this.props.currentFile.width,
            height: this.props.currentFile.height,
        })
    }
    public render() {
        const content = (() => {
            if (this.props.currentFile.type === FileTarget.Pictures) {
                return <ImageDisplayer file={this.props.currentFile} />
            }

            if (this.props.currentFile.type === FileTarget.Movies) {
                return (
                    <VideoPreview
                        autoPlay={true}
                        file={this.props.currentFile}
                    />
                )
            }
        })()

        return (
            <FullscreenOverlay
                onOverlayClick={this.props.onOverlayClick}
                color={'rgba(0,0,0,0.9)'}>
                <MediaWrapper>
                    <MediaSizer dimensions={this.getViewerNodeSize()}>
                        {content}
                    </MediaSizer>
                </MediaWrapper>
            </FullscreenOverlay>
        )
    }
}
