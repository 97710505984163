import { useSelector } from 'react-redux'
import { ManagedModal } from '~/state/modalManager/modalManagerSlice'
import { getVisibleModal } from '~/state/modalManager/selectors'
import { SubscribeModal } from '../SubscribeModal'
import { AcceptTOSModal } from '../Common/AcceptTOSModal'
import { SunsetDeletionPrompt } from '../Common/SunsetDeletionPrompt'

export const ModalPlacement = () => {
    const visibleModal = useSelector(getVisibleModal)

    if (visibleModal === ManagedModal.TermsOfService) {
        return <AcceptTOSModal />
    }

    if (visibleModal === ManagedModal.Subscription) {
        return <SubscribeModal />
    }

    if (visibleModal === ManagedModal.SunsetDeletion) {
        return <SunsetDeletionPrompt />
    }

    return null
}
