import * as React from 'react'
import styled from 'styled-components'
import { colors, layout } from '~/assets/styleConstants'
import { isBodyScrollable } from '~/utilities/preventBodyScroll'

const Wrapper = styled.div`
    width: 100%;
`

const headerRowHeight = 44
const HeaderRowPlaceholder = styled.div`
    height: ${headerRowHeight}px;
`

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: ${headerRowHeight}px;

    border-bottom: 1px solid ${colors.captureGrey400};
    background-color: ${colors.captureGrey50};

    > div {
        width: 100%;
    }
`

const getFixedWidth = () =>
    Math.min(document.body.clientWidth, layout.maxContainerWidth)
const FixedHeaderWrapper = styled(HeaderWrapper)`
    position: fixed;
    top: ${(props: { fixedPosition: number }) => props.fixedPosition}px;
    left: 0;

    width: 100%;

    > div {
        width: ${getFixedWidth}px;
        margin: 0;
    }
`

type Props = {
    header: React.ReactNode
    fixedPositionOffset: number
    children?: React.ReactNode
}

type State = {
    hasStickyHeader: boolean
}

export class ContainerWithStickyHeader extends React.Component<Props, State> {
    public state: State = {
        hasStickyHeader: false,
    }

    private sentryElem = React.createRef<HTMLDivElement>()

    private handleScrollEvent = () => {
        if (!this.sentryElem.current) {
            return
        }

        const shouldHeaderSticky =
            isBodyScrollable() &&
            this.props.fixedPositionOffset >=
                this.sentryElem.current.getBoundingClientRect().top
        if (this.state.hasStickyHeader !== shouldHeaderSticky) {
            this.setState({ hasStickyHeader: shouldHeaderSticky })
        }
    }

    public componentDidMount() {
        window.addEventListener('scroll', this.handleScrollEvent)
    }

    public componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScrollEvent)
    }

    public render() {
        if (this.state.hasStickyHeader) {
            return (
                <Wrapper ref={this.sentryElem}>
                    <HeaderRowPlaceholder />
                    {this.props.children}
                    <FixedHeaderWrapper
                        fixedPosition={this.props.fixedPositionOffset}>
                        {this.props.header}
                    </FixedHeaderWrapper>
                </Wrapper>
            )
        }

        return (
            <Wrapper ref={this.sentryElem}>
                <HeaderWrapper>{this.props.header}</HeaderWrapper>
                {this.props.children}
            </Wrapper>
        )
    }
}
