/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    CAPBAKHTTPValidationError,
    CAPBAKLogoutGetParams,
    CAPBAKLogoutPostParams,
    CAPBAKLogoutResponse,
} from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class Logout<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * @description Logout and delete the Capture token used in the API call. If the user has an active TelenorID session we also try to do a logout from TelenorID
     *
     * @tags logout
     * @name LogoutPost
     * @summary Logout
     * @request POST:/logout
     */
    logoutPost = (query: CAPBAKLogoutPostParams, params: RequestParams = {}) =>
        this.request<CAPBAKLogoutResponse, void | CAPBAKHTTPValidationError>({
            path: `/logout`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Logout and delete the Capture token used in the API call. If the user has an active TelenorID session we also try to do a logout from TelenorID
     *
     * @tags logout
     * @name LogoutGet
     * @summary Logout
     * @request GET:/logout
     */
    logoutGet = (query: CAPBAKLogoutGetParams, params: RequestParams = {}) =>
        this.request<CAPBAKLogoutResponse, void | CAPBAKHTTPValidationError>({
            path: `/logout`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
}
