import type { Action } from '../common/actions'
import { isType } from '../common/actions'
import {
    ClientViewportSizeChangeDisabled,
    ClientViewportSizeChangeEnabled,
    ClientViewportSizeChanged,
} from './actions'

export interface ViewModeState {
    width: number
    height: number
    sizeChangeEnabled: boolean
}

const initialState: ViewModeState = {
    width: typeof window !== 'undefined' ? window.innerWidth : 0,
    height: typeof window !== 'undefined' ? window.innerHeight : 0,
    sizeChangeEnabled: true,
}

export function viewModeReducer(
    state: ViewModeState = initialState,
    action: Action,
): ViewModeState {
    if (isType(action, ClientViewportSizeChanged) && state.sizeChangeEnabled) {
        return {
            ...state,
            ...action.payload,
        }
    }

    if (isType(action, ClientViewportSizeChangeDisabled)) {
        return {
            ...state,
            sizeChangeEnabled: false,
        }
    }

    if (isType(action, ClientViewportSizeChangeEnabled)) {
        return {
            ...state,
            sizeChangeEnabled: true,
            ...action.payload,
        }
    }

    return state
}

export const viewModeReducerMapObj = {
    viewMode: viewModeReducer,
}

export type StateWithViewMode = StateOfReducerMapObj<
    typeof viewModeReducerMapObj
>
