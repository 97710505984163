import type { Store } from '@reduxjs/toolkit'
import type { RequireUserInfoReason } from './actions'
import { ValidUserInfoRequired } from './actions'
import type { StateWithCurrentUser } from './reducer'
import {
    getCurrentUserUUID,
    isLoggedIn,
    mustProvideUserInfo,
} from './selectors'

type QueuedFunction = {
    ok: (v: string) => void
    reject: () => void
}

class UserExistenceGuarantee {
    private haveUser = false
    private queue: Array<QueuedFunction> = []

    constructor(private store: Store<StateWithCurrentUser>) {
        store.subscribe(() => {
            const state = store.getState()

            if (state === undefined) {
                console.warn('UserExistenceGuarantee: undefined state.')
                return
            }

            const haveUser = isLoggedIn(state)

            let f: QueuedFunction | undefined
            if (haveUser !== this.haveUser) {
                this.haveUser = haveUser
                const userId = getCurrentUserUUID(state)
                while ((f = this.queue.pop())) {
                    f.ok(userId!)
                }
            }
            if (!mustProvideUserInfo(state) && this.queue.length > 0) {
                while ((f = this.queue.pop())) {
                    f.reject()
                }
            }
        })
    }

    public requireUserInfo = (
        reason?: RequireUserInfoReason,
    ): Promise<UserID> => {
        if (this.haveUser) {
            // isLoggedIn selector return as true,
            // getCurrentUserUUID selector
            // should return valid userId
            const state = this.store.getState()
            const userId = getCurrentUserUUID(state)
            return Promise.resolve(userId as string)
        }

        this.store.dispatch(ValidUserInfoRequired(reason))
        return new Promise((ok, reject) => {
            this.queue.push({ ok, reject })
        })
    }
}

let instance: UserExistenceGuarantee | undefined
export const connectUserGuarantee = (store: Store<StateWithCurrentUser>) => {
    instance = new UserExistenceGuarantee(store)
}

export const guaranteeAUser = (
    reason?: RequireUserInfoReason,
): Promise<UserID> => {
    if (instance === undefined) {
        // Getting this error? It is because connectUserGuarantee-method (above) has not been called
        throw new Error(
            'UserIfoProvider must be connected before it is being used',
        )
    }
    return instance.requireUserInfo(reason)
}
