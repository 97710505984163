import type { Store } from '@reduxjs/toolkit'
import {
    getCurrentUserUUID,
    isLoggedIn,
    isLoggingIn,
} from '~/state/currentUser/selectors'
import { addTrackerInternal, allowInternalTracking } from './eventTracking'

export const connectAnalyticsConsent = (store: Store) => {
    // for not logged in users, a session_id is generated by cumulus, user is not identified
    const getCurrentUserID = () => getCurrentUserUUID(store.getState()) || ''

    // since the tracker keept the reference to the function which generates the user ID,
    // there is no need to update if the user ID changes (eg: user logs in)
    addTrackerInternal(getCurrentUserID)

    // When logging in, the consent is detected by analyticsMiddleware (when the account info is fetched)
    // Meanwhile we allow internal tracking
    if (!isLoggingIn(store.getState()) && !isLoggedIn(store.getState())) {
        allowInternalTracking()
    }
}
