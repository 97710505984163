import { createActionCreator, createVoidActionCreator } from '../common/actions'
import type { AlbumOptions } from './reducer'

export const AlbumOptionsOverlayTriggered = createActionCreator<AlbumOptions>(
    'DISPLAY_OPTIONS_OVERLAY_TRIGGERED',
)
export const AlbumOptionsOverlayClosed = createVoidActionCreator(
    'OPTIONS_OVERLAY_CLOSED',
)

export const AlbumLinkWasCopied = createVoidActionCreator('LINK_WAS_COPIED')

export const AlbumChangedToPrivate =
    createVoidActionCreator('ALBUM_MADE_PRIVATE')
