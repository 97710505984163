import { isMinSky } from '~/config/constants'

/* tslint:disable:max-line-length jsx-self-close */
const TelenorCapture: React.FunctionComponent<{
    height: number
    width: number
    color?: string
}> = (props) => (
    <svg
        version="1.1"
        x="0px"
        y="0px"
        width={props.width}
        height={props.height}
        viewBox="0 0 156 40.7">
        <g fill={props.color || ''}>
            <polygon points="96.6,32.4 96.6,5.8 97.4,5.8 97.4,32.4 " />
            <path
                fill={props.color || '#15ABE3'}
                d="M23.8,12.7c0.2-0.2,0.7-0.6,0.8-0.6c0.1-0.9,0.4-2.4,1.2-3.9c0.8-1.6,2.1-3.4,3.8-4.6c1.5-1,3.9-2.1,5.8-2.5 c1.5-0.3,3-0.4,4.2-0.3c2.5,0.2,4,1,4.7,1.9c0.3,0.4,0.4,0.8,0.4,1.1c0,0.5-0.2,1.1-0.9,1.7c-0.7,0.6-2,1.3-4,2 c-2,0.7-4.7,1.4-7.4,2c-2.3,0.5-4.1,1.1-5.1,1.5c-0.3,0.1-0.5,0.2-0.8,0.3c-0.2,0.4-0.6,1.5-0.7,2.1c2,0.8,3.3,1.8,4.2,2.7 c1.3,1.1,2.8,2.3,4.6,4.5c1.7,2,4.4,6,5.4,9.8c1.1,4.2,0.4,8.1-1.9,9.2c-2.3,1.1-5.4-0.5-7.5-2.7c-2.1-2.1-3.5-4.7-4.8-8.5 c-1.2-3.3-1.6-8.2-1.6-10.7c0-0.8,0-1.3,0-2.1c-0.9-0.6-2.1-0.6-3.3,0.1c-2.3,1.3-4.6,4.2-5.9,5.6c-0.6,0.6-1.3,1.6-2.1,2.6 c-1,1.3-2.2,2.7-3.2,3.4c-1.6,1.1-4.1,1.6-5.9,0.4c-1-0.7-1.5-2-1.5-3.4c0-1,0.2-1.8,0.7-2.7c0.6-1,1.6-2.2,3.1-3.4 c1.6-1.3,4.1-2.8,6.7-3.8C16.8,12.7,21,12.2,23.8,12.7L23.8,12.7z"
            />
            <g>
                <path d="M48,24.7c-1.3,0-1.8-0.8-1.8-1.9v-7h-1.3c-0.1,0-0.1-0.2-0.1-0.4c0-0.2,0-0.4,0.1-0.4h1.3v-2.2c0-0.1,0.3-0.1,0.5-0.1 c0.2,0,0.5,0,0.5,0.1V15h2.1c0.1,0,0.1,0.2,0.1,0.4c0,0.2,0,0.4-0.1,0.4h-2.1v6.9c0,0.8,0.3,1,1.1,1h1c0.1,0,0.2,0,0.2,0.4 c0,0.4,0,0.5-0.2,0.5L48,24.7L48,24.7z" />
                <path d="M130.1,24.7c-1.3,0-1.8-0.8-1.8-1.9v-7h-1.3c-0.1,0-0.1-0.2-0.1-0.4c0-0.2,0-0.4,0.1-0.4h1.3v-2.2c0-0.1,0.3-0.1,0.5-0.1 s0.5,0,0.5,0.1V15h2.1c0.1,0,0.1,0.2,0.1,0.4c0,0.2,0,0.4-0.1,0.4h-2.1v6.9c0,0.8,0.3,1,1.1,1h1c0.1,0,0.2,0,0.2,0.4 c0,0.4,0,0.5-0.2,0.5L130.1,24.7L130.1,24.7z" />
                <path d="M51,20.1v0.8c0,1.7,0.9,2.8,2.7,2.8c1.4,0,2.2-0.4,2.9-0.8c0.1-0.1,0.6,0.7,0.4,0.8c-0.9,0.5-2,0.8-3.3,0.8 c-2.3,0-3.7-1.3-3.7-3.6v-2.8c0-2.2,1.5-3.6,3.5-3.6c2.2,0,3.6,1.4,3.6,3.8v1.7L51,20.1L51,20.1z M56.2,18.3 c0-1.9-1.2-2.9-2.7-2.9c-1.4,0-2.6,0.9-2.6,2.8v1h5.2L56.2,18.3L56.2,18.3z" />
                <path d="M62.6,20.1v0.8c0,1.7,0.9,2.8,2.7,2.8c1.4,0,2.2-0.4,2.9-0.8c0.1-0.1,0.6,0.7,0.4,0.8c-0.9,0.5-2,0.8-3.3,0.8 c-2.3,0-3.7-1.3-3.7-3.6v-2.8c0-2.2,1.5-3.6,3.5-3.6c2.2,0,3.6,1.4,3.6,3.8v1.7L62.6,20.1L62.6,20.1z M67.8,18.3 c0-1.9-1.2-2.9-2.7-2.9c-1.4,0-2.6,0.9-2.6,2.8v1h5.2L67.8,18.3L67.8,18.3z" />
                <path d="M146.3,20.1v0.8c0,1.7,0.9,2.8,2.7,2.8c1.4,0,2.2-0.4,2.9-0.8c0.1-0.1,0.6,0.7,0.4,0.8c-0.9,0.5-2,0.8-3.3,0.8 c-2.3,0-3.7-1.3-3.7-3.6v-2.8c0-2.2,1.5-3.6,3.5-3.6c2.2,0,3.6,1.4,3.6,3.8v1.7L146.3,20.1L146.3,20.1z M151.5,18.3 c0-1.9-1.2-2.9-2.7-2.9c-1.4,0-2.6,0.9-2.6,2.8v1h5.2V18.3z" />
                <path d="M60.4,24.6c-1.3,0-1.8-0.8-1.8-1.9V11.5c0-0.1,0.3-0.1,0.5-0.1c0.2,0,0.5,0,0.5,0.1v11.2c0,0.8,0.1,1.1,0.8,1.1h0.5 c0.1,0,0.2,0,0.2,0.4c0,0.4,0,0.4-0.2,0.4L60.4,24.6L60.4,24.6z" />
                <path d="M76.4,24.5c-0.2,0-0.5,0-0.5-0.1v-6.4c0-1.6-0.7-2.3-1.8-2.3c-1.2,0-2.2,1.1-2.9,2.1v6.7c0,0.1-0.3,0.1-0.5,0.1 s-0.5,0-0.5-0.1v-9.5c0-0.1,0.3-0.1,0.5-0.1s0.5,0,0.5,0.1v1.7c0.8-1.1,1.8-2,3.2-2c1.5,0,2.5,1.2,2.5,2.9v6.9 C76.9,24.5,76.5,24.5,76.4,24.5z" />
                <path d="M78.4,21.2V18c0-2.3,1.3-3.5,3.7-3.5c2.4,0,3.7,1.2,3.7,3.5v3.2c0,2.3-1.4,3.5-3.7,3.5C79.7,24.7,78.4,23.4,78.4,21.2z M82.1,15.4c-1.7,0-2.7,0.7-2.7,2.8V21c0,2,1,2.8,2.7,2.8c1.7,0,2.8-0.7,2.8-2.8v-2.8C84.8,16.1,83.8,15.4,82.1,15.4z" />
                <path d="M90.4,15.8c-0.8,0-1.4,0.7-2.4,2.2v6.4c0,0.1-0.3,0.1-0.5,0.1c-0.2,0-0.5,0-0.5-0.1v-9.5c0-0.1,0.3-0.1,0.5-0.1 c0.2,0,0.4,0,0.4,0.1V17c1.1-1.6,1.6-2.3,2.7-2.3H91c0.1,0,0.1,0.3,0.1,0.5c0,0.2,0,0.5-0.1,0.5L90.4,15.8L90.4,15.8z" />
                <path d="M144.3,15.8c-0.8,0-1.4,0.7-2.4,2.2v6.4c0,0.1-0.3,0.1-0.5,0.1s-0.5,0-0.5-0.1v-9.5c0-0.1,0.3-0.1,0.5-0.1s0.4,0,0.4,0.1 V17c1.1-1.6,1.6-2.3,2.7-2.3h0.4c0.1,0,0.1,0.3,0.1,0.5c0,0.2,0,0.5-0.1,0.5L144.3,15.8L144.3,15.8z" />
            </g>
            <g>
                <path d="M102.8,21v-3.1c0-2.2,1.3-3.4,3.5-3.4c1.2,0,2.1,0.4,2.9,1c0.2,0.2-0.2,0.9-0.4,0.8c-0.2-0.2-1.2-0.9-2.4-0.9 c-1.7,0-2.6,0.9-2.6,2.8v2.8c0,1.8,0.9,2.8,2.6,2.8c1.2,0,2-0.6,2.5-0.9c0.2-0.1,0.6,0.5,0.4,0.7c-0.6,0.6-1.7,1.1-3,1.1 C104.2,24.4,102.8,23.2,102.8,21z" />
                <path d="M116.6,24.3c-0.2,0-0.5,0-0.5-0.1v-1c-0.6,0.6-1.7,1.3-3,1.3c-1.8,0-3-1.1-3-2.8v-0.5c0-2.2,1.9-3.1,6-2.6v-0.8 c0-1.8-0.7-2.5-2.3-2.5c-0.8,0-1.8,0.2-2.6,0.6c-0.2,0.1-0.5-0.8-0.3-0.9c0.7-0.3,1.8-0.6,2.9-0.6c2.2,0,3.2,1.2,3.2,3.3v6.5 C117.1,24.3,116.8,24.3,116.6,24.3z M116.2,19.3c-3.6-0.5-5,0.2-5,1.8v0.4c0,1.3,1,2,2.2,2c1.2,0,2.2-0.6,2.9-1.3V19.3z" />
                <path d="M119.8,23v4.7c0,0.1-0.3,0.1-0.5,0.1s-0.5,0-0.5-0.1V14.7c0-0.1,0.3-0.1,0.5-0.1s0.5,0,0.5,0.1V16 c0.4-0.7,1.4-1.6,2.8-1.6c2,0,3.3,1.2,3.3,3.4V21c0,2.3-1.3,3.4-3.3,3.4C121.1,24.4,120.2,23.7,119.8,23z M119.8,17.7V21 c0,1.6,1.2,2.6,2.7,2.6c1.5,0,2.5-0.7,2.5-2.8V18c0-2-1.1-2.7-2.7-2.7C120.9,15.3,119.8,16.5,119.8,17.7z" />
                <path d="M133.1,14.6c0.2,0,0.5,0,0.5,0.1v6.7c0,1.4,0.8,2.1,1.8,2.1c1.7,0,3-2.1,3-2.1v-6.7c0-0.1,0.3-0.1,0.5-0.1 c0.2,0,0.5,0,0.5,0.1v9.5c0,0.1-0.3,0.1-0.5,0.1c-0.2,0-0.5,0-0.5-0.1v-1.7c-0.7,1.1-1.9,2-3.3,2c-1.5,0-2.5-1.1-2.5-2.8v-6.9 C132.6,14.6,132.9,14.6,133.1,14.6z" />
            </g>
        </g>
    </svg>
)

const TelenorMinSky: React.FunctionComponent<{
    height: number
    width: number
    color?: string
}> = (props) => (
    <svg
        version="1.1"
        x="0px"
        y="0px"
        width={props.width}
        height={props.height}
        viewBox="0 0 1752.7 460.5">
        <g fill={props.color || ''}>
            <polygon points="1109,372.8 1109,59.4 1117.5,59.4 1117.5,372.8 1109,372.8" />
            <path
                fill={props.color || '#15ABE3'}
                d="M251.8,140.9c2.4-1.8,8.7-6.5,9.6-7.1c1.5-10.4,5.2-27.8,13.9-45.6c9.3-19.2,24.2-40.4,45-54.5c17.4-11.8,46.2-24.8,68.4-29.6c18.2-3.9,35.2-4.7,49.8-3.3c30,2.7,46.7,11.3,55,22.4c3.1,4.2,4.8,9.3,4.9,12.6c0.2,5.5-2.3,12.7-10.1,19.8c-7.7,6.9-24.1,15.5-46.6,23c-23.3,7.8-55,16-86.7,23.3c-26.6,6.1-48.1,13.1-60.5,17.8c-3.7,1.4-6.1,2.5-9.4,4c-2,4.8-6.6,17.6-7.8,24.3c23.2,9.3,38.3,21.6,50,31.2c15.1,12.4,32.7,27,54.4,53.4c19.8,24.1,52,70.3,63.6,115.1c12.8,49.1,4.7,95.8-22.9,108.8c-27.2,12.7-63.4-5.7-88.8-32.1c-24.2-25.2-41.1-54.8-57-100.3c-13.7-39.3-19.3-96.2-19.3-125.9c0-9.9,0-15.8,0.4-24.8c-11.1-7.2-24.2-6.8-38.8,1.7c-26.8,15.5-54.6,48.9-69.4,66.3c-6.5,7.6-15.2,18.8-24.5,30.4c-12.2,15.4-25.7,31.4-38,40.4c-18.5,13.4-48.2,19.1-69,4.2c-11.6-8.3-17.7-24-17.9-39.9c-0.1-11.3,2.6-21.5,8.2-31.4c6.9-12.3,18.4-25.5,36.6-40.6c18.8-15.6,48.8-33.1,78.9-45.2C169.6,140.8,219,134.9,251.8,140.9L251.8,140.9z"
            />
            <g>
                <path d="M536.1,281.4c-15.6,0-21.4-9.3-21.4-21.8v-82.1h-15.8c-1.5,0-1.5-2.5-1.5-4.7c0-2.1,0-4.9,1.5-4.9h15.8V142c0-1.3,3.5-1.3,5.6-1.3c2.1,0,5.6,0,5.6,1.3v25.9h24.5c1.2,0,1.2,2.3,1.2,4.9c0,2.5,0,4.7-1.2,4.7h-24.5v81.7c0,8.9,3.7,12.3,12.9,12.3h11.4c1.5,0,1.9,0.4,1.9,4.7c0,4.7-0.4,5.3-1.9,5.3H536.1z" />
                <path d="M571.8,227.7v9.6c0,20.3,10.6,32.8,32,32.8c16.2,0,26-4.5,34.1-8.9c1.7-0.8,6.9,8.3,4.8,9.5c-10.6,6.1-23.7,9.7-38.5,9.7c-27.5,0-43.7-15.7-43.7-42.8v-33.1c0-25.4,17.9-41.9,41.4-41.9c25.4,0,42.6,16.5,42.6,44.7v20.3H571.8z M633.4,206.4c0-22.7-14.4-33.7-31.4-33.7c-16.4,0-30.2,11-30.2,33.1v11.8h61.6V206.4z" />
                <path d="M707.8,227.7v9.6c0,20.3,10.6,32.8,32,32.8c16.2,0,26-4.5,34.1-8.9c1.7-0.8,6.9,8.3,4.8,9.5c-10.6,6.1-23.7,9.7-38.5,9.7c-27.5,0-43.7-15.7-43.7-42.8v-33.1c0-25.4,17.9-41.9,41.4-41.9c25.4,0,42.6,16.5,42.6,44.7v20.3H707.8z M769.3,206.4c0-22.7-14.4-33.7-31.4-33.7c-16.4,0-30.2,11-30.2,33.1v11.8h61.6V206.4z" />
                <path d="M683,281c-15.4,0-21.4-9.3-21.4-21.8V126.8c0-1.3,3.5-1.3,5.6-1.3c2.1,0,5.6,0,5.6,1.3v131.9c0,8.9,0.7,12.5,9.9,12.5h5.6c1.5,0,1.9,0.4,1.9,4.7c0,4.7-0.4,5.1-1.9,5.1H683z" />
                <path d="M870.5,278.9c-2.1,0-5.6,0-5.6-1.3v-75.9c0-18.9-8.1-27.5-21.6-27.5c-14.3,0-25.8,12.5-33.9,24.6v78.9c0,1.3-3.5,1.3-5.6,1.3c-2.1,0-5.6,0-5.6-1.3V165.5c0-1.3,3.5-1.3,5.6-1.3c2.1,0,5.4,0,5.4,1.3V186c8.9-12.9,20.8-23.3,37.5-23.3c18.1,0,29.5,13.8,29.5,33.9v81C876.1,278.9,872.5,278.9,870.5,278.9z" />
                <path d="M894,240.1v-37.1c0-26.7,15.8-41.3,43.3-41.3c27.7,0,43.7,14.6,43.7,41.3v37.1c0,26.5-16,41.3-43.7,41.3C909.9,281.5,894,266.6,894,240.1z M937.3,172.1c-20,0-32,8.7-32,32.8v33.3c0,23.9,12.1,33.1,32,33.1c20.2,0,32.4-8.7,32.4-33.1V205C969.7,181,957.5,172.1,937.3,172.1z" />
                <path d="M1035.6,176.5c-9.2,0-16.8,8.5-28.3,26.3v74.9c0,1.3-3.5,1.3-5.6,1.3c-2.1,0-5.4,0-5.4-1.3V165.5c0-1.3,3.3-1.3,5.4-1.3c2.1,0,5.2,0,5.2,1.3v25.6c12.5-19.3,19.1-26.5,31.6-26.5h4.2c1.2,0,1.2,4,1.2,6.1c0,2.1-0.4,5.7-1.7,5.7H1035.6z" />
            </g>
            <g>
                <path d="M1253.3,277.9c0,1.3-3.6,1.3-5.7,1.3s-5.7,0-5.7-1.3v-79c0-16.1-8.4-24.9-19.3-24.9c-12.1,0-24.5,14.9-31,26.2v77.7c0,1.3-3.6,1.3-5.7,1.3s-5.7,0-5.7-1.3V165.2c0-1.3,3.4-1.3,5.4-1.3c2.1,0,5.7,0,5.7,1.3V187c7.3-11.9,19.3-24.5,33.9-24.5c16.1,0,24.5,13.4,26.4,24.5c8.6-13.2,20.7-24.5,34.3-24.5c17.2,0,28.7,12.1,28.7,32v83.4c0,1.3-3.6,1.3-5.7,1.3c-2.1,0-5.7,0-5.7-1.3v-79c0-16.8-7.1-24.9-18.8-24.9c-12.1,0-23.9,12.1-31.4,25.5V277.9z" />
                <path d="M1339.8,147.2v-16.5c0-1.3,3.6-1.3,5.7-1.3s5.7,0,5.7,1.3v16.5c0,1.3-3.6,1.3-5.7,1.3S1339.8,148.5,1339.8,147.2zM1339.8,277.9V165.2c0-1.3,3.6-1.3,5.7-1.3s5.7,0,5.7,1.3v112.7c0,1.3-3.6,1.3-5.7,1.3S1339.8,279.2,1339.8,277.9z" />
                <path d="M1450.8,279.2c-2.1,0-5.7,0-5.7-1.3v-76.9c0-18.6-8.2-27.2-21.8-27.2c-14.4,0-25.6,12.4-33.8,24.3v79.8c0,1.3-3.6,1.3-5.7,1.3s-5.7,0-5.7-1.3V165.2c0-1.3,3.6-1.3,5.7-1.3s5.4,0,5.4,1.3v20.3c9-12.8,20.6-23,37.3-23c18.2,0,29.7,13.6,29.7,33.5v81.9C1456.5,279.2,1452.9,279.2,1450.8,279.2z" />
                <path d="M1502.8,272.3c-1.7-1,3.4-10.7,5-9.6c7.3,4.2,17.8,8.2,27.4,8.2c15.9,0,25.6-10.5,25.6-22.2c0-32-55.9-22.6-55.9-58.4c0-15.9,12.8-27.6,34.3-27.6c12.1,0,22,2.7,26.8,4.8c1.9,1-1.9,10.3-3.1,9.8c-7.7-2.7-13.8-4.6-24.5-4.6c-14.5,0-22.2,8.4-22.2,17.8c0,24.7,55.9,18.6,55.9,58c0,16.3-13,32.5-36.9,32.5C1524.8,280.8,1510.6,276.9,1502.8,272.3z" />
                <path d="M1612.4,217l48.6,60.9c0.8,1.3-5.2,1.3-7.3,1.3c-2.1,0-6.1,0-6.9-1.3l-42.7-53.6l-6.7,7.1v46.5c0,1.3-3.8,1.3-5.9,1.3c-2.1,0-5.4,0-5.4-1.3V122.7c0-1.3,3.6-1.3,5.7-1.3s5.7,0,5.7,1.3v94.9l46.7-52.4c1-1.3,4.6-1.3,6.7-1.3s8.4,0,7.3,1.3L1612.4,217z" />
                <path d="M1698.7,319.2c-0.4,1.3-4,1.3-6.1,1.3s-6.5,0.2-6.1-1.3l15.1-42.1l-39.4-111.8c-0.4-1.3,4-1.3,6.1-1.3c2.1,0,5.9,0,6.3,1.3l33.1,97.8l32.5-97.8c0.4-1.3,4.4-1.3,6.5-1.3c2.1,0,6.5,0.2,6.1,1.3L1698.7,319.2z" />
            </g>
        </g>
    </svg>
)

export const TelenorLogoFlat: React.FunctionComponent<{
    height: number
    width: number
}> = (props: { height: number; width: number }) => (
    <svg
        x="0px"
        y="0px"
        width={props.width}
        height={props.height}
        viewBox="0 0 498.3 460.5">
        <path
            fill="#15ABE3"
            d="M251.8,140.9c2.4-1.8,8.7-6.5,9.6-7.1c1.5-10.4,5.2-27.8,13.9-45.6c9.3-19.2,24.2-40.4,45-54.5c17.4-11.8,46.2-24.8,68.4-29.6c18.2-3.9,35.2-4.7,49.8-3.3c30,2.7,46.7,11.3,55,22.4c3.1,4.2,4.8,9.3,4.9,12.6c0.2,5.5-2.3,12.7-10.1,19.8c-7.7,6.9-24.1,15.5-46.6,23c-23.3,7.8-55,16-86.7,23.3c-26.6,6.1-48.1,13.1-60.5,17.8c-3.7,1.4-6.1,2.5-9.4,4c-2,4.8-6.6,17.6-7.8,24.3c23.2,9.3,38.3,21.6,50,31.2c15.1,12.4,32.7,27,54.4,53.4c19.8,24.1,52,70.3,63.6,115.1c12.8,49.1,4.7,95.8-22.9,108.8c-27.2,12.7-63.4-5.7-88.8-32.1c-24.2-25.2-41.1-54.8-57-100.3c-13.7-39.3-19.3-96.2-19.3-125.9c0-9.9,0-15.8,0.4-24.8c-11.1-7.2-24.2-6.8-38.8,1.7c-26.8,15.5-54.6,48.9-69.4,66.3c-6.5,7.6-15.2,18.8-24.5,30.4c-12.2,15.4-25.7,31.4-38,40.4c-18.5,13.4-48.2,19.1-69,4.2c-11.6-8.3-17.7-24-17.9-39.9c-0.1-11.3,2.6-21.5,8.2-31.4c6.9-12.3,18.4-25.5,36.6-40.6c18.8-15.6,48.8-33.1,78.9-45.2C169.6,140.8,219,134.9,251.8,140.9L251.8,140.9z"
        />
    </svg>
)

export const TelenorCaptureLogo = isMinSky ? TelenorMinSky : TelenorCapture
export const TelenorCaptureLogoWhite: React.FunctionComponent<{
    height: number
    width: number
}> = (props: { height: number; width: number }) =>
    isMinSky ? (
        <TelenorMinSky
            height={props.height}
            width={props.width}
            color="white"
        />
    ) : (
        <TelenorCapture
            height={props.height}
            width={props.width}
            color="white"
        />
    )
