import styled from 'styled-components'
import { colors } from '~/assets/styleConstants'

type InfoExternalLink = {
    link: string
    text: string
}

export type InfoProps = {
    infoIcon: React.ReactNode
    infoTitle?: string
    extLink?: InfoExternalLink
    children?: React.ReactNode
}

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-self: center;
`

const Icon = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

const Title = styled.div`
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin: 31px auto 0;
    max-width: 480px;
    align-self: center;
`

const ExtLink = styled.a`
    display: block;
    font-size: 14px;
    color: ${colors.captureBlue};
    text-align: center;
    text-decoration: underline;
    margin-top: 45px;
`

export const InfoContainer: React.FunctionComponent<InfoProps> = (props) => {
    const externalLink = props.extLink && (
        <ExtLink className="external-link" href={props.extLink.link}>
            {props.extLink.text}
        </ExtLink>
    )

    return (
        <Content id="info-content">
            <Icon>{props.infoIcon}</Icon>
            {props.infoTitle && (
                <Title id="info-title">{props.infoTitle}</Title>
            )}
            {props.children}
            {externalLink}
        </Content>
    )
}
