import { PagePrimaryButton } from '@capture/capture-components'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { maybePaginatedDownloadJob } from '~/API/download'
import { _ } from '~/assets/localization/util'
import { getCurrentUserUUID } from '~/state/currentUser/selectors'

interface TimelineDownloadEntryProps {
    timelineCount: number
    timelineJobID: string
}

export const TimelineDownloadEntry = (props: TimelineDownloadEntryProps) => {
    const [isStartingDownload, setIsStartingDownload] = useState(false)
    const dispatch = useDispatch()
    const userID = useSelector(getCurrentUserUUID)

    const handleDownloadTimeline = async () => {
        if (!props.timelineJobID || !userID) {
            return
        }

        setIsStartingDownload(true)

        await maybePaginatedDownloadJob(dispatch, {
            type: 'takeout',
            jobID: props.timelineJobID,
            context: 'TakeoutTimeline',
        })

        setIsStartingDownload(false)
    }

    return (
        <PagePrimaryButton
            onPress={handleDownloadTimeline}
            isDisabled={props.timelineCount === 0 || isStartingDownload}>
            {_('takeout_download_button')}
        </PagePrimaryButton>
    )
}
