import type { EmptyObject, Middleware } from '@reduxjs/toolkit'
import { deleteAlbum } from '~/API/album'
import { fetchListOfJobs } from '~/API/job'
import { albumListReducerMapObj } from '../albumList/reducer'
import {
    getPendingAlbums,
    getRecentlyCompletedAlbumFromUploadedFileID,
    havePendingAutoCreatedAlbums,
} from '../albumList/selectors'
import { isType } from '../common/actions'
import { currentUserReducerMapObj } from '../currentUser/reducer'
import { getCurrentUserUUID } from '../currentUser/selectors'
import { FileUploadSucceeded, UploaderStopped } from '../uploader/actions'
import { uploaderReducerMapObj } from '../uploader/reducer'
import { getCurrentOrNextUploadingFile } from '../uploader/selectors'
import {
    AutoGeneratedAlbumCanceled,
    AutoGeneratedAlbumFinished,
} from './actions'

export const albumMiddlewareReducerMapObj = {
    ...albumListReducerMapObj,
    ...currentUserReducerMapObj,
    ...uploaderReducerMapObj,
}

type AlbumMiddlewareState = StateOfReducerMapObj<
    typeof albumMiddlewareReducerMapObj
>

export const albumMiddleware: Middleware<EmptyObject, AlbumMiddlewareState> =
    (store) => (next) => (action) => {
        const state = store.getState()
        if (havePendingAutoCreatedAlbums(state)) {
            if (isType(action, FileUploadSucceeded)) {
                const album = getRecentlyCompletedAlbumFromUploadedFileID(
                    state,
                    action.payload.fileID,
                )
                if (album) {
                    store.dispatch(AutoGeneratedAlbumFinished(album))
                    const currentUser = getCurrentUserUUID(state)
                    if (currentUser) {
                        fetchListOfJobs(store.dispatch, currentUser).catch()
                    }
                }
            }

            if (isType(action, UploaderStopped)) {
                const nextFile = getCurrentOrNextUploadingFile(state)
                const pendingAlbums = getPendingAlbums(state)

                if (nextFile) {
                    const album = pendingAlbums.filter(
                        (a) => a.tempID === nextFile.targetJob,
                    )[0]
                    if (album) {
                        store.dispatch(AutoGeneratedAlbumCanceled(album))
                        deleteAlbum(
                            store.dispatch,
                            album.jobID,
                            action.payload.navigate,
                        )
                    }
                }
            }
        }

        return next(action)
    }
