import { Prompt, Typography } from '@capture/capture-components'
import { _ } from '~/assets/localization/util'

interface DeletePromptProps {
    singular: boolean
    handleConfirm: () => void
}

export const DeletePrompt = ({
    singular,
    handleConfirm,
}: DeletePromptProps) => {
    return (
        <Prompt.Content title={_('album_trash__delete_album_title')}>
            <Typography.Paragraph>
                {singular
                    ? _('album_trash__delete_album_body')
                    : _('album_trash__delete_albums_body')}
            </Typography.Paragraph>
            <Prompt.ButtonsSection>
                <Prompt.ActionButton key="cancel" variant="secondary">
                    {_('cancel')}
                </Prompt.ActionButton>
                <Prompt.ActionButton
                    variant="destructive"
                    key="submit"
                    onPress={handleConfirm}>
                    {_('delete_')}
                </Prompt.ActionButton>
            </Prompt.ButtonsSection>
        </Prompt.Content>
    )
}
