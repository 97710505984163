import { colors } from '~/assets/styleConstants'
import { isMobileDevice } from '~/utilities/device'

export const WhiteHoverEffect = `
    position: relative;

    ${
        !isMobileDevice.any()
            ? `
        &:hover::before {
            content: " ";
            position: absolute;
            top: 0; bottom: 0; left: 0; right: 0;
            background-color: rgba(${colors.white_rgb}, 0.3);
        }
    `
            : ''
    }
`
