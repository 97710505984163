import styled from 'styled-components'
import SvgClose from '@capture/capture-components/src/icons/Close'
import { _ } from '~/assets/localization/util'
import { colors, mediaQueries } from '~/assets/styleConstants'
import { bytesToSize } from '~/utilities/fileSizeFormatting'
import type { ButtonProps, OverflowButtonProps } from '../Common/Button'
import { isOverflowButton, isRegularButton } from '../Common/Button'
import { IconButton, IconTextButton } from '../Common/IconTextButton'
import { ExpandableOverflowButton, OverflowMenu } from '../Common/OverflowMenu'
import { TopNavBar } from '../Navigation/TopNavBar'

// TODO: this feels pretty hacky, but harmless for now
import { EmptyIcon } from '../Icons/EmptyIcon'
import { ReadonlyTooltip } from '../Common/ReadonlyTooltip'

const SelectionToolBarWrapper = styled(TopNavBar)`
    display: flex;
    align-items: center;
    justify-content: space-between;

    animation: fadeIn 0.3s ease-in both;

    background-color: ${colors.captureBlue};
    padding: 8px 16px;
    box-sizing: border-box;

    color: ${colors.white};
    font-size: 14px;
`

const IconWrapper = styled.div`
    cursor: pointer;
`

const ButtonSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const LeftSection = styled(ButtonSection)`
    justify-content: flex-start;
    > div {
        display: flex;
        margin-right: 8px;
    }
    > div:first-child {
        margin-right: 16px;

        ${mediaQueries.mobile} {
            margin-right: 12px;
        }
    }
`

const RightSection = styled(ButtonSection)`
    justify-content: flex-end;
    gap: 20px;
`

type Props = {
    selectedFiles: FileID[]
    selectedFilesSize?: number
    cancelSelectMode: () => void
    buttons: Array<ButtonProps | OverflowButtonProps>
}

const getSelectedInfoString = (count: number) =>
    count === 0
        ? _('none_selected')
        : _('selected__format').replace('%d', count.toString())
const getSelectedFileSizeString = (filesSize: number | undefined) =>
    filesSize ? `(${bytesToSize(filesSize)})` : ''

export const SelectionToolbarMobile = (
    props: Props & { overflowOptions?: ButtonProps[] },
) => {
    const visibleButtons = props.buttons
        .flatMap((b) => (isRegularButton(b) ? b : b.buttons))
        .map((b, i) =>
            b.isDisabledForReadonly ? (
                <ReadonlyTooltip
                    key={`visibleButton${i}`}
                    renderElement={(isReadOnlyUser) => (
                        <IconButton
                            data-cy={b.cyKey}
                            onClick={b.onClick}
                            icon={b.icon || EmptyIcon}
                            color={colors.white}
                            hoverColor={colors.captureBlue900}
                            isDisabled={isReadOnlyUser}
                        />
                    )}
                />
            ) : (
                <IconButton
                    key={`visibleButton${i}`}
                    data-cy={b.cyKey}
                    onClick={b.onClick}
                    icon={b.icon || EmptyIcon}
                    color={colors.white}
                    hoverColor={colors.captureBlue900}
                    isDisabled={b.isDisabled}
                />
            ),
        )

    const rightSection = props.selectedFiles.length > 0 && (
        <RightSection>
            {visibleButtons}
            {props.overflowOptions && props.overflowOptions.length > 0 && (
                <OverflowMenu
                    menuOptions={props.overflowOptions}
                    iconColor={colors.white}
                />
            )}
        </RightSection>
    )

    return (
        <SelectionToolBarWrapper>
            <LeftSection>
                <IconWrapper
                    onClick={props.cancelSelectMode}
                    onKeyUp={props.cancelSelectMode}
                    role="button"
                    tabIndex={0}>
                    <SvgClose color={colors.white} />
                </IconWrapper>
                <div>
                    {props.selectedFiles.length === 0
                        ? _('none_selected')
                        : props.selectedFiles.length}
                </div>
                <div>{getSelectedFileSizeString(props.selectedFilesSize)}</div>
            </LeftSection>
            {rightSection}
        </SelectionToolBarWrapper>
    )
}

const DesktopButton = (btn: ButtonProps) => (
    <IconTextButton
        {...btn}
        data-cy={btn.cyKey}
        icon={btn.icon || EmptyIcon}
        color={btn.isDisabled ? colors.captureGrey400 : colors.white}
        hoverColor={btn.isDisabled ? undefined : colors.captureBlue900}
    />
)

const DesktopOverflowButton = ExpandableOverflowButton(DesktopButton, {
    v: 'bottom',
    h: 'right',
})

export const SelectionToolbarDesktop = (props: Props) => {
    const rightContent = props.buttons.map((btn, i) =>
        isOverflowButton(btn) ? (
            <DesktopOverflowButton key={`overflowButton${i}`} {...btn} />
        ) : btn.isDisabledForReadonly ? (
            <ReadonlyTooltip
                key={`overflowButton${i}`}
                renderElement={(isReadOnlyUser) => (
                    <DesktopButton {...btn} isDisabled={isReadOnlyUser} />
                )}
            />
        ) : (
            <DesktopButton key={`overflowButton${i}`} {...btn} />
        ),
    )

    return (
        <SelectionToolBarWrapper>
            <LeftSection>
                <IconButton
                    onClick={props.cancelSelectMode}
                    icon={SvgClose}
                    color={colors.white}
                    hoverColor={colors.captureBlue900}
                />
                <div>{getSelectedInfoString(props.selectedFiles.length)}</div>
                <div>{getSelectedFileSizeString(props.selectedFilesSize)}</div>
            </LeftSection>
            <RightSection>{rightContent}</RightSection>
        </SelectionToolBarWrapper>
    )
}
