import * as React from 'react'
import styled from 'styled-components'
import SvgList from '@capture/capture-components/src/icons/List'
import { fontSize } from '~/assets/styleConstants'

type Props = {
    mainText: string
    subText: string
    thumb: React.ReactElement<any>
    doExpand: () => any
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
`

const Thumbnail = styled.div`
    position: relative;
    width: 88px;
    height: 88px;
    margin-right: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
`

const UploadInfoBox = styled.div`
    height: 88px;
    flex: 1;
    display: flex;
`

const UploadTextWrapper = styled.div`
    flex: 1;
`
const MainText = styled.div`
    font-size: ${fontSize.medium_16};
    margin-bottom: 8px;
`
const SubText = styled.div`
    font-size: ${fontSize.xSmall_10};
`
const ExpandIconWrapper = styled.div`
    cursor: pointer;
    height: 24px;
    display: flex;
`

export class UploadStatusBoxContent extends React.Component<Props> {
    public render() {
        return (
            <Wrapper>
                <Thumbnail>{this.props.thumb}</Thumbnail>
                <UploadInfoBox>
                    <UploadTextWrapper>
                        <MainText>{this.props.mainText}</MainText>
                        {this.props.subText && (
                            <SubText>{this.props.subText}</SubText>
                        )}
                    </UploadTextWrapper>
                    <ExpandIconWrapper
                        onClick={this.props.doExpand}
                        onKeyUp={this.props.doExpand}
                        role="button"
                        tabIndex={0}>
                        <SvgList />
                    </ExpandIconWrapper>
                </UploadInfoBox>
            </Wrapper>
        )
    }
}
