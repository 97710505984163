import type { RefObject } from 'react'
import { useEffect } from 'react'

type ClickOutsideEventHandler = (event: MouseEvent) => void
type Options = { handleRightClick?: boolean }

// roughly from https://usehooks.com/useOnClickOutside/

export const useClickOutside = <T extends HTMLElement = HTMLElement>(
    ref: RefObject<T>,
    callback: ClickOutsideEventHandler,
    options?: Options,
) => {
    useEffect(() => {
        function handleClick(event: MouseEvent) {
            const element = ref?.current

            // Do nothing if clicking ref's element or descendent element
            if (!element || element.contains(event.target as Node)) {
                return
            }

            callback(event)
        }

        document.addEventListener('click', handleClick, true)
        if (options?.handleRightClick) {
            document.addEventListener('contextmenu', handleClick, true)
        }
        return () => {
            document.removeEventListener('click', handleClick, true)
            if (options?.handleRightClick) {
                document.removeEventListener('contextmenu', handleClick, true)
            }
        }
    }, [ref, callback, options?.handleRightClick])
}

export default useClickOutside
