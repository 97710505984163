import { getRange } from '~/utilities/arrayUtils'

// TODO: Use native lib.d.ts types WebKitFileEntry/WebKitDirectoryEntry
// when issue #18139 is resolved
// https://github.com/Microsoft/TypeScript/issues/18139
type FileCallback = (file: File) => void
type EntriesCallback = (entries: ItemEntry[]) => void
type ErrorCallback = (error: DOMException) => void
interface ItemFileEntry extends FileSystemEntry {
    file(successCallback: FileCallback, errorCallback?: ErrorCallback): void
}
interface ItemDirectoryReader {
    readEntries(
        successCallback: EntriesCallback,
        errorCallback?: ErrorCallback,
    ): void
}
interface ItemDirectoryEntry extends FileSystemEntry {
    createReader(): ItemDirectoryReader
}
type ItemEntry = ItemFileEntry | ItemDirectoryEntry | FileSystemEntry | null
const isFileEntry = (e: ItemEntry): e is ItemFileEntry =>
    e !== null && e.isFile && e.name !== '.DS_Store'
const isDirectoryEntry = (e: ItemEntry): e is ItemDirectoryEntry =>
    e !== null && e.isDirectory

export type Directory = {
    name: string
    files: File[]
    children: Directory[]
}

const getAllDirectoryEntries = (
    directory: ItemDirectoryEntry,
): Promise<ItemEntry[]> =>
    new Promise((resolve) => {
        const itemEntries: ItemEntry[] = []
        const dirReader = directory.createReader()
        // readEntries only read first 100 files at the time, must call it recursively until empty
        const getEntries = () => {
            dirReader.readEntries((entries: ItemEntry[]) => {
                if (entries.length === 0) {
                    resolve(itemEntries)
                } else {
                    itemEntries.push(...entries)
                    getEntries()
                }
            })
        }
        getEntries()
    })

const transformEntriesToContainer = async (
    entries: ItemEntry[],
    name: string,
): Promise<Directory> => {
    const files: Array<Promise<File>> = entries.filter(isFileEntry).map((f) => {
        return new Promise((ok) => f.file(ok))
    })
    const children: Array<Promise<Directory>> = entries
        .filter(isDirectoryEntry)
        .map((d) => {
            return getAllDirectoryEntries(d).then((es) =>
                transformEntriesToContainer(es, d.name),
            )
        })

    return {
        name,
        files: await Promise.all(files),
        children: await Promise.all(children),
    }
}

export const getDirectoryStructFromDataTransfer = (
    dataTransferItems: DataTransferItemList,
): Promise<Directory> => {
    const entries = getRange(dataTransferItems.length).map((i) =>
        dataTransferItems[i].webkitGetAsEntry(),
    )
    return transformEntriesToContainer(entries, '')
}
