import * as React from 'react'
import styled from 'styled-components'
import type { PageDescription } from '~/utilities/navigation'
import { fontSize } from '~/assets/styleConstants'
import { SlideInBurgerMenu } from './SlideInBurgerMenu'
import { TwoAreasTopNavBar } from './TwoAreasTopNavBar'

const ElementWrapper = styled.div`
    margin-left: 24px;
    display: inline-flex;
    align-self: center;
`
const PageTitle = styled(ElementWrapper)`
    font-size: ${fontSize.medium_16};
`

type Props = {
    rightElements?: () => React.ReactNode[]
    currentPage?: PageDescription
}

export class TopNavBarMobile extends React.Component<Props, unknown> {
    public render(): JSX.Element {
        let pageSpecificElements = null
        if (this.props.rightElements) {
            pageSpecificElements = this.props
                .rightElements()
                .map((el, i) => (
                    <ElementWrapper key={`TopNavBarRightElement${i}`}>
                        {el}
                    </ElementWrapper>
                ))
        }
        const leftElements = [
            <SlideInBurgerMenu
                key={'menu_mobile'}
                currentPage={this.props.currentPage}
            />,
            <PageTitle key={'title'}>
                {this.props.currentPage?.pageTitle}
            </PageTitle>,
        ]

        return (
            <TwoAreasTopNavBar
                left={leftElements}
                right={pageSpecificElements}
                hideUserAvi={true}
            />
        )
    }
}
