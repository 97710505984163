import { Suspense, lazy } from 'react'
import { isMinSky } from '~/config/constants'

// lazy load logos, we will likely ever need one of these varian
const CaptureLogoVerticalCapture = lazy(
    () => import('./CaptureLogoVerticalCapture'),
)
const CaptureLogoVerticalMinSky = lazy(
    () => import('./CaptureLogoVerticalMinSky'),
)

// This component is a wrapper around lazy loaded svgs
export const CaptureLogoVertical = (props: { size: number }) => {
    return (
        <Suspense fallback={null}>
            {isMinSky ? (
                <CaptureLogoVerticalMinSky size={props.size} />
            ) : (
                <CaptureLogoVerticalCapture size={props.size} />
            )}
        </Suspense>
    )
}
