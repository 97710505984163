export enum FileTarget {
    Pictures = 'Pictures',
    Documents = 'Documents',
    Movies = 'Movies',
}

export const imageFileExtensions = [
    'sr2',
    'j6i',
    'x3f',
    'gif',
    'cr2',
    'png',
    'tiff',
    'mos',
    'eip',
    'webp',
    'rwz',
    'heic',
    'mef',
    'mrw',
    'kdc',
    'dng',
    'mfw',
    'tif',
    'orf',
    'pef',
    'iiq',
    'jpg',
    'ari',
    'crw',
    'bay',
    'jpeg',
    'dcr',
    'fff',
    'erf',
    'heif',
    'psd',
    'srf',
    'nef',
    'srw',
    'rwl',
    'k25',
    'bmp',
    'raw',
    'nrw',
    '3fr',
    'raf',
    'rw2',
    'arw',
]

export const videoFileExtensions = [
    'mpg',
    'mts',
    'mp2v',
    'vob',
    'ogm',
    'avi',
    'qt',
    '3gp',
    'mpeg',
    'mov',
    'm4v',
    'dv',
    'mkv',
    'flv',
    '4gp',
    'rmvb',
    'wmv',
    'avci',
    'mov__',
    'asf',
    'webm',
    'rm',
    'm1s',
    'mp4',
    'm2ts',
]

/**
 * Creates string with accepted extensions in a way readable to the File spec
 * @param imageFileExtensions
 * @param videoFileExtensions
 * @returns string
 */
const makeAcceptFieldFromExtensionsList = (
    imageFileExtensions: string[],
    videoFileExtensions: string[],
) => {
    let accept = ''

    imageFileExtensions.forEach((element) => {
        accept += `.${element},`
    })

    videoFileExtensions.forEach((element) => {
        accept += `.${element},`
    })

    return accept
}

export const acceptedExtensions = makeAcceptFieldFromExtensionsList(
    imageFileExtensions,
    videoFileExtensions,
)

/**
 * Whether string contains any of the extensions in given array of extensions
 * @param fileName
 * @param extensions
 * @returns boolean
 */
const matchInArray = (fileName: string, extensions: string[]): boolean => {
    const sections = fileName.split('.')
    const extension = sections.at(-1)
    if (!extension) return false
    return extensions.includes(extension)
}

// TODO: extensions from backend, Refs CAPWEB-2727
export const getFileTargetFromName = (fileName: string) => {
    const lowerCaseFileName = fileName.toLocaleLowerCase()
    // check fileName in edge cases where we only get parital JobFile
    if (
        lowerCaseFileName &&
        matchInArray(lowerCaseFileName, imageFileExtensions)
    ) {
        return FileTarget.Pictures
    }
    if (
        lowerCaseFileName &&
        matchInArray(lowerCaseFileName, videoFileExtensions)
    ) {
        return FileTarget.Movies
    }
    return FileTarget.Documents
}

export const getFileExtension = (fileName: string) => {
    const match = fileName.match(/\.([^.]+)$/)

    return match ? match[1] : undefined
}

export const isHEICFile = (fileName: string) => /\.heic$/i.test(fileName)

export const getFileExtensionUpperCase = (fileName: string) => {
    return isHEICFile(fileName)
        ? 'HEIC'
        : getFileExtension(fileName)?.toUpperCase()
}
