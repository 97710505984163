import type {
    StripePaymentInfo,
    StripeProductsResponse,
    UserGrant,
} from '~/@types/backend-types'
import { createActionCreator, createVoidActionCreator } from '../common/actions'

export const RefusedSubscription = createVoidActionCreator(
    'REFUSED_SUBSCRIPTION',
)

export const PurchaseStarted = createVoidActionCreator('PURCHASE_STARTED')
export const PurchaseSucceeded = createVoidActionCreator('PURCHASE_SUCCESSFUL')
export const PurchaseFailed = createVoidActionCreator('PURCHASE_FAILED')

export const PlanChangeStarted = createVoidActionCreator('PLAN_CHANGE_STARTED')
export const PlanChangeSucceeded = createActionCreator<{ newPlanID: string }>(
    'PLAN_CHANGE_SUCCEEDED',
)
export const PlanChangeFailed = createVoidActionCreator('PLAN_CHANGE_FAILED')

export const PaymentInfoFetched = createActionCreator<StripePaymentInfo | null>(
    'PAYMENT_INFO_FETCHED',
)
export const CreditCardUpdated = createVoidActionCreator('CREDIT_CARD_UPDATED')
export const CreditCardUpdateFailed = createVoidActionCreator(
    'CREDIT_CARD_UPDATE_FAILED',
)
export const CreditCardDeleted = createVoidActionCreator('CREDIT_CARD_DELETED')
export const CreditCardDeleteFailed = createVoidActionCreator(
    'CREDIT_CARD_DELETE_FAILED',
)

export const StorageProductsFetched =
    createActionCreator<StripeProductsResponse>('STORAGE_PRODUCTS_FETCHED')
export const UserStoragePlanFetched = createActionCreator<UserGrant[]>(
    'USER_STORAGE_PLAN_FETCHED',
)

export const PlanCanceled = createVoidActionCreator('PLAN_CANCELED')
export const PlanCancelFailed = createVoidActionCreator('PLAN_CANCEL_FAILED')

export const PlanDowngradeClicked = createVoidActionCreator(
    'PLAN_DOWNGRADE_CLICKED',
)

export const PlanReactivated = createVoidActionCreator('PLAN_REACTIVATED')
export const PlanReactivationFailed = createVoidActionCreator(
    'PLAN_REACTIVATION_FAILED',
)
