import { useParams } from 'react-router-dom'
import { _, getLinkParts } from '~/assets/localization/util'
import { SUPPORT_SITE_ADDRESS_FOR_ACCOUNT_TERMINATED } from '~/config/constants'
import { PageWrapper } from '../Common/PageWrapper'
import { CaptureIcon } from '../Icons/CaptureLogo'
import { CaptureLogoHorizontal } from '../Icons/CaptureLogoHorizontal'
import { TwoAreasTopNavBar } from '../Navigation/TwoAreasTopNavBar'
import { InfoContainer } from './InfoContainer'

const ErrorPageContent: React.FC<{
    title: string
    children?: React.ReactNode
}> = ({ title, children }) => (
    <PageWrapper
        navBar={
            <TwoAreasTopNavBar
                left={<CaptureLogoHorizontal />}
                hideUserAvi={true}
            />
        }>
        <InfoContainer infoIcon={<CaptureIcon size={78} />} infoTitle={title}>
            {children}
        </InfoContainer>
    </PageWrapper>
)

export const ErrorPageMount: React.FC = () => {
    const { error } = useParams<{ error: string }>()
    if (error === 'user_disabled') {
        const [beforeLink, linkText, afterLink] = getLinkParts(
            _('account_terminated_desc__format'),
        )
        return (
            <ErrorPageContent title={_('account_terminated_error')}>
                <p>
                    {beforeLink}
                    <a href={SUPPORT_SITE_ADDRESS_FOR_ACCOUNT_TERMINATED}>
                        {linkText}
                    </a>
                    {afterLink}
                </p>
            </ErrorPageContent>
        )
    }
    return <ErrorPageContent title={_('something_went_wrong')} />
}
