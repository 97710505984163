import type { SVGProps } from 'react'
const SvgNextPhoto = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill={props.color ?? 'currentColor'}
            d="m13.35 12-3.9-3.9a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l4.6 4.6c.1.1.17.208.213.325.041.117.062.242.062.375s-.02.258-.062.375a.876.876 0 0 1-.213.325l-4.6 4.6a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7z"
        />
    </svg>
)
export default SvgNextPhoto
