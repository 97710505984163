import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import type { Dispatch } from '~/state/common/actions'
import { _ } from '~/assets/localization/util'
import { deleteComment, editComment } from '~/API/album'
import { zIndex } from '~/assets/styleConstants'
import { AlbumFileComments } from '~/routing/pages'
import type { AlbumFileComment } from '~/state/album/selectors'
import {
    DeleteCommentCanceled,
    DeleteCommentClicked,
    EditCommentCanceled,
    EditCommentClicked,
} from '~/state/files/actions'
import { isMobileMode } from '~/state/viewMode/selectors'
import type { WithRouterProps } from '~/utilities/navigation'
import { withRouter } from '~/utilities/navigation'
import type { ButtonProps } from '../Common/Button'
import { Button } from '../Common/Button'
import { ConfirmPromptComponent } from '../Common/DialoguePromptComponent'
import { RippleLoaderOverlay } from '../Common/RippleLoaderOverlay'
import { EditCommentComponent } from './EditCommentComponent'
import { SingleComment } from './SingleComment'

const CommentWrapper = styled.div`
    position: ${(props: { shouldBeRelative: boolean }) =>
        props.shouldBeRelative ? 'relative' : 'auto'};
`

const Wrapper = styled.div`
    width: 90%;
    max-width: 100%;
    position: absolute;
    ${(props: { isCloseToBottom: boolean }) =>
        props.isCloseToBottom ? 'bottom: 8px' : 'top: 100%'};
    left: 50%;
    transform: translateY(-12px) translateX(-50%);
    z-index: ${zIndex.menu};
`

const getActionsForComment = (
    dispatch: Dispatch,
    ownProps: OwnProps,
): ButtonProps[] => {
    const actions = []
    if (ownProps.currentUserCan.editComment) {
        actions.push(
            Button(_('edit'), () =>
                dispatch(EditCommentClicked({ commentID: ownProps.commentID })),
            ),
        )
    }
    if (ownProps.currentUserCan.deleteComment) {
        actions.push(
            Button(_('delete_'), () =>
                dispatch(
                    DeleteCommentClicked({ commentID: ownProps.commentID }),
                ),
            ),
        )
    }
    return actions
}
type OwnProps = AlbumFileComment & {
    now: Date
    truncateLongComments: boolean
}

type StateProps = {
    isMobileMode: boolean
}
type DispatchProps = {
    commentActions: ButtonProps[]
    deleteComment: () => void
    cancelDeleteComment: () => void
    cancelEditComment: () => void
    submitEditedComment: (text: string) => void
}

type Props = OwnProps & StateProps & DispatchProps & WithRouterProps

type ComponentState = { promptIsCloseToBottom: boolean }

class _SingleCommentContainer extends React.Component<Props, ComponentState> {
    private commentWrapper = React.createRef<HTMLDivElement>()

    public state: ComponentState = { promptIsCloseToBottom: false }

    public componentDidUpdate(prevProps: Props) {
        if (
            this.props.awaitsDecisionToBeDeleted !==
            prevProps.awaitsDecisionToBeDeleted
        ) {
            this.checkIfCommentisCloseToBottom()
        }
    }

    private checkIfCommentisCloseToBottom = () => {
        if (!this.commentWrapper.current) {
            return
        }

        const totalDistance =
            window.innerHeight -
            this.commentWrapper.current.getBoundingClientRect().bottom
        if (this.props.isMobileMode) {
            this.setState({ promptIsCloseToBottom: totalDistance < 200 })
        } else {
            const distanceToTopOfList: number =
                this.commentWrapper.current.offsetTop +
                this.commentWrapper.current.offsetHeight -
                this.commentWrapper.current.offsetParent!.scrollTop
            const distanceToBottomOfList =
                this.commentWrapper.current.offsetParent!.clientHeight -
                distanceToTopOfList

            this.setState({
                promptIsCloseToBottom:
                    Math.min(totalDistance, distanceToBottomOfList) < 100,
            })
        }
    }

    public render() {
        let deletePrompt = null

        if (
            this.props.awaitsDecisionToBeDeleted ||
            this.props.isPendingServerDelete
        ) {
            deletePrompt = (
                <Wrapper isCloseToBottom={this.state.promptIsCloseToBottom}>
                    <ConfirmPromptComponent
                        onConfirm={this.props.deleteComment}
                        onCancel={this.props.cancelDeleteComment}
                        confirmText={_('delete_')}>
                        {_('delete_comment_prompt_text')}
                    </ConfirmPromptComponent>
                    {this.props.isPendingServerDelete && (
                        <RippleLoaderOverlay loaderSize={48} />
                    )}
                </Wrapper>
            )
        }
        let commentElement = null
        if (this.props.isEditing || this.props.isPendingServerEdit) {
            commentElement = (
                <EditCommentComponent
                    isSubmitting={this.props.isPendingServerEdit}
                    text={this.props.text}
                    doCancel={this.props.cancelEditComment}
                    doSubmit={this.props.submitEditedComment}
                />
            )
        } else {
            commentElement = (
                <SingleComment
                    {...this.props}
                    onShowFullCommentClick={
                        this.props.truncateLongComments
                            ? () =>
                                  this.props.navigate(
                                      AlbumFileComments(
                                          this.props.albumID,
                                          this.props.fileID,
                                      ).url,
                                  )
                            : undefined
                    }
                />
            )
        }

        return (
            <CommentWrapper
                data-cy="commentsList"
                ref={this.commentWrapper}
                shouldBeRelative={
                    this.props.awaitsDecisionToBeDeleted ||
                    this.props.isPendingServerDelete
                }>
                {commentElement}
                {deletePrompt}
            </CommentWrapper>
        )
    }
}

const mapStateToProps = (
    state: StateOfSelector<typeof isMobileMode>,
): StateProps => ({
    isMobileMode: isMobileMode(state),
})

const mapDispatchToProps = (
    dispatch: Dispatch,
    ownProps: OwnProps,
): DispatchProps => ({
    commentActions: getActionsForComment(dispatch, ownProps),
    cancelDeleteComment: () =>
        dispatch(DeleteCommentCanceled({ commentID: ownProps.commentID })),
    deleteComment: () =>
        deleteComment(
            dispatch,
            ownProps.albumID,
            ownProps.fileID,
            ownProps.commentID,
        ),
    cancelEditComment: () =>
        dispatch(EditCommentCanceled({ commentID: ownProps.commentID })),
    submitEditedComment: (text: string) =>
        editComment(
            dispatch,
            ownProps.albumID,
            ownProps.fileID,
            ownProps.commentID,
            text,
        ),
})

const ConnectedSingleCommentContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(_SingleCommentContainer)

export const SingleCommentContainer = withRouter(
    ConnectedSingleCommentContainer,
)
