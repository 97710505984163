import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { _ } from '~/assets/localization/util'
import { trackEvent } from '~/analytics/eventTracking'
import { colors, fontSize } from '~/assets/styleConstants'
import { withoutTheBools } from '~/utilities/arrayUtils'
import { isMobileDevice } from '~/utilities/device'
import { preventDefault } from '~/utilities/eventHandling'
import type { PageDescription } from '~/utilities/navigation'
import { shareToEmail, shareToSMS } from '~/utilities/share'
import { setCursorType } from '~/state/cursor/cursorSlice'
import { ShareToComponent } from '../Share/ShareToComponent'
import {
    GetLinkButton,
    ShareByMailButton,
    ShareBySMSButton,
} from '../Share/ShareToOptionButtons'

export const CopyLinkOutlinedBtn = styled.a`
    height: 28px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid ${colors.captureBlue};
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    padding: 3px;

    font-size: ${fontSize.small_14};
    font-weight: bold;
    color: ${colors.captureBlue};
    text-transform: uppercase;
    text-align: center;

    &:active {
        border: 2px solid ${colors.captureBlue};
        padding: 2px;
        background-color: ${colors.captureBlue50};
    }
`

type Props = {
    sharePage: PageDescription
    doClose: () => void
    onLinkCopied: () => void
}

export const ShareURLToOptions: React.FunctionComponent<Props> = (props) => {
    const [showLink, setShowLink] = useState<boolean>(false)
    const dispatch = useDispatch()
    const copy = async (text: string) => {
        try {
            dispatch(setCursorType('wait'))
            await navigator.clipboard.writeText(text)
            dispatch(setCursorType('auto'))
        } catch (error) {
            console.error(error)
            dispatch(setCursorType('auto'))
        }
    }

    const getSharedURL = (): URLstring => location.origin + props.sharePage.url
    const onShareByEmail = () => {
        trackEvent('ShareAlbum', 'shareAlbumClicked', 'sharedByEmail')
        shareToEmail(`${getSharedURL()}#se`)
        props.doClose()
    }
    const onShareBySMS = () => {
        trackEvent('ShareAlbum', 'shareAlbumClicked', 'sharedBySMS')
        shareToSMS(`${getSharedURL()}#sms`)
        props.doClose()
    }
    const onGetLink = async () => {
        trackEvent('ShareAlbum', 'shareAlbumClicked', 'linkCopied')

        if (isMobileDevice.any()) {
            setShowLink(true)
        } else {
            await copy(`${getSharedURL()}#sc`)
            props.onLinkCopied()
            props.doClose()
        }
    }

    const isMobile = isMobileDevice.any()

    const shareOptions = withoutTheBools([
        isMobile && <ShareBySMSButton key="shareSMS" onClick={onShareBySMS} />,
        <ShareByMailButton key="shareMail" onClick={onShareByEmail} />,
        <GetLinkButton key="getLink" onClick={onGetLink} />,
    ])

    const copyLinkButton = showLink && (
        <CopyLinkOutlinedBtn //localhost:9000/a/Yw3S1qN5To6HAG5Xs15U2g#sc
            href={`${getSharedURL()}#sc`}
            onClick={preventDefault}>
            {_('hold_to_copy_link')}
        </CopyLinkOutlinedBtn>
    )

    return (
        <ShareToComponent
            shareOptions={shareOptions}
            bottomElement={copyLinkButton}
            isMobile={isMobile}
        />
    )
}
