import { getElementIndex } from './arrayUtils'

export enum Direction {
    UP = 'UP',
    DOWN = 'DOWN',
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
}

/**
 * A layout of 11 images, organised on rows with 4 images with would have indexes:
 *  0  1  2  3
 *  4  5  6  7
 *  8  9 10
 *
 * getNextGridIndex returns index of neighbour if it exist,
 * or undefined if navigating out of grid.
 *
 * Example:
 * With currentIndex 5 and direction LEFT, 4 is returned
 * With currentIndex 3 and direction RIGHT, undefined is returned
 * With currentIndex 6 or 7 and direction DOWN, 10 is returned
 */
export const getNextGridIndex = (
    totalLength: number,
    rowLength: number,
    currentIndex: number,
    direction: Direction,
): number | undefined => {
    switch (direction) {
        case Direction.RIGHT: {
            const nextRight = currentIndex + 1
            return nextRight % rowLength === 0 || nextRight > totalLength - 1
                ? undefined
                : nextRight
        }
        case Direction.LEFT:
            return currentIndex % rowLength === 0 ? undefined : currentIndex - 1
        case Direction.UP: {
            const nextUp = currentIndex - rowLength
            return nextUp < 0 ? undefined : nextUp
        }
        case Direction.DOWN: {
            const nextDown = currentIndex + rowLength
            const totalFilledLength =
                rowLength * Math.ceil(totalLength / rowLength)
            if (nextDown < totalFilledLength) {
                return Math.min(nextDown, totalLength - 1)
            }
            return undefined
        }
    }
}

export type GroupDescription = { key: string; itemCount: number }
type SelectedGroupItem = { group: string; index: number }
const makeSureInGroup = (
    item: SelectedGroupItem,
    group: GroupDescription,
): SelectedGroupItem => {
    if (item.index < group.itemCount) {
        return item
    }
    return { group: item.group, index: group.itemCount - 1 }
}

/**
 * getNextItemInVerticalListOfGrids returns the next item within the timeline segment if it exist.
 * If navigating out of the current group (UP/DOWN) it returns items in the above/below group if available.
 * If there is no next group, undefined is returned.
 *
 * Example:
 * If group_1 has 8 photos
 *      0  1  2  3
 *      4  5  6  7
 * and group_2 has 3 photos
 *      0  1  2
 * navigating DOWN from index 7 in group_1 will return index 2 in group_2
 */
export const getNextItemInVerticalListOfGrids = (
    groups: GroupDescription[],
    rowLength: number,
    currentItem: SelectedGroupItem,
    direction: Direction,
): SelectedGroupItem | undefined => {
    const currentGroupIndex = getElementIndex(
        groups,
        (g) => g.key === currentItem.group,
    )
    const currentGroup = groups[currentGroupIndex]
    const nextIndex = getNextGridIndex(
        currentGroup.itemCount,
        rowLength,
        currentItem.index,
        direction,
    )
    if (nextIndex !== undefined) {
        return { group: currentGroup.key, index: nextIndex }
    }

    switch (direction) {
        case Direction.DOWN: {
            const groupBelow = groups[currentGroupIndex + 1]
            if (groupBelow === undefined) {
                return undefined
            }
            return makeSureInGroup(
                { group: groupBelow.key, index: currentItem.index % rowLength },
                groupBelow,
            )
        }
        case Direction.UP: {
            const groupAbove = groups[currentGroupIndex - 1]
            if (groupAbove === undefined) {
                return undefined
            }
            const rowsAbove = Math.ceil(groupAbove.itemCount / rowLength)
            return makeSureInGroup(
                {
                    group: groupAbove.key,
                    index:
                        (rowsAbove - 1) * rowLength +
                        (currentItem.index % rowLength),
                },
                groupAbove,
            )
        }
    }
    return undefined
}

/**
 * When navigating RIGHT/LEFT in a horizontal list the next item is returned if it exist, undefined if not.
 * Navigating UP/DOWN will return undefined as this is navigating out of the segment
 */
export const getNextItemInHorizontalList = <TKey>(
    keys: TKey[],
    currentActive: TKey,
    direction: Direction,
) => {
    const currentIndex = getElementIndex(keys, (k) => k === currentActive)
    const nextIndex = getNextGridIndex(
        keys.length,
        keys.length,
        currentIndex,
        direction,
    )
    if (nextIndex !== undefined) {
        return keys[nextIndex]
    }
    return undefined
}

/**
 * When navigating UP/DOWN in a vertical list the next item is returned if it exist, undefined if not.
 * Navigating RIGHT/LEFT will return undefined as this is navigating out of the segment
 */
export const getNextItemInVerticalList = <TKey>(
    keys: TKey[],
    currentActive: TKey,
    direction: Direction,
) => {
    if (direction === Direction.LEFT || direction === Direction.RIGHT) {
        return undefined
    }
    const currentIndex = getElementIndex(keys, (k) => k === currentActive)
    const nextIndex = getNextGridIndex(keys.length, 1, currentIndex, direction)
    if (nextIndex !== undefined) {
        return keys[nextIndex]
    }
}

export const directionFromKeyEvent = (
    event: KeyboardEvent,
): Direction | undefined => {
    switch (event.keyCode) {
        case 37: // ArrowLeft
            return Direction.LEFT
        case 38: // ArrowUp
            return Direction.UP
        case 39: // ArrowRight
            return Direction.RIGHT
        case 40: // ArrowDown
            return Direction.DOWN
        default:
            return undefined
    }
}
