import { getElementIndex } from '~/utilities/arrayUtils'
import type {
    StateWithStatusNotifications,
    StatusNotification,
} from './reducer'

export const isPendingStatus = (msg: StatusNotification) => {
    return (
        msg.type === 'filesAreBeingDeleted' ||
        msg.type === 'filesAreBeingRestored' ||
        msg.type === 'filesAreBeingCopied' ||
        msg.type === 'preparingDownload'
    )
}

export const getStatusNotifications = (
    state: StateWithStatusNotifications,
): StatusNotification[] => {
    return state.statusNotifications.messages.filter((m, i, self) => {
        return (
            !isPendingStatus(m) ||
            getElementIndex(self, (e) => e.type === m.type) === i
        )
    })
}
