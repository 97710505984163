import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ChangedCurrentCarouselFile } from '~/state/carouselViewer/actions'
import { getVideoPlayStatus } from '~/state/carouselViewer/pureSelectors'
import {
    getCurrentViewerNode,
    getPlaybackOrder,
    shouldCarouselAutoNavigate,
} from '~/state/carouselViewer/selectors'

export const CarouselAutoNavigator: React.FC = () => {
    const [viewerNode, shouldAutoNavigate, videoPlayStatus, playbackOrder] = [
        useSelector(getCurrentViewerNode),
        useSelector(shouldCarouselAutoNavigate),
        useSelector(getVideoPlayStatus),
        useSelector(getPlaybackOrder),
    ]
    const dispatch = useDispatch()
    const videoPlayStatusTracker = useRef(videoPlayStatus)
    useEffect(() => {
        const didJustStop =
            videoPlayStatus === 'finished' &&
            videoPlayStatusTracker.current === 'playing'
        videoPlayStatusTracker.current = videoPlayStatus

        if (shouldAutoNavigate && viewerNode) {
            const theTimer = window.setTimeout(
                () => {
                    dispatch(
                        ChangedCurrentCarouselFile(
                            playbackOrder === 'backward'
                                ? viewerNode.prev
                                    ? viewerNode.prev
                                    : viewerNode.last
                                : viewerNode.next
                                  ? viewerNode.next
                                  : viewerNode.first,
                        ),
                    )
                },
                didJustStop ? 1 : 5000,
            )
            return () => window.clearTimeout(theTimer)
        }
    }, [
        viewerNode,
        videoPlayStatus,
        shouldAutoNavigate,
        playbackOrder,
        dispatch,
    ])

    return null
}
