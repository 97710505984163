import * as React from 'react'

import { connect } from 'react-redux'
import { getDownloaderModalState } from '~/state/downloader/selectors'
import type { ModalStatus } from '~/state/downloader/reducer'
import { unsetContextMenuTarget } from '~/state/contextMenu/actions'
import type { Dispatch } from '~/state/common/actions'
import MaybePaginatedDownloadModal from '~/components/Downloader/MaybePaginatedDownloadModal'
import { PaginatedDownloadModal } from '~/components/Downloader/PaginatedDownloadModal'
import type { CaptureFile } from '~/state/files/selectors'
import { getCaptureFilesByID } from '~/state/files/selectors'
import { Pages } from '~/routing'
import { isLoggedIn } from '~/state/currentUser/selectors'
import { FilesOptionsClosed } from '~/state/FilesOptions/actions'
import type { FilesOptions } from '~/state/FilesOptions/reducer'
import { getFilesOptionsStatus } from '~/state/FilesOptions/selector'
import { isMobileMode } from '~/state/viewMode/selectors'
import type { WithRouterProps } from '~/utilities/navigation'
import { withRouter } from '~/utilities/navigation'
import { AddToAlbum } from './AddToAlbum'
import { ShareFilesOptions } from './ShareFilesOptions'

// TODO: Ensure consistency wherever this type is used (CAPWEB-2892)
export type FileOptionContext =
    | 'CarouselView'
    | 'TimelinePage'
    | 'TimelineSelection'
    | 'AlbumPage'
    | 'AlbumListPage'
    | 'AlbumReceiverSelection'
    | 'TakeoutAlbum'
    | 'TakeoutTimeline'
    | 'DocumentsPage'
    | 'TrashPage'
    | 'Unknown'

type OwnProps = {
    files: FileID[] // not needed for download_huge_selection, pass []
    jobID: JobID
    context: FileOptionContext
    onActionCompleted?: () => void
} & WithRouterProps

type StateProps = {
    options?: FilesOptions
    isLoggedIn: boolean
    isMobile: boolean
    selectedFiles: CaptureFile[]
    downloaderModal: ModalStatus
}
type DispatchProps = {
    dismissOverlay: () => void
    confirmOption: () => void
    confirmCreateAlbum: (albumID: JobID) => void
}

type Props = StateProps & DispatchProps & OwnProps

class FilesOptionsPlacementComponent extends React.Component<Props> {
    public componentWillUnmount() {
        if (this.props.options) {
            // close prompt if user navigates away
            this.props.dismissOverlay()
        }
    }

    public render() {
        if (this.props.downloaderModal === 'max') {
            return <PaginatedDownloadModal />
        }
        if (!this.props.options) {
            return null
        }
        switch (this.props.options.type) {
            case 'add_to_album':
                return this.props.selectedFiles.length !== 0 ? (
                    <AddToAlbum
                        files={this.props.selectedFiles}
                        onConfirmNew={this.props.confirmCreateAlbum}
                        onConfirmExisting={this.props.confirmOption}
                        cancel={this.props.dismissOverlay}
                        context={this.props.context}
                        jobID={this.props.jobID}
                    />
                ) : null
            case 'share_to_album':
                return (
                    <AddToAlbum
                        jobID={this.props.jobID}
                        files={this.props.selectedFiles}
                        onConfirmNew={this.props.confirmCreateAlbum}
                        onConfirmExisting={this.props.confirmOption}
                        cancel={this.props.dismissOverlay}
                        filter={'shared'}
                        context={this.props.context}
                    />
                )
            case 'share':
                return (
                    <ShareFilesOptions
                        isLoggedIn={this.props.isLoggedIn}
                        isMobile={this.props.isMobile}
                        files={this.props.selectedFiles}
                        onCancel={this.props.dismissOverlay}
                        onConfirm={this.props.confirmOption}
                        context={this.props.context}
                    />
                )
            case 'download_huge_selection':
                if (this.props.downloaderModal === 'off') {
                    return (
                        <MaybePaginatedDownloadModal
                            downloadType={this.props.options.downloadType}
                            jobID={this.props.options.jobID}
                            fileInfos={this.props.options.fileInfos}
                            size={this.props.options.size}
                            context={this.props.context}
                            zipFileName={this.props.options.zipFileName}
                            onCancel={this.props.dismissOverlay}
                        />
                    )
                }
                break
            default:
                return null
        }
    }
}

type FilesOptionsClosedStoreState = StateOfSelector<
    typeof getFilesOptionsStatus
> &
    StateOfSelector<typeof getDownloaderModalState> &
    StateOfSelector<typeof isLoggedIn> &
    StateOfSelector<typeof isMobileMode> &
    StateOfSelector<typeof getCaptureFilesByID>

const mapStateToProps = (
    state: FilesOptionsClosedStoreState,
    ownProps: OwnProps,
): StateProps => ({
    options: getFilesOptionsStatus(state),
    downloaderModal: getDownloaderModalState(state),
    isLoggedIn: isLoggedIn(state),
    isMobile: isMobileMode(state),
    selectedFiles: getCaptureFilesByID(state, ownProps.jobID, ownProps.files),
})

const mapDispatchToProps = (
    dispatch: Dispatch,
    ownProps: OwnProps,
): DispatchProps => {
    const dismissOverlay = () => {
        dispatch(FilesOptionsClosed())
        // Unsetting the file target for the contextMenu set in the GroupListItem
        dispatch(unsetContextMenuTarget())
    }
    const confirmOption = () => {
        dismissOverlay()
        if (ownProps.onActionCompleted) {
            ownProps.onActionCompleted()
        }
    }

    return {
        dismissOverlay,
        confirmOption,
        confirmCreateAlbum: (albumID: JobID) => {
            confirmOption()
            ownProps.navigate(Pages.EditAlbum(albumID, 't').url)
        },
    }
}

export const FilesOptionsPlacement = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(FilesOptionsPlacementComponent),
)
