import { createSelector } from 'reselect'
import type { CommentsState, FileComment, StateWithComments } from './reducer'

const rootSelector = (state: StateWithComments): CommentsState => state.comments

export const getCommentsGroupedByImage = createSelector(
    rootSelector,
    (comments: CommentsState): Record<FileID, FileComment[]> => {
        const result: Record<FileID, FileComment[]> = {}
        Object.keys(comments).forEach((commentID: CommentID) => {
            const comment = comments[commentID]
            result[comment.fileID] = [
                ...(result[comment.fileID] || []),
                comment,
            ].sort(
                (commentA, commentB) => commentA.timestamp - commentB.timestamp,
            )
        })
        return result
    },
)
