import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import type { Dispatch } from '~/state/common/actions'
import { _ } from '~/assets/localization/util'
import { postComment } from '~/API/album'
import { colors, fontSize } from '~/assets/styleConstants'
import { getCurrentUserInfo } from '~/state/currentUser/selectors'
import { CommentStatus } from '~/state/files/reducer'
import { getCommentStatus } from '~/state/files/selectors'
import { isMobileDevice } from '~/utilities/device'
import type { User } from '~/state/users/reducer'
import { Avatar } from '../Common/Avatar'

type FormProps = { isEmpty: boolean; hasFocus: boolean; maxHeight: number }
const CommentForm = styled.form`
    margin: 8px;
    display: flex;
    border: 1px solid
        ${(props: FormProps) =>
            props.hasFocus ? colors.captureBlue : colors.captureGrey400};
    background-color: white;
    position: relative;
    flex: 1;
    font-size: ${fontSize.medium_16};
    padding: 8px;

    &:hover {
        border: 1px solid
            ${(props) =>
                props.hasFocus ? colors.captureBlue : colors.captureGrey500};
    }

    & > textarea {
        height: ${(props) => (props.isEmpty ? '40px!important' : 'auto')};
        max-height: ${(props) => props.maxHeight}px;
        flex: 1;
        overflow-y: auto;
        border: none;
        resize: none;
        outline: none;
        box-sizing: border-box;
        padding-right: 48px;
        padding-left: 5px;
        font-size: ${fontSize.medium_16};
    }
`

const PublishBtn = styled.input`
    background: ${(props: { color: string; visibility: string }) =>
        props.color};
    visibility: ${(props: { color: string; visibility: string }) =>
        props.visibility};
    border-radius: 4px;
    border: 1px solid ${(props) => props.color};
    color: white;
    min-width: 48px;
    height: 30px;
    margin: 4px;
    font-size: ${fontSize.small_12};
    text-transform: uppercase;
    -webkit-appearance: none;
    position: absolute;
    right: 0;
    bottom: 0;
`
const StatusElement = styled.div`
    margin-bottom: 12px;
`
const ErrorMessage = styled.span`
    color: ${colors.captureMagenta};
    font-size: 11px;
    padding: 4px 16px;
`

type OwnProps = {
    albumID: JobID
    fileID: FileID
    maxHeight: number
    onHeightChange?: () => void
}

type StateProps = {
    commentStatus: CommentStatus
    currentUserInfo?: User
}

type DispatchProps = {
    handledCommentSubmit: (commentText: string) => Promise<boolean>
}

type Props = OwnProps & StateProps & DispatchProps

type ComponentState = { comment: string; hasFocus: boolean }

class _CommentInputComponent extends React.Component<Props, ComponentState> {
    public state: ComponentState = { comment: '', hasFocus: false }
    private textAreaElement: HTMLTextAreaElement | null = null

    private lastKnownHeight = -1
    private handleTextChange = (
        evt: React.SyntheticEvent<HTMLTextAreaElement>,
    ) => {
        let callback: (() => void) | undefined
        if (
            this.textAreaElement &&
            this.textAreaElement.scrollHeight !== this.lastKnownHeight
        ) {
            callback =
                this.lastKnownHeight !== -1
                    ? this.props.onHeightChange
                    : undefined
            this.lastKnownHeight = this.textAreaElement.scrollHeight
        }

        if (this.state.comment !== evt.currentTarget.value) {
            this.setState(
                { ...this.state, comment: evt.currentTarget.value },
                callback,
            )
        }

        if (this.textAreaElement) {
            this.textAreaElement.style.height = '38px'
            this.textAreaElement.style.height = `${this.textAreaElement.scrollHeight}px`
        }
    }

    private handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (this.state.comment) {
            if (await this.props.handledCommentSubmit(this.state.comment)) {
                this.setState({ ...this.state, comment: '' })
            }
        }
        return false
    }
    private handleFocus = () => {
        this.setState({ ...this.state, hasFocus: true })
    }
    private handleBlur = () => {
        this.setState({ ...this.state, hasFocus: false })
    }
    private bindTextAreaElement = (me: HTMLTextAreaElement | null) => {
        this.textAreaElement = me
    }
    private handleInputKeyDown = async (
        evt: React.KeyboardEvent<HTMLTextAreaElement>,
    ) => {
        if (
            evt.keyCode === 13 &&
            !evt.shiftKey &&
            this.state.comment &&
            !isMobileDevice.any()
        ) {
            if (await this.props.handledCommentSubmit(this.state.comment)) {
                this.setState({ ...this.state, comment: '' })
            }
        }
    }

    public render() {
        let errorMessage = null
        if (this.props.commentStatus === CommentStatus.ERROR) {
            errorMessage = (
                <StatusElement>
                    <ErrorMessage>{_('comment_error_message')}</ErrorMessage>
                </StatusElement>
            )
        }

        let textColor = colors.captureGrey800
        let btnColor = colors.captureBlue
        if (this.props.commentStatus === CommentStatus.PENDING) {
            textColor = colors.captureGrey400
            btnColor = colors.captureGrey400
        }

        const publishBtn = (
            <PublishBtn
                data-cy="album__publishBtn"
                className="publish-btn"
                visibility={
                    this.state.comment.length !== 0 ? 'visible' : 'hidden'
                }
                type="submit"
                value={_('post')}
                disabled={this.props.commentStatus === CommentStatus.PENDING}
                color={btnColor}
            />
        )

        const avatarArea = this.props.currentUserInfo && (
            <Avatar
                size={24}
                name={this.props.currentUserInfo.name}
                img={this.props.currentUserInfo.profilePicture}
            />
        )
        return (
            <div className="comment-input">
                {errorMessage}
                <CommentForm
                    className="comment-form"
                    onSubmit={this.handleSubmit}
                    isEmpty={this.state.comment.length === 0}
                    hasFocus={this.state.hasFocus}
                    maxHeight={this.props.maxHeight}>
                    {avatarArea}
                    <textarea
                        ref={this.bindTextAreaElement}
                        className="comment-area"
                        value={this.state.comment}
                        placeholder={_('write_your_comment')}
                        onChange={this.handleTextChange}
                        readOnly={
                            this.props.commentStatus === CommentStatus.PENDING
                        }
                        style={{ color: textColor }}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        onKeyDown={this.handleInputKeyDown}
                    />
                    {publishBtn}
                </CommentForm>
            </div>
        )
    }
}

const mapStateToProps = (
    state: StateOfSelector<typeof getCommentStatus> &
        StateOfSelector<typeof getCurrentUserInfo>,
    ownProps: OwnProps,
): StateProps => ({
    commentStatus: getCommentStatus(state, ownProps.fileID),
    currentUserInfo: getCurrentUserInfo(state),
})
const mapDispatchToProps = (
    dispatch: Dispatch,
    ownProps: OwnProps,
): DispatchProps => ({
    handledCommentSubmit: (commentText: string) => {
        return postComment(
            dispatch,
            ownProps.albumID,
            ownProps.fileID,
            commentText,
        )
            .then(() => true) // sucessfully posted comment
            .catch(() => false) // comment was not posted properly
    },
})

export const CommentInputComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(_CommentInputComponent)
