import type { ServiceDict } from '~/API/externals'
import type { Action } from '../common/actions'
import { isType } from '../common/actions'
import { FetchedHostDirectory } from './actions'

export type HostsState = ServiceDict

const initialState: HostsState = {
    appHost: 'N/A',
    thumbHost: 'N/A',
    videoHost: 'N/A',
    pollHost: 'N/A',
    downloadHost: 'N/A',
}

export const hostsReducer = (
    state: HostsState = initialState,
    action: Action,
): HostsState => {
    if (isType(action, FetchedHostDirectory)) {
        return Object.assign({}, state, { ...action.payload.hosts })
    }

    return state
}

export const hostsReducerMapObj = {
    hosts: hostsReducer,
}

export type StateWithHosts = StateOfReducerMapObj<typeof hostsReducerMapObj>
