import * as Sentry from '@sentry/react'
import * as React from 'react'
import { ErrorMessage } from '@capture/capture-components'
import { _ } from '~/assets/localization/util'
import { trackEvent } from '~/analytics/eventTracking'

type Props = {
    fallbackUI?: React.ReactNode
    children?: React.ReactNode
}

export class ErrorBoundary extends React.Component<
    Props,
    { hasError: boolean }
> {
    public state = { hasError: false }
    public static getDerivedStateFromError(_error: any) {
        return { hasError: true }
    }
    public componentDidCatch(error: any, _errorInfo: any) {
        Sentry.captureException(error, {
            level: 'error',
            tags: { captureScope: 'app_level' },
        })
        trackEvent(
            'Error',
            'AppErrorBoundaryInvoked',
            error.message || 'unspecified error',
        )
    }
    public render() {
        if (this.state.hasError) {
            return (
                this.props.fallbackUI || (
                    <ErrorMessage>{_('something_went_wrong')}</ErrorMessage>
                )
            )
        }

        return this.props.children
    }
}
