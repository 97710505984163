import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
    PageCard,
    PageNeutralButton,
    Typography,
} from '@capture/capture-components'
import SvgAlbumPage from '@capture/capture-components/src/icons/AlbumPage'
import SvgArrowBack from '@capture/capture-components/src/icons/ArrowBack'
import SvgPhotosAndVideos from '@capture/capture-components/src/icons/PhotosAndVideos'
import { Pages, RoutePath } from '../../routing'
import { PageWrapper } from '../Common/PageWrapper'
import { _ } from '../../assets/localization/util'
import { fontSize } from '../../assets/styleConstants'
import { TwoAreasTopNavBar } from '../Navigation/TwoAreasTopNavBar'
import { IconTextButton } from '../Common/IconTextButton'

const Content = styled.section`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

type TrashNavigationPageProps = {
    inAppMode?: boolean
}

export const TrashNavigationPage = ({
    inAppMode = false,
}: TrashNavigationPageProps) => {
    const navigate = useNavigate()

    return (
        <PageWrapper
            hideFooter={inAppMode}
            navBar={
                !inAppMode && (
                    <TwoAreasTopNavBar
                        left={
                            <IconTextButton
                                onClick={() => navigate(Pages.Settings.url)}
                                text={_('settings')}
                                icon={SvgArrowBack}
                                fontSize={fontSize.small_14}
                            />
                        }
                    />
                )
            }>
            <PageCard>
                <Typography.SectionHeader>
                    {_('recently_deleted')}
                </Typography.SectionHeader>
                <Typography.Divider />
                <Content>
                    <PageNeutralButton
                        onPress={() =>
                            navigate(
                                inAppMode
                                    ? RoutePath.getInAppTrashPhotosPath({
                                          fromNav: true,
                                      })
                                    : RoutePath.TrashPhotos,
                            )
                        }>
                        <SvgPhotosAndVideos />
                        {_('photos_and_videos')}
                    </PageNeutralButton>
                    <PageNeutralButton
                        onPress={() =>
                            navigate(
                                inAppMode
                                    ? RoutePath.getInAppTrashAlbumsPath({
                                          fromNav: true,
                                      })
                                    : RoutePath.TrashAlbums,
                            )
                        }>
                        <SvgAlbumPage />
                        {_('albums')}
                    </PageNeutralButton>
                </Content>
            </PageCard>
        </PageWrapper>
    )
}
