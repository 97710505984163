/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { CAPBAKSupportedExtensionsResponse } from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class SupportedExtensions<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags files
     * @name SupportedExtensionsGet
     * @summary Get Supported Extensions
     * @request GET:/supported_extensions
     */
    supportedExtensionsGet = (params: RequestParams = {}) =>
        this.request<CAPBAKSupportedExtensionsResponse, any>({
            path: `/supported_extensions`,
            method: 'GET',
            format: 'json',
            ...params,
        })
}
