import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import type { Dispatch } from '~/state/common/actions'
import { Pages } from '~/routing/index'
import { doLogin } from '~/API/login'
import {
    getCurrentUserInfo,
    isLoggedIn,
    isLoggingIn,
} from '~/state/currentUser/selectors'
import type { PageDescription } from '~/utilities/navigation'
import { goBack } from '~/utilities/navigation'
import { LoadingPage } from '../Common/LoadingPage'
import { LoginRequiredPage } from './LoginRequiredPage'

type Props = {
    text: string
    targetAfterLogin?: PageDescription
    afterLoginAction?: (dispatch: Dispatch, ...args: any[]) => void
    loginUtmSource?: string
    showCreateAccount?: boolean
}
export const RequireLoginActionPage: React.FC<Props> = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const isLogged = useSelector(isLoggedIn)
    const isLogging = useSelector(isLoggingIn)
    const userName = useSelector(getCurrentUserInfo)?.name

    const handleLoginOccurred = (...args: any[]) => {
        props.afterLoginAction?.(dispatch, ...args)
        props.targetAfterLogin &&
            navigate(props.targetAfterLogin?.url ?? Pages.Timeline.url, {
                replace: true,
            })
    }

    const login = () => doLogin(props.loginUtmSource)

    useEffect(() => {
        if (isLogged) {
            handleLoginOccurred(userName)
        }
    }, [isLogged])

    if (!isLogging && !isLogged) {
        return (
            <LoginRequiredPage
                text={props.text}
                onSignIn={login}
                onRegister={login}
                goBack={goBack}
                showCreateAccount={props.showCreateAccount}
            />
        )
    }
    return <LoadingPage />
}
