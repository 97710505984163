export const isEmail = (value: string): boolean => {
    // Basic W3C email input validation
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#Validation
    // tslint:disable-next-line:max-line-length
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i.test(
        value,
    )
}

export const hasTextValue = (value: string) => value.trim() !== ''

export const hasOnlyNumbers = (value: string, length?: number) => {
    const s = value.trim()
    return /^\d+$/.test(s) && (length === undefined || s.length === length)
}
export const hasOnlyNumbersMinLength = (value: string, minLength: number) => {
    const s = value.replace(/\s/g, '')
    return /^\d+$/.test(s) && s.length >= minLength
}

export const isPhoneNumber = (value: string) =>
    hasOnlyNumbersMinLength(value, 8)

export const makeWhitelist =
    <T>(validValues: T[]) =>
    (valToCheck: unknown): T | undefined => {
        return validValues.filter((e) => e === valToCheck)[0]
    }

export const hasAutofilledElementsOnPage = (): boolean => {
    try {
        const autofilled = document.querySelectorAll('input:-webkit-autofill')
        return autofilled.length > 0
    } catch (e) {
        // The above querySelector throws on on firefox (where it would not match anyway)
        return false
    }
}
