import styled from 'styled-components'
import { fontSize } from '~/assets/styleConstants'
import { FullscreenOverlay } from '../Common/FullscreenOverlay'
import type { PopupProps } from './PopupHorizontalLayout'
import { getIllustrationFromSource } from './PopupHorizontalLayout'

const Wrapper = styled.div`
    width: 100%;
    max-width: 480px;
    max-height: 100%;
    overflow: auto;

    margin: 8px;
    background-color: white;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 3px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    padding-bottom: 32px;
`

const Header = styled.div`
    font-size: ${fontSize.large_24};
    font-weight: bold;
    margin: 8px 0;
`
const Text = styled.div`
    font-size: ${fontSize.medium_18};
    margin: 24px 0;
`
const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
`
const ButtonDiv = styled.div`
    width: 240px;
    font-size: 16px;
`

export const PopupVerticalLayout: React.FunctionComponent<PopupProps> = (
    props,
) => {
    const buttons = props.buttons.map((button: React.ReactNode, i) => (
        <ButtonDiv key={`popupButton${i}`}>{button}</ButtonDiv>
    ))

    return (
        <FullscreenOverlay>
            <Wrapper>
                {getIllustrationFromSource(props.illustration)}
                <Header>{props.headerText}</Header>
                {props.mainText && <Text>{props.mainText}</Text>}
                <ButtonWrapper>{buttons}</ButtonWrapper>
            </Wrapper>
        </FullscreenOverlay>
    )
}
