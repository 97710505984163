import styled from 'styled-components'
import SvgInfinity from '@capture/capture-components/src/icons/Infinite'
import { colors } from '~/assets/styleConstants'
import { CenteredElementWrapper } from './LayoutComponents'

const StorageBar = styled.div<{ backgroundColor?: string; height?: number }>`
    height: ${(props) => props.height || 32}px;
    width: 100%;
    border-radius: 2px;
    background-color: ${(props) =>
        props.backgroundColor || colors.captureGrey200};
    overflow: hidden;
    margin: 0 auto;
`

const UsedStorage = styled.div<{ usedRatio: number; usedQuotaColor: string }>`
    width: 100%;
    height: 100%;
    width: ${(props) => props.usedRatio * 100}%;
    min-width: ${(props) => (props.usedRatio > 0 ? 8 : 0)}px;
    transition: transform 0.3s ease-out;
    background-color: ${(props) => props.usedQuotaColor};
`

type BarProps = {
    usedRatio: number
    backgroundColor?: string
    usedQuotaColor?: string
    warningColor?: string
    barHeight?: number
    warningThreshold?: number
}

export const QuotaBar: React.FunctionComponent<BarProps> = (props) => {
    const warningThreshold = props.warningThreshold || 0.95
    const usedQuotaColor = props.usedQuotaColor || colors.captureGrey800
    const warningColor =
        props.warningColor || `rgba(${colors.warningRed_rgb}, 0.25)`

    return (
        <StorageBar
            backgroundColor={props.backgroundColor}
            height={props.barHeight}>
            <UsedStorage
                usedRatio={props.usedRatio}
                usedQuotaColor={
                    props.usedRatio > warningThreshold
                        ? warningColor
                        : usedQuotaColor
                }
            />
        </StorageBar>
    )
}

const InfinityIconWrapper = styled(CenteredElementWrapper)`
    svg {
        display: block;
    }
`

export const InfiniteQuotaBar: React.FunctionComponent<{
    infinitySignColor?: string
    barColor?: string
}> = (props) => (
    <div style={{ position: 'relative' }}>
        <QuotaBar
            usedRatio={1}
            warningThreshold={1}
            usedQuotaColor={props.barColor || colors.captureBlue50}
        />
        <InfinityIconWrapper>
            <SvgInfinity
                color={props.infinitySignColor}
                width={96}
                height={96}
            />
        </InfinityIconWrapper>
    </div>
)
