import styled from 'styled-components'
import type { ConfirmProps } from './DialoguePromptComponent'
import {
    ConfirmPromptComponent,
    LoggedOutPromptComponent,
    OkPromptComponent,
} from './DialoguePromptComponent'
import { FullscreenOverlay } from './FullscreenOverlay'
import { KeyEventProvider } from './KeyEventProvider'

const Wrapper = styled.div`
    padding: 8px;
    box-sizing: border-box;
    white-space: pre-wrap;
`
function wrapInFullscreenOverlay<T extends object>(
    Inner: React.FunctionComponent<T>,
    shouldStackOnTop?: boolean,
) {
    return function FullScreenOverlayComp(props: T) {
        return (
            <FullscreenOverlay shouldStackOnTop={shouldStackOnTop}>
                <Wrapper>
                    <Inner {...props} />
                </Wrapper>
            </FullscreenOverlay>
        )
    }
}

const withKeyboardActions = <T extends ConfirmProps>(
    Inner: React.FunctionComponent<T>,
): React.FunctionComponent<T> =>
    function KeyboardardActionComp(props) {
        return (
            <KeyEventProvider
                methods={{ Enter: props.onConfirm, Escape: props.onCancel }}>
                <Inner {...props} />
            </KeyEventProvider>
        )
    }

export const ConfirmPromptOverlay = wrapInFullscreenOverlay(
    withKeyboardActions(ConfirmPromptComponent),
)
export const OkPromptOverlay = wrapInFullscreenOverlay(OkPromptComponent)
export const LoggedOutPromptOverlay = wrapInFullscreenOverlay(
    LoggedOutPromptComponent,
    false,
)
