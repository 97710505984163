import { createSelector, type Selector } from 'reselect'
import type {
    ScrollerGroup,
    GroupSelectionStatus,
} from '~/components/TimelineScroller/FastScrollerContent'
import {
    makeTimelineScrollerGroups,
    getGroupSelectionStatus,
} from '~/components/TimelineScroller/makeScrollerGroups'
import {
    getElementIndex,
    getRange,
    groupArray,
    inArray,
    withoutTheUndefined,
    cachedInArray,
} from '~/utilities/arrayUtils'
import {
    Day_asString,
    compareMonth,
    getFileDay,
    getFileTime,
    isFileRecent,
    makeMonthGroupHeaderByFile,
} from '~/utilities/dateOperations'
import { RecordUtil } from '~/utilities/dictionaryUtils'
import {
    FileTarget,
    getFileExtension,
    isHEICFile,
} from '~/utilities/fileTarget'
import type { GridStyle } from '~/utilities/gridElementSizeCalculator'
import { calcImagesPerRow } from '~/utilities/gridElementSizeCalculator'
import type { ImageGroupStyle } from '~/utilities/imageGroupStyle'
import { computeImageGroupHeight } from '~/utilities/imageGroupStyle'
import {
    getCastDetails,
    getCurrentViewInfo,
} from '../carouselViewer/pureSelectors'
import type { StateWithFiles } from '../files/reducer'
import type {
    BasicViewFile,
    CaptureFile,
    FileWithGrouping,
} from '../files/selectors'
import {
    getCaptureFilesForJob,
    getDocumentFilesForJob,
    getFileType,
} from '../files/selectors'
import { getLastSeenElementForJob } from '../lastSeenElement/selectors'
import {
    makeTimelineFiles,
    makeTimelineGroups,
} from '../recentFiles/recentFilesProcessing'
import { getRecentFiles, getRecentFilesCounts } from '../recentFiles/selectors'
import {
    getSelectedFileIDs,
    getSelectedFileSizeforJob,
    getSelectedFilesForJob,
} from '../selectedFiles/selectors'
import type { StateWithRecentFiles } from '../recentFiles/recentFilesSlice'
import { getImageGroupStyle } from '../viewMode/selectors'
import type { StateWithViewMode } from '../viewMode/reducer'
import type { StateWithLastSeenElement } from '../lastSeenElement/reducer'
import type {
    MediaFilterMode,
    StateWithTimeline,
    TimelineFilterMode,
    TimelineMonthCountDetail,
    TimelineMonthSection,
} from './reducers'
import { MonthFetchStatus } from './reducers'
import type { TimelineSectionReference } from './index'
import {
    TimelineSectionReference__allDays,
    TimelineSectionReference__asString,
    TimelineSectionReference__compare,
} from './index'

export type TimelineMonthWithGroupedCounts = {
    month: number
    year: number
    count: number
    countGrouped: TimelineMonthCountDetail
    fetchStatus: MonthFetchStatus
}

export const getTimelineJobID = (state: StateWithTimeline) =>
    state.timeline.timelineJob

export const getTimelineInitialLastChangeEventID = (state: StateWithTimeline) =>
    state.timeline.initialLastChange

export const getTimelineFilterMode = (
    state: StateWithTimeline,
): TimelineFilterMode => state.timeline.filter

export const getIsRecentFilterActive = (state: StateWithTimeline): boolean =>
    state.timeline.filter === 'recents_1' ||
    state.timeline.filter === 'recents_7' ||
    state.timeline.filter === 'recents_30'

export const getSelectedTimelineFiles = (
    state: StateWithTimeline & StateOfSelector<typeof getSelectedFilesForJob>,
) =>
    state.timeline.timelineJob
        ? getSelectedFilesForJob(state, state.timeline.timelineJob)
        : []

export const getSelectedTimelineFileIDs = createSelector(
    getSelectedTimelineFiles,
    (files): FileID[] => files.map(({ fileID }) => fileID),
)

export const getSelectedDocumentFiles = createSelector(
    getSelectedTimelineFiles,
    (files): FileWithGrouping[] =>
        files.filter((f) => getFileType(f) === FileTarget.Documents),
)

export const getSelectedDocumentFileIDs = createSelector(
    getSelectedDocumentFiles,
    (files): FileID[] => files.map((f) => f.fileID),
)

export const getSelectedDocumentsFileSize = createSelector(
    getSelectedDocumentFiles,
    (files): number => files.reduce((acc, { size }) => acc + size, 0),
)

export const haveSelectedHEICFile = createSelector(
    getSelectedTimelineFiles,
    (files): boolean => files.some((f) => isHEICFile(f.path)),
)

export const getSelectedFilesExtensions = createSelector(
    getSelectedTimelineFiles,
    (files): Array<string | undefined> =>
        files.map((f) => getFileExtension(f.path)),
)

export const getSelectedTimelineFilesSize = (
    state: StateWithTimeline & StateOfSelector<typeof getSelectedFilesForJob>,
): number =>
    state.timeline.timelineJob
        ? getSelectedFileSizeforJob(state, state.timeline.timelineJob)
        : 0

const getTimelineSelectMode = (state: StateWithTimeline) =>
    state.timeline.isSelectMode
export const isTimelineInSelectMode = createSelector(
    getTimelineSelectMode,
    getSelectedTimelineFiles,
    (forcedSelectMode, selectedFiles): boolean =>
        forcedSelectMode || selectedFiles.length > 0,
)

export type TimelineSection = TimelineMonthSection // TODO: Make local instance (and maybe transform) from reducer value

type TimelineFilesSelectorState = StateOfSelector<typeof getTimelineJobID> &
    StateOfSelector<typeof getCaptureFilesForJob>

export const getTimelineFiles = (state: TimelineFilesSelectorState) => {
    const jobID = getTimelineJobID(state)
    return jobID !== undefined ? getCaptureFilesForJob(state, jobID) : []
}
const getTimelineFilesBySection = createSelector(
    (state: StateWithTimeline) => state.timeline.sections,
    getTimelineFiles,
    (sections, files): DictionaryOf<Array<FileWithGrouping<CaptureFile>>> => {
        if (sections === undefined) {
            return {}
        }
        const dayToSectionMap: Record<string, string> = {}
        sections.forEach((s) => {
            TimelineSectionReference__allDays(s).forEach((day) => {
                dayToSectionMap[Day_asString(day)] =
                    TimelineSectionReference__asString(s)
            })
        })
        return groupArray(
            files,
            (f) => dayToSectionMap[Day_asString(getFileDay(f))],
        )
    },
)

export const getTimelineSections = createSelector(
    (state: StateWithTimeline) => state.timeline.sections,
    getTimelineFilesBySection,
    (sections, fileGroups): TimelineSection[] => {
        return (sections || [])
            .map((s) => {
                if (s.fetchStatus === MonthFetchStatus.FETCHED) {
                    return {
                        ...s,
                        counts: calculateGroupCount(
                            fileGroups[TimelineSectionReference__asString(s)] ||
                                [],
                        ),
                    }
                }
                return s
            })
            .sort(TimelineSectionReference__compare)
    },
)

export const containsUnloadedSections = (sections: TimelineSection[]) =>
    sections.some((s) => s.fetchStatus !== MonthFetchStatus.FETCHED)

const sectionLengthByFilter: Record<
    MediaFilterMode,
    (counts: TimelineSection['counts']) => number
> = {
    all: (counts) => counts.pictures + counts.videos + counts.screenshots,
    only_images: (counts) => counts.pictures + counts.screenshots,
    only_videos: (counts) => counts.videos,
    // only_screenshots: (counts) => counts.screenshots,
}
type TimelineSectionCounts = TimelineSectionReference & {
    sectionLength: number
}
export const getFilteredTimelineSectionCounts = createSelector(
    getTimelineSections,
    getTimelineFilterMode,
    (sections, filter): TimelineSectionCounts[] =>
        sections.map(({ year, month, startDay, endDay, counts }) => ({
            year,
            month,
            startDay,
            endDay,
            sectionLength:
                sectionLengthByFilter[filter as MediaFilterMode](counts), // This will be called only on basic filters
        })),
)
export const getTimelineFileCount: (state: StateWithTimeline) => number =
    createSelector(
        (state: StateWithTimeline) => state.timeline.sections || [],
        (sections) =>
            sections.reduce(
                (acc, { counts }) => acc + sectionLengthByFilter.all(counts),
                0,
            ),
    )

const nextFetchStatus = (
    current: MonthFetchStatus | undefined,
    candidate: MonthFetchStatus,
): MonthFetchStatus => {
    const useCandidate =
        current === undefined ||
        current === MonthFetchStatus.FETCHED ||
        (current === MonthFetchStatus.FETCHING &&
            candidate !== MonthFetchStatus.FETCHED)
    return useCandidate ? candidate : current!
}

const getMonths = createSelector(
    (state: StateWithTimeline) => state.timeline.sections || [],
    (monthSections): TimelineMonthWithGroupedCounts[] =>
        RecordUtil.from(
            groupArray(monthSections, ({ year, month }) => `${year}-${month}`),
        )
            .map(
                (sections): TimelineMonthWithGroupedCounts => ({
                    year: sections[0].year,
                    month: sections[0].month,
                    count: sections.reduce(
                        (n, { counts }) =>
                            n +
                            counts.pictures +
                            counts.videos +
                            counts.screenshots,
                        0,
                    ),
                    countGrouped: {
                        pictures: sections.reduce(
                            (n, s) => n + s.counts.pictures,
                            0,
                        ),
                        videos: sections.reduce(
                            (n, s) => n + s.counts.videos,
                            0,
                        ),
                        screenshots: sections.reduce(
                            (n, s) => n + s.counts.screenshots,
                            0,
                        ),
                    },
                    fetchStatus: sections
                        .map((s) => s.fetchStatus)
                        .reduce(nextFetchStatus),
                }),
            )
            .asArray()
            .map(({ value }) => value),
)
export const getTimelineMonths: (
    state: StateWithTimeline,
) => TimelineMonthWithGroupedCounts[] = createSelector(getMonths, (months) => {
    return (months || []).filter((m) => m.count !== 0).sort(compareMonth)
})

export type ImageGroupInfo = {
    count: number
    fetchStatus: MonthFetchStatus
    selectionStatus: GroupSelectionStatus
}

export const haveTimelineMonths = (state: StateWithTimeline): boolean => {
    return state.timeline.sections !== undefined
}

export const getFetchedTimelineSections = createSelector(
    getTimelineSections,
    (sections): TimelineSection[] => {
        return sections.filter(
            (m) => m.fetchStatus === MonthFetchStatus.FETCHED,
        )
    },
)
export const getUnfetchedTimelineSections = createSelector(
    getTimelineSections,
    (sections): TimelineSectionReference[] => {
        return sections
            .filter((m) => m.fetchStatus === MonthFetchStatus.UNFETCHED)
            .map(({ month, year, startDay, endDay }) => ({
                month,
                year,
                startDay,
                endDay,
            }))
    },
)

const makeMonthGroupHeaderByMonth = (m: MonthRef): string => {
    return m.year + (m.month > 9 ? '-' : '-0') + m.month
}

export type ImageGroup = {
    groupKey: string
    images: Array<BasicViewFile | undefined> // Files not yet loaded are added as `undefined`
    height: number
    position: number
    selectionStatus?: GroupSelectionStatus
}

// An image is considered a screenshot if name of file begins with screenshot_ (the Apps prefixes screenshots this way)
const isScreenshotRegexp = /(\/|^)screenshot_[^/]+$/i
export const timelineImageFilters: Record<
    MediaFilterMode,
    (file: CaptureFile) => boolean
> = {
    all: () => true,
    only_videos: (f) => f.type === FileTarget.Movies,
    only_images: (f) => f.type === FileTarget.Pictures,
}

const timelineGroupCountByFilter: Record<
    MediaFilterMode,
    (group: TimelineMonthWithGroupedCounts) => number
> = {
    all: (g) => g.count,
    only_videos: (g) => g.countGrouped.videos,
    only_images: (g) => g.countGrouped.pictures,
}

export const getFilteredTimelineFiles = createSelector(
    getTimelineFiles,
    getTimelineFilterMode,
    (_state: TimelineFilesSelectorState, filterBy?: MediaFilterMode) =>
        filterBy,
    (files, filter, filterBy): CaptureFile[] =>
        files.filter(
            // TODO(br1anchen): fix wrong mismatch betwwen `MediaFilterMode` and `TimelineFilterMode`
            timelineImageFilters[filterBy ?? (filter as MediaFilterMode)],
        ),
)

type TimelineMonthCount = Omit<TimelineMonthWithGroupedCounts, 'countGrouped'>
const getFilteredTimelineMonthCount = createSelector(
    getTimelineMonths,
    getTimelineFilterMode,
    (_state: StateWithTimeline, filterBy?: MediaFilterMode) => filterBy,
    (months, filter, filterBy): TimelineMonthCount[] => {
        return months
            .map((m) => ({
                ...m,
                count: timelineGroupCountByFilter[
                    filterBy || (filter as MediaFilterMode)
                ](m), // This will be called only on basic filters
            }))
            .filter((mc) => mc.count > 0)
    },
)

const getFilteredKnownMonthImageCounts = createSelector(
    getFilteredTimelineMonthCount,
    (timelineMonths): DictionaryOf<number> => {
        return timelineMonths
            .filter((m) => m.fetchStatus !== MonthFetchStatus.FETCHED)
            .reduce<DictionaryOf<number>>((knownImageCount, known) => {
                knownImageCount[makeMonthGroupHeaderByMonth(known)] =
                    known.count
                return knownImageCount
            }, {})
    },
)

const makeTimelineImageGroups = (
    files: CaptureFile[],
    knownMonthImageCounts: DictionaryOf<number>,
    groupStyle: ImageGroupStyle,
) => {
    const timelineImages: DictionaryOf<CaptureFile[]> = groupArray(
        files,
        makeMonthGroupHeaderByFile,
    )
    const groupKeys = new Set([
        ...Object.keys(timelineImages),
        ...Object.keys(knownMonthImageCounts),
    ])

    let accumulatHeight = groupStyle.verticalOffset || 0
    return Array.from(groupKeys)
        .sort((a, b) => b.localeCompare(a))
        .map((groupKey: string): ImageGroup => {
            const _imgs = timelineImages[groupKey] || []
            const imageCount = Math.max(
                _imgs.length,
                knownMonthImageCounts[groupKey] || 0,
            )
            const images =
                _imgs.length < imageCount
                    ? getRange(imageCount).map((i) => _imgs[i])
                    : _imgs
            const height = computeImageGroupHeight(imageCount, groupStyle)
            const position = accumulatHeight
            accumulatHeight += height

            return {
                groupKey,
                images,
                height,
                position,
            }
        })
}

export const getRecentFilesFiltered = createSelector(
    getTimelineFilterMode,
    getRecentFiles,
    (filter: TimelineFilterMode, files: CaptureFile[]): CaptureFile[] => {
        const filteredFiles = files.filter((file) => {
            if (filter === 'recents_1') {
                return isFileRecent(file, 1)
            }
            if (filter === 'recents_7') {
                return isFileRecent(file, 7)
            }
            if (filter === 'recents_30') {
                return isFileRecent(file, 30)
            }
            return false
        })
        return filteredFiles
    },
)

export const getDefaultRecentFilter = createSelector(
    getIsRecentFilterActive,
    getTimelineFilterMode,
    getRecentFilesCounts,
    (hasRecentsFilter, filter, counts): TimelineFilterMode => {
        if (hasRecentsFilter) {
            return filter
        }
        if (counts[7] > 0) {
            return 'recents_7'
        }
        if (counts[1] > 0) {
            return 'recents_1'
        }
        if (counts[30] > 0) {
            return 'recents_30'
        }
        return 'recents_7'
    },
)

export const getRecentFilesGrouped = createSelector(
    getRecentFilesFiltered,
    getImageGroupStyle,
    (filteredFiles, groupStyle): ImageGroup[] => {
        const timelineFiles = makeTimelineFiles(filteredFiles)
        const groups = makeTimelineGroups(timelineFiles, groupStyle)

        return groups
    },
)

type TimelineImageGroupsSelector = Selector<
    TimelineFilesSelectorState & StateWithViewMode,
    ImageGroup[],
    [MediaFilterMode | undefined]
>
export const makeTimelineImageGroupsSelector = (
    styleSelector: Selector<StateWithViewMode, ImageGroupStyle>,
): TimelineImageGroupsSelector =>
    createSelector(
        [
            getFilteredTimelineFiles,
            getFilteredKnownMonthImageCounts,
            styleSelector,
        ],
        makeTimelineImageGroups,
    )

const getAllFilesGrouped = makeTimelineImageGroupsSelector(getImageGroupStyle)
// Inject here new sources for timeline groups
const getCleanTimelineGroups = (
    state: TimelineFilesSelectorState &
        StateWithRecentFiles &
        StateWithViewMode,
    filter?: MediaFilterMode,
) => {
    const isRecents = getIsRecentFilterActive(state)
    let groups: ImageGroup[]
    if (isRecents) {
        groups = getRecentFilesGrouped(state)
    } else {
        groups = getAllFilesGrouped(state, filter)
    }
    return groups
}

export const getCleanTimelineGroupsWithoutFilter = (
    state: StateOfSelector<typeof getAllFilesGrouped>,
) => {
    return getAllFilesGrouped(state, 'all')
}

export const getCleanTimelineGroupsOnlyImages = (
    state: StateOfSelector<typeof getAllFilesGrouped>,
) => {
    return getAllFilesGrouped(state, 'only_images')
}

export const getTimelineImageGroups = createSelector(
    getCleanTimelineGroups,
    (timelineGroups) => timelineGroups,
)

export const getTimelineGroupsWithSelectionStatus = createSelector(
    getTimelineImageGroups,
    getSelectedTimelineFileIDs,
    (timelineGroups, selectedFiles): ImageGroup[] => {
        const isFileSelected = cachedInArray(selectedFiles)
        return timelineGroups.map((group) => {
            const selectedGroupFiles = group.images.filter((f) =>
                f ? isFileSelected(f.fileID) : false,
            )
            return {
                ...group,
                selectionStatus: getGroupSelectionStatus(
                    selectedGroupFiles.length,
                    group.images.length,
                ),
            }
        })
    },
)

type ImageGroupSelector = Selector<
    TimelineFilesSelectorState &
        StateOfSelector<typeof getSelectedFileIDs> &
        StateOfSelector<typeof isTimelineInSelectMode> &
        StateWithRecentFiles &
        StateWithViewMode,
    ImageGroup[],
    [MediaFilterMode | undefined]
>
export const getTimelineImageGroupsWithoutFilter = createSelector(
    getCleanTimelineGroupsWithoutFilter,
    (timelineGroups) => timelineGroups,
)

export const getTimelineImageGroupsOnlyImages = createSelector(
    getCleanTimelineGroupsOnlyImages,
    (timelineGroups) => timelineGroups,
)

export const getTimelineGroupsWithSelectionStatusWithoutFilter = createSelector(
    getTimelineImageGroupsWithoutFilter,
    getSelectedTimelineFileIDs,
    (timelineGroups, selectedFiles): ImageGroup[] => {
        const isFileSelected = cachedInArray(selectedFiles)
        return timelineGroups.map((group) => {
            const selectedGroupFiles = group.images.filter((f) =>
                f ? isFileSelected(f.fileID) : false,
            )
            return {
                ...group,
                selectionStatus: getGroupSelectionStatus(
                    selectedGroupFiles.length,
                    group.images.length,
                ),
            }
        })
    },
)

type ScrollerGroupSelector = (
    state: StateOfSelector<ImageGroupSelector>,
    f?: MediaFilterMode,
) => ScrollerGroup[]

export const makeScrollerGroupSelector = (
    groupSelector: ImageGroupSelector,
): ScrollerGroupSelector =>
    createSelector(
        groupSelector,
        getSelectedFileIDs,
        isTimelineInSelectMode,
        (
            imgGroups: ImageGroup[],
            selectedIDs,
            isSelectMode,
        ): ScrollerGroup[] => {
            const checkSelectionMethod = (fileID: FileID) =>
                selectedIDs.includes(fileID)

            return makeTimelineScrollerGroups(
                imgGroups,
                isSelectMode ? checkSelectionMethod : undefined,
            )
        },
    )

export const getTimelineScrollerGroups = makeScrollerGroupSelector(
    getTimelineImageGroups,
)

export const getTimelineScrollerGroupsWithoutFilter = makeScrollerGroupSelector(
    getTimelineImageGroupsWithoutFilter,
)

export const getTimelineScrollerGroupsOnlyImages = makeScrollerGroupSelector(
    getTimelineImageGroupsOnlyImages,
)

export const isAllFilesFetched = (state: StateWithTimeline) =>
    state.timeline.allFilesFetched

type DocumentFile = {
    fileID: FileID
    fileName: string
    uploadedTime: Date
}

export const getTimelineDocumentsFile = (
    state: StateWithTimeline & StateWithFiles,
) => {
    const jobID = getTimelineJobID(state)
    return jobID === undefined ? [] : getDocumentFilesForJob(state, jobID)
}

export type DocumentFileGroup = {
    header: string
    files: DocumentFile[]
}
export const getTimelineDocumentGroups: (
    state: StateWithTimeline & StateWithFiles,
) => DocumentFileGroup[] = createSelector(getTimelineDocumentsFile, (files) => {
    const groupKeys: DictionaryOf<boolean> = {}
    const documentGroups: DictionaryOf<DocumentFile[]> = {}

    files
        .filter((f) => !f.isPendingServerDelete)
        .forEach((f) => {
            const g = makeMonthGroupHeaderByFile(f)
            groupKeys[g] = true

            const documentFile = {
                fileID: f.fileID,
                fileName: f.path,
                uploadedTime: new Date(getFileTime(f)),
            }
            if (documentGroups[g] === undefined) {
                documentGroups[g] = [documentFile]
            } else {
                documentGroups[g].push(documentFile)
            }
        })

    return Object.keys(groupKeys)
        .sort((a, b) => b.localeCompare(a))
        .map((g) => ({
            header: g,
            files: documentGroups[g],
        }))
})

export const getLastSeenTimelineElement = (
    state: StateWithTimeline & StateWithLastSeenElement,
): FileID | undefined => {
    const timelineID = getTimelineJobID(state)
    if (timelineID) {
        return getLastSeenElementForJob(state, timelineID)
    }
}

export const getLastViewedOffset = (style: GridStyle) =>
    createSelector(
        getTimelineImageGroups,
        getLastSeenTimelineElement,
        (groups, lastSeenElement: FileID | undefined): number | undefined => {
            if (lastSeenElement) {
                const offsetData = groups.reduce(
                    (acc, currGroup) => {
                        const imageIDs = withoutTheUndefined(
                            currGroup.images,
                        ).map((f) => f.fileID)
                        if (inArray(imageIDs, lastSeenElement)) {
                            return {
                                groupOffset: currGroup.position,
                                fileIndex: imageIDs.indexOf(lastSeenElement),
                            }
                        }
                        return acc
                    },
                    { groupOffset: 0, fileIndex: 0 },
                )

                const offset =
                    offsetData.groupOffset +
                    Math.floor(offsetData.fileIndex / calcImagesPerRow(style)) *
                        style.elementHeight // inner group offset
                return offset
            }
            return undefined
        },
    )

const calculateGroupCount = (
    fileInfos: Array<{ type: FileTarget; path: string }>,
): TimelineMonthCountDetail => {
    const newGroup = { pictures: 0, videos: 0, screenshots: 0 }
    fileInfos.forEach((file) => {
        switch (file.type) {
            case FileTarget.Pictures:
                newGroup[
                    isScreenshotRegexp.test(file.path)
                        ? 'screenshots'
                        : 'pictures'
                ] += 1
                break
            case FileTarget.Movies:
                newGroup.videos += 1
                break
            default:
                break
        }
    })
    return newGroup
}

export type ImageGroupItemReference = { group: string; index: number }
export const getCastedTimelineFile = createSelector(
    getCurrentViewInfo,
    getTimelineJobID,
    getCastDetails,
    getTimelineImageGroups,
    (
        viewerInfo,
        timelineID,
        castDetails,
        imageGroups,
    ): ImageGroupItemReference | undefined => {
        if (!viewerInfo || !castDetails || timelineID !== viewerInfo.jobID) {
            return
        }

        let focusedItem = { group: '', index: 0 }
        imageGroups.forEach((group) => {
            const index = getElementIndex(
                group.images,
                (groupFile) =>
                    groupFile !== undefined &&
                    groupFile.fileID === viewerInfo.fileID,
            )
            if (index >= 0) {
                focusedItem = { group: group.groupKey, index }
            }
        })

        return focusedItem
    },
)
