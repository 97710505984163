import type { SVGProps } from 'react'
const SvgSms = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill={props.color ?? 'currentColor'}
            d="M8 13c.283 0 .52-.096.713-.287A.968.968 0 0 0 9 12a.968.968 0 0 0-.287-.713A.967.967 0 0 0 8 11a.967.967 0 0 0-.713.287A.968.968 0 0 0 7 12c0 .283.096.52.287.713.192.191.43.287.713.287m4 0c.283 0 .52-.096.713-.287A.968.968 0 0 0 13 12a.968.968 0 0 0-.287-.713A.968.968 0 0 0 12 11a.968.968 0 0 0-.713.287A.968.968 0 0 0 11 12c0 .283.096.52.287.713.192.191.43.287.713.287m4 0c.283 0 .52-.096.712-.287A.968.968 0 0 0 17 12a.968.968 0 0 0-.288-.713A.968.968 0 0 0 16 11a.968.968 0 0 0-.713.287A.968.968 0 0 0 15 12c0 .283.096.52.287.713.192.191.43.287.713.287M6 20l-2.3 2.3c-.317.317-.68.387-1.088.212-.408-.175-.612-.487-.612-.937V6c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 4 4h16c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v12c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 20 20zm-.85-2H20V6H4v13.125z"
        />
    </svg>
)
export default SvgSms
