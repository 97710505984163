import type { AlbumDetails, AlbumSortOrder } from '../albumList/reducer'
import { AlbumOptionsOverlayTriggered } from '../albumOptions/actions'
import { createActionCreator, createVoidActionCreator } from '../common/actions'

/* tslint:disable:max-line-length */

/**
 * Albums do not have their own state (the state is derived from state of other parts), but do dispatch som actions
 * that are made available for analytical purposes (by the analytics-package)
 */

export const DownloadAlbumClicked = createActionCreator<JobID>(
    'DOWNLOAD_ALBUM_CLICKED',
)
export const DownloadAlbumSuccess = createActionCreator<JobID>(
    'DOWNLOAD_ALBUM_SUCCESS',
)
export const DownloadAlbumFailed = createActionCreator<JobID>(
    'DOWNLOAD_ALBUM_FAILED',
)

// Event to track if users are interested in/tries to trigger full-screen-image
export const ClickedAlbumImage = createActionCreator<{
    albumID: JobID
    imageID: ImageID
}>('CLICKED_ALBUM_IMAGE')

export const AlbumSharingInitiated = (id: JobID) =>
    AlbumOptionsOverlayTriggered({ albumID: id, type: 'share' })
export const EditAlbumSettingsInitiated = (id: JobID) =>
    AlbumOptionsOverlayTriggered({ albumID: id, type: 'settings' })
export const DisplayListOfLoversInitiated = (albumID: JobID, fileID: FileID) =>
    AlbumOptionsOverlayTriggered({ albumID, fileID, type: 'loveList' })
export const DisplayListOfParticipantsInitiated = (albumID: JobID) =>
    AlbumOptionsOverlayTriggered({ albumID, type: 'participantsList' })
export const AlbumListFilterInitiated = () =>
    AlbumOptionsOverlayTriggered({ type: 'albums_filter' })
export const AlbumListSortModeInitiated = () =>
    AlbumOptionsOverlayTriggered({ type: 'albums_sort' })
export const CreateAlbumInitiated = () =>
    AlbumOptionsOverlayTriggered({ type: 'create_album' })
export const ShowShareAlbumPrompt = (id: JobID) =>
    AlbumOptionsOverlayTriggered({ albumID: id, type: 'share_prompt' })
export const ShowAlbumSortOrderPrompt = (id: JobID) =>
    AlbumOptionsOverlayTriggered({ albumID: id, type: 'album_files_sort' })

export const AlbumWasCreated = createActionCreator<{
    jobID: JobID
    shared: boolean
}>('ALBUM_WAS_CREATED')
export const AlbumCreationConfirmed = createActionCreator<{
    jobID: JobID
    shared: boolean
}>('ALBUM_CREATION_WAS_CONFIRMED')
export const AlbumCreationEnded = createVoidActionCreator(
    'ALBUM_JUST_CREATED_CLEARED',
)
export type AutoGeneratedAlbumInfo = {
    jobID: JobID
    tempID: string
    name: string
}
export const AutoGeneratedAlbumCreated =
    createActionCreator<AutoGeneratedAlbumInfo>('AUTO_CREATED_ALBUM_CREATED')
export const AutoGeneratedAlbumFinished =
    createActionCreator<AutoGeneratedAlbumInfo>('AUTO_CREATED_ALBUM_FINISHED')
export const AutoGeneratedAlbumCanceled =
    createActionCreator<AutoGeneratedAlbumInfo>('AUTO_CREATED_ALBUM_CANCELED')

type SortOrderPayload = {
    jobID: JobID
    sortOrder: AlbumSortOrder
}
export const AlbumSortOrderChanged = createActionCreator<SortOrderPayload>(
    'ALBUM_SORT_MODE_CHANGED',
)
export const AlbumSortOrderSaved = createVoidActionCreator(
    'ALBUM_SORT_ORDER_SAVED',
)
export const AlbumSortOrderSaveFailed = createVoidActionCreator(
    'ALBUM_SORT_ORDER_SAVE_FAILED',
)

export const AlbumDetailsFetched = createActionCreator<AlbumDetails>(
    'ALBUM_DETAILS_FETCHED',
)

export const AlbumCreatedFromUploadFilesFlag = createVoidActionCreator(
    'UPLOADED_FILES_TO_ALBUM_FLAG',
)

export const ClearAlbumCreatedFromUploadFiles = createVoidActionCreator(
    'UPLOADED_FILES_TO_ALBUM_FLAG_REMOVED',
)
