import { useEffect, useRef, useState } from 'react'
import { getConnectedInstance } from '~/state/uploader/uploadQueue'
import { Thumb } from './Thumb'

type Props = {
    fileName: string
    uploadId: number
}

export const AsyncPreviewThumb = ({ fileName, uploadId }: Props) => {
    const [thumbURL, setThumbURL] = useState('')
    const isMounted = useRef(false)

    useEffect(() => {
        const updateThumb = (uploadId: number) => {
            getConnectedInstance()
                .getUploadFilePreviewThumb(uploadId)
                .then((thumbURL) => {
                    setThumbURL(thumbURL)
                })
        }

        isMounted.current = true
        if (isMounted.current) {
            updateThumb(uploadId)
        }
        return () => {
            isMounted.current = false
        }
    }, [uploadId])

    return <Thumb fileName={fileName} thumbURL={thumbURL} />
}
