import type { AlbumFile } from '~/state/album/selectors'
import type { BasicViewFile } from '~/state/files/selectors'
import { createActionCreator, createVoidActionCreator } from '../common/actions'

export const setContextMenuTarget = createActionCreator<
    BasicViewFile | AlbumFile
>('CONTEXT_MENU_TARGET_SET')
export const unsetContextMenuTarget = createVoidActionCreator(
    'CONTEXT_MENU_TARGET_UNSET',
)
export const showSideInfoMenu = createVoidActionCreator('SHOW_SIDE_FILE_INFO')
export const hideSideInfoMenu = createVoidActionCreator('HIDE_SIDE_FILE_INFO')
