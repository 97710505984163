/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    CAPBAKHTTPValidationError,
    CAPBAKTrashCanGetParams,
    CAPBAKTrashcanFileItem,
} from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class TrashCan<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * @description this call will list all files in the trash can
     *
     * @tags trash_can
     * @name TrashCanGet
     * @summary Get Trash Can Files
     * @request GET:/trash_can
     */
    trashCanGet = (
        query: CAPBAKTrashCanGetParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKTrashcanFileItem[], CAPBAKHTTPValidationError>({
            path: `/trash_can`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
}
