/**
 * By convention amongst clients, album-passwords are hashed with the album UUID into some magic string and used as password.
 * This tool-function does that.
 *
 * @param password (user provided string)
 * @param albumID  (UUID of album)
 * @returns {string}
 */
export async function getAlbumPasswordHash(password: string, albumID: JobID) {
    // Convert the password and albumID to an ArrayBuffer
    const passwordBuffer = new TextEncoder().encode(password + albumID)
    const passwordHash = await crypto.subtle.digest('SHA-1', passwordBuffer)
    const passwordHex = Array.from(new Uint8Array(passwordHash))
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('')

    const saltBuffer = new TextEncoder().encode(albumID)

    // Import the password as a raw key
    const key = await crypto.subtle.importKey(
        'raw',
        new TextEncoder().encode(passwordHex),
        { name: 'PBKDF2' },
        false,
        ['deriveBits'],
    )

    // Derive a key from the password
    const derivedBits = await crypto.subtle.deriveBits(
        { name: 'PBKDF2', salt: saltBuffer, iterations: 1001, hash: 'SHA-1' },
        key,
        5 * 32,
    )

    // Convert the derived key to a base64 string
    let binary = ''
    const bytes = new Uint8Array(derivedBits)
    const len = bytes.byteLength
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
    }
    const derivedKeyBase64 = btoa(binary)

    return derivedKeyBase64
}
