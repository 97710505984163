import type { SVGProps } from 'react'
const SvgAvatar = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill={props.color ?? 'currentColor'}
            d="M5.85 17.1c.85-.65 1.8-1.163 2.85-1.538A9.737 9.737 0 0 1 12 15c1.15 0 2.25.188 3.3.563 1.05.374 2 .887 2.85 1.537a7.73 7.73 0 0 0 1.363-2.325A7.838 7.838 0 0 0 20 12c0-2.217-.78-4.104-2.337-5.662C16.104 4.779 14.217 4 12 4s-4.104.78-5.662 2.338C4.779 7.896 4 9.783 4 12c0 .983.162 1.908.487 2.775.325.867.78 1.642 1.363 2.325M12 13c-.983 0-1.812-.338-2.488-1.012C8.837 11.313 8.5 10.483 8.5 9.5c0-.983.338-1.812 1.012-2.487C10.188 6.338 11.017 6 12 6c.983 0 1.813.338 2.488 1.013.675.675 1.012 1.504 1.012 2.487 0 .983-.338 1.813-1.012 2.488C13.813 12.663 12.983 13 12 13m0 9a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12a9.738 9.738 0 0 1-.788 3.9 10.098 10.098 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0 1 12 22"
        />
    </svg>
)
export default SvgAvatar
