import type { SVGProps } from 'react'
const SvgCopy = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill={props.color ?? 'currentColor'}
            d="M9 18c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 7 16V4c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 9 2h9c.55 0 1.02.196 1.413.587C19.804 2.98 20 3.45 20 4v12c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 18 18zm0-2h9V4H9zm-4 6c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 3 20V7c0-.283.096-.52.288-.713A.968.968 0 0 1 4 6c.283 0 .52.096.713.287.191.192.287.43.287.713v13h10c.283 0 .52.096.713.288.191.191.287.429.287.712s-.096.52-.287.712A.968.968 0 0 1 15 22z"
        />
    </svg>
)
export default SvgCopy
