import SvgArrowBack from '@capture/capture-components/src/icons/ArrowBack'
import SvgDelete from '@capture/capture-components/src/icons/Delete'
import SvgDownload from '@capture/capture-components/src/icons/Download'
import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { downloadFiles } from '~/API/download'
import { deleteMultipleFiles, fetchAllUserFiles } from '~/API/job'
import { trackEvent } from '~/analytics/eventTracking'
import { _, getStringWithAmount } from '~/assets/localization/util'
import { fontSize, layout } from '~/assets/styleConstants'
import { IconTextButton } from '~/components/Common/IconTextButton'
import { TwoAreasTopNavBar } from '~/components/Navigation/TwoAreasTopNavBar'
import { Pages } from '~/routing'
import type { Dispatch } from '~/state/common/actions'
import { getCurrentUserUUID } from '~/state/currentUser/selectors'
import type { ExtendedJobFile } from '~/state/files/reducer'
import {
    FilesWereDeselected,
    FilesWereSelected,
} from '~/state/selectedFiles/actions'
import { makeIsFileSelected } from '~/state/selectedFiles/selectors'
import type { DocumentFileGroup } from '~/state/timeline/selectors'
import {
    getSelectedDocumentFileIDs,
    getSelectedDocumentFiles,
    getSelectedDocumentsFileSize,
    getTimelineDocumentGroups,
    getTimelineJobID,
    isAllFilesFetched,
} from '~/state/timeline/selectors'
import { getImageGroupStyle, isMobileMode } from '~/state/viewMode/selectors'
import { isHEICFile } from '~/utilities/fileTarget'
import type { ImageGroupStyle } from '~/utilities/imageGroupStyle'
import type { WithRouterProps } from '~/utilities/navigation'
import { withRouter } from '~/utilities/navigation'
import { Button } from '../Common/Button'
import { ConfirmPromptOverlay } from '../Common/DialoguePromptOverlay'
import { EmptyStatePage } from '../Common/EmptyStatePage'
import { PageWrapper } from '../Common/PageWrapper'
import { RippleLoaderPage } from '../Common/RippleLoader'
import { HeaderText } from '../Common/StandardPageElements'
import {
    SelectionToolbarDesktop,
    SelectionToolbarMobile,
} from '../Timeline/SelectionToolBar'
import { DocumentsList } from './DocumentsList'

const ContentWrapper = styled.div`
    width: 100%;
    max-width: ${(props: { width: number }) => props.width}px;
`

const Header = styled(HeaderText)`
    margin-top: ${layout.selectionToolbarHeight}px;
    width: 100%;
    max-width: ${(props: { width: number }) => props.width}px;
    text-align: left;
`

type PromptProps = {
    filesNum: number
    onConfirm: () => void
    onDeny: () => void
}
const DeleteFilesPrompt: React.FunctionComponent<PromptProps> = ({
    filesNum,
    onConfirm,
    onDeny,
}) => {
    const deletePromptText = getStringWithAmount(
        filesNum,
        _('delete_single_document_file'),
        _('delete_document_files__format'),
    )

    return (
        <ConfirmPromptOverlay
            onConfirm={onConfirm}
            onCancel={onDeny}
            confirmText={_('delete_')}>
            {deletePromptText}
        </ConfirmPromptOverlay>
    )
}

type StateProps = {
    getFileSelectedStatus: (id: FileID) => boolean
    selectedFilesIDs: FileID[]
    selectedFiles: ExtendedJobFile[]
    isMobileMode: boolean
    timelineJobID: JobID | undefined
    currentUser: UserID | undefined
    isDocumentsReady: boolean
    documentGroups: DocumentFileGroup[]
    selectedFileSize: number
    groupStyle: ImageGroupStyle
}

type DocumentsAction = (jobID: JobID, files: FileID[]) => Promise<void>
type DispatchProps = {
    fetchAllFiles: (jobID: JobID, currentUser: UserID) => void
    deleteFiles: DocumentsAction
    downloadFiles: (jobID: JobID, files: ExtendedJobFile[]) => void
    doSelectFile: (fileID: string) => void
    doDeselectFile: (fileID: FileID) => void
    doClearSelection: (fileIDs: FileID[]) => void
}

type Props = StateProps & DispatchProps & WithRouterProps

type PageState = {
    showDeletePrompt: boolean
}

class DocumentsPageComponent extends React.Component<Props, PageState> {
    private initialState: PageState = {
        showDeletePrompt: false,
    }
    public state: PageState = this.initialState

    private tryFetchDocuments = ({
        isDocumentsReady,
        timelineJobID,
        currentUser,
    }: Props) => {
        if (!isDocumentsReady && timelineJobID && currentUser) {
            this.props.fetchAllFiles(timelineJobID, currentUser)
        }
    }

    private resetSelection = () => {
        this.setState(this.initialState)
        this.props.doClearSelection(this.props.selectedFilesIDs)
    }

    private doActionForFiles = (
        action: DocumentsAction,
        targetFiles: FileID[],
    ) => {
        if (this.props.timelineJobID) {
            action(this.props.timelineJobID, targetFiles).then(
                this.resetSelection,
            )
        }
    }

    private handleDownloadSelectedFiles = () => {
        if (this.props.timelineJobID) {
            this.props.downloadFiles(
                this.props.timelineJobID,
                this.props.selectedFiles,
            )
        }
    }

    private triggerDeletePrompt = () =>
        this.setState({ showDeletePrompt: true })

    private confirmDelete = () => {
        if (this.props.selectedFilesIDs.length > 0) {
            this.doActionForFiles(
                this.props.deleteFiles,
                this.props.selectedFilesIDs,
            )
        }
    }

    private closePrompt = () => {
        this.setState({
            showDeletePrompt: false,
        })
    }

    private selectionButtons = [
        Button(_('download'), this.handleDownloadSelectedFiles, {
            icon: SvgDownload,
        }),
        Button(_('delete_'), this.triggerDeletePrompt, { icon: SvgDelete }),
    ]

    public componentDidMount() {
        this.tryFetchDocuments(this.props)
    }

    public componentWillUnmount() {
        this.props.doClearSelection(this.props.selectedFilesIDs)
    }

    public componentDidUpdate(prevProps: Props) {
        if (
            prevProps.isDocumentsReady !== this.props.isDocumentsReady ||
            prevProps.timelineJobID !== this.props.timelineJobID ||
            prevProps.currentUser !== this.props.currentUser
        ) {
            this.tryFetchDocuments(this.props)
        }

        if (!prevProps.isDocumentsReady && this.props.isDocumentsReady) {
            trackEvent('documents', 'filesLoaded')
            if (this.props.documentGroups.length === 0) {
                trackEvent('documents', 'filesLoaded_noContent')
            }
        }
    }

    public render() {
        const isInSelectMode = this.props.selectedFilesIDs.length > 0

        const leftButton = (
            <IconTextButton
                onClick={() => this.props.navigate(Pages.Settings.url)}
                text={_('settings')}
                icon={SvgArrowBack}
                fontSize={fontSize.small_14}
            />
        )

        const getToolbar = () => {
            if (!isInSelectMode) {
                return <TwoAreasTopNavBar left={leftButton} right={null} />
            }
            const Toolbar = this.props.isMobileMode
                ? SelectionToolbarMobile
                : SelectionToolbarDesktop

            return (
                <Toolbar
                    buttons={this.selectionButtons}
                    selectedFiles={this.props.selectedFilesIDs}
                    cancelSelectMode={this.resetSelection}
                    selectedFilesSize={this.props.selectedFileSize}
                />
            )
        }

        let content
        if (!this.props.isDocumentsReady) {
            content = <RippleLoaderPage />
        } else if (this.props.documentGroups.length === 0) {
            content = <EmptyStatePage header={_('documents_no_files')} />
        } else {
            const deletePrompt = this.state.showDeletePrompt && (
                <DeleteFilesPrompt
                    filesNum={this.props.selectedFilesIDs.length}
                    onConfirm={this.confirmDelete}
                    onDeny={this.closePrompt}
                />
            )
            content = (
                <>
                    <ContentWrapper width={this.props.groupStyle.width}>
                        {!this.props.isMobileMode && (
                            <Header width={this.props.groupStyle.width}>
                                {_('documents')}
                            </Header>
                        )}
                        <DocumentsList
                            isMobile={this.props.isMobileMode}
                            isInSelectMode={isInSelectMode}
                            getFileSelectionStatus={
                                this.props.getFileSelectedStatus
                            }
                            documentGroups={this.props.documentGroups}
                            onFileSelected={this.props.doSelectFile}
                            onFileDeSelected={this.props.doDeselectFile}
                        />
                    </ContentWrapper>
                    {deletePrompt}
                </>
            )
        }

        return (
            <PageWrapper
                navBar={getToolbar()}
                isContentReady={this.props.isDocumentsReady}>
                {content}
            </PageWrapper>
        )
    }
}

type DocumentsPageStoreState = StateOfSelector<
    typeof getSelectedDocumentFileIDs
> &
    StateOfSelector<typeof getSelectedDocumentFiles> &
    StateOfSelector<ReturnType<typeof makeIsFileSelected>> &
    StateOfSelector<typeof isMobileMode> &
    StateOfSelector<typeof getTimelineJobID> &
    StateOfSelector<typeof isAllFilesFetched> &
    StateOfSelector<typeof getCurrentUserUUID> &
    StateOfSelector<typeof getTimelineDocumentGroups> &
    StateOfSelector<typeof getSelectedDocumentsFileSize> &
    StateOfSelector<typeof getImageGroupStyle>

const mapStateToProps = (state: DocumentsPageStoreState): StateProps => ({
    selectedFilesIDs: getSelectedDocumentFileIDs(state),
    selectedFiles: getSelectedDocumentFiles(state),
    getFileSelectedStatus: (fileID: FileID) =>
        makeIsFileSelected(fileID)(state),
    isMobileMode: isMobileMode(state),
    timelineJobID: getTimelineJobID(state),
    isDocumentsReady: isAllFilesFetched(state),
    currentUser: getCurrentUserUUID(state),
    documentGroups: getTimelineDocumentGroups(state),
    selectedFileSize: getSelectedDocumentsFileSize(state),
    groupStyle: getImageGroupStyle(state),
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    fetchAllFiles: (jobID: JobID, currentUser: UserID) =>
        fetchAllUserFiles(dispatch, jobID, currentUser),
    deleteFiles: (jobID: JobID, files: FileID[]) =>
        deleteMultipleFiles(dispatch, jobID, files),
    downloadFiles: (jobID: JobID, files: ExtendedJobFile[]) =>
        downloadFiles(dispatch, {
            type: 'download',
            jobID,
            fileIDs: files.map((file) => file.fileID),
            hasHEIC: files.some((file) => isHEICFile(file.path)),
        }),
    doSelectFile: (fileID: FileID) => dispatch(FilesWereSelected([fileID])),
    doDeselectFile: (fileID: FileID) => dispatch(FilesWereDeselected([fileID])),
    doClearSelection: (fileIDs: FileID[]) =>
        dispatch(FilesWereDeselected(fileIDs)),
})

const ConnectedDocumentsPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DocumentsPageComponent)

export const DocumentsPage = withRouter(ConnectedDocumentsPage)
