export type GridElementProps = {
    elementWidth: number
    elementHeight: number
    elementSpaceAround: number
}
export type GridStyle = GridElementProps & {
    width: number
}

export const getElementSize = (style: GridElementProps) => ({
    width: style.elementWidth + style.elementSpaceAround * 2,
    height: style.elementHeight + style.elementSpaceAround * 2,
})

export const computeMaxGridWidth = (
    style: GridElementProps,
    maxItemsPerRow: number,
) => (style.elementWidth + style.elementSpaceAround * 2) * maxItemsPerRow

export const computeGridContainerWidth = (
    screenWidth: number,
    maxContainerWidth: number,
    elementWidth: number,
    elementSpaceAround: number,
) => {
    const totalElementWidth = elementWidth + elementSpaceAround * 2
    return (
        Math.floor(
            Math.min(maxContainerWidth, screenWidth) / totalElementWidth,
        ) * totalElementWidth
    )
}

export const calcImagesPerRow = (style: GridStyle) => {
    const elementSize = getElementSize(style)
    return Math.floor(style.width / elementSize.width)
}
export const calcNumberOfVisibleThumbs = (
    style: GridStyle,
    screenHeight: number,
): number => {
    const elementSize = getElementSize(style)
    const imagesPerRow = calcImagesPerRow(style)

    return Math.ceil(screenHeight / elementSize.height) * imagesPerRow
}

export const computeGridHeight = (style: GridStyle, gridItemCount: number) => {
    const elementSize = getElementSize(style)
    return (
        Math.ceil(gridItemCount / calcImagesPerRow(style)) * elementSize.height
    )
}
