import * as React from 'react'
import styled from 'styled-components'
import { _ } from '~/assets/localization/util'
import { colors, fontSize } from '~/assets/styleConstants'
import type { ButtonProps } from './Button'
import { Button } from './Button'
import { FullscreenOverlay } from './FullscreenOverlay'
import { FilledTextButton, TextButton } from './IconTextButton'

const Wrapper = styled.div`
    max-width: 400px;
    background-color: white;
    box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 24px;
    box-sizing: border-box;
    border-radius: 3px;
    font-size: ${fontSize.small_14};

    & h2 {
        margin-top: 12px;
    }
    & a {
        color: ${colors.captureBlue};
    }
`

const BtnArea = styled.div`
    margin: 8px -12px 0;
    padding-top: 8px;
    border-top: 1px solid ${colors.captureGrey300};
    text-align: right;
`
const BtnWrapper = styled.div`
    display: inline-block;
    width: 100px;
    margin-left: 8px;
`

type GeneralAcceptPromptProps = {
    header: string
    primaryButton: ButtonProps
    secondaryButton: ButtonProps
    children?: React.ReactNode
}
export const GeneralAcceptPromptOverlay: React.FunctionComponent<
    GeneralAcceptPromptProps
> = (props) => (
    <FullscreenOverlay>
        <Wrapper>
            <h2>{props.header}</h2>
            {props.children}
            <BtnArea>
                <BtnWrapper data-cy={'overlay_deny'}>
                    <TextButton {...props.secondaryButton} />
                </BtnWrapper>
                <BtnWrapper data-cy={'overlay_accept'}>
                    <FilledTextButton {...props.primaryButton} />
                </BtnWrapper>
            </BtnArea>
        </Wrapper>
    </FullscreenOverlay>
)

export class YesNoPromptOverlay extends React.Component<{
    header: string
    onResponse: (v: boolean) => void
    children?: React.ReactNode
}> {
    private onYes = () => this.props.onResponse(true)
    private onNo = () => this.props.onResponse(false)

    public render() {
        return (
            <GeneralAcceptPromptOverlay
                header={this.props.header}
                primaryButton={Button(_('yes'), this.onYes)}
                secondaryButton={Button(_('no'), this.onNo)}>
                {this.props.children}
            </GeneralAcceptPromptOverlay>
        )
    }
}
