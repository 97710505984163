import * as React from 'react'
import { connect } from 'react-redux'
import type { Album } from '~/state/album/selectors'
import { getAlbum } from '~/state/album/selectors'
import { JobInfoStatus } from '~/state/jobInfo/reducer'
import { getStatusOfJob } from '~/state/jobInfo/selectors'
import { AlbumNotFoundPage, ProvidePasswordAlbumPage } from '../Info'
import { LoadingPage } from '../Common/LoadingPage'

export type FetchedPageType = React.ComponentType<{ album: Album }>

type OwnProps = {
    albumID: JobID
    // eslint-disable-next-line react-redux/no-unused-prop-types -- this prompt is incorrect, typescript cannot see the usage
    fetchedPage: FetchedPageType
}
type StateProps = {
    fetchStatus: JobInfoStatus
    album?: Album
}
type Props = OwnProps & StateProps

class _AlbumContainer extends React.Component<Props> {
    public render() {
        switch (this.props.fetchStatus) {
            case JobInfoStatus.FETCHED:
            case JobInfoStatus.PARTIALLY_FETCHED:
                return (
                    this.props.album && (
                        <this.props.fetchedPage album={this.props.album} />
                    )
                )
            case JobInfoStatus.NOT_FOUND:
            case JobInfoStatus.FETCH_FAILED:
                return <AlbumNotFoundPage />
            case JobInfoStatus.PASSWORD_REQUIRED:
                return <ProvidePasswordAlbumPage albumID={this.props.albumID} />
            case JobInfoStatus.NOT_STARTED:
            case JobInfoStatus.PENDING:
            default:
                return <LoadingPage />
        }
    }
}

const mapStateToProps = (
    state: StateOfSelector<typeof getStatusOfJob> &
        StateOfSelector<typeof getAlbum>,
    ownProps: OwnProps,
): StateProps => ({
    fetchStatus: getStatusOfJob(state, ownProps.albumID),
    album: getAlbum(state, ownProps.albumID),
})

export const AlbumContainer = connect(mapStateToProps)(_AlbumContainer)
