import { createRef, useEffect } from 'react'

type Ext = { onClickOutside?: () => void }

// We do this in order to satisfy T type in Inner component
// eslint-disable-next-line @typescript-eslint/ban-types
export const withClickOutsideHandling = <T extends {}>(
    Inner: React.ComponentType<T>,
): React.FunctionComponent<T & Ext> => {
    const ClickOutsideHandler = (props: Ext) => {
        const { onClickOutside, ...rest } = props
        const ref = createRef<HTMLDivElement>()

        useEffect(() => {
            const handleClickOutsideMenu = (event: MouseEvent | TouchEvent) => {
                if (
                    onClickOutside &&
                    ref?.current &&
                    !ref?.current.contains(event.target as Node)
                ) {
                    onClickOutside()
                }
            }

            document.addEventListener('click', handleClickOutsideMenu, {
                capture: true,
            })
            document.addEventListener('touchend', handleClickOutsideMenu, {
                capture: true,
            })
            document.addEventListener('contextmenu', handleClickOutsideMenu, {
                capture: true,
            })
            return () => {
                document.removeEventListener('click', handleClickOutsideMenu, {
                    capture: true,
                })
                document.removeEventListener(
                    'touchend',
                    handleClickOutsideMenu,
                    { capture: true },
                )
                document.removeEventListener(
                    'contextmenu',
                    handleClickOutsideMenu,
                    {
                        capture: true,
                    },
                )
            }
        }, [ref, onClickOutside])

        return (
            <div ref={ref}>
                <Inner {...(rest as T)} />
            </div>
        )
    }
    return ClickOutsideHandler
}
