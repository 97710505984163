/**
 * This component is used to indicate to users the expected deletion date of their files
 * It belongs to the sunset flow and should be deleted once this is through
 */

import { Prompt, Typography } from '@capture/capture-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { _ } from '~/assets/localization/util'
import { SUNSET_FAQ } from '~/config/constants'
import { Pages } from '~/routing'
import { getSunsetDeletionDate } from '~/state/currentUser/selectors'
import { dismissSunsetDeletionModal } from '~/state/modalManager/modalManagerSlice'

export const SunsetDeletionPrompt = () => {
    const dispatch = useDispatch()
    const deletionDate = useSelector(getSunsetDeletionDate)
    const navigate = useNavigate()

    const handlePromptDismiss = () => {
        dispatch(dismissSunsetDeletionModal())
    }

    const openSunsetInNewTab = () => {
        window.open(SUNSET_FAQ, '_blank')
    }

    const navigateToSubscriptionPlans = () => {
        // Dismiss the prompt before navigating,
        // otherwise component will mounted again on new page
        handlePromptDismiss()
        navigate(Pages.SettingsStorage.url)
    }

    const handleOpenChanged = (open: boolean) => {
        if (!open) {
            handlePromptDismiss()
        }
    }

    if (!deletionDate) {
        return null
    }

    const [before, after] = _('deletion_prompt__body').split('%delete_date%')

    return (
        <Prompt.Root defaultOpen onOpenChange={handleOpenChanged}>
            <Prompt.Content
                title={_('deletion_prompt__title')}
                preventCloseOnClickOutside>
                <Typography.Paragraph>
                    {before}
                    <Typography.Bold>{deletionDate}</Typography.Bold>
                    {after}
                </Typography.Paragraph>
                <Prompt.ButtonsSection>
                    <Prompt.ActionButton
                        variant="secondary"
                        key="secondary"
                        onPress={openSunsetInNewTab}>
                        {_('deletion_prompt__more_info')}
                    </Prompt.ActionButton>
                    <Prompt.ActionButton
                        variant="primary"
                        key="primary"
                        onPress={navigateToSubscriptionPlans}>
                        {_('deletion_prompt__plans')}
                    </Prompt.ActionButton>
                </Prompt.ButtonsSection>
            </Prompt.Content>
        </Prompt.Root>
    )
}
