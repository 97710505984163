/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    CAPBAKAccountAttributeDeleteParams,
    CAPBAKAccountAttributeGetParams,
    CAPBAKAccountAttributePostParams,
    CAPBAKAccountAttributePostPayload,
    CAPBAKAccountAttributeResponse,
    CAPBAKHTTPValidationError,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class AccountAttribute<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * @description This call is used to upsert account attribute. It will send a client notification of the upsert.
     *
     * @tags account_info
     * @name AccountAttributePost
     * @summary Post Account Attribute
     * @request POST:/account_attribute
     */
    accountAttributePost = (
        query: CAPBAKAccountAttributePostParams,
        data: CAPBAKAccountAttributePostPayload,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKAccountAttributeResponse,
            void | CAPBAKHTTPValidationError
        >({
            path: `/account_attribute`,
            method: 'POST',
            query: query,
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
        })
    /**
     * @description This call is used to get account attributes of a user
     *
     * @tags account_info
     * @name AccountAttributeGet
     * @summary Get Account Attributes
     * @request GET:/account_attribute
     */
    accountAttributeGet = (
        query: CAPBAKAccountAttributeGetParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKAccountAttributeResponse, CAPBAKHTTPValidationError>(
            {
                path: `/account_attribute`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            },
        )
    /**
     * @description This call is used to delete account attributes of a user
     *
     * @tags account_info
     * @name AccountAttributeDelete
     * @summary Delete Account Attributes
     * @request DELETE:/account_attribute
     */
    accountAttributeDelete = (
        query: CAPBAKAccountAttributeDeleteParams,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/account_attribute`,
            method: 'DELETE',
            query: query,
            format: 'json',
            ...params,
        })
}
