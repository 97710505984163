import styled from 'styled-components'
import { colors, fontSize } from '~/assets/styleConstants'

// based on the styles from LoginRequiredPage.tsx

export const Content = styled.div`
    text-align: center;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

interface TitleTextProps {
    success?: boolean
}
export const TitleText = styled.h2<TitleTextProps>`
    font-size: ${fontSize.large_22};
    margin: 64px 0 16px;
    font-weight: 700;
    color: ${(props: TitleTextProps) =>
        props.success ? colors.successGreen : colors.captureGrey800};
`

export const Divider = styled.hr`
    border-top: 1px solid ${colors.captureGrey300};
    width: 100%;
    margin: 0;
`

export const Paragraph = styled.p`
    font-size: ${fontSize.medium_18};
    text-align: left;
`

export const Buttons = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 64px;

    & > div {
        margin: 8px;
        flex: 0 1 144px;
    }
`
