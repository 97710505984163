import styled from 'styled-components'
import { LoadingLogo, LoadingLogoWhite } from './LoadingLogo'

export const Container = styled.div`
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
`

export const LoadingPage: React.FunctionComponent<{
    colorTheme?: 'white' | 'blue'
}> = (props) => (
    <Container>
        {props.colorTheme === 'white' ? (
            <LoadingLogoWhite size={150} />
        ) : (
            <LoadingLogo size={150} />
        )}
    </Container>
)
