import {
    sessionStorageGet,
    sessionStorageSet,
} from '../../utilities/webStorage'

export type TestFlagOptions = {
    ['new-signup']: boolean
    ['delete-card']: boolean
    ['use-telenor-id-pluss']: boolean
}
export type TestFlag = keyof TestFlagOptions

const prefix = '_testFlag__'
const defaultTestFlags: TestFlagOptions = {
    ['new-signup']: false,
    ['delete-card']: false,
    ['use-telenor-id-pluss']: false,
}

/**
 * Class contains utilities for managing test flags
 * Since the set flags are often set without the interface, it is best that the service has no state
 */
export class TestFlagService {
    /**
     * Construct the storage key for a test flag
     */
    private static getStorageKey = (key: string) => `${prefix}${key}`

    /**
     * Get all test flag session storage keys
     */
    public static getStorageKeys = (): string[] => {
        return Object.keys(defaultTestFlags).map(TestFlagService.getStorageKey)
    }

    /**
     * Whether a key is part of the defined test flags
     */
    private static isDefined = (key: string): key is TestFlag =>
        key in defaultTestFlags

    /**
     * Get all test flags defined in the session
     */
    public static getDefined = (): TestFlagOptions => {
        return Object.keys(defaultTestFlags).reduce((flags, key) => {
            flags[key as TestFlag] = TestFlagService.isEnabled(key as TestFlag)
            return flags
        }, {} as TestFlagOptions)
    }

    /**
     * Add a test flag to the session
     */
    public static set = (key: TestFlag, isSet: boolean) => {
        sessionStorageSet(`${prefix}${key}`, isSet ? '1' : '0')
    }

    /**
     * Check if a particular test flag is enabled
     */
    public static isEnabled = (key: TestFlag) => {
        const storedFlag = sessionStorageGet(`${prefix}${key}`)
        if (typeof storedFlag === 'string') {
            return storedFlag === '1'
        }
        return false
    }

    /**
     * Whether there are any test flags affecting the session
     */
    public static hasActiveFlags = () => {
        const flags = TestFlagService.getDefined()
        return Object.values(flags).some((flag) => flag)
    }

    /**
     * Convert URL params to test flags
     */
    public static paramsToFlags = () => {
        const params = new URLSearchParams(window.location.search)
        params.forEach((value, key) => {
            if (TestFlagService.isDefined(key)) {
                TestFlagService.set(key, value === '1')
            }
        })
    }
}
