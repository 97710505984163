import { APP_STORE_LINK_ANDROID, APP_STORE_LINK_IOS } from '~/config/constants'
import { AppStoreBadge } from '../Icons/AppStoreBadge'
import { GooglePlayBadge } from '../Icons/GooglePlayBadge'

type AppBadgeLinkProps = {
    width: number | string
    link: string
    badge: typeof AppStoreBadge | typeof GooglePlayBadge
}

const AppBadgeLink: React.FunctionComponent<AppBadgeLinkProps> = ({
    width,
    link,
    badge: Badge,
}) => (
    <a href={link}>
        <Badge
            width={typeof width === 'number' ? `${width}px` : width}
            height={'100%'}
        />
    </a>
)

type BadgeLinkProps = {
    width?: number | string
}

export const GooglePlayBadgeLink: React.FunctionComponent<BadgeLinkProps> = (
    props,
) => (
    <AppBadgeLink
        width={props.width || '135px'}
        link={APP_STORE_LINK_ANDROID}
        badge={GooglePlayBadge}
    />
)

export const AppStoreBadgeLink: React.FunctionComponent<BadgeLinkProps> = (
    props,
) => (
    <AppBadgeLink
        width={props.width || '135px'}
        link={APP_STORE_LINK_IOS}
        badge={AppStoreBadge}
    />
)
