import { createActionCreator } from '../common/actions'

export type FileMetadata = {
    fileID: FileID
    deviceManufacturer?: string
    deviceModel?: string
    iso?: string
    aperture?: string
    exposure?: string
    focalLength?: string
}
export const FileMetadataFetchingStarted = createActionCreator<FileID>(
    'FILE_METADATA_FETCHING_STARTED',
)
export const FileMetadataWasFetched = createActionCreator<FileMetadata>(
    'FILE_METADATA_WAS_FETCHED',
)
export const FileMetadataFetchingFailed = createActionCreator<FileID>(
    'FILE_METADATA_FETCHING_FAILED',
)
