import { _ } from '~/assets/localization/util'
import type { PageDescription } from '~/utilities/navigation'
import { uuidToB64 } from '~/utilities/uuid'
import type { CarouselViewerNode } from '~/components/CarouselView/index'
import { RoutePath } from './routePath'

export const page = (url: string, pageTitle?: string): PageDescription => ({
    url,
    pageTitle,
})

export const Timeline = page(RoutePath.Timeline, _('timeline'))
export const Albums = page(RoutePath.Albums, _('albums'))
export const TrashNavigation = page(
    RoutePath.TrashNavigation,
    _('recently_deleted'),
)
export const Documents = page(RoutePath.Documents, _('documents'))
export const Settings = page(RoutePath.Settings, _('settings'))
export const SettingsPrivacy = page(RoutePath.SettingsPrivacy)
export const SettingsStorage = page(RoutePath.SettingsStorage)
export const SettingsLoggedInDevices = page(RoutePath.SettingsLoggedInDevices)
export const SelectProfilePicture = page(RoutePath.SelectProfilePicture)
export const Takeout = page(RoutePath.SettingsTakeout)

// Regarding *B64-pages:
// Routes with an albumUUID in the URL are using the Base64-packed version of the uuid.
// The page-functions handle the transformation themselves (hence the uuidToB64), but B64-suffixed versions are
// provided for use by the pages where the B64-packed version of the UUID is present (as a param from the Router).
// This to avoid having to decode the uuid just to have it encoded again
// (even though the unpacked UUIDs are to be used everywhere else internally)

export const Album = (albumID: JobID) =>
    page(RoutePath.getLoggedInAlbumPath(uuidToB64(albumID)))
export const AlbumB64 = (albumIDB64: string) =>
    page(RoutePath.getLoggedInAlbumPath(albumIDB64))
export const CopyAlbumRequireLogin = (albumID: JobID) =>
    page(RoutePath.getCopyAlbumLoginPath(uuidToB64(albumID)))
export const CopyAlbumFileRequireLogin = (albumID: JobID, fileID: FileID) =>
    page(RoutePath.getCopyAlbumFileLoginPath(uuidToB64(albumID), fileID))
export const AlbumNotLoggedIn = (albumID: JobID) =>
    page(RoutePath.getNotLoggedInAlbumPath(uuidToB64(albumID)))
type GoToAfter = string // Indicator for where to go after album-creation-cancel. 't' will return to Timeline. All other cases returns to AlbumList.
export const EditAlbum = (albumID: JobID, goToAfter: GoToAfter = 'a') =>
    page(RoutePath.getEditAlbumPath(uuidToB64(albumID), goToAfter))
export const AfterAbortedAlbumEdit = (goToAfter: GoToAfter) =>
    goToAfter === 't' ? Timeline : Albums
export const CreateAlbumRequireLogin = (albumID: JobID) =>
    page(RoutePath.getCreateAlbumLoginPath(uuidToB64(albumID)))
export const SubscribeToAlbumRequireLogin = (albumID: JobID) =>
    page(RoutePath.getSubscribeToAlbumLoginPath(uuidToB64(albumID)))

export const AddMorePhotosToAlbum = (albumID: JobID) =>
    page(RoutePath.morePhotosAlbum(uuidToB64(albumID)))
export const CreateAlbumAddPhotos = (albumID: JobID) =>
    page(RoutePath.morePhotosCreateAlbum(uuidToB64(albumID)))
export const Share = (shareID: JobID) =>
    page(RoutePath.getShareReceiverPath(shareID))
export const CopyShareRequireLogin = (shareID: JobID) =>
    page(RoutePath.getCopyShareLoginPath(shareID))
export const DownloadShareContent = (shareID: JobID) =>
    page(RoutePath.getDownloadSharePath(shareID))

// Shared
export const AlbumFileComments = (albumID: JobID, fileID: FileID) =>
    page(RoutePath.getAlbumFileCommentsPath(uuidToB64(albumID), fileID))
export const AlbumImageAsShare = (albumID: JobID, fileID: FileID) =>
    page(RoutePath.getAlbumImageAsSharePath(uuidToB64(albumID), fileID))

export const AlbumCarousel = (
    albumID: JobID,
    fileID: FileID,
    shouldAutoPlay?: boolean,
) =>
    page(
        RoutePath.getAlbumCarouselPath(uuidToB64(albumID), fileID) +
            `${shouldAutoPlay ? '#autoPlay' : ''}`,
    )
export const AlbumCarouselMatcher = (
    albumIDPattern: string,
    fileIDPattern: string,
    shouldAutoPlay?: boolean,
) =>
    page(
        RoutePath.getAlbumCarouselPath(albumIDPattern, fileIDPattern) +
            `${shouldAutoPlay ? '#autoPlay' : ''}`,
    )

export const TimelineCarousel = (fileID: FileID, shouldAutoPlay?: boolean) =>
    page(
        RoutePath.getTimelineCarouselPath(fileID) +
            `${shouldAutoPlay ? '#autoPlay' : ''}`,
    )

export const CarouselPageForViewerNode = (viewerNode: CarouselViewerNode) => {
    if (
        viewerNode.type === 'albumPrivate' ||
        viewerNode.type === 'albumPublic' ||
        viewerNode.type === 'share'
    ) {
        return AlbumCarousel(viewerNode.file.jobID, viewerNode.file.fileID)
    } else {
        return TimelineCarousel(viewerNode.file.fileID)
    }
}
