import type { FileWithGrouping } from '~/state/files/selectors'

export type FileInfoForSize = FileWithGrouping<{ size: number }>
export const getFileSize = (file: FileInfoForSize) => {
    let val = file.size

    if (file.livePhotoFile) {
        val += file.livePhotoFile.size
    }
    if (file.burstFiles) {
        val += file.burstFiles.reduce(
            (accBurst, currBurst) => accBurst + currBurst.size,
            0,
        )
    }
    return val
}

export const getTotalSize = (fileInfos: FileInfoForSize[]): number => {
    return fileInfos.reduce((acc, info) => acc + getFileSize(info), 0)
}
