import type { SVGProps } from 'react'
const SvgAddToAlbum = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill={props.color ?? 'currentColor'}
            fillRule="evenodd"
            d="M8.788 16.263a10.268 10.268 0 0 0-4.05-.113c-.575.1-1.155.25-1.738.45V15a1 1 0 1 0-2 0v3.15c0 .383.163.675.488.875.325.2.662.208 1.012.025a9.05 9.05 0 0 1 1.938-.775A7.836 7.836 0 0 1 6.5 18c.75 0 1.483.096 2.2.288.717.191 1.4.479 2.05.862.183.1.38.18.588.238.208.058.429.087.662.087.233 0 .454-.03.662-.088.209-.058.405-.137.588-.237a8.181 8.181 0 0 1 2.05-.862A8.477 8.477 0 0 1 17.5 18c.7 0 1.387.092 2.063.275a9.05 9.05 0 0 1 1.937.775c.35.183.688.175 1.012-.025A.966.966 0 0 0 23 18.15V6.1c0-.183-.046-.358-.137-.525a.863.863 0 0 0-.413-.375c-.767-.4-1.567-.7-2.4-.9-.833-.2-1.683-.3-2.55-.3A10.78 10.78 0 0 0 12 5.5 10.78 10.78 0 0 0 6.5 4c-.867 0-1.717.1-2.55.3-.833.2-1.633.5-2.4.9a.863.863 0 0 0-.413.375A1.074 1.074 0 0 0 1 6.1V8a1 1 0 1 0 2 0V6.7a8.665 8.665 0 0 1 1.713-.525 9.218 9.218 0 0 1 4.112.125c.767.2 1.492.5 2.175.9v9.85c-.733-.35-1.47-.613-2.213-.788m6.425 0c-.742.174-1.48.437-2.213.787V7.2c.683-.4 1.408-.7 2.175-.9a9.175 9.175 0 0 1 4.113-.125A8.664 8.664 0 0 1 21 6.7v9.9c-.583-.2-1.163-.35-1.738-.45a10.268 10.268 0 0 0-4.05.113"
            clipRule="evenodd"
        />
        <path
            fill={props.color ?? 'currentColor'}
            d="M5.075 12.488H1a1 1 0 1 1 0-2h4.075l-.9-.9a.916.916 0 0 1-.287-.7c.008-.267.112-.5.312-.7.2-.183.433-.28.7-.288.267-.008.5.088.7.288l2.6 2.6c.2.2.3.433.3.7 0 .267-.1.5-.3.7l-2.6 2.6a.977.977 0 0 1-.687.287.93.93 0 0 1-.713-.287.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7l.875-.9"
        />
    </svg>
)
export default SvgAddToAlbum
