import type { SVGProps } from 'react'
const SvgOffline = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill={props.color ?? 'currentColor'}
            d="m19.05 21.9-8.7-8.75a8.848 8.848 0 0 0-1.488.475c-.475.2-.92.442-1.337.725-.35.233-.738.354-1.163.363a1.362 1.362 0 0 1-1.062-.438 1.36 1.36 0 0 1-.413-1.087c.025-.425.205-.763.538-1.013.383-.283.787-.542 1.212-.775.425-.233.863-.45 1.313-.65L5.7 8.5c-.433.233-.854.48-1.263.738-.408.258-.804.537-1.187.837-.333.267-.713.4-1.138.4a1.4 1.4 0 0 1-1.062-.45c-.3-.3-.442-.658-.425-1.075.017-.417.192-.758.525-1.025.367-.3.742-.587 1.125-.862s.792-.53 1.225-.763L2.1 4.9a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l16.975 16.975c.2.2.3.438.3.713 0 .275-.1.512-.3.712-.2.183-.437.28-.713.288a.93.93 0 0 1-.712-.288M12 21c-.7 0-1.292-.246-1.775-.737A2.426 2.426 0 0 1 9.5 18.5c0-.7.242-1.292.725-1.775C10.708 16.242 11.3 16 12 16s1.292.242 1.775.725c.483.483.725 1.075.725 1.775 0 .683-.242 1.27-.725 1.763A2.393 2.393 0 0 1 12 21m6.825-6.875a1.24 1.24 0 0 1-.937.388 1.33 1.33 0 0 1-.938-.413l-.238-.237-.262-.263-3.6-3.6c1.1.1 2.138.342 3.113.725.975.383 1.87.892 2.687 1.525.3.233.47.538.513.913.041.375-.071.695-.338.962m4.125-4.1c-.283.3-.633.454-1.05.463a1.697 1.697 0 0 1-1.125-.388 13.692 13.692 0 0 0-4.037-2.287A13.772 13.772 0 0 0 12 7a13.208 13.208 0 0 0-2 .15L7.45 4.6c.733-.2 1.48-.35 2.237-.45.759-.1 1.53-.15 2.313-.15 2.083 0 4.046.346 5.887 1.038A17.157 17.157 0 0 1 22.85 7.9c.333.283.508.633.525 1.05.017.417-.125.775-.425 1.075"
        />
    </svg>
)
export default SvgOffline
