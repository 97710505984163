import { _ } from '~/assets/localization/util'
import { bytesToSize } from '~/utilities/fileSizeFormatting'

export const StorageInfoText: React.FunctionComponent<{
    totalStorage: number
    usedStorage: number
    currentPlanSize?: number
}> = (props) => {
    if (props.totalStorage === Infinity) {
        return (
            <div>
                {bytesToSize(props.usedStorage)} {_('used_storage')}
            </div>
        )
    }

    const breakDownText =
        props.currentPlanSize &&
        `${bytesToSize(props.currentPlanSize, 0)} (${_('current_plan')}) + ` +
            `${bytesToSize(props.totalStorage - props.currentPlanSize, 0)} (${_(
                'other_sources',
            )})`

    const storageInfoText = _('storage_used_out_of__format')
        .split(/(%\w+%)/)
        .map((t) => {
            if (t === '%storage_used%') {
                return (
                    <b key="storage_used">{bytesToSize(props.usedStorage)}</b>
                )
            }
            if (t === '%storage_total%') {
                return (
                    <b key="storage_total">{bytesToSize(props.totalStorage)}</b>
                )
            }
            return t
        })

    return (
        <>
            <div>{storageInfoText}</div>
            {breakDownText && <div>{breakDownText}</div>}
        </>
    )
}
