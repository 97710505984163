/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    CAPBAKHTTPValidationError,
    CAPBAKNamePostParams,
} from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class Name<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * @description This api call sets and clears the name of the user.
     *
     * @tags account_info
     * @name NamePost
     * @summary Set User Name
     * @request POST:/name
     */
    namePost = (query: CAPBAKNamePostParams, params: RequestParams = {}) =>
        this.request<any, CAPBAKHTTPValidationError>({
            path: `/name`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
}
