import { getCurrentLocale } from '~/config/constants'
import { _ } from '~/assets/localization/util'
import type { TimeMeasurement } from '~/utilities/dateOperations'
import { getTimeSince, timeIntervals } from '~/utilities/dateOperations'

/**
 * Returns formatted date with day, month and year
 * Jun 21, 2024
 */
export const localizedDateStringDefault = (date: Date): string =>
    date.toLocaleDateString(getCurrentLocale().replace('_', '-'), {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    })

/**
 * Returns short formatted date with day, month and year
 * 6/21/2024
 */
export const localizedDateStringShort = (date: Date): string =>
    date.toLocaleDateString(getCurrentLocale().replace('_', '-'))

/**
 * Returns formatted date with day, month and year
 * June 21, 2024
 */
export const localizedDateStringLong = (date: Date): string =>
    date.toLocaleDateString(getCurrentLocale().replace('_', '-'), {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    })

/**
 * Returns formatted date with weekday day, month and year
 * Friday, June 21, 2024
 */
export const localizedDateStringWithWeekday = (date: Date): string =>
    date.toLocaleDateString(getCurrentLocale().replace('_', '-'), {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    })

/**
 * Returns formatted time with weekday
 * Friday 9:06:12 AM
 */
export const localizedTimeStringWithWeekday = (date: Date): string =>
    date.toLocaleTimeString(getCurrentLocale().replace('_', '-'), {
        weekday: 'long',
    })

/**
 * Returns formatted time padded to 2 digits HH:mm:ss
 * 09:06:12 AM
 */
export const localizedTimeStringLong = (date: Date): string =>
    date.toLocaleTimeString(getCurrentLocale().replace('_', '-'), {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    })

/**
 * Returns formatted time padded to 2 digits HH:mm
 * 09:06 AM
 */
export const localizedTimeStringShort = (date: Date): string =>
    date.toLocaleTimeString(getCurrentLocale().replace('_', '-'), {
        hour: '2-digit',
        minute: '2-digit',
    })

export const getFilesDateRangeString = (
    start: Date,
    end: Date,
    now?: Date,
): string => {
    const locale = getCurrentLocale().replace('_', '-')

    // hide year if date range is within the current year
    const hideCurrentYear =
        end.getFullYear() === (now || new Date()).getFullYear()

    let firstHalfOptions: Intl.DateTimeFormatOptions
    let secondHalfOptions: Intl.DateTimeFormatOptions
    if (start.getFullYear() !== end.getFullYear()) {
        firstHalfOptions = { month: 'short', day: 'numeric', year: 'numeric' }
        secondHalfOptions = { month: 'short', day: 'numeric', year: 'numeric' }
    } else if (
        start.getMonth() !== end.getMonth() ||
        start.getDate() !== end.getDate()
    ) {
        // same year, different months/days
        firstHalfOptions = { month: 'short', day: 'numeric' }
        secondHalfOptions = {
            month: 'short',
            day: 'numeric',
            year: hideCurrentYear ? undefined : 'numeric',
        }
    } else {
        // same day
        return start.toLocaleDateString(locale, {
            month: 'short',
            day: 'numeric',
            year: hideCurrentYear ? undefined : 'numeric',
        })
    }

    const stringElements = [
        start.toLocaleDateString(locale, firstHalfOptions),
        end.toLocaleDateString(locale, secondHalfOptions),
    ]

    return stringElements.join(' - ')
}

type SinceType = {
    singular: string
    plural: string
}

const timeSinceStrings: Record<TimeMeasurement, SinceType> = {
    year: {
        singular: _('year_ago__format'),
        plural: _('years_ago__format'),
    },
    month: {
        singular: _('month_ago__format'),
        plural: _('months_ago__format'),
    },
    day: {
        singular: _('day_ago__format'),
        plural: _('days_ago__format'),
    },
    hour: {
        singular: _('hour_ago__format'),
        plural: _('hours_ago__format'),
    },
    minute: {
        singular: _('minute_ago__format'),
        plural: _('minutes_ago__format'),
    },
}

const getTimeSinceString = (secondsSince: number): string => {
    const timeMeasurementsOrdered = (
        Object.keys(timeIntervals) as TimeMeasurement[]
    ).sort((ti1, ti2) => timeIntervals[ti2] - timeIntervals[ti1])

    let timeSinceResult: string = _('just_now')
    for (const unit of timeMeasurementsOrdered) {
        const diff = Math.floor(secondsSince / timeIntervals[unit])

        if (diff > 0) {
            const { singular, plural } = timeSinceStrings[unit]
            const rawString = diff > 1 ? plural : singular
            timeSinceResult = rawString.split('%d').join(diff.toString())
            break
        }
    }
    return timeSinceResult
}

export const TimeSinceString: React.FunctionComponent<{
    then: Date
    now: Date
}> = (props) => (
    <span>{getTimeSinceString(getTimeSince(props.then, props.now))}</span>
)
