import type { ResumableState } from './resumeSlice'

export const getHasResumableCopyShareToTimeline = (state: {
    resume: ResumableState
}) => {
    const resumeCopyToTimeline = state.resume.copyShareToTimeline
    return resumeCopyToTimeline !== null
}

export const getResumableCopyShareToTimeline = (state: {
    resume: ResumableState
}) => state.resume.copyShareToTimeline
