import type { SVGProps } from 'react'
const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill={props.color ?? 'currentColor'}
            d="m9.55 15.15 8.475-8.475c.2-.2.438-.3.713-.3.274 0 .512.1.712.3.2.2.3.438.3.713 0 .274-.1.512-.3.712l-9.2 9.2c-.2.2-.433.3-.7.3a.96.96 0 0 1-.7-.3L4.55 13a.93.93 0 0 1-.288-.713 1.02 1.02 0 0 1 .313-.712c.2-.2.437-.3.712-.3.275 0 .513.1.713.3l3.55 3.575"
        />
    </svg>
)
export default SvgCheck
