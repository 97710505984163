import type { Merge } from 'type-fest'
import { AlbumSortOrderChanged } from '../album/actions'
import type { AlbumViewMode } from '../album/selectors'
import type { ConfigurableAlbumDetails } from '../albumList/reducer'
import type { Action } from '../common/actions'
import { isType } from '../common/actions'
import {
    AlbumViewModeChanged,
    AlbumViewModeFetched,
    FileWasSetAsCoverPhoto,
    JobChangesWasFetched,
    JobInfoChangeDetected,
    UnableToFetchJobChanges,
} from '../job/actions'
import {
    ChangeJobPropertySucceeded,
    ErrorWhenFetchingJobInfo,
    FetchedJobInfo,
    JobListWasFetched,
    JobNotFound,
    JobPasswordProvided,
    JobRequiresLoggedInUser,
    JobRequiresPassword,
    JobUserLoggedIn,
    JobWasDeleted,
    StartedFetchingJobInfo,
} from './actions'

export type JobInfoState = DictionaryOf<JobInfoElement>

// Enumeration of different states a job can have in the system
export enum JobInfoStatus {
    NOT_STARTED = 'NOT_STARTED', // Never tried to fetch any jobInfo
    PENDING = 'PENDING', // Currently in progress of fetching jobInfo
    NOT_FOUND = 'NOT_FOUND', // Job does not exist (or is unavailable for other reasons)
    PASSWORD_REQUIRED = 'PASSWORD_REQUIRED', // Job requires password (and the one provided is not correct)
    PASSWORD_PROVIDED = 'PASSWORD_PROVIDED', // User has provided password and the new password should be retried
    LOGIN_REQUIRED = 'LOGIN_REQUIRED', // Job requires user to be logged in
    FETCHED = 'FETCHED', // Ideal end-state: jobInfo is fully fetched
    PARTIALLY_FETCHED = 'PARTIALLY_FETCHED', // The stored jobInfo is incomplete/inaccurate and should be refetched
    FETCH_FAILED = 'FETCH_FAILED', // Some other thing went wrong when fetching the job (network error, unknown responses etc) - given up.
    RECENTLY_DELETED = 'RECENTLY_DELETED', // Just deleted album. For the JobSyncer to ignore it
}

export type JobInfoType = 'timeline' | 'story' | 'share'
type BaseJobInfo<TJobType extends JobInfoType, TInfo = object> = Merge<
    {
        type: TJobType
        ctime?: number
        mtime: number
        owner: UserID
    },
    TInfo
>

export type TimelineJobInfo = BaseJobInfo<'timeline'>
export type ShareJobInfo = BaseJobInfo<'share'>
export type StoryJobInfo = BaseJobInfo<
    'story',
    ConfigurableAlbumDetails & {
        last_update: number
        coverPhoto?: FileID
        size: number
        has_heic: boolean
    }
>

export type JobInfo = TimelineJobInfo | ShareJobInfo | StoryJobInfo

export type JobInfoElement<TJobInfo extends JobInfo = JobInfo> = {
    jobID: JobID
    status: JobInfoStatus
    providedPassword?: string
    lastSeenSerial?: number // -1 for broken job
    jobInfo?: TJobInfo
    viewMode?: AlbumViewMode
}

const getDefaultElement = (jobID: JobID): JobInfoElement => {
    return {
        jobID,
        status: JobInfoStatus.NOT_STARTED,
    }
}

function newStateWithElementChanges(
    state: JobInfoState,
    elementId: JobID,
    changes: Partial<JobInfoElement>,
): JobInfoState {
    return {
        ...state,
        [elementId]: {
            ...(state[elementId] || getDefaultElement(elementId)),
            ...changes,
        },
    }
}

const newStateWithJobInfoChanges = (
    state: JobInfoState,
    elementId: JobID,
    changes: Partial<JobInfo>,
): JobInfoState => {
    const { jobInfo } = state[elementId]
    if (jobInfo) {
        const { type: _, ...rest } = changes
        return newStateWithElementChanges(state, elementId, {
            jobInfo: {
                ...jobInfo,
                ...rest,
            },
        })
    }

    return state
}

const initialState: JobInfoState = {}

export const jobInfoReducer = (
    state: JobInfoState = initialState,
    action: Action,
): JobInfoState => {
    if (isType(action, StartedFetchingJobInfo)) {
        return newStateWithElementChanges(state, action.payload, {
            status: JobInfoStatus.PENDING,
        })
    }

    if (isType(action, JobNotFound)) {
        return newStateWithElementChanges(state, action.payload, {
            status: JobInfoStatus.NOT_FOUND,
        })
    }
    if (isType(action, JobRequiresPassword)) {
        return newStateWithElementChanges(state, action.payload, {
            status: JobInfoStatus.PASSWORD_REQUIRED,
        })
    }
    if (isType(action, JobRequiresLoggedInUser)) {
        return newStateWithElementChanges(state, action.payload, {
            status: JobInfoStatus.LOGIN_REQUIRED,
        })
    }
    if (isType(action, JobUserLoggedIn)) {
        return newStateWithElementChanges(state, action.payload, {
            status: JobInfoStatus.NOT_STARTED,
        })
    }
    if (isType(action, JobPasswordProvided)) {
        return newStateWithElementChanges(state, action.payload.job, {
            status: JobInfoStatus.PASSWORD_PROVIDED,
            providedPassword: action.payload.password,
        })
    }

    if (isType(action, FetchedJobInfo)) {
        return newStateWithElementChanges(state, action.payload.job, {
            status: JobInfoStatus.FETCHED,
            jobInfo: action.payload.info,
            viewMode:
                state[action.payload.job] && state[action.payload.job].viewMode,
        })
    }

    if (isType(action, JobListWasFetched)) {
        const newState: JobInfoState = {}
        action.payload.forEach((infoRef) => {
            newState[infoRef.job] = {
                ...state[infoRef.job],
                jobID: infoRef.job,
                status: JobInfoStatus.PARTIALLY_FETCHED, // info from job list is incomplete, fetch full info from infosyncer later
                jobInfo: infoRef.info,
            }
        })

        return newState
    }

    if (isType(action, ErrorWhenFetchingJobInfo)) {
        return newStateWithElementChanges(state, action.payload, {
            status: JobInfoStatus.FETCH_FAILED,
        })
    }

    if (isType(action, JobInfoChangeDetected)) {
        const stateJob = state[action.payload.jobID]
        if (
            stateJob &&
            stateJob.jobInfo &&
            'last_update' in stateJob.jobInfo &&
            stateJob.jobInfo.last_update < action.payload.eventID
        ) {
            return newStateWithElementChanges(state, action.payload.jobID, {
                status: JobInfoStatus.PARTIALLY_FETCHED,
            })
        }
    }

    if (isType(action, JobWasDeleted)) {
        const { [action.payload]: _omit, ...s } = state
        return newStateWithElementChanges(s, action.payload, {
            status: JobInfoStatus.RECENTLY_DELETED,
        })
    }

    if (isType(action, FileWasSetAsCoverPhoto)) {
        return newStateWithJobInfoChanges(state, action.payload.jobID, {
            coverPhoto: action.payload.fileID,
        })
    }

    if (
        isType(action, AlbumViewModeChanged) ||
        isType(action, AlbumViewModeFetched)
    ) {
        return newStateWithElementChanges(state, action.payload.jobID, {
            viewMode: action.payload.viewMode,
        })
    }

    if (isType(action, AlbumSortOrderChanged)) {
        return newStateWithJobInfoChanges(state, action.payload.jobID, {
            sort_order: action.payload.sortOrder,
        })
    }

    if (isType(action, JobChangesWasFetched)) {
        return newStateWithElementChanges(state, action.payload.jobID, {
            lastSeenSerial: action.payload.lastSerial,
        })
    }

    if (isType(action, UnableToFetchJobChanges)) {
        return newStateWithElementChanges(state, action.payload, {
            lastSeenSerial: -1,
            status: JobInfoStatus.PARTIALLY_FETCHED,
        })
    }

    if (isType(action, ChangeJobPropertySucceeded)) {
        const { jobInfo } = state[action.payload.job]
        const { property } = action.payload
        if (jobInfo) {
            return newStateWithJobInfoChanges(state, action.payload.job, {
                [property]: action.payload.value,
            })
        }
    }

    return state
}

export const jobInfoReducerMapObj = {
    jobInfo: jobInfoReducer,
}
export type StateWithJobInfo = StateOfReducerMapObj<typeof jobInfoReducerMapObj>
