import styled from 'styled-components'
import { _ } from '~/assets/localization/util'
import { PRODUCT_NAME } from '~/config/constants'
import { colors, mediaQueries } from '~/assets/styleConstants'
import { FilledTextButton } from '../Common/IconTextButton'

const DescriptionText = styled.div`
    margin-bottom: 24px;
    ${mediaQueries.mobile} {
        margin-bottom: 16px;
    }
`
const OptionsWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    > div {
        width: 194px;
        box-sizing: border-box;
    }
    ${mediaQueries.mobile} {
        flex-direction: column;
        > div {
            width: 100%;
            margin: 4px 0;
        }
    }
`

type Props = {
    onClickSignIn: () => void
}

export const RequireUserInfoOptions: React.FC<Props> = (props) => (
    <>
        <DescriptionText>
            {_('provide_name_description__format').replace(
                '%product_name%',
                PRODUCT_NAME,
            )}
        </DescriptionText>
        <OptionsWrapper>
            <FilledTextButton
                data-cy="loginBtn"
                onClick={props.onClickSignIn}
                text={_('register_or_sign_in')}
                hoverColor={colors.captureBlue}
                hoverFillColor={colors.captureBlue100}
            />
        </OptionsWrapper>
    </>
)
