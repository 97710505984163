import type { SVGProps } from 'react'
const SvgBurstPhoto = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill={props.color ?? 'currentColor'}
        viewBox="0 0 24 24"
        {...props}>
        <path
            fill="currentColor"
            d="M1 18V6c0-.283.096-.52.288-.713A.968.968 0 0 1 2 5c.283 0 .52.096.712.287.192.192.288.43.288.713v12c0 .283-.096.52-.288.712A.967.967 0 0 1 2 19a.967.967 0 0 1-.712-.288A.968.968 0 0 1 1 18m4 0V6c0-.283.096-.52.287-.713A.968.968 0 0 1 6 5c.283 0 .52.096.713.287.191.192.287.43.287.713v12c0 .283-.096.52-.287.712A.967.967 0 0 1 6 19a.967.967 0 0 1-.713-.288A.968.968 0 0 1 5 18m6 1c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 9 17V7c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 11 5h10c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v10c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 21 19zm0-2h10V7H11zm4.5-3-1-1.325a.445.445 0 0 0-.4-.187.51.51 0 0 0-.4.212l-1.125 1.5a.427.427 0 0 0-.037.525A.51.51 0 0 0 13 15h6c.2 0 .35-.092.45-.275a.44.44 0 0 0-.05-.525l-1.6-2.175a.475.475 0 0 0-.4-.2c-.167 0-.3.067-.4.2z"
        />
    </svg>
)
export default SvgBurstPhoto
