import styled from 'styled-components'
import SvgClose from '@capture/capture-components/src/icons/Close'
import SvgMinimize from '@capture/capture-components/src/icons/Minimize'
import { colors, fontSize } from '~/assets/styleConstants'
import { EmptyIcon } from '../Icons/EmptyIcon'
import type { ButtonProps } from './Button'
import { IconTextButton } from './IconTextButton'
import { OverlayModal } from './OverlayModal'

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: ${(props: {
        closeButtonHidden: boolean
        minButton: boolean
    }) =>
        props.minButton
            ? 'flex-start'
            : props.closeButtonHidden
              ? 'flex-end'
              : 'space-between'};
    font-size: ${fontSize.small_12};
    font-weight: bold;
`

export const ItemOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: ${(props: { backgroundRGBA?: string }) =>
        props.backgroundRGBA
            ? `${props.backgroundRGBA}`
            : colors.blackSemitransparent};
`

type Props = {
    onClose: () => void
    /** Upper left corner button. Default icon is an 'X'. */
    cancelButton?: ButtonProps
    /** Upper right corner button. Default icon is an '__'. */
    minimizeButton?: ButtonProps
    /** Upper right corner button. No default icon. */
    confirmButton?: ButtonProps
    /** Displayed above the header elements. */
    title?: string
    /** Header elements will not be part of the scrollable overflow area. */
    header?: React.ReactNode
    /** Footer elements will not be part of the scrollable overflow area. */
    footer?: React.ReactNode
    /** The Overlay will not close on click outside. */
    ignoreCloseOnClickOutside?: boolean
    children?: React.ReactNode
}

export const OptionsOverlay = (props: Props) => {
    const overlayIconSize = 20

    const closeButton = props.cancelButton && (
        <IconTextButton
            onClick={props.cancelButton.onClick}
            text={props.cancelButton.text}
            icon={props.cancelButton.icon || SvgClose}
            iconSize={overlayIconSize}
        />
    )
    const minimizeButton = props.minimizeButton && (
        <IconTextButton
            onClick={props.minimizeButton.onClick}
            text={props.minimizeButton.text}
            icon={props.minimizeButton.icon || SvgMinimize}
            iconSize={overlayIconSize}
        />
    )
    const confirmButton = props.confirmButton && (
        <IconTextButton
            onClick={props.confirmButton.onClick}
            text={props.confirmButton.text}
            icon={props.confirmButton.icon || EmptyIcon}
            color={
                props.confirmButton.isDisabled === false
                    ? colors.captureBlue
                    : colors.captureGrey400
            }
            isDisabled={props.confirmButton.isDisabled}
            iconSize={overlayIconSize}
        />
    )
    const title = props.title && (
        <div
            style={{
                marginTop: closeButton || confirmButton ? 24 : 0,
                paddingBottom: 4,
            }}>
            {props.title}
        </div>
    )
    const headerElements = (
        <div>
            <ButtonsWrapper
                closeButtonHidden={!props.cancelButton}
                minButton={!!props.minimizeButton}>
                {closeButton}
                {minimizeButton}
                {confirmButton}
            </ButtonsWrapper>
            {title}
            {props.header}
        </div>
    )

    return (
        <OverlayModal
            onClose={props.onClose}
            headerElements={headerElements}
            footerElements={props.footer}
            ignoreCloseOnClickOutside={props.ignoreCloseOnClickOutside}>
            {props.children}
        </OverlayModal>
    )
}
