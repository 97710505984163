import type { StateWithLastSeenElement } from './reducer'

export const getLastSeenElementForJob = (
    state: StateWithLastSeenElement,
    jobID: JobID,
): FileID | undefined => state.lastSeenElement.activeFile[jobID]

export const getIdOfLastSeenAlbum = (
    state: StateWithLastSeenElement,
): JobID | undefined => state.lastSeenElement.currentAlbum
