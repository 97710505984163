import styled from 'styled-components'
import { isMobileDevice } from '~/utilities/device'
import type { Icon } from '../Icons'
import { CheckFilledIcon } from '../Icons/CheckIcon'
import { CircleIcon } from '../Icons/CircleIcon'
import { IconButton } from './IconTextButton'

const SelectIndicator = styled.div`
    position: relative;
    cursor: pointer;

    svg {
        opacity: ${(props: { opacity: number }) => props.opacity};
    }
    ${!isMobileDevice.any()
        ? `
        &:hover {
            svg {
                opacity: 1;
            }
            .item-select-indicator {
                visibility: visible;
            }
        }
    `
        : ''}
`
const SelectPlaceholder = styled.div`
    position: absolute;
`
const CheckMark = styled.div.attrs({
    className: 'item-select-indicator',
})`
    visibility: ${(props: { selected: boolean }) =>
        props.selected ? 'visible' : 'hidden'};
`

type SingleProps = {
    onClick: (event: React.SyntheticEvent<HTMLDivElement>) => void
    isSelected: boolean
    showPlaceholder: boolean
    iconSize?: number
    checkmarkColor?: string
    fillColor?: string
    borderColor?: string
    cyKey?: string
}

export const SelectionIndicator: React.FunctionComponent<SingleProps> = (
    props,
) => {
    const icon: Icon = () => (
        <CheckFilledIcon
            size={props.iconSize || 24}
            color={props.fillColor}
            stroke={props.checkmarkColor}
        />
    )
    const placeholder = props.showPlaceholder && (
        <SelectPlaceholder
            onClick={props.onClick}
            onKeyUp={props.onClick}
            role="button"
            tabIndex={0}>
            <CircleIcon
                size={props.iconSize || 24}
                color={props.borderColor || props.checkmarkColor}
            />
        </SelectPlaceholder>
    )

    return (
        <SelectIndicator
            opacity={props.isSelected || props.showPlaceholder ? 1 : 0.5}>
            {placeholder}
            <CheckMark
                selected={props.isSelected}
                data-is-selected={props.isSelected}>
                <IconButton
                    data-cy={props.cyKey}
                    onClick={props.onClick}
                    icon={icon}
                />
            </CheckMark>
        </SelectIndicator>
    )
}
