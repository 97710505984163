import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import SvgCastStop from '@capture/capture-components/src/icons/CastStop'
import { _ } from '~/assets/localization/util'
import { Pages } from '~/routing'
import type { WithRouterProps } from '~/utilities/navigation'
import { withRouter } from '~/utilities/navigation'
import type { CastingDetail } from '~/state/carouselViewer/reducer'
import { getCurrentViewerNode } from '~/state/carouselViewer/selectors'
import { getCastCommander } from '~/API/syncers/CastSyncer'
import { getCastDetails } from '~/state/carouselViewer/pureSelectors'
import { colors } from '~/assets/styleConstants'
import { IconButton } from '../Common/IconTextButton'
import type { CarouselViewerNode } from '.'

const ThePill = styled.div`
    position: fixed;
    bottom: 26px;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 252px;
    transform: translateX(-50%);

    color: white;
    background: rgba(0, 0, 0, 0.55);
    border-radius: 11px;
    padding: 12px;

    & > div:first-child {
        cursor: pointer;
        width: 200px;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: -12px;
        padding: 12px;
    }
`
type StateProps = {
    currentCarouselNode: CarouselViewerNode | undefined
    castingDetails?: CastingDetail
}
type Props = StateProps & { children?: React.ReactNode } & WithRouterProps
export class _CastPill extends React.Component<Props> {
    private enterCarousel = () => {
        if (this.props.currentCarouselNode) {
            this.props.navigate(
                Pages.CarouselPageForViewerNode(this.props.currentCarouselNode)
                    .url,
            )
        }
    }

    private stopCasting = () => {
        getCastCommander().endCastSession()
    }

    public render() {
        if (!this.props.castingDetails) {
            return null
        }

        return (
            <>
                {this.props.children}
                <ThePill>
                    <div
                        onClick={this.enterCarousel}
                        onKeyUp={this.enterCarousel}
                        role="button"
                        tabIndex={0}>
                        {_('casting_to').replace(
                            '%s',
                            this.props.castingDetails.deviceName,
                        )}
                    </div>
                    <IconButton
                        onClick={this.stopCasting}
                        icon={SvgCastStop}
                        color={colors.white}
                    />
                </ThePill>
            </>
        )
    }
}

const mapStateToProps = (
    state: StateOfSelector<typeof getCurrentViewerNode> &
        StateOfSelector<typeof getCastDetails>,
): StateProps => ({
    currentCarouselNode: getCurrentViewerNode(state),
    castingDetails: getCastDetails(state),
})

export const CastPill = withRouter(connect(mapStateToProps, null)(_CastPill))
