import type { FunctionComponent, ReactNode } from 'react'
import { useCallback, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import SvgOverflowMenu from '@capture/capture-components/src/icons/OverflowMenu'
import { getViewportWidth } from '~/state/viewMode/selectors'
import { colors, fontSize } from '~/assets/styleConstants'
import type { Icon } from '../Icons'
import type { ButtonProps, OverflowButtonProps } from './Button'
import { withClickOutsideHandling } from './HandleClickOutsideHOC'
import type { SVGIcon } from './IconTextButton'
import { IconButton } from './IconTextButton'
import { MethodProvider } from './MethodProvider'
import type { Position } from './Positioning'
import { ButtonWithExpandingContent } from './Positioning'
import { ReadonlyTooltipOnMenu } from './ReadonlyTooltip'

const MenuWrapper = withClickOutsideHandling(styled.div`
    position: absolute;
    ${(props: { position: Position }) =>
        props.position.v === 'bottom' ? 'top: -24px' : 'bottom: -24px'};
    ${(props) => (props.position.h === 'left' ? 'left: 0' : 'right: 0')};
    background: white;
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.3) 1px 2px 5px;
    padding: 8px 0;
`)

type SubMenuWrapperProps = {
    mainIndex: number
    isPositionedLeft: boolean
}

const SubMenuWrapper = styled.div`
    position: absolute;
    top: ${(props: SubMenuWrapperProps) => props.mainIndex * 32 + 8}px;
    box-shadow: ${(props: SubMenuWrapperProps) =>
        props.isPositionedLeft
            ? 'rgba(0, 0, 0, 0.3) 1px 2px 5px'
            : 'rgba(0, 0, 0, 0.3) -1px 2px 5px'};
`

const SubMenuOptionWrapper = styled.div`
    background-color: white;
    display: block;
    width: 200px;
    overflow: auto;
`

const activeColor = colors.captureGrey800
const inactiveColor = colors.captureGrey400

const OptionButton = styled.div<Pick<ButtonProps, 'isDisabled'>>`
    // button looks disabled
    cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
    color: ${(props) => (props.isDisabled ? inactiveColor : activeColor)};

    // button style
    width: 100%;
    padding: 8px 16px;
    box-sizing: border-box;
    text-align: left;

    display: flex;
    gap: 0.5rem;
    align-items: center;

    &:hover {
        background-color: ${(props) =>
            props.isDisabled ? 'inherit' : colors.captureGrey100};
    }
`

const IconDiv = styled.div`
    display: inherit;
    justify-content: center;
`

const TextDiv = styled.div`
    font-size: ${fontSize.small_14};
`

const OptionDesc = styled.div`
    font-size: ${fontSize.small_12};
    font-style: italic;
`

export type ButtonWithExpandingContentProps = {
    menuOptions: ButtonProps[]
    onClickAction?: () => void
    icon?: SVGIcon
    showIcons?: boolean
    iconColor?: string
    hoverColor?: string
    isDisabled?: boolean
}

type MenuProps = {
    doCollapse: () => void
    isExpanded: boolean
    position: Position
    children?: ReactNode
}

const callAll = (callbacks: Array<() => void>) => {
    callbacks.forEach((cb) => {
        cb?.()
    })
}

const getIcon = (icon?: Icon, isDisabled?: boolean) => {
    if (!icon) {
        return null
    }
    const UserIcon = icon
    return (
        <UserIcon size={24} color={isDisabled ? inactiveColor : activeColor} />
    )
}

const MenuOption: FunctionComponent<{
    opt: ButtonProps
    onClick: () => void
    onMouseOver?: () => void
    onMouseLeave?: () => void
    showIcon?: boolean
}> = ({ opt, onClick, onMouseOver, onMouseLeave, showIcon }) => {
    if (opt.isDisabledForReadonly) {
        return (
            <ReadonlyTooltipOnMenu
                renderElement={(isReadonlyUser) => {
                    const doDisable = isReadonlyUser || opt.isDisabled
                    return (
                        <OptionButton
                            data-cy={opt.cyKey}
                            onClick={doDisable ? undefined : onClick}
                            onKeyUp={doDisable ? undefined : onClick}
                            onMouseOver={onMouseOver}
                            onFocus={onMouseOver}
                            onMouseLeave={onMouseLeave}
                            isDisabled={doDisable}
                            role="button"
                            tabIndex={0}>
                            {showIcon !== false ? (
                                <IconDiv>
                                    {getIcon(opt.icon, doDisable)}
                                </IconDiv>
                            ) : null}
                            <TextDiv>
                                {opt.text}
                                {opt.altText && (
                                    <OptionDesc>{opt.altText}</OptionDesc>
                                )}
                            </TextDiv>
                        </OptionButton>
                    )
                }}
            />
        )
    }
    return (
        <OptionButton
            data-cy={opt.cyKey}
            onClick={onClick}
            onKeyUp={onClick}
            onMouseOver={onMouseOver}
            onFocus={onMouseOver}
            onMouseLeave={onMouseLeave}
            isDisabled={opt.isDisabled}
            role="button"
            tabIndex={0}>
            {showIcon !== false ? (
                <IconDiv>{getIcon(opt.icon, opt.isDisabled)}</IconDiv>
            ) : null}
            <TextDiv>
                {opt.text}
                {opt.altText && <OptionDesc>{opt.altText}</OptionDesc>}
            </TextDiv>
        </OptionButton>
    )
}

type MakeMenuOptionsProps = {
    options: ButtonProps[]
    afterClick: () => void
    showIcons?: boolean
}

export const MakeMenuOptions = (props: MakeMenuOptionsProps) => {
    const [showSubMenu, setShowSubMenu] = useState(false)
    const [isPositionedLeft, setIsPositionedLeft] = useState(false)
    const windowWidth = useSelector(getViewportWidth)

    const ref = useCallback(
        (node: HTMLDivElement) => {
            if (node !== null) {
                if (windowWidth - node.getBoundingClientRect().right < 200) {
                    node.style.transform = 'translateX(-100%)'
                    setIsPositionedLeft(false)
                } else {
                    node.style.transform = 'translateX(100%)'
                    setIsPositionedLeft(true)
                }
            }
        },
        [windowWidth],
    )

    return (
        <>
            {props.options.map((opt, mainIndex) => {
                return opt.subMenu ? (
                    <div key={opt.text}>
                        <MethodProvider
                            method={callAll}
                            arg={
                                opt.isDisabled
                                    ? []
                                    : [opt.onClick, props.afterClick]
                            }>
                            {(method) => (
                                <MenuOption
                                    opt={opt}
                                    onClick={method}
                                    onMouseOver={() => setShowSubMenu(true)}
                                    onMouseLeave={() => setShowSubMenu(false)}
                                    showIcon={props.showIcons}
                                />
                            )}
                        </MethodProvider>
                        {showSubMenu && (
                            <SubMenuWrapper
                                ref={ref}
                                mainIndex={mainIndex}
                                isPositionedLeft={isPositionedLeft}>
                                {opt.subMenu.map((subOpt, _subIndex) => {
                                    return (
                                        <SubMenuOptionWrapper key={subOpt.text}>
                                            <MethodProvider
                                                key={subOpt.text}
                                                method={callAll}
                                                arg={
                                                    subOpt.isDisabled
                                                        ? []
                                                        : [
                                                              subOpt.onClick,
                                                              props.afterClick,
                                                          ]
                                                }>
                                                {(method) => (
                                                    <MenuOption
                                                        opt={subOpt}
                                                        onClick={() => {
                                                            method()
                                                            setShowSubMenu(
                                                                false,
                                                            )
                                                        }}
                                                        onMouseOver={() =>
                                                            setShowSubMenu(true)
                                                        }
                                                        onMouseLeave={() =>
                                                            setShowSubMenu(
                                                                false,
                                                            )
                                                        }
                                                        showIcon={
                                                            props.showIcons
                                                        }
                                                    />
                                                )}
                                            </MethodProvider>
                                        </SubMenuOptionWrapper>
                                    )
                                })}
                            </SubMenuWrapper>
                        )}
                    </div>
                ) : (
                    <MethodProvider
                        key={opt.text}
                        method={callAll}
                        arg={
                            opt.isDisabled
                                ? []
                                : [opt.onClick, props.afterClick]
                        }>
                        {(method) => (
                            <MenuOption
                                opt={opt}
                                onClick={method}
                                showIcon={props.showIcons}
                            />
                        )}
                    </MethodProvider>
                )
            })}
        </>
    )
}

export const Menu: FunctionComponent<MenuProps> = (props) => {
    if (!props.isExpanded) {
        return null
    }
    return (
        <MenuWrapper
            onClickOutside={props.doCollapse}
            position={props.position}>
            {props.children}
        </MenuWrapper>
    )
}

const ExpandableOverflowMenu = (position: Position) =>
    ButtonWithExpandingContent(position)<
        ButtonWithExpandingContentProps,
        Pick<ButtonWithExpandingContentProps, 'menuOptions'>
    >(
        ({
            toggleExpand,
            icon,
            iconColor,
            hoverColor,
            isDisabled,
            onClickAction,
        }) => (
            <IconButton
                onClick={() => {
                    onClickAction?.()
                    toggleExpand()
                }}
                icon={icon || SvgOverflowMenu}
                color={iconColor}
                hoverColor={hoverColor}
                isDisabled={isDisabled}
            />
        ),
        ({ doCollapse, isExpanded, menuOptions }) => (
            <Menu
                position={position}
                doCollapse={doCollapse}
                isExpanded={isExpanded}>
                <MakeMenuOptions
                    options={menuOptions}
                    afterClick={doCollapse}
                />
            </Menu>
        ),
    )

export const OverflowMenu = ExpandableOverflowMenu({ v: 'bottom', h: 'right' })
export const OverflowMenuExpandingUpwards = ExpandableOverflowMenu({
    v: 'top',
    h: 'right',
})

export const ExpandableOverflowButton = (
    ButtonComp: FunctionComponent<ButtonProps>,
    position: Position,
) =>
    ButtonWithExpandingContent(position)<
        Omit<ButtonProps, 'onClick'>,
        Pick<OverflowButtonProps, 'buttons'>
    >(
        ({ toggleExpand, ...buttonProps }) => (
            <ButtonComp onClick={toggleExpand} {...buttonProps} />
        ),
        ({ doCollapse, isExpanded, buttons }) => (
            <Menu
                position={position}
                doCollapse={doCollapse}
                isExpanded={isExpanded}>
                <MakeMenuOptions options={buttons} afterClick={doCollapse} />
            </Menu>
        ),
    )
