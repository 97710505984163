import styled from 'styled-components'
import { colors, fontSize } from '~/assets/styleConstants'

const PageWrapper = styled.div`
    padding: 0 16px;
    box-sizing: border-box;
    align-self: center;

    display: flex;
    gap: 32px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`

export const HeaderText = styled.div`
    font-size: ${fontSize.medium_20};
    font-weight: bold;
    color: ${colors.captureGrey800};
    text-align: center;
`

export const SubHeaderText = styled.div`
    margin-top: 0.5em;
    font-size: ${fontSize.medium_16};
`

type PageProps = {
    header?: React.ReactNode
    subHeader?: React.ReactNode
    illustration?: React.ReactNode
    children?: React.ReactNode
}

export const EmptyStatePage: React.FunctionComponent<PageProps> = ({
    header,
    subHeader,
    illustration,
    children,
}) => (
    <PageWrapper>
        {illustration}
        <div>
            {header && <HeaderText>{header}</HeaderText>}
            {subHeader && <SubHeaderText>{subHeader}</SubHeaderText>}
        </div>
        {children}
    </PageWrapper>
)
