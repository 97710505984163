import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { fetchConnectedDevices } from '~/API/job'
import { _ } from '~/assets/localization/util'
import { fontSize, mediaQueries } from '~/assets/styleConstants'
import {
    AppStoreBadgeLink,
    GooglePlayBadgeLink,
} from '~/components/Common/AppBadgeLink'
import { getConnectedDevices } from '~/state/currentUser/selectors'
import { buyMoreStorage } from '~/utilities/appLink'
import { isMobileDevice } from '~/utilities/device'
import { RippleLoaderPage } from './RippleLoader'
import { FilledTextButton } from './IconTextButton'

const AppBadges = styled.div`
    margin: 16px;
    text-align: center;
    a {
        margin: 10px 12px;
        display: inline-block;
    }
`

const OverlayContentWrapper = styled.div`
    margin: 8px 0;
    ${mediaQueries.mobile} {
        p {
            font-size: ${fontSize.small_14};
        }
    }
`

export const BuyFromAbroad: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState(true)
    const connectedDevices = useSelector(getConnectedDevices)
    const dispatch = useDispatch()

    let text: string = _('buy_more_storage_install_app')
    let appBadges = (
        <AppBadges>
            <GooglePlayBadgeLink />
            <AppStoreBadgeLink />
        </AppBadges>
    )

    if (isMobileDevice.any() && connectedDevices.length !== 0) {
        text = _('buy_more_storage_open_app')
        appBadges = (
            <AppBadges>
                <FilledTextButton
                    text={_('open_in_app')}
                    onClick={buyMoreStorage}
                    data-cy={'settings__buyMoreBtn'}
                />
            </AppBadges>
        )
    } else if (isMobileDevice.iOS()) {
        appBadges = (
            <AppBadges>
                <AppStoreBadgeLink />
            </AppBadges>
        )
    } else if (isMobileDevice.Android()) {
        appBadges = (
            <AppBadges>
                <GooglePlayBadgeLink />
            </AppBadges>
        )
    }

    useEffect(() => {
        fetchConnectedDevices(dispatch).then(() => setIsLoading(false))
    }, [])

    if (isLoading) {
        return <RippleLoaderPage />
    }

    return (
        <OverlayContentWrapper>
            {text}
            {appBadges}
        </OverlayContentWrapper>
    )
}
