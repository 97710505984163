import styled from 'styled-components'
import { colors, fontSize } from '../../assets/styleConstants'
import type { SVGIcon } from './IconTextButton'

const IconWrapper = styled.div`
    display: inline-flex;
    margin-right: 0.25em;
    align-items: center;
    font-size: ${fontSize.small_12};

    svg {
        margin-right: 2px;
    }
`

export const MaybeNumberOf: React.FunctionComponent<{
    icon: SVGIcon
    numberOf: number
    color?: string
}> = ({ icon: NumberIcon, numberOf, color }) => {
    return numberOf > 0 ? (
        <IconWrapper>
            <NumberIcon color={color || colors.white} />
            {numberOf}
        </IconWrapper>
    ) : null
}
