import * as React from 'react'
import styled from 'styled-components'
import { InvisibleFileInput } from './InvisibleFileInput'

export const AcceptType_images = 'image/*, .heic'

const Wrapper = styled.span`
    cursor: pointer;
    display: inherit;
`
type Props = {
    fileHandler: (files: File[]) => void
    acceptTypes: string
    acceptMultiple?: boolean
    children?: React.ReactNode
    isDisabled?: boolean
}
export class FileAcceptElement extends React.Component<Props> {
    private fileInput: InvisibleFileInput | null = null

    private bindFileInput = (me: InvisibleFileInput | null) => {
        this.fileInput = me
    }

    private handleClick = () => {
        if (this.fileInput) {
            this.fileInput.triggerFileDialog()
        }
    }

    public render() {
        return (
            <Wrapper
                onClick={
                    !this.props.isDisabled ? this.handleClick : () => undefined
                }
                onKeyUp={
                    !this.props.isDisabled ? this.handleClick : () => undefined
                }
                role="button"
                tabIndex={0}>
                <InvisibleFileInput
                    ref={this.bindFileInput}
                    fileHandler={this.props.fileHandler}
                    acceptTypes={this.props.acceptTypes}
                    acceptMultiple={this.props.acceptMultiple}
                />
                {this.props.children}
            </Wrapper>
        )
    }
}
