import { _ } from '~/assets/localization/util'
import { colors } from '~/assets/styleConstants'
import {
    BorderedTextButton,
    FilledTextButton,
} from '~/components/Common/IconTextButton'
import { Buttons, Divider, Paragraph, TitleText } from './unsubscribe.styled'

export const FeedbackUnsubscribed = () => {
    return (
        <>
            <TitleText success>
                {_('unsubscribe_from_email_success_title')}
            </TitleText>
            <Paragraph>{_('unsubscribe_from_email_success_text')}</Paragraph>
        </>
    )
}

export const FeedbackSaved = () => {
    return (
        <>
            <TitleText success>
                {_('unsubscribe_from_email_stay_title')}
            </TitleText>
            <Paragraph>{_('unsubscribe_from_email_stay_text')}</Paragraph>
        </>
    )
}

interface FormProps {
    unsubscribe: () => void
    stayInList: () => void
}

export const UnsubscribeForm = ({ unsubscribe, stayInList }: FormProps) => {
    return (
        <>
            <TitleText>{_('unsubscribe_from_email_form_title')}</TitleText>
            <Divider />
            <Paragraph>{_('unsubscribe_from_email_form_text1')}</Paragraph>
            <Paragraph>{_('unsubscribe_from_email_form_text2')}</Paragraph>
            <Buttons>
                <BorderedTextButton
                    text={_('unsubscribe_from_email_form_button_stay')}
                    color={colors.captureBlue}
                    onClick={stayInList}
                    data-cy="stay-button"
                />
                <FilledTextButton
                    text={_('unsubscribe_from_email_form_button_unsubscribe')}
                    fillColor={colors.warningRed}
                    onClick={unsubscribe}
                    data-cy="unsubscribe-button"
                />
            </Buttons>
        </>
    )
}
