import { groupArray } from '~/utilities/arrayUtils'
import { compareDay } from '~/utilities/dateOperations'
import { makeWhitelist } from '~/utilities/inputValidation'
import type { Action } from '../common/actions'
import { isType } from '../common/actions'
import {
    AllJobFilesWasFetched,
    FetchedDefaultJob,
    FetchedLastSerialOfDefaultJob,
    FileRangeFetchFailed,
    FileRangeFetchingStarted,
    FileRangeWasFetched,
} from '../job/actions'
import {
    FilesWereDeselected,
    FilesWereSelected,
    SelectionActionConfirmHandled,
} from '../selectedFiles/actions'
import {
    SectionsWereExpired,
    TimelineFileSelectingStarted,
    TimelineFilterSet,
    TimelineMonthsFetched,
    TimelineSectionsFetched,
} from './actions'
import {
    TimelineSectionReference__asDays,
    TimelineSectionReference__asString,
} from './index'

export enum MonthFetchStatus {
    UNFETCHED = 'UNFETCHED',
    FETCHING = 'FETCHING',
    FETCHED = 'FETCHED',
}
export type TimelineMonthCountDetail = {
    pictures: number
    videos: number
    screenshots: number
}
export type TimelineMonthSection = {
    year: number
    month: number
    startDay: number // 1-31 (end inclusive)
    endDay: number // 1-31 (end inclusive)
    counts: TimelineMonthCountDetail
    fetchStatus: MonthFetchStatus
}

export type MediaFilterMode = 'all' | 'only_images' | 'only_videos'
export type RecentsFilterMode = 'recents_1' | 'recents_7' | 'recents_30'

export type TimelineFilterMode = MediaFilterMode | RecentsFilterMode

export const asTimelineFilterValue = makeWhitelist<TimelineFilterMode>([
    'all',
    'only_images',
    'only_videos',
])

export type TimelineState = {
    timelineJob?: JobID
    initialLastChange: number
    allFilesFetched: boolean
    sections?: TimelineMonthSection[]
    filter: TimelineFilterMode
    isSelectMode: boolean
}

const initialState: TimelineState = {
    initialLastChange: -1,
    allFilesFetched: false,
    filter: 'all',
    isSelectMode: false,
}

const updateStateWithFileRangeAction = (
    state: TimelineState,
    start: DayRef,
    end: DayRef,
    newStatus: MonthFetchStatus,
): TimelineState => {
    if (state.sections && !state.allFilesFetched) {
        return {
            ...state,
            sections: state.sections.map((m) => {
                const mDays = TimelineSectionReference__asDays(m)
                if (
                    compareDay(start, mDays.start) >= 0 &&
                    compareDay(end, mDays.end) <= 0
                ) {
                    return { ...m, fetchStatus: newStatus }
                }
                return m
            }),
        }
    }

    return state
}

export function timelineReducer(
    state: TimelineState = initialState,
    action: Action,
): TimelineState {
    if (isType(action, FetchedDefaultJob)) {
        return { ...state, timelineJob: action.payload }
    }

    if (isType(action, FetchedLastSerialOfDefaultJob)) {
        return { ...state, initialLastChange: action.payload }
    }

    if (
        isType(action, TimelineMonthsFetched) &&
        state.timelineJob === action.payload.jobID
    ) {
        return {
            ...state,
            sections: action.payload.months.map(
                ({
                    year,
                    month,
                    picture_count,
                    screenshot_count,
                    video_count,
                }) => {
                    const section: TimelineMonthSection = {
                        year,
                        month,
                        startDay: 1,
                        endDay: 31,
                        counts: {
                            pictures: picture_count,
                            videos: video_count,
                            screenshots: screenshot_count,
                        },
                        fetchStatus: state.allFilesFetched
                            ? MonthFetchStatus.FETCHED
                            : MonthFetchStatus.UNFETCHED,
                    }
                    return section
                },
            ),
        }
    }
    if (
        isType(action, TimelineSectionsFetched) &&
        state.timelineJob === action.payload.jobID
    ) {
        return {
            ...state,
            sections: action.payload.sections.map(
                ({
                    year,
                    month,
                    startDay,
                    endDay,
                    ...s
                }): TimelineMonthSection => ({
                    year,
                    month,
                    startDay,
                    endDay,
                    counts: {
                        pictures: s.pictureCount,
                        videos: s.videoCount,
                        screenshots: s.screenshotCount,
                    },
                    fetchStatus: state.allFilesFetched
                        ? MonthFetchStatus.FETCHED
                        : MonthFetchStatus.UNFETCHED,
                }),
            ),
        }
    }

    if (isType(action, TimelineFilterSet)) {
        return { ...state, filter: action.payload }
    }

    if (isType(action, TimelineFileSelectingStarted)) {
        return { ...state, isSelectMode: true }
    }
    if (
        (isType(action, FilesWereSelected) ||
            isType(action, FilesWereDeselected)) &&
        state.isSelectMode
    ) {
        return { ...state, isSelectMode: false }
    }

    if (
        isType(action, FileRangeFetchingStarted) ||
        isType(action, FileRangeWasFetched) ||
        isType(action, FileRangeFetchFailed)
    ) {
        const newStatus = {
            [FileRangeFetchingStarted.type]: MonthFetchStatus.FETCHING,
            [FileRangeWasFetched.type]: MonthFetchStatus.FETCHED,
            [FileRangeFetchFailed.type]: MonthFetchStatus.UNFETCHED,
        }[action.type]
        return updateStateWithFileRangeAction(
            state,
            action.payload.start,
            action.payload.end,
            newStatus,
        )
    }
    if (isType(action, SelectionActionConfirmHandled)) {
        return { ...state, isSelectMode: false }
    }

    if (
        isType(action, AllJobFilesWasFetched) &&
        state.timelineJob === action.payload.jobID &&
        (action.payload.lastEvent === -1 ||
            state.initialLastChange <= action.payload.lastEvent)
    ) {
        const months = state.sections?.map((s) => ({
            ...s,
            fetchStatus: MonthFetchStatus.FETCHED,
        }))
        return {
            ...state,
            allFilesFetched: true,
            sections: months,
        }
    }

    if (
        isType(action, SectionsWereExpired) &&
        state.timelineJob === action.payload.jobID
    ) {
        const lookup = groupArray(
            action.payload.expiredSections,
            TimelineSectionReference__asString,
        )
        const sections = state.sections?.map((s) => {
            const match = lookup[TimelineSectionReference__asString(s)]
            return match !== undefined
                ? {
                      ...s,
                      counts: match[0].counts,
                      fetchStatus: MonthFetchStatus.UNFETCHED,
                  }
                : s
        })

        return { ...state, sections }
    }

    return state
}

export const timelineReducerMapObj = {
    timeline: timelineReducer,
}
export type StateWithTimeline = StateOfReducerMapObj<
    typeof timelineReducerMapObj
>
